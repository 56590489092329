<template>
  <div class="flex w-full justify-center pt-4">
    <div class="animated fade-in-up flex items-center justify-center">
      <div class="position-relative add-node-colour tile flex w-full flex-col items-start rounded text-xs shadow">
        <div class="my-2 flex w-full flex-col items-start justify-center px-4">
          <div class="cursor-pointer">
            <div id="addNodeButton" @click="addNodeOnClick({ parent_id: this.id })">
              <span class="text-white">Add Node</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id'],
  emits: ['addNode'],
  methods: {
    addNodeOnClick(event) {
      this.$emit('addNode', event);
    },
  },
};
</script>

<style>
.add-node-colour {
  background-color: var(--vh-black);
}
.add-node-colour:hover {
  background-color: var(--vh-blue);
  cursor: pointer;
}
</style>
