<template>
  <div id="expertise_areas" class="col-span-2 mt-8 mb-4 flex flex-row items-center justify-center">
    <h1 class="mr-2 text-lg font-bold text-gray-500">Expertise Areas</h1>
    <div class="h-1 flex-grow bg-gray-500"></div>
    <!-- If we need separate specialty areas for users with different specialties  -->
    <!-- <Dropdown v-model="selectedUserSpecialtyId" :options="user.specialties" placeholder="Select Specialty" optionLabel="name" optionValue="id" /> -->
  </div>
  <Message severity="info" :closable="false" class="text-left">Increase the chances of receiving consults that relate to your expertise by specifying your areas of expertise. Consulting phyisicians see this information before booking a consult with you.</Message>
  <div v-if="this.user.expertise_areas.length > 0" class="w-full">
    <div class="text-xs uppercase font-display font-semibold text-gray-400 text-left border-b border-gray-400 p-1">Your Expertise Areas</div>
    <div v-for="item in this.user.expertise_areas" :key="item.id" class="flex flex-row justify-between items-center font-display border-b border-gray-400 p-1">
      {{ item.name }}
      <Button label="Remove" class="p-button-icon-only p-button-text" @click="remove(item)" v-tooltip.left="'Remove expertise area'"> <i class="pi pi-times navbar-icon text-gray-400" style="font-size: 0.8rem !important"></i> </Button>
    </div>
  </div>
  <div v-else>
    <Message severity="warn" :closable="false" class="text-left">You currently have no expertise areas. Please add your areas of expertise by clicking the button below.</Message>
  </div>
  <div class="mt-5">
    <div class="flex flex-col md:flex-row justify-end items-end">
      <Button class="p-button-secondary" icon="pi pi-plus" label="Add New Expertise Area" @click="displayDialog = !displayDialog"></Button>
    </div>
  </div>

  <Dialog :draggable="false" v-model:visible="displayDialog" :modal="true" :dismissableMask="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ minWidth: '52vw' }">
    <template #header>
      <h2 class="text-md font-bold text-gray-500">Add new expertise area</h2>
    </template>
    <div class="px-0 text-left mb-4">
      <label for="expertiseAreasMultiselect" class="auth-input">Select your expertise areas from our existing wide range of selections.</label>
      <MultiSelect :class="['w-full', { 'p-invalid': emptyError }]" v-model="selectedExpertiseAreas" :options="allExpertiseAreasOfSpecialty" name="expertiseAreasMultiselect" required optionLabel="name" optionValue="id" :showToggleAll="false" :filter="true" display="chip" emptyMessage="Select Expertise Areas" />
    </div>
    <div class="w-full flex justify-center items-center text-center"><Button class="p-button-secondary p-button-outlined my-5" @click="showAddNewExperiseAreas = true" v-if="!showAddNewExperiseAreas" id="showAddNewExpertiseButton">Can't find your expertise area? Click here to add a new one.</Button></div>
    <div class="col-start-1 col-end-3 mb-2 sm:col-auto" v-if="showAddNewExperiseAreas">
      <label for="expertiseAreasMultiselect" class="auth-input">Add expertise areas. Press enter to add multiple expertise areas.</label>
      <Chips class="w-full" v-model="newlyAddedAreasOfExpertise" separator="," required :addOnBlur="true" :inputId="'newExpertise'" />
    </div>
    <div class="col-span-2 mt-8 flex flex-row justify-end">
      <Button label="Add Expertise Area" class="p-button p-button-secondary" id="addNewExpertiseAreasButton" type="submit" @click="addNewExpertiseAreas()"></Button>
    </div>
  </Dialog>
  <Loading z-index="99" v-model:active="isLoading" loader="spinner" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions } from 'vuex';
import Button from 'primevue/button';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message';
import Loading from 'vue-loading-overlay';

export default {
  props: ['loggedInUser'],
  components: {
    Button,
    MultiSelect,
    InputText,
    Dialog,
    Message,
    Loading,
  },
  data() {
    return {
      displayDialog: false,
      user: this.loggedInUser,
      errors: [],
      newExpertiseName: '',
      selectedExpertiseAreas: [],
      specialtyId: this.loggedInUser.specialties[0].id,
      componentKey: 0,
      userSpecialtyIds: [],
      selectedUserSpecialtyId: '',
      newlyAddedAreasOfExpertise: [],
      showAddNewExperiseAreas: false,
    };
  },
  methods: {
    ...mapActions(['getExpertiseAreaById', 'createExpertiseArea', 'attachExpertiseAreas', 'removeExpertiseAreas']),
    addNewExpertiseAreas() {
      let promises = [];
      if (this.selectedExpertiseAreas.length > 0) {
        promises.push(
          this.attachExpertiseAreas({
            user_id: this.loggedInUser.id,
            specialty_id: this.specialtyId,
            expertise_area_ids: this.selectedExpertiseAreas,
          }).then((response) => {
            this.user.expertise_areas = response.data.data.expertise_areas;
          })
        );
      }
      if (this.newlyAddedAreasOfExpertise.length > 0) {
        promises.push(
          this.createExpertiseArea({
            user_id: this.user.id,
            specialty_id: this.specialtyId,
            names: this.newlyAddedAreasOfExpertise,
          }).then((response) => {
            this.user.expertise_areas = response.data.data.expertise_areas;
          })
        );
      }
      if (promises.length > 0) {
        this.isLoading = true;
        Promise.all(promises)
          .then(() => {
            this.componentKey += 1;
            this.isLoading = false;
            this.displayDialog = !this.displayDialog;
            this.newlyAddedAreasOfExpertise = [];
            this.selectedExpertiseAreas = [];
            this.showAddNewExperiseAreas = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Account Updated',
              detail: 'Account information has been successfully updated.',
              life: 5000,
            });
          })
          .catch((err) => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          });
      }
    },
    remove(item) {
      this.isLoading = true;
      this.removeExpertiseAreas({
        user_id: this.loggedInUser.id,
        specialty_id: this.specialtyId,
        expertise_area_ids: [item.id],
      })
        .then((response) => {
          (this.selectedExpertiseAreas = []), (this.componentKey += 1);
          this.user.expertise_areas = response.data.data.expertise_areas;
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Expertise Area Removed',
            detail: 'Account information has been successfully updated.',
            life: 2000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        });
    },
    submit() {
      this.isLoading = true;
      this.attachExpertiseAreas({
        user_id: this.loggedInUser.id,
        specialty_id: this.specialtyId,
        expertise_area_ids: this.selectedExpertiseAreas,
      })
        .then((response) => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Expertise Area Added',
            detail: 'Account information has been successfully updated.',
            life: 5000,
          });
          this.componentKey += 1;
          this.selectedExpertiseAreas = [];
          this.user.expertise_areas = response.data.data.expertise_areas;
        })
        .catch(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        });
    },
  },
  computed: {
    allExpertiseAreasOfSpecialty() {
      if (this.user.specialties) {
        return this.user.specialties[0].expertise_areas.filter((area) => !this.user.expertise_areas.find((userArea) => userArea.name === area.name));
      }
      return [];
    },
  },
  created() {},
};
</script>

<style></style>
