<template>
  <div class="main-inner-wrap vld-parent" v-if="isLoading">
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
  </div>
  <div class="main-inner-wrap" v-if="!isLoading" id="allResponseFormRelatedFileComponent">
    <div class="grid grid-cols-3 mt-5 rounded-lg text-sm font-display w-full cursor-pointer">
      <div @click="sharedFilterClicked('All')" class="rounded bg-white justify-center flex flex-col align-center px-1 py-1" :class="{ 'text-primary border-primary border': fileFilters.shared == 'All', ' border-t border-b border-l border-r': fileFilters.shared != 'All' }" id="responseFormRelatedFileFilterByAllInput">All</div>
      <div @click="sharedFilterClicked('Shared')" class="rounded bg-white justify-center flex flex-col align-center px-1 py-1" :class="{ 'text-primary border-primary border': fileFilters.shared == 'Shared', 'border-r border-t border-b': fileFilters.shared != 'Shared' }">
        <span v-if="!isGP(loggedInUser)">Shared with You</span>
        <span v-else>My Uploads</span>
      </div>
      <div @click="sharedFilterClicked('Favorites')" class="rounded bg-white justify-center flex flex-col align-center px-1 py-1" :class="{ 'text-primary border-primary border': fileFilters.shared == 'Favorites', ' border-t border-b border-r': fileFilters.shared != 'Favorites' }" id="responseFormRelatedFileFilterByFavoritesInput">Favorites</div>
    </div>
    <div class="flex w-full mt-2">
      <!-- <IconField iconPosition="left">
        <InputIcon class="pi pi-search"> </InputIcon>
        <InputText id="responseFormRelatedFileSearchByNameInput" v-model="searchFileByNameInput" placeholder="Search by name" class="w-full p-inputtext-xs" @input="filterFilesByName" />
      </IconField> -->
      <IconField iconPosition="left" class="w-3/4">
        <InputIcon class="pi pi-search"> </InputIcon>
        <InputText id="responseFormRelatedFileSearchByNameInput" v-model="searchFileByNameInput" placeholder="Search by Title" class="p-inputtext-xs" @input="filterFilesByName" />
      </IconField>

      <MultiSelect v-model="selectedTags" :options="availableTags" placeholder="Filter by Tags" class="multiselect-sm ml-1 w-1/4" @change="filterFilesByTags" />
    </div>
    <!-- This is when a file is referred via URL e.g. when a shared file link is visited -->
    <div v-if="fileIdInQuery" class="md:flex sm:grid justify-center my-2 w-full">
      <div class="md:w-1/4 sm:w-full" v-if="queriedFile">
        <Message severity="success" class="text-start" :closable="false">Here is the resource that was shared with you, check out other resources available on our platform below.</Message>
        <SingleRelatedFileTile :file="queriedFile" :tileColor="availableTileColors[0]" :isFeatured="true" :id="'referralResponseFormRelatedQueriedFileTile'" />
      </div>
      <Message severity="error" v-else>The resource that was shared with you was not shared with all of Virtual Hallway network. Please ask the resource owner to share the resource with the network.</Message>
    </div>
    <div v-if="filteredFiles.length < 1">
      <Message severity="info" :closable="false" class="text-left">
        <p v-if="fileFilters.shared == 'All'">No resources found, please try changing the filters.</p>
        <p v-if="fileFilters.shared == 'Shared'">
          <span v-if="isSpecialist(loggedInUser)"> You have not shared resources with any user. To share a resource, please view a consult and look for the Resources section. </span>
          <span v-else> There are no shared resources. When a {{ getReferralPersonTerm(actingUser) }} shares a resource related to a consult, you will be able to view them here. </span>
        </p>
        <p v-else-if="fileFilters.shared == 'Favorites'">You have not favorited any resource, click on the <i class="pi pi-heart mx-1"></i> icon on a resource to add it to your favorite list.</p>
      </Message>
    </div>
    <div class="flex flex-col w-full p-2 mt-1 rounded-lg" v-for="[key, value] of Object.entries(filesBySpecialty)" v-else id="responseFormRelatedFileTiles">
      <div class="font-display text-start text-lg border-b rounded-xl pl-3 mb-2 pb-1" :style="`color:${tilesColors[key]};border-color:${tilesColors[key]};`">{{ key }}</div>
      <div class="w-full sm:flex sm:flex-col md:grid md:grid-cols-8 md:gap-4">
        <div class="w-full md:col-span-2 sm:flex sm:flex-col mt-2 animated fade-in-right">
          <SingleRelatedFileTile :file="value.featured_file" :tileColor="tilesColors[key]" :isFeatured="true" />
        </div>
        <div class="w-full md:col-span-6 sm:flex sm:flex-col">
          <div class="w-full md:grid md:grid-cols-3 md:gap-4 sm:flex sm:flex-col items-center">
            <div v-for="(file, index) in value.files.slice(0, 5)" class="mt-3 animated fade-in-right">
              <SingleRelatedFileTile :file="file" :tileColor="tilesColors[key]" :id="'referralResponseFormRelatedFileTile' + index" />
            </div>
            <div v-if="value.files.length > 5 && !showAllSpecialtyFiles[key]" @click="showAllSpecialtyFiles[key] = true" :style="`background-color: ${tilesColors[key]};`" class="mt-3 flex flex-col w-full bg-white rounded-lg shadow text-white font-display text-lg items-center justify-center hover:opacity-90 cursor-pointer h-full">View More</div>
            <div v-if="showAllSpecialtyFiles[key]" v-for="file in value.files.slice(5, value.length)" class="mt-3 animated fade-in-right">
              <SingleRelatedFileTile :file="file" :tileColor="tilesColors[key]" />
            </div>
            <div v-if="showAllSpecialtyFiles[key]" @click="showAllSpecialtyFiles[key] = false" :style="`background-color: ${tilesColors[key]};`" class="mt-3 flex flex-col w-full bg-white rounded-lg shadow text-white font-display text-lg items-center justify-center hover:opacity-90 cursor-pointer h-full">View Less</div>
          </div>
        </div>
        <!-- <ReferralFileRelatedFileList :referralResponseFormRelatedFiles="referralResponseFormRelatedFiles" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferralFileRelatedFileList from '@/components/referral_response_form_related_files/ReferralFileRelatedFileList.vue';
import SingleRelatedFileTile from '@/components/referral_response_form_related_files/SingleRelatedFileTile.vue';

import Loading from 'vue-loading-overlay';
import Message from 'primevue/message';

export default {
  components: {
    ReferralFileRelatedFileList,
    Loading,
    Message,
    SingleRelatedFileTile,
  },
  data() {
    return {
      isLoading: false,
      availableTileColors: ['#334155', '#991b1b', '#0891b2', '#1d4ed8', '#115e59', '#b91c1c', '#4338ca', '#0e7490', '#b45309', '#15803d', '#6d28d9', '#be185d'],
      tilesColors: {},
      fileFilters: {
        shared: 'All',
      },
      filteredFiles: [],
      searchFileByNameInput: '',
      selectedCities: [],
      availableTags: [],
      selectedTags: [],
      showAllSpecialtyFiles: {},
      fileIdInQuery: null,
      queriedFile: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'isSpecialist', 'referralResponseFormRelatedFiles', 'actingUser']),
    filesBySpecialty() {
      let files = {};
      this.isLoading = true;
      let colorIndex = 0;
      for (let i = 0; i < this.filteredFiles.length; i++) {
        let specialty = this.filteredFiles[i].referral.specialty.name;
        if (!files[specialty]) {
          files[specialty] = {};
          files[specialty]['featured_file'] = null;
          files[specialty]['files'] = [];
          files[specialty]['view_all'] = false;
          this.tilesColors[specialty] = this.availableTileColors[colorIndex++];
          if (colorIndex == this.availableTileColors.length) {
            colorIndex = 0;
          }
        }
        if (!files[specialty]['featured_file'] && this.filteredFiles[i].is_featured) {
          files[specialty]['featured_file'] = this.filteredFiles[i];
        } else {
          files[specialty]['files'].push(this.filteredFiles[i]);
        }
      }
      // If there are no featured files, select the most recent file to be featured
      for (let [key, value] of Object.entries(files)) {
        if (!value.featured_file) {
          files[key].featured_file = value.files[0];
          files[key].files.splice(0, 1);
        }
      }
      this.isLoading = false;
      return files;
    },
  },
  methods: {
    ...mapActions(['getAllReferralResponseRelatedFileByUserId']),
    filterFilesByShare() {
      if (this.fileFilters.shared == 'All') {
        this.filteredFiles = this.referralResponseFormRelatedFiles;
      } else if (this.fileFilters.shared == 'Shared') {
        if (this.isSpecialist(this.loggedInUser)) {
          this.filteredFiles = this.referralResponseFormRelatedFiles.filter((f) => f.uploader_id == this.loggedInUser.id);
        } else {
          this.filteredFiles = this.referralResponseFormRelatedFiles.filter((f) => f.referral.referee_id == this.loggedInUser.id);
        }
      } else if (this.fileFilters.shared == 'Favorites') {
        this.filteredFiles = this.referralResponseFormRelatedFiles.filter((f) => f.favorite_users.find((u) => u.id == this.loggedInUser.id));
      }
    },
    filterFilesByName() {
      this.filterFilesByShare();
      if (this.searchFileByNameInput.length > 0) {
        this.filteredFiles = this.filteredFiles.filter((f) => {
          if (f.title?.toLowerCase().includes(this.searchFileByNameInput.toLowerCase())) {
            return f;
          } else {
            return null;
          }
        });
      }
    },
    filterFilesByTags() {
      this.filterFilesByShare();
      if (this.selectedTags.length > 0) {
        this.filteredFiles = this.filteredFiles.filter((file) => file.tags.find((tag) => this.selectedTags.includes(tag.name)));
      }
    },
    sharedFilterClicked(filterValue) {
      this.fileFilters.shared = filterValue;
      this.filterFilesByShare();
    },
  },
  created() {
    this.isLoading = true;
    this.getAllReferralResponseRelatedFileByUserId({
      // user_id: this.loggedInUser.id,
      required_relations: ['referral', 'referral.specialty', 'uploader', 'favorite_users'],
    }).then(() => {
      this.isLoading = false;
      this.filterFilesByShare();
      for (let i = 0; i < this.filteredFiles.length; i++) {
        for (let j = 0; j < this.filteredFiles[i].tags.length; j++) {
          if (!this.availableTags.includes(this.filteredFiles[i].tags[j].name)) {
            this.availableTags.push(this.filteredFiles[i].tags[j].name);
          }
        }
      }
      this.fileIdInQuery = this.$route.query?.id;
      if (this.fileIdInQuery) {
        this.queriedFile = this.filteredFiles.find((f) => f.id == this.fileIdInQuery);
      }
    });
  },
};
</script>

<style></style>
