import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import { globalMixin } from '@/mixins';

import PageNotFound from '../views/public/PageNotFound.vue';
import Login from '../views/public/Login.vue';
import DemoLogin from '../views/public/DemoLogin.vue';
import Logout from '../views/user/Logout.vue';
import Registration from '../views/public/registration/Registration.vue';
import AccountSetupPage from '../views/user/onboarding/AccountSetupPage.vue';
import RegistrationThankYou from '../views/public/registration/RegistrationThankYou.vue';
import GeneralPrivacyPolicy from '../views/public/privacy/GeneralPrivacyPolicy.vue';
import PlatformPrivacyPolicy from '../views/public/privacy/PlatformPrivacyPolicy.vue';
import TermsOfService from '../views/public/privacy/TermsOfService.vue';
import Home from '../views/user/Home.vue';
import ReferralHistory from '../views/user/referral/History.vue';
import AllNotifications from '../views/user/my_account/AllNotifications.vue';
import ReferralTriage from '../views/user/referral/ReferralTriage.vue';
import ShowReferral from '../views/user/referral/Show.vue';
import MakeReferral from '../views/user/gp/MakeReferral.vue';
import Schedules from '../views/user/specialist/schedule/All.vue';
import Preferences from '../views/user/specialist/Preferences.vue';
import ExampleConsultForm from '../views/user/specialist/ExampleConsultForm.vue';
import Onboarding from '../views/user/onboarding/Onboarding.vue';
import AddSchedule from '../views/user/specialist/schedule/AddSchedule/AddSchedule.vue';
import PasswordReset from '../views/public/PasswordReset.vue';
import PasswordResetRequest from '../views/public/PasswordResetRequest.vue';
import VerifyEmail from '../views/public/VerifyEmail.vue';
import EmailNotVerified from '../views/public/EmailNotVerified.vue';
import ShowMyAccount from '../views/user/my_account/ShowMyAccount.vue';
import CloseAccount from '../views/user/my_account/CloseAccount.vue';
import InviteColleague from '../views/user/InviteColleague.vue';
import LegacyReRegister from '../views/user/legacy_reregister/LegacyReRegister.vue';
import Lectures from '../views/user/lectures/Lectures.vue';
import SingleLecture from '../views/user/lectures/SingleLecture.vue';
import LectureCredits from '../views/user/lectures/LectureCredits.vue';
import ManageUsers from '../views/user/org_unit/ManageUsers.vue';
import AddUser from '../views/user/org_unit/AddUser.vue';
import EditUser from '../views/user/org_unit/EditUser.vue';
import InviteUser from '../views/user/org_unit/InviteUser.vue';
import AddExistingUserToOrgUnit from '../views/user/org_unit/AddExistingUser.vue';
import ShareReferralsWithOrgUnit from '../views/user/referral/ShareReferralsWithOrgUnit.vue';
import SmartPathBuilder from '../views/user/specialist/smartpath/Builder.vue';
import SmartPaths from '../views/user/specialist/smartpath/SmartPaths.vue';
import SmartPath from '../views/user/gp/make_smartpath/SmartPath.vue';
import SmartPathTransactions from '../views/user/specialist/smartpath/SmartPathTransactions.vue';
import SmartPathTransactionHistory from '../views/user/gp/smartpath/SmartPathTransactionHistory.vue';
import ViewTransaction from '../views/user/specialist/smartpath/ReviewTransaction.vue';
import CorticoConsultRequest from '../views/public/ExternalReferralRequest/CorticoRequest.vue';
import AllResponseFormRelatedFiles from '../views/user/AllResponseFormRelatedFiles.vue';
import Billing from '../views/user/billing/Billing.vue';
import NotifyUserFromTextMessage from '../views/user/NotifyUserFromTextMessage.vue';
import VerifyEmailUpdate from '../views/public/VerifyEmailUpdate.vue';
import RecentCallsHistory from '../views/user/caller/RecentCallsHistory.vue';
import ManageCallDisplayNames from '../views/user/caller/ManageCallDisplayNames.vue';
import AddOrEditEncounterNote from '../views/user/encounter_note/AddOrEditEncounterNote.vue';
import Notes from '../views/user/encounter_note/Notes.vue';
import UserInviteAcceptLinkHandler from '../views/public/UserInviteAcceptLinkHandler.vue';
import EulaPage from '../views/public/EulaPage.vue';

const routes = [
  {
    name: 'Landing',
    path: '/',
    component: Login,
    meta: { requiresVisitor: true },
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    meta: { requiresVisitor: true },
  },
  {
    name: 'DemoLogin',
    path: '/cep',
    component: DemoLogin,
    meta: { requiresVisitor: true },
  },
  {
    name: 'DemoLoginNovaScotia',
    path: '/cep-ns',
    component: DemoLogin,
    meta: { requiresVisitor: true },
  },
  {
    name: 'Registration',
    path: '/registration',
    component: Registration,
    meta: { requiresVisitor: true, title: 'Registration' },
  },
  {
    name: 'AccountSetupPage',
    path: '/account-setup',
    component: AccountSetupPage,
    meta: { requiresAuth: true },
  },
  {
    name: 'Logout',
    path: '/logout',
    component: Logout,
  },
  {
    name: 'RegistrationThankYou',
    path: '/thank-you',
    component: RegistrationThankYou,
    meta: { requiresVisitor: true },
  },
  {
    name: 'GeneralPrivacyPolicy',
    path: '/privacy-policy',
    component: GeneralPrivacyPolicy,
    meta: { requiresVisitor: true },
  },
  {
    // may need updating
    name: 'PlatformPrivacyPolicy',
    path: '/platform-privacy-policy',
    component: PlatformPrivacyPolicy,
    meta: { requiresVisitor: true },
  },
  {
    // may need updating
    name: 'TermsOfService',
    path: '/terms-of-service',
    component: TermsOfService,
    meta: { requiresVisitor: true },
  },
  {
    name: 'Home',
    path: '/home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    name: 'ReferralHistory',
    path: '/referralhistory',
    component: ReferralHistory,
    meta: { requiresAuth: true },
  },
  {
    name: 'Billing',
    path: '/billing',
    component: Billing,
    meta: { requiresAuth: true },
  },
  {
    name: 'ReferralTriage',
    path: '/referraltriage',
    component: ReferralTriage,
    meta: { requiresAuth: true },
  },
  {
    name: 'AllNotifications',
    path: '/allnotifications',
    component: AllNotifications,
    meta: { requiresAuth: true },
  },
  {
    name: 'Referral',
    path: '/referral',
    component: ShowReferral,
    meta: { requiresAuth: true },
  },
  {
    name: 'ReferralDetail',
    path: '/referral/:id',
    component: ShowReferral,
    meta: { requiresAuth: true },
  },
  {
    name: 'MakeReferral',
    path: '/makereferral',
    component: MakeReferral,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    name: 'Schedules',
    path: '/schedules',
    component: Schedules,
    meta: { requiresAuth: true },
  },
  {
    name: 'Preferences',
    path: '/preferences',
    component: Preferences,
    meta: { requiresAuth: true },
  },
  {
    name: 'ExampleConsultForm',
    path: '/exampleconsultform',
    component: ExampleConsultForm,
    meta: { requiresAuth: true },
  },
  {
    name: 'DemoReferral',
    path: '/demoreferral',
    component: ExampleConsultForm,
    meta: { requiresAuth: true },
  },
  {
    name: 'Verification',
    path: '/verification',
    component: Onboarding,
    meta: { requiresAuth: true },
  },
  {
    // maybe remove
    name: 'LegacyReRegister',
    path: '/legacyreregister',
    component: LegacyReRegister,
    meta: { requiresAuth: true },
  },
  {
    name: 'AddSchedule',
    path: '/addschedule',
    component: AddSchedule,
    meta: { requiresAuth: true },
  },
  {
    name: 'ShowMyAccount',
    path: '/myaccount',
    component: ShowMyAccount,
    meta: { requiresAuth: true },
  },
  {
    // maybe remove
    name: 'InviteColleague',
    path: '/inviteuser',
    component: InviteColleague,
  },
  {
    name: 'Lectures',
    path: '/lectures',
    component: Lectures,
    meta: { title: 'Lectures' },
  },
  {
    name: 'LectureCredits',
    path: '/lecturecredits',
    component: LectureCredits,
    meta: { requiresAuth: true },
  },
  {
    name: 'SingleLecture',
    path: '/lecture/:lectureId',
    // path: '/lecture/:pretty_url',
    component: SingleLecture,
  },
  {
    name: 'PasswordResetRequest',
    path: '/passwordreset',
    component: PasswordResetRequest,
  },
  {
    name: 'PasswordReset',
    path: '/passwordreset/:userId/:code',
    component: PasswordReset,
  },
  {
    name: 'VerifyEmail',
    path: '/verifyemail/:userId/:code',
    component: VerifyEmail,
    meta: { requiresVisitor: true },
  },
  {
    name: 'EmailNotVerified',
    path: '/emailnotverified',
    component: EmailNotVerified,
    meta: { requiresAuth: true },
  },
  {
    name: 'CloseAccount',
    path: '/closeaccount',
    component: CloseAccount,
  },
  {
    name: 'ManageUsers',
    path: '/manageusers',
    component: ManageUsers,
  },
  {
    name: 'AddUser',
    path: '/manageusers/adduser',
    component: AddUser,
  },
  {
    name: 'EditUser',
    path: '/editaccount/:userId',
    component: EditUser,
  },
  {
    name: 'InviteUser',
    path: '/manageusers/inviteuser',
    component: InviteUser,
  },
  {
    name: 'AddExistingUserToOrgUnit',
    path: '/permission/allowtobeadded/:userId/:orgUnitId/:code',
    component: AddExistingUserToOrgUnit,
    meta: { requiresAuth: true },
  },
  {
    name: 'ShareConsults',
    path: '/shareconsults',
    component: ShareReferralsWithOrgUnit,
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPaths',
    path: '/smartpaths',
    component: SmartPaths,
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathBuilder',
    path: '/builder/:smartPathId',
    component: SmartPathBuilder,
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathSurvey',
    path: '/pathway/:smartPathId/:contributorId?/:transactionId?',
    component: SmartPath,
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathTransactions',
    path: '/results/:smartPathId',
    component: SmartPathTransactions,
    meta: { requiresAuth: true },
  },
  {
    name: 'SmartPathTransactionHistory',
    path: '/pathwayhistory',
    component: SmartPathTransactionHistory,
    meta: { requiresAuth: true },
  },
  {
    name: 'ReviewSmartPathTransaction',
    path: '/reviewresult/:transactionId',
    component: ViewTransaction,
    meta: { requiresAuth: true },
  },
  {
    name: 'CorticoConsultRequest',
    path: '/cortico/consult-request/:requestCode',
    component: CorticoConsultRequest,
    meta: { requiresAuth: false },
  },
  {
    name: 'Resources',
    path: '/resources',
    component: AllResponseFormRelatedFiles,
    meta: { requiresAuth: true },
  },
  {
    name: 'NotifyUserFromTextMessage',
    path: '/nUfT/:code',
    component: NotifyUserFromTextMessage,
    meta: { requiresAuth: false },
  },
  {
    name: 'VerifyEmailUpdate',
    path: '/verify-email-update/:code',
    component: VerifyEmailUpdate,
    meta: { requiresAuth: false },
  },
  {
    name: 'RecentCallsHistory',
    path: '/recent-calls-history',
    component: RecentCallsHistory,
    meta: { requiresAuth: true },
  },
  {
    name: 'ManageCallDisplayNames',
    path: '/call-display-names',
    component: ManageCallDisplayNames,
    meta: { requiresAuth: true },
  },
  {
    name: 'AddEncounterNote',
    path: '/add-note',
    component: AddOrEditEncounterNote,
    meta: { requiresAuth: true },
  },
  {
    name: 'Notes',
    path: '/notes',
    component: Notes,
    meta: { requiresAuth: true },
  },
  {
    name: 'EditEncounterNote',
    path: '/note/:id',
    component: AddOrEditEncounterNote,
    meta: { requiresAuth: true },
  },
  // The following link will no longer be useful for future invites, but any invites already sent before 31st July 2024 needs this handler
  {
    name: 'UserInviteAcceptLinkHandlerOld',
    path: '/join-hallway/:inviteCode',
    component: UserInviteAcceptLinkHandler,
    meta: { requiresAuth: false },
  },
  {
    name: 'UserInviteAcceptLinkHandler',
    path: '/accept-invite/:inviteCode',
    component: UserInviteAcceptLinkHandler,
    meta: { requiresAuth: false },
  },
  {
    name: 'EULA',
    path: '/eula',
    component: EulaPage,
    meta: { requiresAuth: false },
  },
  { path: '/:pathMatch(.*)*', component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top on route change
    return { top: 0 };
  },
});

router.beforeEach(async (to, from) => {
  const isAuthenticated = await store.getters.isLoggedIn;
  const isPreboarding = await store.getters.isPreboarding;
  const isOnboarding = await store.getters.isOnboarding;
  const isLegacyNeedReRegister = await store.getters.isLegacyNeedReRegister;
  const loggedInUser = await store.getters.loggedInUser;
  const onboardingForm = await store.getters.onboardingForm;
  const isEmailActivated = await store.getters.isEmailActivated;
  const isAllowedMakeReferral = await store.getters.isAllowedMakeReferral;
  const isOrgUnitHead = await store.getters.isOrgUnitHead;
  const isBookingHallwayAdmin = globalMixin.methods.isBookingHallwayAdmin(loggedInUser);
  const actingUser = await store.getters.actingUser;
  const isCme = await store.getters.isCme;
  const isActive = await store.getters.isActive;

  let smartPathRoutes = ['SmartPaths', 'ShowSmartPathTransactions', 'SmartPathTransactionHistory', 'SmartPathTransactions', 'SmartPathSurvey', 'SmartPathBuilder'];
  if (to.meta.requiresAuth && !isAuthenticated) {
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  } else if (to.name != 'EmailNotVerified' && isAuthenticated && !isEmailActivated) {
    return {
      path: '/emailnotverified',
    };
  } else if (isAuthenticated && !isOrgUnitHead(loggedInUser) && actingUser === null) {
    await store
      .dispatch('logout')
      .then(() => {
        router.go();
      })
      .catch(() => {});
  } else if (isAuthenticated && isPreboarding(loggedInUser) && to.name !== 'AccountSetupPage' && to.name !== 'Lectures' && to.name !== 'SingleLecture' && to.name !== 'LectureCredits' && to.name !== 'Logout' && to.name !== 'ShowMyAccount' && to.name !== 'CloseAccount') {
    return {
      path: '/account-setup',
    };
  } else if (isAuthenticated && to.name == 'AccountSetupPage' && !isPreboarding(loggedInUser)) {
    return {
      path: '/home',
    };
  } else if (isAuthenticated && smartPathRoutes.includes(to.name) && (loggedInUser.practice_province.name !== 'Nova Scotia' || loggedInUser.provider_type === 'Pharmacist' || isBookingHallwayAdmin)) {
    return {
      path: '/home',
    };
  } else if (isAuthenticated && to.name == 'Billing' && (isPreboarding(loggedInUser) || isBookingHallwayAdmin || loggedInUser.provider_type == 'Pharmacist' || (isOrgUnitHead(loggedInUser) && !isActive(loggedInUser)))) {
    return {
      path: '/home',
    };
  } else if (onboardingForm) {
    if (isAuthenticated && !onboardingForm.is_eula_agreed && to.name != 'EULA') {
      return {
        path: '/eula',
      };
    } else if (isAuthenticated && isLegacyNeedReRegister(loggedInUser) && to.name != 'LegacyReRegister' && onboardingForm.is_eula_agreed && to.name != 'Logout') {
      return {
        path: '/legacyreregister',
      };
    } else if (isAuthenticated && isOnboarding(loggedInUser) && !isAllowedMakeReferral && to.name == 'MakeReferral' && to.name != 'LegacyReRegister') {
      return {
        path: '/verification',
      };
    } else if (isAuthenticated && !isLegacyNeedReRegister(loggedInUser) && to.name == 'LegacyReRegister') {
      return {
        path: '/home',
      };
    } else if (isAuthenticated && onboardingForm.is_eula_agreed && to.name == 'EULA') {
      return {
        path: '/home',
      };
    } else if ((to.name == 'EditUser' || to.name == 'ManageUsers' || to.name == 'AddUser') && !isOrgUnitHead(loggedInUser) && !isBookingHallwayAdmin) {
      return {
        path: '/home',
      };
    } else if (to.meta.requiresVisitor && isAuthenticated && !isPreboarding(loggedInUser)) {
      return {
        path: '/home',
      };
    } else if (globalMixin.methods.isNativePlatform() && ['AddEncounterNote', 'Notes', 'EditEncounterNote'].includes(from.name) && !['AddEncounterNote', 'Notes', 'EditEncounterNote'].includes(to.name)) {
      return {
        path: from.path,
      };
    }
    // else if (globalMixin.methods.isNativePlatform() && !isCme(loggedInUser) && ['Lectures', 'SingleLecture', 'LectureCredits'].includes(from.name) && !['Lectures', 'SingleLecture', 'LectureCredits'].includes(to.name)) {
    //   return {
    //     path: from.path,
    //   };
    // }
    else if (globalMixin.methods.isNativePlatform() && ['RecentCallsHistory'].includes(from.name) && !['RecentCallsHistory'].includes(to.name)) {
      return {
        path: from.path,
      };
    }
    // else if (globalMixin.methods.isNativePlatform() && isCme(loggedInUser) && !['Lectures', 'SingleLecture', 'LectureCredits', 'AccountSetupPage', 'ShowMyAccount', 'Logout'].includes(to.name)) {
    //   return {
    //     path: from.path,
    //   };
    // }
  }
});

router.afterEach((to) => {
  // We set the title and meta description with the <Head> component in SingleLecture.vue
  if (to.name != 'SingleLecture') {
    if (to.meta.title) {
      document.title = `${to.meta.title} - Virtual Hallway`;
    } else {
      document.title = `Virtual Hallway`;
    }
  }
});

export default router;
