<template>
  <div v-if="tutorialStatus != '' && tutorialStatus != 'referral-form-complete'" class="specialist-tooltip-background"></div>
  <div class="main-inner-wrap">
    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block"><i class="pi pi-calendar-times mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div v-if="isOrgUnitHead(loggedInUser)" class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Set Consultant Availability</h1>
        <h1 class="text-sm text-gray-200">Please select the consultant whose availability you wish to view, then select the dates and times they are available to be booked for consults.</h1>
      </div>
      <div v-else class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">My Availability</h1>
        <h1 class="text-sm text-gray-200">Please select the dates and times you are available to be booked for consults.</h1>
      </div>
      <Button @click="addAvailabilityTutorialOnClick" v-on:click.stop label="View Availability Tutorial" icon="pi pi-external-link" class="p-button-outlined p-button-secondary p-button-sm hide-mobile z-10 text-xs" id="tips-button" iconPos="right" />
    </div>
    <div :class="['bg-white', 'border-1', 'rounded-lg', 'w-full shadow', 'max-w-screen-lg', 'my-4', 'animated', 'fade-in-up', 'p-2', 'referral-tooltip', { 'in-front': isTutorial }]" v-if="!isLoading">
      <ChangeActingUserDropdown titleText="Setting Availability Of:" :selectedUser="actingUser" v-if="isOrgUnitHead(loggedInUser)" :orgUnitUsers="orgUnitUsers" @actingUserChanged="updateViewedAvailability" />
      <div class="px-6" v-if="isOrgUnitHead(loggedInUser) && orgUnitUsers && orgUnitUsers.length === 0">
        <Message severity="info" class="w-full text-center"> You do not have any consultant in your practice group. Add new users in the users tab. </Message>
      </div>
      <template v-if="tutorialStatus == 'org-select-user'">
        <div v-if="tutorialFit()" class="referral-tooltiptext org-schedule-select-user-tooltip py-4 px-6">
          <h1 class="text-left text-xl font-bold text-white">
            <div>Choose a User</div>
          </h1>
          <br />
          <div class="text-left font-bold text-white">
            <p>Select the user you would like to set availability for.</p>
          </div>
          <br />
          <div class="flex flex-row justify-between">
            <button class="text-xs font-bold text-white" @click="endSpecialistTutorial" id="skipSpecialistTutorialBtnUpper">Skip Tutorial</button>
            <div class="text-xs font-bold text-white">1/3</div>
          </div>
        </div>
      </template>
      <div class="p-2 md:p-5" v-if="actingUser !== null && actingUser.id !== 0">
        <div class="mt-1 mb-8 rounded border border-solid border-gray-200 text-left">
          <div class="w-full flex justify-between items-center border-b border-solid border-gray-200 p-3">
            <h1 class="fc-toolbar-title">Recurring Weekly Schedule <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="8" /></h1>
          </div>

          <div class="flex flex-col p-2">
            <div class="border-b border-solid border-gray-200 px-1 pt-1">
              <div class="info-paragraph--gray mb-3">
                <p>Select recurring times you are able to be booked each week for consults.</p>
              </div>
            </div>

            <div v-for="day in daysOfTheWeek" :key="day" class="group w-full" name="daysOfTheWeek">
              <div :class="['flex', 'justify-between', 'p-4', { 'border-bottom': day !== 'Sunday' }]">
                <div
                  @click="
                    goToAddSchedule({
                      dates: day,
                      selectedIsRecurring: true,
                      dayOff: false,
                    })
                  "
                  class="cursor-pointer text-sm font-medium group-hover:underline"
                >
                  {{ day }}s
                </div>
                <div class="flex flex-row items-center">
                  <div v-for="scheduleDay in filterRecurring" :key="scheduleDay">
                    <div v-if="formatDateTimeToWeekday(scheduleDay[0].start_datetime).toString() === day" class="flex flex-row">
                      <span v-for="(schedule, i) in scheduleDay" :key="schedule.id" :class="['text-base', 'font-medium', 'text-gray-400', 'hover:text-red-500', { 'time-divider': i !== 0 && i <= 2 }, { hidden: i > 2 }, 'p-1', 'hidden', 'sm:block ']">
                        <div v-if="i < 2">
                          {{ formatTime(schedule.start_datetime) }} -
                          {{ formatTime(schedule.end_datetime) }}
                          <span v-if="schedule.is_smart_path == 1">(SmartPath)</span>
                          <div v-if="i !== scheduleDay.length"></div>
                        </div>
                        <span v-else-if="i === 2"> +{{ scheduleDay.length - i }} More </span>
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-row pl-1">
                    <i
                      class="pi pi-pencil cursor-pointer px-2 text-blue-500 hover:text-red-300"
                      style="font-size: 1rem"
                      @click="
                        goToAddSchedule({
                          dates: day,
                          selectedIsRecurring: true,
                          dayOff: false,
                          isEditing: true,
                        })
                      "
                      v-if="isDayInSchedule(day)"
                      id="weeklyEdit"
                    ></i>
                    <i
                      class="pi pi-trash cursor-pointer px-2 text-red-500 hover:text-red-800"
                      style="font-size: 1rem"
                      @click="
                        goToDeleteSchedule({
                          dates: day,
                          selectedIsRecurring: true,
                          dayOff: false,
                        })
                      "
                      v-if="isDayInSchedule(day)"
                    ></i>
                    <i
                      class="pi pi-plus-circle h-full cursor-pointer px-2 text-green-500 hover:text-green-800"
                      style="font-size: 1rem"
                      @click="
                        goToAddSchedule({
                          dates: day,
                          selectedIsRecurring: true,
                          dayOff: false,
                        })
                      "
                      v-if="!isDayInSchedule(day)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5 rounded border border-solid border-gray-200 text-left mb-8">
          <div class="w-full border-b border-solid border-gray-200 p-3">
            <h1 class="fc-toolbar-title">Holidays &amp; Days Off <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="8" /></h1>
          </div>
          <div class="p-3">
            <div class="info-paragraph--gray mb-3">
              <p>Specify days off that will supercede your daily schedule set below.</p>
            </div>
            <div v-for="(schedulesByYear, i) in groupByYearDaysOff" :key="i">
              <div class="flex flex-row items-center mb-3">
                <h1 class="flex flex-col text-lg font-semibold text-gray-400">
                  {{ formatToYear(schedulesByYear[0][0].start_datetime) }}
                </h1>
                <hr class="m-1 flex h-1 w-full flex-col rounded border-0 bg-gray-200 text-gray-200" />
              </div>
              <div class="schedule-grid grid gap-3 justify-center sm:justify-start">
                <div v-for="groupedSchedule in schedulesByYear" :key="groupedSchedule" class="date-card relative w-36 text-center">
                  <div class="transform transition duration-500 ease-in-out hover:-translate-y-1">
                    <DateCard @goToAddSchedule="goToAddSchedule" @goToDeleteSchedule="goToDeleteSchedule" :groupedSchedule="groupedSchedule" :dayOfWeek="null" :dayOff="true" />
                  </div>
                </div>
                <div
                  class="mt-5 flex h-32 w-36 cursor-pointer flex-col border-4 border-dotted border-gray-300 p-6 hover:border-solid hover:border-red-500"
                  @click="
                    goToAddSchedule({
                      dates: null,
                      selectedIsRecurring: false,
                      dayOff: true,
                    })
                  "
                  id="addDayOff"
                >
                  <div class="flex justify-center align-middle">
                    <div class="flex justify-center rounded-full bg-gray-200 p-2">
                      <i class="pi pi-plus-circle rounded-full bg-white text-red-500" style="font-size: 1.875rem; font-weight: 700"></i>
                    </div>
                  </div>
                  <div class="flex justify-center align-middle text-xl text-red-500">Add Day</div>
                </div>
              </div>
            </div>
            <div
              class="flex h-32 w-36 cursor-pointer flex-col border-4 border-dotted border-gray-300 p-6 hover:border-solid hover:border-red-500"
              @click="
                goToAddSchedule({
                  dates: null,
                  selectedIsRecurring: false,
                  dayOff: true,
                })
              "
              v-if="groupByYearDaysOff.length === 0"
              id="addDayOff"
            >
              <div class="flex justify-center align-middle">
                <div class="flex justify-center rounded-full bg-gray-200 p-2">
                  <i class="pi pi-plus-circle rounded-full bg-white text-red-500" style="font-size: 1.875rem; font-weight: 700"></i>
                </div>
              </div>
              <div class="flex justify-center align-middle text-xl text-red-500">Add Day</div>
            </div>

            <div v-if="error != ''" class="error">
              {{ error }}
            </div>
          </div>
        </div>

        <div id="dailyScheduleWrapper" class="rounded border border-solid border-gray-200 text-left mt-5">
          <div class="w-full border-b border-solid border-gray-200 p-3">
            <h1 class="fc-toolbar-title">Individual Daily Schedule <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="8" /></h1>
          </div>
          <div class="p-3">
            <div class="info-paragraph--gray mb-3">
              <p>Select specific individual dates and times you are available for consults</p>
            </div>
            <div v-for="(schedulesByYear, i) in groupByYear" :key="i">
              <div class="flex flex-row items-center">
                <h1 class="flex flex-col text-lg font-semibold text-gray-400">
                  {{ formatToYear(schedulesByYear[0][0].start_datetime) }}
                </h1>
                <hr class="m-1 flex h-1 w-full flex-col rounded border-0 bg-gray-200 text-gray-200" />
              </div>
              <div class="schedule-grid grid gap-3 pt-3">
                <div v-for="groupedSchedule in schedulesByYear" :key="groupedSchedule" class="date-card relative w-36 text-center">
                  <div class="transform transition duration-500 ease-in-out hover:-translate-y-1">
                    <DateCard @goToAddSchedule="goToAddSchedule" @goToDeleteSchedule="goToDeleteSchedule" :groupedSchedule="groupedSchedule" :dayOfWeek="null" :dayOff="false" :isSmartPath="groupedSchedule[0].is_smart_path" />
                  </div>
                </div>
                <div
                  class="mt-5 flex h-32 w-36 cursor-pointer flex-col border-4 border-dotted border-gray-300 p-6 hover:border-solid hover:border-red-500"
                  @click="
                    goToAddSchedule({
                      dates: null,
                      selectedIsRecurring: false,
                      dayOff: false,
                    })
                  "
                  id="addDay"
                >
                  <div class="flex justify-center align-middle">
                    <div class="flex justify-center rounded-full bg-gray-200 p-2">
                      <i class="pi pi-plus-circle rounded-full bg-white text-red-500" style="font-size: 1.875rem; font-weight: 700"></i>
                    </div>
                  </div>
                  <div class="flex justify-center align-middle text-xl text-red-500">Add Day</div>
                </div>
              </div>
            </div>
            <div
              class="flex h-32 w-36 cursor-pointer flex-col border-4 border-dotted border-gray-300 p-6 hover:border-solid hover:border-red-500"
              @click="
                goToAddSchedule({
                  dates: null,
                  selectedIsRecurring: false,
                  dayOff: false,
                })
              "
              id="addDay"
              v-if="groupByYear.length === 0"
            >
              <div class="flex justify-center align-middle">
                <div class="flex justify-center rounded-full bg-gray-200 p-2">
                  <i class="pi pi-plus-circle rounded-full bg-white text-red-500" style="font-size: 1.875rem; font-weight: 700"></i>
                </div>
              </div>
              <div class="flex justify-center align-middle text-xl text-red-500">Add Day</div>
            </div>

            <div v-if="error != ''" class="error">
              {{ error }}
            </div>
          </div>
        </div>

        <template v-if="tutorialStatus == 'set-availability'">
          <span class="referral-tooltiptext py-4 px-6" style="left: -140px; top: 75px">
            <h1 class="text-left text-xl font-bold text-white">
              <div>Set your availability</div>
            </h1>
            <br />
            <div class="text-left font-semibold text-white">
              <p>You can set a recurring schedule for any day of the week by clicking the green plus icon.</p>
            </div>
            <br />
            <div class="flex flex-row justify-between">
              <button class="text-xs font-bold text-white" @click="endSpecialistTutorial" id="skipSpecialistTutorialBtnUpper">Skip Tutorial</button>
              <button v-if="loggedInUser.onboarding_form.status === 'Editing'" class="book-a-consult-new-feature-tool-tip-btn text-xs font-bold text-white" @click="goToVerification" id="bookConsultTutorialBtn" v-tooltip.bottom="nextButtonMessage">NEXT</button>
            </div>
          </span>

          <span class="referral-tooltiptext-lower py-4 px-6" style="left: -140px; top: 625px">
            <div class="text-left font-bold text-white">
              <p>
                You can also select specific dates and times that you are available for phone consults.<br />
                Consult length can be adjusted in the ‘Account’ tab. <br />
              </p>
            </div>
            <br />
            <div class="flex flex-row justify-between">
              <button class="text-xs font-bold text-white" @click="endSpecialistTutorial" id="skipSpecialistTutorialBtnLower">Skip Tutorial</button>
              <div class="text-xs font-bold text-white">3/3</div>
            </div>
          </span>
        </template>
      </div>
    </div>
    <div class="bg-white rounded-lg w-full shadow max-w-screen-lg mb-4 animated fade-in-up p-4 md:p-8">
      <EditSpecialistPreference :loggedInUser="loggedInUser" />
    </div>

    <AddSchedule ref="addSchedule" :selectedSchedule="selectedSchedule" :selectedIsRecurring="selectedIsRecurring" :dayOff="dayOff" :isEditing="isEditing" @closeModal="closeModal" />

    <ConfirmDeleteSchedule ref="confirmDeleteSchedule" :selectedSchedule="selectedSchedule" @closeModal="closeModal" />
  </div>

  <div>
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import AddSchedule from './AddSchedule/AddSchedule.vue';
import DateCard from '@/components/schedule/DateCard.vue';
import Loading from 'vue-loading-overlay';
import ChangeActingUserDropdown from '@/components/org_unit/ChangeActingUserDropdown.vue';
import moment from 'moment';
import ConfirmDeleteSchedule from './ConfirmDeleteSchedule.vue';
import Message from 'primevue/message';
import Button from 'primevue/button';

export default {
  data() {
    return {
      error: '',
      daysOfTheWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      selectedSchedule: null,
      selectedIsRecurring: false,
      dayOff: null,
      selectedUser: null,
      isLoading: false,
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'schedules', 'groupedSchedules', 'getUserId', 'isOrgUnitHead', 'actingUser', 'orgUnitUsers', 'tutorialStatus', 'getUserActionByName']),
    isTutorial() {
      return this.tutorialStatus != '';
    },
    filterSingleDays() {
      return this.groupedSchedules.filter((schedules) => {
        return !schedules[0].is_recurring && schedules[0].start_datetime.getTime() !== schedules[0].end_datetime.getTime();
      });
    },
    filterSingleDaysOff() {
      return this.groupedSchedules.filter((schedules) => {
        return !schedules[0].is_recurring && schedules[0].start_datetime.getTime() === schedules[0].end_datetime.getTime();
      });
    },
    filterRecurring() {
      return this.groupedSchedules.filter((schedules) => schedules[0].is_recurring);
    },
    groupByYear() {
      let groupedMap = {};
      for (let schedule of this.filterSingleDays) {
        let year = moment(schedule[0].start_datetime).format('YYYY');
        if (groupedMap[year]) {
          groupedMap[year].push(schedule);
        } else {
          groupedMap[year] = [schedule];
        }
      }
      let keys = Object.keys(groupedMap).sort(this.compareYear);
      if (keys.length > 1) {
        keys.sort(this.compareYear);
      }
      let result = [];
      for (let key of keys) {
        result.push(groupedMap[key]);
      }
      return result;
    },
    groupByYearDaysOff() {
      let groupedMap = {};
      for (let schedule of this.filterSingleDaysOff) {
        let year = moment(schedule[0].start_datetime).format('YYYY');
        if (groupedMap[year]) {
          groupedMap[year].push(schedule);
        } else {
          groupedMap[year] = [schedule];
        }
      }
      let keys = Object.keys(groupedMap).sort(this.compareYear);
      if (keys.length > 1) {
        keys.sort(this.compareYear);
      }
      let result = [];
      for (let key of keys) {
        result.push(groupedMap[key]);
      }
      return result;
    },
    nextButtonMessage() {
      if (this.schedules.length === 0) {
        return 'Not ready to set your availability? Let’s verify your account.';
      }
      return 'Lets get your account verified to start accepting consults!';
    },
  },
  components: {
    AddSchedule,
    DateCard,
    ConfirmDeleteSchedule,
    Loading,
    ChangeActingUserDropdown,
    Message,
    Button,
  },
  methods: {
    ...mapActions(['getUpcomingScheduleOfUserForSpecialist', 'setSelectedUserToUpdateSchedule', 'groupSameDateSchedules', 'getOrgUnitSpecialists']),
    ...mapMutations(['setActingUser', 'setTutorialStatus']),
    compareYear(a, b) {
      return a - b;
    },
    endSpecialistTutorial() {
      this.updateUserTutorialByName(this.loggedInUser, 'set_availability', true, 'Skipped');
      this.setTutorialStatus('');
    },
    goToAddSchedule(event) {
      this.selectedIsRecurring = event.selectedIsRecurring;
      this.dayOff = event.dayOff;
      this.isEditing = event.isEditing;
      if (event.selectedIsRecurring) {
        this.selectedSchedule = null;
        for (let scheduleDay of this.filterRecurring) {
          if (this.formatDateTimeToWeekday(scheduleDay[0].start_datetime).toString() === event.dates) {
            this.selectedSchedule = scheduleDay;
          }
        }
        if (this.selectedSchedule === null) {
          this.selectedSchedule = this.getNextDayOfWeek(event.dates);
        }
      } else {
        this.selectedSchedule = event.dates;
      }
      this.$refs.addSchedule.openAddScheduleModal();
    },
    goToDeleteSchedule(event) {
      this.selectedIsRecurring = event.selectedIsRecurring;
      this.dayOff = event.dayOff;
      if (event.selectedIsRecurring) {
        this.selectedSchedule = null;
        for (let scheduleDay of this.filterRecurring) {
          if (this.formatDateTimeToWeekday(scheduleDay[0].start_datetime).toString() === event.dates) {
            this.selectedSchedule = scheduleDay;
          }
        }
      } else {
        this.selectedSchedule = event.dates;
      }
      this.$refs.confirmDeleteSchedule.openDeleteScheduleModal();
    },
    closeModal() {
      this.selectedSchedule = null;
      this.$toast.removeAllGroups();
      this.getUpcomingScheduleOfUserForSpecialist(this.actingUser.id)
        .then((res) => {
          if (res.status == 200) {
            this.groupSameDateSchedules(this.schedules);
          }
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        });
    },
    getNextDayOfWeek(dayOfWeek) {
      let today = moment().toDate();
      let nextDayOfWeek = moment().isoWeekday(dayOfWeek).add(1, 'hours').toDate();
      if (nextDayOfWeek.getTime() < today.getTime()) {
        nextDayOfWeek = moment(nextDayOfWeek).add(1, 'weeks').toDate();
      }
      return [
        {
          start_datetime: new Date(nextDayOfWeek.setHours(8, 0, 0, 0)),
          end_datetime: new Date(nextDayOfWeek.setHours(18, 0, 0, 0)),
        },
      ];
    },
    isDayInSchedule(dayOfWeek) {
      let recurringSchedules = this.filterRecurring.find((schedules) => this.formatDateTimeToWeekday(schedules[0].start_datetime).toString() === dayOfWeek);
      return recurringSchedules;
    },
    setupSchedules() {
      if (this.actingUser.id !== 0) {
        this.isLoading = true;
        this.setSelectedUserToUpdateSchedule(this.actingUser)
          .then(() => {
            this.getUpcomingScheduleOfUserForSpecialist(this.actingUser.id)
              .then((res) => {
                if (res.status == 200) {
                  this.groupSameDateSchedules(this.schedules);
                  this.isLoading = false;
                }
              })
              .catch(() => {
                this.showGenericErrorToast();
                this.isLoading = false;
              });
          })
          .catch(() => {
            this.showGenericErrorToast();
            this.isLoading = false;
          });
      }
    },
    updateViewedAvailability() {
      if (this.tutorialStatus !== '') {
        this.setTutorialStatus('set-availability');
      }
      this.setupSchedules();
    },
    addAvailabilityTutorialOnClick() {
      if (this.isOrgUnitHead(this.loggedInUser)) {
        this.setTutorialStatus('org-select-user');
      } else {
        this.setTutorialStatus('set-availability');
      }
    },
    goToVerification() {
      this.setTutorialStatus('verification');
      this.$router.push({ path: `/verification` });
    },
  },
  created() {
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.isLoading = true;
      this.getOrgUnitSpecialists(this.loggedInUser.org_units[0].id)
        .then(() => {
          this.setActingUser(null);
          this.isLoading = false;
          if (this.orgUnitUsers.length > 0) {
            let userTutorial = this.getUserActionByName(this.loggedInUser, 'set_availability');
            if (userTutorial && userTutorial.status == 'Active' && this.tutorialFit()) {
              this.setTutorialStatus('org-select-user');
            }
          }
        })
        .catch(() => {
          this.showGenericErrorToast();
          this.isLoading = false;
        });
    } else {
      this.setupSchedules();
    }
  },
};
</script>

<style>
.time-divider {
  @apply border-l border-solid border-gray-200;
}
.border-bottom {
  @apply border-b border-solid border-gray-200;
}
.date-card:hover .date-card-icon {
  visibility: visible;
}
.schedule-grid {
  grid-template-columns: repeat(auto-fit, minmax(150px, 160px));
  justify-items: center;
}
.referral-tooltip {
  position: relative;
}

.referral-tooltip .referral-tooltiptext {
  width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  top: 10px;
  left: -130px;
  margin-left: -70px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.referral-tooltip .referral-tooltiptext.org-schedule-select-user-tooltip {
  top: 0px;
}

.referral-tooltip .referral-tooltiptext-lower {
  width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 580px;
  left: -130px;
  margin-left: -70px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.referral-tooltip .referral-tooltiptext::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 102%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.referral-tooltip .referral-tooltiptext-lower::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 102%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.specialist-tooltip-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.in-front {
  position: relative;
  z-index: 100;
}
</style>
