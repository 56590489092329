<template>
  <div class="border border-gray-300 p-4 md:p-6 mt-4 rounded-lg bg-white shadow w-full max-w-2xl animated fade-in-up">
    <MSI :openBillingArrangement="openBillingArrangement" @closeBillingArrangementModal="closeBillingArrangementModal" v-if="hasProvinceOfPractice(actingUser, 'NS')" />
    <ABBA :openBillingArrangement="openBillingArrangement" @closeBillingArrangementModal="closeBillingArrangementModal" v-else-if="hasProvinceOfPractice(actingUser, 'AB')" />
    <OMHLTC :openBillingArrangement="openBillingArrangement" @closeBillingArrangementModal="closeBillingArrangementModal" v-else-if="hasProvinceOfPractice(actingUser, 'ON')" />
    <PEIBA :openBillingArrangement="openBillingArrangement" @closeBillingArrangementModal="closeBillingArrangementModal" v-else-if="hasProvinceOfPractice(actingUser, 'PE')" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MSI from '@/views/user/onboarding/onboardingForm/MSI.vue';
import ABBA from '@/views/user/onboarding/onboardingForm/ABBA.vue';
import OMHLTC from '@/views/user/onboarding/onboardingForm/OMHLTC.vue';
import PEIBA from '@/views/user/onboarding/onboardingForm/PEIBA.vue';

export default {
  props: ['billingArrangement'],
  components: {
    MSI,
    ABBA,
    OMHLTC,
    PEIBA,
  },
  data() {
    return {
      openBillingArrangement: true,
      isLoading: false,
      eTransferEmail: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser', 'hasProvinceOfPractice', 'isOrgUnitHead', 'isPreboarding', 'requiresToUploadBillingArrangement', 'billingArrangement']),
  },
  created() {},
  methods: {
    ...mapActions(['updateUserAccountInfo']),
  },
};
</script>
<style lang=""></style>
