<template>
  <div class="flex flex-col lg:flex-row">
    <div v-if="existingSchedules.length > 0" class="flex flex-col w-full md:w-96 p-4">
      <div class="font-medium text-lg">
        Current Schedule on
        {{ formatDate(selectedDate) }}
      </div>
      <div v-if="existingSchedules[0].is_recurring" class="confirmAddScheduleDateCard">
        <DateCard @goToAddSchedule="null" :groupedSchedule="existingSchedules" :dayOfWeek="formatDateTimeToWeekday(existingSchedules[0].start_datetime)" />
      </div>
      <div v-else class="confirmAddScheduleDateCard">
        <DateCard @goToAddSchedule="null" :groupedSchedule="existingSchedules" :dayOfWeek="null" />
      </div>
    </div>
    <div class="flex flex-col p-4 w-full md:w-96">
      <div v-if="existingSchedules.length > 0" class="font-medium text-lg">Updated Schedule:</div>
      <div v-else class="font-medium text-lg">Please Confirm Schedule Below:</div>
      <div v-if="dayOff" class="confirmAddScheduleDateCard">
        <div class="flex flex-row justify-center mt-2" v-if="newSchedules.length > 1">
          <div class="flex flex-col w-full">
            From
            <DateCard @goToAddSchedule="null" :groupedSchedule="[newSchedules[0]]" :dayOfWeek="null" :dayOff="dayOff" />
          </div>
          <div>-</div>
          <div class="flex flex-col w-full">
            Till
            <DateCard @goToAddSchedule="null" :groupedSchedule="[newSchedules[newSchedules.length - 1]]" :dayOfWeek="null" :dayOff="dayOff" />
          </div>
        </div>
        <DateCard v-else @goToAddSchedule="null" :groupedSchedule="[newSchedules[0]]" :dayOfWeek="null" :dayOff="dayOff" />
      </div>
      <div class="confirmAddScheduleDateCard" v-else-if="isRecurring">
        <DateCard @goToAddSchedule="null" :groupedSchedule="newSchedules" :dayOfWeek="formatDateTimeToWeekday(newSchedules[0].start_datetime)" />
      </div>
      <div class="confirmAddScheduleDateCard" v-else>
        <DateCard @goToAddSchedule="null" :groupedSchedule="newSchedules" :dayOfWeek="null" />
      </div>
      <div v-if="isSmartPath" class="mt-2 text-left">&nbsp;* This will only be available within SmartPath.</div>
    </div>
    <br />
  </div>
  <div v-if="error != ''" class="error">
    {{ error }}
    <br />
  </div>
  <div class="p-4">
    <Button @click="confirmAddSchedule" class="flex w-full justify-center" id="confirmAddScheduleButton" name="confirmButton"> Confirm Add Schedule </Button>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Button from 'primevue/button';
import DateCard from '@/components/schedule/DateCard.vue';

export default {
  props: ['user', 'newSchedules', 'selectedDate', 'isRecurring', 'isSmartPath', 'dayOff', 'existingSchedules', 'selectedSchedule'],
  emits: ['closeAddScheduleModal'],
  data() {
    return {
      error: '',
      confirmNewSchedules: null,
      oldSchedules: null,
    };
  },
  components: {
    Button,
    DateCard,
  },
  methods: {
    ...mapActions(['addSchedule']),
    ...mapMutations(['setTutorialStatus']),
    confirmAddSchedule() {
      this.$toast.removeAllGroups();
      let promises = [];
      if (!this.dayOff) {
        promises.push(this.sendScheduleUpdateRequest(this.newSchedules));
      } else {
        for (let i = 0; i < this.newSchedules.length; i++) {
          promises.push(this.sendScheduleUpdateRequest([this.newSchedules[i]]));
        }
      }
      Promise.all(promises)
        .then(() => {
          this.updateUserTutorialByName(this.loggedInUser, 'set_availability', true, 'Completed');
          this.$emit('closeAddScheduleModal');
          this.$toast.add({
            severity: 'success',
            summary: 'Schedule Added',
            life: 5000,
          });
        })
        .catch((error) => {
          this.$emit('closeAddScheduleModal');
          let err = error.response;
          // Prommise.all() only handles one error even for multiple failed requests.
          if (err.status == 422) {
            for (const key in err.data.errors) {
              err.data.errors[key].forEach((element) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: element,
                  life: 10000,
                });
              });
            }
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          }
        });
    },
    sendScheduleUpdateRequest(schedules) {
      return this.addSchedule({
        schedules: schedules,
        schedule_id_to_overwrite: this.selectedSchedule ? this.selectedSchedule[0].schedule_id : null,
        isRecurring: this.isRecurring,
        isSmartPath: this.isSmartPath,
        userId: this.actingUser.id,
        dayOff: this.dayOff,
      });
    },
  },
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser', 'tutorialStatus']),
  },
};
</script>

<style>
/* The following are for hiding Edit and Delete icons form DateCard when user wants to confirm schedule */
.confirmAddScheduleDateCard #delete-individual-schedule {
  display: none;
}
.confirmAddScheduleDateCard #edit-individual-schedule {
  display: none;
}
</style>
