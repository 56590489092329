<template>
  <div class="footer-container">
    <div class="footer-content flex flex-col">
      <div class="footer-mobile-img-header">
        <img src="@/assets/Virtual-Hallway-Logo-Nav.png" alt="" class="w-full" />
      </div>
      <div class="footer-columns">
        <div class="footer-block flex w-full flex-col items-center text-center sm:w-2/5 sm:items-start sm:text-left">
          <div class="footer-img">
            <img src="@/assets/Virtual-Hallway-Logo-Nav.png" alt="" class="w-full" />
          </div>
          <div class="footer-heading">Easy and accessible phone consults</div>
          <!-- put contact support here on mobile -->
          <a class="btn btn--main w-48" href="/registration"> Get Started </a>
        </div>
        <div class="footer-block flex flex-col">
          <h4 class="footer-link-title">Menu</h4>
          <a href="https://virtualhallway.ca/primary-care" class="footer-link">
            <div>Family Physicians</div>
          </a>
          <a href="https://virtualhallway.ca/nurse-practitioners" class="footer-link">
            <div>Nurse Practitioners</div>
          </a>
          <a href="https://virtualhallway.ca/specialists" class="footer-link">
            <div>Specialists</div>
          </a>
          <a href="https://virtualhallway.ca/innovation-leaders" class="footer-link">
            <div>Innovation Leaders</div>
          </a>
          <a href="https://virtualhallway.ca/security/" class="footer-link">
            <div>Security</div>
          </a>
          <a href="https://virtualhallway.ca/about/" class="footer-link">
            <div>About</div>
          </a>
          <a href="https://virtualhallway.ca/contact/" class="footer-link">
            <div>Contact</div>
          </a>
        </div>
        <div class="footer-block flex flex-col">
          <h4 class="footer-link-title">Resources</h4>
          <a href="https://virtualhallway.ca/primary-care-resources" class="footer-link">
            <div>Primary Care</div>
          </a>
          <a href="https://virtualhallway.ca/specialist-resources" class="footer-link">
            <div>Specialists</div>
          </a>
          <a href="https://app.virtualhallway.ca/lectures" class="footer-link">
            <div>CME Lectures</div>
          </a>
          <a href="https://virtualhallway.ca/faq" class="footer-link">
            <div>FAQ</div>
          </a>
          <a href="https://virtualhallway.ca/blog" class="footer-link">
            <div>Blog</div>
          </a>
          <a href="https://virtualhallway.canews" class="footer-link">
            <div>News</div>
          </a>
          <a href="https://virtualhallway.ca/white-papers/" class="footer-link">
            <div>White Papers</div>
          </a>
        </div>
        <div class="footer-block flex flex-col">
          <h4 class="footer-link-title">Account</h4>
          <a href="/registration" class="footer-link">
            <div>Register</div>
          </a>
          <a href="/login" class="footer-link">
            <div>Log in</div>
          </a>
          <a href="https://virtualhallway.ca/contact/" class="footer-link">
            <div>Help</div>
          </a>
        </div>
      </div>
      <div class="footer-row">
        <div><a class="footer-link" href="mailto:support@virtualhallway.ca" target="_self"> support@virtualhallway.ca </a></div>
        <div>
          <a href="https://www.facebook.com/virtualhallway" target="_blank" class="footer-link mr-4"> Facebook </a>
          <a href="https://www.linkedin.com/company/virtual-hallway" target="_blank" class="footer-link"> Linkedin </a>
        </div>
      </div>
      <div class="footer-row">
        <span class="footer-bottom-text">© 2024 Virtual Hallway</span>
        <div class="footer-row-right">
          <a href="https://virtualhallway.ca/privacy-policy/" class="footer-bottom-text">
            <div>General Privacy Policy</div>
          </a>
          <a href="https://virtualhallway.ca/platform-privacy-policy/" class="footer-bottom-text">
            <div>Platform Privacy Policy</div>
          </a>
          <a href="https://virtualhallway.ca/terms-of-service/" class="footer-bottom-text">
            <div>Terms Conditions</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer-container {
  background-color: var(--vh-black);
}
.footer-content {
  align-items: flex-start;
  padding-top: 75px;
  padding-bottom: 0;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}
.footer-columns {
  padding-bottom: 20px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
}
#footer-main-content-block {
  width: 40%;
}
.footer-block {
  width: 20%;
  padding: 20px;
  flex-wrap: nowrap;
  align-items: flex-start;
  text-align: start;
}
.footer-img {
  width: 300px;
}
.footer-heading {
  color: var(--vh-white);
  font-size: 20px;
  margin-top: 12px;
  margin-bottom: 18px;
  line-height: 1.4;
}

.footer-link-title {
  color: var(--vh-white);
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  line-height: 1.1;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  border-bottom-color: var(--vh-white);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.footer-link {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-top: 0px;
  margin-bottom: 12px;
  overflow: hidden;
}
a.footer-link {
  font-size: 16px;
  margin-bottom: 10px;
  font-family: 'Poppins';
}
.footer-link:hover {
  color: var(--vh-white);
}
.footer-row {
  width: 100%;
  align-items: center;
  text-align: justify;
  padding-bottom: 10px;
  padding-top: 10px;
  flex-direction: row;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  border-top-color: rgba(255, 255, 255, 0.1);
  border-top-width: 1px;
  border-top-style: solid;
}
.footer-row:nth-last-child(1) {
  padding-top: 18px;
  padding-bottom: 18px;
}
.footer-bottom-text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}
a.footer-bottom-text:hover {
  color: var(--vh-white);
}
.footer-row-right {
  margin-left: auto;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.footer-row-right .footer-bottom-text {
  margin-left: 24px;
}

.footer-mobile-img-header {
  flex-direction: unset;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 230px;
}

@media (max-width: 991px) {
  .footer-img {
    display: none;
  }
  .footer-link-title {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .footer-mobile-img-header {
    display: none;
  }
}

@media (max-width: 479px) {
  .footer-columns {
    flex-direction: column;
  }
  #footer-main-block {
    width: 100%;
  }
  #footer-empty-block {
    display: none;
  }
  .footer-columns .btn {
    width: 100%;
  }
  .footer-content {
    padding-top: 45px;
  }
  .footer-heading {
    margin-top: 45px;
  }
}

@media (max-width: 650px) {
  .footer-block {
    align-items: start;
    text-align: center;
    padding-top: 7px;
    width: 100%;
  }
  .footer-link-title {
    width: 100%;
  }
  .footer-row {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .footer-bottom-text {
    padding-bottom: 10px;
  }
  .footer-row-right {
    margin-left: auto;
    margin-right: auto;
  }
  .footer-row-right .footer-bottom-text {
    margin-left: 0px;
    margin-right: 10px;
  }
}
</style>
