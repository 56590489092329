<template>
  <Dialog header="Request a Service" :breakpoints="getDialogBreakPoints" :style="{ width: '500px' }" v-model:visible="visible" @hide="closeRequestServiceModal" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" class="p-0" id="specialistServiceRequestModal">
    <div class="flex flex-col p-4">
      <div class="info-paragraph--gray flex flex-row items-center justify-center">
        <i class="pi pi-exclamation-triangle pr-2" style="font-size: 1rem" />
        <p class="text-left">By requesting a service, the {{ getReferralPersonTerm(actingUser) }} will be notified via text to add in availability.</p>
      </div>
      <h1 class="mb-4">Select the service(s) you want {{ displayNameByRole(selectedSpecialist) }} to be availability for.</h1>
      <div class="grid col-auto gap-6 mb-6">
        <div v-for="(serviceForRequest, index) in availableServicesForRequest" :key="serviceForRequest" :id="`claimType-${index}`">
          <div v-if="!availableServices.includes(serviceForRequest.service)" @click="pushOrPopToSelectedServicesForRequest(serviceForRequest.service)" :aria-label="'requestService-' + serviceForRequest.name" class="h-20" :class="[{ 'selected-service-for-request-button': selectedServicesForRequest.find((service) => service == serviceForRequest.service) }, 'select-button-small border-hover--blue']">
            <div :aria-label="serviceForRequest.name">{{ serviceForRequest.name }}</div>
          </div>
          <div v-else class="h-20 select-button-small hover:cursor-default" :aria-label="'requestService-' + serviceForRequest.name">
            <div class="text-gray-300" :aria-label="serviceForRequest.name">{{ serviceForRequest.name }}</div>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-end w-full">
        <Button :disabled="selectedServicesForRequest.length < 1" label="Submit" @click="submitServiceRequest" id="submitRequestServicesBtn" />
      </div>
    </div>
  </Dialog>

  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';
import { mapGetters, mapActions } from 'vuex';

export default {
  inject: ['mixpanel'],
  components: {
    Dialog,
    Button,
    Loading,
  },
  emits: ['closeRequestServiceModal'],
  props: ['selectedSpecialist', 'showRequestServiceModal', 'availableServices'],
  data() {
    return {
      isLoading: false,
      availableServicesForRequest: [
        { service: 'Phone Consult', name: 'Phone Consult' },
        { service: 'On Demand Phone Consult', name: 'On Demand Phone Consult' },
        // { service: 'eConsult', name: 'eConsult' },
      ],
      selectedServicesForRequest: [],
      visible: false,
    };
  },
  created() {
    this.visible = true;
  },
  methods: {
    ...mapActions(['requestSpecialistService']),
    submitServiceRequest() {
      this.isLoading = true;
      let data = {
        requester_id: this.actingUser.id,
        requested_id: this.selectedSpecialist.id,
        requested_services: this.selectedServicesForRequest,
      };

      this.requestSpecialistService(data)
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Services Requested',
            detail: 'The user has been notified to add the requested services.',
            life: 10000,
          });
          this.closeRequestServiceModal();
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    closeRequestServiceModal() {
      this.visible = false;
      this.$emit('closeRequestServiceModal');
    },
    pushOrPopToSelectedServicesForRequest(service) {
      if (this.selectedServicesForRequest.includes(service)) {
        let index = this.selectedServicesForRequest.indexOf(service);
        this.selectedServicesForRequest.splice(index, 1);
      } else {
        this.selectedServicesForRequest.push(service);
      }
    },
  },
  computed: {
    ...mapGetters(['actingUser', 'loggedInUser']),
    getDialogBreakPoints() {
      return { '960px': '75vw', '640px': '90vw' };
    },
  },
};
</script>
