<template lang="">
  <div class="flex flex-col w-1/2 h-96 mx-auto" id="notifyUserFromTextMessageParentDiv">
    <div class="bg-white rounded my-auto p-12 flex flex-col justify-center" v-if="errors.length > 0">
      <div class="p-5">
        <i class="pi pi-exclamation-circle text-yellow-500" style="font-size: 3rem"></i>
      </div>
      <div class="font-semibold">
        <ul v-for="(item, index) in errors" :key="index">
          <li>{{ item }}</li>
        </ul>
      </div>
    </div>
    <div class="bg-white rounded my-auto p-12 flex flex-col justify-center" v-if="success">
      <div class="p-5">
        <i class="pi pi-check-circle text-green-500" style="font-size: 3rem"></i>
      </div>
      <div class="font-semibold flex flex-col">
        <div>
          {{ success }}
        </div>
        <div class="my-5"><Button label="Click Here to Visit the Consult" @click="visitConsult()" /></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      success: '',
      referralId: null,
      errors: [],
    };
  },
  created() {
    let code = this.$route.params.code;
    this.sendNotificationToUserFromTextMessage(code)
      .then((res) => {
        this.success = res.data.message;
        this.referralId = res.data.data.referral_id;
      })
      .catch((err) => {
        if (err.status == 422) {
          this.errors = this.getAllMessagesFromError422(err);
        }
      });
  },
  methods: {
    ...mapActions(['sendNotificationToUserFromTextMessage']),
    visitConsult() {
      this.$router.replace(`/referral/${this.referralId}`);
    },
  },
};
</script>
<style lang=""></style>
