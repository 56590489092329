<template>
  <div class="mb-2 grid grid-cols-2 gap-2 text-left" v-if="referral.status !== 'Pending'" id="showReferralFormParentDiv">
    <div class="col-start-1 col-end-3 border-b border-solid border-gray-100 pb-1">
      <div class="consult-field-label">Consult Question</div>
      <div class="consult-field-text">
        <pre v-if="referralForm.referral_question"
          >{{ referralForm.referral_question }}
        </pre>
        <p v-else>N/A</p>
      </div>
    </div>
    <div class="col-start-1 col-end-3 border-b border-solid border-gray-100 pb-1" v-for="(fieldData, fieldName) in referralForm.form" :key="fieldName">
      <div class="consult-field-label">
        {{ fieldName }}
        <br />
      </div>
      <div class="consult-field-text">
        <template v-if="fieldData['type'] === 'search_select'">
          <span v-for="(field, i) in fieldData['value']" :key="i">
            <span>{{ field }} </span>
            <span v-if="fieldData['value'].length - 1 !== i">,&nbsp;</span>
          </span>
          <span v-if="fieldData['value'].length === 0"> N/A </span>
        </template>

        <template v-if="fieldData['type'] === 'select'">
          <!-- Old referral forms do not have the meta_data, for those forms we can only show the selected values.
          Otherwise, show all items with disabled checkboxes. -->
          <div v-if="fieldData['meta_data']" class="mt-3 grid grid-cols-1 gap-x-1 text-xs md:grid-cols-2">
            <div class="p-field-checkbox" v-for="(val, i) in JSON.parse(fieldData['meta_data'])" :key="i" align="left">
              <template v-if="fieldData['value'].includes(val)">
                <!-- <input type="checkbox" :value="val" :id="fieldName + val" checked="checked" onclick="return false;" style="cursor: auto" /> -->
                <!-- <i class="pi pi-check-square text-primary" /> -->
                <!-- <i class="fa fa-square-check text-primary" style="font-size: 1rem" /> -->
                <i class="fas fa-square-check text-primary" style="font-size: 1.15rem" />
              </template>
              <template v-else>
                <!-- <input type="checkbox" :value="val" :id="fieldName + val" disabled style="cursor: auto" /> -->
                <i class="pi pi-stop text-gray-300" />
              </template>
              <label :for="fieldName + val" class="ml-2">{{ val }}</label>
            </div>
          </div>
          <div v-else>
            <span v-for="(field, i) in fieldData['value']" :key="i">
              <span>{{ field }} </span>
              <span v-if="fieldData['value'].length - 1 !== i">,&nbsp;</span>
            </span>
            <span v-if="fieldData['value'].length === 0"> N/A </span>
          </div>
        </template>

        <template v-if="fieldData['type'] === 'date'">
          <div v-if="fieldData['value']">
            {{ formatDateYYYYMMDD(fieldData['value']) }}
          </div>
          <div v-else>N/A</div>
        </template>
        <template v-if="fieldData['type'] === 'string' || fieldData['type'] === 'radio_button'">
          <p v-if="fieldData['value'] != null">
            {{ fieldData['value'] }}
          </p>
          <p v-else>N/A</p>
        </template>
        <template v-if="fieldData['type'] === 'text'">
          <pre v-if="fieldData['value'] != null"
            >{{ fieldData['value'] }}
        </pre
          >
          <p v-else>N/A</p>
        </template>
        <template v-if="fieldData['type'] === 'file_uploads'">
          <div class="mb-2" v-if="fieldData['value'].length > 0">
            <div v-for="(image, index) in fieldData.value" :key="index" class="flex flex-row justify-between items-center pt-1 pb-2 mb-2 border-b last:border-0">
              <p>File {{ index + 1 }}</p>
              <Button @click="viewFormFieldFile(fieldName, index)" label="View" class="p-button-xs p-button-secondary" />
            </div>
            <Button @click="downloadFormFieldFiles(fieldName)" label="Download All" id="download_all" class="p-button-sm" />
          </div>
          <p v-else>N/A</p>
        </template>
      </div>
    </div>
    <template v-if="completeView && attachedReferrals.length > 0">
      <div class="col-start-1 col-end-3">
        <div class="mb-2 text-xs font-normal text-gray-400">
          Patient Previous Consults
          <br />
        </div>
        <AttachPatientTable :referrals="attachedReferrals" :key="attachedReferrals.length" :selectionView="false" />
      </div>
    </template>
    <div class="col-start-1 col-end-3">
      <AddendumsComponent :type="'referral_form'" :referral="referral" :addendums="referralFormAddendums" :responseForm="referral.referral_response_form" />
    </div>
  </div>

  <div v-else>
    <div class="flex flex-col sm:flex-row">
      <div class="text-left sm:w-full" style="padding: 0">
        <div class="my-1 flex flex-row">
          <div class="align-center mr-2 flex items-center justify-center rounded bg-blue-50" style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-user text-left text-xl text-blue-400"></i>
          </div>
          <div class="mb-2 flex flex-col items-start border-b border-solid border-gray-100 py-1 md:border-none md:py-0">
            <div class="mb-1 text-xs font-normal uppercase text-gray-400">Referring Practitioner:</div>
            <div class="text-sm font-semibold text-blue-400">{{ referral.referee.full_name }}</div>
          </div>
        </div>
        <div class="my-1 flex flex-row">
          <div class="align-center mr-2 flex items-center justify-center rounded bg-blue-50" style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-phone text-left text-xl text-blue-400"></i>
          </div>
          <!-- When does this show up? -->
          <div class="mb-2 flex flex-col items-start border-solid border-gray-100 py-1 md:border-none md:py-0">
            <div class="mb-1 text-xs font-normal uppercase text-gray-400">Referring Practitioner Phone:</div>
            <div class="text-sm font-semibold text-blue-400">{{ referral.referee.phone_number }}</div>
          </div>
        </div>
      </div>
      <div class="text-left sm:w-full" style="padding: 0">
        <div class="my-1 flex flex-row">
          <div class="align-center mr-2 flex items-center justify-center rounded bg-blue-50" style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-calendar text-left text-xl text-blue-400"></i>
          </div>
          <div class="mb-2 flex flex-col items-start border-b border-solid border-gray-100 py-1 md:border-none md:py-0">
            <div class="mb-1 text-xs font-normal uppercase text-gray-400">Consult Date:</div>
            <div class="text-sm font-semibold text-blue-400">{{ formatDate(referral.start_datetime) }} {{ getUserTimeZoneAbbr() }}</div>
          </div>
        </div>
        <div class="my-1 flex flex-row">
          <div class="align-center mr-2 flex items-center justify-center rounded bg-blue-50" style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-clock text-left text-xl text-blue-400"></i>
          </div>
          <div class="mb-2 flex flex-col items-start border-b border-solid border-gray-100 py-1 md:border-none md:py-0">
            <div class="mb-1 text-xs font-normal uppercase text-gray-400">Consult Time:</div>
            <div class="text-sm font-semibold text-blue-400">{{ formatTime(referral.start_datetime) }} {{ getUserTimeZoneAbbr() }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 justify-center rounded-lg border-2 border-solid border-yellow-500 p-4 py-8 text-gray-500">
      <div class="mb-3 flex justify-center text-yellow-500">
        <i class="pi pi-clock" style="font-size: 3.5rem"></i>
      </div>
      <div class="text-sm text-gray-500">This consult has not yet been fully submitted by the referring practitioner, please wait for them to submit the required consultation information.</div>
    </div>
  </div>

  <Dialog :draggable="false" v-model:visible="showReferralImageDialog" :modal="true" :dismissableMask="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }">
    <img id="referral_image" class="mx-auto" :src="this.selectedImageUrl" />
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import AttachPatientTable from '@/components/referral/AttachPatientTable.vue';
import TextWithFileUploadInputComponent from '@/components/misc/TextWithFileUploadInputComponent.vue';
import moment from 'moment';

export default {
  name: 'ShowReferralForm',
  props: ['referral', 'completeView'],
  data() {
    return {
      removeDefaultFields: {},
      openAddendum: false,
      addendumText: '',
      isWithinWindowToSignOff: false,
      showReferralImageDialog: false,
    };
  },
  components: {
    Button,
    Textarea,
    AttachPatientTable,
    TextWithFileUploadInputComponent,
  },
  computed: {
    ...mapGetters(['referralForm', 'referralFormFields', 'loggedInUser', 'actingUser', 'referralAddendums', 'attachedReferrals']),
    removeDefaultFieldsFromReferral: function () {
      let removedDefault = {};
      for (const [key, value] of Object.entries(this.referral.referral_form.form)) {
        if (key !== 'Patient First Name' && key !== 'Patient Last Name' && key !== 'Health Card Number' && key !== 'Patient Date of Birth' && key !== 'Brief History' && key !== 'Referral Question' && key !== 'Prior Diagnosis' && key !== 'Medication List' && key !== 'Allergies') {
          removedDefault[key] = value;
        }
      }
      return removedDefault;
    },
    showMakeAddendumButton() {
      if (this.referral.interaction_type.name === 'phone') {
        return !this.openAddendum && (this.referral.status === 'Upcoming' || this.referral.status === 'In Review') && this.loggedInUser.id === this.referral.referee.id && (this.referral.interaction_type.name === 'phone' || this.referral.interaction_type.name === 'econsult');
      }
      return false;
    },
    referralFormAddendums() {
      return this.referralAddendums.filter((addendum) => addendum.type === 'referral_form');
    },
  },
  methods: {
    ...mapActions(['getReferralForm', 'getReferralFormFields', 'downloadFilesOfReferralFormField', 'viewFileOfReferralFormField', 'createReferralAddendum', 'signOffReferralForm', 'viewDownloadedFile', 'downloadAttachedFile']),
    downloadFormFieldFiles(fieldName) {
      this.$toast.removeAllGroups();
      this.downloadFilesOfReferralFormField({
        referralFormId: this.referralForm.id,
        fieldName: fieldName,
      })
        .then(() => {})
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        });
    },
    viewFormFieldFile(fieldName, index) {
      this.$toast.removeAllGroups();
      this.viewFileOfReferralFormField({
        referralFormId: this.referralForm.id,
        fieldName: fieldName,
        index: index,
      })
        .then((response) => {
          if (response.data.type == 'image/png' || response.data.type == 'image/jpg' || response.data.type == 'image/jpeg') {
            // Open image files in showReferralImageDialog
            this.selectedImageUrl = window.URL.createObjectURL(new Blob([response.data]));
            this.showReferralImageDialog = true;
          } else {
            let fileName = '';
            if (response.data.type == 'application/pdf') {
              fileName = `${fieldName}_${index}.pdf`;
            } else {
              fileName = `${fieldName}_${index}.docx`;
            }
            this.viewDownloadedFile({ downloadedFileContent: response.data, fileName: fileName })
              .then(() => {})
              .catch(() => {
                this.showGenericErrorToast();
              });
          }
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        });
    },
    processTabs(event) {
      let textarea = document.getElementById(event.target.id);
      let start = textarea.selectionStart;
      textarea.value = textarea.value.substring(0, textarea.selectionStart) + '\t' + textarea.value.substring(textarea.selectionStart, textarea.value.length);
      event.target.selectionStart = event.target.selectionEnd = start + 1;
    },
    signOff() {
      this.isLoading = true;
      this.$toast.removeAllGroups();
      this.signOffReferralForm(this.referral.referral_form.id)
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Consult Signed Off',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    getAllReferralData() {
      this.getReferralForm(this.referral)
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
      this.getReferralFormFields(this.referral.specialty.id)
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });

      if (!this.referral.referral_form.is_signed_off) {
        const thirtyDaysFromReferralCreated = moment(this.referral.created_at).add(30, 'days');
        const now = moment();
        this.isWithinWindowToSignOff = now.isBefore(thirtyDaysFromReferralCreated);
      }
      this.removeDefaultFields = this.removeDefaultFieldsFromReferral;
    },
  },
  created() {
    this.getAllReferralData();
  },
  watch: {
    referral: function () {
      // watch it
      this.getAllReferralData();
    },
  },
};
</script>

<style>
pre {
  white-space: pre-wrap;
  font-family: inherit;
  -moz-tab-size: 4; /* Firefox 4+ */
  -o-tab-size: 4; /* Opera 11.5 & 12.1 only */
  tab-size: 4; /* Chrome 21+, Safari 6.1+, Opera 15+ */
}
</style>
