<template>
  <Dialog header="Next Available Consult" :visible="showModal" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '40vw', maxWidth: '550px' }" :modal="true" :draggable="false" :closable="false" class="rounded-b-xl">
    <div class="border-1 border-gray-200 pt-4 pb-3 rounded-lg">
      <div class="h-4rem border-round flex-1 text-center font-semibold text-white">
        <div class="relative w-full">
          <div class="flex h-12 w-full flex-row items-center justify-end px-3 md:px-6">
            <div>
              <ViewBio :user="selectedSpecialist" />
            </div>
          </div>
        </div>
        <div class="position-relative -mt-14 flex flex-col items-center">
          <img v-bind:src="selectedSpecialist.profile.profile_picture_location" class="relative z-20 h-20 w-20 rounded-full border-4 border-solid border-white bg-white object-cover" v-if="selectedSpecialist.profile.profile_picture_location" />
          <div class="my-2 flex">
            <h3 class="text-blue text-sm font-semibold md:text-xl">{{ displayNameByRole(selectedSpecialist) }}</h3>
          </div>
        </div>
      </div>
      <div class="mx-2 mb-4">
        <div class="justify-left items-left w-full flex-row px-3 text-left">
          <p class="border-bottom pb-1 text-xs text-gray-500">Specialty</p>
          <p class="text-gray-600 font-display mt-1 text-sm font-semibold">
            {{ selectedSpecialty.name }}
          </p>
        </div>
        <div class="justify-left items-left mt-3 w-full flex-row px-3 text-left">
          <p class="border-bottom pb-1 text-xs text-gray-500">Date</p>
          <p class="text-gray-600 font-display mt-1 text-sm font-semibold">
            {{ formatDateTimeToSpecifiedFormat(start_datetime, 'DD MMM YYYY') }}
          </p>
        </div>
        <div class="justify-left items-left mt-3 w-full flex-row px-3 text-left">
          <p class="border-bottom pb-1 text-xs text-gray-500">Time</p>
          <p class="text-gray-600 font-display mt-1 text-sm font-semibold">
            {{ formatDateTimeToSpecifiedFormat(start_datetime, 'h:mmA') }}
            -
            {{ formatDateTimeToSpecifiedFormat(end_datetime, 'h:mmA') }} {{ getUserTimeZoneAbbr() }}
          </p>
        </div>
      </div>
      <p v-if="selectedSpecialist.specialist_preference.call_asap_optin == true" class="tip-title mx-5 mt-2 mb-2 text-xs"><i class="pi fas fa-bolt mr-1" style="font-size: 0.8rem"></i><strong>Get OnDemand</strong> will book this appointment as an <strong>OnDemand Consult</strong>, notifying the {{ getReferralPersonTerm(actingUser) }} that you are open to having the phone call before the specified booking time, potentially allowing your consult question to be answered more quickly.</p>
      <div class="mx-5 my-3 grid md:grid-cols-2 gap-3">
        <Button label="Schedule a Call" @click="confirmReferral" class="w-full p-button-sm p-button-secondary" :class="{ 'col-span-2': !selectedSpecialist.specialist_preference.call_asap_optin }" />
        <Button label="Get Call ASAP" v-if="selectedSpecialist.specialist_preference.call_asap_optin == true" icon="fas fa-bolt" @click="confirmAsapReferral" class="p-button-sm p-button-primary w-full" />
      </div>
    </div>

    <template #footer>
      <Button label="Close" icon="pi pi-times" @click="closeModal" class="p-button-secondary p-button-outlined" />
    </template>
  </Dialog>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import Dialog from 'primevue/dialog';
import ViewBio from '@/components/misc/ViewBio.vue';
import Button from 'primevue/button';

export default {
  inject: ['mixpanel'],
  props: ['selectedSpecialty', 'selectedSpecialist', 'selectedAvailabilitySlot', 'showModal'],
  emits: ['closeConfirmReferralModal', 'confirmReferral'],
  data() {
    return {
      error: '',
      start_datetime: '',
      end_datetime: '',
      isLoading: false,
      call_asap_val: '',
    };
  },
  components: {
    Loading,
    Dialog,
    ViewBio,
    Button,
  },
  computed: {
    ...mapGetters(['tutorialStatus', 'actingUser', 'loggedInUser']),
  },
  methods: {
    ...mapActions(['addReferral']),
    ...mapMutations(['setTutorialStatus']),
    goToSelectDate() {
      this.$emit('goToSelectDate');
    },
    closeModal() {
      this.$emit('closeConfirmReferralModal');
    },
    confirmReferral() {
      if (this.selectedSpecialist.id != this.actingUser.id) {
        this.mixpanel.track('Make Referral - Confirmed Book Next Availability', { specialty: this.selectedSpecialty.name, specialistId: this.selectedSpecialist.id, availabilitySlot: this.formatDateTimeToSpecifiedFormat(this.selectedAvailabilitySlot.start_datetime, 'MMM DD h:mmA', true) });
        this.call_asap_val = false;
        this.$emit('confirmReferral', this.call_asap_val);
      } else {
        this.showFailedToBookConsultToast();
      }
    },
    confirmAsapReferral() {
      this.mixpanel.track('Make Referral - Confirmed Book Next Availability - Call ASAP', { specialty: this.selectedSpecialty.name, specialistId: this.selectedSpecialist.id, availabilitySlot: this.formatDateTimeToSpecifiedFormat(this.selectedAvailabilitySlot.start_datetime, 'MMM DD h:mmA', true) });
      if (this.selectedSpecialist.id != this.actingUser.id) {
        this.call_asap_val = true;
        this.$emit('confirmReferral', this.call_asap_val);
      } else {
        this.showFailedToBookConsultToast();
      }
    },
    showFailedToBookConsultToast() {
      this.$toast.add({
        severity: 'warn',
        summary: 'Can Not Book Consult',
        detail: 'You can not book a consult with your own account, please select a different user.',
        life: 10000,
      });
    },
  },
  created() {
    this.start_datetime = moment.utc(this.selectedAvailabilitySlot.start_datetime).toDate();
    this.end_datetime = moment.utc(this.selectedAvailabilitySlot.end_datetime).toDate();
  },
};
</script>

<style>
.confirm-referral-content {
  padding: 0px 40px;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.confirm-referral-content-date-time,
.confirm-referral-content-text {
  text-align: center;
  width: 100%;
}
.flex flex-col {
  display: flex;
  flex-direction: column;
}
.confirm-referral-content-date-time {
  justify-content: space-evenly;
  align-items: center;
}
.confirm-referral-content-text:last-child(1) {
  margin-top: auto;
  margin-bottom: 0;
  height: 100%;
}

.auth-input--blue {
  color: #859dc4;
}
</style>
