<template>
  <div>
    <modal ref="uploadPhotoId" @closeModal="closeModal">
      <template v-slot:header>
        <div class="header">
          <div class="px-5 pt-5">
            <Button label="Close" icon="pi pi-times" class="p-button-secondary p-button-outlined p-button-sm" @click="closeUploadPhotoIdModal" />
          </div>
        </div>
      </template>

      <template v-slot:body>
        <div class="upload-photo-container max-w-5xl">
          <h1 class="text-3xl font-bold text-blue">Upload Your Photo ID</h1>
          <div class="seperator"></div>
          <div class="info-paragraph--gray">
            <p>Please upload a photo of your government-issued identification (Driver's License or Passport) by clicking on the box below. If you are logged in on your mobile device, you can tap on the upload box and take a picture with your camera.</p>
          </div>
          <FileUpload name="files[]" :maxFileSize="20000000" :fileLimit="1" accept="image/png, image/jpeg, image/jpg" @select="handleFileChange" :uploadLabel="'Submit'" :showCancelButton="false" :showUploadButton="false" chooseLabel="Select" @remove="remove" :key="rerender">
            <template #empty>
              <p class="text-sm">Drag and drop files to here to upload.</p>
            </template>
          </FileUpload>
          <div v-if="error"><span class="error">Please upload a Photo ID to submit</span><br /></div>
          <div class="mt-3 flex justify-end">
            <Button @click="submit" class="flex w-28 justify-center" :disabled="file === null || isLoading" id="uploadPhotoIdSubmit">Submit</Button>
          </div>
        </div>
      </template>
    </modal>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import Modal from '@/components/misc/Modal.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';
import Loading from 'vue-loading-overlay';

export default {
  emits: ['closeUploadPhotoIdModal'],
  props: ['openUploadPhotoId'],
  data() {
    return {
      error: false,
      isLoading: false,
      file: null,
      rerender: false,
    };
  },
  computed: {
    ...mapGetters(['getUserId', 'actingUser']),
  },
  methods: {
    ...mapActions(['uploadPhotoId']),
    ...mapMutations(['setTutorialStatus']),
    closeUploadPhotoIdModal() {
      this.$refs.uploadPhotoId.closeModal();
    },
    closeModal(event) {
      this.$emit('closeUploadPhotoIdModal', event);
    },
    submit() {
      if (this.file === null) {
        this.error = true;
      } else {
        const data = new FormData();
        data.append('file', this.file);
        data.append('user_id', this.actingUser.id);
        this.$toast.removeAllGroups();
        this.isLoading = true;
        this.uploadPhotoId(data)
          .then(() => {
            this.isLoading = false;
            this.$refs.uploadPhotoId.closeModal();
            this.$emit('closeUploadPhotoIdModal', { data: data, show: false });
            this.$toast.add({
              severity: 'success',
              summary: 'Photo ID Successfully Uploaded',
              life: 3000,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          });
      }
    },
    remove() {
      this.rerender = !this.rerender;
      this.file = null;
    },
    handleFileChange(event) {
      this.file = event.files[0];
    },
  },
  unmounted() {
    this.setTutorialStatus('');
  },
  components: {
    Modal,
    Button,
    FileUpload,
    Loading,
  },
  watch: {
    openUploadPhotoId(val) {
      if (val) {
        this.$refs.uploadPhotoId.openModal();
      }
    },
  },
};
</script>

<style>
.header {
  display: flex;
}
.upload-photo-container {
  height: 100%;
  padding: 20px;
}
</style>
