<template>
  <a @click="openEula" class="text-xs text-secondary underline cursor-pointer">End User License Agreement</a>
  <Dialog header="Virtual Hallway End User License Agreement" :breakpoints="{ '960px': '70vw', '640px': '90vw' }" style="max-width: 800px; width: 100%; padding-bottom: 48px" v-model:visible="show" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" class="euladialog p-0" id="eulaDialog">
    <div class="max-h-400 bg-gray-100 p-6 text-left text-sm" ref="eula" id="eula">
      <EulaArticle />
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="closeEula()" />
    </template>
  </Dialog>
</template>

<script>
import EulaArticle from '@/views/public/EulaArticle.vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
export default {
  components: {
    Button,
    Dialog,
    EulaArticle,
  },
  data() {
    return {
      isLoading: false,
      show: false,
    };
  },
  methods: {
    openEula() {
      this.show = true;
    },
    closeEula() {
      this.show = false;
    },
  },
};
</script>
<style scoped>
.page-body > div {
  margin-bottom: 10px;
}

#eulaDialog .p-dialog-content {
  padding: 0;
  height: 75vh;
}

#eula > ul,
#eula > ol {
  list-style: revert;
  margin-left: 1.5rem;
}
</style>
