<template>
  <div v-if="!isLoading && selectedTransaction !== null && loggedInUser" class="main-inner-wrap">
    <div class="align-start relative mt-4 flex w-full flex-row items-center justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <i class="pi pi-sitemap mb-2 mt-2 mr-5 text-white" style="font-size: 3rem"></i>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">SmartPath Result</h1>
        <h1 class="text-sm text-gray-200">Review this SmartPath</h1>
      </div>
    </div>

    <div class="align-start relative mt-4 flex w-full items-center justify-between rounded-2xl bg-white p-6 text-left shadow border-1">
      <div class="w-full">
        <div class="mb-3 flex flex-row items-start justify-between border-b pb-2">
          <div class="flex flex-row">
            <div class="text-blue-400">
              <h2 class="text-left font-semibold text-lg text-gray-600">Pathway Result Details</h2>
              <div class="flex flex-col text-left">
                <h5 class="text-xs text-gray-400">Created: {{ formatDateTime(selectedTransaction.created_at) }} {{ getUserTimeZoneAbbr() }}</h5>
                <h5 class="text-xs text-gray-400">Last Updated: {{ formatDateTime(selectedTransaction.updated_at) }} {{ getUserTimeZoneAbbr() }}</h5>
              </div>
            </div>
          </div>
          <div v-if="terminalNode != null && terminalNode.recommended_action" class="flex flex-row justify-center">
            <Button @click="faxSmartPathResultOnClick(selectedTransaction.id)" v-on:click.stop icon="pi pi-file-pdf" label="Send As Fax" class="p-button-sm !mr-2" />
            <Button @click="downloadSmartPathResult(selectedTransaction.id)" icon="pi pi-download" label="Download" class="p-button-sm" />
          </div>
        </div>

        <div class="mb-2 grid w-full gap-2 text-left sm:grid-cols-1 md:grid-cols-4">
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">SmartPath Result Status</div>
            <div class="font-bold text-gray-500 font-display text-sm">
              {{ selectedTransaction.status }}
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Patient Name</div>
            <div class="font-bold text-gray-500 font-display text-sm">
              <p v-if="selectedTransaction.patient_first_name">
                {{ selectedTransaction.patient_first_name }}
                {{ selectedTransaction.patient_last_name }}
              </p>
              <p v-else>N/A</p>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Patient Health Card Number</div>
            <div class="font-semibold text-gray-500 font-display text-sm">
              <p v-if="selectedTransaction.patient_health_card_number">{{ selectedTransaction.patient_health_card_number }}</p>
              <p v-else>N/A</p>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Patient Health Card Province</div>
            <div class="font-semibold text-gray-500 font-display text-sm">
              <p v-if="selectedTransaction.patient_health_card_province">{{ selectedTransaction.patient_health_card_province }}</p>
              <p v-else>Not Provided</p>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Patient Date of Birth</div>
            <div class="font-semibold text-gray-500 font-display text-sm">
              <div class="flex-row flex" v-if="selectedTransaction.patient_dob">
                <div>{{ selectedTransaction.patient_dob }}</div>
                <div class="ml-2 text-gray-600 w-full">Age: {{ formatAge(selectedTransaction.patient_dob) }}</div>
              </div>
              <p v-else>Not Provided</p>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Patient Sex</div>
            <div class="font-semibold text-gray-500 font-display text-sm">
              <p v-if="selectedTransaction.patient_sex">{{ selectedTransaction.patient_sex }}</p>
              <p v-else>Not Provided</p>
            </div>
          </div>
          <div v-if="selectedTransaction.patient_health_card_version_number" class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Patient Health Card Version Number</div>
            <div class="font-semibold text-gray-500 font-display text-sm">
              <p v-if="selectedTransaction.patient_health_card_version_number">{{ selectedTransaction.patient_health_card_version_number }}</p>
              <p v-else>N/A</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isSpecialist(loggedInUser) || isOrgUnitHead(loggedInUser)" id="triageMessage" class="mt-4 flex flex-col w-full items-center justify-between rounded-2xl bg-white p-6 text-left shadow border-1">
      <div class="w-full">
        <div class="mb-3 flex flex-row items-start justify-between border-b pb-2">
          <div class="flex flex-row">
            <div class="text-blue-400">
              <h2 class="text-left font-semibold text-lg text-gray-600">Triage</h2>
              <div class="flex flex-col text-left">
                <h5 class="text-xs text-gray-400">Leave a message regarding the current triage workflow for the referring physician to see.</h5>
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-center">
            <ToggleButton class="p-button-sm" @change="toggleRead(selectedTransaction.id)" v-on:click.stop v-model="selectedTransaction.contributor_has_seen" onLabel="Mark as Not Reviewed" offLabel="Mark as Reviewed" onIcon="pi pi-envelope" offIcon="pi pi-envelope" />
          </div>
        </div>
      </div>

      <div class="flex flex-row w-full">
        <div v-if="terminalNode != null && terminalNode.triage_label" class="w-full justify-start">
          <div class="flex flex-row text-xs text-gray-500">
            Triage Label (for current terminal node)
            <br />
          </div>
          <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
            <div>{{ terminalNode.triage_label }}</div>
          </div>
        </div>

        <div v-if="selectedTransaction.linked_smart_path_transaction_id" class="w-full justify-start">
          <div class="flex flex-row text-xs text-gray-500">
            Linked from another SmartPath
            <br />
          </div>
          <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
            <div>Linked from SmartPath Result ID: {{ selectedTransaction.linked_smart_path_transaction_id }}</div>
          </div>
        </div>
      </div>

      <div class="flex flex-row w-full">
        <div v-if="selectedTransaction.message_for_triage" class="w-full justify-start">
          <div class="flex flex-row text-xs text-gray-500">
            Message for Triage
            <br />
          </div>
          <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
            <div>{{ selectedTransaction.message_for_triage }}</div>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full">
        <div class="flex flex-row w-full">
          <h2 class="text-left font-semibold text-lg text-gray-600">Leave a Message (In Person Only)</h2>
        </div>
        <Textarea id="triageMessageInput" v-model="triageMessage" class="auth-input" :autoResize="true" rows="5" cols="30" maxLength="65535" />
        <div class="w-full flex flex-row justify-end my-2">
          <Button @click="saveTriageMessage(selectedTransaction.id)" id="saveTriageMessageButton" v-on:click.stop icon="pi pi-check" label="Save Message" class="p-button-sm" />
        </div>
      </div>
    </div>

    <div v-if="isGP(loggedInUser)" id="triageNotes" class="mt-4 flex flex-col w-full items-center justify-between rounded-2xl bg-white p-6 text-left shadow border-1">
      <div class="w-full">
        <div class="mb-3 flex flex-row items-start justify-between border-b pb-2">
          <div class="flex flex-row">
            <div class="text-blue-400">
              <h2 class="text-left font-semibold text-lg text-gray-600">Triage Notes</h2>
              <div class="flex flex-col text-left">
                <h5 class="text-xs text-gray-400">The SmartPath physician's team will review the case, and leave a message here if needed.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full">
        <div class="flex flex-row">
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Triage Review Status</div>
            <div v-if="selectedTransaction.contributor_has_seen == true" class="font-bold text-gray-500 font-display text-sm">Review Status: Reviewed</div>
            <div v-else class="font-bold text-gray-500 font-display text-sm">Pending Review</div>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Triage Message</div>
            <div v-if="selectedTransaction.triage_message != null" class="font-bold text-gray-500 font-display text-sm">{{ selectedTransaction.triage_message }}</div>
            <div v-else class="font-bold text-gray-500 font-display text-sm">The triage officer has not yet sent a message.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="show-referral-container flex w-full flex-col">
      <div class="animated fade-in-up animated-delay-1 my-2 w-full rounded-lg bg-white p-5 shadow border-1">
        <div class="mb-4 flex flex-row items-start justify-between">
          <div class="flex flex-row">
            <div class="flex items-center">
              <img v-bind:src="selectedTransaction.referee.profile.profile_picture_location" class="mx-auto mr-2 h-12 w-12 rounded-full bg-white object-cover" v-if="selectedTransaction.referee.profile.profile_picture_location" />
              <div v-else class="mr-2 h-12 w-12 rounded-full bg-white">
                <i class="pi pi-user"></i>
              </div>
            </div>
            <div class="text-blue-400">
              <h2 class="text-left font-bold text-gray-600">Additional Information</h2>
            </div>
          </div>
        </div>
        <div v-if="selectedTransaction.smart_path.contributors.find((contributor) => contributor.id == actingUser.id) || actingUser.id == selectedTransaction.smart_path.author.id">
          <div class="mb-4 flex w-full flex-row justify-start font-semibold text-gray-500">Referee</div>

          <div class="col-start-1 col-end-3 pb-1">
            <div class="justify-start">
              <div class="flex flex-row text-xs text-gray-500">
                Referee Full Name
                <br />
              </div>
              <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
                <div>{{ selectedTransaction.referee.full_name }}</div>
              </div>
            </div>
            <div class="justify-start">
              <div class="flex flex-row text-xs text-gray-500">
                Referee Email
                <br />
              </div>
              <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
                <div>{{ selectedTransaction.referee.email }}</div>
              </div>
            </div>
            <div class="justify-start">
              <div class="flex flex-row text-xs text-gray-500">
                Referee Phone Number
                <br />
              </div>
              <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
                <div>{{ selectedTransaction.referee.phone_number }}</div>
              </div>
            </div>
            <div class="justify-start">
              <div class="flex flex-row text-xs text-gray-500">
                Referee College Number
                <br />
              </div>
              <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
                <div>{{ selectedTransaction.referee.cpsns }}</div>
              </div>
            </div>
            <div class="justify-start">
              <div class="flex flex-row text-xs text-gray-500">
                Referee Status
                <br />
              </div>
              <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
                <div>{{ selectedTransaction.referee.status }}</div>
              </div>
            </div>
            <div class="justify-start">
              <div class="flex flex-row text-xs text-gray-500">
                Referee Province
                <br />
              </div>
              <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
                <div>{{ selectedTransaction.referee.practice_province.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="actingUser.id == selectedTransaction.referee.id">
          <div class="mb-4 flex w-full flex-row justify-start font-semibold text-gray-500">Pathway</div>
          <div class="col-start-1 col-end-3 justify-start">
            <div class="flex flex-row text-xs text-gray-500">
              Pathway Name
              <br />
            </div>
            <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
              {{ selectedTransaction.smart_path.name }}
            </div>
          </div>
          <div class="col-start-1 col-end-3 justify-start">
            <div class="flex flex-row text-xs text-gray-500">
              Pathway Specialist
              <br />
            </div>
            <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
              {{ selectedTransaction.smart_path_person.full_name }}
            </div>
          </div>
          <div class="col-start-1 col-end-3 justify-start">
            <div class="flex flex-row text-xs text-gray-500">
              Pathway Author
              <br />
            </div>
            <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
              {{ selectedTransaction.smart_path.author.full_name }}
            </div>
          </div>
          <div class="col-start-1 col-end-3 justify-start">
            <div class="flex flex-row text-xs text-gray-500">
              Pathway Description
              <br />
            </div>
            <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
              {{ selectedTransaction.smart_path.description }}
            </div>
          </div>
        </div>
      </div>

      <div class="animated fade-in-up animated-delay-1 my-2 w-full rounded-lg bg-white p-5 shadow border-1">
        <div class="mb-4 flex flex-row items-start justify-between">
          <div class="flex flex-row">
            <div class="flex items-center">
              <img v-bind:src="selectedTransaction.smart_path_person.profile.profile_picture_location" class="mx-auto mr-2 h-12 w-12 rounded-full bg-white object-cover" v-if="selectedTransaction.smart_path_person.profile.profile_picture_location" />
              <div v-else class="mr-2 h-12 w-12 rounded-full bg-white">
                <i class="pi pi-user"></i>
              </div>
            </div>
            <div class="text-blue-400">
              <h2 class="text-left font-bold text-gray-600">SmartPath Result</h2>
            </div>
          </div>
        </div>

        <div class="mb-4 flex w-full flex-row justify-start font-semibold text-gray-400">Pathway Answers</div>

        <div v-for="node in JSON.parse(selectedTransaction.smart_path_result)" :key="node.id">
          <div v-if="JSON.parse(selectedTransaction.smart_path.smart_path)[node['id']]['node_type'] != 'terminal'">
            <div class="border-b border-solid border-gray-100 mt-2">
              <div class="text-xs font-normal justify-start flex text-gray-400">Question</div>
              <div class="rich-text text-xs" v-html="node['question']" />
              <div class="text-xs mt-2 font-normal justify-start flex text-gray-400">Answer</div>
              <div class="text-gray-500 font-semibold text-sm" v-html="node['value']" />
            </div>
          </div>
        </div>

        <div class="my-4 flex w-full flex-row justify-start font-semibold text-gray-400">Terminal Node Entry</div>

        <div v-if="terminalNode != null && terminalNode.recommended_action">
          <div class="mb-2 justify-start">
            <div class="flex flex-row text-xs text-gray-500">
              Recommended Action
              <br />
            </div>
            <div class="rich-text text-xs" v-html="terminalNode.recommended_action" />
          </div>

          <div v-if="terminalNode != null && terminalNode.recommended_specialty.length > 0">
            <div class="justify-start">
              <div class="flex flex-row text-xs text-gray-500 my-4">
                Recommended Specialties
                <br />
              </div>
              <div v-for="specialty in terminalNode.recommended_specialty" :key="specialty.id" class="mb-2 flex flex-row font-semibold text-gray-500 font-display text-sm">
                <div>- {{ specialty.name }}</div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="text-md mb-2 justify-start flex text-md font-semibold text-red-400">Terminal Node not yet reached!</div>
        </div>
      </div>
    </div>
    <FaxConsultDialog :show="showFaxConsultDialog" @closeFaxConsultDialog="showFaxConsultDialog = false" :fax_document="'smart_path_result'" :documentId="selectedTransaction.id" :displayEditFaxOption="true" />
  </div>

  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import Button from 'primevue/button';
import ToggleButton from 'primevue/togglebutton';
import FaxConsultDialog from '@/components/referral/FaxConsultDialog.vue';
import Textarea from 'primevue/textarea';

export default {
  components: {
    Loading,
    Button,
    ToggleButton,
    FaxConsultDialog,
    Textarea,
  },
  data() {
    return {
      isLoading: true,
      terminalNode: null,
      showFaxConsultDialog: false,
      triageMessage: '',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser', 'selectedTransaction', 'isGP', 'isSpecialist', 'isOrgUnitHead']),
  },
  methods: {
    ...mapActions(['getSmartPathTransactionById', 'downloadSmartPathResult', 'toggleTransactionSeen', 'saveTransactionTriageMessage']),
    downloadResult(transactionId) {
      this.downloadSmartPathResult(transactionId).catch(() => {
        this.showGenericErrorToast();
      });
    },
    toggleRead(transactionId) {
      this.isLoading = true;
      this.toggleTransactionSeen({ transaction_id: transactionId })
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    faxSmartPathResultOnClick(transactionId) {
      this.selectedTransactionId = transactionId;
      this.showFaxConsultDialog = true;
    },
    saveTriageMessage(transactionId) {
      this.isLoading = true;
      this.saveTransactionTriageMessage({ user_id: this.loggedInUser.id, transaction_id: transactionId, triage_message: this.triageMessage })
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Message saved!',
            detail: 'This message will be visible to the referring physician.',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
  created() {
    this.isLoading = true;
    this.getSmartPathTransactionById(this.$route.params.transactionId)
      .then(() => {
        // grab the terminal node info of the transactions terminal node
        this.terminalNode = JSON.parse(this.selectedTransaction.smart_path.smart_path)[JSON.parse(this.selectedTransaction.smart_path_result)[JSON.parse(this.selectedTransaction.smart_path_result).length - 1]['id']];
        this.triageMessage = this.selectedTransaction.triage_message;
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });
  },
};
</script>

<style>
.referral-form-tooltip {
  position: relative;
}

.in-front {
  position: relative;
  z-index: 100;
}

.dark-mint-show-referral-background {
  background-color: var(--vh-new-feature-blue);
}

.modal-footer {
  border-radius: 6px;
}

.dark-mint-show-referral-button-background.p-button.p-component {
  background-color: #10699e;
  border-color: #10699e;
}

.dark-mint-show-referral-button-background.p-button.p-component:hover {
  background-color: #0a7abd;
  border-color: #0a7abd;
}

.dark-mint-show-referral-modal-background.modal__header {
  background-color: var(--vh-dark-mint) !important;
}

.dark-mint-show-referral-header .modal__header {
  background-color: var(--vh-dark-mint);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
</style>
