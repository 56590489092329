<template lang="">
  <DatePicker v-model="selectedDate" :popover="{ visibility: 'none' }" class="relative" mode="date" :masks="{ input: 'MM/DD/YYYY' }" @change="selectedDateUpdated" :max-date="maxInputDate">
    <template #default="{ inputValue, inputEvents }">
      <div class="flex rounded py-1.5 border-2 border-gray-300 hover:border-gray-500 overflow-hidden">
        <button class="flex justify-center items-center px-2 text-blue" @click="showDatePickerOverlay" type="button" aria-haspopup="true" aria-controls="overlay_panel">
          <i class="pi pi-calendar" />
        </button>
        <input :value="inputValue" v-on="inputEvents" class="flex-grow px-2 py-2 text-xs" :class="displayAge ? 'w-4/5' : 'w-full'" :id="inputId" placeholder="MM/DD/YYYY" />
        <span v-if="displayAge && inputValue && selectedDate && formatAge(selectedDate)" class="text-xs w-full flex justify-end py-2 pr-2 ml-auto font-semibold text-gray-600">Age: {{ formatAge(selectedDate) }}</span>
      </div>
    </template>
  </DatePicker>
  <OverlayPanel ref="datePickerOverlay" id="newLayer0">
    <div class="sm:col-auto w-96 flex flex-row">
      <Calendar v-model="selectedDateFromDatePickerOverlay" inline view="thisIsAWrongParameterToMakeItWork" dateFormat="yy" @date-select="updateSelectedDate" :maxDate="maxInputDate" />
    </div>
  </OverlayPanel>
</template>
<script>
import { DatePicker } from 'v-calendar';
import OverlayPanel from 'primevue/overlaypanel';

export default {
  props: ['inputModel', 'inputId', 'maxInputDate', 'displayAge'],
  emits: ['dateSelected'],
  data() {
    return {
      selectedDate: this.inputModel,
      selectedDateFromDatePickerOverlay: null,
    };
  },
  components: {
    DatePicker,
    OverlayPanel,
  },
  methods: {
    showDatePickerOverlay(event) {
      this.$refs.datePickerOverlay.toggle(event);
      setTimeout(() => {
        // This is to select the year picker button first once the datepicker pops up, since by default
        // you've to first select the current year to get the year selector dropdown.
        document.getElementsByClassName('p-datepicker-year')[0].click();
      }, 300);
    },
    updateSelectedDate() {
      setTimeout(() => {
        // This is to close the overlay automatically once a date is selected
        this.$refs['datePickerOverlay'].toggle();
        this.selectedDate = this.selectedDateFromDatePickerOverlay;
      }, 300);
    },
  },
  watch: {
    selectedDate() {
      this.$emit('dateSelected', this.selectedDate);
    },
  },
};
</script>
<style lang=""></style>
