<template>
  <div v-if="this.loggedInUser.org_units.length > 0" class="animated-quick fade-in-down mb-2">
    <Message severity="info" :closable="false" class="text-left">By sharing with a Practice Group, your Practice Group Administrators will have access to your consults.</Message>
    <div class="mb-3 flex flex-col md:flex-row justify-between pb-4">
      <div class="p-text-left">
        <p class="p-text-bold font-display text-gray-600">Auto Share Consults <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="6" /></p>
        <p class="text-sm text-gray-500 mb-2 md:mb-0">Select which Practice Group you want to have access to your new consults.</p>
      </div>
      <div class="w-full md:w-64">
        <Dropdown v-model="selectedOrgUnitId" :options="shareOrgUnitOptions" optionValue="id" optionLabel="name" id="selectOrgUnit" class="auth-input w-full text-xs font-normal uppercase text-gray-400" />
      </div>
    </div>
    <div class="mt-2 flex justify-end">
      <Button @click="submitUpdateProfile" id="confirmChangesButton">Confirm Changes</Button>
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions } from 'vuex';
import Button from 'primevue/button';
import Message from 'primevue/message';
import Loading from 'vue-loading-overlay';
import Dropdown from 'primevue/dropdown';

export default {
  props: ['loggedInUser'],
  emits: ['selectInfo'],
  data() {
    return {
      shareOrgUnitOptions: [],
      selectedOrgUnitId: 0,
      profile: this.loggedInUser.profile,
      isLoading: false,
    };
  },
  created() {
    this.shareOrgUnitOptions = JSON.parse(JSON.stringify(this.loggedInUser.org_units));
    this.shareOrgUnitOptions.push({ id: 0, name: 'Private, Do Not Share' });
    if (this.loggedInUser.profile.org_unit_referral_access_preference) {
      this.selectedOrgUnitId = this.loggedInUser.profile.org_unit_referral_access_preference;
    }
  },
  methods: {
    ...mapActions(['updateUserProfileInfo']),
    submitUpdateProfile() {
      this.isLoading = true;
      this.$toast.removeAllGroups();
      this.profile.org_unit_referral_access_preference = this.selectedOrgUnitId;
      this.profile.user_id = this.loggedInUser.id;
      this.updateUserProfileInfo(this.profile)
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Account Updated',
            detail: 'Your account information has been successfully updated.',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        });
    },
  },
  components: { Button, Loading, Message, Dropdown },
};
</script>
