<template>
  <div v-if="isNativePlatform()" class="pt-mobile-inset bg-white sticky top-0 flex flex-row justify-between items-center m-0">
    <div class="text-blue-500 flex flex-row items-center" @click="clickBack" id="manageCallDisplayNameBackButton"><i class="pi pi-angle-left pl-2" style="font-size: 1.5rem"></i> Back</div>
    <div class="font-seimbold text-gray-600">Caller ID</div>
    <div class="text-white"><i class="pi pi-angle-left pl-2" style="font-size: 1.5rem"></i> Phone</div>
  </div>
  <div :class="isNativePlatform() ? 'bg-white h-full m-0' : 'main-inner-wrap'">
    <div v-if="!isEditingDisplayName" class="w-full pt-3 text-lg bg-white">
      <div class="bg-white w-full rounded-lg text-black px-2">
        <div class="w-full flex flex-row py-3 px-2 justify-center border-b cursor-pointer" v-for="(callDisplayName, idx) in callDisplayNames" :key="idx" @click="selectCallDisplayNameOnClick(callDisplayName)" :id="`callDisplayName-${idx}`">
          {{ callDisplayName.display_name }}
          <span class="ml-auto my-auto">
            <i class="pi pi-star-fill my-auto text-blue-700 mr-2" style="font-size: 0.8rem" v-if="callDisplayName.is_default" />
            <i class="pi pi-ellipsis-h my-auto" style="font-size: 1rem" />
          </span>
        </div>
      </div>
      <div class="w-full flex flex-row justify-center pt-5 bg-white">
        <Button class="p-button p-button-primary" label="Add New Caller ID" @click="selectCallDisplayNameOnClick(null)" id="addNewCallerIdButton"></Button>
      </div>
    </div>
    <div v-else class="animated fade-in-left bg-white">
      <AddOrEditCallDisplayName :selectedCallDisplayName="selectedCallDisplayName" @finishEditing="isEditingDisplayName = false" />
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" class="bg-white" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Loading from 'vue-loading-overlay';
import AddOrEditCallDisplayName from '@/components/caller/AddOrEditCallDisplayName.vue';
import CapacitorCallPlugin from '@/plugins/CapacitorCallPlugin';

export default {
  components: {
    Loading,
    InputText,
    Button,
    Dropdown,
    AddOrEditCallDisplayName,
  },
  data() {
    return {
      isLoading: false,
      selectedCallDisplayName: null,
      isEditingDisplayName: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'callDisplayNames']),
  },
  methods: {
    ...mapActions(['getCallDisplayNames', 'createOrUpdateDisplayName']),
    async clickBack() {
      try {
        if (this.isEditingDisplayName) {
          this.isEditingDisplayName = false;
        } else {
          await CapacitorCallPlugin.closeCallDisplayNameViewController();
        }
      } catch (e) {}
    },
    selectCallDisplayNameOnClick(data) {
      this.selectedCallDisplayName = data;
      this.isEditingDisplayName = true;
    },
  },
  mounted() {
    this.isEditingDisplayName = this.$route.query.addDisplayName;
    const el = document.querySelector('.layout-wrapper');
    if (el) {
      el.classList.add('!bg-white');
    }

    const layoutMain = document.querySelector('.layout-main');
    if (layoutMain) {
      layoutMain.classList.add('!bg-white');
    }
  },
  unmounted() {
    const el = document.querySelector('.layout-wrapper');
    if (el) {
      el.classList.remove('!bg-white');
    }
    const layoutMain = document.querySelector('.layout-main');
    if (layoutMain) {
      layoutMain.classList.remove('!bg-white');
    }
  },
  created() {
    this.isLoading = true;
    this.getCallDisplayNames()
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });
  },
};
</script>

<style></style>
