<template>
  <router-link class="sidebar-router-link p-ripple group" v-ripple to="/manageusers" @click="$emit('menu-toggle', event)">
    <div v-if="inviteAccess(loggedInUser)" class="sidebar-router-label">Referral Network</div>
    <div v-else class="sidebar-router-label">Users</div>
    <i class="pi pi-users"></i>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
import Ripple from 'primevue/ripple';

export default {
  emits: ['menu-toggle'],
  directives: {
    ripple: Ripple,
  },
  data() {
    return {
      realOnboardingForm: this.loggedInUser,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'onboardingForm', 'isOrgUnitHead']),
  },
};
</script>

<style scoped>
i {
  font-size: 1.2rem !important;
}
</style>
