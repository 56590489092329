<template>
  <Dialog header="Upload Profile Picture" v-model:visible="openChangeProfilePictureModal" style="width: 300px" :modal="true" :dismissableMask="true" :closable="true">
    <div class="flex bg-white h-full flex-grow flex-col justify-center items-center overflow-hidden rounded-2xl py-8 px-2 border-1">
      <div class="relative">
        <div class="rounded-full bg-green-400 absolute bottom-0 right-0 border-2 border-solid border-white w-5 h-5 z-50"></div>
        <div class="flex justify-center">
          <img v-bind:src="loggedInUser.profile.profile_picture_location" class="h-24 w-24 rounded-full bg-white object-cover" v-if="loggedInUser.profile.profile_picture_location && !this.previewImage" />
          <img v-bind:src="previewImage" class="h-24 w-24 rounded-full bg-white object-cover" v-if="this.previewImage" />
        </div>
      </div>
      <div class="mt-3 flex items-center">
        <h3 class="font-body text-md font-semibold capitalize">{{ loggedInUser.first_name }} {{ loggedInUser.last_name }}</h3>
      </div>
    </div>
    <div class="flex justify-center mt-5">
      <FileUpload mode="basic" class="w-full p-button-secondary" style="width: 100%" uploadIcon="pi pi-upload" :maxFileSize="20000000" :fileLimit="1" accept="image/png, image/jpeg, image/jpg" :customUpload="true" @select="handleProfilePhotoFileChange" @uploader="uploadProfilePhoto" chooseLabel="Upload New Profile Picture" id="profile_pic_file_upload" />
      <Button label="Done" class="w-full p-button-secondary" v-if="this.previewImage" @click="uploadProfilePhoto()" />
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';

export default {
  data() {
    return {
      previewImage: null,
      fileHolder: '',
      openChangeProfilePictureModal: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['uploadProfilePicture']),
    toggleModal() {
      this.previewImage = null;
      this.fileHolder = null;
      this.openChangeProfilePictureModal = !this.openChangeProfilePictureModal;
    },
    handleProfilePhotoFileChange(e) {
      document.getElementById('profile_pic_file_upload').classList.add('hidden');
      this.previewImage = e.files[0].objectURL;
      this.fileHolder = e.files[0];
    },
    uploadProfilePhoto() {
      this.uploadProfilePicture({
        userId: this.loggedInUser.id,
        file: this.fileHolder,
      })
        .then(() => {
          this.openChangeProfilePictureModal = false;
          this.previewImage = null;
          this.fileHolder = '';

          this.$toast.add({
            severity: 'success',
            summary: 'Account Updated',
            detail: 'User profile picture has been successfully updated.',
            life: 5000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error updating their account, please contact dev team',
            life: 10000,
          });
        });
    },
  },
  components: {
    Button,
    FileUpload,
  },
};
</script>

<style>
#profile_pic_file_upload > .p-fileupload-choose-selected {
  display: none !important;
}
#profile_pic_file_upload,
#profile_pic_file_upload > .p-fileupload-choose {
  width: 100%;
}
.p-fileupload-choose:not(.p-disabled):hover {
  background: #545b62;
  border: 1px solid #545b62;
}
</style>
