<template>
  <Dialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :visible="showModal" :modal="true" :dismissableMask="true" :draggable="false" :closable="false" id="confirmDeleteDisplayNameDialog" style="max-width: 480px">
    <template #header>
      <div class="flex flex-row items-center">
        <h1 class="text-xl font-bold text-gray-700">Confirm Delete Caller ID</h1>
      </div>
    </template>
    <div class="flex w-full flex-col p-2">
      <div class="tip-title mb-3 text-sm">Are you sure you want to delete this caller ID?</div>
      <label class="auth-input" for="phoneNumber">Display Name</label>
      <span class="p-input-icon-left w-full">
        {{ callDisplayName.display_name }}
      </span>
    </div>
    <template #footer>
      <div class="flex flex-row justify-end">
        <div class="mr-2">
          <Button label="Close" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="closeDialog" />
        </div>
        <div class="-mr-1">
          <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="deleteAndCloseDialog" id="confirmDeleteCallerIdButton" />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Button,
    Dialog,
  },
  props: ['callDisplayName'],
  emits: ['successfullyDeleted'],
  data() {
    return {
      showModal: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['actingUser']),
  },
  created() {},
  methods: {
    ...mapActions(['deleteDisplayName']),
    openDialog() {
      this.showModal = true;
    },
    closeDialog() {
      this.showModal = false;
    },
    deleteAndCloseDialog() {
      this.deleteDisplayName(this.callDisplayName)
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Caller ID Deleted',
            detail: 'You successfully deleted the caller ID.',
            life: 3000,
          });
          this.$emit('successfullyDeleted');
          this.closeDialog();
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
  },
};
</script>
<style>
#faxConsultDialog .p-dialog-content {
  padding: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
