<template>
  <div v-if="isSpecialist(loggedInUser) || isGP(loggedInUser) || isOrgUnitHead(loggedInUser)" id="notificationSettingsParentDiv">
    <SubscribeToCalendar :loggedInUser="loggedInUser" />
    <div class="col-span-2 mt-2 mb-4 flex flex-row items-center justify-center">
      <h1 class="mr-2 text-lg font-bold text-gray-600">Notification Settings <ToggleSupportSectionButton :openedSupportSectionAccountAccordionIndex="2" /></h1>
      <div class="h-1 flex-grow bg-gray-600"></div>
    </div>
    <Message severity="info" :closable="false" class="text-left">Toggle email or SMS notifications for each event, then click Confirm Changes.</Message>

    <div v-for="toggleGroup in toggleGroups" :key="toggleGroup.title" class="mb-2 flex flex-col md:flex-row justify-between border-b border-solid border-gray-200 p-2 pb-4">
      <div class="text-left">
        <p class="font-semibold font-display text-gray-600">{{ toggleGroup.title }}</p>
        <p class="text-sm text-gray-500">{{ toggleGroup.description }}</p>
      </div>
      <div class="flex flex-col text-left mt-3 md:mt-0 items-start md:items-end">
        <label v-for="option in toggleGroup.options" :key="option" class="flex flex-row-reverse md:flex-row items-center justify-end md:justify-end cursor-pointer mb-4 last:mb-0">
          <span class="mx-2 text-xs font-medium text-gray-900">{{ option }}</span>
          <input type="checkbox" :value="option" class="sr-only peer" v-model="notificationSetting[toggleGroup.model]" />
          <div class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-green-300" :aria-label="option"></div>
        </label>
      </div>
    </div>
  </div>

  <div class="mb-2 flex flex-col md:flex-row justify-between border-b border-solid border-gray-200 p-2 pb-4">
    <div class="text-left">
      <p class="font-semibold font-display text-gray-600">Subscription to Email List</p>
      <p class="text-sm text-gray-500" v-if="!initiallyIsInEmailList">Get weekly email updates on {{ getReferralPersonTerm(actingUser, true) }} Availability and lectures from Virtual Hallway.</p>
      <p v-else class="text-sm text-gray-500">Thank you for subscribing, you can unsubscribe by clicking the link at the bottom of our emails.</p>
    </div>
    <div class="flex flex-col text-left mt-3 md:mt-0 items-start md:items-end">
      <label class="flex flex-row-reverse md:flex-row items-center justify-end md:justify-end cursor-pointer mb-4 last:mb-0" v-if="!initiallyIsInEmailList">
        <span class="mx-2 text-xs font-medium text-gray-900">Email</span>
        <input type="checkbox" class="sr-only peer" v-model="user.is_in_email_list" />
        <div class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-green-300" aria-label="EmailList"></div>
      </label>
    </div>
  </div>
  <div class="mt-4 flex justify-end">
    <Button @click="submit" class="p-button-secondary" id="confirmChangesButton">Confirm Changes</Button>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';
import SelectButton from 'primevue/selectbutton';
import InputSwitch from 'primevue/inputswitch';
import Message from 'primevue/message';
import TutorialVideoDialog from '../misc/TutorialVideoDialog.vue';

export default {
  components: { Button, Loading, InputSwitch, SelectButton, Message, TutorialVideoDialog },
  props: ['loggedInUser'],
  emits: ['selectInfo'],
  inject: ['mixpanel'],
  data() {
    return {
      user: this.loggedInUser,
      notificationSetting: this.loggedInUser.profile.notification_setting,
      isLoading: false,
      initiallyIsInEmailList: true,
      toggleGroups: [],
      toggleGroupOptions: ['Email', 'SMS'],
    };
  },
  computed: {
    ...mapGetters(['isGP', 'isSpecialist', 'hasProvinceOfPractice', 'actingUser', 'isOrgUnitHead']),
  },
  created() {
    this.setupConditionalToggleGroups();
    this.initiallyIsInEmailList = this.user.is_in_email_list;

    if (!Array.isArray(this.notificationSetting.consult_is_booked)) {
      Object.keys(this.notificationSetting).forEach((key) => {
        this.notificationSetting[key] = JSON.parse(this.notificationSetting[key]);
      });
    }
  },
  methods: {
    ...mapActions(['updateUserAccountInfo', 'updateNotificationSetting']),
    setupConditionalToggleGroups() {
      if (this.loggedInUser.agreed_to_push_notifications) {
        this.toggleGroupOptions.push('Push');
      }

      this.toggleGroups.push(
        {
          title: 'Consult is Booked',
          description: 'Get notified when a consultation has been booked.',
          model: 'consult_is_booked',
          options: this.toggleGroupOptions,
        },
        {
          title: 'Consult is Cancelled',
          description: 'Get notified when a consultation has been cancelled.',
          model: 'consult_is_cancelled',
          options: this.toggleGroupOptions,
        },
        {
          title: 'Consult is Completed',
          description: 'Get notified when consultation report has been submitted.',
          model: 'consult_completed',
          options: this.toggleGroupOptions,
        }
      );
      if (this.isGP(this.loggedInUser) || this.isSpecialist(this.loggedInUser)) {
        this.toggleGroups.push({
          title: 'Consult Reminder',
          description: 'Get notified 15 minutes before consultation time.',
          model: 'upcoming_referral',
          options: this.toggleGroupOptions,
        });
        this.toggleGroups.push({
          title: 'Addendum is Created',
          description: 'Get notified when an addendum has been added to a consultation report.',
          model: 'addendum_created',
          options: this.toggleGroupOptions,
        });
        this.toggleGroups.push({
          title: 'Consult Running Late Message',
          description: 'Allow the ' + this.getReferralPersonTerm(this.actingUser) + ' to notify you if they running late for the call.',
          model: 'consult_running_late',
          options: this.toggleGroupOptions,
        });
        this.toggleGroups.push({
          title: 'Consult is Submitted by Practice Group Administrator',
          description: 'Get notified when your Practice Group Administrator submits a consult on your behalf.',
          model: 'consult_submitted_by_administor',
          options: this.toggleGroupOptions,
        });
      }
      if (this.displayEconsult()) {
        this.toggleGroups.push({
          title: 'eConsult',
          description: 'Get notified when a comment has been added to eConsult.',
          model: 'econsult_comment_created',
          options: this.toggleGroupOptions,
        });
      }
      if (this.isSpecialist(this.loggedInUser)) {
        this.toggleGroups.push({
          title: 'Consult Nudge Message',
          description: 'Allow the General Practitioner to notify you if they have not received a call.',
          model: 'consult_nudge',
          options: this.toggleGroupOptions.filter((option) => option !== 'Email'),
        });
      }
    },
    submit() {
      this.isLoading = true;
      this.$toast.removeAllGroups();
      this.updateUserAccountInfo(this.user)
        .then(() => {
          this.updateNotificationSetting(this.notificationSetting)
            .then(() => {
              this.isLoading = false;
              this.$toast.add({
                severity: 'success',
                summary: 'Account Updated',
                detail: 'Your account information has been successfully updated.',
                life: 5000,
              });
            })
            .catch(() => {
              this.isLoading = false;
              this.showGenericErrorToast();
            });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
};
</script>
