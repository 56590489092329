<template>
  <Dialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" style="max-width: 700px" header="Welcome to SmartPath!" v-model:visible="visible" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" @hide="closeSmartPaths" @show="openSmartPaths" class="p-0" id="specSmartPaths">
    <div class="flex flex-col">
      <div class="flex flex-col p-2 mb-2">
        <div class="flex w-full flex-col">
          <!-- <div class="text-lg text-gray-600 font-semibold">Welcome to SmartPath!</div> -->
          <div class="text-sm text-gray-500 font-semibold">Before you start your first SmartPath, here are some things to keep in mind.</div>
          <ul class="text-xs text-gray-500 mt-2">
            <li>1. <strong>Automatically Save Your Progress:</strong> Start a SmartPath, leave, and return right where you left off. Take your time to act on advice. No worries about losing data. SmartPath saves your answers everytime.</li>

            <li>2. <strong>Easy Information Transfer:</strong> If the SmartPath result recommends a referral or phone consult, a form will automatically be populated so you don't need to repeat work.</li>
            <li>3. <strong>MyPathways:</strong> Access prior SmartPaths in the sidebar's MyPathways tab. Download and fax SmartPath referrals as well as receive updates and messages about referral triage.</li>
            <li>4. <strong>Your Feedback Matters:</strong> Complete a quick survey at the end of each SmartPath. Your input is valuable as we refine and enhance the SmartPath experience. We're eager to make it even better!</li>
          </ul>
          <div class="text-sm text-gray-500 font-semibold">Thanks for using SmartPath! Select your SmartPath in the dropdown below to being.</div>
        </div>
      </div>

      <div class="flex flex-col rounded bg-red-500 p-6">
        <h1 class="mb-2 text-white md:text-2xl">Select SmartPath</h1>
        <Dropdown v-model="selectedSmartPath" class="w-full" id="selectSmartPathDropDown" :options="smartPaths" optionLabel="name" />
      </div>
      <div v-if="selectedSmartPath.description !== null" class="flex flex-col p-2 mt-4">
        <h1 class="mb-2 text-gray-500 md:text-2xl">Pathway Description</h1>
        <hr class="mb-2" />
        <pre class="text-sm">{{ selectedSmartPath.description }}</pre>
      </div>

      <div class="flex flex-row w-full">
        <div class="flex flex-col p-2 mt-4 mb-2 w-full">
          <div class="flex w-full flex-col">
            <div class="text-xs">
              By clicking Start Pathway,<br />
              <ul>
                <div>1. I acknowledge I am a physician in the Nova Scotia Central Zone</div>
                <div>2. Agree to the <SmartPathEULADialog /></div>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex flex-row w-full justify-end items-end mb-4">
          <!-- <div class="w-full justify-end align-bottom"> -->
          <div @click="startSmartPath" id="startSmartPathButton" class="align-bottom text-sm h-12 w-32 mr-6 font-semibold text-center cursor-pointer rounded-md border-2 border-solid border-gray-800 bg-white p-3 text-black hover:bg-gray-200">Start Pathway</div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import Dropdown from 'primevue/dropdown';
import SmartPathEULADialog from '@/components/gp/smartpath/SmartPathEULADialog.vue';

export default {
  inject: ['mixpanel'],
  components: {
    Dropdown,
    SmartPathEULADialog,
  },
  props: ['smartPaths', 'user', 'selectedSpecialty', 'show'],
  emits: ['closeViewSmartPaths'],
  data() {
    return {
      isLoading: false,
      selectedSmartPath: this.smartPaths[0],
      visible: false,
    };
  },
  created() {
    this.visible = true;
  },
  methods: {
    ...mapMutations(['setSelectedContributor']),
    openSmartPaths() {
      this.selectedSmartPath = this.smartPaths[0];
      this.mixpanel.track('Make Referral - Clicked View SmartPath', { specialty: this.selectedSpecialty.name, specialistId: this.user.id });
    },
    closeSmartPaths() {
      this.visible = false;
      this.$emit('closeViewSmartPaths');
    },
    startSmartPath() {
      this.setSelectedContributor(this.user);
      this.$router.push({ path: '/pathway/' + this.selectedSmartPath.id + '/' + this.user.id });
    },
  },
};
</script>
<style>
#specBio .p-dialog-content {
  padding: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-dialog {
  border: none !important;
}
.pathways-button:hover {
  background-color: #f9f9f9 !important;
}
</style>
