<template>
  <div class="main-inner-wrap">
    <transition name="slide-up-fade">
      <div class="bg-white rounded-xl p-5 pt-8 shadow max-w-sm" v-if="toggleTransition">
        <div class="justify-center w-full items-center text-center flex flex-col">
          <i class="pi pi-lock login-form-heading-icon mb-4" style="font-size: 3rem" />
          <h2 class="text-xl font-bold">Reset Password</h2>
        </div>
        <form method="post" @submit.prevent="submit" class="p-5">
          <p class="text-sm font-medium font-display mb-4">Enter your email and a password reset link will be emailed to you:</p>
          <InputText type="email" name="email" v-model="email" required class="w-full mb-4" maxLength="255" />
          <div class="mb-4"></div>
          <button type="submit" class="p-button p-button-secondary flex flex-col justify-center w-full">Send Reset Link</button>
          <ul v-for="(error, i) in errors" :key="i" class="error">
            <li>{{ error }}</li>
          </ul>
        </form>
        <div class="text-sm mb-4 font-display"><span class="text-secondary cursor-pointer" @click="$router.push('/login')">Back to Login</span></div>
      </div>
    </transition>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import InputText from 'primevue/inputtext';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      errors: [],
      isLoading: false,
      toggleTransition: false,
    };
  },
  components: {
    Loading,
    InputText,
  },
  methods: {
    ...mapActions(['sendPasswordResetEmail']),
    submit() {
      this.$toast.removeAllGroups();
      this.isLoading = true;
      this.sendPasswordResetEmail(this.email)
        .then(() => {
          this.isLoading = false;
          this.errors = [];
          this.$toast.add({
            severity: 'success',
            summary: 'Reset Link Password Sent',
            detail: 'If your account exists we will send you a password reset link to your email.',
            life: 10000,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.errors = [];
          if (error.status !== 500) {
            this.$toast.add({
              severity: 'success',
              summary: 'Reset Link Password Sent',
              detail: 'If your account exists we will send you a password reset link to your email.',
              life: 10000,
            });
          } else {
            this.showGenericErrorToast();
          }
        });
    },
  },
  mounted() {
    this.toggleTransition = true;
  },
};
</script>

<style>
.width--600 {
  width: 600px;
}
.slide-up-fade-enter-active {
  transition: all 0.15s ease-out;
}

.slide-up-fade-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-up-fade-enter-from,
.slide-up-fade-leave-to {
  transform: translatey(200px);
  opacity: 0;
}
</style>
