<template>
  <template v-if="!isLoading && (referral.interaction_type.name === 'phone' || referral.interaction_type.name === 'econsult')">
    <form method="post" @submit.prevent="submit" v-if="referral.status !== 'Cancelled'">
      <div class="mt-3 grid grid-cols-6 gap-1 gap-x-2" id="referralResponseFormParentDiv">
        <div class="relative mb-2 col-span-2" v-if="referral.interaction_type.name === 'phone'">
          <DatePicker v-model="responseForm.call_start_time" :popover="{ visibility: 'focus' }" class="relative" mode="time" is-required :input-debounce="100">
            <template v-slot="{ inputValue, inputEvents }">
              <label class="auth-input">Call Start Time </label>
              <InputText type="text" :value="inputValue" v-on="inputEvents" v-model="responseForm.call_start_time" class="auth-input relative" required maxLength="55" id="callStartTime" />
              <p class="text-sm text-red-500" v-if="errorMsgs.callStartTime">{{ errorMsgs.callStartTime }}</p>
            </template>
          </DatePicker>
        </div>
        <div class="relative mb-2 col-span-2" v-if="referral.interaction_type.name === 'phone'">
          <DatePicker v-model="responseForm.call_end_time" :popover="{ visibility: 'focus' }" class="relative" mode="time" is-required :input-debounce="100">
            <template v-slot="{ inputValue, inputEvents }">
              <label class="auth-input">Call End Time </label>
              <InputText type="text" :value="inputValue" v-on="inputEvents" v-model="responseForm.call_end_time" class="auth-input relative" required maxLength="55" id="callEndTime" />
              <p class="text-sm text-red-500" v-if="errorMsgs.callEndTime">{{ errorMsgs.callEndTime }}</p>
            </template>
          </DatePicker>
        </div>
        <div class="mb-2 col-span-2">
          <DatePicker v-model="call_date" is-required :popover="{ visibility: 'focus' }" class="relative pb-2" v-if="referral.interaction_type.name === 'phone'" @dayclick="formatCallTime">
            <template v-slot="{ inputValue, inputEvents }">
              <label class="auth-input">Date of Followup </label>
              <InputText type="text" :value="inputValue" v-on="inputEvents" v-model="call_date" class="auth-input relative" required name="dateTimeInput" maxLength="55" id="dateTimeInput" />
              <p class="text-sm text-red-500" v-if="errorMsgs.dateTimeInput">{{ errorMsgs.dateTimeInput }}</p>
            </template>
          </DatePicker>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-1 gap-x-2">
        <div ref="scribeParentDiv" id="scribeParentDiv" class="w-full col-start-1 col-end-3">
          <div class="mb-2" v-if="showScribeOptions">
            <div class="flex w-full font-display items-center mt-3">
              <div class="text-sm whitespace-nowrap mr-2">Scribe</div>
              <div class="line-divider w-full" style="margin-left: 0"></div>
            </div>
            <div v-if="completedSummaries.length > 0" id="scribeSummariesParentDiv">
              <div class="w-full text-start text-xs my-4" id="summaryTextArea">
                <pre>{{ selectedSummary.summary }}</pre>
              </div>
              <div class="flex w-full font-display justify-center items-center my-4">
                <div class="flex w-full">
                  <div class="w-8 h-8 hover:bg-gray-100 flex justify-center items-center rounded-full border border-gray-500 mr-1" @click="appendToRecommendation">
                    <i class="pi pi-plus text-gray-700" style="font-size: 0.8rem"></i>
                  </div>
                  <div class="w-8 h-8 p-1 hover:bg-gray-100 flex justify-center items-center rounded-full border border-gray-500 mr-1" @click="copyToClipboard">
                    <i class="pi pi-copy text-gray-700" style="font-size: 1rem"></i>
                  </div>
                  <div class="w-8 h-8 p-1 hover:bg-gray-100 flex justify-center items-center rounded-full border border-gray-500 mr-1" @click="showRegenerateScribeDialog = true" v-if="scribeStatus.can_regenerate">
                    <i class="pi pi-refresh text-gray-700" style="font-size: 1rem" id="showRegenerateSummaryBtn"></i>
                  </div>
                </div>
                <div class="line-divider w-full" style="margin-left: 0"></div>
                <div class="text-xs ml-2 flex justify-end">
                  <div class="flex justify-center items-center whitespace-nowrap ml-1" id="summaryCountDiv">
                    <i class="pi pi-angle-left cursor-pointer mr-1 font-medium" @click="selectSummaryLeftOnClick" id="selectPreviousSummaryBtn" style="font-size: 1.3rem"></i>
                    <span class="ml-1 flex flex-row justify-start items-center"> {{ completedSummaries.findIndex((item) => item.id === selectedSummary?.id) + 1 }} / {{ completedSummaries.length }} </span>
                    <i class="pi pi-angle-right cursor-pointer ml-1" @click="selectSummaryRightOnClick" id="selectNextSummaryBtn" style="font-size: 1.3rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-4" id="scribeStatusMessageDiv">
              <Message v-if="scribeLoadingMessage" severity="info" :closable="false" class="text-left !text-xs !my-2" icon="pi pi-spin pi-spinner">{{ scribeLoadingMessage }}</Message>
              <Message v-else-if="scribeSuccessMessage" severity="success" :closable="false" class="text-left !text-xs !my-2">{{ scribeSuccessMessage }}</Message>
              <Message v-else-if="scribeWarningMessage" severity="warn" :closable="false" class="text-left !text-xs !my-2">{{ scribeWarningMessage }}</Message>
              <Message v-else-if="scribeErrorMessage" severity="error" :closable="false" class="text-left !text-xs !my-2">{{ scribeErrorMessage }}</Message>
            </div>
            <div v-if="completedSummaries.length < 1 && scribeStatus.can_regenerate" class="mb-5">
              <Button label="Regenerate Summary" class="p-button-outlined w-full" icon="pi pi-refresh" @click="showRegenerateScribeDialog = true" id="scribeRegenerateSummaryButton" />
            </div>
          </div>
        </div>
        <div class="col-start-1 col-end-3 mb-2">
          <label class="auth-input"> Opinion/Recommendation </label>
          <Textarea v-model="responseForm.discussion_details" class="auth-input" :autoResize="true" rows="5" cols="30" required maxLength="65535" id="discussionDetails" @keydown.tab.prevent="processTabs($event)" placeholder="Please summarize the details of the phone consult." />
          <p class="text-sm text-red-500" v-if="errorMsgs.discussionDetails">{{ errorMsgs.discussionDetails }}</p>
        </div>

        <div class="col-start-1 col-end-3 mb-2 flex flex-col" v-if="responseForm && !hasProvinceOfPractice(referral.specialty, ['ON'])">
          <label class="auth-input" for="diagnostic_code_unknown">Diagnostic Code Undetermined/Unknown:</label>
          <InputSwitch class="auth-input mt-1" id="diagnostic_code_unknown" v-model="diagnosticCodeUnknown" @click="toggleDiagnosticCodeDiagnosis" v-tooltip="'Try to search for the diagnostic code for the patient below. If you are unable to find it or it is unknown click this toggle and enter a diagnosis below.'" />
        </div>

        <div class="col-start-1 col-end-3 mb-2" v-if="responseForm && !hasProvinceOfPractice(referral.specialty, ['ON']) && !diagnosticCodeUnknown">
          <label class="auth-input" for="diagnostic_code">Diagnostic Code<i class="pi pi-question-circle ml-2" v-tooltip.bottom="'Diagnostic codes are used to group and identify diseases, disorders, symptoms and other reasons for patient encounters. Use our diagnostic code lookup by typing the code, or spell out the diagnosis and a list of related codes will popup for you.'"></i></label>
          <AutoComplete class="auth-input w-full" v-model="responseForm.diagnostic_code" :suggestions="diagnosticCodes" field="code_text" @complete="searchDiagnosticCode($event)" inputId="selenium_diagnostic_code" id="diagnostic_code" placeholder="Start Typing And Select Option" forceSelection :completeOnFocus="true" />
          <p class="text-sm text-red-500 p-2" v-if="errorMsgs.diagnosticCode">{{ errorMsgs.diagnosticCode }}</p>
        </div>

        <div class="col-start-1 col-end-3 mb-2" v-if="responseForm && hasProvinceOfPractice(referral.specialty, ['ON'])">
          <!-- OHIP diagnostic code tooltip text copyed from: https://support.mdbilling.ca/hc/en-us/articles/207669223--OHIP-Diagnostic-Codes-Dx-Lookup -->
          <label class="auth-input" for="diagnostic_code">OHIP Diagnostic Code<i class="pi pi-question-circle ml-2" v-tooltip.bottom="'Diagnostic codes are used to group and identify diseases, disorders, symptoms and other reasons for patient encounters. Use our diagnostic code lookup by typing the code, or spell out the diagnosis and a list of related codes will popup for you.'"></i></label>
          <AutoComplete class="auth-input w-full" v-model="responseForm.diagnostic_code" :suggestions="filteredDiagnosticCodesOntario" field="code_text" @complete="searchDiagnosticCodeOntario($event)" inputId="diagnostic_code_ontario" placeholder="Start Typing And Select an Option" forceSelection :completeOnFocus="true" />
          <p class="text-sm text-red-500 p-2" v-if="errorMsgs.diagnosticCode">{{ errorMsgs.diagnosticCode }}</p>
        </div>

        <div class="col-start-1 col-end-3 mb-2" v-if="hasProvinceOfPractice(referral.specialty, 'ON') || diagnosticCodeUnknown">
          <label class="auth-input" for="diagnosis"> Diagnosis </label>
          <InputText v-model="responseForm.diagnosis" class="auth-input" id="diagnosis" name="diagnosis" required placeholder="Please specify your diagnosis of the patient" />
          <p class="text-sm text-red-500 p-2" v-if="errorMsgs.diagnosis">{{ errorMsgs.diagnosis }}</p>
        </div>

        <template v-if="isGastro">
          <div class="col-start-1 col-end-2 mb-2">
            <label class="auth-input" for="diagnosis"> NSH Encounter Number </label>
            <InputText v-model="responseForm.nsh_encounter_number" class="auth-input" :autoResize="true" id="departmentBarCode" name="departmentBarCode" required maxLength="55" placeholder="Specify the NSH Encounter Number" />
            <p class="text-sm text-red-500 p-2" v-if="errorMsgs.departmentBarCode">{{ errorMsgs.departmentBarCode }}</p>
          </div>

          <div class="col-start-2 col-end-3 mb-2">
            <label class="auth-input" for="diagnosis"> NSHA Visit Type </label>
            <InputText v-model="responseForm.nsh_visit_type" class="auth-input" :autoResize="true" id="nshaEventBarCode" name="nshaEventBarCode" disabled required maxLength="55" placeholder="Specify the NSHA Visit Type" />
            <p class="text-sm text-red-500 p-2" v-if="errorMsgs.nshaEventBarCode">{{ errorMsgs.nshaEventBarCode }}</p>
          </div>
          <div class="col-start-1 col-end-3 mb-2">
            <label class="auth-input" for="diagnosis"> MRN </label>
            <InputText v-model="responseForm.mrn" class="auth-input" :autoResize="true" id="mrn" name="mrn" required maxLength="55" placeholder="Specify the MRN Number" />
            <p class="text-sm text-red-500 p-2" v-if="errorMsgs.mrn">{{ errorMsgs.mrn }}</p>
          </div>
        </template>

        <div v-if="isSpecialist(loggedInUser) && loggedInUser.org_units.length > 0" class="col-start-1 col-end-3">
          <div class="info-paragraph--gray">
            <p>By sharing this with your practice group, the practice group account will have full access to this consult.</p>
          </div>
          <div class="-mt-2 mb-2 w-full text-left" id="selected-patient-container">
            <Dropdown :class="['w-full text-left']" v-model="selectedOrgUnitId" :options="shareOrgUnitOptions" optionValue="id" optionLabel="name" id="selectOrgUnit" class="auth-input w-full text-xs font-normal uppercase text-gray-400" placeholder="Private, Do Not Share" />
          </div>
        </div>
        <Button label="Save Draft" icon="pi pi-save" @click="saveForm" name="saveButton" class="p-button-secondary p-button-sm col-span-1 mb-2 md:col-span-1"></Button>
        <Button label="Submit" icon="pi pi-arrow-right" iconPos="right" type="submit" name="submitButton" class="order-1 p-button-sm col-span-1 mb-2 flex justify-center md:order-2 md:col-span-1" v-if="isSpecialist(loggedInUser)"> </Button>
      </div>
    </form>
  </template>

  <Dialog header="Regenerate Scribe Response" :breakpoints="{ '960px': '50vw', '640px': '90vw' }" class="h-3/4" style="width: 800px" v-model:visible="showRegenerateScribeDialog" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" id="regenerateScribDialog">
    <div class="p-2 text-left">
      <h1 class="text-left text-blue text-lg font-bold">Sorry we didn't get that right...</h1>
      <div class="text-md my-2">In order to get you the best summary, please specify why you want to regenerate your summary:</div>
      <div id="inaccurate-medications-button-selenium" class="select-button border-hover--blue" @click="regenerateSummaryOnClick('The listed medications are inaccurate')">Medications were inaccurate<i class="pi pi-caret-right" /></div>
      <div class="select-button border-hover--blue" @click="regenerateSummaryOnClick('The recommendations are inaccurate')">Recommendations were inaccurate<i class="pi pi-caret-right" /></div>
      <div class="select-button border-hover--blue" @click="regenerateSummaryOnClick('The whole response is inaccurate')">The whole summary was inaccurate<i class="pi pi-caret-right" /></div>

      <h1 class="text-left text-blue text-lg font-bold mb-1">Or, tell us where we went wrong...</h1>
      <Textarea v-model="customScribePrompt" class="auth-input mt-4" :autoResize="true" rows="5" cols="30" required maxLength="65535" id="customRegenerateScribeMessage" @keydown.tab.prevent="processTabs($event)" />
      <div v-if="customScribePromptError" class="text-sm text-red-500">
        {{ customScribePromptError }}
      </div>
      <div class="flex flex-row justify-end w-full mt-4">
        <Button label="Submit" class="p-button-secondary p-button-outlined" @click="regenerateSummaryOnClick(null)" />
      </div>
    </div>
  </Dialog>

  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { DatePicker } from 'v-calendar';
import moment from 'moment';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';
import Dropdown from 'primevue/dropdown';
import AutoComplete from 'primevue/autocomplete';
import InputSwitch from 'primevue/inputswitch';
import debounce from 'lodash.debounce';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import Dialog from 'primevue/dialog';

export default {
  props: ['referral'],
  emits: ['submitReferralResponseForm', 'showSavedResponseModal'],
  components: {
    DatePicker,
    InputText,
    Textarea,
    Button,
    Loading,
    Dropdown,
    AutoComplete,
    InputSwitch,
    Dialog,
  },
  data() {
    return {
      showResponseForCancel: false,
      responseForm: this.referral.referral_response_form,
      referralForm: this.referral.referral_form,
      errorMsgs: {},
      call_date: this.referral.referral_response_form.call_start_time,
      isLoading: false,
      editStartDateTime: null,
      selectedOrgUnitId: null,
      shareOrgUnitOptions: [],
      isGastro: false,
      diagnosticCodeUnknown: false,
      filteredDiagnosticCodesOntario: null,
      autoSaveResponseForm: null,
      scribeStatus: null,
      scribeLoadingMessage: null,
      scribeSuccessMessage: null,
      scribeWarningMessage: null,
      scribeErrorMessage: null,
      completedSummaries: [],
      selectedSummary: null,
      showRegenerateScribeDialog: false,
      showScribeFailedMessage: false,
      customScribePrompt: null,
      customScribePromptError: null,
      showScribeOptions: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isSpecialist', 'isOrgUnitHead', 'hasProvinceOfPractice', 'diagnosticCodes', 'diagnosticCodesOntario', 'tutorialStatus', 'summaries']),
  },
  created() {
    if (this.referral.interaction_type.name == 'phone' && this.referral.call_asap == true) {
      this.call_date = moment().toDate();
      this.responseForm.call_start_time = moment().subtract(this.referral.referral_person.specialist_preference.referral_duration_in_minutes, 'minutes').toDate().getTime();
      this.responseForm.call_end_time = moment().toDate().getTime();
    }
    if (this.referral.diagnostic_code) {
      this.responseForm.diagnostic_code = this.referral.diagnostic_code;
    } else if (this.responseForm.diagnosis && !this.hasProvinceOfPractice(this.referral.specialty, ['ON'])) {
      this.diagnosticCodeUnknown = true;
    }
    this.editStartDateTime = new Date();
    if ((this.isSpecialist(this.loggedInUser) || this.isOrgUnitHead(this.loggedInUser)) && this.loggedInUser.org_units.length > 0) {
      const referralOrgUnit = this.referral.org_units.find((org_unit) => this.loggedInUser.org_units.find((user_org_unit) => user_org_unit.pivot.user_id === org_unit.pivot.user_id));

      const gastro = this.loggedInUser.org_units.find((org_unit) => org_unit.name === 'Division of Digestive Care and Endoscopy');
      if (gastro != null) {
        this.isGastro = true;
        this.responseForm.nsh_visit_type = '*4aMBrpt*';
      }

      if (referralOrgUnit) {
        this.selectedOrgUnitId = referralOrgUnit.id;
      } else {
        this.selectedOrgUnitId = 0;
      }

      this.shareOrgUnitOptions = JSON.parse(JSON.stringify(this.loggedInUser.org_units));
      this.shareOrgUnitOptions.push({ id: 0, name: 'Private, Do Not Share' });
    }
    // The debounce ensures the method is called only once
    // after the specified delay, e.g. if the watcher fires
    // this method 10 times within 1000ms, the method will
    // only be called one time after 1000ms instead of making
    // 10 calls within 1000ms.
    this.autoSaveResponseForm = debounce(function () {
      this.saveForm();
    }, 20000);
    // Defining the watcher here instead of in watch property
    // since otherwise the watcher gets fired due to object
    // manipulation in the lines above.
    this.$watch('responseForm', {
      handler(val) {
        this.autoSaveResponseForm(val);
      },
      deep: true,
    });

    this.getScribeStatus({ referral_id: this.referral.id, user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }).then((scribeStatus) => {
      this.scribeStatus = scribeStatus;
      if (this.scribeStatus?.call_status != 'Invalid') {
        this.showScribeOptions = true;
        // listen for the Transcription
        // TODO_Ashfaq: All events should send the Scribe Status and messages should be shown based on that
        window.Echo.private('scribe.' + this.referral.referral_person.id).listen('TranscriptionComplete', (e) => {
          this.scribeLoadingMessage = 'Almost there! Summary will be ready momentarily!';
          this.scribeErrorMessage = null;
          this.scribeSuccessMessage = null;
          this.scribeWarningMessage = null;
        });
        // listen for the Summary
        window.Echo.private('scribe.' + this.referral.referral_person.id).listen('SummaryComplete', (e) => {
          this.scribeLoadingMessage = null;
          this.scribeErrorMessage = null;
          this.scribeSuccessMessage = null;
          this.scribeWarningMessage = null;
          this.scribeStatus.can_regenerate = true;
          // TODO_Ashfaq: Make the following a method as it's similar to what we do below
          this.getSummariesByReferral(this.referral.id)
            .then(() => {
              if (this.summaries.length > 0) {
                this.completedSummaries = this.summaries.filter((summary) => summary.status == 'Complete');
                if (this.completedSummaries.length > 0) {
                  this.selectedSummary = this.completedSummaries[this.completedSummaries.length - 1];
                  this.streamSelectedSummary(JSON.parse(JSON.stringify(this.completedSummaries[this.completedSummaries.length - 1])));
                  return;
                }
              }
              this.scribeErrorMessage = 'Sorry there was an error generating the summary, please try generating again or contact our support team to resolve the issue.';
              this.showGenericErrorToast();
            })
            .catch(() => {
              this.showGenericErrorToast();
            });
        });
      }
      if (this.scribeStatus.type == 'notify') {
        const scribeParentDiv = this.$refs.scribeParentDiv;
        if (scribeParentDiv) {
          scribeParentDiv.scrollIntoView({
            block: 'center',
            inline: 'nearest',
            behavior: 'smooth',
          });
        }
        if (this.scribeStatus.severity == 'error') {
          this.scribeErrorMessage = this.scribeStatus.message;
        } else if (this.scribeStatus.severity == 'warning') {
          this.scribeWarningMessage = this.scribeStatus.message;
        } else if (this.scribeStatus.transcription_status == 'Complete' && this.scribeStatus.summary_status == 'Complete') {
          this.scribeSuccessMessage = this.scribeStatus.message;
        } else {
          this.scribeLoadingMessage = this.scribeStatus.message;
        }
      }
      if (this.scribeStatus.transcription_status == 'Complete') {
        // get the existing summaries
        this.getSummariesByReferral(this.referral.id)
          .then(() => {
            if (this.summaries.length > 0) {
              this.completedSummaries = this.summaries.filter((summary) => summary.status == 'Complete');
              if (this.completedSummaries.length > 0) {
                this.selectedSummary = this.completedSummaries[this.completedSummaries.length - 1];
              }
            }
          })
          .catch(() => {
            this.showGenericErrorToast();
          });
      }
    });
  },
  methods: {
    ...mapMutations(['setTutorialStatus', 'setSummaries']),
    ...mapActions(['saveResponseForm', 'getDiagnosticCodes', 'getDiagnosticCodesOntario', 'acceptReferral', 'getSummariesByReferral', 'regenerateSummary', 'getScribeStatus']),
    saveForm() {
      this.$toast.removeAllGroups();
      if (!this.responseForm.diagnostic_code?.code || !this.responseForm.diagnostic_code?.text) {
        this.responseForm.diagnostic_code = null;
      }
      this.updateForm(false)
        .then(() => {
          this.isLoading = false;
          this.editStartDateTime = new Date();
          this.$toast.add({
            severity: 'success',
            summary: 'Consult Saved',
            life: 5000,
          });
          this.errorMsgs = [];
          this.$emit('showSavedResponseModal');
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 400) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Please Refresh Page',
              detail: 'It looks like you have an out-dated version of the referral form please copy any changes you made and refresh the page.',
              group: 'referral_out_date',
            });
          } else {
            this.showGenericErrorToast();
          }
        });
    },
    submit() {
      this.validateForm();
      this.updateForm(true);
    },
    validateForm() {
      this.errorMsgs = {};
      if (!this.responseForm.call_start_time) {
        this.errorMsgs.callStartTime = 'Please enter the call start time.';
      }
      if (!this.responseForm.call_end_time) {
        this.errorMsgs.callEndTime = 'Please enter the call end time.';
      }
      if (!this.call_date) {
        this.errorMsgs.dateTimeInput = 'Please enter the date of the followup.';
      }
      // NS/AB/PE users need to put either diagnostic code or diagnosis.
      if (!this.hasProvinceOfPractice(this.referral.specialty, ['ON'])) {
        if (!this.diagnosticCodeUnknown && !this.responseForm.diagnostic_code?.code && !this.responseForm.diagnostic_code?.text) {
          this.errorMsgs.diagnosticCode = 'A valid diagnostic code is required. Please select a code by typing in the Diagnosis Code field. If you can not find the correct code, please press the Diagnostic Code Undetermined/Unknown button to type the code.';
        } else if (this.diagnosticCodeUnknown && !this.responseForm.diagnosis) {
          this.errorMsgs.diagnosis = 'Please enter diagnosis details.';
        }
      } else {
        if (!this.responseForm.diagnostic_code?.code && !this.responseForm.diagnostic_code?.text) {
          this.errorMsgs.diagnosticCode = 'A valid diagnostic code is required. Please select a code by typing in the Diagnosis Code field.';
        }
        if (!this.responseForm.diagnosis) {
          this.errorMsgs.diagnosticCode = 'Please enter diagnosis details.';
        }
      }
      if (!this.responseForm.discussion_details) {
        this.errorMsgs.discussionDetails = 'Please provide the discussion details.';
      }
      if (this.isGastro && !this.responseForm.nsh_encounter_number) {
        this.errorMsgs.departmentBarCode = 'Please provide an NSH encounter number.';
      }
      if (this.isGastro && !this.responseForm.nsh_visit_type) {
        this.errorMsgs.nshaEventBarCode = 'Please provide an NSHA Visit Type.';
      }
      if (this.isGastro && !this.responseForm.mrn) {
        this.errorMsgs.mrn = 'Please provide the MRN.';
      }
      if (this.responseForm.call_start_time && this.responseForm.call_end_time) {
        this.responseForm.call_start_time = moment(this.responseForm.call_start_time);
        this.responseForm.call_end_time = moment(this.responseForm.call_end_time);
        this.call_date = moment(this.call_date);
        if (this.responseForm.call_start_time.isAfter(this.responseForm.call_end_time)) {
          this.errorMsgs.callStartTime = 'Call start time should be before call end time, please select a correct time.';
        }
      }
    },
    updateForm(submitted) {
      return new Promise((resolve, reject) => {
        this.formatCallTime();
        this.responseForm.submitted = submitted;
        this.responseForm.edit_start_datetime = this.editStartDateTime;
        this.responseForm.edit_end_datetime = new Date();
        // Cancelling the debounced method is handled in beforeUnmounted()
        // event, however, also putting this here for extra caution.
        this.autoSaveResponseForm.cancel();
        if (!submitted) {
          this.saveResponseForm({ ...this.responseForm, org_unit_id: this.selectedOrgUnitId })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          if (Object.keys(this.errorMsgs).length == 0) {
            this.$emit('submitReferralResponseForm', { responseForm: this.responseForm, org_unit_id: this.selectedOrgUnitId, isScribeReferralCall: this.showScribeOptions, selectedSummary: this.selectedSummary });
            resolve(true);
          } else {
            this.$toast.add({
              severity: 'warn',
              summary: 'Error Submitting Form',
              detail: 'There are some issues with the information provided on the consult request, please address the issues.',
              life: 5000,
            });
            reject(false);
          }
        }
      });
    },
    processTabs(event) {
      let textarea = document.getElementById(event.target.id);
      let start = textarea.selectionStart;
      textarea.value = textarea.value.substring(0, textarea.selectionStart) + '\t' + textarea.value.substring(textarea.selectionStart, textarea.value.length);
      event.target.selectionStart = event.target.selectionEnd = start + 1;
    },
    formatCallTime() {
      this.responseForm.call_start_time = moment(this.responseForm.call_start_time);
      this.responseForm.call_end_time = moment(this.responseForm.call_end_time);
      if (this.referral.interaction_type.name != 'econsult') {
        this.call_date = moment(this.call_date);
        this.responseForm.call_start_time = this.call_date.format('YYYY-MM-DD ') + this.responseForm.call_start_time.format('HH:mm');
        this.responseForm.call_end_time = this.call_date.format('YYYY-MM-DD ') + this.responseForm.call_end_time.format('HH:mm');
      } else {
        this.responseForm.call_start_time = this.responseForm.call_start_time.format('YYYY-MM-DD HH:mm');
        this.responseForm.call_end_time = this.responseForm.call_end_time.format('YYYY-MM-DD HH:mm');
      }
    },
    searchDiagnosticCode(event) {
      this.getDiagnosticCodes(event.query)
        .then(() => {})
        .catch(() => {});
    },
    searchDiagnosticCodeOntario(event) {
      this.getDiagnosticCodesOntario(event.query)
        .then(() => {
          setTimeout(() => {
            if (!event.query.trim().length) {
              this.filteredDiagnosticCodesOntario = [...this.diagnosticCodesOntario];
            } else {
              this.filteredDiagnosticCodesOntario = this.diagnosticCodesOntario.filter((diagnostic) => {
                return diagnostic.code_text.toLowerCase().includes(event.query.toLowerCase());
              });
            }
          }, 250);
        })
        .catch(() => {});
    },
    toggleDiagnosticCodeDiagnosis() {
      this.errorMsgs = [];
      this.responseForm.diagnostic_code = null;
      this.responseForm.diagnosis = null;
    },
    // In person referrals need to be accepted
    acceptReferralOnClick() {
      const data = {};
      data.userId = this.referral.referral_person_id;
      data.referral_id = this.referral.id;

      this.isLoading = true;
      this.acceptReferral(data)
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Consult successfully accepted',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    regenerateSummaryOnClick(prompt) {
      if (prompt == null) {
        prompt = this.customScribePrompt;
      }
      if (prompt == null) {
        this.customScribePromptError = 'Please enter a custom response!';
      } else {
        this.scribeLoadingMessage = 'Scribe is regenerating your summary. This will be quick!';
        this.regenerateSummary({ prompt: prompt, summary_id: this.selectedSummary.id })
          .then(() => {
            this.customScribePromptError = null;
            this.customScribePrompt = null;
            this.showRegenerateScribeDialog = false;
          })
          .catch(() => {
            this.showGenericErrorToast();
          });
      }
    },
    streamSelectedSummary(selectedSummary) {
      this.selectedSummary = {
        id: 0,
        summary: '',
      };

      const scribeParentDiv = this.$refs.scribeParentDiv;
      if (scribeParentDiv) {
        scribeParentDiv.scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }

      let index = 0;
      const summaryWords = selectedSummary.summary.split(' ');
      selectedSummary.summary = '';

      const intervalId = setInterval(() => {
        if (index < summaryWords.length) {
          this.selectedSummary = { ...selectedSummary, summary: summaryWords.slice(0, index + 1).join(' ') };
          index++;
          if (this.isNativePlatform()) {
            Haptics.impact({ style: ImpactStyle.Medium })
              .then(() => {})
              .catch(() => {});
          }
        } else {
          clearInterval(intervalId);
        }
      }, 20);
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.selectedSummary.summary);
      this.$toast.removeAllGroups();
      this.$toast.add({
        severity: 'success',
        summary: 'Copied',
        life: 2000,
      });
    },
    appendToRecommendation() {
      this.responseForm.discussion_details = this.responseForm.discussion_details ? this.responseForm.discussion_details + '\n' + this.selectedSummary.summary : this.selectedSummary.summary;
      this.$toast.add({
        severity: 'success',
        summary: 'Added to Recommendation',
        life: 2000,
      });
    },
    selectSummaryLeftOnClick() {
      if (this.completedSummaries.findIndex((item) => item.id === this.selectedSummary?.id) !== 0) {
        this.selectedSummary = this.completedSummaries[this.completedSummaries.findIndex((item) => item.id === this.selectedSummary?.id) - 1];
      } else {
        this.selectedSummary = this.completedSummaries[this.completedSummaries.length - 1];
      }
    },
    selectSummaryRightOnClick() {
      if (this.completedSummaries.findIndex((item) => item.id === this.selectedSummary?.id) !== this.completedSummaries.length - 1) {
        this.selectedSummary = this.completedSummaries[this.completedSummaries.findIndex((item) => item.id === this.selectedSummary?.id) + 1];
      } else {
        this.selectedSummary = this.completedSummaries[0];
      }
    },
  },
  beforeUnmount() {
    // This component is rerendered when the form gets saved/submitted
    // since the updated_at field is set as key for it. Thus, when we
    // unmount it, we need to ensure all debounced method(s) that are
    // supposed to be executed gets cancelled, otherwise the debounced
    // methods get executed even if component is destroyed.
    this.autoSaveResponseForm.cancel();
  },
  watch: {
    'responseForm.call_start_time'() {
      let startTime = moment(this.responseForm.call_start_time);
      let endTime = moment(this.responseForm.call_end_time);
      // This to ensure call end time is always after start time
      // which prevents autosave from breaking.
      if (endTime.isSameOrBefore(startTime)) {
        // There's an existing issue here if the user selects 23:50
        // as start time, the end time goes to next day 00:00, but
        // while trying to save the form this becomes invalid as
        // we reformat the time to same day, leading to end time
        // being before the start time. This would not happen in a
        // real life scenario thus ignoring this for now.
        endTime = startTime.add(10, 'minutes');
        this.responseForm.call_end_time = new Date(moment(endTime));
      }
    },
    'responseForm.call_end_time'() {
      let startTime = moment(this.responseForm.call_start_time);
      let endTime = moment(this.responseForm.call_end_time);
      // This to ensure call start time is always after end time
      // which prevents autosave from breaking.
      if (endTime.isSameOrBefore(startTime)) {
        startTime = endTime.subtract(10, 'minutes');
        this.responseForm.call_start_time = new Date(moment(startTime));
      }
    },
  },
};
</script>

<style>
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  overflow-wrap: break-word;
  word-wrap: break-word !important;
  white-space: normal !important;
  max-width: 500px;
}
</style>
