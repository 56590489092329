<template>
  <EditProfilePictureDialog :selectedUser="loggedInUser" :key="loggedInUser.id" @close="openChangeProfilePictureModal = false" ref="editProfilePictureDialog" />

  <div class="mt-1 flex h-full min-h-screen flex-col pt-mobile-inset" v-if="loggedInUser">
    <div class="relative h-full w-full">
      <div class="absolute z-0 h-64 w-full bg-secondary"></div>
      <div class="p-4">
        <div class="height-min-content animated fade-in-up relative mx-auto my-32 max-w-screen-md rounded-xl bg-white py-6 px-6 shadow">
          <div class="absolute left-10 -top-16 z-10 h-28 w-28 rounded-full bg-white shadow-sm">
            <img @click="this.$refs.editProfilePictureDialog.toggleModal()" v-bind:src="loggedInUser.profile.profile_picture_location" class="mx-auto h-28 w-28 rounded-full bg-white object-cover cursor-pointer hover:opacity-90" v-if="loggedInUser.profile.profile_picture_location" v-tooltip.right="'Change Profile Picture.'" />
            <div class="h-28 w-28 rounded-full bg-white" v-else>
              <i class="pi pi-user"></i>
            </div>
          </div>
          <div class="relative flex flex-row justify-between pt-8 text-left">
            <div>
              <h2 class="text-lg font-bold text-gray-600 md:text-2xl">
                {{ loggedInUser.first_name + ' ' + loggedInUser.last_name }}
              </h2>
              <h2 class="text-sm font-semibold text-gray-400 lg:text-md">
                {{ loggedInUser.email }}
              </h2>
            </div>
            <div v-if="allReferralsMade && !isCme(loggedInUser)" class="text-center hidden md:block">
              <h2 class="text-lg font-bold text-gray-600 md:text-2xl">
                {{ allReferralsMade }}
              </h2>
              <h2 class="text-sm font-semibold text-gray-400 lg:text-md" v-if="isGP(loggedInUser)">Consults Made</h2>
              <h2 class="text-sm font-semibold text-gray-400 lg:text-md" v-if="isSpecialist(loggedInUser)">Consults</h2>
            </div>
          </div>
          <div class="relative my-5 flex w-full flex-row flex-wrap justify-between border-t border-b border-gray-200 py-5 text-xs font-semibold text-gray-500 font-display">
            <div :class="['my-account-links', { active: component === 'EditGeneralInfo' }]" @click="selectInfo('EditGeneralInfo')" id="generalLink">
              <div class="pi pi-user-edit" style="fontsize: 1rem"></div>
              <span class="px-1.5">GENERAL</span>
            </div>
            <div :class="['my-account-links', { active: component === 'EditNotificationsInfo' }]" v-if="!isCme(loggedInUser)" @click="selectInfo('EditNotificationsInfo')" id="notificationsLink">
              <div class="pi pi-bell" style="fontsize: 1rem"></div>
              <span class="px-1.5">NOTIFICATIONS</span>
            </div>
            <div :class="['my-account-links', { active: component === 'EditSecurityInfo' }]" @click="selectInfo('EditSecurityInfo')" id="securityLink">
              <div class="pi pi-shield" style="fontsize: 1rem"></div>
              <span class="px-1.5">SECURITY</span>
            </div>
            <div :class="['my-account-links', { active: component === 'EditOrgUnitAccess' }]" id="groupAccountsLink" @click="selectInfo('EditOrgUnitAccess')" v-if="(isGP(loggedInUser) || isSpecialist(loggedInUser)) && loggedInUser.org_units.length > 0">
              <div class="pi pi-user-plus" style="fontsize: 1rem"></div>
              <span class="px-1.5">GROUP ACCOUNTS</span>
            </div>
            <div :class="['my-account-links', { active: component === 'EditFaxNumbers' }]" v-if="!isCme(loggedInUser)" id="preferencesLink" @click="selectInfo('EditFaxNumbers')">
              <div class="pi pi-phone" style="fontsize: 1rem"></div>
              <span class="px-1.5">FAX NUMBERS</span>
            </div>
          </div>
          <component :is="component" :loggedInUser="loggedInUser" @selectInfo="selectInfo"></component>
          <div class="absolute -bottom-16 left-0 right-0" v-if="isGP(loggedInUser) || isSpecialist(loggedInUser) || isCme(loggedInUser)">
            <Button class="cancel-btn p-button-outlined p-button-secondary p-button-sm" @click="closeAccount" id="closeAccountButton">Close Account</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditGeneralInfo from '@/components/my_account/EditGeneralInfo.vue';
import EditNotificationsInfo from '@/components/my_account/EditNotificationsInfo.vue';
import EditSecurityInfo from '@/components/my_account/EditSecurityInfo.vue';
import EditFaxNumbers from '@/components/my_account/EditFaxNumbers.vue';
import EditOrgUnitAccess from '@/components/my_account/EditOrgUnitAccess.vue';
import Button from 'primevue/button';

export default {
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'isSpecialist', 'isCme', 'allReferrals', 'isOrgUnitHead', 'actingUser', 'updateWhitelist', 'hasProvinceOfPractice']),
  },
  components: {
    EditGeneralInfo,
    EditNotificationsInfo,
    EditSecurityInfo,
    Button,
    EditFaxNumbers,
    EditOrgUnitAccess,
  },
  data() {
    return {
      file: null,
      success: '',
      errors: [],
      allReferralsMade: null,
      welcomeMessage: this.$route.query.referrer,
      openChangeProfilePictureModal: false,
      component: 'EditGeneralInfo',
    };
  },
  methods: {
    ...mapActions(['getAllReferralsOfUser', 'getReferralByOrgUnitIdAndUserId', 'getWhitelistedFaxNumbers', 'createWhitelistedFaxNumber', 'updateWhitelistedFaxNumber', 'deleteWhitelistedFaxNumber']),
    selectInfo(event) {
      if (this.component !== event) {
        this.component = event;
      }
    },
    closeAccount() {
      this.$router.push('closeaccount');
    },
    closeThis() {
      this.openChangeProfilePictureModal = false;
    },
  },
  created() {
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.getReferralByOrgUnitIdAndUserId({ orgUnitId: this.loggedInUser.org_units[0].id, userId: this.actingUser.id })
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {});
    } else if (!this.isCme(this.loggedInUser)) {
      this.getAllReferralsOfUser({
        user_id: this.actingUser.id,
        required_relations: ['referral_form', 'interaction_type', 'specialty', 'org_units', 'referee'],
      })
        .then(() => {
          if (this.allReferrals) {
            this.allReferralsMade = this.allReferrals.length;
          }
        })
        .catch(() => {});
    }
    if (this.$route.query.showFaxNumbers) {
      this.selectedScreen = this.$route.query.showFaxNumbers;
      this.selectInfo('EditFaxNumbers');
      this.isLoading = false;
    } else if (this.$route.query.subscribeToCalendar) {
      this.selectInfo('EditNotificationsInfo');
    }
  },
};
</script>
<style>
.height-1000 {
  height: 1000px;
}
.height-min-content {
  height: min-content;
}
.my-account-links {
  cursor: pointer;
  @apply mb-2 flex items-center border-b-2  border-transparent py-1 hover:border-red-500 hover:border-current hover:text-red-500 md:mb-0;
}
.my-account-links.active {
  @apply border-b-2 border-red-500  text-red-500;
}
</style>
