<template>
  <Accordion class="animated-quick fade-in-down" :activeIndex="0">
    <AccordionTab>
      <template #header>
        <span>Two Factor Authentication <ToggleSupportSectionButton :openedSupportSectionAccountAccordionIndex="3" /></span>
      </template>
      <div class="flex justify-between">
        <div class="p-text-left">
          <p v-if="twoFactorAuthEnabled">
            <span class="p-text-bold text-xl font-medium text-green-400">Two Factor Authentication Enabled</span>
            <br />
            Associated Phone number: {{ loggedInUser.phone_number }}
          </p>
          <div v-else>
            <h2 class="p-text-bold text-md font-medium text-gray-400 md:text-lg">Two Factor Authentication Disabled</h2>
            <p class="text-sm text-gray-500">We recommend enabling it to increase the security on your account.</p>
          </div>
        </div>
        <div class="p-text-right vertical-container p-grid p-ai-center">
          <InputSwitch v-model="twoFactorAuthEnabled" @change="twoFactorSwitchChanged" />
        </div>
        <Dialog header="Enable Two Factor Authentication" v-model:visible="twoFactorConfirmEnableDisplay" class="m-2 max-w-2xl" :modal="true" :dismissableMask="true" :draggable="false">
          Are you sure you want to enable two factor authentication? If you enable this feature, each time you try to login to your account a verification code will be sent to your phone number
          {{ loggedInUser.phone_number }} for extra security.<br /><br />
          If this number is incorrect, you can update your phone number in your account settings.<br /><br />
          A verification code will be sent to your phone to enable this feature. Do you wish to proceed?
          <div v-if="errors.length != 0" class="p-text-bold p-text-center pt-4 pb-4">
            <ul v-for="(error, i) in errors" :key="i" class="error">
              <li>{{ error }}</li>
            </ul>
          </div>
          <template #footer>
            <Button label="Close" icon="pi pi-times" @click="twoFactorConfirmEnableDisplay = false" class="p-button-outlined p-button-secondary" />
            <Button label="Yes" icon="pi pi-check" @click="confirmEnableTwoFactor" autofocus />
          </template>
        </Dialog>
        <Dialog header="Disable Two Factor Authentication" v-model:visible="twoFactorConfirmDisableDisplay" class="m-2 max-w-2xl" :modal="true" :dismissableMask="true" :draggable="false">
          Are you sure you want to disable two factor authentication? This will make your profile less secure.<br /><br />
          A verification code will be sent to your phone before you can can change your settings.
          <div v-if="errors.length != 0" class="p-text-bold p-text-center pt-4 pb-4">
            <ul v-for="(error, i) in errors" :key="i" class="error">
              <li>{{ error }}</li>
            </ul>
          </div>
          <template #footer>
            <Button label="Close" icon="pi pi-times" @click="twoFactorConfirmDisableDisplay = false" class="p-button-outlined p-button-secondary" />
            <Button label="Yes" icon="pi pi-check" @click="confirmEnableTwoFactor" autofocus />
          </template>
        </Dialog>
        <Dialog header="Confirm Change Two Factor Authentication Settings" v-model:visible="twoFactorEnableEnterCodeDisplay" class="m-2 max-w-2xl" :modal="true" :draggable="false">
          <div class="mt-8">
            <p class="success p-text-center mb-5">
              A verification code has been sent to your phone number
              {{ loggedInUser.phone_number }}.
            </p>
            <p class="warning p-text-center mt-3 mb-3">
              The code will expire in:
              <CountDownTimer :countDownInSeconds="300" />
            </p>
            <div class="p-text-center">
              <div class="input-field mb-4">
                <label for="email">Please Enter The Verification Code</label>
                <br />
                <InputText type="text" v-model="verificationCodeToEnable2fa" required autofocus maxLength="255" />
              </div>
              <div class="mb-4">
                <Button label="Update Two Factor Authentication Settings" @click="verifyOtp" />
              </div>
              <div class="mb-4">
                <Button label="Resend Verification Code" class="p-button-outlined" @click="reSendOtp" />
              </div>
              <div v-if="errors.length != 0" class="p-text-bold p-text-center pt-4 pb-4">
                <ul v-for="(error, i) in errors" :key="i" class="error">
                  <li>{{ error }}</li>
                </ul>
              </div>
            </div>
          </div>
          <template #footer>
            <Button label="Dismiss" icon="pi pi-times" @click="twoFactorEnableEnterCodeDisplay = false" class="p-button-outlined" />
          </template>
        </Dialog>
      </div>
    </AccordionTab>
    <AccordionTab>
      <template #header>
        <span>Password Settings <ToggleSupportSectionButton :openedSupportSectionAccountAccordionIndex="4" /></span>
      </template>

      <form method="post" @submit.prevent="submit" class="py-4" v-if="user">
        <div class="grid grid-cols-1 gap-1">
          <div class="mb-5" v-if="isNativePlatform()">
            <Button class="p-button p-button-outlined" label="Enable Login with Face ID" icon="fas fa-fingerprint" @click="updateBiometricSetting"></Button>
          </div>
          <div class="mb-2">
            <label class="auth-input">Please Enter Your Current Password </label>
            <Password name="currentPassword" :inputId="'currentPassword'" v-model="currentPassword" :toggleMask="true" :feedback="false" required class="auth-input" maxLength="255" />
          </div>
          <div class="mb-2">
            <label class="auth-input"> Please Enter Your New Password </label>
            <Password name="newPassword" :inputId="'newPassword'" v-model="newPassword" :toggleMask="true" :feedback="true" required class="auth-input" maxLength="255" />
          </div>
          <div class="mb-6">
            <label class="auth-input"> Please Confirm Your New Password </label>
            <Password name="confirmNewPassword" :inputId="'confirmNewPassword'" v-model="confirmNewPassword" :toggleMask="true" :feedback="false" required class="auth-input" maxLength="255" />
          </div>
        </div>
        <div class="flex justify-end"><Button type="submit" label="Confirm Change Password" icon="pi pi-check" id="confirmChangesButton"></Button></div>
      </form>
      <div v-if="passwordErrors.length != 0">
        <div colspan="2" style="text-align: left" class="error">
          Your new password must have:
          <ul v-for="(error, i) in passwordErrors" :key="i">
            <li>{{ error }}</li>
          </ul>
        </div>
      </div>
    </AccordionTab>
  </Accordion>
  <div v-if="errors.length != 0">
    <ul v-for="(error, i) in errors" :key="i" class="error w-full flex justify-center mt-2">
      <li>{{ error }}</li>
    </ul>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
  <Dialog v-model:visible="showBiometricSettingModal" modal header="Face ID Settings" :style="{ width: '90vw' }">
    <div v-if="openBiometricSetting" class="flex flex-col items-center">
      <div class="my-3">Please enable Face ID . Click the button below to open settings, then turn on the Face ID option.</div>
      <Button class="p-button p-button-outlined" label="Open Settings" icon="pi pi-cog" @click="openNativeAppSettings"></Button>
    </div>
    <div v-else-if="biometricSet">You already have Face ID enabled for your account.</div>
    <div v-else>Your Face ID settings have been updated, please logout and login with your password to complete this process.</div>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import InputSwitch from 'primevue/inputswitch';
import Dialog from 'primevue/dialog';
import CountDownTimer from '@/components/misc/CountDownTimer.vue';
import InputText from 'primevue/inputtext';
import { NativeSettings, IOSSettings } from 'capacitor-native-settings';
import { NativeBiometric } from 'capacitor-native-biometric';

export default {
  props: ['loggedInUser'],
  emits: ['selectInfo'],
  components: {
    Password,
    Button,
    Loading,
    Accordion,
    AccordionTab,
    InputSwitch,
    Dialog,
    CountDownTimer,
    InputText,
  },
  data() {
    return {
      user: this.loggedInUser,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      errors: [],
      passwordErrors: [],
      isLoading: false,
      twoFactorAuthEnabled: this.loggedInUser.profile.two_factor_auth_enabled,
      twoFactorConfirmEnableDisplay: false,
      twoFactorConfirmDisableDisplay: false,
      twoFactorEnableEnterCodeDisplay: false,
      verificationCodeToEnable2fa: '',
      showBiometricSettingModal: false,
      openBiometricSetting: false,
      biometricSet: false,
    };
  },
  methods: {
    ...mapActions(['changePassword', 'sendOtpToUser', 'update2faSettings']),
    twoFactorSwitchChanged() {
      if (this.twoFactorAuthEnabled) {
        this.twoFactorConfirmEnableDisplay = true;
      } else {
        this.twoFactorConfirmDisableDisplay = true;
      }
      this.twoFactorAuthEnabled = !this.twoFactorAuthEnabled;
    },
    confirmEnableTwoFactor() {
      this.isLoading = true;
      this.errors = [];
      this.sendOtpToUser({ email: this.loggedInUser.email })
        .then(() => {
          this.isLoading = false;
          this.twoFactorConfirmEnableDisplay = false;
          this.twoFactorConfirmDisableDisplay = false;
          this.twoFactorEnableEnterCodeDisplay = true;
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.status == 500) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          } else this.errors.push(err.data.error);
        });
    },
    reSendOtp() {
      this.isLoading = true;
      this.errors = [];
      this.$toast.removeAllGroups();
      this.sendOtpToUser({ email: this.loggedInUser.email })
        .then((res) => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'info',
            summary: 'Verification Code Resent',
            detail: res.data.message,
            life: 5000,
          });
        })
        .catch((err) => {
          if (err.status == 500) {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          } else this.errors.push(err.data.error);
        });
    },
    verifyOtp() {
      this.errors = [];
      if (this.verificationCodeToEnable2fa == '') {
        this.errors.push('Please enter the verification code that has been sent to your phone.');
      } else {
        this.$toast.removeAllGroups();
        this.isLoading = true;
        this.update2faSettings({
          userId: this.loggedInUser.id,
          verificationCode: this.verificationCodeToEnable2fa,
          twoFactorAuthEnabled: !this.twoFactorAuthEnabled,
        })
          .then((res) => {
            this.isLoading = false;
            this.twoFactorEnableEnterCodeDisplay = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Two Factor Authentication Settings Updated',
              detail: res.data.message,
              life: 5000,
            });
            this.verificationCodeToEnable2fa = '';
            this.twoFactorAuthEnabled = !this.twoFactorAuthEnabled;
          })
          .catch((err) => {
            this.isLoading = false;
            if (err.status == 500) {
              this.$toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
                life: 10000,
              });
            } else this.errors.push(err.data.error);
          });
      }
    },
    submit() {
      this.passwordErrors = this.validatePassword(this.newPassword, this.confirmNewPassword);
      if (this.currentPassword == '') {
        this.passwordErrors.push('Please enter your current password.');
      }
      if (this.passwordErrors.length == 0) {
        this.isLoading = true;
        this.$toast.removeAllGroups();
        this.errors = [];
        this.changePassword({
          userId: this.user.id,
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          newPasswordConfirmation: this.confirmNewPassword,
        })
          .then(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Password Updated',
              detail: 'Your password has been successfully updated.',
              life: 5000,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.status == 422) {
              if (error.data.errors.password && error.data.errors.password[0]?.includes('uncompromised')) {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Please Choose Different Password',
                  detail: 'It looks like that password is a weak password.',
                  life: 10000,
                });
              } else {
                for (const key in error.data.errors) {
                  error.data.errors[key].forEach((element) => {
                    this.errors.push(element);
                  });
                }
              }
            } else {
              this.showGenericErrorToast();
            }
          });
      }
    },
    updateBiometricSetting() {
      NativeBiometric.verifyIdentity()
        .then(async () => {
          const credentials = await NativeBiometric.getCredentials({
            server: 'app.virtualhallway.ca',
          });
          if (credentials) {
            this.biometricSet = true;
          } else {
            this.biometricSet = false;
          }
          this.showBiometricSettingModal = true;
        })
        .catch(() => {
          this.openBiometricSetting = true;
          this.showBiometricSettingModal = true;
        });
    },
    openNativeAppSettings() {
      NativeSettings.openIOS({
        option: IOSSettings.App,
      });
    },
  },
};
</script>

// Style coped from: https://www.w3schools.com/css/tryit.asp?filename=trycss_table_padding
<style scoped>
table,
td,
th {
  border: 1px solid #ddd;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

table {
  border-collapse: collapse;
  width: 50%;
}

th,
td {
  padding: 15px;
}

thead {
  background-color: rgb(46, 43, 43);
  color: white;
}
</style>
