<template lang="">
  <Dialog v-model:visible="visible" modal header="Consult Reschedule" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '40vw', maxWidth: '450px' }" draggable="false">
    <template v-if="isLoading">
      <div class="flex flex-row justify-center m-12">
        <ProgressSpinner />
      </div>
    </template>
    <template v-else>
      <div class="text-gray-500">
        <template v-if="specialistRequestedRebook"> Selecting accept will cancel this consult and send an email to the consulting practitioner asking them to reschedule with you. </template>
        <template v-else> By selecting accept, you will be taken to the consult booking process to reschedule. After you finish rescheduling, this consult will be cancelled. This consult request information will be carried over to the new consult.</template>
      </div>
      <div class="flex flex-col mt-3">
        <label for="" class="font-semibold text-gray-500 text-xs mb-2">Details regarding the request to reschedule (Optional) </label>
        <Textarea v-model="cancellationDetails" id="referralCancellationDetails" rows="2" class="w-full text-sm" autofocus />
      </div>
      <div class="flex flex-row justify-end mt-5">
        <div class="mr-3">
          <Button label="Close" class="p-button-sm p-button-secondary p-button-outlined w-full" @click="$emit('modalClosed')"></Button>
        </div>
        <div v-if="specialistRequestedRebook">
          <Button label="Accept" class="p-button-sm p-button-primary w-full" @click="specialistRebookReferral"></Button>
        </div>
        <div v-else>
          <Button label="Accept" class="p-button-sm p-button-primary w-full" @click="rebookReferral"></Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['referral', 'showConfirmReferralRebookModal', 'specialistRequestedRebook'],
  emits: ['modalClosed', 'consultRescheduledBySpecialist'],
  data() {
    return {
      cancellationDetails: null,
      isLoading: false,
      visible: false,
    };
  },
  created() {
    this.visible = true;
    if (this.referral.status == 'Cancelled' || this.referral.status == 'Complete') {
      this.rebookReferral();
    }
  },
  computed: {
    ...mapGetters(['actingUser', 'loggedInUser']),
  },
  methods: {
    ...mapActions(['setReferralToRebook', 'cancelReferral', 'checkCurrentUsersAvailability']),
    rebookReferral() {
      this.referral.cancellation_details = this.cancellationDetails;
      this.setReferralToRebook(this.referral)
        .then(() => {
          this.$router.push('/makereferral');
          this.visible = false;
          this.$emit('modalClosed');
        })
        .catch(() => {});
    },
    specialistRebookReferral() {
      let data = {};
      data.userId = this.loggedInUser.id;
      data.reason_for_failed = 'Request To Reschedule';
      data.cancellation_details = this.cancellationDetails;
      data.referral = this.referral;
      this.isLoading = true;
      this.cancelReferral(data)
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Reschedule Message Sent',
            detail: 'The referring practitioner was notified to reschedule the consult.',
            life: 5000,
          });
          this.$emit('consultRescheduledBySpecialist');
          this.visible = false;
          this.$emit('modalClosed');
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
          this.visible = false;
          this.$emit('modalClosed');
        });
    },
    closeDialog() {
      this.visible = false;
      this.$emit('modalClosed');
    },
  },
};
</script>
<style lang=""></style>
