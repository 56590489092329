<template>
  <div class="grid lg:grid-cols-1 xl:grid-cols-2" :class="isNativePlatform() ? 'bg-white !h-screen' : ''">
    <div class="justify-left hidden items-center p-6 xl:flex pc-bg" :class="{ 'sp-bg': background_role === 'sp', 'cme-bg': background_role === 'cme' }"></div>
    <div class="flex w-full flex-col justify-center py-12">
      <div class="flex flex-col items-center w-full max-w-full justify-center px-2 pb-8 text-center">
        <div class="max-w-lg animated fade-in-up rounded-2xl p-4 sm:p-10 mb-4" :class="isNativePlatform() ? '' : 'shadow card'">
          <h2 class="text-gray-500 text-xs font-medium mb-2" v-if="background_role != 'cme'">Welcome to Virtual Hallway</h2>
          <h1 v-if="!background_role" class="text-xl font-bold mb-6">Sign up for your free account</h1>
          <h1 v-if="background_role == 'sp'" class="text-xl font-bold mb-6">Sign up as a specialist</h1>
          <h1 v-if="background_role == 'fp'" class="text-xl font-bold mb-6">Sign up as a family physician</h1>
          <h1 v-if="background_role == 'np'" class="text-xl font-bold mb-6">Sign up as a nurse practitioner</h1>
          <h1 v-if="background_role == 'cme'" class="text-xl font-bold mb-6">Lecture Registration</h1>
          <h2 v-if="background_role == 'cme'" class="text-gray-500 text-xs font-medium -mt-4 mb-8">Wondering if you are eligible to join? <CmeEligibilityDialog /></h2>
          <RegistrationForm :loggedInUser="loggedInUser" />
        </div>
        <div class="text-sm mb-4 font-display">Already have an account? <span class="text-primary cursor-pointer" @click="$router.push('/login')">Log in here</span>&nbsp;</div>
      </div>
      <div class="flex flex-row justify-around bg-white text-xs underline" v-if="isNativePlatform()">
        <a href="https://virtualhallway.ca/privacy-policy/" class="">
          <div>General Privacy Policy</div>
        </a>
        <a href="https://virtualhallway.ca/platform-privacy-policy/" class="">
          <div>Platform Privacy Policy</div>
        </a>
        <a href="https://virtualhallway.ca/terms-of-service/" class="">
          <div>Terms Conditions</div>
        </a>
      </div>
    </div>
  </div>

  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import RegistrationForm from '@/views/public/registration/registrationForm/RegistrationForm.vue';
import Loading from 'vue-loading-overlay';

export default {
  data() {
    return {
      // might not need
      background_role: '',
      response: '',
      isLoading: false,
    };
  },
  components: {
    RegistrationForm,
    Loading,
  },
};
</script>

<style scoped>
.process-dropdown-text {
  margin-top: 10px;
  text-align: left;
  padding: 0px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  transition: opacity 0.5s ease;
  height: 0px;
}

.process-dropdown-text.active {
  visibility: visible;
  opacity: 1;
  height: 100%;
  padding: 30px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(400px);
  opacity: 0;
}

.pc-bg {
  background-image: url('@/assets/Registration-BG.jpg');
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.sp-bg {
  background-image: url('@/assets/Registration-BG-2.jpg');
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.cme-bg {
  /* find image to use */
  background-image: url('@/assets/Registration-BG-3.jpg');
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
