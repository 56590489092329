<template>
  <div class="align-start relative mt-4 flex w-full items-center justify-between bg-white text-left" :class="completeView ? 'border-solid border-gray-300 border-1 shadow rounded-2xl p-4 md:p-6' : ''">
    <div class="w-full">
      <div class="mb-3 flex flex-col md:flex-row items-start justify-between border-b pb-4">
        <div class="flex flex-row">
          <div class="text-blue-400">
            <h2 class="text-left font-semibold text-lg text-gray-600" v-if="referral.interaction_type.name === 'phone'">Consult Details</h2>
            <h2 class="text-left font-semibold text-lg text-gray-600 flex flex-row" v-else-if="referral.interaction_type.name === 'econsult'">
              {{ referral.interaction_type.display_name }}<span class="flex"><i class="fa fa-plus text-pink-500 flex pt-0.5" style="font-size: 0.7rem; font-weight: 900"></i>&nbsp;</span> Details
            </h2>
            <div class="flex flex-row" style="align-items: center">
              <h5 class="text-xs text-gray-500">Last Updated: {{ formatDateTime(referral.updated_at) }} {{ getUserTimeZoneAbbr() }}</h5>
            </div>
          </div>
        </div>
        <div class="grid gap-2 md:flex md:flex-row md:flex-wrap mt-4 md:mt-0 w-full sm:w-fit">
          <template v-if="completeView">
            <template v-if="referral.status !== 'Pending' && referral.status !== 'Complete' && referral.status !== 'Cancelled' && referral.interaction_type.name === 'phone' && canEditReferralResponseForm(loggedInUser, referral.referral_person, referral)">
              <div class="flex flex-row flex-wrap justify-center" v-if="isNativePlatform()">
                <Button label="Call" class="p-button-sm p-button w-full p-button-success" @click="startCall" id="startCallButton"></Button>
              </div>
              <div class="md:hidden flex flex-row flex-wrap justify-center" v-else>
                <a class="w-full" :href="`tel:${referral.referee.phone_number}`"><Button label="Call User" class="p-button-sm p-button w-full p-button-success" icon="pi pi-phone"></Button></a>
              </div>
            </template>
            <div v-if="referral.status === 'In Review' && canEditReferralResponseForm(loggedInUser, referral.referral_person, referral) && !referral.running_late_sent && hasNotificationEnabled(referral.referee, 'consult_running_late', 'SMS') && checkIfLessThanOneDayAfterReferralCall() && referral.interaction_type.name === 'phone'" class="flex flex-row flex-wrap justify-center">
              <Button icon="pi pi-comments" label="Send Running Late Message" class="p-button-outlined p-button-secondary p-button-sm w-full" @click="clickSendRunningLateMessage" />
            </div>
            <div class="flex flex-wrap">
              <CancelReferral :referral="referral" />
            </div>
            <div class="hidden md:flex flex-col flex-wrap group -mt-1 relative justify-center items-center" v-if="referral.status === 'Complete' && (loggedInUser.id === referral.referee.id || hasBeenThanked)" v-tooltip.bottom="toolTipMessage">
              <div tabindex="0" class="flex flex-col justify-center items-center thank-you-wrapper" :class="[loggedInUser.id === referral.referee.id && !hasBeenThanked ? 'transform transition duration-500 ease-in-out hover:scale-110' : '', loggedInUser.id === referral.referee.id ? 'cursor-pointer' : '', loggedInUser.id === referral.referral_person.id && referral?.thank_you.message ? 'cursor-pointer' : '', loggedInUser.id === referral.referral_person.id && !referral?.thank_you.liked_by_receiver ? 'cursor-pointer' : '']" @click="thankYouOnClick" @mouseleave="leaveSendMessage">
                <span :key="hasBeenThanked" class="relative">
                  <div class="particles absolute top-[12px] left-[8px]" style="--total-particles: 6">
                    <div class="particle" style="--i: 1; --color: #7642f0"></div>
                    <div class="particle" style="--i: 2; --color: #afd27f"></div>
                    <div class="particle" style="--i: 3; --color: #de8f4f"></div>
                    <div class="particle" style="--i: 4; --color: #d0516b"></div>
                    <div class="particle" style="--i: 5; --color: #5686f2"></div>
                    <div class="particle" style="--i: 6; --color: #d53ef3"></div>
                  </div>
                  <i class="fas fa-heart text-blue-700 absolute top-1 left-2" style="font-size: 1rem" v-if="referral?.thank_you?.liked_by_receiver" />
                  <i class="fas fa-heart" :class="[hasBeenThanked ? 'text-red-400' : '', loggedInUser.id === referral.referee.id ? 'group-hover:text-red-400' : '']" style="font-size: 1rem" />
                </span>
                <i class="fas fa-hand-holding -mt-4" style="font-size: 1.5rem" />
              </div>
              <template v-if="displaySendThankYouMessage">
                <div class="absolute w-36 h-10 top-12 justify-center items-center bg-white border border-gray-900 rounded-lg font-semibold send-thank-you-tooltip -right-[52px] flex cursor-pointer text-sm" @click="sendThankYouMessageOnClick" id="sendThankYouMessage" @mouseenter="clearTimeOutSendThankYouMessage" @mouseleave="leaveSendMessage">
                  <span>Send Message</span>
                </div>
              </template>
              <SendThankYouMessageDialog :referral="referral" ref="sendThankYouMessageDialog" />
              <ThankYouMessageDialog :referral="referral" ref="thankYouMessageDialog" />
            </div>
            <div v-if="referral.status === 'In Review' && canEditReferralForm(loggedInUser, referral.referee, referral) && !referral.nudge_sent && hasNotificationEnabled(referral.referral_person, 'consult_nudge', 'SMS') && checkIfLessThanOneDayAfterReferralCall() && referral.interaction_type.name === 'phone'" class="flex flex-row flex-wrap justify-center">
              <Button icon="pi pi-comments" label="Send Nudge Message" class="p-button-outlined p-button-secondary p-button-sm w-full" @click="clickSendNudgeMessage" />
            </div>
            <div class="flex flex-row flex-wrap justify-center" v-if="referral.status !== 'Pending' && referral.interaction_type.name === 'phone' && canEditReferralForm(loggedInUser, referral.referee, referral)">
              <Button label="Reschedule" v-if="referral.status !== 'Cancelled' && referral.status !== 'Complete'" class="p-button-sm p-button-secondary w-full" icon="pi pi-refresh" @click="showConfirmReferralRebookModal = true"></Button>
              <Button label="Book Again" v-else class="p-button-sm p-button-secondary w-full" icon="pi pi-refresh" @click="showConfirmReferralRebookModal = true" v-tooltip="'Click here to carry over this consult request information and book a new consult with any consultant.'"></Button>
            </div>
            <div class="flex flex-row flex-wrap justify-center" v-else-if="referral.status !== 'Pending' && referral.status !== 'Cancelled' && referral.status !== 'Complete' && referral.interaction_type.name === 'phone' && canEditReferralResponseForm(loggedInUser, referral.referral_person, referral)">
              <Button label="Reschedule" class="p-button-secondary p-button-sm w-full" icon="pi pi-refresh" @click="showConfirmReferralRebookModal = true" v-tooltip="'We\'ll cancel the consult and send an email to the referee asking them to reschedule with you.'"></Button>
            </div>
          </template>
          <div class="relative flex flex-row flex-wrap justify-center">
            <template v-if="showFaxConsultToolTip">
              <div class="hide-mobile new-fax-feature-tool-tip absolute right-48 z-50 w-72 rounded-md bg-blue-500 py-4 px-4 text-left text-white">
                <h1 class="text-lg font-bold uppercase"><span>&#10024;</span> Try Faxing a Consult!</h1>
                <p class="clear-left mt-1 text-xs font-bold">You can now fax your consults to a white listed fax number. Click the "Download" button and then choose the "Fax Consult" option.</p>
                <div class="mt-2 ml-auto mr-2 flex flex-row justify-end"><button class="new-fax-feature-tool-tip-btn text-xs font-bold text-white" @click="closeFaxConsultToolTip" id="closeFaxConsultToolTipBtn">CLOSE</button></div>
              </div>
            </template>

            <SplitButton v-if="referral.status !== 'Pending'" label="Download" class="p-button-sm p-button-secondary w-full" icon="pi pi-download" @click="toggleReferralMenu($event)" :model="referralMenuItems"></SplitButton>
          </div>
        </div>
        <TieredMenu ref="menu" :model="referralMenuItems" :popup="true" />
        <FaxConsultDialog :show="showFaxConsultDialog" @closeFaxConsultDialog="showFaxConsultDialog = false" :referral="referral" :fax_document="fax_document" :documentId="faxDocumentId" :displayEditFaxOption="true" />
      </div>

      <div class="mb-2 grid w-full gap-2 text-left sm:grid-cols-1" :class="completeView ? 'md:grid-cols-4' : 'md:grid-cols-2'">
        <template v-if="referral.interaction_type.name === 'phone'">
          <div class="border-b border-solid border-gray-100 pb-1" :class="{ 'col-start-1 col-end-3': !isCrossBorderSpecialist(loggedInUser) && completeView }">
            <div class="consult-field-label">Patient Name</div>
            <div class="consult-field-text">
              <p v-if="referralForm.patient_first_name">
                {{ referralForm.patient_first_name }}
                {{ referralForm.patient_last_name }}
              </p>
              <p v-else>N/A</p>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1" v-if="isCrossBorderSpecialist(loggedInUser)">
            <div class="consult-field-label">Consult Province</div>
            <div class="consult-field-text">
              <span>{{ referral.specialty.practice_province.name }}</span>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div v-if="referral.call_asap == true">
              <div class="consult-field-label">Expect Call By</div>
              <div class="consult-field-text">
                {{ formatDate(referral.end_datetime) }}
              </div>
            </div>
            <div v-else>
              <div class="consult-field-label">Date of Consult</div>
              <div class="consult-field-text">
                {{ formatDate(referral.start_datetime) }}
              </div>
            </div>
          </div>
          <div v-if="referral.call_asap == false" class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Consult Time</div>
            <div class="consult-field-text">{{ formatTime(referral.start_datetime) }} {{ getUserTimeZoneAbbr() }}</div>
          </div>
          <div v-else></div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">{{ healthCardProvinceOptionsRequireIdNumber().includes(referralForm.health_card_province) ? 'ID Number' : 'Health Card/ID Number' }}</div>
            <div class="consult-field-text">
              <p v-if="referralForm.health_card_number">{{ referralForm.health_card_number }}</p>
              <p v-else>N/A</p>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Health Card/Policy Type</div>
            <div class="consult-field-text">
              <p v-if="referralForm.health_card_province">{{ referralForm.health_card_province }}</p>
              <p v-else>N/A</p>
            </div>
          </div>
          <div :class="['pb-1', 'border-b', 'border-gray-100', 'border-solid']">
            <div class="consult-field-label">Referring Practitioner Name</div>
            <div class="consult-field-text">
              {{ referral.referee_first_name }}
              {{ referral.referee_last_name }}
              <span v-if="canEditReferralResponseForm(loggedInUser, referral.referral_person, referral)">
                <span v-if="referral.referee.role == 'gp' && referral.referee.provider_type == 'NP'">(Nurse Practitioner)</span>
                <span v-else-if="referral.referee.role == 'gp' && referral.referee.provider_type != 'NP'">(Family Doctor)</span>
                <span v-else-if="referral.referee.role == 'specialist'">(Specialist)</span>
              </span>
              <template v-if="referral.org_units.find((org_unit) => org_unit.pivot.user_id === referral.referee_id)">At {{ referral.org_units.find((org_unit) => org_unit.pivot.user_id === referral.referee_id).name }} </template>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1" v-if="referral.interaction_type.name === 'phone'">
            <div class="consult-field-label">Referring Practitioner Phone</div>
            <div class="consult-field-text">
              <a :href="'tel:' + referralForm.alternative_phone_number" v-if="referralForm.alternative_phone_number"> {{ formatPhoneNumber(referralForm.alternative_phone_number) }}</a>
              <a :href="'tel:' + referral.referee.phone_number" v-else> {{ formatPhoneNumber(referral.referee_phone_number) }}</a>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Patient Date of Birth</div>
            <div class="consult-field-text">
              <p v-if="referralForm.patient_dob" class="flex flex-row">
                {{ formatDateYYYYMMDD(referralForm.patient_dob) }}
                <span class="px-2">(Age: {{ formatAge(referralForm.patient_dob) }})</span>
              </p>
              <p v-else>N/A</p>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Patient Sex</div>
            <div class="consult-field-text">
              {{ referralForm.patient_sex }}
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Specialist Name</div>
            <div class="consult-field-text">
              {{ referral.referral_person_first_name }}
              {{ referral.referral_person_last_name }}
              <template v-if="referral.org_units.find((org_unit) => org_unit.pivot.user_id === referral.referral_person_id)">At {{ referral.org_units.find((org_unit) => org_unit.pivot.user_id === referral.referral_person_id).name }} </template>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Specialty</div>
            <div class="consult-field-text">
              {{ referral.specialty.name }}
            </div>
          </div>
        </template>
        <template v-if="referral.interaction_type.name === 'econsult'">
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Patient Name</div>
            <div class="consult-field-text">
              <p v-if="referralForm.patient_first_name">
                {{ referralForm.patient_first_name }}
                {{ referralForm.patient_last_name }}
              </p>
              <p v-else>N/A</p>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Patient Date of Birth</div>
            <div class="consult-field-text">
              <p v-if="referralForm.patient_dob" class="flex flex-row">
                {{ formatDateYYYYMMDD(referralForm.patient_dob) }}
                <span class="px-2">(Age: {{ formatAge(referralForm.patient_dob) }})</span>
              </p>
              <p v-else>N/A</p>
            </div>
          </div>
          <div :class="['pb-1', 'border-b', 'border-gray-100', 'border-solid']">
            <div class="consult-field-label">Referring Practitioner Name</div>
            <div class="consult-field-text">
              {{ referral.referee_first_name }}
              {{ referral.referee_last_name }}
              <span v-if="canEditReferralResponseForm(loggedInUser, referral.referral_person, referral)">
                <span v-if="referral.referee.role == 'gp' && referral.referee.provider_type == 'NP'">(Nurse Practitioner)</span>
                <span v-else-if="referral.referee.role == 'gp' && referral.referee.provider_type != 'NP'">(Family Doctor)</span>
                <span v-else-if="referral.referee.role == 'specialist'">(Specialist)</span>
              </span>
              <template v-if="referral.org_units.find((org_unit) => org_unit.pivot.user_id === referral.referee_id)">At {{ referral.org_units.find((org_unit) => org_unit.pivot.user_id === referral.referee_id).name }} </template>
            </div>
          </div>

          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Specialist Name</div>
            <div class="consult-field-text">
              {{ referral.referral_person_first_name }}
              {{ referral.referral_person_last_name }}
              <template v-if="referral.org_units.find((org_unit) => org_unit.pivot.user_id === referral.referral_person_id)">At {{ referral.org_units.find((org_unit) => org_unit.pivot.user_id === referral.referral_person_id).name }} </template>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">{{ healthCardProvinceOptionsRequireIdNumber().includes(referralForm.health_card_province) ? 'ID Number' : 'Health Card/ID Number' }}</div>
            <div class="consult-field-text">
              <p v-if="referralForm.health_card_number">{{ referralForm.health_card_number }}</p>
              <p v-else>N/A</p>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Health Card/Policy Type</div>
            <div class="consult-field-text">
              <p v-if="referralForm.health_card_province">{{ referralForm.health_card_province }}</p>
              <p v-else>N/A</p>
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Patient Sex</div>
            <div class="consult-field-text">
              {{ referralForm.patient_sex }}
            </div>
          </div>
          <div class="border-b border-solid border-gray-100 pb-1">
            <div class="consult-field-label">Specialty</div>
            <div class="consult-field-text">
              {{ referral.specialty.name }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="viewCallModal" modal :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }">
    <template #header>
      <h2>Call {{ referral.referee.full_name }}</h2>
    </template>
    <div class="flex flex-row justify-center m-2">
      <img v-bind:src="referral.referral_person.profile.profile_picture_location" class="h-24 w-24 rounded-full bg-white object-cover" v-if="referral.referral_person.profile.profile_picture_location" />
      <div class="mr-2 h-12 w-12 rounded-full bg-white" v-else>
        <i class="pi pi-user"></i>
      </div>
    </div>
    <div class="flex flex-row justify-center m-2 text-gray-600 font-semibold">
      {{ referral.referee.full_name }}
    </div>
    <div class="flex flex-row justify-center m-5" v-if="!calling">
      <Button label="Confirm Call" icon="pi pi-phone" @click="confirmCall"></Button>
    </div>
    <div class="flex flex-col items-center m-5" v-else>
      <i class="pi pi-phone text-primary animate-bounce mb-5" style="font-size: 2rem"></i>
      <Button label="Hang Up" icon="pi pi-times" class="p-button p-button-outlined text-xs" @click="hangUpCall"></Button>
    </div>
  </Dialog>

  <Dialog v-model:visible="showVoipOrPstnCallDialog" modal :style="{ width: '92vw' }">
    <div class="flex flex-row justify-between">
      <Button class="p-button p-button-outlined" label="VoIP Call" @click="makeCall('voip')" />
      <Button class="p-button p-button-outlined" label="PSTN Call" @click="makeCall('pstn')" />
    </div>
  </Dialog>
  <ConfirmDialog></ConfirmDialog>
  <PromptToAddAvailability v-if="showPromptToAddAvailabilityModal" :showPromptToAddAvailability="showPromptToAddAvailabilityModal" @modalClosed="showPromptToAddAvailabilityModal = false" :promtFromReferralReschedule="true" />
  <ConfirmReferralRebook v-if="showConfirmReferralRebookModal" :referral="referral" :showConfirmReferralRebookModal="showConfirmReferralRebookModal" :specialistRequestedRebook="canEditReferralResponseForm(loggedInUser, referral.referral_person, referral)" @consultRescheduledBySpecialist="consultRescheduledBySpecialist" @modalClosed="showConfirmReferralRebookModal = false" />
</template>

<script>
import SplitButton from 'primevue/splitbutton';
import Button from 'primevue/button';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import TieredMenu from 'primevue/tieredmenu';
import FaxConsultDialog from './FaxConsultDialog.vue';
import SendThankYouMessageDialog from './SendThankYouMessageDialog.vue';
import ThankYouMessageDialog from './ThankYouMessageDialog.vue';
import CancelReferral from './CancelReferral.vue';
import CapacitorCallPlugin from '@/plugins/CapacitorCallPlugin';
import PromptToAddAvailability from '../../components/specialist/PromptToAddAvailability.vue';
import ConfirmReferralRebook from './ConfirmReferralRebook.vue';

export default {
  props: ['referral', 'completeView'],
  components: {
    Button,
    SplitButton,
    TieredMenu,
    FaxConsultDialog,
    CancelReferral,
    SendThankYouMessageDialog,
    ThankYouMessageDialog,
    ConfirmReferralRebook,
  },
  data() {
    return {
      feedbackText: '',
      feedbackError: '',
      currentTime: moment(),
      error: '',
      showFaxConsultDialog: false,
      fax_document: '',
      referralMenuItems: [],
      referralForm: null,
      showFaxConsultToolTip: false,
      hasBeenThanked: false,
      displaySendThankYouMessage: false,
      displaySendThankYouMessageTimer: null,
      loadingButton: false,
      showPromptToAddAvailabilityModal: false,
      showConfirmReferralRebookModal: false,
      faxDocumentId: null,
      showVoipOrPstnCallDialog: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'canEditReferralForm', 'canEditReferralResponseForm', 'getUserActionByName', 'isCrossBorderSpecialist', 'isSpecialist', 'actingUser', 'acsToken', 'hasValidAcsToken']),
    toolTipMessage() {
      if (this.isSpecialist(this.loggedInUser)) {
        let message = `${this.referral.referee.first_name} says thank you!`;
        if (!this.referral?.thank_you.message && !this.referral?.thank_you.liked_by_receiver) {
          message += ' Like this thank you!';
        }
        return message;
      } else if (this.isGP(this.loggedInUser) && !this.hasBeenThanked) {
        return `Say thanks`;
      } else if (this.isGP(this.loggedInUser) && this.referral?.thank_you?.liked_by_receiver) {
        return `${this.displayNameByRole(this.referral.referral_person)} liked your thank you!`;
      }
    },
  },
  methods: {
    ...mapActions(['setReferralToRebook', 'faxPdfOfReferralForm', 'downloadPdfOfReferralForm', 'downloadPdfOfResponseForm', 'downloadReferralIcsFile', 'downloadPdfOfCombinedForm', 'sendRunningLateMessage', 'sendNudgeMessage', 'cancelReferral', 'createOrUpdateThankYou', 'likeThankYou', 'checkCurrentUsersAvailability']),
    setOptionsMenuItems() {
      this.referralMenuItems = [];
      let interaction_type = '';
      if (this.referral.interaction_type.name === 'phone') {
        interaction_type = 'Consult';
        this.referralMenuItems.push({
          label: 'Calendar Event (.ics File)',
          icon: 'pi pi-calendar',
          disabled: this.referral.status !== 'Upcoming',
          command: () => {
            const user_id = this.canEditReferralForm(this.loggedInUser, this.referral.referee, this.referral) ? this.referral.referee.id : this.referral.referral_person.id;
            this.downloadReferralIcsFile({ referral_id: this.referral.id, user_id: user_id });
          },
        });
      } else {
        interaction_type = 'Referral';
      }
      this.referralMenuItems.push({
        label: `${interaction_type} Request PDF`,
        icon: 'pi pi-download',
        disabled: this.referral.status === 'Pending',
        command: () => {
          this.downloadPdf();
        },
      });
      this.referralMenuItems.push({
        label: `${interaction_type} Report PDF`,
        icon: 'pi pi-download',
        disabled: this.referral.status !== 'Complete',
        command: () => {
          this.downloadResponsePdf();
        },
      });
      this.referralMenuItems.push({
        label: `${interaction_type} Request + Report Form PDF`,
        icon: 'pi pi-download',
        disabled: this.referral.status !== 'Complete',
        command: () => {
          this.downloadCombinedFormsPdf();
        },
      });
      if (this.referral.status !== 'Pending') {
        this.referralMenuItems.push({
          label: `Fax ${interaction_type} Request`,
          icon: 'pi pi-file-pdf',
          command: () => {
            this.fax_document = 'consult_request';
            this.faxDocumentId = this.referral.referral_form.id;
            this.showFaxConsultDialog = true;
          },
        });
      }
      if (this.referral.status === 'Complete') {
        this.referralMenuItems.push({
          label: `Fax ${interaction_type} Report`,
          icon: 'pi pi-file-pdf',
          disabled: this.referral.status !== 'Complete',
          command: () => {
            this.fax_document = 'consult_report';
            this.faxDocumentId = this.referral.referral_response_form.id;
            this.showFaxConsultDialog = true;
          },
        });
      }
    },
    downloadPdf() {
      this.error = '';
      this.downloadPdfOfReferralForm(this.referral.referral_form.id)
        .then(() => {})
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error Downloading File',
            detail: 'There was an error processing your request, please contact support@virtualhallway.ca or try again later.',
            life: 10000,
          });
        });
    },
    downloadResponsePdf() {
      this.downloadPdfOfResponseForm(this.referral.referral_response_form.id)
        .then()
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error Downloading File',
            detail: 'There was an error processing your request, please contact support@virtualhallway.ca or try again later.',
            life: 10000,
          });
        });
    },
    downloadCombinedFormsPdf() {
      this.downloadPdfOfCombinedForm(this.referral.id)
        .then()
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error Downloading File',
            detail: 'There was an error processing your request, please contact support@virtualhallway.ca or try again later.',
            life: 10000,
          });
        });
    },
    clickSendNudgeMessage() {
      this.sendNudgeMessage({
        user_id: this.loggedInUser.id,
        referral_id: this.referral.id,
      })
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Nudge Message Sent',
            life: 5000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        });
    },
    clickSendRunningLateMessage() {
      this.sendRunningLateMessage({
        user_id: this.loggedInUser.id,
        referral_id: this.referral.id,
      })
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Running Late Message Sent',
            life: 5000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        });
    },
    toggleReferralMenu(event) {
      this.$refs.menu.toggle(event);
      if (this.showFaxConsultToolTip) {
        this.closeFaxConsultToolTip();
      }
    },
    checkIfLessThanOneDayAfterReferralCall() {
      return moment().diff(moment(this.referral.start_datetime), 'days') === 0;
    },
    closeFaxConsultToolTip() {
      this.updateUserTutorialByName(this.loggedInUser, 'fax_a_consult', false, 'Skipped');
      this.showFaxConsultToolTip = false;
    },
    thankYouOnClick() {
      if (this.loggedInUser.id === this.referral.referee.id) {
        this.displaySendThankYouMessage = true;
        if (!this.hasBeenThanked) {
          const data = { referral_id: this.referral.id, sender_id: this.loggedInUser.id, receiver_id: this.referral.referral_person.id };
          this.createOrUpdateThankYou(data)
            .then(() => {
              this.hasBeenThanked = true;
            })
            .catch(() => {
              this.showGenericErrorToast();
            });
        }
      } else if (this.loggedInUser.id === this.referral.referral_person.id) {
        if (this.referral?.thank_you.message) {
          this.$refs.thankYouMessageDialog.openDialog();
        } else if (!this.referral?.thank_you.liked_by_receiver) {
          this.$toast.add({
            severity: 'success',
            summary: 'Liked Thank You Note!',
            life: 3000,
          });
          const data = { thank_you_id: this.referral.thank_you.id, liked_by_receiver: true };
          this.likeThankYou(data)
            .then(() => {})
            .catch(() => {});
        }
      }
    },
    sendThankYouMessageOnClick() {
      this.displaySendThankYouMessage = false;
      this.$refs.sendThankYouMessageDialog.openDialog();
    },
    clearTimeOutSendThankYouMessage() {
      if (this.displaySendThankYouMessageTimer) {
        clearTimeout(this.displaySendThankYouMessageTimer);
      }
    },
    leaveSendMessage() {
      if (this.loggedInUser.id === this.referral.referee.id) {
        this.clearTimeOutSendThankYouMessage();

        this.displaySendThankYouMessageTimer = setTimeout(() => {
          this.displaySendThankYouMessage = false;
        }, 3000);
      }
    },
    async startCall() {
      if (this.referral.referee.has_device_voip_token) {
        this.showVoipOrPstnCallDialog = true;
      } else {
        this.makeCall('pstn');
      }
    },
    async makeCall(callType) {
      const phoneNumber = this.referral.referral_form.alternative_phone_number ? this.referral.referral_form.alternative_phone_number : this.referral.referee.phone_number;
      const callRecipientName = this.referral.referee.full_name;
      if (this.isNativePlatform()) {
        await CapacitorCallPlugin.displayCallView({ phoneNumber, callRecipientName, callType, referralId: this.referral.id });
      }
    },
    confirmCall() {},
    consultRescheduledBySpecialist() {
      this.checkCurrentUsersAvailability(this.actingUser.id).then((res) => {
        let hasAvailableSlots = res.data.data;
        if (!hasAvailableSlots && this.loggedInUser.specialist_preference.call_asap_optin == false) {
          this.showPromptToAddAvailabilityModal = true;
        }
      });
    },
  },
  created() {
    this.setOptionsMenuItems();
    this.referralForm = this.referral.referral_form;
    this.hasBeenThanked = !!this.referral.thank_you;
  },
  mounted() {
    const faxAConsultTutorial = this.getUserActionByName(this.loggedInUser, 'fax_a_consult');
    if (faxAConsultTutorial && faxAConsultTutorial.status == 'Active') {
      this.showFaxConsultToolTip = true;
    }
    if (this.$route.query.thankYou && this.referral.referral_person.id === this.loggedInUser.id) {
      if (this.referral?.thank_you.message) {
        this.$refs.thankYouMessageDialog.openDialog();
      } else {
        document.querySelector('div[class*="thank-you-wrapper"]')?.focus();
      }
    }
  },
  watch: {
    referral: function () {
      this.referralForm = this.referral.referral_form;
      this.setOptionsMenuItems();
    },
  },
};
</script>

<style>
.referral-container {
  color: #859dc4;
}

.new-fax-feature-tool-tip {
  position: absolute;
  width: 300px;
  background-color: var(--vh-new-feature-blue);
  top: -60px;
  right: 170px;
  border-radius: 6px;
  z-index: 1000;
}

.new-fax-feature-tool-tip::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.new-fax-feature-tool-tip-btn {
  background-color: #005d9b;
  @apply h-6 w-14 rounded;
}
.send-thank-you-tooltip::after {
  border-color: transparent;
  border-style: solid;
  content: '';
  height: 0;
  position: absolute;
  pointer-events: none;
  top: -6px;
  transform: rotate(180deg);
  width: 0;
  right: 50%;
  left: 50%;
  border-top-color: black;
  border-width: 6px 5px 0 5px;
}
.particles {
  width: 1px;
  height: 1px;
}

.particle {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.1em;
  width: 0.1em;
  border-radius: 0.05em;
  background-color: var(--color);
  --percentage: calc(var(--i) / var(--total-particles));
  --Θ: calc(var(--percentage) * 1turn);
  -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0) scaleY(0);
  transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0) scaleY(0);
  -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
}
.thank-you-wrapper:focus .particle {
  -webkit-animation: particles-out calc(1s * 1.2) cubic-bezier(0.7, 0, 0.3, 1) forwards;
  animation: particles-out calc(1s * 1.2) cubic-bezier(0.7, 0, 0.3, 1) forwards;
}
@-webkit-keyframes particles-out {
  50% {
    height: 0.3em;
  }
  50%,
  60% {
    height: 0.3em;
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em) scale(1);
    transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em) scale(1);
  }
  60% {
    height: 0.2em;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em) scale(0);
    transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em) scale(0);
  }
}
@keyframes particles-out {
  50% {
    height: 0.3em;
  }
  50%,
  60% {
    height: 0.3em;
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em) scale(1);
    transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(0.8em) scale(1);
  }
  60% {
    height: 0.2em;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em) scale(0);
    transform: translate(-50%, -50%) rotate(var(--Θ)) translateY(1em) scale(0);
  }
}

@media (max-width: 638px) {
  #download-split-button > button.p-button.p-component.p-splitbutton-defaultbutton > span.p-button-label {
    padding-left: 2rem;
  }
}
</style>
