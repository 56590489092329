<template>
  <div class="flex w-full flex-col">
    <div class="flex w-full justify-center py-1">
      <div class="w-full">
        <div class="max-h-88 my-2 flex w-full flex-col md:flex-row rounded-2xl">
          <DatePicker v-model="selectedDate" :attributes="attributes" class="make-referral-date-picker animated fade-in-up relative mr-4 mb-4 bg-white p-4 shadow md:mb-0" :available-dates="allowedDates" :min-date="minDate" :max-date="maxDate" :disabled-dates="disabledDates" />
          <div v-if="showSelectTime" class="animated fade-in-up w-full rounded-2xl bg-white border-1 p-6 shadow" style="max-height: 350px">
            <div ref="SelectTime" class="w-full">
              <SelectTime :date="selectedDate" @timeSlotSelected="timeSlotSelected" :key="selectedDate" :selectedSchedulesForDate="selectedSchedulesForDate" />
            </div>
          </div>
        </div>
        <div class="mt-4 mb-10 flex flex-row rounded-2xl border-1 bg-white p-6 shadow">
          <div class="flex w-full flex-col items-start">
            <Button @click="goToSelectSpecialist" class="p-button p-button-secondary flex w-28 justify-center" icon="pi pi-caret-left" label="Back" v-if="referralToRebook === null"> </Button>
            <Button @click="cancelRebooking" class="p-button-outlined p-button-danger m-0 ml-6 mt-6 flex justify-center" v-else> Cancel Rebooking </Button>
          </div>
          <div class="flex w-full flex-row justify-end" v-if="isTimeSelected">
            <div v-tooltip.bottom="{ value: 'You cannot book yourself', disabled: actingUser.id !== selectedSpecialist.id }">
              <Button @click="confirmReferral" class="animated fade-in-right flex justify-center" icon="pi pi-check" iconPos="right" id="nextButton" label="Confirm Time" :disabled="actingUser.id === selectedSpecialist.id"></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import 'v-calendar/style.css';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { DatePicker } from 'v-calendar';
import moment from 'moment';
import SelectTime from '../make_referral/SelectTime.vue';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';

export default {
  inject: ['mixpanel'],
  components: {
    DatePicker,
    SelectTime,
    Button,
    Loading,
  },
  props: ['selectedSpecialists', 'selectedSchedules', 'selectedSpecialty'],
  data() {
    return {
      selectedDate: null,
      minDate: moment().toDate(),
      maxDate: moment().add(60, 'days').toDate(),
      availableDates: [],
      attributes: [],
      isTimeSelected: false,
      allowedDates: [],
      selectedSchedulesForDate: [],
      isMobile: false,
      selectedAvailabilitySlotId: null,
      selectedDateTime: null,
      isLoading: false,
      disabledDates: [],
    };
  },
  computed: {
    ...mapGetters(['referralToRebook', 'tutorialStatus', 'actingUser', 'smartPathReferralTransactionId', 'selectedTransaction']),
    showSelectTime() {
      return this.selectedSchedulesForDate.length > 0 && this.selectedDate;
    },
  },
  emits: ['goToSelectSpecialist', 'goToSelectTime', 'confirmReferral'],
  mounted() {
    this.findCols();
    window.addEventListener('resize', this.onResize, { passive: true });

    const calendarNextMonth = document.querySelector('.vc-arrow.is-right');
    const calendarPreviousMonth = document.querySelector('.vc-arrow.is-left');

    if (calendarNextMonth) {
      calendarNextMonth.addEventListener('click', () => {
        this.mixpanel.track('Make Referral - Select Date - Clicked Next Month', { specialty: this.selectedSpecialty.name, specialistIds: this.selectedSpecialists.map((specialist) => specialist.id) });
      });
    }
    if (calendarPreviousMonth) {
      calendarPreviousMonth.addEventListener('click', () => {
        this.mixpanel.track('Make Referral - Select Date - Clicked Previous Month', { specialty: this.selectedSpecialty.name, specialistIds: this.selectedSpecialists.map((specialist) => specialist.id) });
      });
    }
  },

  methods: {
    findCols() {
      this.isMobile = window.innerWidth < 1024;
      if (window.innerWidth > 1024) {
        return 2;
      } else {
        return 1;
      }
    },
    ...mapActions(['setReferralToRebook', 'addReferral', 'updateTransactionStatus']),
    ...mapMutations(['setTutorialStatus']),
    goToSelectSpecialist() {
      if (this.smartPathReferralTransactionId != null) {
        this.isLoading = true;

        this.updateTransactionStatus({ user_id: this.actingUser.id, transaction_id: this.smartPathReferralTransactionId, status: 'In Progress' })
          .then(() => {
            this.isLoading = false;
            this.$router.push({ path: '/pathway/' + this.selectedTransaction.smart_path_id + '/' + this.selectedTransaction.smart_path_person.id + '/' + this.selectedTransaction.id });
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      } else {
        this.$emit('goToSelectSpecialist');
      }
    },
    confirmReferral() {
      this.$emit('confirmReferral', {
        selectedAvailabilitySlotId: this.selectedAvailabilitySlotId,
        selectedSpecialist: this.selectedSpecialist,
        consultType: 'phone',
      });
      this.mixpanel.track('Make Referral - Booked Consult', { specialty: this.selectedSpecialty.name, specialistId: this.selectedSpecialist.id, selectedDate: this.formatDateTimeToSpecifiedFormat(this.selectedDateTime, 'MMM DD h:mmA', true) });
    },
    timeSlotSelected(event) {
      this.selectedAvailabilitySlotId = event.selectedAvailabilitySlotId;
      this.selectedSpecialist = this.selectedSpecialists.find((specialist) => specialist.id === event.selectedUserId);
      this.selectedDateTime = event.selectedAvailabilitySlotStartDateTime;
      this.isTimeSelected = true;
      this.mixpanel.track('Make Referral - Selected Time', { specialty: this.selectedSpecialty.name, specialistId: this.selectedSpecialist.id, selectedDate: this.formatDateTimeToSpecifiedFormat(this.selectedDateTime, 'MMM DD h:mmA', true) });
    },
    cancelRebooking() {
      this.setReferralToRebook(null).then(() => {
        this.$router.push('/home');
      });
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.minDate = new Date();
    let date = new Date();
    let lastSchedule = new Date();
    while (date.getTime() <= this.maxDate.getTime()) {
      let d = date.toDateString();
      if (
        !this.selectedSchedules.find((schedule) => {
          return schedule.start_datetime.toDateString() == d;
        })
      ) {
        this.disabledDates.push(new Date(date));
      } else {
        if (lastSchedule.getTime() <= date.getTime()) {
          lastSchedule = new Date(date);
        }
        this.availableDates.push(new Date(date));
      }
      date.setDate(date.getDate() + 1);
    }
    this.maxDate = lastSchedule;
    this.disabledDates.push(new Date(date));
    this.attributes = [
      {
        highlight: {
          fillMode: 'outline',
        },
        dates: this.availableDates,
      },
    ];
  },
  updated() {
    this.$nextTick(function () {
      const selectTime = this.$refs.SelectTime;
      if (selectTime) {
        setTimeout(() => {
          selectTime.scrollIntoView({
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth',
          });
        }, 300);
      }
    });
  },
  watch: {
    selectedDate(val, oldVal) {
      if (!val) {
        this.selectedDate = oldVal;
        return;
      } else {
        this.selectedSchedulesForDate = this.selectedSchedules.filter((schedule) => {
          return schedule.start_datetime.getDate() === val.getDate() && schedule.start_datetime.getYear() === val.getYear() && schedule.start_datetime.getMonth() === val.getMonth();
        });
        this.mixpanel.track('Make Referral - Selected Date', { specialty: this.selectedSpecialty.name, specialistIds: this.selectedSpecialists.map((specialist) => specialist.id), selectedDate: this.formatDateTimeToSpecifiedFormat(val, 'MMM DD h:mmA', true) });
      }
    },
  },
};
</script>

<style>
.make-referral-date-picker {
  min-width: 20rem;
}

/* This overrides the default style for available dates */
.vc-blue {
  --vc-accent-50: #2c283e;
  --vc-accent-100: #2c283e;
  --vc-accent-200: #2c283e;
  --vc-accent-300: #2c283e;
  --vc-accent-400: #2c283e;
  --vc-accent-500: #2c283e;
  /* border color */
  --vc-accent-600: #2c283e;
  /* text color */
  --vc-accent-700: #2c283e;
  --vc-accent-800: #2c283e;
  --vc-accent-900: #2c283e;
}

/* This overrides the default style for disabled dates*/
.vc-disabled {
  cursor: not-allowed;
  background-color: white;
  border-color: #ffaca7;
  cursor: not-allowed;
  /* border:none; */
  position: relative;
  border-width: 2px;
}
.vc-disabled:before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;

  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
</style>
