<template>
  <div v-if="user">
    <div class="relative">
      <div class="absolute -top-20" id="addFaxNumberHeader"></div>
    </div>
    <Message severity="info" :closable="false" class="text-left">Enter a number below to have your {{ getReferralPersonTerm(actingUser) }} reports automatically faxed to your office. <ToggleSupportSectionButton :openedSupportSectionAccountAccordionIndex="5" /></Message>
    <form method="post" @submit.prevent="submit" class="mb-4">
      <div class="mb-4 grid grid-cols-2 gap-1 gap-x-5">
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="firstName">Contact Name <span class="error">&nbsp;*</span> </label>
          <InputText v-model="inputContactName" class="auth-input" id="contactName" required maxLength="55" />
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label for="faxNumber" class="auth-input" v-if="!phoneNumberError">Fax Number <span class="error">&nbsp;*</span></label>
          <label for="faxNumber" class="auth-input" v-else>Fax number must be a 10 digit number</label>
          <InputMask :autoClear="false" type="tel" id="faxNumber" name="faxNumber" v-model="inputFaxNumber" class="auth-input" required maxLength="16" mask="(999)-999-9999" />
        </div>
        <div class="col-span-2 mt-4 flex justify-end">
          <Button type="submit" label="Add a Verified Contact" icon="pi pi-check" id="confirmContactButton"></Button>
        </div>
      </div>
    </form>
  </div>
  <template v-if="whitelist?.length > 0">
    <div class="col-span-2 mt-2 mb-4 flex flex-row items-center justify-center">
      <h1 class="mr-2 text-lg font-bold text-gray-500">Verified Fax Numbers</h1>
      <div class="h-1 flex-grow bg-gray-500"></div>
    </div>
    <Message severity="info" :closable="false" class="text-left" v-if="isGP(user)">Faxed reports include information for your patient's EMR: the consult booking date, consult completion date, and {{ getReferralPersonTerm(actingUser) }} notes. The fax is sent once the {{ getReferralPersonTerm(actingUser) }} completes their notes.</Message>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="card animated fade-in-up rounded-2xl shadow">
          <DataTable editMode="row" :value="whitelist" class="p-datatable-gridlines editable-cells-table" dataKey="id" :rowHover="true" v-model:editingRows="editingRows" @row-edit-save="onRowEditSave" responsiveLayout="scroll">
            <template #header>
              <div class="p-d-flex p-jc-between p-flex-column p-flex-sm-row">
                <h1 class="fc-toolbar-title mt-2 mb-2 md:mb-0">Saved Fax Contacts</h1>
              </div>
            </template>
            <template #empty> No Saved Contacts Found. </template>
            <template #loading> Loading Contact Data. Please wait. </template>

            <Column field="#" header="Auto Send">
              <template #body="{ data }">
                <div class="flex w-full justify-center">
                  <Checkbox id="binary" v-model="data.auto_send" :binary="true" @click="updateAutoSave(data)" />
                </div>
              </template>
              <template #editor="{ data }">
                <div class="flex w-full justify-center">
                  <Checkbox id="binary" v-model="data.auto_send" :binary="true" />
                </div>
              </template>
            </Column>

            <Column field="contact_name" header="Contact Name" :sortable="true">
              <template #editor="{ data, field }">
                <InputText v-model="data[field]" class="auth-input" required maxLength="55" />
              </template>
            </Column>

            <Column field="fax_number" header="Fax Number">
              <template #editor="{ data, field }">
                <InputMask v-model="data[field]" class="auth-input" required maxLength="16" mask="(999)-999-9999" />
              </template>
              <template #body="{ data, field }">
                <InputMask v-model="data[field]" required maxLength="16" mask="(999)-999-9999" disabled value="data[field]" style="color: black; border: none; background-color: transparent !important" class="hover:bg-gray-400" />
              </template>
            </Column>

            <Column :rowEditor="true" field="id" header="Edit" style="width: 1rem"> </Column>

            <Column header="Delete" style="width: 1rem">
              <template #body="{ data }">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="showDeleteFaxModal(data)" />
              </template>
            </Column>
          </DataTable>
          <ConfirmDeleteFaxDialog :show="showConfirmDeleteFaxDialog" :faxInfo="faxInfo" @closeConfirmDeleteFaxDialog="showConfirmDeleteFaxDialog = false" />
        </div>
      </div>
    </div>
  </template>
  <!-- <div class="col-span-2 mt-2 mb-4 flex flex-row items-center justify-center">
    <h1 class="mr-2 text-lg font-bold text-gray-500">Send Automatic Fax Number</h1>
    <div class="h-1 flex-grow bg-gray-500"></div>
  </div>
  <Message severity="info" :closable="false" class="text-left">We will automatically send faxes to your selected preferred fax number whenever you submit a consult request and when a consultant submits the response form.</Message>
  <form method="post" @submit.prevent="submit" class="mb-4">
    <div class="mr-4 flex flex-col">
      <label class="auth-input">Selected Automatic Fax Number</label>
      <Dropdown class="auth-input" v-model="selectedFaxNumber" :options="whitelist" optionLabel="fax_number" id="fax_number" :showClear="true">
        <template #value="slotProps">
          <div>
            <span v-if="slotProps.value">{{ slotProps.value?.contact_name }}: {{ formatPhoneNumber(slotProps.value?.fax_number) }}</span>
          </div>
        </template>
        <template #option="slotProps">
          <div class="p-dropdown-car-option">
            <span>{{ slotProps.option?.contact_name }}: {{ formatPhoneNumber(slotProps.option?.fax_number) }}</span>
          </div>
        </template>
      </Dropdown>
    </div>
    <div class="col-span-2 mt-4 flex justify-end">
      <Button type="submit" label="Update Automatic Fax Number" icon="pi pi-check" id="confirmContactButton"></Button>
    </div>
  </form> -->

  <Loading z-index="99" v-model:active="isLoading" loader="spinner" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Message from 'primevue/message';
import InputMask from 'primevue/inputmask';
import Loading from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import { FilterOperator } from 'primevue/api';
import ConfirmDeleteFaxDialog from '../misc/ConfirmDeleteFaxDialog.vue';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';

export default {
  emits: ['selectInfo'],
  props: ['loggedInUser'],
  data() {
    return {
      user: this.loggedInUser,
      errors: [],
      phoneNumberError: false,
      phoneNumberWitMask: '',
      isLoading: false,
      file: null,
      rerender: false,
      filters1: null,
      faxNumbers: [],
      editingRows: [],
      inputFaxNumber: '',
      inputContactName: '',
      showConfirmDeleteFaxDialog: false,
      faxInfo: '',
      selectedFaxNumber: null,
    };
  },
  computed: {
    ...mapGetters(['isSpecialist', 'isGP', 'isOrgUnitHead', 'actingUser', 'whitelist', 'getUserActionByName']),
  },
  methods: {
    ...mapActions(['updateUserAccountInfo', 'createWhitelistedFaxNumber', 'deleteWhitelistedFaxNumber', 'getWhitelistedFaxNumbers', 'updateWhitelistedFaxNumber']),
    submit() {
      this.validatePhoneNumber();
      if (!this.phoneNumberError) {
        this.$toast.removeAllGroups();
        this.isLoading = true;
        let user_data = { user_id: this.actingUser.id, contact_name: this.inputContactName, fax_number: this.inputFaxNumber };
        this.inputFaxNumber = '';
        this.inputContactName = '';
        this.createWhitelistedFaxNumber(user_data)
          .then(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Contact Saved',
              detail: 'Contact information has been successfully updated.',
              life: 5000,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Invalid Input',
              detail: 'Please make sure you have entered a contact name.',
              life: 5000,
            });
          });
      } else {
        this.isLoading = false;
        this.$toast.add({
          severity: 'error',
          summary: 'Failed to Add Fax Number',
          detail: 'Please confirm fax number is 10 digits long.',
          life: 5000,
        });
      }
    },
    validatePhoneNumber() {
      this.user.phone_number = this.inputFaxNumber.replace(/[^0-9]/g, '');
      if (!/[0-9]/.test(this.user.phone_number) || this.user.phone_number.length != 10) {
        this.phoneNumberError = true;
      } else {
        this.phoneNumberError = false;
      }
    },
    onRowEditSave(event) {
      this.isLoading = true;
      let { newData } = event;
      this.updateWhitelistedFaxNumber(newData)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'Invalid Input',
            detail: 'Please make sure you have entered a contact name and the fax number is 10 digits long.',
            life: 5000,
          });
        });
    },
    updateAutoSave(data) {
      let updateFaxNumber = this.whitelist.find((faxNumber) => faxNumber.id === data.id);
      if (updateFaxNumber) {
        updateFaxNumber.auto_send = !updateFaxNumber.auto_send;
        this.isLoading = true;
        this.updateWhitelistedFaxNumber(updateFaxNumber)
          .then(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Auto Send Fax Number Updated',
              detail: updateFaxNumber.auto_send ? 'We will now auto fax this fax number' : 'We will no longer auto fax this fax number',
              life: 3000,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
    showDeleteFaxModal(event) {
      this.faxInfo = event;
      this.showConfirmDeleteFaxDialog = true;
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        start_datetime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        'interaction_type.name': { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
  },
  created() {
    this.initFilters1();
    this.getWhitelistedFaxNumbers(this.actingUser.id)
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });
    const autoFaxAConsultTutorial = this.getUserActionByName(this.loggedInUser, 'auto_fax_a_consult');
    if (autoFaxAConsultTutorial && autoFaxAConsultTutorial.status == 'Active') {
      this.updateUserTutorialByName(this.loggedInUser, 'auto_fax_a_consult', false, 'Completed');
    }
    const faxAConsultTutorial = this.getUserActionByName(this.loggedInUser, 'fax_a_consult');
    if (faxAConsultTutorial && faxAConsultTutorial.status == 'Active') {
      this.updateUserTutorialByName(this.loggedInUser, 'fax_a_consult', false, 'Completed');
    }
  },
  components: {
    InputText,
    Button,
    Message,
    InputMask,
    Loading,
    DataTable,
    Column,
    ConfirmDeleteFaxDialog,
    Dropdown,
    Checkbox,
  },
  watch: {
    show: {
      handler() {
        this.showModal = !this.showModal;
      },
    },
  },
};
</script>

<style>
.auth-input.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-left: -1px;
}
.auth-input.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -1px;
}

.p-message-wrapper span.p-message-icon {
  margin-right: 1rem;
}

::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: 0.5rem;
  }
}
</style>
