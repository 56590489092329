<template>
  <div v-if="callDisplayName">
    <form method="post" @submit.prevent="submit" class="w-full text-center mt-2 px-4 pt-4">
      <div class="flex flex-row justify-between">
        <i class="pi my-auto text-red-700 pi-trash mr-3" style="font-size: 1.2rem" @click="deleteButtonOnClick" v-if="callDisplayName.id && callDisplayNames.length > 1" id="deleteCallerIdButton" />
        <h1 class="text-black text-3xl mx-auto font-bold" v-if="callDisplayName.id">Edit Caller ID</h1>
        <h1 class="text-black text-3xl mx-auto font-bold" v-else>Add Caller ID</h1>
        <i class="pi my-auto text-blue-700" :class="callDisplayName.is_default ? 'pi-star-fill' : 'pi-star'" style="font-size: 1.2rem" @click="updateIsDefaultOnClick" id="defaultCallerIdButton" />
      </div>

      <Message severity="info" :closable="false" class="text-left">The caller ID will be displayed to the person you are calling. This way, your patients and colleagues will know it's you without revealing your number.</Message>
      <span class="text-xs">Caller IDs can only be 15 characters long</span>
      <InputText type="text" name="callDisplayNameInput" id="callDisplayNameInput" v-model="callDisplayName.display_name" required class="w-full !mt-4" maxLength="15" />
      <Button label="Submit" type="submit" class="w-full !mt-4 !bg-green-600 !border-green-700 !rounded-lg" id="addCallDisplayNameSubmitButton" />
    </form>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
  <ConfirmDeleteDisplayNameDialog :callDisplayName="selectedCallDisplayName" ref="confirmDeleteDisplayNameDialog" @successfullyDeleted="$emit('finishEditing')" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Menu from 'primevue/menu';
import Loading from 'vue-loading-overlay';
import ConfirmDeleteDisplayNameDialog from '@/components/caller/ConfirmDeleteDisplayNameDialog.vue';

export default {
  inject: ['mixpanel'],
  components: {
    Loading,
    InputText,
    Button,
    Dropdown,
    Menu,
    ConfirmDeleteDisplayNameDialog,
  },
  props: ['selectedCallDisplayName'],
  emits: ['finishEditing'],
  data() {
    return {
      isLoading: false,
      callDisplayName: null,
      allowIsDefaultUpdate: true,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'callDisplayNames']),
  },
  methods: {
    ...mapActions(['createOrUpdateDisplayName']),
    submit() {
      this.isLoading = true;
      this.createOrUpdateDisplayName(this.callDisplayName)
        .then(() => {
          this.mixpanel.track('Manage Display Name - Updated Caller IDs');
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Caller ID set!',
            detail: 'Add more as needed for different practice locations.',
            life: 5000,
          });
          this.$emit('finishEditing');
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    updateIsDefaultOnClick() {
      if (this.allowIsDefaultUpdate) {
        this.callDisplayName.is_default = !this.callDisplayName.is_default;
      }
    },
    deleteButtonOnClick() {
      this.$refs.confirmDeleteDisplayNameDialog.openDialog();
    },
  },
  created() {
    this.callDisplayName = this.selectedCallDisplayName;
    if (this.callDisplayName == null) {
      this.callDisplayName = {
        is_default: false,
        display_name: null,
        user_id: this.loggedInUser.id,
      };
    }
    this.allowIsDefaultUpdate = !this.callDisplayName.is_default;
  },
};
</script>

<style></style>
