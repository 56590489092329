<template>
  <div class="main-inner-wrap">
    <transition name="slide-up-fade">
      <div class="reg-thank-you-card flex flex-col card shadow rounded-3xl" v-if="toggleTransition">
        <i class="pi pi-check-square" style="font-size: 3rem; color: #5d97f7"></i>
        <h3 id="reg-thank-you-sub-heading" class="text-gray-400">Thank You For Registering</h3>
        <h1 id="reg-thank-you-heading" class="text-blue text-3xl font-bold">Check Your Email For Verification</h1>
        <div class="seperator"></div>
        <p>
          Thank you for registering with Virtual Hallway, please check your email for a link to verify your account. If you have any questions please feel free to email us at
          <a href="mailto: support@virtualhallway.ca">support@virtualhallway.ca</a>
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggleTransition: false,
    };
  },
  mounted() {
    this.toggleTransition = true;
  },
};
</script>

<style scoped>
.reg-thank-you-card {
  margin: 75px;
  padding-top: 75px;
  padding-bottom: 50px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 650px;
  text-align: center;
  flex-wrap: nowrap;
}
.reg-thank-you-card i.pi-check-square {
  color: #0c7fbe;
  margin-bottom: 20px;
}
#reg-thank-you-sub-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 21px;
  font-weight: 700;
}
#reg-thank-you-heading {
  margin-top: 0px;
  font-size: 32px;
}
</style>
