<template lang="">
  <div class="pt-mobile-inset animated backdrop-blur-xl bg-white/75 text-center text-lg font-semibold fixed top-0 z-10 border-b w-full" :class="[{ hidden: hasScrolled === null }, { 'fade-in-down': hasScrolled === true }, { 'fade-out-up': hasScrolled === false }]">
    <div class="h-10">Recents</div>
  </div>

  <div class="bg-white h-screen">
    <div class="pt-mobile-inset text-left font-bold px-5 text-3xl mt-2">
      <div>Recents</div>
    </div>
    <div class="flex flex-row border-b px-5 py-3 justify-between items-center active:bg-gray-200" v-for="(item, index) in callRecords" :key="index" @click="makeCall(item)">
      <div class="flex-col text-left">
        <div class="flex flex-row items-center">
          <i class="pi pi-phone mr-3"></i>
          <div class="flex-col">
            <div class="flex-row" v-if="item.callee_contact_name">{{ item.callee_contact_name }}</div>
            <div class="flex-row" v-else-if="item.referral?.referee?.first_name">{{ displayNameByRole(item.referral?.referee) }}</div>
            <div class="flex-row" v-else>{{ formatRecentCallHistoryPhoneNumber(item.callee_phone_number) }}</div>
            <div class="flex-row text-xs text-gray-500">
              <template v-if="item.referral?.referee?.first_name"> Virtual Hallway</template>
              <template v-else-if="item.callee_contact_name"> Mobile </template>
              <template v-else> Unknown </template>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col">
        <div>{{ formatRecentCallsHistoryTime(item.created_at) }}</div>
      </div>
    </div>
    <div class="flex flex-row items-center justify-center h-3/4">
      <ProgressSpinner v-if="isLoading" style="height: 4rem" />
      <div class="flex flex-col m-5 bg-white rounded" v-else-if="callRecords.length < 1">You have not made any calls yet.</div>
    </div>
  </div>
  <div class="fixed bottom-0 right-0 mb-28 mr-5 rounded-full px-3 py-2 bg-secondary" @click="setCallHistory"><i class="pi pi-refresh text-white"></i></div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import CapacitorCallPlugin from '@/plugins/CapacitorCallPlugin';
import { Contacts } from '@capacitor-community/contacts';

export default {
  data() {
    return {
      callRecords: [],
      contacts: [],
      isLoading: true,
      hasScrolled: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'acsToken', 'hasValidAcsToken']),
  },
  created() {
    this.setCallHistory();
    window.addEventListener('reloadCallHistory', () => {
      this.setCallHistory();
    });
  },
  methods: {
    ...mapActions(['getCallRecordsByUserId']),
    async makeCall(callRecord) {
      await CapacitorCallPlugin.displayCallView({ phoneNumber: callRecord.callee_phone_number });
    },
    formatRecentCallsHistoryTime(value) {
      if (!value) return '';
      let callTime = moment.utc(value).local();
      if (callTime.isSame(moment(), 'day')) {
        return moment(value).format('h:mm A');
      } else if (callTime.isSame(moment(), 'week')) {
        return moment(value).format('ddd');
      } else {
        return moment(value).format('MMM DD');
      }
    },
    formatRecentCallHistoryPhoneNumber(val) {
      if (val == null) {
        return '';
      }
      let areaCode = val.substring(0, 1);
      let firstThreeDigits = val.substring(1, 4);
      let secondThreeDigits = val.substring(4, 7);
      let lastFourDigits = val.substring(7, 12);
      return areaCode + ' (' + firstThreeDigits + ') ' + secondThreeDigits + '-' + lastFourDigits;
    },
    getUnformattedPhoneNumber(unformattedPhoneNumber) {
      return '1' + unformattedPhoneNumber.replace(/\D/g, '').trim();
    },
    setCallHistory() {
      this.isLoading = true;
      this.getCallRecordsByUserId({
        user_id: this.loggedInUser.id,
        required_relations: ['referral', 'referral.referee'],
      })
        .then((res) => {
          this.callRecords = res.data;
          let projection = {
            // Specify which fields should be retrieved.
            name: true,
            phones: true,
            postalAddresses: true,
          };
          if (this.isNativePlatform() && this.callRecords.length > 0) {
            Contacts.getContacts({ projection })
              .then((res) => {
                this.contacts = res.contacts;
                this.contacts.forEach((contact) => {
                  if (contact.phones?.length > 0) {
                    let phoneNumbers = contact.phones;
                    phoneNumbers.forEach((phoneNumber) => {
                      if (phoneNumber.type == 'mobile') {
                        this.callRecords.forEach((callRecord) => {
                          if (callRecord.callee_phone_number == this.getUnformattedPhoneNumber(phoneNumber.number)) {
                            callRecord.callee_contact_name = contact.name.display;
                          }
                        });
                      }
                    });
                    this.isLoading = false;
                  }
                });
              })
              .catch(() => {
                this.isLoading = false;
              });
          } else {
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    handleScroll() {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      const heightToChange = 20;
      if (this.hasScrolled === null && scrollPosition > heightToChange) {
        this.hasScrolled = scrollPosition > heightToChange;
      } else if (this.hasScrolled !== null) {
        this.hasScrolled = scrollPosition > heightToChange;
      }
    },
  },
  mounted() {
    if (this.isNativePlatform()) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  unmounted() {
    if (this.isNativePlatform()) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
};
</script>
<style lang=""></style>
