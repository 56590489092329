<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div :class="['card', 'shadow', 'rounded-2xl', 'animated', 'fade-in-up', { 'in-front': tutorialStatus !== '' }]">
        <DataTable :value="users" :paginator="true" :filters="filters" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-col w-full justify-between">
              <div class="flex flex-row w-full justify-between">
                <div class="mt-2 mb-2 md:mb-0 flex flex-row items-center justify-center" v-if="!isBookingHallwayAdmin(loggedInUser)">
                  <img v-bind:src="loggedInUser.org_units[0].cover_image_location" class="w-28 mr-5" v-if="loggedInUser.org_units[0].cover_image_location" />
                  <h1>
                    {{ loggedInUser.org_units[0].name }}
                  </h1>
                </div>
                <div class="mt-2 mb-2 md:mb-0 flex flex-row items-center justify-center" v-else-if="isBookingHallwayAdmin(loggedInUser)">
                  <img v-bind:src="loggedInUser.booking_hallways[0].cover_image_location" class="w-28 mr-5" v-if="loggedInUser.booking_hallways[0].cover_image_location" />
                  <h1>
                    {{ loggedInUser.booking_hallways[0].name }}
                  </h1>
                </div>
                <Button icon="pi pi-plus" label="Add User" @click="clickAddUser" name="addUser" />
              </div>
              <div class="mt-5">
                <IconField iconPosition="left">
                  <InputIcon class="pi pi-search"> </InputIcon>
                  <InputText v-model="filters['full_name'].value" placeholder="Search Users by Name" />
                </IconField>
              </div>
            </div>
          </template>
          <template #empty> No Users found. </template>
          <template #loading>
            <Loading z-index="99" :v-model:active="true" color="#ef5164" :can-cancel="false" :is-full-page="true" />
          </template>
          <Column field="full_name" header="Name" style="min-width: 8rem" :sortable="true" />
          <Column header="Practitioner Type" :sortable="true">
            <template #body="{ data }">
              <template v-if="data.provider_type == 'NP'">Nurse Practitioner</template>
              <template v-else-if="data.provider_type == 'Pharmacist'">Pharmacist</template>
              <template v-else>{{ displayRole(data.role) }}</template>
            </template>
          </Column>
          <Column field="provider_type" header="Provider Type" :sortable="true" v-if="!isBookingHallwayAdmin(loggedInUser)" />
          <Column field="email" header="Email" :sortable="true" v-if="isBookingHallwayAdmin(loggedInUser)" />
          <Column field="status" header="Status" style="min-width: 8rem" :sortable="true">
            <template #body="{ data }">
              <div :class="'p-text-center top-6 right-6 rounded px-2 py-1 text-xs font-bold uppercase text-white ' + data.status">
                {{ data.status }}
              </div>
            </template>
          </Column>
          <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="{ data }">
              <Button type="button" class="p-button-outlined" icon="pi pi-cog" @click="editUser(data)" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="showAddBookingHallwayUserModal" modal header="Add User" :style="{ width: '70vw', maxWidth: '675px' }" id="addBookingHallwayUserModal">
    <div class="px-2 pb-2 flex flex-col w-full justify-end items-end">
      <Message :closable="false" class="w-full">
        You can invite up to 100 users simultaneously by entering their email addresses below. Each email should be on a new line. If you need to invite more than 100 users at once, send the first 100 invites followed by consecutive batches of 100 emails.
        <br />
        <br />
        Invited users will receive an email to join your department. Once accepted, they will be added to your department immediately.
      </Message>
      <Textarea v-model="bookingHallwayInvitedUserEmailsInput" id="bookingHallwayInvitedUserEmailsInput" rows="8" cols="30" placeholder="example1@abc.com&#10;example2@abc.com&#10;example3@abc.com" />
      <Message severity="success" v-if="correctEmailCount > 0" class="text-green-500 text-center w-full" :closable="false">{{ correctEmailCount }} email(s) ready to be invited.</Message>
      <Message severity="error" :closable="false" v-if="errorneousEmails.length > 0" class="flex flex-col w-full text-sm !m-0">
        <p>Erroneous emails found:</p>
        <ul class="ml-8" style="list-style-type: circle">
          <li v-for="email in errorneousEmails">{{ email }}</li>
        </ul>
        <p>Please fix the above mentioned emails to send invites.</p>
      </Message>
      <Button label="Send Invites" class="mt-5" @click="submitBookingHallwayInvitedUserEmails" id="submitBookingHallwayInvitedUserEmailsBtn" />
    </div>
  </Dialog>
  <Dialog v-model:visible="showRemoveBookingHallwayUserModal" modal header="Remove User" :style="{ width: '70vw', maxWidth: '560px' }">
    <div class="px-2 pb-2 flex flex-col w-full justify-end items-end">
      <Message severity="warn" :closable="false" class="w-full"> Are you sure you want to remove this user from your department? Note that the user will still be able to access features of Virtual Hallway but will lose access to your organization. </Message>
      <Button label="Confirm Remove User" class="mt-5" @click="confirmRemoveUserFromBookingHallway" />
    </div>
  </Dialog>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Loading from 'vue-loading-overlay';
import { FilterMatchMode } from 'primevue/api';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import debounce from 'lodash.debounce';

export default {
  props: ['users'],
  emits: ['userRemoved'],
  components: {
    Loading,
    DataTable,
    Column,
    InputText,
    Button,
  },
  data() {
    return {
      showAddBookingHallwayUserModal: false,
      showRemoveBookingHallwayUserModal: false,
      bookingHallwayInvitedUserEmailsInput: null,
      bookingHallwayInvitedUserEmails: [],
      errorneousEmails: [],
      correctEmailCount: 0,
      performEmailInputValidationDebounce: null,
      filters: {
        full_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  created() {
    // The debounce ensures the method is called only once
    // after the specified delay, e.g. if the watcher fires
    // this method 10 times within 1000ms, the method will
    // only be called one time after 1000ms instead of making
    // 10 calls within 1000ms.
    this.performEmailInputValidationDebounce = debounce(function (val) {
      this.performEmailInputValidation(true);
    }, 1000);
    // Defining the watcher here instead of in watch property
    // since otherwise the watcher gets fired due to object
    // manipulation while setting up the component.
    this.$watch('bookingHallwayInvitedUserEmailsInput', {
      handler(val) {
        this.performEmailInputValidationDebounce(val);
      },
      deep: true,
    });
  },
  methods: {
    ...mapMutations(['setActingUser', 'setTutorialStatus']),
    ...mapActions(['removeUserFromByBookingHallwayById', 'inviteUsersByEmail']),
    editUser(user) {
      this.setActingUser(user);
      if (!this.isBookingHallwayAdmin(this.loggedInUser)) {
        this.$router.push('/editaccount/' + user.id);
      } else {
        this.showRemoveBookingHallwayUserModal = true;
      }
    },
    clickAddUser() {
      this.showAddBookingHallwayUserModal = true;
    },
    clickInviteUser() {
      this.$router.push({ path: '/manageusers/inviteuser' });
    },
    performEmailInputValidation() {
      this.bookingHallwayInvitedUserEmails = this.bookingHallwayInvitedUserEmailsInput.split(/\n/);
      this.bookingHallwayInvitedUserEmails = this.bookingHallwayInvitedUserEmails.filter((email) => email != '');
      this.errorneousEmails = this.bookingHallwayInvitedUserEmails.filter((email) => !String(email).match(/^\S+@\S+$/));
      this.correctEmailCount = this.bookingHallwayInvitedUserEmails.length - this.errorneousEmails.length;
    },
    submitBookingHallwayInvitedUserEmails() {
      this.performEmailInputValidation();
      if (this.errorneousEmails.length < 1) {
        this.inviteUsersByEmail({
          emails: this.bookingHallwayInvitedUserEmails,
        })
          .then((data) => {
            this.showAddBookingHallwayUserModal = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Users Invited',
              detail: 'Please allow up to 10 minutes to finish sending out requests to all of the users.',
              life: 5000,
            });

            // going back as pharmacist
          })
          .catch(() => {
            this.showGenericErrorToast();
          });
      }
    },
    confirmRemoveUserFromBookingHallway() {
      this.removeUserFromByBookingHallwayById({
        booking_hallway_id: this.loggedInUser.booking_hallways[0].id,
        user_id: this.actingUser.id,
      })
        .then(() => {
          this.$emit('userRemoved', this.actingUser.id);
          this.$toast.add({
            severity: 'success',
            summary: 'Users Removed',
            detail: 'The user has been removed from your hallway.',
            life: 5000,
          });
          this.showRemoveBookingHallwayUserModal = false;
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
  },
  computed: {
    ...mapGetters(['loggedInUser', 'tutorialStatus', 'isOrgUnitHead', 'actingUser']),
  },
};
</script>

<style scoped>
.Preboarding {
  background: #b866ff !important;
  border-color: #b866ff !important;
}
div > .Preboarding:hover {
  background: #ac4dff !important;
  border-color: #ac4dff !important;
}
.Onboarding {
  background: #ffab66 !important;
  border-color: #ffab66 !important;
}
div > .Onboarding:hover {
  background: #eb9753 !important;
  border-color: #eb9753 !important;
}
.Active {
  background: #24d0af !important;
  border-color: #24d0af !important;
}
div > .Active:hover {
  background: #00bc96 !important;
  border-color: #00bc96 !important;
}
.Closed {
  background: #f05b51 !important;
  border-color: #f05b51 !important;
}
div > .Closed:hover {
  background: #c4392f !important;
  border-color: #c4392f !important;
}
.Locked {
  background: #f05b51 !important;
  border-color: #f05b51 !important;
}
div > .Locked:hover {
  background: #c4392f !important;
  border-color: #c4392f !important;
}
</style>
