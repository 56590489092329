<template>
  <div class="main-inner-wrap">
    <div v-if="isOrgUnitHead(loggedInUser)" class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block"><i class="pi pi-shield mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">User Verification</h1>
        <h1 class="text-sm text-gray-200">To have full use of the platform, users are required upload a copy of their Government Issued Photo ID, as well as the necessary billing paperwork for Fee For Service practitioners. Select a user below to submit these on their behalf.</h1>
      </div>
    </div>
    <div class="mt-2 max-w-3xl" v-if="!isLoading">
      <div class="w-full rounded-2xl bg-white py-2 px-6 shadow" v-if="isOrgUnitHead(loggedInUser) && orgUnitUsers && orgUnitUsers.length === 0">
        <Message severity="info" class="text-center" style="margin-left: auto; margin-right: auto"> You do not have any users to verify </Message>
      </div>
      <ChangeActingUserDropdown v-if="isOrgUnitHead(loggedInUser)" titleText="Updating Verification For:" class="card mb-2 shadow" :selectedUser="actingUser" :orgUnitUsers="orgUnitUsers" @actingUserChanged="getOnboardingInfoOfActingUser" />

      <div :class="['px-4', 'lg:px-12', 'py-12', 'card', 'rounded-2xl', 'shadow', { 'referral-tooltip': tutorialStatus === 'verification' }, { 'in-front': tutorialStatus === 'verification' }, { 'mt-16': !isOrgUnitHead(loggedInUser) }]" v-if="actingUser !== null && actingUser.id != 0">
        <div v-if="!isOrgUnitHead(loggedInUser)">
          <h1 class="text-3xl font-bold">Verification</h1>
          <h3 class="pt-2 mb-6 text-lg font-semibold text-gray-500" v-if="isGP(loggedInUser)">Welcome to Virtual Hallway</h3>
          <h3 class="pt-2 mb-6 text-lg font-semibold text-gray-500" v-else-if="isSpecialist(loggedInUser)">🎉 Welcome Dr. {{ loggedInUser.last_name }}, to Virtual Hallway! 🎉</h3>
          <div class="info-paragraph--gray" v-if="isOrgUnitHead(loggedInUser)">
            <p class="text-left text-sm">You can book up to five phone consults with a {{ getReferralPersonTerm(actingUser) }} prior to completing your account verification. After that, we ask that you complete the steps below to continue using Virtual Hallway.</p>
          </div>
        </div>
        <div v-if="!isOrgUnitHead(loggedInUser)">
          <div class="success text-left" v-if="actingUserOnboardingForm.status === 'Submitted'">
            <Message severity="success"> Thank you for registering with Virtual Hallway. Your documents have been submitted and your account will be activated shortly. You will receive an email notifying you of your account activation. If you have any questions feel free to email us at support@virtualhallway.ca </Message>
          </div>
        </div>
        <Message severity="warn" v-if="isOrgUnitHead(loggedInUser) && actingUserOnboardingForm.status === 'Editing'"> To gain full access, each user must login to their own account and agree to the End User License Agreement. </Message>
        <div v-else-if="isSpecialist(loggedInUser)" class="text-left text-sm text-gray-500 space-y-2">
          <div>We're excited to have you onboard our platform, designed to connect you with family physicians and nurse practitioners for expert phone consultations. Please complete the verification process by uploading the required identification documents.</div>
          <div class="font-semibold text-gray-500">Once verified and operational, you can:</div>
          <div>📞 <span class="font-semibold text-gray-500 ml-1"> Share Expertise: </span> Advise primary care providers and improve patient care.</div>
          <div>🌐 <span class="font-semibold text-gray-500 ml-1"> Expand Your Network: </span> Connect with fellow healthcare professionals.</div>
          <div>🚀 <span class="font-semibold text-gray-500 ml-1"> Make a Difference: </span> Support clinical decision-making and enhance patient outcomes.</div>
          <div>If you have any questions, our support team is here to help.</div>
          <div>Let's make a positive impact on healthcare together!</div>
        </div>

        <div class="upload-item flex flex-col-reverse md:flex-row">
          <div class="upload-submit mb-4 md:mb-0">
            <Button @click="openUploadPhotoIdModal" v-if="uploadedPhotoID == false" name="photoIdButton" class="w-36 justify-center">Upload</Button>
            <div v-else-if="uploadedPhotoID == true">
              <i class="pi pi-check-square success" style="font-size: 1.5rem"></i>
              <h3 name="photoIdComplete" class="success">Complete</h3>
            </div>
          </div>
          <div class="upload-content">
            <h3 class="text-gray-600 font-bold">Upload Photo ID</h3>
            <p v-if="isOrgUnitHead(loggedInUser)" class="text-left text-sm">All users on our platform are verified healthcare practitioners. Please help us verify the identity of the selected practitioner by uploading their government issued photo identification.</p>
            <p v-else class="text-left text-sm">All users on our platform are verified healthcare practitioners. Please help us verify your identity by uploading government issued photo identification.</p>
          </div>
          <UploadPhotoID :openUploadPhotoId="openUploadPhotoId" @closeUploadPhotoIdModal="closeUploadPhotoIdModal" />
        </div>
        <div class="upload-item flex flex-col-reverse md:flex-row" v-if="actingUser.provider_type === 'FFS' && !isSpecialist(loggedInUser) && actingUser?.practice_province?.billing_opt_in">
          <div class="upload-submit">
            <!-- Don't give PE the option to bill -->
            <template v-if="hasProvinceOfPractice(actingUser, ['NS', 'AB', 'ON'])">
              <Button @click="openBillingArrangementModal" v-if="uploadedBillingArrangement == false" name="billingArrangementButton" class="justify-center">Complete Form</Button>
              <div v-else-if="uploadedBillingArrangement == true">
                <i class="pi pi-check-square success" style="font-size: 1.5rem"></i>
                <h3 name="billingArrangementComplete" class="success">Complete</h3>
                <Button v-if="actingUser.status == 'Active'" @click="openBillingArrangementModal" name="billingArrangementEdit" class="p-button-success">ReUpload</Button>
              </div>
            </template>
          </div>
          <template v-if="hasProvinceOfPractice(actingUser, 'NS')">
            <div class="upload-content">
              <h3 class="text-gray-600 font-bold">MSI Business Arrangement Form</h3>
              <p class="text-left text-sm">
                Completing this form allows Virtual Hallway to bill MSI on your behalf using billing codes 03.09L and 03.09K. You will receive an e-transfer for 100% of your billings the first week of every month for the consults you complete.<br /><br />
                Click <a target="_blank" style="text-decoration: underline" href="https://app.virtualhallway.ca/images/nova_scotia_billing_guide.pdf">here</a> for more information.
              </p>
            </div>
            <Dialog v-model:visible="openBillingArrangement" modal :show-header="false" :style="{ width: '640px' }" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :dismissable-mask="true">
              <MSI @closeBillingArrangementModal="closeBillingArrangementModal" />
            </Dialog>
          </template>
          <template v-else-if="hasProvinceOfPractice(actingUser, 'AB')">
            <div class="upload-content">
              <h3 class="text-gray-600 font-bold">Business Arrangement and Relationships Application</h3>
              <p class="text-left text-sm">You will need to download and fill out the Alberta Business Arrangement and Relationships application. This form authorizes Virtual Hallway to submit billings for phone consults on your behalf.</p>
            </div>
            <Dialog v-model:visible="openBillingArrangement" modal :show-header="false" :style="{ width: '640px' }" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :dismissable-mask="true">
              <ABBA @closeBillingArrangementModal="closeBillingArrangementModal" />
            </Dialog>
          </template>
          <template v-else-if="hasProvinceOfPractice(actingUser, 'ON')">
            <div class="upload-content">
              <h3 class="text-gray-600 font-bold">Authorization for Group Payment</h3>
              <p class="text-left text-sm">
                Completing this form allows Virtual Hallway to bill OHIP on your behalf using billing codes K730 and K731. You will receive an e-transfer for your billings the first week of every month for the consults you complete.<br /><br />
                Click <a target="_blank" style="text-decoration: underline" href="https://app.virtualhallway.ca/images/ontario_billing_guide.pdf">here</a> for more information.
              </p>
            </div>
            <Dialog v-model:visible="openBillingArrangement" modal :show-header="false" :style="{ width: '640px' }" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :dismissable-mask="true">
              <OMHLTC @closeBillingArrangementModal="closeBillingArrangementModal" />
            </Dialog>
          </template>
          <!-- Uncomment when we get form, We aren't billing -->
          <!-- <template v-else-if="hasProvinceOfPractice(actingUser, 'PE')">
            <div class="upload-content">
              <h3 class="text-gray-600 font-bold">PEI Business Arrangement Form</h3>
              <p class="text-left text-sm">Completing this form allows Virtual Hallway to bill PEI Health on your behalf.</p>
            </div>
            <PEIBA :openBillingArrangement="openBillingArrangement" @closeBillingArrangementModal="closeBillingArrangementModal" />
          </template> -->
          <template v-else>
            <div class="upload-content">
              <h3 class="text-gray-600 font-bold">Billing Information</h3>
              <p class="text-left text-sm">We will reach out to set up your billing account.</p>
            </div>
          </template>
        </div>
      </div>
    </div>
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
  </div>
</template>

<script>
import UploadPhotoID from './onboardingForm/UploadPhotoID.vue';
import MSI from './onboardingForm/MSI.vue';
import PEIBA from './onboardingForm/PEIBA.vue';
import ABBA from './onboardingForm/ABBA.vue';
import OMHLTC from './onboardingForm/OMHLTC.vue';
import ChangeActingUserDropdown from '@/components/org_unit/ChangeActingUserDropdown.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Button from 'primevue/button';
import Message from 'primevue/message';
import Loading from 'vue-loading-overlay';
import Modal from '@/components/misc/Modal.vue';

export default {
  computed: {
    ...mapGetters(['actingUserOnboardingForm', 'loggedInUser', 'actingUser', 'photoId', 'billingArrangement', 'isGP', 'getProviderType', 'orgUnitUsers', 'isOrgUnitHead', 'hasProvinceOfPractice', 'tutorialStatus', 'isSpecialist']),
  },
  components: {
    UploadPhotoID,
    MSI,
    ABBA,
    OMHLTC,
    Button,
    Message,
    ChangeActingUserDropdown,
    Loading,
    Modal,
    PEIBA,
  },
  data() {
    return {
      providerType: '',
      component: null,
      steps: [],
      openUploadPhotoId: false,
      uploadedPhotoID: null,
      openBillingArrangement: false,
      uploadedBillingArrangement: null,
      status: null,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(['getPhotoId', 'getBillingArrangement', 'getOnboardingForm', 'getOnboardingUsersOfOrgUnit', 'bookDemoAsReferralPerson']),
    ...mapMutations(['setActingUser', 'setTutorialStatus']),
    openUploadPhotoIdModal() {
      this.openUploadPhotoId = true;
    },
    closeUploadPhotoIdModal(event) {
      this.openUploadPhotoId = event.show;
      this.uploadedPhotoID = event.data != null;
    },
    openBillingArrangementModal() {
      // For regular users, they should edit their billing agreement in My Account.
      // Org unit heads edit the users' agreement forms in a dialog in this page.
      if (this.loggedInUser.id == this.actingUser.id) {
        this.$router.push({ path: '/billing' });
      } else {
        this.openBillingArrangement = true;
      }
    },
    closeBillingArrangementModal(event) {
      this.openBillingArrangement = event.show;
      this.uploadedBillingArrangement = event.data != null;
    },
    /**
     * Update this to call call of these at the same time
     */
    getOnboardingInfoOfActingUser() {
      let userId = this.actingUser.id;
      if (userId !== 0) {
        this.isLoading = true;
        const promises = [this.getOnboardingForm(userId), this.getPhotoId(userId), this.getBillingArrangement({ userId: this.actingUser.id, practiceProvinceId: this.actingUser.practice_province.id })];
        Promise.all(promises)
          .then(() => {
            this.uploadedPhotoID = this.photoId != null && this.actingUserOnboardingForm.status !== 'Incomplete';
            this.uploadedBillingArrangement = this.billingArrangement != null && this.billingArrangement.status === 'Submitted';
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
  },
  created() {
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.isLoading = true;
      this.getOnboardingUsersOfOrgUnit(this.loggedInUser.org_units[0].id).then(() => {
        // Check if current active user belongs to list of onboarding users of the Org Unit,
        // these are cases where Org Unit Head tried making a referral for an un-onboarded
        // GP and got redirected. If it is not the case, then make the Org Unit Head select
        // a user, thus set the actingUser as null.
        if (!this.orgUnitUsers.some((user) => user.id == this.actingUser.id)) {
          this.setActingUser(null);
        } else {
          this.getOnboardingInfoOfActingUser();
        }
        this.isLoading = false;
      });
    } else {
      this.getOnboardingInfoOfActingUser();
    }
  },
  watch: {
    'loggedInUser.onboarding_form.status'(val) {
      if (val == 'Submitted') {
        if (this.isGP(this.loggedInUser)) {
          this.$toast.removeAllGroups();
          this.$toast.add({
            severity: 'success',
            summary: 'Welcome to Virtual Hallway! 🎉',
            detail: 'Your account will be reviewed shortly.',
            life: 5000,
          });
          this.$router.push({
            path: '/makereferral',
          });
        } else if (this.isSpecialist(this.loggedInUser)) {
          this.$router.push({
            path: '/home',
          });
        }
      }
    },
  },
};
</script>

<style>
.onboarding-container {
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 65px 50px;
}
.onboarding-card {
  padding: 20px 50px;
}

/* .info-paragraph--gray {
  text-align: left;
  background-color: #f9f9f9;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #859dc4;
  border-color: #e7e7ef;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  width: 100%;
} */
.info-paragraph--gray {
  text-align: left;
  background-color: #f9f9f9;
  padding: 10px 15px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #4b4b4b;
  border-color: #4b4b4b;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.text-light-blue {
  color: #859dc4;
}
.text-blue {
  color: var(--vh-blue);
}
.seperator {
  margin-bottom: 25px;
  border-radius: 100px;
  width: 100px;
  margin-top: 10px;
  background-color: var(--vh-blue);
  margin-right: auto;
  margin-left: auto;
  height: 4px;
  min-height: 4px;
}
.seperator-dark-blue {
  margin-bottom: 25px;
  border-radius: 100px;
  margin-top: 10px;
  background-color: #859dc4;
  margin-right: auto;
  margin-left: auto;
  height: 4px;
  min-height: 4px;
}
.upload-item {
  width: 100%;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  border-bottom-style: solid;
  border-bottom-color: #aabfdd;
  border-bottom-width: 1px;
}
.upload-submit {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 25%;
}
.upload-content {
  width: 75%;
  align-items: flex-start;
  text-align: left;
  justify-content: center;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}
.upload-item:nth-last-child(1) {
  border: none;
}
.success {
  color: #00bc96;
}
@media (max-width: 1024px) {
  .upload-item {
    justify-content: start;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .upload-content,
  .upload-submit {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .onboarding-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
