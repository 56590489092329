<template>
  <div class="sticky right-0" :class="isNativePlatform() ? 'bottom-28' : 'bottom-10'" v-if="!isLoading && this.steps.filter((step) => step.complete === true).length !== this.steps.length">
    <div class="animated fade-in-up animated-delay-2 relative h-full w-52 rounded-xl py-5 text-white shadow-lg ml-auto" :class="isNativePlatform() ? 'mr-4' : 'mr-10'" v-if="showAccountSetupList" style="background-color: #786efe">
      <span class="absolute top-0 right-2 cursor-pointer text-gray-200" @click="showAccountSetupList = !showAccountSetupList"><i class="pi pi-times" style="font-size: 0.75rem"></i></span>
      <div class="pb-1 text-md font-bold font-display uppercase">Account Setup {{ accountSetupPercentage }}%</div>
      <div class="text-md flex flex-col pl-4 pr-2 text-left">
        <div
          v-for="(step, i) in steps"
          :key="i"
          :aria-label="step.text"
          :class="['group flex h-8 items-center rounded-lg', { ['cursor-pointer hover:bg-indigo-500']: !step.complete }, { 'cursor-default': step.complete }]"
          @click="
            () => {
              return step.complete ? null : step.onClick();
            }
          "
        >
          <div class="w-5">
            <i class="fa-solid fa-circle-check -p-2 z-10 rounded-full bg-white text-green-400" v-if="step.complete"></i>
          </div>
          <div :class="[{ 'transform transition duration-500 ease-in-out group-hover:translate-x-2': !step.complete }, { ' text-gray-300 text-opacity-75': step.complete }]">
            {{ step.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="animated fade-in-up animated-delay-2 flex h-12 w-48 cursor-pointer items-center justify-center rounded-3xl text-sm font-bold uppercase font-display text-white ml-auto" :class="isNativePlatform() ? 'mr-4' : 'mr-10'" @click="showAccountSetupList = !showAccountSetupList" id="accountSetupInitial" style="background-color: #786efe" v-else>Account Setup {{ accountSetupPercentage }}%</div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      showAccountSetupList: false,
      openOtherSpecialtyReferralFormExample: false,
      steps: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['isSpecialist', 'schedules', 'actingUser', 'photoId', 'billingArrangement']),
    accountSetupPercentage() {
      //0. Email Verified
      //1. Set Schedule
      //2. upload photo Id
      //3. if ffs -> upload billing
      //4. if other -> confirm referral form
      //5. complete demo booking
      //6. upload profile pic
      //7. Add areas of expertise
      return Math.ceil((this.steps.filter((step) => step.complete === true).length / this.steps.length) * 100);
    },
  },
  methods: {
    ...mapMutations(['setTutorialStatus']),
    ...mapActions(['getUpcomingScheduleOfUserForSpecialist', 'getPhotoId', 'getBillingArrangement']),
  },
  created() {
    this.isLoading = true;
    let promises = [];
    this.steps.push({ complete: true, text: 'Verified Email', onClick: null });
    if (this.isSpecialist(this.actingUser)) {
      this.steps.push({
        complete: this.schedules.length > 0,
        text: 'Set Availability',
        onClick: () => {
          this.setTutorialStatus('set-availability');
          this.$router.push({ path: `/schedules` });
        },
      });
      this.steps.push({
        complete: false,
        text: 'Upload Photo ID',
        onClick: () => {
          this.$router.push({ path: `/verification` });
        },
      });
      this.steps.push({
        complete: this.actingUser.expertise_areas.length > 0,
        // complete: false,
        text: 'Add Expertise Areas',
        onClick: () => {
          this.$router.push({ path: `/myaccount`, query: { expertise_areas: true } });
        },
      });
      if (this.actingUser.billing_opt_in) {
        this.steps.push({
          complete: false,
          text: 'Complete Billing Form',
          onClick: () => {
            this.$router.push({ path: '/billing' });
          },
        });
      }
      if (this.actingUser.specialties.find((specialty) => specialty.name === 'Other')) {
        this.steps.push({
          complete: this.actingUser.onboarding_form.has_reviewed_example_consult_form,
          text: 'Review Consult Form',
          onClick: () => {
            this.$router.push({ path: `/exampleconsultform` });
          },
        });
      }

      this.steps.push({
        complete: !this.actingUser.profile.profile_picture_location.includes('default.png'),
        text: 'Upload Profile Picture',
        onClick: () => {
          this.$router.push({ path: `/myaccount`, query: { profile_picture: true } });
        },
        cursor: this.actingUser.profile.profile_picture_location.includes('default.png'),
      });
      promises.push(
        new Promise((resolve, reject) => {
          this.getUpcomingScheduleOfUserForSpecialist(this.actingUser.id)
            .then(() => {
              this.steps.find((step) => step.text === 'Set Availability').complete = this.schedules.length > 0;
              resolve('success');
            })
            .catch((error) => {
              reject(error);
            });
        })
      );
    }
    promises.push(
      new Promise((resolve, reject) => {
        this.getPhotoId(this.actingUser.id)
          .then(() => {
            this.steps.find((step) => step.text === 'Upload Photo ID').complete = this.photoId != null;
            resolve('success');
          })
          .catch((error) => {
            reject(error);
          });
      })
    );

    if (this.actingUser.provider_type === 'FFS') {
      promises.push(
        new Promise((resolve, reject) => {
          this.getBillingArrangement({ userId: this.actingUser.id, practiceProvinceId: this.actingUser.practice_province.id })
            .then(() => {
              this.steps.find((step) => step.text === 'Complete Billing Form').complete = this.billingArrangement?.status === 'Submitted';
              resolve('success');
            })
            .catch((error) => {
              reject(error);
            });
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style></style>
