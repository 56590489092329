<template>
  <div class="w-full">
    <h1 class="text-xl font-bold text-gray-600 text-center">Business Arrangement and Relationships Application <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="5" /></h1>
    <form method="post" @submit.prevent="submit" class="billing-arrangement-content">
      <p class="text-sm font-bold text-gray-500">Please download and print the Alberta Business Arrangement and Relationships application below, and submit it via email to registration@virtualhallway.ca, or by fax to 902-417-2100</p>
      <p class="text-sm text-gray-500">We aim to ensure all fee for service clinicians are compensated for their time spent conducting peer-to-peer consults. Completing this form authorizes Virtual Hallway to submit billings for phone consults on your behalf.&nbsp;</p>
      <p class="text-sm text-gray-500">If you wish to receive payouts to a different E-Transfer address, you can specify it below.</p>
      <div class="grid grid-cols-2 gap-1 gap-x-3">
        <div class="mb- col-start-1 col-end-3 sm:col-auto">
          <label for="eTransferEmail" class="auth-input">E-Transfer Email<span class="error">&nbsp; *</span></label>
          <InputText name="eTransferEmail" id="eTransferEmail" v-model="eTransferEmail" required class="auth-input" type="email" />
        </div>
        <div class="mb- col-start-1 col-end-3 sm:col-auto">
          <label for="serviceProviderNumber" class="auth-input">Practitioner Identifier Number <span class="error">&nbsp; *</span> &nbsp;</label>
          <InputText name="serviceProviderNumber" id="serviceProviderNumber" v-model="serviceProviderNumber" class="auth-input billing-arrangement-side-by-side" required maxLength="255" />
        </div>
      </div>
      <div class="mt-2 text-center">
        <Message severity="info" class="text-start">
          <p>Please download, fill out, and sign the Business Arrangement Form, then attach the form below and submit.</p>
          <p>"By signing the form, I assign payment for services rendered by me and submitted by the above group to be made directly to the Business Arrangement(s) established for the group."</p>
        </Message>
        <Button icon="pi pi-download" label="Download Form" class="p-button-secondary p-button-outlined mb-2 rounded py-2 px-4 font-bold text-white" type="button" @click="downloadForm" id="downloadBillingArrangementButton" />
        <br />
        <br />
        <FileUpload name="files[]" :maxFileSize="20000000" :fileLimit="1" accept=".pdf" :customUpload="true" @select="handleFileChange" @upload="handleFileChange" :auto="true" :uploadLabel="'Submit'" :showCancelButton="false" :showUploadButton="false" chooseLabel="Select" @remove="remove" :key="rerender">
          <template #empty>
            <p class="text-sm">Drag and drop files to here to upload.</p>
          </template>
        </FileUpload>
      </div>
      <div v-if="error" class="text-center"><span class="error">Please upload the Alberta Business Arrangement to submit</span><br /></div>
      <div class="flex justify-center py-5">
        <Button icon="pi pi-check" type="submit" label="Submit" name="submit" id="submit" class="w-1/3"></Button>
      </div>
    </form>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import moment from 'moment';
import Modal from '@/components/misc/Modal.vue';
import { mapGetters, mapActions } from 'vuex';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';
import FileUpload from 'primevue/fileupload';
import Message from 'primevue/message';

export default {
  emits: ['closeBillingArrangementModal'],
  computed: {
    ...mapGetters(['billingArrangement', 'actingUser']),
  },
  components: {
    Modal,
    InputText,
    Button,
    Loading,
    FileUpload,
    Message,
  },
  data() {
    return {
      error: false,
      todaysDate: moment().format('YYYY-MM-DD'),
      serviceProviderNumber: null,
      signatureAgreement: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      signatureAgreementError: false,
      signatureError: false,
      isLoading: false,
      eTransferEmail: '',
      file: null,
      rerender: false,
    };
  },
  methods: {
    ...mapActions(['uploadBillingArrangement', 'downloadAbbaForm']),
    submit() {
      this.error = false;
      if (this.file === null) {
        this.error = true;
      } else {
        const data = new FormData();
        data.append('file', this.file);
        data.append('service_provider_number', this.serviceProviderNumber);
        data.append('user_id', this.actingUser.id);
        data.append('etransfer_email', this.eTransferEmail);
        data.append('practice_province_id', this.actingUser.practice_province.id);
        this.isLoading = true;
        this.uploadBillingArrangement(data)
          .then((response) => {
            this.isLoading = false;
            this.$emit('closeBillingArrangementModal', {
              data: response.data.data,
              show: false,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
    downloadForm() {
      this.error = '';
      this.downloadAbbaForm().catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error Downloading File',
          detail: 'There was an error processing your request, please contact support@virtualhallway.ca or try again later.',
          life: 10000,
        });
      });
    },
    remove() {
      this.rerender = !this.rerender;
      this.file = null;
    },
    handleFileChange(event) {
      this.file = event.files[0];
    },
  },
  created() {
    if (this.actingUser) {
      this.eTransferEmail = this.actingUser.email;
    }
  },
};
</script>

<style>
.billing-arrangement-content {
  text-align: start;
  font-size: 14px;
}
.billing-arrangement-content p,
.billing-arrangement-content label {
  padding-top: 8px;
}
.billing-arrangement-container {
  padding: 0 20px 0 20px;
}
.billing-arrangement-rows {
  justify-content: space-between;
}
.billing-arrangement-side-by-side {
  width: 48%;
}
.signature-agreement-text {
  font-size: 13px;
}
#signature {
  padding-bottom: 10px;
  margin-right: 0px;
}
</style>
