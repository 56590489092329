<template>
  <div class="main-inner-wrap">
    <div class="flex flex-grow justify-center items-center">
      <div class="p4 text-center">
        <i class="pi pi-ban mb-4" style="font-size: 4rem"></i>
        <h1 class="text-4xl mb-4">Page Not Found</h1>
        <template v-if="isLoggedIn">
          <router-link to="/contactsupport" class="p-button" exact-path>Contact Support</router-link>
        </template>
        <template v-else>
          <p class="mb-4 w-80">Try logging in, or contact us for support.</p>
          <a href="https://virtualhallway.ca/contact" class="p-button">Contact Support</a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
};
</script>
