<template>
  <div v-if="!isLoading">
    <RemainingConsults v-if="actingUser !== null && actingUser.id != 0" :class="{ 'pt-8': isNativePlatform() }" />
  </div>

  <div class="main-inner-wrap" v-if="!isLoading">
    <div v-if="actingUser == null || actingUser.id == 0" class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block"><i class="pi pi-id-card mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Book a Consult</h1>
        <h1 class="text-sm text-gray-200">To book a consult on behalf of a Primary Care Practitioner, begin by selecting a user below. The practitioner you select will need to log in to review and submit the consult form once you have saved it.</h1>
      </div>
      <div>
        <Button v-if="tutorialFit()" @click="setTutorialStatus('org-select-user')" v-on:click.stop label="Tutorial" icon="pi pi-external-link" class="p-button-outlined p-button-secondary p-button-sm hide-mobile z-10 text-xs" id="tips-button" iconPos="right" />
      </div>
    </div>

    <div v-if="isOrgUnitHead(loggedInUser)" class="h-full w-full pt-4">
      <div :class="['bg-white', 'rounded-2xl', 'shadow', 'w-full', { 'in-front': tutorialStatus !== '' }]">
        <div class="py-2 px-6" v-if="orgUnitUsers && orgUnitUsers.length === 0">
          <Message severity="info" class="text-center"> You do not have any General Practitioners in your practice group. Add new users in the users tab. </Message>
        </div>
        <ChangeActingUserDropdown v-else-if="referralToRebook === null" titleText="Booking a Consult For:" :selectedUser="actingUser" :orgUnitUsers="orgUnitUsers" @actingUserChanged="updateReferee" />
        <ChangeActingUserDropdown v-else titleText="Rebooking Consult For:" :selectedUser="referralToRebook.referee" :orgUnitUsers="[referralToRebook.referee]" />
      </div>
    </div>

    <!-- Users Not in NS ON AB NL PE -->
    <div v-if="!isOrgUnitHead(loggedInUser) && !hasProvinceOfPractice(loggedInUser, ['NS', 'ON', 'AB', 'NL', 'PE'])" class="mt-12 w-full max-w-2xl rounded-2xl bg-white p-6 py-12 shadow">
      <h3 class="mb-3 pt-2 text-lg font-semibold text-gray-400">Welcome to Virtual Hallway</h3>
      <h1 class="mb-3 text-xl font-bold text-gray-600">We are not yet operating in {{ actingUser.practice_province.name }}</h1>
      <div class="seperator"></div>
      <p class="mb-8 px-4 text-left text-sm text-gray-500">We appreciate your interest and can assure you that we are working to expand our availability across Canada. Click below to receive an email from us when we start operations in {{ loggedInUser.practice_province.name }} We hope to see you soon!</p>
      <Button label="Get Notified" icon="pi pi-bell" class="mb-4" @click="showSuccessToast" />
      <p class="mb-8 px-4 text-left text-sm text-gray-500">In the meantime, check out our free CME Lecture Series, with live and on-demand lectures.</p>
      <Button label="View Lectures" icon="pi pi-bell" class="mb-4" @click="$router.push('/lectures')" />
    </div>

    <!-- Users in NS ON AB NL PE -->
    <div v-else :class="['w-full', 'rounded-xl', 'animated', 'fade-in-up', { 'in-front': isTutorial }]">
      <!-- <div class="block md:absolute -mt-2 mb-2 md:mt-0 top-8 right-5" v-if="selectedScreen === 'selectDate'  && hasProvinceOfPractice(actingUser, 'AB') && selectedSpecialists.find((user) => econsultCounts.find(econsultCount => econsultCount.user_id === user.id))">
            <Button label="Available For eConsult" class="p-button-warning econsult-btn" @click='econsultOnClick'></Button>
          </div> -->
      <Message v-if="pendingConsults?.length" severity="warn" :closable="true" class="text-left">
        <template v-if="pendingConsults.length == 1"> You have {{ pendingConsults?.length }} pending consult, submit it before you lose your booking. </template>
        <template v-else> You have {{ pendingConsults?.length }} pending consults, submit them before you lose your booking. </template>
        <span @click="goToPendingReferralHistory" class="font-bold underline cursor-pointer">View Now</span>
      </Message>
      <BookingProgressBar v-if="!isOrgUnitHead(loggedInUser)" :event="selectedScreen" :selectedSpecialty="selectedSpecialty" :selectedSpecialists="selectedSpecialists" :selectedDate="selectedDateTime" :consultType="selectedInteractionType" />
      <div class="referral-tooltip">
        <div class="relative">
          <Dropdown v-model="selectedInteractionTypeMenu" :options="interactionTypeMenuItems" placeholder="Any" class="z-10" id="interaction-type-dropdown" v-if="showSelectedInteractionTypeDropDown" @change="selectedInteractionTypeMenuOnChange" scrollHeight="300px" @show="removeHighlighted" panelStyle="min-width:550px;">
            <template #value="slotProps">
              <span v-if="slotProps.value.title">{{ slotProps.value.title }}</span>
              <span v-else>{{ slotProps.placeholder }}</span>
            </template>
            <template #option="slotProps">
              <div class="my-0 -mx-4 flex w-full justify-center rounded-xl border-2 border-blue-400 px-2 py-4" style="width: 232px">
                <div class="justify-left flex w-full">
                  <div class="">
                    <div>
                      <p class="p-text-bold text-md font-medium text-blue-400 md:text-xl"><i :class="[slotProps.option.icon]" /> {{ slotProps.option.title }}</p>
                      <p class="text-sm text-gray-500">
                        {{ slotProps.option.subTitle }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Dropdown>

          <template v-if="showSelectedInteractionTypeDropDown && this.tutorialStatus === 'select-interaction-type'">
            <div class="in-person-new-feature-tool-tip py-4 px-6 text-left text-white">
              <h1 class="text-left text-xl font-bold text-white">
                <div>Select Referral</div>
              </h1>
              <br />
              <div class="text-left font-bold text-white">
                <p>Click the dropdown to select Referrals.</p>
              </div>
              <br />
            </div>
          </template>
        </div>
        <div :key="selectedInteractionType">
          <InlineMessage severity="info" v-if="externalReferralRequest" class="w-full">
            <span class="font-semibold text-sm">Making consult request for patient: {{ externalReferralRequest.referral_data.patient_first_name }} {{ externalReferralRequest.referral_data.patient_last_name }} ({{ externalReferralRequest.referral_data.patient_health_card_number }})</span>
            <button @click="openConfirmCancelExternalReferralRequestDialog = true" class="text-center items-center pt-1 pb-1.5 pl-3 pr-3 text-white text-xs font-semibold bg-secondary hover:bg-gray-500 ml-4 rounded">Discard</button>
          </InlineMessage>
          <BookingHeader v-if="actingUser && selectedScreen" :actingUser="actingUser" :selectedScreen="selectedScreen" :referralToRebook="referralToRebook" />

          <SelectSpecialty @goToSelectSpecialist="goToSelectSpecialist($event)" @popUpConfirmReferral="popUpConfirmReferral($event)" @openAvailabilityNotHereModal="openAvailabilityNotHereModal" v-if="selectedScreen == 'selectSpecialty'" />
          <div v-for="selectedSpecialty in selectedSpecialties" :key="selectedSpecialty.id">
            <SelectSpecialist :selectedSpecialty="selectedSpecialty" @goToSelectSpecialty="goToSelectSpecialty" @goToSelectDate="goToSelectDate" v-if="selectedScreen == 'selectSpecialist'" @bookEconsult="bookEconsult" @bookOnDemand="bookOnDemand" />
          </div>

          <SelectDate :selectedSpecialty="selectedSpecialty" :selectedSpecialists="selectedSpecialists" :selectedSchedules="selectedSchedules" :selectedDateTime="selectedDateTime" @goToSelectSpecialist="goToSelectSpecialist($event)" @confirmReferral="confirmReferral($event)" v-if="selectedScreen == 'selectDate'" />
        </div>

        <ConfirmReferralModal :selectedSpecialty="selectedSpecialty" :selectedSpecialist="selectedSpecialist" :selectedAvailabilitySlot="selectedAvailabilitySlot" :showModal="popUpConfirmReferralModal" @closeConfirmReferralModal="closeConfirmReferralModal" @confirmReferral="confirmReferralFromPopUp" v-if="popUpConfirmReferralModal" />
        <!-- Rework this with just one conditional per step, and remove step 4 -->

        <template v-if="['org-select-user', 'select-a-specialty', 'select-a-specialist', 'select-a-time', 'referral-form', 'org-select-user-in-person', 'try-smartpath'].includes(tutorialStatus)">
          <span v-if="tutorialFit()" :class="['referral-tooltiptext', 'py-4', 'px-6', { 'org-select-user-tooltip': tutorialStatus === 'org-select-user' || tutorialStatus === 'org-select-user-in-person' }]">
            <h1 class="text-left text-xl font-bold text-white">
              <div v-if="tutorialStatus == 'org-select-user' || tutorialStatus == 'org-select-user-in-person'">Choose a User</div>
              <div v-if="tutorialStatus == 'select-a-specialty'">Choose a Specialty</div>
              <div v-if="tutorialStatus == 'select-a-specialist'">Choose a Specialist</div>
              <div v-if="tutorialStatus == 'select-a-time'">Choose a Date and Time</div>
              <div v-if="tutorialStatus == 'referral-form'">Consult Request Form</div>
              <div v-if="tutorialStatus == 'try-smartpath'">Try SmartPath!</div>
            </h1>
            <br />
            <div class="text-left font-bold text-white">
              <p v-if="tutorialStatus == 'org-select-user' || tutorialStatus == 'org-select-user-in-person'">Select the user you would like to book a consult for.</p>
              <template v-if="tutorialStatus == 'try-smartpath'">
                <span>SmartPath is a new triage tool currently in pilot for Nova Scotia Central Zone physicians.</span>
                <br />
                <br />
                <span>The highlighted specialists are offering SmartPaths. Click one to start!</span>
              </template>
              <p v-if="tutorialStatus == 'select-a-specialty'">Select which specialty you would like to <span v-if="selectedInteractionType === 'phone'">consult</span> with.</p>
              <template v-if="selectedInteractionType === 'phone '">
                Select who you want to consult with.
                <br />
                <br />
                <span>Choose a specific specialist, or, for maximum number of options, pick "Any".</span>
              </template>
              <p v-if="tutorialStatus == 'select-a-specialist'">
                <template v-if="selectedInteractionType === 'phone'">
                  Select who you want to consult with.
                  <br />
                  <br />
                  <span>Choose a specific specialist, or, for maximum number of options, pick "Any".</span>
                </template>
              </p>
              <p v-if="tutorialStatus == 'select-a-time'">Select the date and time you would like to consult with the specialist.</p>
              <div v-if="tutorialStatus == 'referral-form'">
                Enter your patient's details to your Consult Request form. All data written to this form will remain private and secure on our servers.
                <br />
                <br />
                When you are done, click Submit.
                <br />
                <br />
                <div class="text-xs" v-if="selectedInteractionType === 'phone'">*Unsure of what kind of questions work well with phone consults? <a target="_blank" href="https://virtualhallway.ca/is-my-question-appropriate-for-a-phone-consult/">Click here.</a></div>
              </div>
            </div>
            <br />
            <div class="flex flex-row justify-between">
              <button class="text-xs font-bold text-white" @click="skipTutorial" id="skipTutorialBtn" v-if="tutorialStatus == 'try-smartpath'">Close</button>
              <button class="text-xs font-bold text-white" @click="skipTutorial" id="skipTutorialBtn" v-else-if="selectedInteractionType === 'phone'">Skip Tutorial</button>
              <div class="text-xs font-bold text-white">{{ getTutorialStep }}</div>
            </div>
          </span>
        </template>
      </div>
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />

  <modal ref="tutorialModal" v-if="tutorialStatus == ''" class="dark-mint-header">
    <template v-slot:header></template>
    <template v-slot:body>
      <div class="flex w-full max-w-lg justify-center rounded-b-xl p-12">
        <div class="flex w-full max-w-lg flex-col">
          <div class="w-full">
            <h1 class="text-xl font-bold mb-2">Welcome to Virtual Hallway! &#127881;</h1>
          </div>
          <div class="text-sm w-full max-w-lg px-4 pt-2 text-gray-600">
            <div class="mb-6">Let's make your first consult.</div>
            <div class="pb-6">
              <Button class="p-button-secondary" @click="startTutorial" id="tutorialGetStartedBtn">Get Started</Button>
            </div>
          </div>
          <p class="text-sm mb-4 text-gray-600" id="notReadyToConsultBtn">Not ready to make a consult?</p>
          <div class="flex w-full flex-row items-center justify-between px-12">
            <span id="skipTutorialBtn" class="text-blue text-sm only-child-m-auto cursor-pointer font-semibold hover:text-red-300" @click="closeTutorialModal"> Skip Tutorial </span>
          </div>
        </div>
      </div>
    </template>
  </modal>

  <modal ref="autoFaxFeatureModal" v-if="tutorialFit() && tutorialStatus == ''" class="dark-mint-header" id="autoFaxFeatureModal" style="padding-top: 100px">
    <template v-slot:header></template>
    <template v-slot:body>
      <div class="w-full max-w-lg flex justify-center">
        <div class="relative z-20 flex h-20 w-20 justify-center rounded-full border-4 border-solid border-white bg-green-300 -mt-10">
          <i class="fa fa-paper-plane my-auto text-white mr-2" style="font-size: 3rem" />
        </div>
      </div>
      <div class="flex w-full max-w-lg justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <div class="w-full">
            <h1 class="text-xl font-semibold px-4">
              <span class="font-bold text-slate-700 text-lg">Save time:</span> <br />
              Get your completed consult reports auto-faxed!
            </h1>
          </div>
          <br />
          <div class="text-sm w-full max-w-lg px-4 pt-2 text-slate-600">
            <div class="mb-4">When your {{ getReferralPersonTerm(actingUser) }} completes their report, your office will receive the information needed for your patient's EMR.</div>
            <div class="py-4">
              <Button class="p-button" @click="goToFaxSettings" id="tutorialGetStartedBtn">Setup Auto Fax</Button>
            </div>
          </div>
          <div class="mb-4 flex w-full flex-row items-center justify-between px-12">
            <span class="text-blue text-md only-child-m-auto cursor-pointer font-semibold hover:text-red-300" @click="closeAutoFaxFeatureModal"> Skip </span>
          </div>
        </div>
      </div>
    </template>
  </modal>
  <TimezoneModal :openModal="openTimezoneModal" />
  <modal ref="letUsKnowAvailabilityModal">
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="p-5">
        <form method="post" @submit.prevent="submitAvailabilityFeedbackForm">
          <div class="flex flex-col items-center">
            <h1 class="text-blue mt-2 text-xl font-bold">Who would you like to see?</h1>
            <div class="seperator"></div>
            <div class="info-paragraph--gray flex flex-row items-center justify-center">
              <i class="pi pi-exclamation-triangle pr-2" style="fontsize: 1rem" />
              <p class="text-center text-xs">Do not include any patient information in your feedback.</p>
            </div>
          </div>
          <div class="mb-2 pt-2">
            <label class="auth-input">Please provide your feedback on specialty availability. Thank you for your thoughts!<span class="error"></span> </label>
            <Textarea v-model="availabilityFeedbackMessage" class="auth-input" :autoResize="true" rows="5" cols="30" required maxLength="65535" />
          </div>
          <div class="flex-start flex pt-2">
            <span class="error"></span>
            <Checkbox v-model="contactWhenAvailabilitySet" :binary="true" />
            <label :class="'text-left ml-2 text-xs'">Do you want us to contact you when we've added this availability?<br /></label>
          </div>
          <div class="flex justify-end pt-5">
            <Button type="submit" name="submitButton">Submit</Button>
          </div>
        </form>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-s rounded-b py-2 pb-4"></div>
    </template>
  </modal>

  <Dialog v-model:visible="openEmrIntegrationTutorialDialog" class="dark-mint-header" :modal="true" :closable="false" id="emrIntegrationTutorialDialog">
    <template #header>
      <div class="w-full max-w-lg flex justify-center">
        <div class="relative z-20 flex h-20 w-20 justify-center rounded-full border-4 border-solid border-white bg-secondary -mt-10">
          <i class="fa-solid fa-link my-auto text-white" style="font-size: 3rem" />
        </div>
      </div>
    </template>
    <template v-if="emrIntegrationTutorialStep == 1">
      <div class="flex w-full max-w-lg justify-center rounded-b-xl py-4">
        <div class="flex w-full max-w-lg flex-col">
          <div class="w-full text-center">
            <h1 class="text-xl font-semibold">
              <span class="font-bold text-slate-700 text-lg">Make Consults Directly from Your EMR!</span>
            </h1>
          </div>
          <br />
          <div class="text-base w-full max-w-lg px-4 pt-2 text-slate-600 text-center">
            <div class="mb-4">Did you know that you can now make VH consults directly from Oscar and MedAccess EMR and bring in patient information?</div>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-evenly pb-5">
        <div class="flex-1 m-2 text-center">
          <Button class="p-button-outlined text-xs w-full text-center" @click="skipCorticoEmrTutorial" id="tutorialGetStartedBtn" label="Skip for Now" />
        </div>
        <div class="flex-1 m-2 text-center">
          <Button class="p-button text-xs w-full text-center" @click="emrIntegrationTutorialStep = 2" id="tutorialGetStartedBtn" label="Show Me How" />
        </div>
      </div>
    </template>
    <div id="emrIntegrationTutorialStep2Div" class="animated fade-in-up" v-else>
      <div class="flex w-full max-w-lg justify-center rounded-b-xl pb-4">
        <div class="flex w-full max-w-lg flex-col">
          <div class="w-full text-center font-bold text-slate-700 text-lg">Make Consults Directly from Your EMR!</div>
          <div class="text-base w-full max-w-lg px-4 text-slate-600">
            <ol class="text-sm p-3">
              <li>You will need Google Chrome web browser for EMR integration support.</li>
              <li>Click the <span class="text-slate-700 font-bold">Add EMR Plugin</span> button below which will open the page to add the plugin for your EMR.</li>
              <li>Click the <span class="text-slate-700 font-bold">Add to Chrome</span> button on the plugin page to setup the plugin.</li>
              <li>Login to your EMR and go to a patient's profile you would like to consult about.</li>
              <li>Notice the <span class="text-slate-700 font-bold">Consult with a Specialist</span> button on the lower right corner? Click that button and you will be able to consult in VH using patient information from the EMR!</li>
            </ol>
            <p class="text-sm">Having trouble setting up the plugin? <span class="text-blue font-bold">Click here to watch a tutorial video</span>.</p>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-evenly pb-5">
        <div class="flex-1 m-2 text-center">
          <Button class="p-button-outlined text-xs w-full text-center" @click="skipCorticoEmrTutorial" id="tutorialGetStartedBtn" label="Skip for Now" />
        </div>
        <div class="flex-1 m-2 justify-center">
          <a class="p-button text-xs w-full justify-center" id="tutorialGetStartedBtn" target="_blank" href="https://chrome.google.com/webstore/detail/cortico-oscar-emr-telehea/dpbaboapnbnifbekhhjggaolbnncfjfe"> Add EMR Plugin </a>
        </div>
      </div>
    </div>
  </Dialog>
  <Dialog header="Cancel Making Consult" v-model:visible="openConfirmCancelExternalReferralRequestDialog" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" :modal="true">
    <p class="m-0 text-sm text-center font-semibold">Are you sure you want to cancel making a consult for patient {{ externalReferralRequest?.referral_data.patient_first_name }} {{ externalReferralRequest?.referral_data.patient_last_name }}?</p>
    <p class="text-sm mt-2">This will remove the data brought over from your EMR to make the consult. However, you can always repeat the process by clicking the Consult a Specialist button from your EMR.</p>
    <template #footer>
      <Button label="No" icon="pi pi-times" @click="openConfirmCancelExternalReferralRequestDialog = false" class="p-button-text p-button-secondary" />
      <Button label="Yes" icon="pi pi-check" @click="removeExternalReferralRequest" autofocus class="p-button-secondary" />
    </template>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import BookingHeader from '../../../components/referral/BookingHeader.vue';
import SelectSpecialty from './make_referral/SelectSpecialty.vue';
import SelectSpecialist from './make_referral/SelectSpecialist.vue';
import SelectDate from './make_referral/SelectDate.vue';
import ConfirmReferralModal from './make_referral/ConfirmReferralModal.vue';
import BookingProgressBar from '@/components/referral/BookingProgressBar.vue';
import moment from 'moment';
import ChangeActingUserDropdown from '@/components/org_unit/ChangeActingUserDropdown.vue';
import Loading from 'vue-loading-overlay';
import RemainingConsults from '@/components/gp/RemainingConsults.vue';
import Modal from '@/components/misc/Modal.vue';
import Button from 'primevue/button';
import Message from 'primevue/message';
import Dropdown from 'primevue/dropdown';
import Skeleton from 'primevue/skeleton';
import TimezoneModal from '@/components/misc/TimezoneModal.vue';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';
import Dialog from 'primevue/dialog';
import InlineMessage from 'primevue/inlinemessage';
import { createPopup } from '@typeform/embed';
import '@typeform/embed/build/css/popup.css';

export default {
  inject: ['mixpanel'],
  components: {
    SelectSpecialty,
    SelectSpecialist,
    SelectDate,
    ConfirmReferralModal,
    ChangeActingUserDropdown,
    Loading,
    RemainingConsults,
    BookingProgressBar,
    Modal,
    Button,
    Message,
    BookingHeader,
    Dropdown,
    Skeleton,
    TimezoneModal,
    Checkbox,
    Textarea,
    Dialog,
    InlineMessage,
  },
  computed: {
    ...mapGetters(['smartPathReferralForm', 'smartPathSpecialty', 'smartPathReferralTransactionId', 'referralToRebook', 'loggedInUser', 'isOrgUnitHead', 'orgUnitUsers', 'numberRemaingReferrals', 'isGP', 'actingUser', 'schedulesOfSpecialists', 'isAllowedMakeReferral', 'tutorialStatus', 'econsultCounts', 'hasProvinceOfPractice', 'allSpecialties', 'isSpecialist', 'referrals', 'isOnboarding', 'selectedSmartPathReferralContributor', 'smartPathReferralAuthor', 'schedulesOfSpecialists', 'selectedInteractionType', 'allReferrals', 'getUserActionByName', 'invitedByOrgUnitHead', 'storedExternalReferralRequestCode', 'externalReferralRequest']),
    isTutorial() {
      return this.tutorialStatus != '';
    },
    getTutorialStep() {
      let steps = { phone: { 'org-select-user': '0/4', 'select-a-specialty': '1/4', 'select-a-specialist': '2/4', 'select-a-time': '3/4', 'referral-form': '4/4' } };
      if (!this.hasProvinceOfPractice(this.actingUser, 'NS')) {
        steps = { ...steps, phone: { 'org-select-user': '1/3', 'select-a-specialty': '1/4', 'select-a-specialist': '1/3', 'select-a-time': '2/3', 'referral-form': '3/3' } };
      }
      return steps[this.selectedInteractionType][this.tutorialStatus];
    },
    pendingConsults() {
      return this.allReferrals.filter((referral) => {
        return referral.interaction_type.name === 'phone' && referral.status == 'Pending';
      });
    },
  },
  data() {
    return {
      selectedScreen: 'selectSpecialty',
      selectedSpecialty: { name: null, id: null },
      selectedSpecialists: [],
      selectedSchedules: [],
      selectedDate: null,
      selectedDateTime: null,
      selectedUserId: null,
      referralDuration: null,
      previousRoute: null,
      isLoading: false,
      bookingProcessStartDateTime: new Date(),
      consultType: 'phone',
      popUpConfirmReferralModal: false,
      selectedSpecialist: null,
      selectedSpecialties: [],
      selectedInteractionTypeMenu: {
        icon: 'pi pi-phone',
        title: 'Phone Consults',
        subTitle: 'Schedule Phone Consults',
        onClick: () => {
          this.$refs.menu.toggle();
        },
        consultType: 'phone',
      },
      interactionTypeMenuItems: [
        {
          icon: 'pi pi-phone',
          title: 'Phone Consults',
          subTitle: 'Schedule phone consults',
          consultType: 'phone',
        },
      ],
      filterMenuItems: [],
      selectedAvailabilitySlot: null,
      openTimezoneModal: false,
      contactWhenAvailabilitySet: false,
      availabilityFeedbackMessage: '',
      openEmrIntegrationTutorialDialog: false,
      emrIntegrationTutorialStep: 1,
      openConfirmCancelExternalReferralRequestDialog: false,
    };
  },
  mounted() {
    window.history.pushState(null, null, window.location.href);

    const _this = this;
    window.onpopstate = function () {
      if (_this.selectedScreen === 'selectSpecialist' && _this.loggedInUser) {
        if (_this.hasProvinceOfPractice(_this.loggedInUser, ['NS', 'ON', 'PE'])) {
          _this.goToSelectSpecialty();
        }
      } else if (_this.selectedScreen === 'selectDate' && _this.loggedInUser) {
        _this.goToSelectSpecialist(_this.selectedSpecialty);
      }
    };

    let userTutorial = this.getUserActionByName(this.loggedInUser, 'book_a_consult');
    const autoFaxAConsultTutorial = this.getUserActionByName(this.loggedInUser, 'auto_fax_a_consult');
    const now = moment();
    const nowDefaultTimezone = now.toDate().toLocaleString('en-US', { timeZone: this.loggedInUser.default_timezone });

    if (this.hasProvinceOfPractice(this.loggedInUser, ['NS', 'ON', 'AB', 'NL', 'PE'])) {
      if (this.getCurrentTimezoneLocalString() !== nowDefaultTimezone && !this.loggedInUser.hasViewDefaultTimezoneModal) {
        this.updateLoggedInUser({ ...this.loggedInUser, hasViewDefaultTimezoneModal: true });
        this.openTimezoneModal = true;
      } else if (userTutorial && userTutorial.status == 'Active') {
        this.$refs.tutorialModal.openModal();
      } else if (this.getUserActionByName(this.loggedInUser, 'medaccess_cortico_plugin')?.status === 'Active' && this.tutorialFit()) {
        this.openEmrIntegrationTutorialDialog = true;
      } else if (autoFaxAConsultTutorial?.status === 'Active' && this.tutorialFit()) {
        this.$refs.autoFaxFeatureModal.openModal();
      }
    }
  },
  created() {
    // this.previousRoute = window.history.state.back;
    this.setSelectedInteractionType('phone');
    this.selectedInteractionTypeMenu = this.interactionTypeMenuItems.find((item) => item.consultType === this.selectedInteractionType);
    this.isLoading = true;
    this.start_datetime = moment(this.selectedDateTime).format('YYYY-MM-DD HH:mm:ss');
    this.end_datetime = moment(this.selectedDateTime).add(this.referralDuration, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    if (this.$route.query.selectedScreen) {
      this.selectedScreen = this.$route.query.selectedScreen;
      this.selectedSpecialty.name = this.$route.query.specialty;
      this.selectedSpecialty.id = this.$route.query.id;
      this.selectedSpecialties = [this.selectedSpecialty];
      this.isLoading = false;
    } else if (this.referralToRebook !== null) {
      this.setSelectedInteractionType(this.referralToRebook.interaction_type.name);
      this.isLoading = false;
      this.selectedSpecialty = this.referralToRebook.specialty;
      this.selectedScreen = 'selectSpecialist';
      this.setActingUser(this.referralToRebook.referee);
      this.selectedSpecialties = [this.selectedSpecialty];
    } else if (this.isOrgUnitHead(this.loggedInUser)) {
      // If it's a new referral and being made by a Org Unit Head, get the org GPs
      this.selectedScreen = null;
      this.getOrgUnitUsers(this.loggedInUser.org_units[0].id).then(() => {
        this.setActingUser(null);
        this.isLoading = false;
      });
    } else if (this.isAllowedMakeReferral !== true) {
      this.$router.push('/verification');
      // in the case of referring to the author of the smartpath
    } else if (this.smartPathReferralForm !== null && this.selectedSmartPathReferralContributor !== null) {
      let selectedSpecialistIds = [this.selectedSmartPathReferralContributor.id];
      this.getUpcomingScheduleWithScheduleBufferOfUser({ specialistIds: selectedSpecialistIds, smartPathReferral: true })
        .then(() => {
          this.selectedScreen = 'selectDate';
          this.selectedSpecialty = this.smartPathSpecialty;
          this.selectedSpecialties = [this.smartPathSpecialty];
          this.selectedSpecialist = this.selectedSmartPathReferralContributor;
          this.selectedSpecialists = [this.selectedSmartPathReferralContributor];
          this.consultType = 'phone';
          this.selectedSchedules = this.schedulesOfSpecialists;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
      // in the case of referring to a SmartPath suggested specialty
    } else if (this.smartPathReferralForm === null && this.smartPathSpecialty !== null) {
      this.selectedScreen = 'selectSpecialist';
      this.selectedSpecialty = this.smartPathSpecialty;
      this.selectedSpecialties = [this.smartPathSpecialty];
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.setBookingStepBasedOnUserProvince(this.actingUser);
    }
    this.getZonesByProvince(this.loggedInUser.practice_province.id).then().catch();
    if (this.storedExternalReferralRequestCode) {
      this.isLoading = true;
      this.getExternalReferralRequestByCode(this.storedExternalReferralRequestCode)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    ...mapActions(['setReferralToRebook', 'getOrgUnitUsers', 'checkIfUserIsAllowedToMakeReferral', 'addReferral', 'getSpecialties', 'getUpcomingScheduleWithScheduleBufferOfUser', 'getZonesByProvince', 'availabilityFeedback', 'getExternalReferralRequestByCode', 'removeExternalReferralRequestCode', 'getRecentReferralsOfUser']),
    ...mapMutations(['setActingUser', 'setTutorialStatus', 'setSmartPathReferralForm', 'setSmartPathSpecialty', 'setSmartPathReferralTransactionId', 'setSelectedInteractionType', 'setInvitedByOrgUnitHead', 'updateLoggedInUser']),
    goToPendingReferralHistory() {
      this.$router.push({ path: '/referralhistory/', query: { filterStatus: 'Pending' } });
    },
    goToSelectSpecialty() {
      if (this.tutorialStatus == 'try-smartpath') {
        this.setTutorialStatus('');
      } else if (this.tutorialStatus != '') {
        this.setTutorialStatus('select-a-specialty');
      }
      this.selectedScreen = 'selectSpecialty';
    },
    goToSelectSpecialist(selectedSpecialty) {
      this.selectedSpecialists = [];
      if (this.tutorialStatus != '' && this.tutorialStatus != 'try-smartpath') {
        this.setTutorialStatus('select-a-specialist');
      }
      if (selectedSpecialty) {
        this.selectedSpecialty = selectedSpecialty;
        this.selectedSpecialties = [selectedSpecialty];
      }
      this.selectedScreen = 'selectSpecialist';
      this.consultType = null;
    },
    goToSelectDate(event) {
      if (this.tutorialStatus != '') {
        this.setTutorialStatus('select-a-time');
      }
      if (event) {
        this.selectedSpecialty = event.selectedSpecialty;
        this.selectedSpecialists = event.specialists;
        this.selectedSchedules = event.schedules;
        this.selectedSchedules = this.selectedSchedules.sort((a, b) => a.start_datetime - b.start_datetime);
      }
      this.selectedScreen = 'selectDate';
      this.consultType = 'phone';
    },
    popUpConfirmReferral(event) {
      this.popUpConfirmReferralModal = true;
      this.selectedSpecialist = event.user;
      this.selectedSpecialty = event.selectedSpecialty;
      this.consultType = event.consultType;
      this.selectedAvailabilitySlot = event.selectedAvailabilitySlot;
      this.mixpanel.track('Make Referral - Clicked Book Next Availability', { specialty: this.selectedSpecialty.name, specialistId: this.selectedSpecialist.id, availabilitySlot: this.formatDateTimeToSpecifiedFormat(this.selectedAvailabilitySlot.start_datetime, 'MMM DD h:mmA', true) });
    },
    confirmReferralFromPopUp(event) {
      let confirmData = {
        selectedSpecialist: this.selectedSpecialist,
        selectedSpecialty: this.selectedSpecialty,
        consultType: this.consultType,
        call_asap: event,
      };
      if (!event) {
        confirmData.selectedAvailabilitySlotId = this.selectedAvailabilitySlot.id;
      }
      this.confirmReferral(confirmData);
    },
    closeConfirmReferralModal() {
      this.popUpConfirmReferralModal = false;
    },
    confirmReferral(event) {
      if (this.tutorialStatus != '') {
        this.setTutorialStatus('referral-form');
      }

      this.selectedDateTime = event.selectedDateTime;
      this.selectedSpecialist = event.selectedSpecialist;
      this.referralDuration = event.referralDuration;
      this.isLoading = true;
      let referreeId = this.actingUser.id;
      let referral = {
        referee_id: referreeId,
        referral_person_id: this.selectedSpecialist.id,
        specialty_id: this.selectedSpecialty.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        type: event.consultType,
        booking_process_start_datetime: this.bookingProcessStartDateTime,
        booking_process_end_datetime: new Date(),
        call_asap: event.call_asap,
        is_registered_user: this.selectedSpecialist.is_registered_user,
      };
      if (event.selectedAvailabilitySlotId != null) {
        referral.user_availability_slot_id = event.selectedAvailabilitySlotId;
      }
      // gotta pass these up for a smartPath referral
      if (this.smartPathReferralForm !== null) {
        referral['smartPathForm'] = this.smartPathReferralForm;
        referral['transactionId'] = this.smartPathReferralTransactionId;
      }

      // If this referral was confirmed through a external referral request, add the
      // external referral request information.
      if (this.externalReferralRequest) {
        referral.external_referral_request_id = this.externalReferralRequest.id;
      }
      if (!this.isDemoAccount(this.actingUser)) {
        this.addReferral(referral)
          .then((res) => {
            this.isLoading = false;
            if (res.data.message == 'success') {
              // reset the smartPath referral data as it will mess with future referrals
              if (this.smartPathReferralForm !== null) {
                this.setSmartPathReferralForm(null);
                this.setSmartPathSpecialty(null);
                this.setSmartPathReferralTransactionId(null);
              }
              if (this.tutorialStatus != '') {
                this.setTutorialStatus('referral-form');
              }
              this.removeExternalReferralRequestCode();
              this.$router.push('/referral/' + res.data.data.id);
            } else {
              this.error = res.data.error;
              this.error += ' This may be due to that someone has already made a referral in your selected time,' + ' or you may have pressed the confirm button twice.';
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          });
      } else {
        this.$router.push(`/demoreferral?specialty=${this.selectedSpecialty.id}`);
      }

      this.consultType = event.consultType;
    },
    bookEconsult(event) {
      if (event) {
        this.selectedSpecialists = event.specialists;
      }
      //Get econsult total for selected users
      const econsultTotalOfSelectedUsers = this.econsultCounts.filter((obj) => this.selectedSpecialists.find((user) => user.id === obj.user_id));
      //Get lowest number of econsults for selected users
      const lowestCount = Math.min(...econsultTotalOfSelectedUsers.map((obj) => obj.total_count));
      //Get an array of econsult totals of selected with the value of lowestCount
      const userIdWithLowestCount = econsultTotalOfSelectedUsers.filter((obj) => obj.total_count === lowestCount);
      //Get random user id from userIdWithLowestCount
      const randomUserId = userIdWithLowestCount[Math.round(Math.random() * (userIdWithLowestCount.length - 1))];

      this.consultType = 'econsult';

      const referral = {
        selectedDateTime: moment().toDate(),
        selectedSpecialist: this.selectedSpecialists.find((user) => user.id == randomUserId.user_id),
        referralDuration: 0,
        consultType: 'econsult',
      };

      this.confirmReferral(referral);
    },
    bookOnDemand(event) {
      this.selectedSpecialty = event.selectedSpecialty;
      let confirmData = {
        selectedSpecialist: event.selectedSpecialist,
        selectedSpecialty: event.selectedSpecialty,
        consultType: 'phone',
        call_asap: true,
      };
      this.confirmReferral(confirmData);
    },
    updateReferee() {
      if (this.tutorialStatus === 'org-select-user-in-person') {
        this.setTutorialStatus('select-interaction-type');
      } else if (this.tutorialStatus !== '') {
        this.setTutorialStatus('select-a-specialty');
      }
      if (this.actingUser.id !== 0) {
        this.isLoading = true;
        this.checkIfUserIsAllowedToMakeReferral(this.actingUser.id)
          .then(() => {
            if (this.isAllowedMakeReferral) {
              this.resetReferralSelections();
              this.isLoading = false;
              this.setBookingStepBasedOnUserProvince(this.actingUser);
            } else {
              this.$router.push('/verification');
            }
          })
          .catch(() => {
            this.showGenericErrorToast();
          });
      }
    },
    setBookingStepBasedOnUserProvince(user) {
      this.isLoading = true;
      const actingUserProvince = user.practice_province;
      if (this.hasProvinceOfPractice(user, ['NS', 'ON', 'PE']) && this.actingUser.booking_hallways?.find((hallway) => hallway.name == 'General VHC Hallway')) {
        this.selectedSpecialties = [];
        this.isLoading = false;
        if (this.allReferrals.length == 0) {
          this.getRecentReferralsOfUser({
            user_id: user.id,
            required_relations: ['referral_form:id,referral_id,patient_first_name,patient_last_name,is_signed_off', 'interaction_type', 'specialty'],
          })
            .then(() => {})
            .catch(() => {});
        }
      } else {
        this.getSpecialties({
          requesting_user_id: this.actingUser.id,
          province_name: actingUserProvince.name,
          interaction_type_names: ['phone'],
          for_referral: 1,
        })
          .then(() => {
            this.selectedSpecialties = this.allSpecialties;
            this.selectedScreen = 'selectSpecialist';
            this.isLoading = false;
          })

          .catch(() => {
            // this.showGenericErrorToast();
            this.isLoading = false;
          });
      }
    },
    resetReferralSelections() {
      this.selectedScreen = 'selectSpecialty';
      this.selectedSpecialty = { name: null, id: null };
      this.selectedSpecialts = [];
      this.selectedSchedules = [];
      this.selectedDate = null;
      this.selectedDateTime = null;
      this.selectedUserId = null;
      this.referralDuration = null;
    },
    skipTutorial() {
      this.updateUserTutorialByName(this.loggedInUser, 'book_a_consult', true, 'Skipped');
      this.setTutorialStatus('');
    },
    skipCorticoEmrTutorial() {
      this.updateUserTutorialByName(this.loggedInUser, 'medaccess_cortico_plugin', true, 'Skipped');
      this.openEmrIntegrationTutorialDialog = false;
      this.emrIntegrationTutorialStep = 1;
    },
    skipTutorialGoToVerification() {
      this.closeTutorialModal();
      this.$router.push({ path: `/verification` });
    },
    closeTutorialModal() {
      this.skipTutorial();
      this.$refs.tutorialModal.closeModal();
    },
    startTutorial() {
      if (this.isOrgUnitHead(this.loggedInUser)) {
        this.setTutorialStatus('org-select-user');
      } else {
        this.makeConsultTutorialOnClick();
      }
      this.$refs.tutorialModal.closeModal();
    },
    makeConsultTutorialOnClick() {
      if (this.selectedScreen === 'selectSpecialty') {
        this.setTutorialStatus('select-a-specialty');
      } else if (this.selectedScreen === 'selectSpecialist') {
        this.setTutorialStatus('select-a-specialist');
      } else if (this.selectedScreen === 'selectDate') {
        this.setTutorialStatus('select-a-time');
      }
    },
    showSuccessToast() {
      this.$toast.add({
        severity: 'success',
        summary: 'Email Notification Requested',
        detail: 'We will notify you via email when the time comes.',
        life: 10000,
      });
    },
    selectedInteractionTypeMenuOnChange() {
      this.setSelectedInteractionType(this.selectedInteractionTypeMenu.consultType);
      if (this.tutorialStatus === 'select-interaction-type') {
        this.setTutorialStatus('select-a-specialty');
      }
    },
    removeHighlighted() {
      let highlight = document.querySelector('.p-highlight');
      if (highlight) {
        highlight.classList.remove('p-highlight');
      }
      let padding = document.querySelector('.p-dropdown-items');
      if (padding) {
        padding.style.padding = 0;
      }
    },
    closeAutoFaxFeatureModal() {
      this.$refs.autoFaxFeatureModal.closeModal();
      this.updateUserTutorialByName(this.loggedInUser, 'auto_fax_a_consult', false, 'Skipped');
    },
    goToFaxSettings() {
      this.$router.push({ path: `/myaccount`, query: { showFaxNumbers: true } });
    },
    openAvailabilityNotHereModal() {
      this.$refs.letUsKnowAvailabilityModal.openModal();
    },
    submitAvailabilityFeedbackForm() {
      this.isLoading = true;
      let data = { userId: this.loggedInUser.id, feedback: this.availabilityFeedbackMessage, contact: this.contactWhenAvailabilitySet };
      this.availabilityFeedback(data)
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Feedback Submitted',
            detail: 'Thanks for the feedback!',
            life: 5000,
          });
          this.availabilityFeedbackMessage = '';
          this.contactWhenAvailabilitySet = false;
          this.$refs.letUsKnowAvailabilityModal.closeModal();
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    removeExternalReferralRequest() {
      this.removeExternalReferralRequestCode();
      this.openConfirmCancelExternalReferralRequestDialog = false;
    },
  },
  // async beforeRouteLeave(to, from, next) {
  //   //enables navigating with the browser back button to the previous component, but prevents them from navigating away
  //   if (to.path === this.previousRoute && this.selectedScreen === 'selectSpecialty' && this.loggedInUser) {
  //     next(false);
  //   } else if (to.path === this.previousRoute && this.selectedScreen === 'selectSpecialist' && this.loggedInUser) {
  //     if (this.hasProvinceOfPractice(this.loggedInUser, ['NS', 'ON', 'PE'])) {
  //       this.goToSelectSpecialty();
  //       next(false);
  //     }
  //     next(false);
  //   } else if (to.path === this.previousRoute && this.selectedScreen === 'selectDate' && this.loggedInUser) {
  //     this.goToSelectSpecialist(this.selectedSpecialty);
  //     next(false);
  //   } else {
  //     next();
  //   }
  // },
  unmounted() {
    // If referral was set to be rebooked, set it back to null if user goes to another screen
    this.setReferralToRebook(null);
    if (this.tutorialStatus != 'referral-form' && this.tutorialStatus != 'set-availability') {
      if (this.tutorialStatus == 'select-a-specialty' || this.tutorialStatus == 'select-a-specialist' || this.tutorialStatus == 'select-a-time') {
        this.setTutorialStatus('select-a-specialty');
      } else {
        this.setTutorialStatus('');
      }
    }
  },
  watch: {
    selectedInteractionType(val) {
      if (val) {
        this.selectedInteractionTypeMenu = this.interactionTypeMenuItems.find((type) => type.consultType === val);
        this.resetReferralSelections();
      }
    },
  },
};
</script>

<style>
.econsult-btn.p-button.p-component {
  color: white !important;
  background: var(--vh-yellow);
}

.referral-tooltip {
  position: relative;
}

.referral-tooltip .referral-tooltiptext {
  width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  top: 100px;
  left: -130px;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.referral-tooltip .referral-tooltiptext::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 110%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.referral-tooltip .referral-tooltiptext.org-select-user-tooltip {
  top: -250px;
}

.referral-tooltip-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.in-front {
  position: relative;
  z-index: 100;
}

.dark-mint-background {
  /* background-color: var(--vh-majenta); */
  background-color: var(--vh-new-feature-blue);
}

.dark-mint-header .modal__header {
  /* background-color: var(--vh-majenta); */
  background-color: var(--vh-new-feature-blue);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.tutorial {
  /* background-color: var(--vh-orange); */
  background-color: var(--vh-majenta);
}

.only-child-m-auto:only-child {
  margin: 0 auto;
}

#interaction-type-dropdown {
  border: 1px solid white;
  color: white;
  position: absolute;
  right: 285px;
  top: 16px;
  height: 43.59px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  color: white;
  width: 250px;
}

#interaction-type-dropdown:hover + .in-person-new-feature-tool-tip {
  display: block;
}

#interaction-type-dropdown-skeleton {
  position: absolute;
  background-color: rgba(129, 140, 248);
  z-index: 11;
  right: 278px;
  top: 7px;
}

@media (max-width: 1256px) {
  #interaction-type-dropdown {
    right: 22px;
    top: 68px;
  }
  #interaction-type-dropdown-skeleton {
    right: 13px;
    top: 60px;
  }
  .in-person-new-feature-tool-tip {
    top: 19px !important;
    right: 284px !important;
  }
}

@media (max-width: 768px) {
  #interaction-type-dropdown {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 150px;
  }
  #interaction-type-dropdown-skeleton {
    left: 250px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    top: 145px;
  }
  .in-person-new-feature-tool-tip {
    left: 0;
    right: 0 !important;
    margin-left: auto;
    margin-right: auto;
    top: 17px !important;
  }
  .in-person-new-feature-tool-tip::after {
    transform: rotate(0deg) !important;
    top: 100% !important;
    left: 50% !important;
  }
}

#interaction-type-dropdown .p-dropdown-label.p-inputtext,
#interaction-type-dropdown .pi-chevron-down {
  color: white;
}

#interaction-type-dropdown + .p-dropdown-item.p-highlight {
  color: #212529 !important;
  background: transparent !important;
}

/* #interaction-type-dropdown-2 {
  border: 1px solid white;
  color: white;
  position: absolute;
  right: 22px;
  top: 68px;
  height: 43.59px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  color: white;
  width: 250px;
}

@media (max-width: 1072px) {
  #interaction-type-dropdown-2 {
    display: none;
  }
}

#interaction-type-dropdown-2 .p-dropdown-label.p-inputtext,
#interaction-type-dropdown-2 .pi-chevron-down {
  color: white;
}

#interaction-type-dropdown-2 + .p-dropdown-item.p-highlight {
  color: #212529 !important;
  background: transparent !important;
} */

.in-person-new-feature-tool-tip {
  position: absolute;
  top: -60px;
  right: 548px;
  border-radius: 6px;
  width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  z-index: 100;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.in-person-new-feature-tool-tip::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.in-person-new-feature-tool-tip-btn {
  background-color: #005d9b;
  @apply h-6 w-14 rounded;
}

#emrIntegrationTutorialDialog ol {
  list-style: revert;
}

#emrIntegrationTutorialStep2Div .animate-bouncingg {
  animation: bounce 1s once;
}
</style>
