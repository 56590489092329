<template>
  <div class="main-inner-wrap">
    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block"><i class="pi pi-users mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Adding Users to Your Practice Group</h1>
        <h1 class="text-sm text-gray-200">Fill out the form below to create an account for a practitioner in your practice group. They will be sent an email to confirm the creation of their account. Alternatively if the user already has a Virtual Hallway account, click "Add Existing User" and enter their email address.</h1>
      </div>
    </div>
    <div class="lg:max-w-8xl flex w-full max-w-full items-start justify-center px-5 pt-8 pb-20 text-center">
      <div name="slide-fade">
        <div :class="['registration-form-card', 'shadow', 'card', 'rounded-lg', 'p-8', { 'in-front': tutorialStatus !== '' }, 'tooltip']">
          <div class="m-auto mb-8 flex w-full justify-between p-2 text-center">
            <h2 class="mb-2 mt-1 flex-col text-xl font-bold text-gray-500">Add User to Practice Group</h2>
            <Button icon="pi pi-plus flex-col" label="Add Existing User" @click="clickAddExistingUser" name="addExistingUser" />
            <Dialog header="Add Existing User" v-model:visible="displayModalAddExistingUser" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '40vw', maxWidth: '450px' }" :modal="true" :draggable="false">
              <div class="mb-2 px-1">
                <p class="info-paragraph--gray mb-6 flex p-4">Enter the email of an already registered clinician and an email will be sent requesting their permission to be added to your practice group.</p>
              </div>
              <div class="mt-8 flex">
                <span class="p-float-label">
                  <InputText type="email" name="email" id="email" v-model="existingUserEmail" class="w-full" required maxLength="255" />
                  <i class="pi pi-envelope pr-2" />
                  <label for="email">Email</label>
                </span>
              </div>
              <div class="p-3">
                <ul v-for="(error, i) in addingExistingUserErrors" :key="i" class="error w-full">
                  <li class="w-full">{{ error }}</li>
                </ul>
                <p class="success text-sm">
                  {{ addingExistingUserSuccess }}
                </p>
              </div>
              <template #footer>
                <Button label="Close" icon="pi pi-times" @click="closeModalAddExistingUser" class="p-button-text p-button-secondary" />
                <Button label="Add" icon="pi pi-plus" @click="requestToAddExistingUser" autofocus />
              </template>
            </Dialog>
          </div>
          <AddUserForm :propsUser="user" :loggedInUser="loggedInUser" @goToNext="goToNext" />
          <p class="error">
            {{ error }}
          </p>
          <div v-if="tutorialStatus === 'add-user'">
            <span class="org-add-user-tooltiptext py-4 px-4">
              <h1 class="text-left text-xl font-bold text-white">Input the first practitioner’s information into the form below. It is important to include their direct number or cell phone as this is how the consultant will call them.</h1>
              <br />
              <div class="text-left font-bold text-white">
                <p>Click Join.</p>
                <br />
              </div>
              <div class="flex flex-row justify-between">
                <button class="text-xs font-bold text-white" @click="skipTutorial" id="skipTutorialBtn">Skip Tutorial</button>
                <div class="text-xs font-bold text-white">2/3</div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AddUserForm from '@/views/public/registration/registrationForm/AddUserForm.vue';
import Loading from 'vue-loading-overlay';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';

export default {
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        confirmEmail: '',
        practice_province_id: '',
        physician_type: null,
        specialty_ids: [],
        provider_type: null,
        office_email: null,
        office_phone: null,
        street: '',
        city: '',
        postal_code: '',
        province: '',
        cpsns: null,
        password: '',
        password_confirmation: '',
        is_in_email_list: true,
        hear_about_us: null,
        other_specialty: null,
      },
      error: '',
      response: '',
      processItem1: true,
      processItem2: false,
      isLoading: false,
      existingUserEmail: null,
      displayModalAddExistingUser: false,
      addingExistingUserErrors: [],
      addingExistingUserSuccess: null,
    };
  },
  components: {
    AddUserForm,
    Loading,
    Button,
    Dialog,
    InputText,
  },
  computed: {
    ...mapGetters(['loggedInUser', 'tutorialStatus']),
  },
  methods: {
    ...mapActions(['addUserAsOrgUnitHead', 'requestToAddExistingUserToOrgUnit']),
    ...mapMutations(['setTutorialStatus']),
    submit() {
      this.error = '';
      this.isLoading = true;
      let data = {};
      data = this.user;
      data.org_unit_id = this.loggedInUser.org_units[0].id;
      this.addUserAsOrgUnitHead(data)
        .then((res) => {
          this.isLoading = false;
          this.response = res;
          this.$toast.add({
            severity: 'success',
            summary: 'User Added!',
            detail: 'The user has been successfully added, please ask the user to verify their email to login to their account.',
            life: 3000,
          });
          if (this.tutorialStatus !== '') {
            // This is setting the tutorial to Closed since it's the last step in the tutorial.
            this.updateUserTutorialByName(this.loggedInUser, 'add_user_to_org_unit', true, 'Completed');
            // But we still need to show the final tooltip thus setting the tutorial status.
            if (data.physician_type === 'General Practitioner') {
              this.setTutorialStatus('add-user-complete-gp');
            } else if (data.physician_type === 'Specialist') {
              this.setTutorialStatus('add-user-complete-sp');
            }
          }
          this.$router.push({ path: `/manageusers` });
        })
        .catch((res) => {
          this.isLoading = false;
          if (res) {
            this.error = res;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: this.error,
              life: 3000,
            });
          }
        });
    },
    requestToAddExistingUser() {
      this.addingExistingUserErrors = [];
      this.requestToAddExistingUserToOrgUnit({
        email: this.existingUserEmail,
      })
        .then((res) => {
          this.addingExistingUserSuccess = res.message;
        })
        .catch((error) => {
          if (error.status == 422) {
            for (const key in error.data.errors) {
              error.data.errors[key].forEach((element) => {
                this.addingExistingUserErrors.push(element);
              });
            }
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          }
        });
    },
    goToNext($event) {
      this.user = { ...this.user, ...$event };
      this.submit();
    },
    clickAddExistingUser() {
      this.displayModalAddExistingUser = true;
    },
    closeModalAddExistingUser() {
      this.displayModalAddExistingUser = false;
    },
    skipTutorial() {
      this.updateUserTutorialByName(this.loggedInUser, 'add_user_to_org_unit', true, 'Skipped');
      this.setTutorialStatus('');
    },
  },
};
</script>

<style scoped>
.process-dropdown-text {
  margin-top: 10px;
  text-align: left;
  padding: 0px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  transition: opacity 0.5s ease;
  height: 0px;
}
.process-dropdown-text.active {
  visibility: visible;
  opacity: 1;
  height: 100%;
  padding: 30px;
}

.registration-form-card {
  margin: 0;
  max-width: 645px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(400px);
  opacity: 0;
}

.tooltip {
  position: relative;
}

.tooltip .org-add-user-tooltiptext {
  width: 300px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 100px;
  left: -250px;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.tooltip .org-add-user-tooltiptext::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 102%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.p-float-label label {
  margin-left: 0.5rem;
}
.p-inputtext,
.p-dropdown,
.p-multiselect {
  background-color: #fafafa !important;
}
</style>
