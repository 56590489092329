<template>
  <div v-if="isLoading && referral !== null && loggedInUser" class="main-inner-wrap vld-parent">
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
  </div>
  <div v-if="!isLoading && referral !== null && loggedInUser" class="main-inner-wrap">
    <div v-if="referral.status == 'Pending'" class="w-full">
      <BookingProgressBar event="referralForm" :selectedSpecialty="referral.specialty" :selectedSpecialists="[referral.referral_person]" :selectedDate="formatDateTime(referral.start_datetime)" :consultType="consultType" />
    </div>
    <div v-if="referral.status == 'Pending' && canEditReferralForm(loggedInUser, referral.referee, referral)" class="align-start relative mt-4 mb-3 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden md:block"><i class="pi pi-lock mb-2 mt-2 mr-5 text-white" style="font-size: 3rem"></i></div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Submit the Request Form</h1>
        <h1 v-if="isGP(loggedInUser) || isSpecialist(loggedInUser)" class="text-sm text-gray-200">If you need to come back to your work, you can click Save at the bottom of this form.</h1>
        <h1 v-if="isOrgUnitHead(loggedInUser)" class="text-sm text-gray-200">Once complete, the Primary Care Practitioner will need to log into their account to approve and submit the form to the specialist.</h1>
      </div>
      <ConsultFormTipsDialog />
    </div>

    <div v-if="referral.call_asap == false && referral.status == 'Upcoming' && isGP(loggedInUser)" class="align-start relative mt-4 flex w-full flex-row items-center justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <i class="pi pi-check-circle mb-2 mt-2 mr-5 text-white hidden-mobile" style="font-size: 3rem"></i>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Consult Successfully Submitted</h1>
        <h1 class="mb-1 text-sm text-gray-200" v-if="referral.interaction_type.name === 'phone'">
          You will receive a call by <span class="text-red-300">{{ referral.consult_start }}</span> on <span class="text-red-300">{{ referral.consult_date }}</span> from <span class="text-red-300">{{ displayNameByRole(referral.referral_person) }}</span>
        </h1>
      </div>
    </div>

    <div v-if="referral.call_asap == true && referral.status == 'Upcoming' && isGP(loggedInUser)" class="align-start bg-coral relative mt-4 flex w-full flex-row items-center justify-start rounded-2xl p-6 text-left shadow">
      <i class="fas fa-bolt mb-2 mt-2 mr-5 text-white hidden-mobile" style="font-size: 3rem"></i>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">OnDemand Consult Submitted</h1>
        <h1 class="text-sm font-semibold text-white" v-if="referral.interaction_type.name === 'phone'">
          {{ displayNameByRole(referral.referral_person) }} will directly call at the provided number at their earliest availability, at the latest by <span class="text-lg font-bold">{{ referral.on_demand_consult_date }}</span> at <span class="text-lg font-bold">{{ referral.consult_end }}</span
          >.
        </h1>
      </div>
    </div>

    <div v-if="referral.call_asap == true && referral.status == 'Upcoming' && isSpecialist(loggedInUser)" class="align-start bg-coral relative mt-4 flex w-full flex-row items-center justify-start rounded-2xl p-6 text-left shadow">
      <i class="pi pi-bolt mb-2 mt-2 mr-5 text-white hidden-mobile" style="font-size: 3rem"></i>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">OnDemand Consult</h1>
        <h1 class="mb-1 text-sm font-semibold text-white" v-if="referral.interaction_type.name === 'phone'">
          You can call
          <span class="text-lg font-bold"
            ><a :href="`tel:${referral.referee.phone_number}`">{{ referral.referee.phone_number }}</a></span
          >
          any time before <span class="text-lg font-bold">{{ referral.on_demand_consult_date }}</span> at <span class="text-lg font-bold">{{ referral.consult_end }}</span
          >.
        </h1>
      </div>
    </div>

    <div v-if="referral.status == 'InReview' && isGP(loggedInUser)" class="align-start relative mt-4 flex w-full flex-row items-center justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <i class="pi pi-clock mb-2 mt-2 mr-5 text-white hidden-mobile" style="font-size: 3rem"></i>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Consult In Review</h1>
        <h1 class="text-sm text-gray-200" v-if="referral.interaction_type.name === 'phone'">
          Your consult is scheduled for <span class="text-red-300">{{ referral.consult_start }}</span> on <span class="text-red-300">{{ referral.consult_date }}</span
          >. You will be notified when {{ displayNameByRole(referral.referral_person) }} submits the consult report.
        </h1>
      </div>
    </div>

    <ReferralDetails v-if="referral.status !== 'Pending'" :referral="referral" :completeView="true" />

    <div
      :class="[
        {
          'md:flex-row md:items-start': referral.status !== 'Pending',
        },
        {
          'show-referral-container': referral.status == 'Upcoming' || referral.status == 'In Review' || referral.status == 'Complete' || referral.status == 'Cancelled',
        },
        'flex-col',
        'items-center',
        'flex',
        'w-full',
      ]"
    >
      <div
        :class="[
          {
            'mb-12 max-w-2xl': referral.status == 'Pending',

            'in-front': isTutorial,
          },
          'referral-form-tooltip',
          'shadow',
          'bg-white',
          'rounded-lg',
          'p-4',
          'md:p-6',
          'border-solid',
          'border-gray-300',
          'border-1',
          'max-2',
          'w-full',
          'my-2',
          'animated',
          'fade-in-up',
        ]"
      >
        <Referral :referral="referral" />
        <!-- Need the updated_at as the key to rerender component if it is updated -->
        <EditReferralForm ref="editReferralFormComponent" v-if="canEditReferralForm(loggedInUser, referral.referee, referral) && referral.status === 'Pending'" :referral="referral" @showSavedModal="showSavedModal" @submitReferralForm="submitReferralForm" />
        <ShowReferralForm v-else :referral="referral" :completeView="true" />
      </div>

      <div v-if="referral.status !== 'Pending'" :class="['animated fade-in-up animated-delay-1 relative my-2 w-full']">
        <div class="rounded-lg bg-white p-4 md:p-6 border-solid border-gray-300 border-1 shadow mb-4">
          <div class="flex flex-row items-start justify-between">
            <div class="flex flex-row">
              <div class="flex items-center">
                <img v-bind:src="referral.referral_person.profile.profile_picture_location" class="mx-auto mr-3 h-12 w-12 rounded-full bg-white object-cover" v-if="referral.referral_person.profile.profile_picture_location" />
                <div class="mr-2 h-12 w-12 rounded-full bg-white" v-else>
                  <i class="pi pi-user"></i>
                </div>
              </div>
              <div class="text-blue-400" v-if="referral.status !== 'Cancelled'">
                <h2 class="text-left font-semibold text-lg text-gray-600" v-if="referral.interaction_type.name === 'phone'">Consult Response</h2>
                <h2 class="text-left font-semibold text-lg text-gray-600 flex flex-row" v-else-if="referral.interaction_type.name === 'econsult'">
                  {{ referral.interaction_type.display_name }}<span class="flex"><i class="fa fa-plus text-pink-500 flex pt-0.5" style="font-size: 0.7rem; font-weight: 900"></i>&nbsp;</span> Response
                </h2>
                <div class="flex flex-row" style="align-items: center">
                  <h5 class="text-xs text-gray-500" v-if="!referral.referral_response_form.submitted">&nbsp;Awaiting Followup</h5>
                  <h5 class="text-xs text-gray-500" v-else>Submitted: {{ formatDateTime(referral.referral_response_form.updated_at) }} {{ getUserTimeZoneAbbr() }}</h5>
                </div>
              </div>
              <div class="hidden text-red-400 md:block" v-else>
                <h2 class="text-left font-semibold text-lg">Consult Response</h2>
                <div class="flex flex-row text-xs" style="align-items: center">
                  <i class="pi pi-calendar"></i>
                  <h5>&nbsp;Consult Cancelled</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="seperator-light"></div>
          <div class="response-container">
            <!-- Need the updated_at as the key to rerender component if it is updated -->
            <EditResponseForm v-if="canEditReferralResponseForm(loggedInUser, referral.referral_person, referral) && referral.status != 'Complete' && referral.status != 'Cancelled' && referral.status != 'Declined' && referral.status != 'Accepted'" :referral="referral" :key="referral.referral_response_form.updated_at" @submitReferralResponseForm="submitReferralResponseForm" @showSavedResponseModal="showSavedResponseModal" />
            <ShowResponseForm v-else :referral="referral" />
            <template v-if="referral.interaction_type && referral.interaction_type.name === 'econsult'">
              <ConsultComment :actingUser="loggedInUser" :referral="referral" />
            </template>
          </div>
        </div>
        <div class="response-container rounded-lg bg-white p-6 border-solid border-gray-300 border-1 shadow" id="responseFormRelatedFilesComponent" v-if="canEditReferralResponseForm(loggedInUser, referral.referral_person, referral) || canEditReferralForm(loggedInUser, referral.referee, referral)">
          <ResponseFormRelatedFiles :referral="referral" :key="referral.referral_response_form.updated_at" @submitReferralResponseForm="submitReferralResponseForm" @showSavedResponseModal="showSavedResponseModal" />
        </div>
      </div>
    </div>
  </div>
  <modal ref="endTutorialModal" @showSuccessModal="openModal()">
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="px-12 pt-10 mb-2 text-center text-3xl font-bold text-gray-600">
        <h1>Congratulations!</h1>
      </div>
      <div class="w-full max-w-3xl px-8 py-4">
        <div class="text-sm text-center text-gray-500">
          <p class="text-left">Congratulations! You’ve just submitted your consult request. The {{ getReferralPersonTerm(actingUser) }} will call you at the scheduled time, or if you scheduled an OnDemand consult they will call at their earliest opportunity. They will also complete the documentation.</p>
          <br />

          <div style="position: relative; padding-bottom: 56.33802816901409%; height: 0">
            <iframe :src="'https://www.loom.com/embed/6657b2c826134b1f8c14697941a1a07d?sid=1d1afc2c-7c6b-429d-b1a2-1767ba5a687c?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true'" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"></iframe>
          </div>

          <div class="flex items-center justify-center mt-4 w-full">
            <Accordion id="reimbursementExplanationAccordion" v-if="!isSpecialist(loggedInUser) && loggedInUser.provider_type === 'FFS'" style="max-width: 550px !important" class="mb-4">
              <AccordionTab header="Are you a Fee for Service Practitioner? You'll get reimbursed!">
                <div class="text-sm text-left">
                  How does reimbursement work?
                  <br />
                  <br />
                  Virtual Hallway is set up with provincial billing agencies to bill on behalf of fee-for-service practitioners who have completed the billing arrangement form during Onboarding.
                  <br />
                  <br />
                  Once a consult is complete, Virtual Hallway automatically bills on behalf of the practitioner parties. On the first business day of every month, Virtual Hallway e-transfers the billings to practitioners using the platform.
                  <br />
                  <br />
                  Reimbursement rates differ by province. If you have any questions, please reach out to us at support@virtualhallway.ca.
                  <br />
                </div>
                <div class="mt-4 flex w-full flex-row justify-center">
                  <Button class="get-started-button dark-mint-button-background" id="goToVerificationButton" @click="completeTutorialGoToVerification"> Verify My Account </Button>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
          Thanks for consulting with Virtual Hallway!
        </div>

        <div class="flex flex-col items-center justify-around mt-4 mb-6 md:flex-row">
          <div class="mb-4 md:mb-0">
            <Button class="get-started-button dark-mint-button-background p-button-outlined p-button-secondary" id="tutorialCompleteButton" @click="completeTutorial"> Review this Consult </Button>
          </div>
          <template v-if="loggedInUser.status === 'Onboarding'">
            <div>
              <Button class="get-started-button p-button-secondary" label="Verify Your Account" @click="goToAccountVerification" />
            </div>
          </template>
          <template v-else>
            <div>
              <Button class="get-started-button dark-mint-button-background" label="Book Another Consult" @click="goToMakeReferral()" />
            </div>
          </template>
        </div>
      </div>
    </template>
  </modal>

  <Toast position="bottom-right" group="referral_out_date">
    <template #message="slotProps">
      <span class="p-toast-message-icon pi pi-exclamation-triangle"></span>
      <div class="p-toast-message-text flex flex-col">
        <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
        <div class="p-toast-detail">{{ slotProps.message.detail }}</div>
        <div class="mb-2"></div>
        <Button label="Refresh" class="p-button-secondary p-button-outlined" @click="refresh" />
      </div>
    </template>
  </Toast>

  <modal v-if="isTutorial == false" ref="consultSubmittedModal" @showSuccessModal="openModal()">
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="w-full">
        <div class="h-52 overflow-hidden rounded-t-xl object-contain">
          <img src="@/assets/complete.jpg" />
        </div>
      </div>

      <div class="flex w-full justify-center">
        <div v-if="referral.call_asap == true" class="bg-coral relative z-20 -mt-10 h-20 w-20 rounded-full border-4 border-solid border-white">
          <i class="pi pi-bolt rounded-icon-inside text-white" style="font-size: 2rem" />
        </div>
        <div v-else class="relative z-20 -mt-10 h-20 w-20 rounded-full border-4 border-solid border-white bg-primary">
          <i class="pi pi-check rounded-icon-inside text-white" style="font-size: 2rem" />
        </div>
      </div>

      <div class="flex w-full justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <h1 v-if="referral.call_asap == true" class="mb-6 text-xl font-bold md:text-2xl">OnDemand Consult Submitted</h1>
          <h1 v-if="referral.call_asap == false" class="mb-6 text-xl font-bold md:text-2xl">Consult Successfully Submitted</h1>
          <p v-if="referral.call_asap == true && referral.interaction_type.name === 'phone'" class="mb-1 text-sm font-semibold text-gray-500">
            You will receive a call by <span class="text-blue-400">{{ referral.consult_start }}</span> on <span class="text-blue-400">{{ referral.consult_date }}</span> from <span class="text-blue-400"> {{ displayNameByRole(referral.referral_person) }}</span
            >.
            <br />
            <span class="mt-2">Since this is an OnDemand Consult you may receive the call prior to your appointment date.</span>
          </p>
          <p v-if="referral.call_asap == false && referral.interaction_type.name === 'phone'" class="mb-1 text-sm font-semibold text-gray-500">
            You will receive a call at <span class="text-blue-400">{{ referral.consult_start }}</span> on <span class="text-blue-400">{{ referral.consult_date }}</span> from <span class="text-blue-400">{{ displayNameByRole(referral.referral_person) }}</span>
          </p>
          <p class="mb-1 text-sm font-semibold text-gray-500" v-if="isSpecialist(loggedInUser) && loggedInUser.provider_type === 'FFS' && loggedInUser?.practice_province?.billing_opt_in">
            <span>Note you will receive the Referring Physician rate for the consult:</span>
            <span class="text-blue-400">
              <template v-if="hasProvinceOfPractice(loggedInUser, 'NS')">03.09L ($30.25)</template>
              <template v-else-if="hasProvinceOfPractice(loggedInUser, 'ON')">K730 ($31.35)</template>
              <template v-else-if="hasProvinceOfPractice(loggedInUser, 'AB')">03.01LI ($45.21), 03.01LH ($38.32), or 03.01LG ($32.90)</template>
            </span>
          </p>
          <div class="mb-4 flex flex-col items-center justify-between py-4 pb-4 md:flex-row">
            <div class="mb-4 md:mb-0">
              <Button class="p-button-outlined w-60" label="Review Consult" @click="closeConsultSubmittedModal()" id="consultSubmittedDoneBtn" />
            </div>
            <div v-if="isOnboarding(loggedInUser)">
              <Button class="p-button w-60" label="Verify My Account" @click="goToAccountVerification()" id="consultSubmittedVerifyAccountBtn" />
            </div>
            <div v-else>
              <Button class="p-button w-60" label="Book Another Consult" @click="goToMakeReferral()" id="consultSubmittedMakeReferralBtn" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
  <modal ref="consultSavedModal" style="padding-top: 100px">
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="flex w-full justify-center">
        <div class="relative z-20 -mt-10 flex h-20 w-20 justify-center rounded-full border-4 border-solid border-white bg-yellow-300">
          <i class="pi pi-exclamation-circle my-auto text-white" style="font-size: 3rem" />
        </div>
      </div>
      <div class="flex w-full justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <h1 class="mb-4 text-xl font-bold md:text-2xl">Consult Saved as Draft</h1>
          <h3>
            Your consult request is saved as a draft but not submitted. Make sure you hit "Submit" button by <span class="text-blue-400 font-bold">{{ formatDateTime(consultBufferCutOff) }}</span> or you will lose your booking. This so that the {{ getReferralPersonTerm(actingUser) }} receives the form in an appropriate amount of time prior to the call.
          </h3>
          <!-- <p class="mb-2 text-sm mt-3">Your Consult Request Form has been saved, and your appointment is being held. HOWEVER - to finalize your phone consult booking, you must hit “Submit” before the above date and time. </p> -->
          <div class="flex flex-col items-center justify-between py-4 pb-4 md:flex-row">
            <Button class="p-button-outlined p-button-secondary w-60" label="Dismiss" @click="$refs.consultSavedModal.closeModal()" id="consultSubmittedDoneBtn" />
            <Button class="p-button-primary w-60" label="Submit Now" @click="submitReferralFormAfterSave()" id="consultSubmittedDoneBtn" />
          </div>
        </div>
      </div>
    </template>
  </modal>
  <modal ref="confirmLeaveModal" style="padding-top: 100px">
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="flex w-full justify-center">
        <div class="relative z-20 -mt-10 flex h-20 w-20 justify-center rounded-full border-4 border-solid border-white bg-yellow-300">
          <i class="pi pi-exclamation-circle my-auto text-white" style="font-size: 3rem" />
        </div>
      </div>
      <div class="flex w-full justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <h1 class="mb-6 text-xl font-bold">Are you sure you want to leave the page?</h1>
          <p class="mb-4 px-4 text-sm">
            You booking is not finalized until the consult request is submitted. If you don't submit by <span class="font-semibold text-primary">{{ formatDateTime(consultBufferCutOff) }}</span> you will lose your booking.
          </p>
          <div class="flex flex-col items-center justify-between py-4 pb-4 md:flex-row">
            <div class="grid sm:grid-auto md:grid-cols-2 gap-4 mb-0">
              <Button class="p-button-outlined p-button-secondary w-60" label="Leave Page" @click="confirmLeaveOnClick" style="margin-right: 5px" id="confirmLeaveEditReferralPageBtn" />
              <Button class="md:ml-1 w-60" label="Submit Form" @click="submitReferralFormBeforePageLeaveClicked()" />
            </div>
          </div>
          <div class="flex flex-col items-center justify-center md:flex-row mt-2">
            <Button class="p-button-text p-button-secondary underline" label="Dismiss" @click="abortLeaveOnClick" />
          </div>
        </div>
      </div>
    </template>
  </modal>
  <modal ref="consultResponseSavedModal" style="padding-top: 100px">
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="flex w-full justify-center">
        <div class="relative z-20 -mt-10 flex h-20 w-20 justify-center rounded-full border-4 border-solid border-white bg-yellow-300">
          <i class="fa-solid fa-pencil my-auto text-white" style="font-size: 3rem" />
        </div>
      </div>
      <div class="flex w-full justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <h1 class="mb-4 text-xl font-bold md:text-2xl">Your consult is saved as a draft but not submitted. Make sure you come back and submit the consult response.</h1>
          <p class="mb-2 text-sm font-semibold text-gray-500">Your Consult Response Form has been saved but not submitted. Press the Submit button to complete the response form.</p>
          <div class="flex flex-col items-center justify-between py-4 pb-4 md:flex-row">
            <div class="mx-auto mb-0">
              <Button class="p-button-outlined w-60" label="Review Consult" @click="$refs.consultResponseSavedModal.closeModal()" id="consultResponseSavedModalBtn" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
  <modal ref="successfullyAcceptedFirstReferralModal" style="padding-top: 100px">
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="w-full">
        <div class="h-52 overflow-hidden rounded-t-xl object-contain">
          <img src="@/assets/complete.jpg" />
        </div>
      </div>
      <div class="flex w-full justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <h1 class="mb-4 text-xl font-bold md:text-2xl">You have Successfully Accepted your first Referral!</h1>
          <template v-if="this.getUserActionByName(this.loggedInUser, 'review_in_person_form')?.status === 'Active'">
            <p class="mb-2 text-sm font-semibold text-gray-500">This was just a demo but we are on our way to destroying the wait list! If your want to customize your Referral form Click the "Setup Form" button.</p>
            <div class="mb-2 flex flex-col items-center justify-between py-4 pb-4 md:flex-row">
              <div class="w-full pr-1">
                <Button @click="$refs.successfullyAcceptedFirstReferralModal.closeModal()" class="p-button-outlined p-button-secondary flex justify-center" style="width: 100% !important" label="Close" />
              </div>
              <div class="flex w-full justify-center pl-1 text-center">
                <Button @click="setupFormOnClick" label="Setup Form" class="flex justify-center" style="width: 100% !important" />
              </div>
            </div>
          </template>
          <template v-else>
            <br />
            <h1 class="mb-4 text-lg font-bold text-gray-500">You're all set to start accepting Referrals!</h1>
            <div class="flex flex-col items-center justify-between py-4 pb-4 md:flex-row">
              <div class="mx-auto mb-0">
                <Button class="p-button-outlined w-60" label="Close" @click="$refs.successfullyAcceptedFirstReferralModal.closeModal()" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </modal>
  <modal ref="successfullySubmittedFirstReferralModal" style="padding-top: 100px">
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="w-full">
        <div class="h-52 overflow-hidden rounded-t-xl object-contain">
          <img src="@/assets/complete.jpg" />
        </div>
      </div>
      <div class="flex w-full justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <h1 class="mb-4 text-xl font-bold md:text-2xl">You have Successfully Submitted your first Referral!</h1>
          <br />
          <h1 class="text-lg font-bold text-gray-500">The {{ getReferralPersonTerm(actingUser) }} will reach out regarding your referral. If you have any questions you can contact us at <a href="mailto:support@virtualhallway.ca" target="_self"> support@virtualhallway.ca </a></h1>
          <div class="flex flex-col items-center justify-between py-4 pb-4 md:flex-row">
            <div class="mx-auto mb-0">
              <Button class="p-button-outlined w-60" label="Close" @click="$refs.successfullySubmittedFirstReferralModal.closeModal()" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>

  <modal ref="calendarSubscriptionModal" v-if="tutorialFit()" class="dark-mint-header" id="autoFaxFeatureModal" style="padding-top: 100px">
    <template v-slot:header></template>
    <template v-slot:body>
      <div class="w-full max-w-xl flex justify-center">
        <div class="relative z-20 flex h-20 w-20 justify-center rounded-full border-4 border-solid border-white bg-[#2D2840] -mt-10">
          <i class="pi pi-calendar-plus my-auto text-white" style="font-size: 3rem" />
        </div>
      </div>
      <div class="flex w-full max-w-xl justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <div class="w-full pb-4">
            <h1 class="text-xl font-semibold px-4">
              <span class="font-bold text-slate-700 text-lg">Add your consults to your calendar?</span>
            </h1>
          </div>
          <div class="w-full mx-auto">
            <Button class="p-button w-1/2" @click="goToSubscribeToCalendar" label="Link Your Calendar" />
          </div>
          <div class="text-base font-base max-w-lg px-4 pt-4 text-slate-600 mx-auto">
            <div>We'll send your Virtual Hallway consult times to your preferred calendar! Want to learn more? <br /><span @click="playerReady = !playerReady" class="font-base text-primary cursor-pointer">Watch this 30 sec video.</span></div>
          </div>
          <div class="flex justify-center p-2" v-if="playerReady">
            <vimeo-player ref="player" video-id="785243800" @ready="onReady" />
          </div>
          <br />
          <div class="mb-4 flex w-full flex-row items-center justify-between px-12">
            <span class="text-blue text-md only-child-m-auto cursor-pointer font-semibold hover:text-red-300" @click="closeCalendarSubscriptionModal">Skip</span>
          </div>
        </div>
      </div>
    </template>
  </modal>
  <FeedbackDialog ref="feedbackDialog" feedBackTitle="Scribe Feedback" feedBackSubTitle="Scribe" entityType="Summary" :entityTypeId="selectedSummaryForFeedback?.id" />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Referral from '@/components/referral/Referral.vue';
import EditReferralForm from '@/components/referral/EditReferralForm.vue';
import EditResponseForm from '@/components/referral/EditResponseForm.vue';
import ResponseFormRelatedFiles from '@/components/referral_response_form_related_files/ResponseFormRelatedFiles.vue';
import ShowReferralForm from '@/components/referral/ShowReferralForm.vue';
import ShowResponseForm from '@/components/referral/ShowResponseForm.vue';
import ConsultComment from '@/components/referral/ConsultComment.vue';
import BookingProgressBar from '@/components/referral/BookingProgressBar.vue';
import ConsultFormTipsDialog from '@/components/referral/ConsultFormTipsDialog.vue';
import Loading from 'vue-loading-overlay';
import Button from 'primevue/button';
import moment from 'moment';
import Modal from '@/components/misc/Modal.vue';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ReferralDetails from '@/components/referral/ReferralDetails.vue';
import FeedbackDialog from '@/components/misc/FeedbackDialog.vue';
import { createPopup } from '@typeform/embed';
import '@typeform/embed/build/css/popup.css';

export default {
  components: {
    Referral,
    EditReferralForm,
    EditResponseForm,
    ShowReferralForm,
    ShowResponseForm,
    BookingProgressBar,
    Loading,
    Button,
    ConsultComment,
    Modal,
    Accordion,
    AccordionTab,
    ConsultFormTipsDialog,
    ReferralDetails,
    ResponseFormRelatedFiles,
    FeedbackDialog,
  },
  data() {
    return {
      isLoading: true,
      consultType: null,
      consultBufferCutOff: null,
      playerReady: false,
      loomUrl: 'https://www.loom.com/embed/f5ad0f0dbf104df081ba696ba0eaa544?sid=6c0140a2-4bae-43bd-9e18-203aca7b2a39',
      selectedSummaryForFeedback: null,
    };
  },
  computed: {
    ...mapGetters(['referral', 'loggedInUser', 'isGP', 'isSpecialist', 'canEditReferralForm', 'canEditReferralResponseForm', 'tutorialStatus', 'isOrgUnitHead', 'savedReferralId', 'hasProvinceOfPractice', 'schedules', 'groupedSchedules', 'isOnboarding', 'allReferrals', 'getUserActionByName', 'smartPathReferralSurveyFlag', 'actingUser']),
    isTutorial() {
      return this.tutorialStatus == 'referral-form';
    },
    filterRecurring() {
      return this.groupedSchedules.filter((schedules) => schedules[0].is_recurring);
    },
  },
  methods: {
    ...mapActions(['clickedBookAnotherConsult', 'getReferralById', 'setReferralToRebook', 'downloadPdfOfResponseForm', 'refereeHasSeenResponseForm', 'getReferralByOrgUnitId', 'getAllReferralsOfUser', 'updateResponseForm', 'updateReferralForm', 'getAttachedReferrals', 'getReferralAddendumsByReferralId', 'addNewUserActions', 'updateUserAction']),
    ...mapMutations(['setTutorialStatus', 'setSmartPathReferralSurveyFlag', 'setAttachedReferrals']),
    setupShowedReferral() {
      let promises = [this.getReferralById(this.$route.params.id), this.getAttachedReferrals({ referral_id: this.$route.params.id }), this.getReferralAddendumsByReferralId({ referral_id: this.$route.params.id })];

      this.isLoading = true;
      Promise.all(promises)
        .then(() => {
          this.consultType = this.referral?.interaction_type.name;
          if (this.$route.query.rebook == 'yes') {
            this.rebookReferral();
          }
          this.$store.commit('setSelectedReferral', this.referral);
          this.isLoading = false;
          this.showReferralResponseFormFeedbackSurvey();
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    rebookReferral() {
      this.setReferralToRebook(this.referral)
        .then(() => {
          this.$router.push('/makereferral');
        })
        .catch(() => {});
    },
    downloadResponsePdf() {
      this.downloadPdfOfResponseForm(this.referral.referral_response_form.id)
        .then()
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error Downloading File',
            detail: 'There was an error processing your request, please contact support@virtualhallway.ca or try again later.',
            life: 10000,
          });
        });
    },
    checkIfLessThanOneDayAfterReferralCall() {
      return moment().diff(moment(this.referral.start_datetime), 'days') === 0;
    },
    completeTutorial() {
      this.setTutorialStatus('');
      this.$confetti.stop();
      this.$refs.endTutorialModal.closeModal();
    },
    completeTutorialGoToVerification() {
      this.setTutorialStatus('');
      this.$confetti.stop();
      this.$refs.endTutorialModal.closeModal();
      this.$router.push({ path: `/verification` });
    },
    refresh() {
      window.location.reload();
    },
    submitReferralForm(promise, data) {
      this.isLoading = true;
      Promise.all(promise)
        .then(() => {
          this.updateReferralForm(data)
            .then(() => {
              this.isLoading = false;
              this.updateUserTutorialByName(this.loggedInUser, 'book_a_consult', true, 'Completed');
              if (this.tutorialStatus !== '') {
                this.setTutorialStatus('referral-form-complete');
                if (this.referral.interaction_type.name === 'phone') {
                  this.updateUserTutorialByName(this.loggedInUser, 'book_a_consult', true, 'Completed');
                }
              }
              if (this.smartPathAccess(this.loggedInUser) && this.smartPathReferralSurveyFlag !== null) {
                this.displaySmartPathPilotSurvey();
                this.setSmartPathReferralSurveyFlag(null);
              } else if (this.getUserActionByName(this.loggedInUser, 'calendar_subscription')?.status === 'Active') {
                this.$refs.calendarSubscriptionModal.openModal();
              }
              // else {
              // this.$emit('showSuccessModal');
              // }
              window.scrollTo(0, 0);
            })
            .catch((error) => {
              this.isLoading = false;
              if (Object.prototype.hasOwnProperty.call(error.data.errors, 'updated_at')) {
                this.$toast.add({
                  severity: 'warn',
                  summary: 'Please Refresh Page',
                  detail: 'It looks like you have an out-dated version of the referral form please refresh the page.',
                  group: 'referral_out_date',
                });
              } else if (Object.prototype.hasOwnProperty.call(error.data.errors, 'patient_booked_14_day')) {
                this.$toast.add({
                  severity: 'warn',
                  summary: 'Patient Booked Within 14 Days',
                  detail: error.data.errors.patient_booked_14_day[0],
                  life: 10000,
                });
              } else if (Object.prototype.hasOwnProperty.call(error.data.errors, 'patient_dob')) {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error Patient DOB',
                  detail: error.data.errors.patient_dob[0],
                  life: 10000,
                });
              } else {
                this.showGenericErrorToast();
              }
            });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status !== 500) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'It looks like there is an issue with uploading the file, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          } else {
            this.showGenericErrorToast();
          }
        });
    },
    displaySmartPathPilotSurvey() {
      const { toggle } = createPopup('dcTNFPlH', {
        hidden: {
          email: this.loggedInUser.email,
          user_name: this.loggedInUser.first_name + ' ' + this.loggedInUser.last_name,
        },
      });
      toggle();
    },
    showSavedModal() {
      if (this.referral.interaction_type.name === 'phone') {
        this.consultBufferCutOff = moment(this.referral.start_datetime).subtract(this.referral.referral_person.specialist_preference.referral_time_range_in_hours, 'hours');
        this.$refs.consultSavedModal.openModal();
      }
    },
    closeConsultSubmittedModal() {
      this.$refs.consultSubmittedModal.closeModal();
    },
    goToMakeReferral() {
      this.$refs.consultSubmittedModal.closeModal();
      this.clickedBookAnotherConsult(this.referral.id);
      this.$router.push({ path: `/makereferral` });
    },
    goToAccountVerification() {
      this.$refs.consultSubmittedModal.closeModal();
      this.$router.push({ path: `/verification` });
    },
    async confirmLeave() {
      this.consultBufferCutOff = moment(this.referral.start_datetime).subtract(this.referral.referral_person.specialist_preference.referral_time_range_in_hours, 'hours');
      this.$refs.confirmLeaveModal.openModal();
      return await new Promise((resolve) => {
        this.answer = resolve;
      });
    },
    confirmLeaveOnClick() {
      this.$refs.confirmLeaveModal.closeModal();
      this.answer(true);
    },
    abortLeaveOnClick() {
      this.$refs.confirmLeaveModal.closeModal();
      this.answer(false);
    },
    closeResponseSubmittedModal() {
      this.$refs.responseSubmittedModal.closeModal();
    },
    submitReferralFormBeforePageLeaveClicked() {
      this.$refs.confirmLeaveModal.closeModal();
      this.answer(false);
      this.$refs.editReferralFormComponent.submit();
    },
    submitReferralFormAfterSave() {
      this.$refs.consultSavedModal.closeModal();
      this.$refs.editReferralFormComponent.submit();
    },
    goToSetAvailability() {
      this.$refs.responseSubmittedModal.closeModal();
      this.$router.push({ path: `/schedules` });
    },
    submitReferralResponseForm(data) {
      this.isLoading = true;
      this.$toast.removeAllGroups();
      this.updateResponseForm({ ...data.responseForm, org_unit_id: data.org_unit_id })
        .then((response) => {
          this.isLoading = false;
          window.scrollTo(0, 0);
          if (data.isScribeReferralCall) {
            this.selectedSummaryForFeedback = data.selectedSummary;
            this.$refs.feedbackDialog.openDialog();
          } else if (this.getUserActionByName(this.loggedInUser, 'calendar_subscription')?.status === 'Active') {
            this.$refs.calendarSubscriptionModal.openModal();
          }
          if (response.new_user_actions.length > 0) {
            this.addNewUserActions(response.new_user_actions);
            this.showReferralResponseFormFeedbackSurvey();
          }
          this.$toast.add({
            severity: 'success',
            summary: 'Consult Updated',
            life: 5000,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 400) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Please Refresh Page',
              detail: 'It looks like you have an out-dated version of the referral form please refresh the page.',
              group: 'referral_out_date',
            });
          } else {
            this.showGenericErrorToast();
          }
        });
    },
    showSavedResponseModal() {
      this.$refs.consultResponseSavedModal.openModal();
    },
    goToSubscribeToCalendar() {
      this.updateUserTutorialByName(this.loggedInUser, 'calendar_subscription', false, 'Completed');
      this.$router.push({ path: `/myaccount`, query: { subscribeToCalendar: true } });
    },
    closeCalendarSubscriptionModal() {
      this.updateUserTutorialByName(this.loggedInUser, 'calendar_subscription', false, 'Skipped');
      this.$refs.calendarSubscriptionModal.closeModal();
    },
    onReady() {
      this.playerReady = true;
    },
    getAttachedReferralsSetup() {
      this.getAttachedReferrals({ referral_id: this.referral.id })
        .then(() => {})
        .catch(() => {});
    },
    showReferralResponseFormFeedbackSurvey() {
      let notification = this.loggedInUser.actions.find((action) => action.name == 'referral_response_form_submitted_survey' && action.status == 'Active' && JSON.parse(action.meta_data).referral_response_form_id == this.referral.referral_response_form.id);
      if (notification) {
        let typeFormUrl = JSON.parse(notification.meta_data).type_form_url;
        typeFormUrl = typeFormUrl.split('typeform.com/to/')[1];
        const { toggle } = createPopup(typeFormUrl, {
          hidden: {
            user_id: this.loggedInUser.id,
            referral_id: this.referral.id,
          },
        });
        toggle();
        notification.status = 'Read';
        this.updateUserAction(notification);
      }
    },
  },
  created() {
    if (this.tutorialStatus === 'specialistDemoConsult') {
      this.setTutorialStatus('');
    }
    this.isLoading = true;
    this.setupShowedReferral();
    this.loomUrl = 'https://www.loom.com/embed/6657b2c826134b1f8c14697941a1a07d?sid=1d1afc2c-7c6b-429d-b1a2-1767ba5a687c';
  },
  watch: {
    'referral.status'(val) {
      if (val == 'Upcoming') {
        if (this.tutorialStatus == 'referral-form' || this.tutorialStatus == 'referral-form-complete') {
          this.setTutorialStatus('referral-form-complete');
          this.$refs.endTutorialModal.openModal();
          this.$confetti.start();
        }
        this.getAttachedReferralsSetup();
      }
    },
    $route(to, from) {
      // This watcher is to reload the referral when the user clicks a referral
      // from the Patient History table. Since clicking a referral goes to theW
      // same route, this does not by default refresh the page, not updating the
      // showed referral.
      if (to !== from && to.name.includes('ReferralDetail')) {
        this.isLoading = true;
        this.setupShowedReferral();
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.loggedInUser && this.referral && from.name === 'ReferralDetail' && to.name !== 'Landing' && to.query.autoLogout != 'true' && this.canEditReferralForm(this.loggedInUser, this.referral.referee, this.referral) && this.referral.interaction_type.name === 'phone' && this.referral.status === 'Pending') {
      if (await this.confirmLeave()) {
        next();
      }
    } else {
      next();
    }
  },
  unmounted() {
    if (this.tutorialStatus === 'referral-form-complete') {
      this.$confetti.stop();
      this.setTutorialStatus('');
    }
  },
};
</script>

<style>
.referral-form-tooltip {
  position: relative;
}

.in-front {
  position: relative;
  z-index: 100;
}

.dark-mint-show-referral-background {
  background-color: var(--vh-new-feature-blue);
}

.modal-footer {
  border-radius: 6px;
}

.dark-mint-show-referral-button-background.p-button.p-component {
  background-color: #10699e;
  border-color: #10699e;
}

.dark-mint-show-referral-button-background.p-button.p-component:hover {
  background-color: #0a7abd;
  border-color: #0a7abd;
}

.dark-mint-show-referral-modal-background.modal__header {
  background-color: var(--vh-new-feature-blue) !important;
}

.dark-mint-show-referral-header .modal__header {
  background-color: var(--vh-new-feature-blue);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.in-person-response-form-tool-tip {
  position: absolute;
  top: 0px;
  left: -210px;
  border-radius: 6px;
  width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  z-index: 100;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.in-person-response-form-tool-tip::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.in-person-response-form-tool-tip-btn {
  background-color: #005d9b;
  @apply h-6 w-14 rounded;
}

.tf-v1-popup {
  padding-top: 80px;
}
</style>
