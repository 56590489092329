<template>
  <a v-if="this.linkLabel" @click="openTutorialVideoDialog" class="text-sm text-primary hover:text-red-400 cursor-pointer">{{ linkLabel }}</a>
  <Button v-if="this.buttonLabel" :label="buttonLabel" @click="openTutorialVideoDialog" icon="pi pi-external-link" iconPos="right" class="p-button-secondary p-button-sm p-button-outlined" />
  <Dialog :header="videoHeader" v-model:visible="show" :modal="true" :dismissableMask="true" :draggable="false" maximizable="true" :closable="true" class="p-0" id="tutorialVideoDialog" :breakpoints="{ '960px': '50vw', '640px': '90vw' }" style="width: 800px">
    <div style="position: relative; padding-bottom: 56.33802816901409%; height: 0">
      <iframe :src="this.loomUrl" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"></iframe>
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="closeTutorialVideoDialog()" />
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
export default {
  components: {
    Button,
    Dialog,
  },
  props: ['video', 'buttonLabel', 'linkLabel', 'videoHeader'],
  data() {
    return {
      isLoading: false,
      show: false,
      loomUrl: '',
      videoHeader: '',
      appendUrl: '?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
    };
  },
  methods: {
    openTutorialVideoDialog() {
      this.show = true;
    },
    closeTutorialVideoDialog() {
      this.show = false;
    },
  },
  mounted() {
    if (this.video == 'ical_subscription') {
      this.videoHeader = 'Subscribing to iCal';
      this.loomUrl = 'https://www.loom.com/embed/f5ad0f0dbf104df081ba696ba0eaa544?sid=6c0140a2-4bae-43bd-9e18-203aca7b2a39' + this.appendUrl;
    } else if (this.video == 'outlook_subscription') {
      this.videoHeader = 'Subscribing to Outlook';
      this.loomUrl = 'https://www.loom.com/embed/388a4f2cb7ce418d8d09191d0aabf435?sid=cce9c701-4630-424d-bec1-c3bc8ae130fb' + this.appendUrl;
    } else if (this.video == 'manual_subscription') {
      this.videoHeader = 'Subscribing Manually';
      this.loomUrl = 'https://www.loom.com/embed/4112343aafbf455d9a171aa3367e108d?sid=774b32df-02fd-4a9c-b001-0a9de40b9b8e' + this.appendUrl;
    } else if (this.video == 'practice_group_share_consults') {
      this.videoHeader = 'How to Share Consults';
      this.loomUrl = 'https://www.loom.com/embed/b48082aa32f044ca9e9470a176205d5d?sid=d00fb8dd-0d49-4584-a8cd-5ea5a86e9e90' + this.appendUrl;
    } else if (this.video == 'viewing_photos') {
      this.videoHeader = 'Using the viewer for Photos or Documents';
      this.loomUrl = 'https://www.loom.com/embed/29de00b4e70f4526aaf3ec0060b1f5df?sid=27c2929c-5d00-42eb-8d91-df31e56a6d4e' + this.appendUrl;
    } else if (this.video == 'using_autofax') {
      this.videoHeader = 'Using the Autofax Feature';
      this.loomUrl = 'https://www.loom.com/embed/24fafcbcf5e146d9b797ffefd0366a07?sid=96428e6a-97b8-4e13-83c5-80fa662227ca' + this.appendUrl;
    } else if (this.video == 'my_account_pcp') {
      this.videoHeader = 'My Account';
      this.loomUrl = 'https://www.loom.com/embed/2f558c939a394b50bf8b1751a30fb8b3?sid=c925fa9d-d6a6-4325-bf1a-638e8462bdf7' + this.appendUrl;
    } else if (this.video == 'my_account_specialist') {
      this.videoHeader = 'My Account';
      this.loomUrl = 'https://www.loom.com/embed/15a9f8f91a694752be32cb683dd8c33d?sid=b56f0336-0f5c-4ccf-b8e5-8bc073c08095' + this.appendUrl;
    } else if (this.video == 'setting_availability_specialist') {
      this.videoHeader = 'Setting Availability';
      this.loomUrl = 'https://www.loom.com/embed/f665ed0b00cc404ba2c7417829461d78?sid=574d5941-0de7-401d-bb2e-b9f7ee6431e1' + this.appendUrl;
    } else if (this.video == 'cme_lectures_credits') {
      this.videoHeader = 'CME Lectures and Credits';
      this.loomUrl = 'https://www.loom.com/embed/05b5b1b7b9e64b3dbf365f08d6c1a999?sid=54ce34aa-773b-43e2-9429-086516b58adb' + this.appendUrl;
    } else if (this.video == 'on_demand_pcp') {
      this.videoHeader = 'OnDemand Consults';
      this.loomUrl = 'https://www.loom.com/embed/3368be477e7a4b7e89bedf9219aee58a?sid=693c2a09-bd33-4486-bd0b-ba41f2e9ab4f' + this.appendUrl;
    } else if (this.video == 'on_demand_specialist') {
      this.videoHeader = 'OnDemand Consults';
      this.loomUrl = 'https://www.loom.com/embed/6179d706f3194c899731e1db90ea9a70?sid=23fb8e38-ddd8-4ec1-860b-a511d8887442' + this.appendUrl;
    }
  },
};
</script>
<style></style>
