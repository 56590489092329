<template>
  <div class="referral-container">
    <template v-if="!isDisabled && referral.interaction_type.name === 'phone'">
      <div v-if="referral.call_asap == true" class="bg-coral text-white font-bold rounded col-span-2 mt-3 p-2 text-sm">
        <p>Please note that {{ displayNameByRole(referral.referral_person) }}'s response time for OnDemand Consults is within {{ referral.referral_person.specialist_preference.call_asap_turnaround_time_in_days }} days.</p>
      </div>
      <div class="tip-title col-span-2 mt-3 p-2 text-sm">
        <p v-if="getTimeToSubmitInSeconds() > 3600">Please submit by: {{ formatDateTime(getDateTimeToSubmit()) }}</p>
        <p v-else-if="getTimeToSubmitInSeconds() < 3600 && getTimeToSubmitInSeconds() >= 1">Submit in: <countDownTimer :countDownInSeconds="getTimeToSubmitInSeconds()" @countdownEnded="disableSubmitButton" /> minute(s)</p>
      </div>
    </template>
    <template v-else-if="isDisabled && referral.interaction_type.name === 'phone'">
      <div class="tip-title col-span-2 mt-3 p-2 text-sm">
        <p>Referral Deadline Missed. Please book a different time.</p>
      </div>
    </template>

    <div class="w-full" v-if="tutorialStatus === ''">
      <div class="p-float-label mt-6 mb-6 w-full p-0" id="selected-patient-container">
        <Dropdown :class="['w-full text-left']" v-model="selectedPatient" :options="existingPatients" optionLabel="patient_full_name_and_health_card_number" valueLabel="patient_full_name_and_health_card_number" :filter="true" @change="handleExistingPatientChange" id="previousPatientSelect" class="auth-input w-full text-xs font-normal uppercase text-gray-400" :showClear="true" :loading="isLoadingPreviousPatient" />
        <label for="previousPatientSelect" class="ml-2 pt-0.5 text-xs font-normal text-gray-400">Select Existing Patient</label>
      </div>
    </div>
  </div>
  <div class="col-start-1 col-end-3 mb-2"></div>
  <form method="post" autocomplete="off" @submit.prevent="submit" class="grid grid-cols-2 gap-1 gap-x-4" ref="form">
    <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
      <label :class="['auth-input', { error: errors.patient_first_name }]" for="patient_first_name"
        >Patient First Name
        <span class="error">&nbsp;*</span>
      </label>
      <InputText type="text" v-model="referralForm.patient_first_name" class="auth-input" required maxLength="100" id="patient_first_name" />
      <div class="error" id="patient_first_name_error">{{ errors.patient_first_name }}</div>
    </div>
    <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
      <label :class="['auth-input', { error: errors.patient_last_name }]" for="patient_last_name">
        Patient Last Name
        <span class="error">&nbsp;*</span>
      </label>
      <InputText type="text" v-model="referralForm.patient_last_name" class="auth-input" required maxLength="100" id="patient_last_name" />
      <div class="error" id="patient_last_name_error">{{ errors.patient_last_name }}</div>
    </div>
    <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
      <label :class="['auth-input', { error: errors.patient_dob }]" for="patient_dob"
        >Patient Date of Birth
        <span class="error">&nbsp;*</span>
      </label>
      <VHCDatePicker :inputModel="referralForm.patient_dob" :inputId="'patient_dob'" :maxInputDate="new Date()" @dateSelected="(val) => (referralForm.patient_dob = val)" :displayAge="true" />
      <div class="error" id="patient_dob_error">{{ errors.patient_dob }}</div>
    </div>
    <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
      <label :class="['auth-input', { error: errors.health_card_province }]" for="health_card_province">
        Health Card/Policy Type
        <span class="error">&nbsp;*</span>
      </label>
      <Dropdown :class="['w-full text-left']" v-model="referralForm.health_card_province" :options="healthCardProvinceOptions" optionValue="name" optionLabel="name" id="health_card_province" class="auth-input w-full text-xs font-normal text-gray-400" />
      <div class="error" id="health_card_province_error">{{ errors.health_card_province }}</div>
    </div>
    <template v-if="!hasProvinceOfPractice(actingUser, 'ON')">
      <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
        <label :class="['auth-input', { error: errors.health_card_number }]" for="health_card_number">
          {{ healthCardProvinceOptionsRequireIdNumber().includes(referralForm.health_card_province) ? 'ID Number' : 'Health Card/ID Number' }}
          <span class="error">&nbsp;*</span>
        </label>
        <InputText type="text" v-model="referralForm.health_card_number" class="auth-input" required maxLength="255" id="health_card_number" @change="validateHealthCardNumber" />
        <div class="error" id="health_card_number_error">{{ errors.health_card_number }}</div>
      </div>
    </template>
    <template v-else>
      <div class="col-auto mb-2 md:flex md:justify-between">
        <div class="mr-2 w-full md:w-7/12">
          <label :class="['auth-input', { error: errors.health_card_number }]" for="health_card_number">
            {{ healthCardProvinceOptionsRequireIdNumber().includes(referralForm.health_card_province) ? 'ID Number' : 'Health Card/ID Number' }}
            <span class="error">&nbsp;*</span>
          </label>
          <InputText type="text" v-model="referralForm.health_card_number" class="auth-input" required maxLength="255" id="health_card_number" @change="validateHealthCardNumber" />
          <div class="error" id="health_card_number_error">{{ errors.health_card_number }}</div>
        </div>
        <div class="w-full md:w-5/12">
          <label :class="['auth-input', { error: errors.health_card_version_number }]" for="health_card_version_number"
            >Version Number
            <span class="error">&nbsp;*</span>
          </label>
          <InputText type="text" v-model="referralForm.health_card_version_number" class="auth-input" required maxLength="255" id="health_card_version_number" />
          <div class="error" id="health_card_version_number_error">{{ errors.health_card_version_number }}</div>
        </div>
      </div>
    </template>
    <template v-if="referralForm.health_card_province === 'Blue Cross'">
      <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
        <label :class="['auth-input', { error: errors.group_number }]" for="group_number">
          Policy/Group Number
          <span class="error">&nbsp;*</span>
        </label>
        <InputText type="text" v-model="referralForm.group_number" class="auth-input" required maxLength="255" id="group_number" />
        <div class="error" id="group_number_error">{{ errors.group_number }}</div>
      </div>
    </template>
    <div class="select-button-container col-start-1 col-end-3 mb-2 sm:col-auto">
      <label :class="['auth-input', { error: errors.patient_sex }]" for="patient_sex"
        >Patient Sex
        <span class="error">&nbsp;*</span>
      </label>
      <SelectButton v-model="referralForm.patient_sex" :options="['Male', 'Female']" id="patient_sex" class="auth-input w-full" style="height: 50px" />
      <div class="error" id="patient_sex_error">{{ errors.patient_sex }}</div>
    </div>
    <template v-if="referral.interaction_type.name === 'phone' || referral.interaction_type.name === 'econsult'">
      <div class="col-start-1 col-end-3 mb-2">
        <label :class="['auth-input', { error: errors.referral_question }]" for="referral_question">
          Consult Question
          <span class="error">&nbsp;*</span>
          <span v-if="this.referralForm.referral_question" class="ml-auto" v-tooltip.top="'The average consult question is under 300 characters.'">{{ this.referralForm.referral_question.length }}/300</span>
        </label>
        <div v-if="this.referralForm.referral_question && this.referralForm.referral_question.length > 300" class="p-message p-message-warn p-2 text-xs" style="margin-top: 0.25rem; margin-bottom: 0.5rem">The average consult question is under 300 characters, but you can exceed this amount if you feel it is necessary.</div>
        <Textarea v-model="referralForm.referral_question" class="auth-input" :autoResize="true" placeholder="Please describe the question you are looking to consult about" rows="5" cols="30" required maxLength="65535" id="referral_question" @keydown.tab.prevent="processTabs($event)"></Textarea>
        <div class="error" id="referral_question_error">{{ errors.referral_question }}</div>
      </div>
    </template>

    <div v-for="(fieldData, fieldName) in newReferralForm" :key="fieldName" class="col-start-1 col-end-3 mb-2">
      <label v-if="fieldData.type != 'date'" :class="['auth-input', { error: errors[fieldName] }]">
        {{ fieldName }}
        <template v-if="isRequired(fieldName)">
          <span class="error">&nbsp;*</span>
        </template>
        <span v-if="fieldData.type == 'text' && this.newReferralForm[fieldName]['value']" v-tooltip.top="'The average length of this field is under 500 characters.'" class="ml-auto">{{ this.newReferralForm[fieldName]['value'].length }}/500</span>
      </label>
      <InputText type="text" v-if="fieldData.type == 'string'" :disabled="fieldData.field_id == 'smart_path'" v-model="newReferralForm[fieldName]['value']" class="auth-input" maxLength="255" :placeholder="isRequired(fieldName) ? 'If none please write None or Not Available' : ''" />

      <Textarea v-if="fieldData.type == 'text'" v-model="newReferralForm[fieldName]['value']" class="auth-input" :autoResize="true" rows="5" cols="30" maxLength="65535" :id="fieldName" @keydown.tab.prevent="processTabs($event)" :placeholder="isRequired(fieldName) ? 'If none please write None or Not Available' : ''"></Textarea>

      <div v-if="fieldData.type == 'date'">
        <label :class="['auth-input relative', { error: errors[fieldName] }]"
          >{{ fieldName }}
          <span class="error" v-if="isRequired(fieldName)">&nbsp;*</span>
        </label>
        <VHCDatePicker :inputModel="newReferralForm[fieldName]['value']" :inputId="fieldName" @dateSelected="(val) => (newReferralForm[fieldName]['value'] = val)" />
        <div class="error" id="patient_dob_error">{{ errors.patient_dob }}</div>
      </div>

      <div v-if="fieldData.type == 'radio_button'" align="left">
        <span class="p-text-justify mr-4" v-for="(val, i) in JSON.parse(fieldData.meta_data)" :key="i">
          <RadioButton class="mr-2" v-model="newReferralForm[fieldName]['value']" :value="val" :id="fieldName + val" />
          <label :for="fieldName + val">{{ val }}</label>
        </span>
      </div>

      <template v-if="fieldData.type == 'search_select'">
        <Multiselect v-model="newReferralForm[fieldName]['value']" mode="tags" :searchable="true" :createTag="true" :options="JSON.parse(fieldData.meta_data).concat(fieldData.value)" />
      </template>

      <template v-if="fieldData.type == 'select'">
        <div class="mt-1 grid grid-cols-1 gap-x-1 text-sm md:grid-cols-2">
          <div class="p-field-checkbox" v-for="(val, i) in JSON.parse(fieldData.meta_data)" :key="i" align="left">
            <Checkbox :value="val" :inputId="fieldName + val" v-model="newReferralForm[fieldName]['value']" @change="selectFieldUpdated(fieldName, val)" />
            <label :for="fieldName + val" class="ml-2">{{ val }}</label>
          </div>
        </div>
      </template>

      <div class="pb-4" v-if="fieldData.type == 'file_uploads'">
        <FileUpload :maxFileSize="20000000" accept="image/png, image/jpeg, image/jpg, image/heic, application/pdf" :uploadLabel="'Upload File'" :showUploadButton="false" :multiple="true" @select="appendFileToForm($event, fieldName)">
          <template #empty>
            <p class="text-sm">Drag and drop files here to upload.</p>
          </template>
        </FileUpload>
        <div v-if="newReferralForm[fieldName]['value'].length > 0">
          <Message severity="warn" class="text-start">
            <span class="font-bold">{{ newReferralForm[fieldName]['value'].length }} file(s) already uploaded.</span>
            <br />
            <span> Uploading new files will overwrite the existing ones, please re-upload all files if necessary. </span>
          </Message>
          <div class="flex flex-row mt-2 w-full justify-center">
            <Button @click="downloadFormFieldFiles(fieldName)" label="View Uploaded Files" class="p-button-outlined mr-5" id="downloadFormFieldFilesButton" />
            <Button @click="deleteUploadedFiles(fieldName)" label="Delete Uploaded Files" class="p-button-danger p-button-outlined" id="deleteUploadedFilesButton" />
          </div>
        </div>
      </div>
      <div class="error" v-if="errors.hasOwnProperty(fieldName)" :id="fieldName + '_error'">
        {{ errors[fieldName] }}
      </div>
    </div>
    <template v-if="previousPatientReferrals.length > 0">
      <div class="col-start-1 col-end-3 mb-4">
        <div class="w-full">
          <label class="auth-input mb-1" for="altPhoneNumber"
            >Attach Patient Previous Consult
            <i class="ml-1 pi pi-question-circle" v-tooltip.top="'Attach previous consults that might be relevant for this consult'"></i>
          </label>
          <AttachPatientTable :referrals="previousPatientReferrals" :key="referralForm.health_card_number" @attachPreviousConsult="attachPreviousConsult" :selectionView="true" />
        </div>
      </div>
    </template>
    <div class="col-start-1 col-end-3 mb-4" v-if="referral.interaction_type.name === 'phone' && actingUser.cpsns !== 'luke_signup'">
      <label class="auth-input" for="altPhoneNumber"
        >Alternative Phone Number
        <i class="ml-1 pi pi-question-circle" v-tooltip.top="'Enter an alternative phone number if you cannot be reached at your cell phone number'"></i>
      </label>
      <InputMask type="tel" mask="(999)-999-9999" id="altPhoneNumber" name="altPhoneNumber" v-model="altPhoneNumberWitMask" :autoClear="false" :class="['auth-input', { 'p-invalid': altPhoneNumberError }]" @change="validateAltPhoneNumber" maxlength="16" />
      <div class="error text-center" id="alt_phone_number_error">{{ errors.alt_phone_number }}</div>
    </div>
    <div class="col-start-1 col-end-3 mb-4" v-if="hasProvinceOfPractice(actingUser, 'ON') && (!findBillingArrangementByProvince(actingUser, actingUser.practice_province.id) || !findBillingArrangementByProvince(actingUser, actingUser.practice_province.id).service_provider_number)">
      <label class="auth-input" for="ohip_number">OHIP Billing Number<span class="error">&nbsp;*</span>&nbsp;<i class="pi pi-question-circle" v-tooltip="'This is your 6 digit billing number. The full billing number from OHIP consists of 3 parts: \n 1. Group number: this is a 4 digit alpha numeric code. \n 2. Billing number: these 6 digits are the main component of your provider number. This is what is associated with OHIP claims, when you are identified as a referring physician for a consultation to another MD. \n 3. Specialty code: every medical specialty has a 2 digit code'"></i> </label>
      <InputText type="text" v-model="referralForm.ohip_number" class="auth-input" required maxLength="6" minLength="6" id="ohip_number" />
      <div class="error" id="ohip_number_error">{{ errors.ohip_number }}</div>
    </div>
    <template v-if="actingUser.cpsns === 'luke_signup'">
      <div class="col-start-1 col-end-3 mb-4">
        <label class="auth-input" for="cpsns">Practitioner License Number<span class="error">&nbsp;*</span></label>
        <InputText type="text" v-model="referralForm.cpsns" class="auth-input" required maxLength="255" id="cpsns" />
        <div class="error" id="cpsns_error">{{ errors.cpsns }}</div>
      </div>
      <div class="col-start-1 col-end-3 mb-4">
        <label class="auth-input" for="phone_number">Phone Number<span class="error">&nbsp;*</span></label>
        <InputMask type="tel" mask="(999)-999-9999" id="phone_number" name="phone_number" v-model="altPhoneNumberWitMask" :autoClear="false" :class="['auth-input', { 'p-invalid': altPhoneNumberError }]" @change="validateAltPhoneNumber" maxlength="16" v-tooltip.top="'Enter your cell phone number you would like the consultants to reach you at.'" />
        <div class="error" v-if="altPhoneNumberError" id="altPhoneNumberError_error">Phone Number requires 10 digits.</div>
        <div class="error" v-else id="phone_number_error">
          {{ errors.phone_number }}
        </div>
      </div>
    </template>
    <div v-if="(isGP(loggedInUser) || isSpecialist(loggedInUser)) && loggedInUser.org_units.length > 0" class="col-start-1 col-end-3">
      <div class="-mt-2 mb-4 w-full text-left" id="selected-patient-container">
        <label class="auth-input" for="selectOrgUnit">Select the Practice Group associated with this consult</label>
        <Dropdown :class="['w-full text-left']" v-model="selectedOrgUnitId" :options="shareOrgUnitOptions" optionValue="id" optionLabel="name" id="selectOrgUnit" class="auth-input w-full text-xs font-normal uppercase text-gray-400" placeholder="Private, Do Not Share" />
      </div>
      <div class="info-paragraph--gray" v-if="selectedOrgUnitId != 0">
        <p>By sharing this with your practice group, the group account will have full access to this consult.</p>
      </div>
    </div>
    <div class="error col-span-2 mb-2" v-if="errors.requiredFieldsMissing == true">Please fill-out all required fields.</div>
    <div class="md:flex sm:grid w-full items-center col-span-2" v-tooltip.top="{ value: 'Saving, Please Wait', disabled: !isLoadingSave && !isLoadingSubmit }">
      <Button label="Save Draft" :icon="isLoadingSave ? 'pi pi-spin pi-spinner' : 'pi pi-save'" class="p-button-secondary col-span-1 mb-2 w-full" @click="saveForm(false)" name="saveButton" v-if="canMakeReferrals(loggedInUser)" :disabled="isLoadingSave || isLoadingSubmit"></Button>
      <div v-if="!isDisabled || referral.interaction_type.name !== 'phone'" class="sm:ml-2 w-full">
        <Button label="Submit" :icon="isLoadingSubmit ? 'pi pi-spin pi-spinner' : 'pi pi-arrow-right'" iconPos="right" type="submit" name="submitButton" class="mb-2 flex justify-center w-full" v-if="canMakeReferrals(loggedInUser)" v-tooltip.top="{ value: 'Submit Consult. The Physician can add addendums and sign off.', disabled: isGP(loggedInUser) || isSpecialist(loggedInUser) }" :disabled="isLoadingSave || isLoadingSubmit"> </Button>
      </div>
      <template v-else>
        <Button disabled label="Submit" icon="pi pi-arrow-right" iconPos="right" type="submit" name="submitButton" class="w-full mb-2 flex justify-center" v-if="canMakeReferrals(loggedInUser)" v-tooltip.top="{ value: 'Submit Consult. The Physician can add addendums and sign off.', disabled: isGP(loggedInUser) || isSpecialist(loggedInUser) }">
          <p>Referral Deadline Missed. Please book a different time.</p>
        </Button>
      </template>
    </div>
  </form>
  <div v-if="tutorialStatus != ''">
    <span class="referral-form-tooltiptext py-4 px-6">
      <h1 class="text-left text-lg font-bold text-white">Request Form</h1>
      <br />
      <div class="text-left text-sm font-bold text-white">
        Enter your patient's details to your Request form. All data written to this form will remain private and secure on our servers.
        <template v-if="referral.interaction_type.name === 'phone'">
          <br />
          <br />
          <div class="text-xs">*Unsure of what kind of questions work well with phone consults? <a target="_blank" href="https://virtualhallway.ca/is-my-question-appropriate-for-a-phone-consult/">Click here.</a></div>
        </template>
        <br />
        When you are done, click Submit.
      </div>
      <br />
      <div class="flex flex-row justify-between">
        <div class="text-xs font-bold text-white">4/4</div>
        <button class="text-xs font-bold text-white" @click="endTutorial" id="skipTutorialBtn">Skip Tutorial</button>
      </div>
    </span>
  </div>
  <div class="relative flex w-full flex-row items-center justify-center">
    <!-- TODO: fix this on mobile, but it's a mega pain to do so -->
    <div class="absolute top-10 mb-12">
      <CancelReferral :referral="referral" />
      <!-- <Button icon="pi pi-ban" label="Cancel Consult" class="p-button-outlined p-button-danger" name="cancelBtn" @click="submitCancelReferral" /> -->
    </div>
  </div>
  <PatientBookWithinExclusionPeriodDialog :show="showPatientBookWithinExclusionPeriodDialog" :patientBookWithinExclusionPeriodRebookDate="patientBookWithinExclusionPeriodRebookDate" :referral="referral" />
  <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" style="max-width: 470px" :draggable="false" group="templating">
    <template #message="slotProps">
      <div class="flex flex-col justify-center items-center w-full py-3">
        <i :class="slotProps.message.icon" style="font-size: 5rem" class="text-yellow-400 mb-5"></i>
        <p class="pl-2">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { DatePicker } from 'v-calendar';
import Multiselect from '@vueform/multiselect';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import FileUpload from 'primevue/fileupload';
import Loading from 'vue-loading-overlay';
import RadioButton from 'primevue/radiobutton';
import PatientBookWithinExclusionPeriodDialog from '@/components/referral/PatientBookWithinExclusionPeriodDialog.vue';
import OnDemandLearnMoreDialog from '@/components/referral/OnDemandLearnMoreDialog.vue';
import Dropdown from 'primevue/dropdown';
import SelectButton from 'primevue/selectbutton';
import moment from 'moment';
import countDownTimer from '@/components/misc/CountDownTimer.vue';
import VHCDatePicker from '@/components/misc/VHCDatePicker.vue';
import Message from 'primevue/message';
import debounce from 'lodash.debounce';
import AttachPatientTable from '@/components/referral/AttachPatientTable.vue';
import CancelReferral from '@/components/referral/CancelReferral.vue';

export default {
  props: ['referral', 'countDownLimit'],
  emits: ['submitReferralForm', 'showSavedModal'],
  components: {
    DatePicker,
    VHCDatePicker,
    Multiselect,
    InputText,
    Textarea,
    Button,
    Checkbox,
    FileUpload,
    Loading,
    RadioButton,
    InputMask,
    PatientBookWithinExclusionPeriodDialog,
    OnDemandLearnMoreDialog,
    Dropdown,
    SelectButton,
    countDownTimer,
    Message,
    AttachPatientTable,
    CancelReferral,
  },
  data() {
    return {
      // call_asap: '',
      successMsg: '',
      errorMsg: '',
      //// The date parsing here was resolved from: https://stackoverflow.com/a/17545854/10052594
      //// Javascript date object generation is very much sensitive to timezone, since the DoB of
      //// patients weren't saved in backend with timezone information (i.e. just the date) this
      //// parsing is needed to properly show the correct date regardless of timezones.
      patientDoB: this.referral.referral_form.patient_dob != null ? new Date(Date.parse(this.referral.referral_form.patient_dob + 'T00:00:00')).toUTCString() : null,
      newReferralForm: {},
      referralForm: this.referral.referral_form,
      errors: {},
      approved: false,
      filesToUpload: {},
      masks: {
        input: 'MM/DD/YYYY',
      },
      altPhoneNumberWitMask: '',
      altPhoneNumberError: false,
      showPatientBookWithinExclusionPeriodDialog: false,
      patientBookWithinExclusionPeriodRebookDate: null,
      selectedPatient: null,
      editStartDateTime: null,
      isSubmitted: false,
      selectedOrgUnitId: null,
      shareOrgUnitOptions: [],
      isDisabled: false,
      healthCardProvinceOptions: [],
      patientPhoneNumberWithMask: '',
      refereePhoneNumberWithMask: '',
      refereeFaxNumberWithMask: '',
      autoSaveReferralForm: null,
      previousPatientReferrals: [],
      isLoadingSave: false,
      isLoadingSubmit: false,
      isLoadingPreviousPatient: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'existingPatients', 'getUserId', 'canMakeReferrals', 'tutorialStatus', 'isOrgUnitHead', 'actingUser', 'hasProvinceOfPractice', 'diagnosticCodes', 'isSpecialist', 'allProvinces', 'isRegisteredUser', 'whitelist', 'findBillingArrangementByProvince', 'allReferrals']),
    isTutorial() {
      return this.tutorialStatus != '';
    },
  },
  created() {
    this.setSavedReferralId(null);
    this.editStartDateTime = new Date();
    this.newReferralForm = this.referralForm.form;
    this.referralForm.patient_dob = this.patientDoB;
    this.altPhoneNumberWitMask = this.referralForm.alternative_phone_number;
    this.patientPhoneNumberWithMask = this.referralForm.patient_phone_number;
    this.refereePhoneNumberWithMask = this.referralForm.referee_phone_number;
    this.refereeFaxNumberWithMask = this.referralForm.referee_fax_number;
    this.call_asap = this.referral.call_asap;

    if (this.getTimeToSubmitInSeconds() < 1) {
      this.disableSubmitButton();
    }
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.selectedOrgUnitId = this.loggedInUser.org_units[0].id;
      //Need to add end point to get all recent referral form for org unit by id
    } else {
      if (this.loggedInUser.org_units.length > 0) {
        const referralOrgUnit = this.referral.org_units.find((org_unit) => this.loggedInUser.org_units.find((user_org_unit) => user_org_unit.pivot.user_id === org_unit.pivot.user_id));
        if (referralOrgUnit) {
          this.selectedOrgUnitId = referralOrgUnit.id;
        } else {
          this.selectedOrgUnitId = 0;
        }

        this.shareOrgUnitOptions = JSON.parse(JSON.stringify(this.loggedInUser.org_units));
        this.shareOrgUnitOptions.push({ id: 0, name: 'Private, Do Not Share' });
      }
    }
    this.getAllProvinces()
      .then(() => {
        this.healthCardProvinceOptions = this.allProvinces;
        this.healthCardProvinceOptions.push({ name: 'Canadian Armed Forces (CAF)' });
        this.healthCardProvinceOptions.push({ name: 'Royal Canadian Mounted Police (RCMP)' });
        this.healthCardProvinceOptions.push({ name: 'Immigration, Refugees and Citizenship Canada (IRCC)' });
        this.healthCardProvinceOptions.push({ name: 'Veterans Affairs Canada (VAC)' });
        if (this.hasProvinceOfPractice(this.actingUser, 'ON')) {
          this.healthCardProvinceOptions.push({ name: 'University Health Insurance Health Plan' });
        }
        this.healthCardProvinceOptions.push({ name: 'Blue Cross' });
        this.healthCardProvinceOptions.push({ name: 'Interim Federal Health Program' });
        this.healthCardProvinceOptions.push({ name: 'Other' });
        this.setupAutoSave();
      })
      .catch(() => {
        this.setupAutoSave();
      });

    this.isLoadingPreviousPatient = true;
    this.getAllUsersPatientsRecentReferralForm(this.actingUser.id)
      .then(() => {
        this.isLoadingPreviousPatient = false;
      })
      .catch(() => {
        this.isLoadingPreviousPatient = false;
      });

    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.getReferralByOrgUnitId(this.loggedInUser.org_units[0].id)
        .then(() => {
          this.setPreviousPatientReferrals();
        })
        .catch(() => {});
    } else {
      this.getAllReferralsOfUser({
        user_id: this.loggedInUser.id,
        required_relations: ['referral_form', 'interaction_type', 'specialty', 'org_units', 'referral_response_form', 'referral_person', 'referee'],
      })
        .then(() => {
          this.setPreviousPatientReferrals();
        })
        .catch(() => {});
    }
  },
  methods: {
    ...mapActions(['updateReferralForm', 'saveReferralForm', 'uploadFilesForReferralForm', 'downloadFilesOfReferralFormField', 'deleteUploadedFilesFromReferralForm', 'checkIfPatientBookedWithinExclusionPeriod', 'getAllUsersPatientsRecentReferralForm', 'cancelReferral', 'getAllProvinces', 'getWhitelistedFaxNumbers', 'getReferralByOrgUnitId', 'getAllReferralsOfUser']),
    ...mapMutations(['setTutorialStatus', 'setSavedReferralId']),
    appendFileToForm(event, fieldName) {
      this.filesToUpload[fieldName] = new FormData();
      this.filesToUpload[fieldName].append('field_name', fieldName);
      for (let i = 0; i < event.files.length; i++) {
        this.filesToUpload[fieldName].append('files[]', event.files[i]);
      }
      this.filesToUpload[fieldName].append('referral_form_id', this.referralForm.id);
    },
    downloadFormFieldFiles(fieldName) {
      this.errors[fieldName] = '';
      this.downloadFilesOfReferralFormField({
        referralFormId: this.referralForm.id,
        fieldName: fieldName,
      })
        .then(() => {})
        .catch(() => {
          this.errors[fieldName] = 'Sorry there was an error processing your request, please contact support@virtualhallway.ca.';
        });
    },
    deleteUploadedFiles(fieldName) {
      this.errors[fieldName] = '';
      this.deleteUploadedFilesFromReferralForm({
        referralFormId: this.referralForm.id,
        fieldName: fieldName,
      })
        .then(() => {
          this.filesToUpload = {};
        })
        .catch(() => {
          this.errors[fieldName] = 'Sorry there was an error processing your request, please contact support@virtualhallway.ca.';
        });
    },
    saveForm(autoSaved) {
      this.isSubmitted = false;
      if (isNaN(new Date(this.referralForm.patient_dob).getTime())) {
        this.referralForm.patient_dob = null;
      }
      if (!this.referralForm.patient_sex) {
        this.referralForm.patient_sex = 'Not known';
      }
      this.isLoadingSave = true;

      this.updateForm(false)
        .then(() => {
          this.setSavedReferralId(this.referral.id);
          // Reset this value for when user re-saves the form
          this.editStartDateTime = new Date();
          if (this.referral.interaction_type.name === 'phone' && !autoSaved) {
            this.$emit('showSavedModal');
          }
          this.isLoadingSave = false;
          if (!autoSaved) {
            this.$toast.add({
              severity: 'success',
              summary: 'Consult Successfully Saved',
              life: 5000,
            });
          }
        })
        .catch((error) => {
          this.isLoadingSave = false;
          if (error?.data?.errors && Object.prototype.hasOwnProperty.call(error?.data?.errors, 'updated_at')) {
            this.$toast.add({
              severity: 'warn',
              summary: 'Please Refresh Page',
              detail: 'It looks like you have an out-dated version of the referral form please copy any changes you made and refresh the page.',
              group: 'referral_out_date',
            });
          } else if (error?.data?.errors && Object.prototype.hasOwnProperty.call(error?.data?.errors, 'patient_dob')) {
            this.errors.patient_dob = error?.data?.errors?.patient_dob[0];
            this.$toast.add({
              severity: 'error',
              summary: 'Error Patient DOB',
              detail: error.data?.errors?.patient_dob[0],
              life: 10000,
            });
          } else {
            this.showGenericErrorToast();
          }
        });
    },
    submit() {
      this.validateForm(this.newReferralForm);
      if (Object.keys(this.errors).length === 0) {
        this.isSubmitted = true;
        this.isLoadingSubmit = true;
        this.updateForm(true)
          .then(() => {
            this.isLoadingSubmit = false;
          })
          .catch(() => {
            this.isLoadingSubmit = false;
          });
      } else {
        this.errors.requiredFieldsMissing = true;
      }
    },
    updateForm(submitted) {
      return new Promise((resolve, reject) => {
        this.referralForm.agreed_to_user_agreement = true;
        this.referralForm.form = this.newReferralForm;
        this.successMsg = '';
        this.errorMsg = '';
        this.$toast.removeAllGroups();
        this.referralForm.edit_start_datetime = this.editStartDateTime;
        this.referralForm.edit_end_datetime = new Date();
        if (!submitted) {
          this.saveReferralForm({ ...this.referralForm, is_submitted: false, org_unit_id: this.selectedOrgUnitId, type: this.referral.interaction_type.name })
            .then((response) => {
              if (Object.values(this.filesToUpload).length !== 0) {
                let promises = [];
                for (const formData of Object.values(this.filesToUpload)) {
                  promises.push(this.uploadFilesForReferralForm(formData));
                }
                Promise.all(promises)
                  .then((res) => {
                    // This cancels any current debounced calls to autosave, since
                    // we have multiple watchers for autosave sometimes they get
                    // exectued causing an endless loop of autosave.
                    this.autoSaveReferralForm.cancel();
                    resolve(res);
                  })
                  .catch((err) => {
                    this.autoSaveReferralForm.cancel();
                    reject(err);
                  });
              } else {
                this.autoSaveReferralForm.cancel();
                resolve(response);
              }
            })
            .catch((error) => {
              this.autoSaveReferralForm.cancel();
              reject(error);
            });
        } else {
          let promises = [];
          for (const formData of Object.values(this.filesToUpload)) {
            promises.push(this.uploadFilesForReferralForm(formData));
          }
          this.$emit('submitReferralForm', promises, { ...this.referralForm, is_submitted: this.isSubmitted, org_unit_id: this.selectedOrgUnitId, type: this.referral.interaction_type.name });
          this.isSubmitted = false;
          this.autoSaveReferralForm.cancel();
          resolve(true);
        }
      });
    },
    validateForm(form) {
      let valid = true;
      this.errors = {};
      this.validateAltPhoneNumber();
      if (this.altPhoneNumberError) {
        this.errors.alt_phone_number = 'Alternative Phone Number requires 10 digits. If you do not want an alternative phone number remove any numbers from the field.';
      }
      if (this.referralForm.patient_first_name == null || this.referralForm.patient_first_name == '') {
        this.errors.patient_first_name = 'Patient first name is required.';
      }
      if (this.referralForm.patient_last_name == null || this.referralForm.patient_last_name == '') {
        this.errors.patient_last_name = 'Patient last name is required.';
      }
      if (this.referralForm.patient_last_name == this.loggedInUser.last_name && this.referralForm.patient_first_name == this.loggedInUser.first_name) {
        this.errors.patient_last_name = 'Patient Name is flagged as incorrect.';
      }
      if (!this.referralForm.patient_sex) {
        this.errors.patient_sex_error = 'Patient sex is required.';
      }
      if (this.referralForm.patient_dob > new Date()) {
        this.errors.patient_dob = 'Patient date of birth cannot be in the future.';
      }
      // This check is for user Dr. Ellen Wood, we need to keep track of similar
      // feature requests so we can build a generic solution for this.
      else if (this.referral.referral_person.id == 1885) {
        let age = moment().diff(this.referralForm.patient_dob, 'years');
        if (age > 18) {
          this.errors.patient_dob = `${this.displayNameByRole(this.referral.referral_person)} only consults about patients aged 18 or below.`;
        }
      }
      if (this.referralForm.health_card_number == null || this.referralForm.health_card_number == '') {
        this.errors.health_card_number = 'Health Card/ID Number is required.';
      }
      if (!this.referralForm.health_card_province) {
        this.errors.health_card_province = 'Health Card/Policy Type is required.';
      }
      if (this.actingUser.practice_province.name === 'Ontario') {
        if (this.referralForm.health_card_version_number == null || this.referralForm.health_card_version_number == '') {
          this.errors.health_card_version_number = 'Version Number is required.';
        }
      }
      if (this.referralForm.patient_dob == null || this.referralForm.patient_dob == '' || isNaN(new Date(this.referralForm.patient_dob).getTime())) {
        this.errors.patient_dob = 'Patient date of birth is required.';
      }
      if (this.referralForm.patient_sex !== 'Male' && this.referralForm.patient_sex !== 'Female') {
        this.errors.patient_sex = 'Patient sex is required.';
      }
      if (this.referralForm.referral_question == null || this.referralForm.referral_question == '') {
        this.errors.referral_question = 'Consult question is required.';
      }
      //Check if the user is in ON and they are missing billing arrangement form or billing arrangement service provider number (OHIP) and the ohip number is not filled out
      if (this.hasProvinceOfPractice(this.actingUser, 'ON') && (!this.findBillingArrangementByProvince(this.actingUser, this.actingUser.practice_province.id) || !this.findBillingArrangementByProvince(this.actingUser, this.actingUser.practice_province.id).service_provider_number) && (this.referralForm.ohip_number == null || this.referralForm.ohip_number == '')) {
        this.errors.ohip_number = 'OHIP Number is required.';
      }

      if (this.actingUser.cpsns === 'luke_signup') {
        if (this.referralForm.cpsns == null || this.referralForm.cpsns == '') {
          this.errors.cpsns = 'Practitioner License Number is required.';
        }
        //Check if the alt phone number error is false and the form is missing alternative_phone_number
        if (!this.altPhoneNumberError && (this.referralForm.alternative_phone_number == null || this.referralForm.alternative_phone_number == '')) {
          this.errors.phone_number = 'Phone Number is required.';
        }
      }

      if (this.referralForm.health_card_province === 'Blue Cross' && (!this.referralForm.group_number || this.referralForm.group_number == '')) {
        this.errors.group_number = 'Group Number is required.';
      }

      for (const [fieldName, formField] of Object.entries(form)) {
        if (formField.is_required) {
          if (formField.type !== 'file_uploads') {
            if (formField.value == null || formField.value == '') {
              this.errors[fieldName] = `${fieldName} is required`;
              valid = false;
            } else {
              this.errors[fieldName] = '';
              delete this.errors[fieldName];
            }
          } else {
            if (this.filesToUpload[fieldName]) {
              this.errors[fieldName] = '';
              delete this.errors[fieldName];
            } else {
              this.errors[fieldName] = `${fieldName} is required`;
              valid = false;
              //ConfirmDialog for Dermatology in Nova Scotia to proceed without a required field
              if (formField.field_id == 67) {
                this.$confirm.require({
                  group: 'templating',
                  header: 'Submit without uploading a photo?',
                  message: 'Dermatology requests are usually accompanied by a photo, which help the dermatologist provide accurate advice.',
                  icon: 'pi pi-exclamation-circle',
                  acceptIcon: 'pi pi-check',
                  acceptLabel: 'Submit',
                  rejectLabel: 'Back',
                  acceptClass: 'p-button-secondary',
                  rejectClass: 'p-button-text p-button-secondary',
                  accept: () => {
                    delete this.errors[fieldName];
                    valid = true;
                    this.errors.requiredFieldsMissing = false;
                    if (Object.keys(this.errors).length === 1) {
                      this.isSubmitted = true;
                      this.isLoadingSubmit = true;
                      this.updateForm(true)
                        .then(() => {
                          this.isLoadingSubmit = false;
                        })
                        .catch(() => {
                          this.isLoadingSubmit = false;
                        });
                    } else {
                      this.errors.requiredFieldsMissing = true;
                    }
                  },
                  reject: () => {
                    this.errors[fieldName] = `${fieldName} is required`;
                    valid = false;
                  },
                });
              }
            }
          }
        }
      }
      // Bring the input with error into view.
      let firstError = Object.keys(this.errors)[0];
      let dom = document.getElementById(`${firstError}_error`);
      if (dom) {
        dom.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return valid;
    },
    validateAltPhoneNumber() {
      if (!this.altPhoneNumberWitMask) {
        this.altPhoneNumberError = false;
      } else {
        this.referralForm.alternative_phone_number = this.altPhoneNumberWitMask.replace(/[^0-9]/g, '');
        if (this.referralForm.alternative_phone_number.length === 0) {
          this.altPhoneNumberError = false;
          this.referralForm.alternative_phone_number = null;
        } else if (!/[0-9]/.test(this.referralForm.alternative_phone_number) || this.referralForm.alternative_phone_number.length !== 10) {
          this.altPhoneNumberError = true;
        } else {
          this.altPhoneNumberError = false;
        }
      }
    },
    processTabs(event) {
      let textarea = document.getElementById(event.target.id);
      let start = textarea.selectionStart;
      textarea.value = textarea.value.substring(0, textarea.selectionStart) + '\t' + textarea.value.substring(textarea.selectionStart, textarea.value.length);
      event.target.selectionStart = event.target.selectionEnd = start + 1;
    },
    isRequired(fieldName) {
      return this.referralForm.form[fieldName]?.is_required;
    },
    validateHealthCardNumber() {
      if (this.referralForm.health_card_number && this.referral.interaction_type.name === 'phone') {
        let data = {
          date: moment(this.referral.start_datetime).format('YYYY-MM-DD HH:mm:ss'),
          health_card_number: this.referralForm.health_card_number,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          referral_id: this.referral.id,
          specialty_id: this.referral.specialty.id,
        };
        this.checkIfPatientBookedWithinExclusionPeriod(data)
          .then((response) => {
            this.showPatientBookWithinExclusionPeriodDialog = response.data;
            this.patientBookWithinExclusionPeriodRebookDate = moment.utc(response.date_after_exclusion).toDate();
          })
          .catch(() => {});
      }
    },
    resetPatientDOB() {
      this.patientDoB = this.referralForm.patient_dob != null ? new Date(Date.parse(this.referralForm.patient_dob + 'T00:00:00')).toUTCString() : null;
      this.referralForm.patient_dob = this.patientDoB;
    },
    handleExistingPatientChange() {
      if (this.selectedPatient) {
        this.referralForm.patient_first_name = this.selectedPatient.patient_first_name;
        this.referralForm.patient_last_name = this.selectedPatient.patient_last_name;
        this.referralForm.patient_dob = this.selectedPatient.patient_dob;
        this.referralForm.health_card_number = this.selectedPatient.health_card_number;
        this.referralForm.health_card_province = this.selectedPatient.health_card_province;
        this.referralForm.group_number = this.selectedPatient.group_number;

        if (this.actingUser.practice_province.name === 'Ontario') {
          this.referralForm.health_card_version_number = this.selectedPatient.health_card_version_number;
        }
        this.referralForm.patient_sex = this.selectedPatient.patient_sex;
        for (const [key, field] of Object.entries(this.selectedPatient.form)) {
          if (this.newReferralForm[key] && this.newReferralForm[key].type == field.type) {
            this.newReferralForm[key].value = field.value;
          }
        }
        this.validateHealthCardNumber();
      }
    },
    endTutorial() {
      if (this.referral.interaction_type.name === 'phone') {
        this.updateUserTutorialByName(this.loggedInUser, 'book_a_consult', true, 'Completed');
      }
      this.setTutorialStatus('');
    },
    getTimeToSubmitInSeconds() {
      var buffer = this.referral.referral_person.specialist_preference.referral_time_range_in_hours * 60 * 60;
      var refStartTime = this.referral.start_datetime.getTime() / 1000;
      var curTime = moment().toDate().getTime() / 1000;
      var timeToSubmit = refStartTime - curTime - buffer;
      return timeToSubmit;
    },
    getDateTimeToSubmit() {
      return moment(this.referral.start_datetime).subtract(this.referral.referral_person.specialist_preference.referral_time_range_in_hours, 'hours');
    },
    disableSubmitButton() {
      this.isDisabled = true;
    },
    setupAutoSave() {
      // The debounce ensures the method is called only once
      // after the specified delay, e.g. if the watcher fires
      // this method 10 times within 1000ms, the method will
      // only be called one time after 1000ms instead of making
      // 10 calls within 1000ms.
      this.autoSaveReferralForm = debounce(function (val) {
        this.saveForm(true);
      }, 20000);
      // Defining the watcher here instead of in watch property
      // since otherwise the watcher gets fired due to object
      // manipulation while setting up the component.
      this.$watch('referralForm', {
        handler(val) {
          this.autoSaveReferralForm(val);
        },
        deep: true,
      });
      this.$watch('filesToUpload', {
        handler(val) {
          this.autoSaveReferralForm(val);
        },
        deep: true,
      });
      this.$watch('selectedOrgUnitId', (newVal) => {
        this.autoSaveReferralForm(newVal);
      });
    },
    selectFieldUpdated(fieldName, selectedValue) {
      // If user selects None of the above option, deselect all selected options.
      if (selectedValue == 'None of the above') {
        this.newReferralForm[fieldName]['value'] = [];
        this.newReferralForm[fieldName]['value'].push(selectedValue);
      } else {
        const index = this.newReferralForm[fieldName]['value'].indexOf('None of the above');
        if (index > -1) {
          this.newReferralForm[fieldName]['value'].splice(index, 1);
        }
      }
    },
    attachPreviousConsult(selectedReferrals) {
      this.referralForm.attach_previous_referral_ids = selectedReferrals.map((referral) => referral.id);
    },
    setPreviousPatientReferrals() {
      this.previousPatientReferrals = this.allReferrals
        .filter((referral) => referral?.status === 'Complete')
        .filter((referral) => referral?.referral_person?.id !== this.referral?.referral_person?.id)
        .filter((referral) => referral?.referral_form?.health_card_number === this.referralForm?.health_card_number);
    },
  },
  watch: {
    'referralForm.health_card_number'() {
      this.setPreviousPatientReferrals();
    },
  },
  beforeUnmount() {
    // This component is rerendered when the form gets saved/submitted
    // since the updated_at field is set as key for it. Thus, when we
    // unmount it, we need to ensure all debounced method(s) that are
    // supposed to be executed gets cancelled, otherwise the debounced
    // methods get executed even if component is destroyed.
    this.autoSaveReferralForm.cancel();
  },
};
</script>

<style>
/* .p-selectbutton .p-button.p-highlight {
    background: var(--vh-blue)!important;
    background-color: var(--vh-blue)!important;
    border-color: var(--vh-blue)!important;
  }

  .p-selectbutton .p-button.p-highlight:hover {
    background: var(--vh-light-blue)!important;
    background-color: var(--vh-light-blue)!important;
  } */

.multiselect {
  display: flex;
  width: 100%;
  color: var(--vh-gray);
  border-style: solid;
  border-width: 2px;
  border-color: var(--vh-auth-input-border);
  border-radius: 5px;
  height: 40px;
  line-height: 1.3;
  font-size: 14px;
  justify-content: flex-start;
  margin: 1px;
}
.multiselect.p-invalid {
  border-color: var(--vh-error-red);
}

.p-calendar,
.p-calendar .p-inputtext {
  @apply w-full;
}
.auth-input.p-datepicker table td {
  padding: 0.25rem;
}
.text-sm-override {
  font-size: 0.8rem !important;
}
@media (max-width: 767px) {
  .btn-extra-margin {
    margin-bottom: 1rem !important;
  }
}

.referral-form-tooltip .referral-form-tooltiptext {
  /* width: 100%; */
  width: 256px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  /* padding: 5px 0; */
  position: absolute;
  z-index: 1;
  top: 5%;
  left: -35%;
  margin-left: -25px;
  opacity: 1;
  background-color: var(--vh-new-feature-blue);
  transition: opacity 0.3s;
}

.referral-form-tooltip .referral-form-tooltiptext::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 102%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.in-front {
  position: relative;
  z-index: 100;
}

.back {
  z-index: 0;
}

#selected-patient-container {
  padding-left: 0;
  padding-right: 0;
}

.select-button-container .p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #8896a4;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.select-button-container .p-selectbutton .p-button .p-button-icon-left,
.select-button-container .p-selectbutton .p-button .p-button-icon-right {
  color: #6c757d;
}
.select-button-container .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.select-button-container .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #343a40;
}
.select-button-container .p-selectbutton .p-button.p-highlight {
  background: #353337;
  border-color: #353337;
  color: #ffffff;
}
.select-button-container .p-selectbutton .p-button.p-highlight .p-button-icon-left,
.select-button-container .p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.select-button-container .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.select-button-container .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.select-button-container .p-selectbutton.p-invalid > .p-button {
  border-color: #e24c4c;
}

.select-button-container .p-selectbutton .p-button {
  width: 50%;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
