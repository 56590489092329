<template>
  <div class="main-inner-wrap">
    <div class="bg-white rounded-3xl shadow-lg p-10 mt-16">
      <h2 class="font-medium text-blue-500 text-center text-3xl">Email Verification</h2>
      <div class="seperator"></div>
      <h5 class="text-gray-600 mt-2">
        You need to verify your email to access your account. <br />
        Please check your inbox to verify your email.
      </h5>
      <p class="p-5 mt-5 font-medium text-gray-600">Did not receive verification email? <br />Click the button below to resend verification email.</p>
      <button @click="resendEmail" class="btn btn--main">Resend Verification Email</button>
      <ul v-for="(error, i) in errors" :key="i" class="error">
        <li>{{ error }}</li>
      </ul>
      <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      errors: [],
      isLoading: false,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['resendEmailVerificationEmail']),
    resendEmail() {
      this.$toast.removeAllGroups();
      this.isLoading = true;
      this.resendEmailVerificationEmail({
        userId: this.loggedInUser.id,
      })
        .then((res) => {
          this.isLoading = false;
          this.errors = [];
          if (res.status == 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Verification Email Sent',
              detail: res.data.message,
              life: 5000,
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.errors = [];
          if (error.status == 422) {
            for (const key in error.data.errors) {
              error.data.errors[key].forEach((element) => {
                this.errors.push(element);
              });
            }
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          }
        });
    },
  },
};
</script>

<style></style>
