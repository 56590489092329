<template>
  <div :class="['align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary px-6 pt-8 shadow md:py-6 text-left nightmare-container', { 'pb-32 nightmare-container': selectedScreen == 'selectSpecialty' }, { 'pb-16': selectedScreen != 'selectSpecialty' }, { 'select-date-container': selectedScreen == 'selectDate' }]">
    <template v-if="selectedScreen == 'selectSpecialty'">
      <div class="hidden lg:block"><i class="pi pi-phone mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem" v-if="selectedInteractionType === 'phone'"></i></div>
      <div class="flex flex-col items-start">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Select a Specialty <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="0" /></h1>
        <template v-if="selectedInteractionType === 'phone'">
          <h1 class="text-sm text-gray-200 text-left">Choose a Specialty below to view availability</h1>
        </template>
      </div>
    </template>
    <template v-else-if="selectedScreen == 'selectSpecialist'">
      <div class="hidden lg:block"><i class="pi pi-users mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div v-if="referralToRebook" class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Select a {{ getReferralPersonTerm(actingUser, true) }} to Reschedule With</h1>
        <h1 class="text-xs md:text-sm text-gray-200">Your consult form will be copied over from the previous booking</h1>
      </div>
      <div v-else class="flex flex-col text-left">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Select a {{ getReferralPersonTerm(actingUser, true) }}</h1>
        <div v-if="selectedInteractionType == 'phone'" class="w-full">
          <div class="flex flex-row mb-1">
            <i class="text-sm mr-2 pi pi-phone text-white" />
            <h1 class="text-xs md:text-sm text-gray-200"><span class="font-bold">Schedule Phone Consult:</span> Book a specific time with a {{ getReferralPersonTerm(actingUser) }} for focused discussion and advice on your patient.</h1>
          </div>
          <div class="flex flex-row">
            <i class="text-sm mr-2 pi pi-bolt text-white" />
            <h1 class="text-xs md:text-sm text-gray-200">
              <span class="font-bold">OnDemand Phone Consult:</span> Seek immediate advice from available {{ getReferralPersonTerm(actingUser) }}s - by requesting a call back.
              <strong>
                <TutorialVideoDialog video="on_demand_pcp" linkLabel="Click here to watch how this works." />
              </strong>
            </h1>
          </div>
        </div>
      </div>
      <div class="z-100 absolute bottom-5 md:right-4 md:top-4 mx-auto" style="width: 250px" v-if="hasProvinceOfPractice(actingUser, ['NS', 'ON', 'PE'])">
        <IconField iconPosition="left">
          <InputIcon class="pi pi-search"> </InputIcon>
          <InputText v-model="filterValue" :placeholder="'Search ' + getReferralPersonTerm(actingUser, true) + 's'" class="w-full text-white placeholder-white" maxLength="255" @input="filterValueOnChange" style="background: transparent; border: 1px solid white; color: white" />
        </IconField>
      </div>
    </template>
    <template v-else-if="selectedScreen == 'selectDate'">
      <div class="hidden lg:block"><i class="pi pi-calendar mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div class="flex flex-col text-left">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Choose the Date and Time of your Appointment</h1>
        <h1 class="text-sm text-gray-200">After selecting the date, choose from the available hours that day, then confirm.</h1>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import TutorialVideoDialog from '@/components/misc/TutorialVideoDialog.vue';

export default {
  props: ['selectedScreen', 'actingUser', 'referralToRebook'],
  computed: {
    ...mapGetters(['isSpecialist', 'hasProvinceOfPractice', 'selectedInteractionType', 'loggedInUser']),
  },
  data() {
    return {
      filterValue: '',
    };
  },
  methods: {
    ...mapMutations(['setSpecialistFilterValue']),
    makeConsultTutorialOnClick() {
      if (this.selectedScreen === 'selectSpecialty') {
        this.setTutorialStatus('select-a-specialty');
      } else if (this.selectedScreen === 'selectSpecialist') {
        this.setTutorialStatus('select-a-specialist');
      } else if (this.selectedScreen === 'selectDate') {
        this.setTutorialStatus('select-a-time');
      }
    },
    filterValueOnChange() {
      this.setSpecialistFilterValue(this.filterValue);
    },
  },
};
</script>

<style></style>
