<template>
  <div class="flex flex-row w-full justify-start items-start">
    <div class="flex flex-col">
      <InputText type="tel" mask="(999)-999-9999" id="phoneNumber" placeholder="Enter Your Mobile Number" name="phoneNumber" v-model="phoneNumberWithMask" :autoClear="false" style="width: 225px" :class="[{ 'p-invalid': phoneNumberError }]" @change="validatePhoneNumber" maxlength="16" />
      <span class="error !text-left" v-if="phoneNumberError">
        {{ phoneNumberErrorMsg }}
      </span>
    </div>
    <div class="flex w-full -ml-2">
      <Button label="Get App Link" iconPos="right" @click="submit" name="submitButton" id="submitButton" style="border-top-left-radius: 0; border-bottom-left-radius: 0" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';

export default {
  components: {
    Button,
    InputText,
    InputMask,
  },
  data() {
    return {
      isLoading: false,
      user: {
        phone_number: '',
      },
      phoneNumberWithMask: '',
      phoneNumberError: false,
      firstPhoneNumberChange: false,
    };
  },
  methods: {
    ...mapActions(['textAppLink']),
    submit() {
      this.validatePhoneNumber();

      if (!this.phoneNumberError) {
        this.$toast.removeAllGroups();
        this.isLoading = true;
        this.textAppLink({
          ...this.user,
        })
          .then(() => {
            this.isLoading = false;
            this.user.phone_number = '';
            this.$toast.add({
              severity: 'success',
              summary: 'App Link Sent',
              detail: 'Check your mobile device for a link to dowload the app.',
              life: 7000,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
    validatePhoneNumber() {
      if (this.firstPhoneNumberChange) {
        this.phoneNumberError = false;
        this.phoneNumberErrorMsg = '';
        this.user.phone_number = this.phoneNumberWithMask.replace(/[^0-9]/g, '');
        if (!/[0-9]/.test(this.user.phone_number) || this.user.phone_number.length != 10) {
          this.phoneNumberError = true;
          this.phoneNumberErrorMsg = 'Must be 10 digits.';
        }
        if (this.user.phone_number === '') {
          this.phoneNumberError = false;
          this.phoneNumberErrorMsg = '';
        }
      } else {
        this.firstPhoneNumberChange = true;
      }
    },
  },
};
</script>

<style></style>
