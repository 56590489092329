<template>
  <div :class="{ 'pt-mobile-inset bg-white sticky top-0': isNativePlatform() }" v-if="isNativePlatform()">
    <div class="text-left font-bold px-5 py-2 text-2xl border-b">My CME Credits</div>
  </div>
  <div class="vld-parent" :class="{ 'main-inner-wrap': !isNativePlatform() }" v-if="isLoading">
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
  </div>
  <div class="px-4" :class="{ 'main-inner-wrap': !isNativePlatform() }" v-if="!isLoading">
    <div class="relative flex flex-row items-center justify-start w-full p-6 mt-4 mb-1 text-left shadow align-start rounded-2xl bg-secondary remove-later">
      <div class="hidden lg:block"><i class="mt-2 mb-2 mr-5 ml-2 text-white fas fa-award" style="font-size: 2rem"></i></div>
      <div class="flex flex-col">
        <h1 class="text-xl font-bold text-white md:text-2xl">My CME Credits</h1>
      </div>
    </div>
    <div v-if="lectureCredits.length > 1" class="flex flex-row justify-start w-full mt-4 font-semibold cursor-pointer align-start hover:text-gray-500"><h1 @click="$router.push('/lectures')">&lt; Back to Lectures</h1></div>

    <div v-if="lectureCredits.length < 1" class="flex flex-col bg-white rounded-lg shadow p-8 py-16 w-full mt-4">
      <p class="text-sm font-display mb-6">You do not have any CME credits.</p>
      <div><Button label="Back to CME Lectures" @click="$router.push('/lectures')" icon="pi pi-arrow-left" /></div>
    </div>

    <div class="w-full pt-4" v-else>
      <DataTable :value="lectureCredits" :paginator="true" class="billing-export-table p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" filterDisplay="menu" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10, 25, 50, 100]">
        <template #header>
          <div class="p-d-flex p-jc-between p-flex-column p-flex-sm-row">
            <h1 class="mt-2 mb-2 fc-toolbar-title md:mb-0">Credit Table</h1>
            <div class="flex flex-row items-center"></div>
          </div>
        </template>
        <template #empty> No CME credits found. </template>
        <template #loading> Loading CME credit data. Please wait. </template>
        <Column field="lecture_title" header="Lecture" :sortable="true"> </Column>
        <Column field="created_at" dataType="date" header="Date Complete" :sortable="true" style="max-width: 7rem">
          <template #body="{ data }">
            <span class="p-column-title">Date Complete</span>
            <span> {{ formatDate(data.created_at) }}</span>
          </template>
        </Column>
        <Column field="id" header="Download" style="max-width: 4.5rem">
          <template #body="{ data }">
            <Button label="Save" icon="pi pi-download" @click="downloadLectureCreditOnClick(data.id)" name="saveButton" class="p-button-icon-only p-button-sm"></Button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DataTable from 'primevue/datatable';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';
import Column from 'primevue/column';

export default {
  components: {
    DataTable,
    Column,
    Button,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      lecture_id: '',
      user_id: '',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'lectureCredits']),
  },
  methods: {
    ...mapActions(['getAllLectureCreditsOfUser', 'downloadLectureCredit']),
    downloadLectureCreditOnClick(lectureCreditId) {
      this.downloadLectureCredit({
        user_id: this.loggedInUser.id,
        lecture_credit_id: lectureCreditId,
      })
        .then(() => (this.isLoading = false))
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
  created() {
    this.isLoading = true;
    this.getAllLectureCreditsOfUser(this.loggedInUser.id)
      .then(() => (this.isLoading = false))
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });
  },
};
</script>

<style></style>
