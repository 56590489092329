import axios from 'axios';
import globals from '../../globals';

const state = {
  availableZones: [],
};

const getters = {
  availableZones: (state) => {
    return state.availableZones;
  },
};

const actions = {
  getZonesByProvince(context, province_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getZonesByProvinceUrl(province_id), {
          headers: context.getters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setAvailableZones', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setAvailableZones(state, data) {
    state.availableZones = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
