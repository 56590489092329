<template>
  <div class="relative flex items-start justify-center">
    <vueSignature ref="signature" :w="'275px'" :h="'100px'" class="signature ml-8"></vueSignature>
    <Button icon="pi pi-refresh" class="z-5 p-button-sm p-button-secondary" @click="clear" />
  </div>
</template>

<script>
import vueSignature from 'vue3-signature';
import Button from 'primevue/button';

export default {
  components: { vueSignature, Button },
  methods: {
    saveSignature() {
      var _this = this;
      return _this.$refs.signature.save();
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    isSignatureEmpty() {
      var _this = this;
      return _this.$refs.signature.isEmpty();
    },
    urltoFile(url, filename) {
      return fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], filename, { type: 'image/png' });
        });
    },
  },
};
</script>

<style>
.signature {
  border-radius: 5px;
  border: 2px solid #787878;
  cursor: crosshair;
}
</style>
