<template>
  <div>
    <div class="relative flex h-32 flex-col items-center justify-center rounded p-6 text-center shadow border-1" name="dateCard">
      <div :class="['text-3xl', 'font-medium', { 'text-gray-600': !dayOff }, { 'text-red-500': dayOff }]">
        <div v-if="dayOfWeek">{{ dayOfWeek }}s</div>
        <div v-else>
          {{ new Date(groupedSchedule[0].start_datetime).getDate() }}
        </div>
      </div>
      <div :class="['text-xl', { 'text-gray-600': !dayOff }, { 'text-red-400': dayOff }]" v-if="!dayOfWeek">
        {{
          new Date(groupedSchedule[0].start_datetime).toLocaleString('default', {
            month: 'long',
          })
        }}
      </div>
      <div v-if="isSmartPath == true">(SmartPath)</div>
    </div>
    <div v-if="!dayOff && formatDateTime(groupedSchedule[0].start_datetime) != formatDateTime(groupedSchedule[0].end_datetime)">
      <div v-for="schedule in sortTimes" :key="schedule.id">
        <div class="p-1 bg-gray-200 rounded text-xs font-medium mt-2.5">{{ formatTime(schedule.start_datetime) }} - {{ formatTime(schedule.end_datetime) }} {{ getUserTimeZoneAbbr() }}</div>
      </div>
    </div>
    <div class="mt-2.5 rounded bg-gray-200 p-1 text-xs font-medium" v-else>Day Off</div>
    <div class="date-card-icon absolute top-3 left-3" v-if="!dayOff">
      <i
        class="pi pi-pencil cursor-pointer text-blue-500 hover:text-blue-800"
        id="edit-individual-schedule"
        style="font-size: 0.875rem"
        @click="
          $emit('goToAddSchedule', {
            dates: groupedSchedule,
            selectedIsRecurring: false,
            dayOff: dayOff,
            isEditing: true,
          })
        "
      ></i>
    </div>
    <div class="date-card-icon absolute top-3 right-3">
      <i
        class="pi pi-trash cursor-pointer text-red-500 hover:text-red-800"
        id="delete-individual-schedule"
        style="font-size: 0.875rem"
        @click="
          $emit('goToDeleteSchedule', {
            dates: groupedSchedule,
            selectedIsRecurring: false,
            dayOff,
          })
        "
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  props: ['groupedSchedule', 'dayOfWeek', 'dayOff', 'isSmartPath'],
  emits: ['goToAddSchedule', 'goToDeleteSchedule'],
  methods: {
    compareStartTime(a, b) {
      return new Date(a.start_datetime).getTime() - new Date(b.start_datetime).getTime();
    },
  },
  computed: {
    sortTimes() {
      let sortedTimes = [];
      sortedTimes = [...this.groupedSchedule];
      if (sortedTimes.length > 1) {
        return sortedTimes.sort(this.compareStartTime);
      } else {
        return sortedTimes;
      }
    },
  },
};
</script>

<style></style>
