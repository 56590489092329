<template>
  <div class="main-inner-wrap" v-if="isLoading">
    <div class="grid h-96 grid-cols-1 place-content-center gap-4">
      <div>
        <p class="text-lg font-bold">Logging Out</p>
        <ProgressBar mode="indeterminate" style="height: 0.2em" />
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from 'primevue/progressbar';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isLoading: true,
      isFullPage: true,
    };
  },
  components: {
    ProgressBar,
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    goToLogin() {
      this.isLoading = false;
      this.$store.commit('purgeLoggedInUser');
      this.$router.replace('/login');
    },
  },
  created() {
    this.$toast.removeAllGroups();
    if (this.$route.query.unauthorized && !this.loggedInUser) {
      this.isLoading = false;
      this.$store.commit('purgeLoggedInUser');
      this.$router.replace('/login');
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'You are not logged in, please login to your account.',
        life: 10000,
      });
    }
    // Sometimes, auto logout fails and the user token expires for which the user
    // ends up making an unauthorized request. We want to treat these cases as
    // auto logout.
    else if (this.$route.query?.autoLogout == 'true' || (this.$route.query.unauthorized && this.loggedInUser)) {
      // let lastLoggedInUserEmail = null;
      // if (this.loggedInUser) {
      //   lastLoggedInUserEmail = this.loggedInUser.email;
      // }
      this.isLoading = false;
      this.$store.commit('purgeLoggedInUser');
      this.$router.replace({ name: 'Login', query: { autoLogout: 'true', redirect: this.$route.query.redirect } });
    } else {
      this.$store
        .dispatch('logout')
        .then(() => {
          this.isLoading = false;
          if (this.$route.query.redirect) {
            this.$router.replace(this.$route.query.redirect);
          } else {
            this.$router.replace('/login');
          }
        })
        .catch(() => {
          // Errors may occur while trying to logout, but there is need to let the user
          // know about those issues as they would still be logged out as the client
          // side tokens are removed regardless of logging out from the server.
          this.isLoading = false;
          this.$router.replace('/login');
        });
    }
  },
};
</script>

<style></style>
