<template>
  <div class="flex flex-row items-start justify-between">
    <div class="flex flex-row">
      <div class="flex items-center">
        <img v-bind:src="referral.referee.profile.profile_picture_location" class="mx-auto mr-2 h-12 w-12 rounded-full bg-white object-cover" v-if="loggedInUser.profile.profile_picture_location" />
        <div class="mr-2 h-12 w-12 rounded-full bg-white" v-else>
          <i class="pi pi-user"></i>
        </div>
      </div>
      <div class="text-blue-400">
        <h2 class="text-left font-semibold text-lg text-gray-600" v-if="referral.interaction_type.name === 'phone'"><span v-if="referral.call_asap == true">OnDemand</span> Consult Request</h2>
        <h2 class="text-left font-semibold text-lg text-gray-600 flex flex-row" v-else-if="referral.interaction_type.name === 'econsult'">
          {{ referral.interaction_type.display_name }}<span class="flex"><i class="fa fa-plus text-pink-500 flex pt-0.5" style="font-size: 0.7rem; font-weight: 900"></i>&nbsp;</span> Request
        </h2>
        <div class="flex flex-row" style="align-items: center">
          <h5 class="text-xs text-gray-500">Last Updated: {{ formatDateTime(referral.referral_form.updated_at) }} {{ getUserTimeZoneAbbr() }}</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="-mb-2 text-left font-bold text-gray-400" v-if="!referral.referral_form.is_signed_off && referral.status !== 'Pending'">Dictated But Not Signed Off</div>
  <div v-if="error != ''" class="error">
    {{ error }}
  </div>
  <div class="seperator-light"></div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  props: ['referral'],
  components: {
    // Button,
  },
  data() {
    return {
      feedbackText: '',
      feedbackError: '',
      currentTime: moment(),
      referralEndTime: moment(this.referral.end_datetime),
      error: '',
      showFaxConsultDialog: false,
      fax_document: '',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'canEditReferralForm']),
  },
};
</script>

<style>
.referral-container {
  color: #859dc4;
}

.p-tieredmenu {
  min-width: 325px !important;
}
</style>
