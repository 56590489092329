<template>
  <div v-if="!isLoading && user">
    <Message severity="info" :closable="false" class="text-left">Edit your information below, then click Confirm Changes. To change your Email or Province of Practice, please contact support.</Message>
    <form method="post" @submit.prevent="submit" class="mb-4" id="account-form">
      <div class="mb-4 grid grid-cols-2 gap-1 gap-x-5">
        <div class="col-span-2 mt-2 mb-4 flex flex-row items-center justify-center">
          <h1 class="mr-2 text-lg font-bold text-gray-500">Account Info <ToggleSupportSectionButton :openedSupportSectionAccountAccordionIndex="0" /></h1>
          <div class="h-1 flex-grow bg-gray-500"></div>
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="firstName">First Name </label>
          <InputText v-model="user.first_name" class="auth-input" id="firstName" required maxLength="55" />
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="lastName">Last Name </label>
          <InputText v-model="user.last_name" class="auth-input" id="lastName" required maxLength="55" />
        </div>
        <div v-if="!isCme(loggedInUser)" class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label for="phoneNumber" class="auth-input" v-if="!phoneNumberError">Cell Number <span class="error">*&nbsp;</span></label>
          <label for="phoneNumber" class="auth-input" v-else>Phone number must be a 10 digit number</label>
          <InputMask type="tel" mask="(999)-999-9999" id="phoneNumber" name="phoneNumber" v-model="phoneNumberWitMask" maxlength="16" required :autoClear="false" :class="['auth-input', { 'p-invalid': phoneNumberError }]" />
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto" v-if="isGP(loggedInUser) || isSpecialist(loggedInUser)">
          <label class="auth-input" for="cpsns">College Number </label>
          <InputText v-model="user.cpsns" class="auth-input" id="cpsns" maxLength="255" />
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="email">Email </label>
          <div class="auth-input flex border-2 p-3.5 rounded text-xs cursor-pointer" @click="showUpdateEmailPrompt = true" id="email">
            {{ user.email }}
          </div>
        </div>
        <div v-if="!isCme(loggedInUser)" class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input">Province of Practice</label>
          <InputText v-model="user.practice_province.name" class="auth-input cursor-not-allowed" disabled id="provincePractice" />
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" v-if="!defaultTimezoneError">Account Timezone</label>
          <label for="phoneNumber" class="auth-input" v-else>Account Timezone is required</label>
          <Dropdown class="auth-input" v-model="user.default_timezone" :options="timezoneOptions" id="timezone" optionLabel="name" optionValue="value" />
        </div>
        <div v-if="!isCme(loggedInUser)" class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label for="preferred_emr" class="auth-input">What EMR/EHR do you use? (Optional)</label>
          <Dropdown class="auth-input" v-model="user.preferred_emr" :options="emrOptions" id="preferred_emr" :filter="false" emptyMessage="Select your EMR/EHR" />
        </div>
        <div class="col-span-2 mt-4 flex justify-end">
          <Button type="submit" label="Confirm Changes" icon="pi pi-check" id="confirmChangesButton"></Button>
        </div>

        <div class="col-span-2 mt-4 mb-4 flex flex-row items-center justify-center">
          <h1 class="mr-2 text-lg font-bold text-gray-500" v-if="billingArrangement && (isGP(loggedInUser) || isSpecialist(loggedInUser))">Address</h1>
          <h1 class="mr-2 text-lg font-bold text-gray-500" v-else>Address Info</h1>
          <div class="h-1 flex-grow bg-gray-500"></div>
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="street">Street </label>
          <InputText v-model="user.address.street" class="auth-input" id="street" maxLength="255" />
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="city">City </label>
          <InputText v-model="user.address.city" class="auth-input" id="city" maxLength="255" />
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="postalCode">Postal Code </label>
          <InputText v-model="user.address.postal_code" :class="['auth-input', { 'p-invalid': postalCodeError }]" id="postalCode" @change="validatePostalCode" maxLength="6" />
          <span class="error" v-if="postalCodeError"> Please Enter Postal Code with Format A1A1A1 </span>
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="province">Province </label>
          <Dropdown class="auth-input" v-model="user.address.province" :options="allProvinces" id="province" />
        </div>
        <div class="col-span-2 mt-4 flex justify-end">
          <Button type="submit" label="Confirm Changes" icon="pi pi-check" id="confirmChangesButton"></Button>
        </div>

        <div class="col-span-2 mt-4 mb-4 flex flex-row items-center justify-center" v-if="isSpecialist(loggedInUser)">
          <h1 class="mr-2 text-lg font-bold text-gray-500">Public Profile Info</h1>
          <div class="h-1 flex-grow bg-gray-500"></div>
        </div>
        <div class="col-span-2 row-span-2 mb-2" v-if="isSpecialist(loggedInUser)">
          <label class="auth-input" for="bio">Bio </label>
          <TextArea :autoResize="true" placeholder="Enter your bio or professional description (Optional)" v-model="user.bio" class="auth-input" rows="5" cols="30" />
        </div>

        <div class="col-span-2 row-span-2" v-if="isSpecialist(loggedInUser)">
          <label class="auth-input" for="university">University </label>
          <InputText v-model="user.university" class="auth-input" id="university" maxLength="255" placeholder="Enter your University (Optional)" />
        </div>

        <div class="col-span-2 row-span-2" v-if="isSpecialist(loggedInUser)">
          <label class="auth-input" for="practice_location">Practice Location</label>
          <InputText v-model="user.practice_location" class="auth-input" id="practice_location" maxLength="255" placeholder="Enter the city or town of your practice (Optional)" />
        </div>

        <div class="col-span-2 mt-4 flex justify-end" v-if="isSpecialist(loggedInUser)">
          <Button type="submit" label="Confirm Changes" icon="pi pi-check" id="confirmChangesButton"></Button>
        </div>
      </div>
    </form>

    <div class="col-span-2 mt-4 flex flex-row items-center justify-center" id="profile_picture">
      <h1 class="mr-2 text-lg font-bold text-gray-500">Profile Picture/Avatar <ToggleSupportSectionButton :openedSupportSectionAccountAccordionIndex="1" /></h1>
      <div class="h-1 flex-grow bg-gray-500"></div>
    </div>
    <div class="pb-8">
      <label class="text-xs text-white">Profile Picture </label>
      <FileUpload name="files[]" :maxFileSize="20000000" :fileLimit="1" accept="image/png, image/jpeg" :customUpload="true" @select="handleFileChange" :showCancelButton="false" :showUploadButton="false" @remove="remove" :key="rerender" chooseLabel="Choose Profile Picture">
        <template #empty>
          <p class="text-sm">Drag and drop profile picture here to upload.</p>
        </template>
      </FileUpload>
      <div class="mt-4 flex justify-end">
        <Button label="Update Profile Picture" :disabled="file === null" @click="submitProfilePicture" />
      </div>
    </div>
    <EditAvatar v-if="!isCme(loggedInUser)" :loggedInUser="loggedInUser" />
  </div>
  <div v-if="errors.length != 0">
    <ul v-for="(error, i) in errors" :key="i" class="error">
      <li>{{ error }}</li>
    </ul>
  </div>
  <EditExpertiseAreas v-if="isSpecialist(loggedInUser) && !specialistIsOther" :loggedInUser="loggedInUser" ref="expertiseAreas" />
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
  <Dialog v-model:visible="showUpdateEmailPrompt" modal header="Update Email" :style="{ width: '50vw' }">
    <div class="px-2 pb-2">
      <Message :closable="false">To protect your account, we have a two step verification for updating email addresses. To verify that it is you, you will receive a confirmation email to your existing email once you click the confirm button below. Please follow the further instructions on the email to complete this request.</Message>
      <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
        <label class="auth-input" for="email">Existing Email</label>
        <InputText v-model="user.email" class="auth-input" id="existingEmailInput" disabled maxLength="55" />
      </div>
      <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
        <label class="auth-input" for="email">New Email <span class="text-primary ml-1">(required)</span> </label>
        <InputText v-model="newEmail" type="email" class="auth-input" id="newEmailInput" maxLength="55" />
      </div>
      <Message :closable="false" severity="success" v-if="emailUpdateRequestSuccess">{{ emailUpdateRequestSuccess }}</Message>
      <div class="items-end flex flex-row justify-between w-full mt-5">
        <Button label="Back" @click="showUpdateEmailPrompt = false" class="p-button p-button-outlined p-button-secondary" />
        <Button label="Confirm Email Change" @click="confirmEmailChange" />
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Button from 'primevue/button';
import Message from 'primevue/message';
import FileUpload from 'primevue/fileupload';
import InputMask from 'primevue/inputmask';
import Dropdown from 'primevue/dropdown';
import Loading from 'vue-loading-overlay';
import EditAvatar from '@/components/my_account/EditAvatar.vue';
import EditExpertiseAreas from '@/components/specialist/EditExpertiseAreas.vue';

export default {
  emits: ['selectInfo'],
  props: ['loggedInUser'],
  components: {
    InputText,
    TextArea,
    Button,
    FileUpload,
    InputMask,
    Loading,
    Message,
    Dropdown,
    EditAvatar,
    EditExpertiseAreas,
  },
  data() {
    return {
      user: this.loggedInUser,
      specialistIsOther: false,
      errors: [],
      phoneNumberError: false,
      defaultTimezoneError: false,
      phoneNumberWitMask: '',
      isLoading: false,
      file: null,
      rerender: false,
      allProvinces: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'United States'],
      postalCodeRegex: /([A-Za-z][0-9]){3}/,
      postalCodeError: false,
      emrOptions: ['Accuro', 'Epic', 'Oracle Cerner', 'Oscar', 'Medaccess', 'Cerner', 'MEDITECH', 'None', 'PS Suite EMR', 'Wolf'],
      timezoneOptions: [
        { name: 'Newfoundland Time', value: 'America/St_Johns' },
        { name: 'Atlantic Time', value: 'America/Halifax' },
        { name: 'Eastern Time', value: 'America/Toronto' },
        { name: 'Central Time, Winnipeg', value: 'America/Winnipeg' },
        { name: 'Central Time, Regina', value: 'America/Regina' },
        { name: 'Mountain Time', value: 'America/Edmonton' },
        { name: 'Pacific Time', value: 'America/Vancouver' },
      ],
      showUpdateEmailPrompt: false,
      emailUpdateRequestSuccess: null,
    };
  },
  computed: {
    ...mapGetters(['isSpecialist', 'billingArrangement', 'isGP', 'isCme']),
  },
  methods: {
    ...mapActions(['updateUserAccountInfo', 'uploadProfilePicture', 'updateSpecialistPreference', 'getBillingArrangement', 'requestEmailUpdate']),
    validate() {
      if (!this.isCme(this.user)) {
        this.validatePhoneNumber();
      }
      this.validatePostalCode();

      this.defaultTimezoneError = false;
      if (!this.user.default_timezone) {
        this.defaultTimezoneError = true;
      }
    },
    submit() {
      this.validate();

      if (!this.phoneNumberError && !this.defaultTimezoneError && !this.postalCodeError) {
        this.$toast.removeAllGroups();
        this.isLoading = true;
        if (this.billingArrangement) {
          this.user.etransfer_email = this.billingArrangement.etransfer_email;
        }
        this.updateUserAccountInfo(this.user)
          .then(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Account Updated',
              detail: 'Account information has been successfully updated.',
              life: 5000,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          });
      }
    },
    submitProfilePicture() {
      this.errors = [];
      this.$toast.removeAllGroups();

      if (this.file === null) {
        this.errors.push('Please select a profile picture.');
      } else {
        this.uploadProfilePicture({
          userId: this.loggedInUser.id,
          file: this.file,
        })
          .then(() => {
            this.file = null;
            this.rerender = !this.rerender;
            this.$toast.add({
              severity: 'success',
              summary: 'Account Updated',
              detail: 'Your profile picture has been successfully updated.',
              life: 5000,
            });
          })
          .catch((error) => {
            if (error.status == 422) {
              for (const key in error.data.errors) {
                error.data.errors[key].forEach((element) => {
                  this.errors.push(element);
                });
              }
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
                life: 10000,
              });
            }
          });
      }
    },
    validatePhoneNumber() {
      this.user.phone_number = this.phoneNumberWitMask.replace(/[^0-9]/g, '');
      if (!/[0-9]/.test(this.user.phone_number) || this.user.phone_number.length != 10) {
        this.phoneNumberError = true;
      } else {
        this.phoneNumberError = false;
      }
    },
    validatePostalCode() {
      if (this.user.address.postal_code === null) {
        this.postalCodeError = true;
      } else if (this.user.address.province == 'United States') {
        this.postalCodeError = false;
      } else {
        this.user.address.postal_code = this.user.address.postal_code.replace(/\s+/g, '');
        if (this.user.address.postal_code.length === 5 || (this.user.address.postal_code.length === 6 && this.postalCodeRegex.test(this.user.address.postal_code))) {
          this.postalCodeError = false;
        } else {
          this.postalCodeError = true;
        }
      }
    },
    handleFileChange(event) {
      this.file = event.files[0];
    },
    remove() {
      this.rerender = !this.rerender;
      this.file = null;
    },
    confirmEmailChange() {
      this.requestEmailUpdate({
        user_id: this.user.id,
        new_email: this.newEmail,
      })
        .then((res) => {
          this.emailUpdateRequestSuccess = res.data.message;
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
  },
  created() {
    // this.isLoading = true;
    this.phoneNumberWitMask = this.user.phone_number;
    if (this.loggedInUser.specialties.find((specialty) => specialty.name === 'Other')) {
      this.specialistIsOther = true;
    }
    if (!this.isCme(this.user)) {
      this.getBillingArrangement({ userId: this.user.id, practiceProvinceId: this.user.practice_province.id })
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.showGenericErrorToast();
          this.isLoading = false;
        });
    }
  },

  mounted() {
    if (this.$route.query && this.$route.query.profile_picture) {
      const profilePictureEl = document.querySelector('#profile_picture');
      if (profilePictureEl) {
        setTimeout(() => {
          profilePictureEl.scrollIntoView({
            behavior: 'smooth',
          });
        }, 300);
      }
    }

    if (this.$route.query && this.$route.query.expertise_areas) {
      const expertiseAreasEl = document.querySelector('#expertise_areas');
      if (expertiseAreasEl) {
        setTimeout(() => {
          expertiseAreasEl.scrollIntoView({
            behavior: 'smooth',
          });
        }, 300);
      }
    }
  },
};
</script>

<style>
.auth-input.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-left: -1px;
}
.auth-input.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -1px;
}

.p-message-wrapper span.p-message-icon {
  margin-right: 1rem;
}
</style>
