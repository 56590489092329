<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card animated fade-in-up rounded-2xl shadow">
        <DataTable editMode="cell" :value="transactions" :paginator="true" class="p-datatable-gridlines editable-cells-table" :rows="10" dataKey="id" :rowHover="true" filterDisplay="menu" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10, 25, 50, 100]">
          <template #header>
            <div class="p-d-flex p-jc-between p-flex-column p-flex-sm-row">
              <h1 class="fc-toolbar-title mt-2 mb-2 md:mb-0">My Pathways Results</h1>
            </div>
          </template>
          <template #empty> No pathways found. </template>
          <template #loading> Loading Pathway data. Please wait. </template>

          <Column field="referee.full_name" header="Referee" style="min-width: 1rem" :sortable="true">
            <template #body="{ data }">
              <span class="p-column-title">Referee</span>
              {{ data.referee.full_name }}
            </template>
          </Column>

          <Column field="referee.full_name" header="Patient First Name" style="min-width: 1rem" :sortable="true">
            <template #body="{ data }">
              <span class="p-column-title">Patient First Name</span>
              {{ data.patient_first_name }}
            </template>
          </Column>

          <Column field="referee.full_name" header="Patient Last Name" style="min-width: 1rem" :sortable="true">
            <template #body="{ data }">
              <span class="p-column-title">Result Last Name</span>
              {{ data.patient_last_name }}
            </template>
          </Column>

          <Column field="referee.full_name" header="Pathway Version" style="min-width: 1rem">
            <template #body="{ data }">
              <span class="p-column-title">Pathway Version</span>
              {{ data.smart_path_version }}
            </template>
          </Column>

          <Column field="referee.full_name" header="Referral" style="min-width: 1rem">
            <template #body="{ data }">
              <span class="p-column-title">Referral</span>
              <Button v-if="data.status == 'Complete (In Person)'" icon="pi pi-eye" class="p-button-rounded p-button-info p-mr-2" @click="viewInPerson(data.id)" />
            </template>
          </Column>

          <Column field="referee.full_name" header="View" style="min-width: 1rem">
            <template #body="{ data }">
              <span class="p-column-title">View</span>
              <Button icon="pi pi-eye" class="p-button-rounded p-button-info p-mr-2" @click="viewInPerson(data.id)" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  props: ['transactions'],
  components: {
    DataTable,
    Column,
    Button,
  },
  methods: {
    viewInPerson(transactionId) {
      this.$router.push({ path: '/reviewresult/' + transactionId });
    },
  },
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser', 'selectedSmartPath']),
  },
};
</script>

<style scoped lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.status-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-InReview {
    background: #946da6;
    color: #6b039c;
  }

  &.status-Complete {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Upcoming {
    background: #b3e5fc;
    color: #23547b;
  }

  &.status-Pending {
    background: #f8da87;
    color: #81620c;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--surface-d);

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: 0.5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #c63737;
}
</style>
