<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card animated fade-in-up rounded-2xl shadow">
        <DataTable editMode="cell" :value="referrals" :paginator="true" class="p-datatable-gridlines editable-cells-table" :rows="25" dataKey="id" :rowHover="true" v-model:filters="filters1" filterDisplay="menu" :filters="filters1" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10, 25, 50, 100]" v-model:selection="selectedReferrals">
          <template #header>
            <div class="p-d-flex p-jc-between p-flex-column p-flex-sm-row mb-4">
              <h1 class="fc-toolbar-title mt-2 mb-2 md:mb-0" v-if="isSharing">Share Consults with Practice Group Account <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="6" /></h1>
              <h1 class="fc-toolbar-title mt-2 mb-2 md:mb-0" v-else>Remove Access to Consults</h1>
              <IconField iconPosition="left">
                <InputIcon class="pi pi-search"> </InputIcon>
                <InputText v-model="filters1['global'].value" placeholder="Search consults" class="w-full" maxLength="255" />
              </IconField>
            </div>
            <div class="p-d-flex mb-2 text-left" id="selected-patient-container">
              <Dropdown v-model="selectedOrgUnitToShareConsultsWith" :options="actingUser.org_units" optionLabel="name" id="selectOrgUnit" class="auth-input p-d-column text-xs font-normal text-gray-400" placeholder="Select Practice Group" :showClear="true" />
              <Button @click="toggleConfirmAttachReferral" class="p-button-sm" id="shareBtn" v-if="isSharing">Share</Button>
            </div>
            <div class="p-d-flex error mb-2 text-left" id="selected-patient-container">
              {{ selectOrgUnitError }}
            </div>
          </template>
          <template #empty> No Consults found. </template>
          <template #loading> Loading Consult data. Please wait. </template>
          <Column selectionMode="multiple" style="width: 2rem"></Column>

          <Column field="referral_form.patient_full_name" header="Patient" :sortable="true"> </Column>

          <Column field="specialty.name" header="Specialty" v-if="isGP(loggedInUser)"> </Column>

          <Column field="#" header="Consult With" style="min-width: 1rem" :sortable="true">
            <template #body="{ data }">
              <span class="p-column-title">Full Name</span>
              <template v-if="data.referee_id === loggedInUser.id">
                {{ data.referral_person_full_name }}
              </template>
              <template v-else-if="data.referral_person_id === loggedInUser.id">
                {{ data.referee_full_name }}
              </template>
            </template>
          </Column>

          <Column field="start_datetime" dataType="date" header="Consult Date" style="max-width: 8rem" :sortable="true">
            <template #filter="{ filterModel }">
              <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
            </template>
            <template #body="{ data }">
              <span class="p-column-title">Date/Time</span>
              {{ formatDateTime(data.start_datetime) }} {{ getUserTimeZoneAbbr() }}
            </template>
          </Column>

          <Column field="status" header="Status" :showFilterMatchModes="false" bodyClass="text-center" :filterMenuStyle="{ width: '14rem' }" style="max-width: 8rem">
            <template #body="{ data }">
              <span class="p-column-title">Status</span>
              <div :class="'p-text-center top-6 right-6 rounded px-2 py-1 text-xs font-bold uppercase text-white ' + data.className">{{ data.status }}</div>
            </template>
            <template #filter="{ filterModel }">
              <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span :class="'status-badge text-white ' + slotProps.value.replace(/\s/g, '')" v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
                <template #option="slotProps">
                  <span :class="'status-badge text-white ' + slotProps.option.replace(/\s/g, '')">{{ slotProps.option }}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <template v-if="hasProvinceOfPractice(loggedInUser, ['AB', 'SK'])">
            <Column field="interaction_type.name" header="Type" :showFilterMatchModes="false" bodyClass="text-center" :filterMenuStyle="{ width: '14rem' }">
              <template #body="{ data }">
                <span class="p-column-title">Type</span>
                <div :class="'p-text-center top-6 right-6 rounded px-2 py-1 text-xs font-bold uppercase text-white ' + data.interaction_type.name">{{ data.interaction_type.name }}</div>
              </template>
              <template #filter="{ filterModel }">
                <Dropdown v-model="filterModel.value" :options="types" placeholder="Any" class="p-column-filter" :showClear="true">
                  <template #value="slotProps">
                    <span :class="'status-badge text-white ' + slotProps.value" v-if="slotProps.value">{{ slotProps.value }}</span>
                    <span v-else>{{ slotProps.placeholder }}</span>
                  </template>
                  <template #option="slotProps">
                    <span :class="'status-badge text-white ' + slotProps.option">{{ slotProps.option }}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>
          </template>

          <Column field="org_unit" style="min-width: 1rem" header="Shared">
            <template #body="{ data }">
              <div class="text-blue-500" v-if="data.org_units.find((org_unit) => this.loggedInUser.org_units.find((user_org_unit) => user_org_unit.pivot.user_id === org_unit.pivot.user_id))">
                {{ data.org_units.find((org_unit) => this.loggedInUser.org_units.find((user_org_unit) => user_org_unit.pivot.user_id === org_unit.pivot.user_id)).name }}
              </div>
              <div v-else>Not Shared</div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>

  <Dialog header="Confirm Share Consults" v-model:visible="confirmAttachReferralModal" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '40vw', maxWidth: '650px' }" :modal="true" :draggable="false">
    <div class="text-sm">
      <p class="mb-2">
        Are you sure you want to share these consults with <b>{{ selectedOrgUnitToShareConsultsWith.name }}</b
        >?
      </p>
      <p>This will remove access for these consults with any existing departments.</p>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" @click="toggleConfirmAttachReferral" class="p-button-text" />
      <Button label="Yes" icon="pi pi-check" @click="attachReferrals" autofocus />
    </template>
  </Dialog>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import { FilterOperator } from 'primevue/api';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import { mapGetters, mapActions } from 'vuex';
import Dialog from 'primevue/dialog';

export default {
  data() {
    return {
      filters1: null,
      sharedConsultsFilter: null,
      statuses: ['Complete', 'Pending', 'Upcoming', 'Cancelled', 'In Review'],
      types: ['phone', 'econsult'],
      referralMenuItems: [],
      openShareReferralWithOrgUnit: false,
      selectedReferral: null,
      selectedReferrals: [],
      confirmAttachReferralModal: false,
      selectedOrgUnitToShareConsultsWith: null,
      selectOrgUnitError: null,
    };
  },
  created() {
    this.initFilters1();
    if (this.$route.query.org_unit_id) {
      this.selectedOrgUnitToShareConsultsWith = this.actingUser.org_units.find((orgUnit) => orgUnit.id == this.$route.query.org_unit_id);
    }
  },
  props: ['referrals', 'isSharing'],
  components: {
    DataTable,
    Dropdown,
    Column,
    Button,
    InputText,
    Calendar,
    Dialog,
  },
  methods: {
    ...mapActions(['attachOrgUnitToMultipleReferrals', 'detachOrgUnitToMultipleReferrals']),
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        start_datetime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        'interaction_type.name': { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    attachReferrals() {
      this.isLoading = true;
      const data = { org_unit_id: this.selectedOrgUnitToShareConsultsWith.id, referral_ids: this.selectedReferrals.map((referral) => referral.id), user_id: this.loggedInUser.id };
      this.$toast.removeAllGroups();
      this.attachOrgUnitToMultipleReferrals(data)
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Selected Consults Have Been Shared With Practice Group',
            life: 3000,
          });
          this.$router.push({ path: `/referralhistory` });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    detachReferrals() {
      // const data = { org_unit_id: this.selectedOrgUnitId, referral_id: this.referral.id, user_id: this.loggedInUser.id };
      // this.isLoading = true;
      // this.detachOrgUnitToMultipleReferrals(data)
      //   .then(() => {
      //     this.isLoading = false;
      //     this.$toast.add({
      //       severity: 'success',
      //       summary: 'Revoked Access From Practice Group',
      //       life: 3000,
      //     });
      //   })
      //   .catch(() => {
      //     this.isLoading = false;
      //     this.showGenericErrorToast();
      //   });
    },
    toggleConfirmAttachReferral() {
      this.selectOrgUnitError = '';
      if (!this.selectedOrgUnitToShareConsultsWith) {
        this.selectOrgUnitError = 'Please select a department.';
      } else if (this.selectedReferrals.length < 1) {
        this.selectOrgUnitError = 'Please select at least one consult by clicking on the checkbox.';
      } else {
        this.confirmAttachReferralModal = !this.confirmAttachReferralModal;
      }
    },
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'hasProvinceOfPractice', 'actingUser', 'referral']),
  },
};
</script>

<style lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.status-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-InReview {
    background: #946da6;
    color: #6b039c;
  }

  &.status-Complete {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Upcoming {
    background: #b3e5fc;
    color: #23547b;
  }

  &.status-Pending {
    background: #f8da87;
    color: #81620c;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--surface-d);

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: 0.5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #c63737;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #eef2ff !important;
  color: #312e81 !important;
}
</style>
