<template>
  <div :class="animation" class="bg-white rounded-xl shadow p-8 w-full max-w-lg">
    <h1 class="text-left text-blue text-xl font-bold">Welcome to Virtual Hallway</h1>
    <div class="seperator-small mt-4 mb-4"></div>
    <h4 class="text-left text-gray-600 text-sm mb-6"></h4>
    <div id="consult-button" @click="goToNext" class="select-button border-hover--blue">
      <span>I'd like to request or provide consults</span>
      <i class="pi pi-caret-right" />
    </div>
    <div id="cme-button" @click="goToLectures" class="select-button border-hover--blue">
      <span>I'm here for CME credits and lectures </span>
      <i class="pi pi-caret-right" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from 'primevue/button';

export default {
  components: {
    Button,
  },
  props: ['loggedInUser'],
  emits: ['goToPrevious'],

  data() {
    return {
      animation: 'animate-in',
      user: this.loggedInUser,
    };
  },
  computed: {
    ...mapGetters(['isGP', 'isSpecialist', 'allProvinces']),
  },
  created() {
    this.user.role = '';
  },
  methods: {
    goToNext() {
      this.$emit('goToNext', this.user);
    },
    goToLectures() {
      this.user.role = 'cme';
      this.$store.commit('updateLoggedInUser', this.user);
      this.$emit('goToNext', this.user);
    },
  },
};
</script>

<style></style>
