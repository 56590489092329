<template>
  <Dialog header="Book with a Specialist" :breakpoints="getDialogBreakPoints" :style="getDialogStyle" v-model:visible="visible" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" class="pb-10" @hide="closeSpecialistModal" id="specModal">
    <div class="flex flex-col md:flex-row">
      <!-- Profile Section -->
      <!-- <div :class="['flex flex-col items-center justify-center bg-secondary p-6 text-center text-white w-full md:w-72', selectedSpecialist.bio ? 'col-span-2' : 'col-span-6']"> -->
      <div class="flex flex-row md:flex-col items-center justify-center bg-secondary p-6 text-center text-white w-full md:w-72 mx-auto">
        <img v-bind:src="selectedSpecialist.profile.profile_picture_location" class="relative z-20 block md:hidden mb-4 rounded-full border-4 border-solid border-white object-cover h-16 w-16 md:h-24 md:w-24" v-if="selectedSpecialist.profile.profile_picture_location" />
        <div class="w-full text-left md:text-center ml-6 md:ml-0">
          <h2 class="text-md font-bold">{{ displayNameByRole(selectedSpecialist) }}</h2>
          <h2 class="mb-0 md:mb-4 text-md">{{ selectedSpecialist.specialties[0].name }}</h2>
          <img v-bind:src="selectedSpecialist.profile.profile_picture_location" class="relative z-20 hidden md:block mb-4 rounded-full border-4 border-solid border-white object-cover h-16 w-16 md:h-24 md:w-24 mx-auto" v-if="selectedSpecialist.profile.profile_picture_location" />
          <p>{{ selectedSpecialist.practice_province.name }}</p>
          <p>{{ selectedSpecialist.university }}</p>
        </div>
      </div>
      <!--End Profile Section -->

      <div class="flex-col w-full">
        <!-- Booking Section -->
        <div class="flex flex-row border-t-2">
          <div class="flex flex-col w-full">
            <div class="flex flex-col p-4 md:p-6 md:pb-0">
              <h1 class="mb-4 text-sm font-medium text-gray-600">Select your preferred consultation type:</h1>

              <div class="text-sm">
                <div v-if="availableServices.includes('phoneConsult')" class="border-s border-2 border-full border-gray-200 hover:border-gray-500 hover:cursor-pointer rounded p-3 mb-4 flex flex-col md:flex-row justify-between items-start md:items-center" @click="phoneConsult" id="phoneConsultButton">
                  <flex class="flex flex-row items-center">
                    <i class="pi pi-phone text-md ml-2 mr-4" style="font-size: 1.3rem"></i>
                    <div class="flex flex-col">
                      <h1 class="text-md font-semibold">Phone Consult</h1>
                      <div class="flex flex-row text-xs">
                        Next Availability:
                        <span class="ml-1 text-blue">{{ formatDateTimeToSpecifiedFormat(this.availableServices.filter((e) => e.nextPhoneAvailability)[0].nextPhoneAvailability.start_datetime, 'MMM DD h:mm A', true) }}</span>
                      </div>
                      <p class="text-xs">Book a consultation for a specific time.</p>
                    </div>
                  </flex>
                  <div class="hidden md:block"><Button class="p-button-sm" @click="phoneConsult" label="Select" /></div>
                </div>

                <div v-if="availableServices.includes('onDemandConsult')" class="border-s border-2 border-full border-gray-200 hover:border-gray-500 hover:cursor-pointer rounded p-3 mb-4 flex flex-col md:flex-row justify-between items-start md:items-center" @click="onDemand" id="onDemandButton">
                  <flex class="flex flex-row items-center">
                    <i class="pi pi-bolt text-md ml-2 mr-4" style="font-size: 1.3rem"></i>
                    <div class="flex flex-col">
                      <h1 class="text-md font-semibold">OnDemand Consult</h1>
                      <div class="flex flex-row text-xs">
                        Response Time:
                        <span class="ml-1 text-blue">Within {{ selectedSpecialist.specialist_preference.call_asap_turnaround_time_in_days }} days</span>
                      </div>
                      <p class="text-xs">The {{ getReferralPersonTerm(actingUser) }} will call as soon as possible.</p>
                    </div>
                  </flex>
                  <div class="hidden md:block"><Button class="p-button-sm" @click="onDemand" label="Select" /></div>
                </div>

                <!-- <div v-if="availableServices.includes('onDemandConsult')" class="w-full flex justify-end">
                  <TutorialVideoDialog video="on_demand_pcp" linkLabel="Click here to watch how OnDemand Consults work." />
                </div> -->

                <div v-if="availableServices.includes('eConsult')" class="border-s border-2 border-full border-gray-200 hover:border-gray-500 hover:cursor-pointer rounded p-3 mb-4 flex flex-col md:flex-row justify-between items-start md:items-center" @click="eConsult" id="eConsultButton">
                  <flex class="flex flex-row items-center">
                    <i class="pi pi-desktop text-md ml-2 mr-4" style="font-size: 1.3rem"></i>
                    <div class="flex flex-col">
                      <h1 class="text-md font-semibold">eConsult</h1>
                      <p>Asynchronous consult via text</p>
                    </div>
                  </flex>
                  <div class="hidden md:block"><Button class="p-button-sm" @click="eConsult" label="Select" /></div>
                </div>

                <div v-if="specialistSmartPaths.length > 0" class="smartpath-tooltip">
                  <span v-if="tutorialFit()" class="smartpath-tooltiptext py-4 px-6">
                    <h1 class="text-left text-lg font-bold text-white">Try SmartPath!</h1>
                    <div class="text-left font-bold text-white">
                      <span>For patients with lower GI symptoms or anemia.</span>
                    </div>
                  </span>
                </div>

                <div v-if="specialistSmartPaths.length > 0" type="text" placeholder="autoHide: false" class="border-s border-2 border-full border-gray-200 hover:border-gray-500 hover:cursor-pointer rounded p-3 mb-4 flex flex-col md:flex-row justify-between items-start md:items-center" @click="openViewSmartPaths">
                  <flex class="flex flex-row items-center">
                    <i class="pi pi-sitemap text-md ml-2 mr-4" style="font-size: 1.3rem"></i>
                    <div class="flex flex-col">
                      <h1 class="text-md font-semibold">SmartPath Consult</h1>
                      <p class="text-xs">Clinical decision making tool.</p>
                    </div>
                  </flex>
                  <div class="hidden md:block">
                    <Button
                      v-tooltip="{
                        value: 'Click here to start SmartPath',
                        autoHide: false,
                        pt: {
                          arrow: {
                            style: {
                              borderBottomColor: 'var(--vh-new-feature-blue)',
                            },
                          },
                          text: '',
                        },
                      }"
                      class="p-button-sm"
                      @click="openViewSmartPaths"
                      label="Select"
                      id="viewSmartPathButton"
                    />
                  </div>
                </div>
                <ViewSmartPaths v-if="showViewSmartPaths" :smartPaths="specialistSmartPaths" :user="selectedSpecialist" :selectedSpecialty="selectedSpecialty" @closeViewSmartPaths="closeViewSmartPaths" />

                <!-- Kerri Purdy gets this hardcoded button for her lecture -->
                <div v-if="selectedSpecialist.id == 702" class="border-s border-2 border-full border-gray-200 hover:border-gray-500 hover:cursor-pointer rounded p-3 mb-4 flex flex-col md:flex-row justify-between items-start md:items-center" @click="eConsult" id="eConsultButton">
                  <flex class="flex flex-row items-center">
                    <i class="pi pi-desktop text-md ml-2 mr-4" style="font-size: 1.3rem"></i>
                    <div class="flex flex-col">
                      <h1 class="text-md font-semibold">Lectures</h1>
                      <p>Plaque Psoriasis Topical Therapy - Kerri Purdy</p>
                    </div>
                  </flex>
                  <div class="hidden md:block"><Button class="p-button-sm" @click="$router.push('/lecture/45')" label="Select" /></div>
                </div>
                <!-- End Kerri Purdy Section -->
              </div>
            </div>
          </div>
        </div>
        <!--End Booking Section -->

        <!-- Bio/Expertise Section -->
        <div class="col-span-4 flex flex-col pb-6 px-4 md:px-6">
          <div v-if="selectedSpecialist.bio" id="specBio">
            <div v-if="selectedSpecialist.bio">
              <h1 class="mb-2 text-md font-bold text-gray-600">Bio</h1>
              <p class="mb-4 text-sm">{{ selectedSpecialist.bio }}</p>
            </div>

            <div v-if="selectedSpecialist.practice_location">
              <h1 class="mb-2 text-md font-bold text-gray-600">Practice Location</h1>
              <p class="mb-4 text-sm">{{ selectedSpecialist.practice_location }}</p>
            </div>
          </div>
          <div v-if="selectedSpecialist.expertise_areas && selectedSpecialist.expertise_areas.length > 0">
            <h1 class="text-md font-bold text-gray-600 mb-2">Areas of Expertise</h1>
            <div class="areas-of-expertise-scroll">
              <div class="flex flex-col items-start justify-start rounded-xl text-xs font-normal">
                <div class="grid grid-cols-2">
                  <div v-for="area in selectedSpecialist.expertise_areas" class="flex flex-row items-center text-sm mb-2 ml-2" :key="area.id">
                    <i class="pi pi-star-fill text-sm text-left text-blue pr-2"></i>
                    <p>{{ area.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Bio/Expertise Section -->
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { mapGetters } from 'vuex';
import ViewSmartPaths from '../../components/gp/smartpath/ViewSmartPaths.vue';

export default {
  emits: ['closeSpecialistModal', 'specialistCardOnClick', 'econsultOnClick', 'bookOnDemand'],
  inject: ['mixpanel'],
  components: {
    Dialog,
    Button,
    ViewSmartPaths,
  },
  props: ['selectedSpecialist', 'selectedSpecialty', 'show', 'availableServices'],
  data() {
    return {
      isLoading: false,
      specialistSmartPaths: [],
      showViewSmartPaths: false,
      showRequestServiceModal: false,
      visible: false,
    };
  },
  methods: {
    closeSpecialistModal() {
      this.visible = false;
      this.$emit('closeSpecialistModal');
    },
    phoneConsult() {
      this.$emit('specialistCardOnClick');
    },
    onDemand() {
      let event = {
        selectedSpecialist: this.selectedSpecialist,
        selectedSpecialty: this.selectedSpecialty,
      };
      this.$emit('bookOnDemand', event);
    },
    eConsult() {
      this.$emit('econsultOnClick');
    },
    openViewSmartPaths() {
      this.showViewSmartPaths = true;
    },
    closeViewSmartPaths() {
      this.showViewSmartPaths = false;
    },
  },
  computed: {
    ...mapGetters(['smartPaths', 'loggedInUser', 'tutorialStatus', 'actingUser']),
    getDialogBreakPoints() {
      return { '960px': '75vw', '640px': '90vw' };
    },
    getDialogStyle() {
      return { maxWidth: '900px' };
    },
  },
  created() {
    this.visible = true;
    if (this.smartPaths.length) {
      this.specialistSmartPaths = this.smartPaths.filter((smartPath) => (smartPath.author.id == this.selectedSpecialist.id || smartPath.contributors.find((contributor) => contributor.id == this.selectedSpecialist.id)) && smartPath.status == 'Public' && !this.specialistSmartPaths.includes(JSON.stringify(smartPath)));
      this.specialistSmartPaths = this.specialistSmartPaths.filter((value, index) => this.specialistSmartPaths.indexOf(value) === index);

      // filter out duplicate smartpaths... could probably optimize this on the backend later
      // taken from https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects/70406623#70406623
      this.specialistSmartPaths = [...new Map(this.specialistSmartPaths.map((v) => [JSON.stringify(v), v])).values()];
    }
  },
};
</script>
<style>
#specModal .p-dialog-content {
  padding: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-dialog {
  border: none !important;
}

.selected-service-for-request-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #ffffff;
  border: 2px solid rgb(239 68 68);
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  padding: 0.6rem 1rem;
  color: #5b6678;
  text-decoration: none;
  transition: transform 0.1s ease-out;
  font-weight: 500;
  font-size: 0.9rem;
  text-align: left;
  min-width: 220px;
}

.areas-of-expertise-scroll {
  overflow: auto;
  overflow-y: visible;
  direction: ltr;
  max-height: 100px;
}

/* TUTORIAL STUFF START HERE */

.smartpath-tooltip {
  position: relative;
}

.smartpath-tooltip .smartpath-tooltiptext {
  width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 200;
  top: -30px;
  left: -150px;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.smartpath-tooltip .smartpath-tooltiptext::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 45%;
  left: 102%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.smartpath-tooltip-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

/* TUTORIAL STUFF END HERE */
</style>
