import axios from 'axios';
import globals from '../../globals';

const state = {};

const getters = {};

// actions
const actions = {
  downloadAttachedFile(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadAttachedFileByIdUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = response.headers['content-disposition'].split('attachment; filename=')[1];
          // The filename has quotations around it and for some reason the replacing once only
          // gets rid of the first quotation, so have to do it twice here.
          fileName = fileName.replace('"', '');
          fileName = fileName.replace('"', '');
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};

// mutations
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
