import axios from 'axios';
import globals from '../../globals';

const state = {
  referralResponseFormRelatedFiles: [],
};

const getters = {
  referralResponseFormRelatedFiles: (state) => {
    return state.referralResponseFormRelatedFiles;
  },
  canUploadResponseFormRelatedFile: () => (user, referral) => {
    if (!user) {
      return false;
    } else if (user.role === 'specialist') {
      return user.id == referral.referral_person_id || user.id === referral.referral_personable_id;
    } else if (user.role === 'org_unit_head') {
      return referral.org_units.find((org) => org.id == user.org_units[0].id);
    }
  },
  canDeleteResponseFormRelatedFile: () => (user, referral) => {
    if (!user) {
      return false;
    } else if (user.role === 'specialist') {
      return user.id == referral.referral_person_id || user.id === referral.referral_personable_id;
    } else if (user.role === 'org_unit_head') {
      return referral.org_units.find((org) => org.id == user.org_units[0].id);
    }
  },
  canDownloadResponseFormRelatedFile: () => (user, referral) => {
    if (!user) {
      return false;
    } else if (user.role == 'org_unit_head') {
      return referral.org_units.find((org) => org.id == user.org_units[0].id);
    } else {
      return user.id === referral.referee_id || user.id === referral.referral_person_id || user.id === referral.referral_personable_id;
    }
  },
};

// actions
const actions = {
  uploadReferralResponseFormRelatedFiles(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.uploadReferralResponseFormRelatedFilesUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let existingRelatedFiles = context.state.referralResponseFormRelatedFiles;
          existingRelatedFiles = existingRelatedFiles.concat(response.data.data);
          context.commit('setReferralResponseFormRelatedFiles', existingRelatedFiles);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addResponseFormRelatedFileFavoriteUser(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.addResponseFormRelatedFileFavoriteUserUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeResponseFormRelatedFileFavoriteUser(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.removeResponseFormRelatedFileFavoriteUserUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getReferralResponseFormRelatedFilesByReferralId(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getReferralResponseFormRelatedFilesByReferralIdUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          context.commit('setReferralResponseFormRelatedFiles', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadResponseFormRelatedFile(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadResponseFormRelatedFileUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = response.headers['content-disposition'].split('attachment; filename=')[1];
          // The filename has quotations around it and for some reason the replacing once only
          // gets rid of the first quotation, so have to do it twice here.
          fileName = fileName.replace('"', '');
          fileName = fileName.replace('"', '');
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteResponseFormRelatedFile(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(globals.APIs.deleteResponseFormRelatedFileUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          let existingRelatedFiles = context.state.referralResponseFormRelatedFiles;
          existingRelatedFiles = existingRelatedFiles.filter((file) => file.id != data.id);
          context.commit('setReferralResponseFormRelatedFiles', existingRelatedFiles);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllReferralResponseRelatedFileByUserId(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAllReferralResponseRelatedFileByUserIdUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          context.commit('setReferralResponseFormRelatedFiles', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setReferralResponseFormRelatedFiles(state, data) {
    state.referralResponseFormRelatedFiles = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
