<template>
  <div class="animated fade-in-up">
    <div class="times-header">
      <div class="th-line"></div>
      <div class="times-header-label mb-1">Pick Appointment Time For {{ selectedDateTime }}</div>
      <div class="th-line"></div>
      <div class="mb-2 text-xs font-bold text-gray-500">Times in {{ getUserTimeZoneLong() }}</div>
    </div>
    <div class="times-container select-specialty-list overflow-y-scroll">
      <div class="time-slot" v-for="[key, value] of availableTimeSlots" :key="key" @click="selectTimeSlot(key, value)" :class="{ active: key === activeItem }">
        <span>{{ key }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: ['date', 'selectedSchedulesForDate'],
  emits: ['timeSlotSelected'],
  data() {
    return {
      selectedDateTime: moment(this.date).format('MMMM DD'),
      selectedDate: this.formatDateYYYYMMDD(this.date),
      timeSlots: [],
      activeItem: null,
      availableTimeSlots: null,
    };
  },
  computed: {
    ...mapGetters(['schedules', 'referralCounts']),
  },
  methods: {
    selectTimeSlot(key, value) {
      this.activeItem = key;
      this.$emit('timeSlotSelected', {
        selectedUserId: value.availabilitySlot.user_id,
        selectedAvailabilitySlotId: value.availabilitySlot.id,
        selectedAvailabilitySlotStartDateTime: value.availabilitySlot.start_datetime,
      });
    },
    calculateTimeSlots() {
      this.availableTimeSlots = new Map();
      this.selectedSchedulesForDate.forEach((schedule) => {
        let usersReferralCount = this.referralCounts[schedule.user_id] ? this.referralCounts[schedule.user_id] : 0;
        let timeSlot = this.formatTime(schedule.start_datetime);
        let scheduleToAdd = schedule;
        let alreadyAvailableSlot = this.availableTimeSlots.get(timeSlot);
        if (alreadyAvailableSlot && alreadyAvailableSlot.referralCountForUserOfSlot <= usersReferralCount) {
          scheduleToAdd = alreadyAvailableSlot.availabilitySlot;
          usersReferralCount = alreadyAvailableSlot.referralCountForUserOfSlot;
        }
        this.availableTimeSlots.set(timeSlot, {
          availabilitySlot: scheduleToAdd,
          referralCountForUserOfSlot: usersReferralCount,
        });
      });
      this.availableTimeSlots = new Map([...this.availableTimeSlots]);
    },
  },
  created() {
    this.calculateTimeSlots();
  },
};
</script>

<style>
.times-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #16171d;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.th-line {
  content: '';
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  flex: 1;
}
.times-header-label {
  flex: 1;
  margin: 0px 10px;
  white-space: nowrap;
  font-size: 1rem;
}
.times-container {
  padding: 0px 15px 0px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  margin-left: auto;
  margin-right: auto;
  /* max-width: 375px; */
  max-height: 205px;
  grid-gap: 15px;
  justify-content: center;
  align-items: center;
}

.time-slot {
  text-align: center;
  padding: 10px 10px;
  line-height: 1.1;
  background-color: #f8f8f8;
  border: 1px solid #2c283e;
  border-radius: 4px;
  font-size: 12px;
  color: #2c283e;
  cursor: pointer;
  font-weight: 700;
}

.time-slot.active {
  background-color: #2c283e;
  color: #fff;
  border-color: #2c283e;
  box-shadow: none;
}

.time-slot:hover {
  border-color: #2c283e;
  background: #2c283e;
  color: white;
}
</style>
