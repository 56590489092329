<template lang="">
  <Dialog v-model:visible="visible" modal :breakpoints="{ '960px': '75vw', '640px': '90vw' }">
    <div class="flex justify-center rounded-b-xl py-1">
      <div class="flex w-full flex-col px-4 text-center">
        <h1 class="mb-3 text-md font-bold md:text-xl text-gray-600">You Have No Availability</h1>
        <div class="mb-2 text-sm text-gray-500" v-if="!promtFromReferralReschedule">
          <p>Set availability to start receiving Virtual Hallway consultations.</p>
          <p>To avoid running out of availability, consider setting a recurring schedule.</p>
        </div>
        <div class="mb-2 text-sm text-gray-500" v-else>
          <p>Consult reschedule requested, but you do not currently have any availability left.</p>
          <p>Add availability so you can receive consultations.</p>
        </div>

        <div class="flex flex-col items-center justify-between py-4 px-6 pb-4 md:flex-row flex-col-reverse">
          <div class="px-3 pb-1">
            <Button class="p-button-outlined p-button-secondary p-button-secondary w-56" label="Close" @click="$emit('modalClosed')" id="closeNoAvailabilityModal" />
          </div>
          <div class="px-3 pb-1 mb-4 md:mb-0">
            <Button class="p-button w-56" label="Set Availability" @click="$router.push({ path: `/schedules` })" id="goToAvailability" />
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script>
export default {
  props: ['showPromptToAddAvailability', 'promtFromReferralReschedule'],
  emits: ['modalClosed'],
  data() {
    return {
      visible: showPromptToAddAvailability,
    };
  },
};
</script>
<style lang=""></style>
