<template>
  <div class="main-inner-wrap">
    <div class="flex flex-col w-full p-5 bg-white border m-6 rounded">
      <div class="border m-5 font-bold font-display p-5" v-if="loggedInUser">We have updated our EULA, please accept by clicking the button at the bottom of the page to access your account.</div>
      <EulaArticle />
      <Button label="Agree to EULA" @click="agreeToEula" v-if="loggedInUser" />
    </div>
  </div>
</template>

<script>
import EulaArticle from '@/views/public/EulaArticle.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      errors: [],
      isLoading: false,
    };
  },
  components: {
    EulaArticle,
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['uploadEula']),
    agreeToEula() {
      this.uploadEula({
        userId: this.loggedInUser.id,
      })
        .then((res) => {
          window.location.reload();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        });
    },
  },
};
</script>

<style></style>
