<template>
  <Dialog header="Thank You!" v-model:visible="showModal" :draggable="false" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '40vw' }" :modal="true">
    <div class="sm:flex sm:flex-col md:grid">
      <div class="mx-4 mb-4">
        <div class="tip-title mb-3 text-sm" v-if="!referral.thank_you.message">{{ referral.referee.first_name }} {{ referral.referee.last_name }} has sent you a Thank You!</div>
        <template v-else>
          <div class="mb-1 text-sm font-normal text-gray-500">{{ referral.referee.first_name }} {{ referral.referee.last_name }}:</div>
          <div class="text-lg font-semibold text-gray-700 font-display">{{ referral.thank_you.message }}</div>
        </template>
        <div class="flex justify-end w-full"></div>
        <div class="flex flex-row justify-end items-center mt-4">
          <span class="mr-2 group relative" @click="likeThankYouOnClick" v-if="loggedInUser.id === referral.referral_person.id">
            <div class="hidden absolute group-hover:block bottom-10 -left-[89px] z-50 bg-gray-900 rounded-lg text-white text-sm font-semibold w-48 text-left p-2 like-thank-you-tooltip">Like this thank you note!</div>
            <i class="fa-regular fa-heart group-hover:text-blue-700 mr-2 cursor-pointer" style="font-size: 1.5rem" v-if="!referral?.thank_you.liked_by_receiver"></i>
            <i class="fas fa-heart mr-2 text-blue-700" style="font-size: 1.5rem" v-else />
          </span>
          <Button label="Close" icon="" @click="closeDialog" class="p-button-secondary p-button-outlined" />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import Dialog from 'primevue/dialog';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Button,
    Textarea,
    Dialog,
  },
  props: ['referral'],
  data() {
    return {
      showModal: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['likeThankYou']),
    closeDialog() {
      this.showModal = false;
    },
    openDialog() {
      this.showModal = true;
    },
    likeThankYouOnClick() {
      this.$toast.add({
        severity: 'success',
        summary: 'Liked Thank You Note!',
        life: 3000,
      });
      this.closeDialog();
      if (!this.referral?.thank_you.liked_by_receiver) {
        const data = { thank_you_id: this.referral.thank_you.id, liked_by_receiver: true };
        this.likeThankYou(data)
          .then(() => {})
          .catch(() => {});
      }
    },
  },
};
</script>
<style>
.like-thank-you-tooltip::after {
  border-color: transparent;
  border-style: solid;
  content: '';
  height: 0;
  position: absolute;
  pointer-events: none;
  bottom: -8px;
  transform: translateY(-50%);
  width: 0;
  right: 50%;
  left: 50%;
  border-top-color: rgb(17 24 39);
  border-width: 6px 5px 0 5px;
}
</style>
