<template>
  <Dialog header="Interesting in presenting?" :breakpoints="{ '960px': '50vw', '640px': '90vw' }" style="width: 500px" v-model:visible="show" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" id="interestedInSpeakingDiaglog">
    <div class="text-sm mb-2">Let us know the topic you'd like to give a lecture on and we will get back to you as soon as possible.</div>
    <form method="post" @submit.prevent="submitSpeakingInterest">
      <div class="w-full flex flex-row" v-if="!loggedInUser">
        <div class="mb-2 pt-2 w-full mr-2">
          <label class="auth-input">Name</label>
          <InputText v-model="interestedInSpeakingData.name" class="auth-input" required />
        </div>
        <div class="mb-2 pt-2 w-full ml-2">
          <label class="auth-input">Email</label>
          <InputText type="email" v-model="interestedInSpeakingData.email" class="auth-input" required />
        </div>
      </div>
      <div class="mb-2 pt-2">
        <label class="auth-input">What CME topic would you like to speak on?</label>
        <Textarea v-model="interestedInSpeakingData.topic" class="auth-input" :autoResize="true" rows="5" cols="30" required maxLength="65535" />
      </div>
      <div class="flex justify-end pt-5">
        <Button label="Close" class="p-button-secondary p-button-outlined mr-4" @click="show = false" />
        <Button type="submit" name="submitButton"> Submit </Button>
      </div>
    </form>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: ['pastLectures'],
  data() {
    return {
      isLoading: false,
      show: false,
      interestedInSpeakingData: {
        name: null,
        email: null,
        topic: null,
      },
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isLoggedIn']),
  },
  methods: {
    ...mapActions(['submitInterestedInSpeaking']),
    submitSpeakingInterest() {
      this.isLoading = true;
      if (this.loggedInUser && this.interestedInSpeakingData.topic != null) {
        this.interestedInSpeakingData.name = this.loggedInUser.first_name + ' ' + this.loggedInUser.last_name;
        this.interestedInSpeakingData.email = this.loggedInUser.email;
      }
      this.submitInterestedInSpeaking(this.interestedInSpeakingData)
        .then(() => {
          this.isLoading = false;
          this.show = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Speaker Request Sent',
            detail: 'Thank you for your interest. We will reach out shortly.',
            life: 10000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast;
        });
    },
    openDialog() {
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    },
  },
};
</script>

<style></style>
