import axios from 'axios';
import globals from '../../globals';
import moment from 'moment';

const state = {
  referralForm: null,
  referralFormFields: [],
  existingPatients: [],
  referralFormAddendums: [],
  diagnosticCodes: null,
  diagnosticCodesOntario: null,
};

const getters = {
  referralForm: (state) => {
    return state.referralForm;
  },
  referralFormFields: (state) => {
    return state.referralFormFields;
  },
  existingPatients: (state) => {
    return state.existingPatients;
  },
  referralFormAddendums: (state) => {
    return state.referralFormAddendums;
  },
  diagnosticCodes: (state) => {
    return state.diagnosticCodes;
  },
  diagnosticCodesOntario: (state) => {
    return state.diagnosticCodesOntario;
  },
};

// actions
const actions = {
  getReferralForm(context, referral) {
    context.commit('setReferralForm', referral.referral_form);
  },
  getReferralFormFields(context, specialtyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getReferralFormFieldsBySpecialtyId(specialtyId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (response.status == 200) {
            context.commit('setReferralFormFields', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //Doesn't seem to be used any where anymore
  getReferralFormFieldsByReferral(context, referralId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getReferralFormFieldsByReferralIdUrl(referralId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (response.status == 200) {
            context.commit('setReferralFormFields', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateReferralForm(context, referralForm) {
    return new Promise((resolve, reject) => {
      referralForm.patient_dob = moment(referralForm.patient_dob).format('YYYY-MM-DD');
      referralForm.updated_at = context.rootGetters.referral.referral_form.updated_at;
      axios
        .patch(globals.APIs.updateReferralFormUrl(referralForm.id), referralForm, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (response.status == 200) {
            context.commit('setReferral', response.data.data);
            if (response.data.user) {
              context.commit('updateLoggedInUser', response.data.user);
              if (context.getters.actingUser && context.getters.actingUser.id === context.getters.loggedInUser.id) {
                context.commit('updateActingUser', response.data.user);
              }
            }
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  saveReferralForm(context, referralForm) {
    return new Promise((resolve, reject) => {
      if (referralForm.patient_dob) {
        referralForm.patient_dob = moment(referralForm.patient_dob).format('YYYY-MM-DD');
        referralForm.is_signed_off = false;
      }
      referralForm.updated_at = context.rootGetters.referral.referral_form.updated_at;
      axios
        .patch(globals.APIs.saveReferralFormUrl(referralForm.id), referralForm, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (response.status == 200) {
            context.commit('setReferral', response.data.data);
          }
          resolve(response);
        })

        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadFilesForReferralForm(context, formData) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.uploadFilesForReferralFormUrl(), formData, {
          headers: { ...context.rootGetters.setHeaderWithAuth, 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          if (response.status == 200) {
            context.commit('setReferralForm', response.data.data.referral_form);
            context.commit('setReferral', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadFilesOfReferralFormField(context, data) {
    return new Promise((resolve, reject) => {
      // Setting proper request configuration to download ZIP files learned from: https://github.com/axios/axios/issues/2548#issuecomment-553434729
      axios
        .post(
          globals.APIs.downloadFilesOfReferralFormFieldUrl(),
          {
            referral_form_id: data.referralFormId,
            field_name: data.fieldName,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
            responseType: 'blob',
          }
        )
        .then((response) => {
          let fileName = data.fieldName + '.zip';
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  viewFileOfReferralFormField(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.viewFileOfReferralFormFieldUrl(),
          {
            referral_form_id: data.referralFormId,
            field_name: data.fieldName,
            index: data.index,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
            responseType: 'blob',
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteUploadedFilesFromReferralForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.deleteUploadedFilesFromReferralFormUrl(),
          {
            referral_form_id: data.referralFormId,
            field_name: data.fieldName,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            context.commit('setReferralForm', response.data.data.referral_form);
            context.commit('setReferral', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadPdfOfReferralForm(context, referralFormId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadPdfOfReferralFormUrl(referralFormId), {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: 'Consult Request-' + referralFormId + '.pdf' })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  faxPdfOfReferralForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.faxPdfOfReferralFormUrl(),
          {
            referral_form_id: data.referralFormId,
            fax_number: data.fax_number,
            fax_document: data.fax_document,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkIfPatientBookedWithinExclusionPeriod(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.checkIfPatientBookedWithinExclusionPeriodUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllUsersPatientsRecentReferralForm(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAllUsersPatientsRecentReferralFormURL(userId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (response.status == 200) {
            context.commit('setExistingPatients', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addAddendumToReferralForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.addAddendumToReferralFormUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (response.status == 200) {
            context.state.referralFormAddendums.push(response.data.data);
            context.commit('setReferralForm', response.data.referral.referral_form);
            context.commit('setReferral', response.data.referral);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllAddendumsOfReferralForm(context, referralFormId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getReferralFormAddendumsUrl(referralFormId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setReferralFormAddendums', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  signOffReferralForm(context, referral_form_id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          globals.APIs.signOffReferralFormUrl(referral_form_id),
          {},
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          context.commit('setReferralForm', response.data.data.referral_form);
          context.commit('setReferral', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getDiagnosticCodes(context, specialtyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getDiagnosticCodesUrl(specialtyId), {
          // headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setDiagnosticCodes', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDiagnosticCodesOntario(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getDiagnosticCodesOntarioUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setDiagnosticCodesOntario', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setReferralForm(state, data) {
    state.referralForm = data;
  },
  setReferralFormFields(state, data) {
    state.referralFormFields = data;
  },
  setExistingPatients(state, data) {
    state.existingPatients = data;
  },
  setReferralFormAddendums(state, data) {
    data.forEach((addendum) => {
      addendum.created_at = moment.utc(addendum.created_at).toDate();
      addendum.updated_at = moment.utc(addendum.updated_at).toDate();
    });
    state.referralFormAddendums = data;
  },
  setDiagnosticCodes(state, data) {
    let results = data[3];
    let diagnosticCodes = [];
    results.forEach((result) => {
      diagnosticCodes.push({ code: result[0], text: result[1], code_text: result[0] + ' ' + result[1] });
    });
    state.diagnosticCodes = diagnosticCodes;
  },
  setDiagnosticCodesOntario(state, data) {
    state.diagnosticCodesOntario = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
