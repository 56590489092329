<template>
  <form method="post" @submit.prevent="submit" class="grid grid-cols-2 gap-y-5 gap-x-3">
    <div class="col-start-1 col-end-3 sm:col-auto mb-2">
      <span class="p-float-label">
        <InputText type="text" id="firstName" name="firstName" v-model="user.first_name" required class="w-full" maxLength="55" />

        <label for="firstName" class="">First Name </label>
      </span>
    </div>
    <div class="col-start-1 col-end-3 sm:col-auto mb-2">
      <span class="p-float-label">
        <InputText type="text" id="lastName" name="lastName" v-model="user.last_name" required class="w-full" maxLength="55" />
        <label for="lastName" class="">Last Name </label>
      </span>
    </div>

    <div class="hidden">
      <div class="hidden">
        <div class="p-float-label">
          <Dropdown :class="['', { 'p-invalid': provinceError }]" :options="phoneCountryCodes" optionLabel="name" v-model="selectedPhoneCountryCode" id="phoneNumberCountryCode" :required="true" />
          <label for="phoneNumberCountryCode" class=""> Country Code </label>
        </div>
      </div>
    </div>

    <div class="col-start-1 col-end-3 sm:col-auto mb-2">
      <span class="p-float-label">
        <InputMask type="tel" mask="(999)-999-9999" id="phoneNumber" name="phoneNumber" v-model="phoneNumberWitMask" :required="true" :autoClear="false" :class="['w-full', { 'p-invalid': phoneNumberError }]" @change="validatePhoneNumber" maxlength="16" />
        <label for="phoneNumber" class="">Cell Number </label>
      </span>
    </div>

    <div class="col-start-1 col-end-3 sm:col-auto mb-2">
      <span class="p-float-label">
        <InputMask type="tel" mask="(999)-999-9999" id="confirmPhoneNumber" name="confirmPhoneNumber" v-model="confirmPhoneNumberWitMask" :required="true" :autoClear="false" :class="['w-full', { 'p-invalid': phoneNumberError }]" @change="validatePhoneNumber" maxlength="16" />
        <label for="confirmPhoneNumber" class="">Confirm Cell Number </label>
      </span>
      <span class="registration-error" v-if="phoneNumberError">
        {{ phoneNumberErrorMsg }}
      </span>
    </div>

    <div class="col-start-1 col-end-3 sm:col-auto mb-2" v-if="!loggedInUser || isOrgUnitHead(loggedInUser)">
      <span class="p-float-label">
        <InputText type="email" name="email" id="email" v-model="user.email" required :class="['w-full', { 'p-invalid': confirmEmailError }]" @change="validateEmail" maxLength="255" />
        <label for="email" class="">Email </label>
      </span>
    </div>

    <div class="col-start-1 col-end-3 sm:col-auto mb-2" v-if="!loggedInUser || isOrgUnitHead(loggedInUser)">
      <span class="p-float-label">
        <InputText type="email" name="confirmEmail" id="confirmEmail" v-model="user.email_confirmation" required :class="['w-full', { 'p-invalid': confirmEmailError }]" @change="validateEmail" maxLength="255" />
        <label for="confirmEmail" class="text-left">Confirm Email </label>
      </span>
      <span class="registration-error" v-if="confirmEmailError"> Emails Do Not Match </span>
    </div>

    <div class="col-start-1 col-end-3 sm:col-auto mb-2" v-if="!isOrgUnitHead(loggedInUser)">
      <div class="p-float-label">
        <Dropdown :class="['w-full text-left', { 'p-invalid': provinceError }]" v-model="provinceValue" :options="allProvinces" optionLabel="name" id="province" :required="true" @change="provinceValueOnChange" />
        <label for="province" class=""> Province of Practice </label>
      </div>
      <span class="registration-error" v-if="provinceError"> Province of Practice is Required </span>
    </div>

    <div class="col-start-1 col-end-3 sm:col-auto mb-2">
      <div class="p-float-label">
        <Dropdown :class="['w-full text-left', { 'p-invalid': providerTypeError }]" v-model="providerTypeValue" :options="providerTypeOptions" optionLabel="name" valueLabel="value" name="providerType" id="providerType" :required="true" />
        <label for="providerType" class="">Provider Type </label>
      </div>
      <span class="registration-error" v-if="providerTypeError"> Provider Type is Required </span>
    </div>

    <div class="col-start-1 col-end-3 sm:col-auto mb-2" v-if="!isOrgUnitHead(loggedInUser)">
      <span class="p-float-label">
        <InputText type="text" name="cpsns" id="cpsns" v-model="user.cpsns" required class="w-full" maxLength="255" />
        <label for="cpsns" class="">Practitioner License Number </label>
      </span>
    </div>

    <div class="col-start-1 col-end-3 sm:col-auto mb-2" v-if="!loggedInUser || isOrgUnitHead(loggedInUser)">
      <div class="p-float-label">
        <Dropdown :class="['w-full text-left', { 'p-invalid': physicianTypeError }]" v-model="physicianTypeValue" :options="physicianTypeOptions" optionLabel="name" name="physicianType" id="physicianType" :required="true" @change="handlePhysicianTypeChange" />
        <label for="physicianType" class="">Type of Practitioner </label>
      </div>
      <span class="registration-error" v-if="physicianTypeError"> Type of Practitioner is Required </span>
    </div>

    <div class="col-span-2 mb-2" v-if="specialtyVisible && (!loggedInUser || isOrgUnitHead(loggedInUser))">
      <div>
        <div class="p-float-label text-left" v-tooltip.left="'If you can not find your specialty, select Other and we will contact you to add your specialty once you register.'">
          <MultiSelect :class="['w-full', { 'p-invalid': specialityError }]" v-model="user.specialty_ids" :options="allSpecialties" name="speciality" id="speciality" optionLabel="name" :required="true" :showToggleAll="false" :filter="true" emptyMessage="Please Select Province of Practice" />
          <label for="speciality" class="">Type of Specialty </label>
        </div>
      </div>
      <span class="registration-error" v-if="specialityError"> Type of Specialty is Required </span>
    </div>

    <div class="col-span-2 mb-2" v-if="orgUnitVisible && (!loggedInUser || isOrgUnitHead(loggedInUser))">
      <div class="p-float-label text-left" v-tooltip.left="'Please input the name of the Group Practice you wish to join.'">
        <InputText type="text" name="orgUnit" id="orgUnit" :required="true" v-model="user.org_unit" class="w-full" maxLength="255" />
        <label for="orgUnit" class="">Group Practice </label>
      </div>
    </div>

    <div class="col-start-1 col-end-3 mb-2" v-if="user.specialty_ids && user.specialty_ids.find((specialty) => specialty.name === 'Other')">
      <span class="p-float-label">
        <InputText type="text" name="other_specialty" id="other_specialty" v-model="user.other_specialty" required class="w-full" maxLength="255" />
        <label for="other_specialty">Enter Your Specialty If Not Listed Above </label>
      </span>
    </div>

    <div class="col-start-1 col-end-3 sm:col-auto mb-2" v-if="!loggedInUser">
      <span class="p-float-label">
        <Password name="password" id="password" v-model="user.password" :toggleMask="true" required :class="['w-full', { 'p-invalid': passwordError }]" inputStyle="background-color:#fafafa!important;" maxLength="255">
          <template #footer>
            <p class="mt-2 font-bold">Requirements</p>
            <ul class="p-pl-1 p-ml-1 text-sm registration-form-password-strength" style="line-height: 1.5">
              <li><i :class="['pi', !/[a-z]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> At least one lowercase</li>
              <li><i :class="['pi', !/[A-Z]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> At least one uppercase</li>
              <li><i :class="['pi', !/[0-9]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> At least one number</li>
              <li><i :class="['pi', !passwordRegex.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i>At least one special character</li>
              <li><i :class="['pi', user.password.length < 8 ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> Minimum 8 characters</li>
              <li><i :class="['pi', user.password !== user.password_confirmation || user.password === '' ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> Passwords Match</li>
            </ul>
          </template>
        </Password>

        <label for="password" class="">Set Your Password </label>
      </span>
    </div>

    <div class="col-start-1 col-end-3 sm:col-auto" v-if="!loggedInUser">
      <span class="p-float-label">
        <Password name="confirmPassword" id="confirmPassword" v-model="user.password_confirmation" :toggleMask="true" required :class="['w-full', { 'p-invalid': passwordError }]" inputStyle="background-color:#fafafa!important; width: 100% !important;" maxLength="255">
          <template #footer>
            <p class="mt-2 font-bold">Requirements</p>
            <ul class="p-pl-1 p-ml-1 text-sm registration-form-password-strength" style="line-height: 1.5">
              <li><i :class="['pi', !/[a-z]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> At least one lowercase</li>
              <li><i :class="['pi', !/[A-Z]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> At least one uppercase</li>
              <li><i :class="['pi', !/[0-9]/.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> At least one number</li>
              <li><i :class="['pi', !passwordRegex.test(user.password) ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i>At least one special character</li>
              <li><i :class="['pi', user.password.length < 8 ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> Minimum 8 characters</li>
              <li><i :class="['pi', user.password !== user.password_confirmation || user.password === '' ? 'pi-times-circle' : 'pi-check-circle']" style="fontsize: 0.8rem"></i> Passwords Match</li>
            </ul>
          </template>
        </Password>
        <label for="confirmPassword" class="">Confirm Password </label>
      </span>
    </div>

    <div class="col-start-1 col-end-3 -mt-4" v-if="!loggedInUser && passwordError">
      <span class="registration-error"> Passwords Do Not Meet the Following Requirements:{{ passwordErrorMessage }} </span>
    </div>

    <div class="col-span-2" v-if="!loggedInUser">
      <div class="p-float-label">
        <Dropdown v-model="hearAboutUsValue" :options="hearAboutUsOptions" optionLabel="name" optionValue="name" id="hearAboutUs" @change="handleHearAboutUsChange" class="w-full text-left" />
        <label for="providerType" class="">How did you hear about us? </label>
      </div>
    </div>
    <div class="col-span-2 mb-2" v-if="otherHearAboutUsVisible">
      <div>
        <span class="p-float-label">
          <InputText type="text" name="other" id="other" v-model="user.hear_about_us" required class="w-full" maxLength="255" />
          <label for="other" class="">Please enter where you heard about us</label>
        </span>
      </div>
    </div>

    <div class="col-span-2 mx-2">
      <Button type="submit" id="submit" class="text-center w-full p-button-lg p-button-secondary text-xl" label="Join"></Button>
    </div>

    <div class="col-span-2 -mt-4 flex justify-center pl-2">
      <label class="pl-1 text-xs"> &nbsp;By registering, I agree to the Virtual Hallway <EulaDialog /></label>
    </div>

    <div class="col-span-2 -mt-4 flex justify-center pl-2">
      <label class="text-xs pl-1"> &nbsp;Wondering if you are eligible to join? <CmeEligibilityDialog /></label>
    </div>

    <div class="col-span-2 text-left pl-2 mb-1 flex justify-center">
      <Checkbox v-model="user.is_in_email_list" :binary="true" name="emailList" id="emailList" />
      <label for="emailList" class="text-xs pl-1 pt-0.5"> &nbsp;I want to stay up to date on {{ getReferralPersonTerm(actingUser) }} availability updates in Virtual Hallway.</label>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import InputMask from 'primevue/inputmask';
import Password from 'primevue/password';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import EulaDialog from '@/components/misc/EulaDialog.vue';

export default {
  props: ['loggedInUser', 'propsUser'],
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        email_confirmation: '',
        practice_province_id: '',
        physician_type: '',
        specialty_ids: [],
        provider_type: '',
        phone_country_code: '',
        confirm_phone_number: '',
        password: '',
        password_confirmation: '',
        org_unit: '',
      },
      confirmPhoneNumberWitMask: '',
      phoneNumberWitMask: '',
      confirmEmail: '',
      specialtyVisible: false,
      orgUnitVisible: false,
      error: '',
      physicianTypeOptions: [
        { name: 'Primary Care', value: 'gp' },
        { name: 'Specialist', value: 'specialist' },
        { name: 'Resident', value: 'org_unit_head' },
        { name: 'Group Practice Administrator', value: 'org_unit_head' },
      ],
      providerTypeOptions: [
        { name: 'Fee For Service (FFS)', value: 'FFS' },
        { name: 'Salaried (C/AFP)', value: 'AFP' },
        { name: 'Salaried (APP)', value: 'APP' },
        { name: 'Nurse Practitioner (NP)', value: 'NP' },
      ],
      physicianTypeValue: null,
      providerTypeValue: null,
      specialityValue: null,
      provinceValue: null,
      confirmEmailError: false,
      phoneNumberError: false,
      phoneNumberErrorMsg: '',
      physicianTypeError: false,
      providerTypeError: false,
      specialityError: false,
      provinceError: false,
      phoneCountryCodes: [{ name: '+1 (CAN/USA)', value: '1' }],
      selectedPhoneCountryCode: null,
      firstEmailChange: false,
      firstPhoneNumberChange: false,
      passwordError: false,
      eulaAgreed: true,
      response: '',
      otherHearAboutUsVisible: false,
      hearAboutUsOptions: [{ name: 'Word of Mouth' }, { name: 'Webinar/Presentation' }, { name: 'LinkedIn' }, { name: 'Facebook' }, { name: 'Google' }, { name: 'Interactive One-Pager' }, { name: 'Other' }],
      hearAboutUsValue: null,
      passwordRegex: /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/,
      strongRegex: /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/,
    };
  },
  emits: ['goToNext'],
  computed: {
    ...mapGetters(['allSpecialties', 'allProvinces', 'isOrgUnitHead', 'actingUser']),
  },
  components: {
    InputText,
    Dropdown,
    MultiSelect,
    InputMask,
    Password,
    Checkbox,
    Button,
    EulaDialog,
  },
  methods: {
    ...mapActions(['getAllProvinces', 'getSpecialties']),
    submit() {
      if (!this.loggedInUser) {
        this.validateEmail();
      }
      this.validatePhoneNumber();
      if (this.validate()) {
        this.user.practice_province_id = this.provinceValue.id;
        this.user.physician_type = this.physicianTypeValue.value;
        this.user.provider_type = this.providerTypeValue.value;
        this.user.phone_country_code = this.selectedPhoneCountryCode.value;
        this.$emit('goToNext', this.user);
      }
    },
    validate() {
      let valid = true;
      if (this.provinceValue === null) {
        this.provinceError = true;
        valid = false;
      } else {
        this.provinceError = false;
      }
      if (this.physicianTypeValue === null) {
        this.physicianTypeError = true;
        valid = false;
      } else {
        this.physicianTypeError = false;
      }
      if (this.providerTypeValue === null) {
        this.providerTypeError = true;
        valid = false;
      } else {
        this.providerTypeError = false;
      }
      if (this.specialtyVisible) {
        if (this.user.specialty_ids.length === 0) {
          this.specialityError = true;
          valid = false;
        } else {
          this.specialityError = false;
        }
      } else {
        this.specialityError = false;
      }
      if (!this.loggedInUser) {
        this.validateEmail();
        this.validatePassword();
        if (this.passwordError || this.confirmEmailError) {
          valid = false;
        }
      }
      this.validatePhoneNumber();
      if (this.phoneNumberError) {
        valid = false;
      }
      return valid;
    },
    validatePassword() {
      this.passwordError = false;
      this.passwordErrorMessage = '';
      if (this.user.password.length < 8) {
        this.passwordErrorMessage += ' Minimum 8 characters';
        this.passwordError = true;
      }
      if (!/[a-z]/.test(this.user.password)) {
        this.passwordErrorMessage += ' One Lower Case Character';
        this.passwordError = true;
      }
      if (!/[A-Z]/.test(this.user.password)) {
        this.passwordErrorMessage += ' One Upper Case Character';
        this.passwordError = true;
      }
      if (!/[0-9]/.test(this.user.password)) {
        this.passwordErrorMessage += ' One Number';
        this.passwordError = true;
      }
      if (!this.passwordRegex.test(this.user.password)) {
        this.passwordErrorMessage += ' One Special Character (!,@,#,$,etc.)';
        this.passwordError = true;
      }
      if (this.user.password !== this.user.password_confirmation) {
        this.passwordErrorMessage += ' Passwords Do Not Match';
        this.passwordError = true;
      }
    },
    handleHearAboutUsChange() {
      if (this.hearAboutUsValue === 'Other') {
        this.user.hear_about_us = null;
        this.otherHearAboutUsVisible = true;
      } else {
        this.user.hear_about_us = this.hearAboutUsValue;
        this.otherHearAboutUsVisible = false;
      }
    },
    validateEmail() {
      if (this.firstEmailChange) {
        if (this.user.email !== this.user.email_confirmation) {
          this.confirmEmailError = true;
        } else {
          this.confirmEmailError = false;
        }
      } else {
        this.firstEmailChange = true;
      }
    },
    validatePhoneNumber() {
      if (this.firstPhoneNumberChange) {
        this.phoneNumberError = false;
        this.phoneNumberErrorMsg = '';
        this.user.phone_number = this.phoneNumberWitMask.replace(/[^0-9]/g, '');
        this.user.confirm_phone_number = this.confirmPhoneNumberWitMask.replace(/[^0-9]/g, '');
        if (!/[0-9]/.test(this.user.phone_number) || this.user.phone_number.length != 10 || !/[0-9]/.test(this.user.confirm_phone_number) || this.user.confirm_phone_number.length != 10) {
          this.phoneNumberError = true;
          this.phoneNumberErrorMsg = 'Must be 10 digits. ';
        }
        if (this.user.phone_number !== this.user.confirm_phone_number) {
          this.phoneNumberError = true;
          this.phoneNumberErrorMsg += 'Cell numbers do not match.';
        }
      } else {
        this.firstPhoneNumberChange = true;
      }
    },
    handlePhysicianTypeChange() {
      if (this.physicianTypeValue.value === 'specialist') {
        this.specialtyVisible = true;
        this.orgUnitVisible = false;
      } else if (this.physicianTypeValue.value === 'org_unit_head') {
        this.orgUnitVisible = true;
        this.specialtyVisible = false;
      } else {
        this.specialtyVisible = false;
        this.orgUnitVisible = false;
        this.user.specialty_ids = [];
      }
    },
    provinceValueOnChange() {
      if (this.provinceValue) {
        this.getSpecialties({
          requesting_user_id: this.loggedInUser.id,
          province_name: this.provinceValue.name,
          interaction_type_names: ['phone'],
          for_referral: 0,
        })
          .then(() => {})
          .catch(() => {
            this.showGenericErrorToast();
          });
      }
    },
  },
  created() {
    this.selectedPhoneCountryCode = this.phoneCountryCodes[0];
    this.getAllProvinces()
      .then(() => {
        if (this.propsUser) {
          this.user = this.propsUser;
          this.phoneNumberWitMask = this.propsUser.phone_number;
          this.confirmPhoneNumberWitMask = this.propsUser.confirm_phone_number;

          if (this.propsUser.physician_type) {
            this.physicianTypeValue = this.physicianTypeOptions.find((type) => {
              return type.value === this.propsUser.physician_type;
            });
            this.handlePhysicianTypeChange();
          }
          if (this.propsUser.practice_province_id) {
            this.provinceValue = this.allProvinces.find((province) => {
              return province.id === this.propsUser.practice_province_id;
            });
          }
          if (this.propsUser.provider_type) {
            this.providerTypeValue = this.providerTypeOptions.find((type) => {
              return type.value === this.propsUser.provider_type;
            });
          }
          if (this.propsUser.hear_about_us) {
            this.hearAboutUsValue = this.hearAboutUsOptions.find((hear) => {
              return hear.name === this.propsUser.hear_about_us;
            });
            if (!this.hearAboutUsValue) {
              this.hearAboutUsValue = 'Other';
              let tempHearAboutUs = this.propsUser.hear_about_us;
              this.handleHearAboutUsChange();
              this.user.hear_about_us = tempHearAboutUs;
            } else {
              this.hearAboutUsValue = this.propsUser.hear_about_us;
            }
          }
        }
        if (this.loggedInUser && this.propsUser == null) {
          this.user = this.loggedInUser;
          this.phoneNumberWitMask = this.loggedInUser.phone_number;
          this.confirmPhoneNumberWitMask = this.loggedInUser.phone_number;

          this.provinceValue = this.loggedInUser.practice_province;
          this.physicianTypeValue = {
            value: this.loggedInUser.physician_type,
          };
          if (this.loggedInUser.provider_type) {
            this.providerTypeValue = this.providerTypeOptions.find((type) => {
              return type.value === this.loggedInUser.provider_type;
            });
          }
        }
        if (this.isOrgUnitHead(this.loggedInUser)) {
          this.provinceValue = this.loggedInUser.practice_province;
          this.provinceValueOnChange();
          this.user.cpsns = 'Added by org unit';
        }
      })
      .catch(() => {});
  },
  watch: {
    provinceValue() {
      this.provinceError = false;
    },
    physicianTypeValue() {
      this.physicianTypeError = false;
    },
    providerTypeValue() {
      this.providerTypeError = false;
    },
    'user.specialty_ids'() {
      this.specialityError = false;
    },
  },
};
</script>

<style scoped>
.registration-error {
  color: #dc3545;
  font-size: 0.8rem;
  @apply text-left;
}

.registration-form-password-strength i.pi-times-circle {
  color: var(--vh-error-red);
  padding-right: 2px;
}

.registration-form-password-strength i.pi-check-circle {
  color: var(--vh-success-green);
  padding-right: 2px;
}

.p-float-label label {
  margin-left: 0.5rem;
}

.p-inputtext,
.p-dropdown,
.p-multiselect {
  background-color: #fafafa !important;
}
</style>
