<template>
  <div class="flex flex-col w-full rounded-lg bg-white border shadow justify-center" v-if="!isLoading" :id="'referralResponseFormRelatedFeaturedFileTile' + file.id">
    <div class="flex flex-col w-full">
      <div class="flex w-full justify-center" v-if="isFeatured">
        <div v-if="file.cover_image_location" class="rounded-lg h-32 bg-cover bg-center w-full" :style="'background-image: url(' + file.cover_image_location + ')'"></div>
        <div class="h-32 w-full bg-primary flex items-center text-center justify-center" :style="iconStyle" v-else-if="resourceTypeIcons[file.type]"><i :class="resourceTypeIcons[file.type]" style="font-size: 5rem"></i></div>
        <div class="h-32 w-full bg-primary flex items-center text-center justify-center" :style="iconStyle" v-else><i class="fa-solid fa-book" style="font-size: 5rem"></i></div>
      </div>
      <div class="flex flex-row w-full p-2">
        <div v-if="!isFeatured" class="justify-start items-center align-middle place-self-center h-10 w-10 rounded-lg p-2 mr-2" :style="iconStyle"><i :class="resourceTypeIcons[file.type]"></i></div>
        <div class="flex flex-col w-full">
          <div class="flex flex-row justify-between w-full">
            <div class="text-sm font-display text-start mr-1">
              <span v-if="file.title">{{ file.title }}</span>
              <span v-else>{{ file.original_file_name }}</span>
            </div>
            <div class="text-sm font-display flex justify-center items-center text-gray-500">
              <div class="mr-2">
                <i class="pi pi-heart text-gray-500" style="font-size: 0.9rem"></i> <span>{{ file.favorite_users.length }}</span>
              </div>
              <div class=""><i class="pi pi-download text-gray-500" style="font-size: 0.8rem"></i> {{ file.download_count }}</div>
            </div>
          </div>
          <div class="flex flex-row justify-between items-center w-full">
            <div class="text-xs text-gray-500 text-start mr-1" v-if="file.type">{{ file.type }} - {{ file.year }}</div>
            <div class="text-xs text-gray-500 text-start mr-1" v-else>Resource</div>
          </div>
          <div class="flex flex-row justify-between items-center w-full">
            <div class="text-xs text-gray-500 text-start mr-1"><i class="pi pi-upload mr-1" style="font-size: 0.6rem"></i> {{ displayNameByRole(file.uploader) }}</div>
            <div class="text-xs font-display flex justify-center items-center text-xs text-gray-500"></div>
          </div>
        </div>
      </div>
      <div class="flex flex-row w-full border-t p-2 text-start animated fade-in-down text-xs italic" v-if="expanded">
        {{ file.description }}
      </div>
      <div class="flex flex-row w-full px-2 pb-2 mt-1">
        <div class="text-xs rounded-xl px-2 border mr-1" :style="tagsStyle" v-for="tag in file.tags">{{ tag.name }}</div>
      </div>
      <div class="flex flex-row w-full justify-evenly border-t cursor-pointer">
        <div aria-label="expandIcon" :id="'expandFileIcon' + file.id" class="border-r w-full" @click="expanded = !expanded" v-tooltip.bottom="{ value: 'Expand' }" v-if="file.description"><i class="pi pi-bars" :style="`font-size: 1rem;color:${tileColor}`" aria-label="favoriteIcon"></i></div>
        <div class="border-r w-full" @click="shareFile" v-tooltip.bottom="{ value: 'Share Resource' }"><i class="pi pi-share-alt" :style="`font-size: 1rem;color:${tileColor}`" aria-label="favoriteIcon"></i></div>
        <div @click="removeFavoriteUser" v-if="file.favorite_users.find((f) => f.id == loggedInUser.id)" aria-label="favoriteIcon" :id="'removeFileFromFavoriteIcon' + file.id" v-tooltip.bottom="{ value: 'Remove from Favorites' }" class="border-r w-full"><i class="pi pi-heart-fill" :style="`font-size: 1rem;color:${tileColor}`" aria-label="favoriteIcon"></i></div>
        <div @click="addFavoriteUser" v-else aria-label="favoriteIcon" v-tooltip.bottom="{ value: 'Add to Favorites' }" class="border-r w-full"><i class="pi pi-heart text-gray-500" :style="`font-size: 1rem;color:${tileColor}`" aria-label="favoriteIcon" :id="'addFileToFavoriteIcon' + file.id"></i></div>
        <div @click="downloadFile" class="border-r w-full" v-tooltip.bottom="{ value: 'Download File' }" :id="'downloadResponseFormRelatedFileBtn' + file.id"><i class="pi pi-download" :style="`font-size: 1rem;color:${tileColor}`"></i></div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="showShareFileDialog" modal header="Share Resource" class="md:w-1/2 sm:w-full" :dismissable-mask="true" :draggable="false">
    <div class="flex flex-col mb-5">
      <Message severity="info" :closable="false">Share the link with your friends and colleagues to give them access to this resource.</Message>
      <div class="text-sm font-display mb-2">Resource Link</div>
      <div class="md:flex sm:grid text-sm">
        <div class="p-2 font-display border w-full text-gray-500">{{ fileUrl }}</div>
        <Button label="Copy Link" class="w-full p-button-sm" @click="copyFileUrlToClipboard" />
      </div>
    </div>
  </Dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { Share } from '@capacitor/share';

export default {
  props: ['file', 'tileColor', 'isFeatured'],
  emits: ['goToAddSchedule', 'goToDeleteSchedule'],
  data() {
    return {
      iconStyle: [],
      tagsClasses: [],
      resourceTypeIcons: {
        Review: 'fa-solid fa-book',
        'Meta-analysis': 'fa-solid fa-book',
        Guideline: 'fa-solid fa-scroll',
        Opinion: 'fa-solid fa-file-invoice',
        Editorial: 'fa-solid fa-file-invoice',
        RCT: 'fa-solid fa-people-group',
        'Clinical Study': 'fa-solid fa-people-group',
        'Observational Study': 'fa-solid fa-people-group',
        Other: 'fa-solid fa-book-open',
      },
      isLoading: false,
      expanded: false,
      showShareFileDialog: false,
      fileUrl: '',
    };
  },
  created() {
    this.isLoading = true;
    this.iconStyle = `background-color:${this.tileColor}; color:white;`;
    this.tagsStyle = `border-color:${this.tileColor}; color:${this.tileColor};`;
    this.fileUrl = location.origin + '/resources?id=' + this.file.id;
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['downloadResponseFormRelatedFile', 'addResponseFormRelatedFileFavoriteUser', 'removeResponseFormRelatedFileFavoriteUser']),
    downloadFile() {
      this.downloadResponseFormRelatedFile({
        id: this.file.id,
      })
        .then(() => {
          this.file.download_count++;
          this.$toast.add({
            severity: 'success',
            summary: 'File Downloaded',
            detail: 'The file has been successfully downloaded to your computer, please check your downloads folder.',
            life: 5000,
          });
        })
        .catch(() => this.showGenericErrorToast());
    },
    addFavoriteUser() {
      this.addResponseFormRelatedFileFavoriteUser({
        file_id: this.file.id,
      })
        .then(() => {
          this.file.favorite_users.push(this.loggedInUser);
        })
        .catch(() => this.showGenericErrorToast());
    },
    removeFavoriteUser() {
      this.removeResponseFormRelatedFileFavoriteUser({
        file_id: this.file.id,
      })
        .then(() => {
          let userIndex = this.file.favorite_users.indexOf(this.file.favorite_users.find((u) => u.id == this.loggedInUser.id));
          this.file.favorite_users.splice(userIndex, 1);
        })
        .catch(() => this.showGenericErrorToast());
    },
    async shareFile() {
      if (this.isNativePlatform()) {
        await Share.share({
          title: 'Virtual Hallway Resources',
          text: 'Check out this resource in Virtual Hallway.',
          url: this.fileUrl,
          dialogTitle: 'Share Virtual Hallway Resource',
        });
      } else {
        this.showShareFileDialog = true;
        // Setting a timeout here since the dialog needs to be displayed first otherwise
        // the copied to clipboard toast goes under the dialog
        setTimeout(() => {
          this.copyFileUrlToClipboard();
        }, 100);
      }
    },
    copyFileUrlToClipboard() {
      navigator.clipboard.writeText(this.fileUrl);
      this.$toast.add({
        severity: 'success',
        summary: 'Link copied!',
        life: 5000,
      });
    },
  },
};
</script>

<style></style>
