<template>
  <!---------------------------------->
  <!-- this is lowkey deprecated fr -->
  <!---------------------------------->

  <div class="main-inner-wrap vld-parent" v-if="isLoading">
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
  </div>
  <div class="main-inner-wrap" v-if="!isLoading">
    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block"><i class="pi pi-list mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Referral Triage</h1>
        <h1 class="text-sm text-gray-200">
          Search the table below for the consult you're looking for, then click the <i class="pi pi-eye mx-1 mb-2 text-white" style="font-size: 0.8rem" /> icons to view the referral, or the <i class="pi pi-ellipsis-v mx-1 mb-2 text-white" style="font-size: 0.8rem" /> icons to download a PDF of the initial Referral Request, or the Referral Report.
          <span class="text-sm text-gray-200" v-if="(isGP(loggedInUser) || isSpecialist(loggedInUser)) && loggedInUser.org_units.length > 0">
            To share referrals with your Practice Group, click the
            <i class="pi pi-elipsis-v mb-2 text-white" style="font-size: 0.8rem" /> icons, then select the Practice Group you wish to share it with.</span
          >
        </h1>
      </div>
    </div>
    <div v-if="filteredReferrals.length < 1">
      <div class="seperator-dark-blue w-28"></div>
      <span class="text-xl font-semibold text-gray-600"> No referrals to triage </span>
    </div>
    <div class="w-full pt-4" v-else>
      <ReferralList :referrals="filteredReferrals" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferralList from '@/components/referral/ReferralList.vue';
import Loading from 'vue-loading-overlay';

export default {
  components: {
    ReferralList,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      filteredReferrals: [],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'allReferrals', 'isGP', 'isSpecialist', 'isOrgUnitHead', 'actingUser']),
  },
  methods: {
    ...mapActions(['getAllReferralsOfUser', 'getReferralByOrgUnitId']),
  },
  created() {
    this.isLoading = true;
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.getReferralByOrgUnitId(this.loggedInUser.org_units[0].id)
        .then(() => {
          this.filteredReferrals = this.allReferrals
            .filter((referral) => {
              return referral.interaction_type.name === 'in_person';
            })
            .sort((a, b) => {
              if (a.status === 'In Review') {
                return -1;
              } else if (b.status === 'In Review') {
                return 1;
              }
              return 0;
            });
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    } else {
      this.getAllReferralsOfUser({ user_id: this.actingUser.id, required_relations: ['referral_form', 'interaction_type', 'specialty', 'org_units'] })
        .then(() => {
          this.isLoading = false;
          this.filteredReferrals = this.allReferrals
            .filter((referral) => {
              return referral.interaction_type.name === 'in_person' && referral.referral_person_id === this.actingUser.id;
            })
            .sort((a, b) => {
              if (a.status === 'In Review') {
                return -1;
              } else if (b.status === 'In Review') {
                return 1;
              }
              return 0;
            });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    }
  },
};
</script>

<style></style>
