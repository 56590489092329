<template>
  <modal ref="deleteScheduleModal" @closeModal="closeModal">
    <template v-slot:header>
      <div class="header">
        <div class="p-4">
          <!-- <Button @click="closeDeleteScheduelModal" class="p-button-sm p-button-secondary" id="closeBtn"> Close </Button> -->
          <Button label="Close" icon="pi pi-times" class="p-button-secondary p-button-outlined p-button-sm" @click="closeDeleteScheduelModal" />
        </div>
      </div>
    </template>

    <template v-slot:body>
      <div class="pb-5">
        <div v-if="selectedSchedule.length > 0" class="p-4">
          <h5 class="text-md font-medium text-red-500">Are you sure you want to remove this schedule?</h5>
          <div v-if="selectedSchedule[0].is_recurring" class="confirmDeleteScheduleDateCard">
            <DateCard @goToAddSchedule="null" :groupedSchedule="selectedSchedule" :dayOfWeek="formatDateTimeToWeekday(selectedSchedule[0].start_datetime)" />
          </div>
          <div v-else class="confirmDeleteScheduleDateCard">
            <DateCard @goToAddSchedule="null" :groupedSchedule="selectedSchedule" :dayOfWeek="null" />
          </div>
        </div>
        <div class="px-4">
          <Button @click="confirmDeleteSchedule" class="p-button-danger flex w-full justify-center" name="confirmButton"> Confirm Remove Schedule </Button>
        </div>
      </div>
    </template>
    <template v-slot:footer> </template>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from 'primevue/button';
import modal from '@/components/misc/Modal.vue';
import DateCard from '@/components/schedule/DateCard.vue';

export default {
  props: ['selectedSchedule'],
  components: {
    Button,
    modal,
    DateCard,
  },
  data() {
    return {
      schedules: [],
    };
  },
  computed: {
    ...mapGetters(['selectedUserToUpdateSchedule']),
  },
  methods: {
    ...mapActions(['deleteSchedules']),
    openDeleteScheduleModal() {
      this.$refs.deleteScheduleModal.openModal();
    },
    closeModal() {
      this.$emit('closeModal');
    },
    closeDeleteScheduelModal() {
      this.$refs.deleteScheduleModal.closeModal();
    },
    confirmDeleteSchedule() {
      this.deleteSchedules(this.selectedSchedule)
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Schedule Removed.',
            life: 5000,
          });
          this.closeDeleteScheduelModal();
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/* The following are for hiding Edit and Delete icons form DateCard when user wants to confirm schedule */
.confirmDeleteScheduleDateCard #delete-individual-schedule {
  display: none;
}
.confirmDeleteScheduleDateCard #edit-individual-schedule {
  display: none;
}
</style>
