import axios from 'axios';
import globals from '../../globals';

const state = {
  users: [],
  orgUnitUsers: [],
  currentParentUser: null,
  numberRemaingReferrals: null,
  tutorialStatus: '',
  isAllowedMakeReferral: localStorage.getItem('isAllowedMakeReferral'),
  avatarUrls: [],
  expertiseAreas: [],
  whitelist: [],
  isAvailable: localStorage.getItem('isAvailable'),
  showTutorialRelaunchTooltip: false,
  invitedByOrgUnitHead: false,
  offeredServicesOfUsers: {
    eConsult: [],
    phoneConsult: [],
    onDemandConsult: [],
  },
};

const getters = {
  isPhysician: () => (user) => {
    return ['gp', 'specialist'].includes(user?.role) && !['NP', 'PHARMACIST'].includes(user?.provider_type);
  },
  isGP: () => (user) => {
    if (user) {
      return user.role === 'gp';
    } else {
      return false;
    }
  },
  isSpecialist: () => (user) => {
    if (user) {
      return user.role === 'specialist';
    } else {
      return false;
    }
  },
  isOrgUnitHead: () => (user) => {
    if (user) {
      return user.role === 'org_unit_head';
    } else {
      return false;
    }
  },
  isCme: () => (user) => {
    if (user) {
      return user.role === 'cme';
    } else {
      return false;
    }
  },
  canMakeReferrals: () => (user) => {
    if (user) {
      return ['gp', 'specialist', 'org_unit_head'].includes(user.role);
    } else {
      return null;
    }
  },
  canEditReferralForm: () => (user, referee, referral) => {
    // If user is Org Unit Head, need to check if the referee of the consult belongs
    // to his/her org unit to allow edition.
    if (user.role === 'org_unit_head') {
      if (user.org_units.length > 0) {
        return referral.org_units.some((org_unit) => referee.id === org_unit.pivot.user_id && org_unit.id === user.org_units[0].id);
      } else {
        return false;
      }
    } else return user.id === referee.id;
  },
  canEditReferralResponseForm: () => (user, referralPerson, referral) => {
    // If user is Org Unit Head, need to check if the referral person of the consult belongs
    // to his/her org unit to allow edition.
    if (user.role === 'org_unit_head') {
      if (user.org_units.length > 0) {
        return referral.org_units.some((org_unit) => referralPerson.id === org_unit.pivot.user_id && org_unit.id === user.org_units[0].id);
      } else {
        return false;
      }
    } else return user.id === referralPerson.id;
  },
  canSetAvailability: () => (user) => {
    if (user) {
      return ['specialist', 'org_unit_head'].includes(user.role);
    } else {
      return null;
    }
  },
  users: (state) => {
    return state.users;
  },
  orgUnitUsers: (state) => {
    return state.orgUnitUsers;
  },
  numberRemaingReferrals: (state) => {
    return state.numberRemaingReferrals;
  },
  isAllowedMakeReferral: (state) => {
    if (state.isAllowedMakeReferral !== null) {
      return JSON.parse(state.isAllowedMakeReferral);
    } else return false;
  },
  getUserId: (state, getters) => {
    if (getters.loggedInUser !== null) {
      let userId = getters.loggedInUser.id;
      return userId;
    } else return null;
  },
  getProviderType: (state, getters) => {
    if (getters.loggedInUser !== null) {
      let providerType = getters.loggedInUser.provider_type;
      return providerType;
    } else return null;
  },
  isLegacyNeedReRegister: (state, getters) => (user) => {
    if (user !== null) {
      if (user.address && user.phone_number !== null && !getters.isOrgUnitHead(user)) {
        return user.provider_type === 'N/A' || user.first_name === '' || user.last_name === '' || user.phone_number === '' || user.phone_number.length !== 10 || user.cpsns === '';
      } else return null;
    } else return null;
  },
  tutorialStatus: (state) => {
    return state.tutorialStatus;
  },
  showTutorialRelaunchTooltip: (state) => {
    return state.showTutorialRelaunchTooltip;
  },
  hasProvinceOfPractice: () => (user, provinces) => {
    if (!Array.isArray(provinces)) {
      provinces = [provinces];
    }
    return provinces.some((province) => user?.practice_province && (province === user.practice_province?.name || province === user.practice_province?.short_name));
  },
  avatarUrls: (state) => {
    return state.avatarUrls;
  },
  expertiseAreas: (state) => {
    return state.expertiseAreas;
  },
  whitelist: (state) => {
    return state.whitelist;
  },
  isAvailable: (state) => {
    return state.isAvailable;
  },
  getUserActionByName: () => (user, actionName) => {
    return user.actions?.find((action) => action.name == actionName);
  },
  getUserActionsByName: () => (user, actionName) => {
    return user.actions?.filter((action) => action.name == actionName);
  },
  getUserActionById: () => (user, id) => {
    return user.actions?.find((action) => action.id == id);
  },
  isRegisteredUser: () => (user) => {
    if (user) {
      return user.is_registered_user;
    }
    return false;
  },
  invitedByOrgUnitHead: (state) => {
    return state.invitedByOrgUnitHead;
  },
  findBillingArrangementByProvince: () => (user, practiceProvinceId) => {
    return user.billing_arrangements.find((billing_arrangement) => billing_arrangement.practice_province.id === practiceProvinceId);
  },
  isCrossBorderSpecialist: () => (user) => {
    if (!user) {
      return false;
    }
    return user.role === 'specialist' && user.specialties.find((specialty) => specialty.practice_province.id !== user.practice_province.id);
  },
  offeredServicesOfUsers: (state) => {
    return state.offeredServicesOfUsers;
  },
};

// actions
const actions = {
  getAllUsersBySpecialty(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getUsersBySpecialtyUrl(data), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setUsers', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUserById(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getUserByIdUrl(userId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getEconsultCountsOfUsersBySpecialtyId(context, specialtyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getEconsultCountsOfUsersBySpecialtyIdUrl(specialtyId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setEconsultCounts', response.data.econsult_count);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOrgUnitUsers(context, orgUnitId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getUsersByOrgUnitIdUrl(orgUnitId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('setOrgUnitUsers', res.data.data);
            context.commit('setActingUser', res.data.data[0]);
            resolve(res);
          } else reject(res.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getOrgUnitSpecialists(context, orgUnitId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getUsersByRoleAndOrgUnitIdUrl('specialist', orgUnitId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('setOrgUnitUsers', res.data.data);
            context.commit('setActingUser', res.data.data[0]);
            resolve(res);
          } else reject(res.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getOrgUnitGPs(context, orgUnitId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getUsersByRoleAndOrgUnitIdUrl('gp', orgUnitId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('setOrgUnitUsers', res.data.data);
            context.commit('setActingUser', res.data.data[0]);
            resolve(res);
          } else reject(res.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getOnboardingUsersOfOrgUnit(context, orgUnitId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getOnboardingUsersOfOrgUnitUrl(orgUnitId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit('setOrgUnitUsers', res.data.data);
            resolve(res);
          } else reject(res.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  updateOfficeFacilityNumber(context, officefacilitynumber) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.updateOfficeFacilityNumberUrl(), officefacilitynumber, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (context.rootGetters.loggedInUser && context.rootGetters.loggedInUser.id == response.data.data.id) {
            context.commit('updateLoggedInUser', response.data.user);
            context.commit('setOnboardingForm', response.data.onboarding_form);
          }
          if (context.rootGetters.actingUser && context.rootGetters.actingUser.id == response.data.data.id) {
            context.commit('updateActingUser', response.data.user);
            context.commit('setActingUserOnboardingForm', response.data.onboarding_form);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setupUserAccount(context, user) {
    if (user.is_submitted === null || user.is_submitted === undefined) {
      user.is_submitted = false;
    }

    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.setupUserAccountUrl(user.id),
          {
            id: user.id,
            cpsns: user.cpsns,
            physician_type: user.role,
            provider_type: user.provider_type,
            phone_number: user.phone_number,
            practice_province_id: user.practice_province_id,
            specialty_ids: user.specialty_ids,
            is_submitted: user.is_submitted,
            org_unit: user.org_unit,
            ohip_number: user.ohip_number,
            other_specialty: user.other_specialty,
            other_provider_type: user.other_provider_type,
            preferred_emr: user.preferred_emr,
            call_asap_optin: user.call_asap_optin,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          if (context.rootGetters.loggedInUser && context.rootGetters.loggedInUser.id == response.data.user.id && response.data.user.status == 'Onboarding') {
            context.commit('updateLoggedInUser', response.data.user);
          }
          if (context.rootGetters.actingUser && context.rootGetters.actingUser.id == response.data.user.id && response.data.user.status == 'Onboarding') {
            context.commit('updateActingUser', response.data.user);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateUserAccountInfo(context, user) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          globals.APIs.updateUserAccountInfoUrl(user.id),
          {
            ...user,
            address_street: user.address.street,
            address_city: user.address.city,
            address_postal_code: user.address.postal_code,
            address_province: user.address.province,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          if (context.rootGetters.loggedInUser && context.rootGetters.loggedInUser.id == response.data.data.id) {
            context.commit('updateLoggedInUser', response.data.data);
            context.commit('setOnboardingForm', response.data.onboarding_form);
          }
          if (context.rootGetters.actingUser && context.rootGetters.actingUser.id == response.data.data.id) {
            context.commit('updateActingUser', response.data.data);
            context.commit('setActingUserOnboardingForm', response.data.onboarding_form);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateUserProfileInfo(context, profile) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.updateUserProfileInfoUrl(), profile, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  changePassword(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.changePasswordUrl(),
          {
            user_id: data.userId,
            current_password: data.currentPassword,
            password: data.newPassword,
            password_confirmation: data.newPasswordConfirmation,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  uploadProfilePicture(context, data) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('file', data.file);
      formData.append('user_id', data.userId);
      axios
        .post(globals.APIs.uploadProfilePictureUrl(), formData, {
          headers: { ...context.rootGetters.setHeaderWithAuth, 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          if (context.rootGetters.loggedInUser && context.rootGetters.loggedInUser.id == response.data.data.id) {
            context.commit('updateLoggedInUser', response.data.data);
          }
          if (context.rootGetters.actingUser && context.rootGetters.actingUser.id == response.data.data.id) {
            context.commit('updateActingUser', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  closeAccount(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.closeAccountUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  contactSupport(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.contactSupportUrl(),
          {
            user_id: data.user_id,
            message: data.contactMessage,
            method_contact: data.contactMethod,
            confirm_no_patient_info: data.confirmNoPatientInfo,
            route: data.currentRoute,
            role: data.role,
            timezone: data.timezone,
            user_agent: data.user_agent,
            org_units: data.org_units,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendColleagueInvite(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.colleagueInviteUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  textAppLink(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.textAppLinkUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkIfUserIsAllowedToMakeReferral(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.checkIfUserIsAllowedToMakeReferralURL(userId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          localStorage.setItem('isAllowedMakeReferral', JSON.stringify(response.data.is_allowed_make_referral));
          context.commit('setIsAllowedMakeReferral', response.data.is_allowed_make_referral);
          context.commit('setNumberRemaingReferrals', response.data.number_of_remaining_referrals_allowed);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateSpecialistPreference(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.updateSpecialistPreferenceURL(),
          {
            wait_list: data.waitlist_in_days,
            zone: data.zone,
            ...data.specialist_preference,
            id: data.id,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          if (context.rootGetters.loggedInUser && context.rootGetters.loggedInUser.id == response.data.data.id) {
            context.commit('updateLoggedInUser', response.data.data);
          }
          if (context.rootGetters.actingUser && context.rootGetters.actingUser.id == response.data.data.id) {
            context.commit('updateActingUser', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  confirmPassword(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.confirmPasswordUrl(),
          {
            password: data,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  addUserAsOrgUnitHead(context, user) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.addNewUserAsOrgUnitHeadUrl(), user, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          let res = 'There seems to be an error please try again.';
          if (error.response.status === 422) {
            if (error.response.data.errors.email !== null) {
              res = 'That email already exists in our system. Please try to login.';
            }
          }
          reject(res);
        });
    });
  },
  inviteUserAsOrgUnitHead(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.inviteUserAsOrgUnitHeadUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  updateNotificationSetting(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(globals.APIs.updateNotificationSettingUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          let user = context.rootGetters.loggedInUser;
          user.profile.notification_setting = response.data.data;
          context.commit('updateLoggedInUser', user);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  logPerformance(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.performancePageLogUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logFeed(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.feedLinkClickLogUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllAvatarUrls(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAllAvatarUrlsUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setAvatarUrls', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  updateUserAvatar(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.updateUserAvatarUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getExpertiseAreaById(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getExpertiseAreaByIdUrl(userId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  createExpertiseArea(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createExpertiseAreaUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  attachExpertiseAreas(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.attachExpertiseAreasUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  removeExpertiseAreas(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.removeExpertiseAreasUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  createWhitelistedFaxNumber(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createWhitelistedFaxNumberURL(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setWhitelist', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getWhitelistedFaxNumbers(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getWhitelistedFaxNumbersURL(userId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setWhitelist', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  updateWhitelistedFaxNumber(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.updateWhitelistedFaxNumberURL(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setWhitelist', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  deleteWhitelistedFaxNumber(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.deleteWhitelistedFaxNumberURL(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setWhitelist', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  checkCurrentUsersAvailability(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.checkCurrentUsersAvailabilityUrl(data), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setIsAvailable', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getUserActions(context, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getUserActionsUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: params,
        })
        .then((response) => {
          let updatedActions = response.data.data;
          let loggedInUser = context.rootGetters.loggedInUser;
          loggedInUser.actions = updatedActions;
          context.commit('updateLoggedInUser', loggedInUser);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  addNewUserActions(context, newUserActions) {
    let loggedInUser = context.rootGetters.loggedInUser;
    loggedInUser.actions = loggedInUser.actions.concat(newUserActions);
    context.commit('updateLoggedInUser', loggedInUser);
  },
  availabilityFeedback(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.availabilityFeedbackUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  updateUserAction(context, data) {
    // Here, the priority is to update the action for this session even if
    // updating it on the backend fails. So, update the local state first. E.g.,
    // if a user skips tutorial and the server call failed, do not prompt the
    // tutorial again for this session. However, it will pop up when the user
    // logs in again since not updated in server but it is less intrusive.
    let updatedAction = data;
    let loggedInUser = context.rootGetters.loggedInUser;
    loggedInUser.actions.find((action, index) => {
      if (action.id == updatedAction.id) {
        loggedInUser.actions[index] = updatedAction;
        return updatedAction;
      }
    });
    context.commit('updateLoggedInUser', loggedInUser);
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.updateUserActionUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  createUserFeedback(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createUserFeedbackUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  supportCallMe(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.supportCallMeUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getOfferedServicesOfUsers(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getOfferedServicesOfUsersUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          context.commit('setOfferedServicesOfUsers', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  requestSpecialistService(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.requestSpecialistServiceUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendNotificationToUserFromTextMessage(context, code) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.sendNotificationToUserFromTextMessageUrl(code), {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  requestEmailUpdate(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.requestEmailUpdateUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  verifyEmailUpdate(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.verifyEmailUpdateUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  callerBetaRequestAccess(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.callerBetaRequestAccessUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  createAudit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createAuditUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveUserAgreedToPushNotifications(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveUserAgreedToPushNotificationsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  inviteUsersByEmail(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.inviteUsersByEmailUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setUsers(state, data) {
    state.users = data;
  },
  setOrgUnitUsers(state, data) {
    state.orgUnitUsers = data;
  },
  setNumberRemaingReferrals(state, data) {
    state.numberRemaingReferrals = data;
  },
  setIsAllowedMakeReferral(state, data) {
    state.isAllowedMakeReferral = data;
  },
  setTutorialStatus(state, data) {
    state.tutorialStatus = data;
  },
  setAvatarUrls(state, data) {
    state.avatarUrls = data;
  },
  setWhitelist(state, data) {
    state.whitelist = data;
  },
  setExpertiseAreas(state, data) {
    state.expertiseAreas = data;
  },
  setIsAvailable(state, data) {
    state.isAvailable = data;
    localStorage.setItem('isAvailable', data);
  },
  setShowTutorialRelaunchTooltip(state, data) {
    state.showTutorialRelaunchTooltip = data;
  },

  setInvitedByOrgUnitHead(state, data) {
    state.invitedByOrgUnitHead = data;
  },
  setOfferedServicesOfUsers(state, data) {
    state.offeredServicesOfUsers = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
