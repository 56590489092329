<template>
  <div class="col-span-2 mt-8 mb-4 flex flex-row items-center justify-center">
    <h1 class="mr-2 text-lg font-bold text-gray-500">Contributors for {{ smartPath.name }}</h1>
    <div class="h-1 flex-grow bg-gray-500"></div>
  </div>
  <Message severity="info" :closable="false" class="text-left">Select contributors from your Practice Group, then click Add Contributors. A contributor will be able to edit and save this SmartPath. This will also make this SmartPath publically visible on their profile for primary care to book with. In order for the specialist to be added, they must also be opted into SmartPath (contact a Virtual Hallway admin for this).</Message>

  <div v-if="this.smartPath.contributors.length > 0" class="w-full">
    <div class="text-xs uppercase font-display font-semibold text-gray-400 text-left border-b border-gray-400 p-1">Contributors</div>
    <div v-for="contributor in smartPath.contributors" :key="contributor.id" class="flex flex-row justify-between items-center font-display border-b border-gray-400 p-1">
      {{ contributor.full_name }}
      <Button label="Remove" class="p-button-icon-only p-button-text" @click="remove(contributor)" v-tooltip.left="'Remove contributor from this SmartPath'"> <i class="pi pi-times navbar-icon text-gray-400" style="font-size: 0.8rem !important"></i> </Button>
    </div>
  </div>
  <div class="mt-8">
    <div v-if="availableContributors.length > 0">
      <form method="post" @submit.prevent="preventEmpty">
        <div class="px-0 text-left mb-4">
          <label for="contributorsMultiselect" class="auth-input">Select contributors</label>
          <MultiSelect :class="['w-full']" v-model="selectedContributors" :options="availableContributors" name="contributorsMultiselect" required optionLabel="full_name" optionValue="id" :showToggleAll="false" :filter="true" display="chip" emptyMessage="Select SmartPath Contributors" />
        </div>
        <div class="flex flex-col md:flex-row justify-end items-end mb-3">
          <div><Button label="Add Contributors" @click="submit()"></Button></div>
        </div>
      </form>
    </div>
    <div class="text-sm felx flex-col mb-8" v-else>
      <p>There aren't any available {{ getReferralPersonTerm(actingUser) }}s in your practice groups to add to the SmartPath right now.</p>
      <p>Note: in order for a specialist to be eligible for SmartPath, they must be opted in by a Virtual Hallway admin.</p>
    </div>
  </div>

  <Loading z-index="99" v-model:active="isLoading" loader="spinner" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from 'primevue/button';
import MultiSelect from 'primevue/multiselect';
import Message from 'primevue/message';
import Loading from 'vue-loading-overlay';

export default {
  props: ['loggedInUser', 'smartPath'],
  components: {
    Button,
    MultiSelect,
    Message,
    Loading,
  },
  data() {
    return {
      displayDialog: false,
      user: this.loggedInUser,
      errors: [],
      selectedContributors: [],
      userSpecialtyIds: [],
      selectedUserSpecialtyId: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['availableContributors', 'selectedSmartPath', 'actingUser']),
  },
  methods: {
    ...mapActions(['getAvailableContributors', 'attachContributors', 'detachContributors']),
    remove(contributor) {
      this.isLoading = true;
      this.detachContributors({
        smart_path_id: this.smartPath.id,
        user_ids: [contributor.id],
      })
        .then(() => {
          this.getAvailableContributors(this.smartPath.id)
            .then(() => {
              this.isLoading = false;
              this.$toast.add({
                severity: 'success',
                summary: 'Contributors removed.',
                detail: 'SmartPath has been successfully updated.',
                life: 5000,
              });
            })
            .catch(() => {
              this.isLoading = false;
              this.showGenericErrorToast();
            });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    submit() {
      this.isLoading = true;
      this.attachContributors({
        smart_path_id: this.smartPath.id,
        user_ids: this.selectedContributors,
      })
        .then(() => {
          this.selectedContributors = [];
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Contributors added!',
            detail: 'SmartPath has been successfully updated.',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
  created() {
    this.isLoading = true;
    this.getAvailableContributors(this.smartPath.id)
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style></style>
