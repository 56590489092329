<template>
  <div v-if="!isLoading">
    <div class="builder-bar shadow">
      <div class="">
        <div class="save-button-wrapper flex w-full flex-row justify-end">
          <div v-if="isSmartPathAuthor(this.loggedInUser, this.selectedSmartPath)" class="mr-2">
            <InputText id="smartPathName" placeholder="SmartPath Title" v-model="selectedSmartPath.name" required maxLength="255" class="w-64" />
          </div>
          <div v-if="isSmartPathAuthor(this.loggedInUser, this.selectedSmartPath)" class="mr-2">
            <DropDown v-model="selectedSmartPath.status" :options="['Public', 'Private']" />
          </div>
          <div v-if="isSmartPathAuthor(this.loggedInUser, this.selectedSmartPath)" class="mr-2">
            <Button id="manageContributorsButton" label="Add Practice Group" @click="openManageOrgUnitsModal" />
          </div>
          <div v-if="isSmartPathAuthor(this.loggedInUser, this.selectedSmartPath) && selectedSmartPath.org_units != null && selectedSmartPath.org_units.length > 0" class="mr-2">
            <Button id="manageContributorsButton" label="Add Contributors" @click="openManageContributorsModal" />
          </div>
          <div v-if="isSmartPathAuthor(this.loggedInUser, this.selectedSmartPath)" class="mr-2">
            <Button id="editDescriptionButton" label="Edit Description" @click="openEditDescriptionModal" />
          </div>
          <div class="mr-2">
            <Button id="saveSmartPath" class="save-button" label="Save Pathway" @click="saveSmartPathOnClick"></Button>
          </div>
          <div>
            <Button id="saveAndQuitSmartPath" class="save-button" label="Save and Exit" @click="saveAndExitSmartPathOnClick"></Button>
          </div>
        </div>
      </div>
    </div>
    <div class="px-30 h-max tree-wrap my-16 w-full content-center overflow-scroll">
      <ul class="tree w-full">
        <SmartPathNode @addNode="addNode" @deleteNode="deleteNode" @updateNode="updateNode" @changeNodeType="changeNodeType" @uploadLabFile="uploadLabFile" @uploadForYourPatientFiles="uploadForYourPatientFiles" @previewLabFile="previewLabFile" @removeLabFile="removeLabFile" @removeForYourPatientFile="removeForYourPatientFile" :smartPath="smartPath" :curr="0" :key="Object.keys(smartPath).length" />
      </ul>
    </div>
  </div>

  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />

  <modal ref="editDescriptionModal">
    <template v-slot:header>
      <div class="flex w-full justify-center">
        <h1 class="pt-4 text-2xl font-bold text-red-400">
          <label class="auth-input">Please enter a description for</label>
          <div>{{ this.selectedSmartPath.name }}</div>
        </h1>
      </div>
    </template>
    <template v-slot:body>
      <div class="max-w-3xl px-24 py-2">
        <div class="text-centre pt-6 text-xl font-bold text-blue-400">
          <div class="col-start-1 col-end-3 mb-2">
            <label class="auth-input" for="smartpath_description">Pathway Description</label>
            <Textarea v-model="selectedSmartPath.description" class="auth-input" :autoResize="true" rows="10" cols="60" maxLength="65535" required id="smartpath_description" @keydown.tab.prevent="processTabs($event)"></Textarea>
          </div>
        </div>
        <div class="float-right flex flex-row">
          <div class="mr-2">
            <Button id="cancelSmartPathDescription" label="cancel" @click="cancelDescription" />
          </div>
          <div>
            <Button id="saveSmartPathDescription" class="save-button" label="Save and Close" @click="saveDescription" />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-s rounded-b py-2 pb-4"></div>
    </template>
  </modal>

  <modal ref="manageContributorsModal">
    <template v-slot:body>
      <div class="max-w-3xl px-24 py-2">
        <ManageContributors :loggedInUser="loggedInUser" :smartPath="selectedSmartPath" />
      </div>
    </template>
  </modal>

  <modal ref="manageOrgUnitsModal">
    <template v-slot:body>
      <div class="max-w-3xl px-24 py-2">
        <ManageOrgUnits :loggedInUser="loggedInUser" :smartPath="selectedSmartPath" />
      </div>
    </template>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from 'vue-loading-overlay';
import SmartPathNode from '@/components/specialist/smartpath/SmartPathNode.vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Modal from '@/components/misc/Modal.vue';
import Textarea from 'primevue/textarea';
import DropDown from 'primevue/dropdown';
import ManageContributors from '@/components/specialist/smartpath/ManageContributors.vue';
import ManageOrgUnits from '@/components/specialist/smartpath/ManageOrgUnits.vue';

export default {
  components: {
    Loading,
    SmartPathNode,
    Button,
    InputText,
    Modal,
    Textarea,
    DropDown,
    ManageContributors,
    ManageOrgUnits,
  },
  data() {
    return {
      smartPath: {},
      descriptionBackUp: '',
      validation_error: false,
      isLoading: true,
    };
  },
  created() {
    this.getSmartPath(this.$route.params.smartPathId)
      .then(() => {
        this.descriptionBackUp = this.selectedSmartPath.description;
        this.smartPath = JSON.parse(this.selectedSmartPath.smart_path);
        this.getLinkableSmartPaths({ user_id: this.actingUser.id, smart_path_id: this.selectedSmartPath.id })
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });

    this.getSpecialties({
      requesting_user_id: this.actingUser.id,
      province_name: this.actingUser.practice_province.name,
      interaction_type_names: ['phone'],
      for_referral: 1,
    })
      .then(() => {})
      .catch(() => {
        this.showGenericErrorToast();
      });
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isSpecialist', 'actingUser', 'selectedSmartPath']),
  },
  // save Smartpath if auto-logout triggers
  watch: {
    isAppIdle() {
      if (this.isAppIdle) {
        this.saveSmartPathOnClick();
      }
    },
  },
  methods: {
    ...mapActions(['getSmartPath', 'saveSmartPath', 'uploadSmartPathLabForm', 'uploadSmartPathForYourPatientFiles', 'previewSmartPathLabForm', 'removeSmartPathLabForm', 'removeSmartPathForYourPatientFile', 'getSpecialties', 'getLinkableSmartPaths']),
    addNode(event) {
      let id = Math.max(...Object.keys(this.smartPath)) + 1;
      this.smartPath[id] = { node_type: 'decision', parent_id: event.parent_id, children: [], question: '', recommended_action: '', additional_information: '', lab_access: '', for_your_patient_files: '', pointer: '', link: '', recommended_specialty: '', triage_label: '', vimeo_link: '', stat_visits: 0, stat_learn_more_clicks: 0, stat_lab_reqs: 0, stat_for_your_patient_download: 0, stat_total_time_spent_on_node: 0 };
      this.smartPath[event.parent_id]['children'].push({ next: id, answer: '' });
    },
    deleteNode(event) {
      // find where it lives
      let delete_index = -1;
      for (let i = 0; i < this.smartPath[event.parent_id]['children'].length; i++) {
        let child = this.smartPath[event.parent_id]['children'][i];
        if (child['next'] == event.id) {
          delete_index = i;
        }
      }

      if (delete_index !== -1) {
        // make it an orphan
        this.smartPath[event.parent_id]['children'].splice(delete_index, 1);
        // kill it
        delete this.smartPath[event.id];
      }
    },
    // emitting is stupid lol
    updateNode(event) {
      if (this.smartPath[event.id]['node_type'] != 'root') {
        for (let i = 0; i < this.smartPath[event.parent_id]['children'].length; i++) {
          let child = this.smartPath[event.parent_id]['children'][i];
          if (child['next'] == event.id) {
            this.smartPath[event.parent_id]['children'][i]['answer'] = event.answer;
          }
        }
      }

      if (event.question != null) {
        this.smartPath[event.id]['question'] = event.question;
      }

      if (event.recommended_action != null) {
        this.smartPath[event.id]['recommended_action'] = event.recommended_action;
      }

      if (event.lab_access != null) {
        this.smartPath[event.id]['lab_access'] = event.lab_access;
      }

      if (event.for_your_patient_files != null) {
        this.smartPath[event.id]['for_your_patient_files'] = event.for_your_patient_files;
      }

      if (event.additional_information != null) {
        this.smartPath[event.id]['additional_information'] = event.additional_information;
      }

      if (event.recommended_specialty != null) {
        this.smartPath[event.id]['recommended_specialty'] = event.recommended_specialty;
      }

      if (event.triage_label != null) {
        this.smartPath[event.id]['triage_label'] = event.triage_label;
      }

      if (event.vimeo_link != null) {
        this.smartPath[event.id]['vimeo_link'] = event.vimeo_link;
      }

      if (event.suggested_action != null) {
        this.smartPath[event.id]['suggested_action'] = event.suggested_action;
      }

      if (event.pointer != null) {
        this.smartPath[event.id]['pointer'] = Number(event.pointer);
      }

      if (event.link != null) {
        this.smartPath[event.id]['link'] = event.link;
      }

      // pass validation error up through this function onto the smartPath json so we don't have to re-validate
      this.smartPath[event.id]['validation_error'] = event.validation_error;
    },
    changeNodeType(event) {
      this.smartPath[event.id]['node_type'] = event.node_type;
    },
    saveSmartPathOnClick() {
      this.isLoading = true;

      this.validatePathway();

      let pathwayStatus = this.selectedSmartPath.status;
      let showSaveToast = this.selectedSmartPath.status;
      // if pathway is set to Public, but there are validation errors on the front end -
      // we set it to Private but still save it, sending them an error toast about the validation
      if (pathwayStatus == 'Public' && this.validation_error == true) {
        pathwayStatus = 'Private';
        // error toast
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: "There are errors in your pathway. The pathway can be found by hovering over a node's X. Please fix these errors before setting the pathway to Public. In the meantime, the pathway has been saved as Private.",
          life: 20000,
        });
      }

      let saveSmartPathData = {
        id: this.selectedSmartPath.id,
        name: this.selectedSmartPath.name,
        description: this.selectedSmartPath.description,
        smartPath: JSON.stringify(this.smartPath),
        status: pathwayStatus,
        version: this.selectedSmartPath.version,
      };
      this.saveSmartPath(saveSmartPathData)
        .then(() => {
          this.smartPath = JSON.parse(this.selectedSmartPath.smart_path);
          this.isLoading = false;
          if (showSaveToast == 'Private' || this.validation_error == false) {
            this.$toast.add({
              severity: 'success',
              summary: 'Pathway Saved!',
              detail: 'Pathway progress saved successfully!',
              life: 15000,
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
          if (showSaveToast == 'Private' || this.validation_error == false) {
            this.showGenericErrorToast();
          }
        });
    },
    saveAndExitSmartPathOnClick() {
      this.saveSmartPathOnClick();
      this.$router.push({ path: '/smartpaths' });
    },
    openEditDescriptionModal() {
      this.$refs.editDescriptionModal.openModal();
    },
    openManageContributorsModal() {
      this.$refs.manageContributorsModal.openModal();
    },
    openManageOrgUnitsModal() {
      this.$refs.manageOrgUnitsModal.openModal();
    },
    saveDescription() {
      this.saveSmartPathOnClick();
      this.descriptionBackUp = this.selectedSmartPath.description;
      this.$refs.editDescriptionModal.closeModal();
    },
    cancelDescription() {
      this.selectedSmartPath.description = this.descriptionBackUp;
      this.$refs.editDescriptionModal.closeModal();
    },
    validatePathway() {
      this.validation_error = false;
      // if we find a SmartPath node has a validation_error != '', we flag the Builder's validation_error as true
      for (let i = 0; i < Object.values(this.smartPath).length; i++) {
        if (Object.values(this.smartPath)[i].validation_error != '') {
          this.validation_error = true;
        }
      }
    },
    uploadLabFile(event) {
      this.isLoading = true;
      this.validatePathway();

      let pathwayStatus = this.selectedSmartPath.status;
      let showSaveToast = this.selectedSmartPath.status;
      // if pathway is set to Public, but there are validation errors on the front end -
      // we set it to Private but still save it, sending them an error toast about the validation
      if (pathwayStatus == 'Public' && this.validation_error == true) {
        pathwayStatus = 'Private';
        // error toast
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: "There are errors in your pathway. The pathway can be found by hovering over a node's X. Please fix these errors before setting the pathway to Public. In the meantime, the pathway has been saved as Private.",
          life: 20000,
        });
      }

      let saveSmartPathData = {
        id: this.selectedSmartPath.id,
        name: this.selectedSmartPath.name,
        description: this.selectedSmartPath.description,
        smartPath: JSON.stringify(this.smartPath),
        status: pathwayStatus,
        author_id: this.actingUser.id,
        version: this.selectedSmartPath.version,
      };
      this.saveSmartPath(saveSmartPathData)
        .then(() => {
          if (showSaveToast == 'Private' || this.validation_error == false) {
            this.$toast.add({
              severity: 'success',
              summary: 'Pathway Saved!',
              detail: 'Pathway progress saved successfully!',
              life: 15000,
            });
          }

          let smartPathLabFormData = {
            id: this.selectedSmartPath.id,
            ...event,
          };
          this.uploadSmartPathLabForm(smartPathLabFormData)
            .then(() => {
              this.smartPath = JSON.parse(this.selectedSmartPath.smart_path);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.showGenericErrorToast();
            });
        })
        .catch(() => {
          this.isLoading = false;
          if (showSaveToast == 'Private' || this.validation_error == false) {
            this.showGenericErrorToast();
          }
        });
    },
    uploadForYourPatientFiles(event) {
      this.isLoading = true;
      this.validatePathway();
      let pathwayStatus = this.selectedSmartPath.status;
      let showSaveToast = this.selectedSmartPath.status;
      // if pathway is set to Public, but there are validation errors on the front end -
      // we set it to Private but still save it, sending them an error toast about the validation
      if (pathwayStatus == 'Public' && this.validation_error == true) {
        pathwayStatus = 'Private';
        // error toast
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: "There are errors in your pathway. The pathway can be found by hovering over a node's X. Please fix these errors before setting the pathway to Public. In the meantime, the pathway has been saved as Private.",
          life: 20000,
        });
      }

      let saveSmartPathData = {
        id: this.selectedSmartPath.id,
        name: this.selectedSmartPath.name,
        description: this.selectedSmartPath.description,
        smartPath: JSON.stringify(this.smartPath),
        status: pathwayStatus,
        author_id: this.actingUser.id,
        version: this.selectedSmartPath.version,
      };
      this.saveSmartPath(saveSmartPathData)
        .then(() => {
          if (showSaveToast == 'Private' || this.validation_error == false) {
            this.$toast.add({
              severity: 'success',
              summary: 'Pathway Saved!',
              detail: 'Pathway progress saved successfully!',
              life: 15000,
            });
          }

          let smartPathForYourPatientFilesData = {
            id: this.selectedSmartPath.id,
            ...event,
          };
          this.uploadSmartPathForYourPatientFiles(smartPathForYourPatientFilesData)
            .then(() => {
              this.smartPath = JSON.parse(this.selectedSmartPath.smart_path);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.showGenericErrorToast();
            });
        })
        .catch(() => {
          this.isLoading = false;
          if (showSaveToast == 'Private' || this.validation_error == false) {
            this.showGenericErrorToast();
          }
        });
    },
    previewLabFile(event) {
      let smartPathLabFormData = {
        id: this.selectedSmartPath.id,
        ...event,
      };
      this.previewSmartPathLabForm(smartPathLabFormData).catch(() => {
        this.showGenericErrorToast();
      });
    },
    removeLabFile(event) {
      this.isLoading = true;
      let smartPathLabFormData = {
        id: this.selectedSmartPath.id,
        ...event,
      };

      this.removeSmartPathLabForm(smartPathLabFormData)
        .then(() => {
          this.smartPath = JSON.parse(this.selectedSmartPath.smart_path);
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Pathway Saved!',
            detail: 'Lab form removed!',
            life: 15000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    removeForYourPatientFile(event) {
      this.isLoading = true;
      let smartPathForYourPatientData = {
        id: this.selectedSmartPath.id,
        ...event,
      };
      this.removeSmartPathForYourPatientFile(smartPathForYourPatientData)
        .then(() => {
          this.smartPath = JSON.parse(this.selectedSmartPath.smart_path);
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Pathway Saved!',
            detail: 'File removed!',
            life: 15000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
};
</script>

<style scoped>
.tree {
  margin: 0 0 1em;
  text-align: center;
}
.tree {
  display: table;
}

.builder-bar {
  position: fixed;
  height: 60px;
  padding: 0.5em 0.5em 0em 0.5em;
  color: var(--vh-white);
  background-color: #ffffff;
  z-index: 99;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #b7c6dd;
}

.save-button-wrapper {
  float: right;
}

.save-button {
  background-color: var(--vh-black);
  border-color: var(--vh-black);
}

.tree-wrap {
  height: 100%;
  min-height: 100vh;
}
</style>
