import axios from 'axios';
import globals from '../../globals';

const state = {
  photoId: null,
  onboardingForm: JSON.parse(localStorage.getItem('onboardingForm')),
  actingUserOnboardingForm: JSON.parse(localStorage.getItem('actingUserOnboardingForm')),
  billingArrangement: null,
};

const getters = {
  photoId: (state) => {
    return state.photoId;
  },
  onboardingForm: (state) => {
    return state.onboardingForm;
  },
  actingUserOnboardingForm: (state) => {
    return state.actingUserOnboardingForm;
  },
  billingArrangement: (state) => {
    return state.billingArrangement;
  },
  isPreboarding: () => (user) => {
    if (user) {
      return user.status == 'Preboarding';
    } else {
      return null;
    }
  },
  isOnboarding: (state) => (user) => {
    if (user != null && state.onboardingForm != null) {
      return state.onboardingForm.status === 'Editing' || (user.status !== 'Active' && state.onboardingForm.status === 'Submitted') || state.onboardingForm.status === 'Incomplete';
    } else {
      return false;
    }
  },
  isActive: (state) => (user) => {
    return user?.status === 'Active';
  },
};

// actions
const actions = {
  uploadPhotoId(context, file) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.uploadPhotoIdURL(), file, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (context.rootGetters.loggedInUser && context.rootGetters.loggedInUser.id == response.data.user.id) {
            context.commit('updateLoggedInUser', response.data.user);
            context.commit('setOnboardingForm', response.data.onboarding_form);
          }
          if (context.rootGetters.actingUser && context.rootGetters.actingUser.id == response.data.user.id) {
            context.commit('updateActingUser', response.data.user);
            context.commit('setActingUserOnboardingForm', response.data.onboarding_form);
          }
          context.dispatch('checkIfUserIsAllowedToMakeReferral', response.data.user.id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadEula(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.agrreeToEulaURL(data.onboardingFormId), data.is_eula_agreed, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setOnboardingForm', response.data.data);
          context.dispatch('checkIfUserIsAllowedToMakeReferral');
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOnboardingForm(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getOnboardingFormURL(userId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          if (context.rootGetters.loggedInUser && context.rootGetters.loggedInUser.id == userId) {
            context.commit('setOnboardingForm', response.data.data);
          }
          if (context.rootGetters.actingUser && context.rootGetters.actingUser.id == userId) {
            context.commit('setActingUserOnboardingForm', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateBillingArrangement(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.updateBillingArrangementURL(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setBillingArrangement', response.data.data);
          if (context.rootGetters.loggedInUser && context.rootGetters.loggedInUser.id == response.data.user.id) {
            context.commit('updateLoggedInUser', response.data.user);
            context.commit('setOnboardingForm', response.data.onboarding_form);
            context.commit('setBillingArrangement', response.data.user.billing_arrangements[0]);
          }
          context.commit('updateActingUser', response.data.user);
          context.commit('setActingUserOnboardingForm', response.data.onboarding_form);
          context.dispatch('checkIfUserIsAllowedToMakeReferral', response.data.user.id);
          context.commit('setRequiresToUploadBillingArrangement', false);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  uploadBillingArrangement(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.uploadBillingArrangementURL(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setBillingArrangement', response.data.data);
          if (context.rootGetters.loggedInUser && context.rootGetters.loggedInUser.id == response.data.user.id) {
            context.commit('updateLoggedInUser', response.data.user);
            context.commit('setOnboardingForm', response.data.onboarding_form);
            context.commit('setRequiresToUploadBillingArrangement', false);
          }
          context.commit('updateActingUser', response.data.user);
          context.commit('setActingUserOnboardingForm', response.data.onboarding_form);
          context.dispatch('checkIfUserIsAllowedToMakeReferral', response.data.user.id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBillingArrangement(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getBillingArrangementURL(data), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setBillingArrangement', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPhotoId(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getPhotoIdURL(userId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setPhotoId', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadAbbaForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadAbbaFormUrl(data), {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: `BusinessArrangement.pdf` })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  downloadUnSignedBillingForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadUnSignedBillingArrangementFormUrl(data), {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = null;
          if (data.province === 'NS') {
            fileName = `MSI Provider Group Business Arrangement Form re Group 152756 Virtual_Hallway.pdf`;
          } else if (data.province === 'ON') {
            fileName = `OMHLTC Provider Group Business Arrangement Form re Group 152756 Virtual_Hallway.pdf`;
          } else {
            fileName = `Virtual Hallway Billing Form.pdf`;
          }
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  requestAdditionalReferralFormFields(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.requestAdditionalReferralFormFieldsUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setPhotoId(state, data) {
    state.photoId = data;
  },
  setOnboardingForm(state, data) {
    localStorage.setItem('onboardingForm', JSON.stringify(data));
    state.onboardingForm = data;
  },
  setActingUserOnboardingForm(state, data) {
    localStorage.setItem('actingUserOnboardingForm', JSON.stringify(data));
    state.actingUserOnboardingForm = data;
  },
  setBillingArrangement(state, data) {
    state.billingArrangement = data;
  },
  setOmhltcAgreement(state, data) {
    state.OmhltcAgreement = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
