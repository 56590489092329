<template lang="">
  <i class="pi pi-question-circle cursor-pointer" @click="openSupportSection"></i>
</template>
<script>
export default {
  props: ['openedSupportSectionKnowledgebaseAccordionIndex', 'openedSupportSectionAccountAccordionIndex'],
  methods: {
    openSupportSection() {
      this.$store.commit('setOpenedSupportSectionKnowledgebaseAccordionIndex', this.openedSupportSectionKnowledgebaseAccordionIndex);
      this.$store.commit('setOpenedSupportSectionAccountAccordionIndex', this.openedSupportSectionAccountAccordionIndex);
    },
  },
};
</script>
<style lang=""></style>
