<template>
  <Button v-if="user.bio || user?.practice_province.name !== loggedInUser?.practice_province.name" @click="openBio" v-on:click.stop label="Bio" icon="pi pi-external-link" class="p-button-outlined p-button-secondary p-button-sm hide-mobile text-xs" iconPos="right" style="position: absolute; right: 10px; top: 12px" />
  <Dialog :breakpoints="getDialogBreakPoints" :style="getDialogStyle" v-model:visible="show" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" class="p-0" id="specBio">
    <div class="sm:flex sm:flex-col md:grid md:grid-cols-6">
      <div :class="['flex flex-col items-center justify-center bg-secondary p-6 text-center text-white', user.bio ? 'col-span-2' : 'col-span-6']">
        <h2 class="text-lg font-bold">{{ displayNameByRole(user) }}</h2>
        <h2 class="mb-4 text-lg">{{ user.specialties[0].name }}</h2>
        <img v-bind:src="user.profile.profile_picture_location" class="relative z-20 mb-4 rounded-full border-4 border-solid border-white object-cover h-24 w-24" v-if="user.profile.profile_picture_location" />
        <p>{{ user.practice_province.name }}</p>
        <p>{{ user.university }}</p>
      </div>
      <div class="col-span-4 flex flex-col p-6">
        <div v-if="user.bio">
          <h1 class="mb-2 text-xl font-bold text-gray-500">Bio</h1>
          <hr class="mb-2" />
          <p class="mb-4 text-sm">{{ user.bio }}</p>
        </div>

        <div v-if="user.expertise_areas">
          <h1 class="text-xl font-bold text-gray-500 mb-2">Areas of Expertise</h1>
          <div class="flex flex-col items-start justify-start rounded-xl text-xs font-normal" style="height: 2.25rem">
            <div v-for="area in user.expertise_areas" class="flex flex-row items-center text-sm mb-2" :key="area.id">
              <i class="pi pi-star-fill text-sm text-left text-blue pr-2"></i>
              <p>{{ area.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { mapGetters } from 'vuex';

export default {
  inject: ['mixpanel'],
  components: {
    Button,
    Dialog,
  },
  props: ['user', 'selectedSpecialty'],
  data() {
    return {
      isLoading: false,
      show: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    getDialogBreakPoints() {
      return { '960px': '75vw', '640px': '90vw' };
    },
    getDialogStyle() {
      if (this.user.bio) {
        return { width: ' 900px' };
      }
      return { width: '40vw', maxWidth: '550px' };
    },
  },
  methods: {
    openBio() {
      this.show = true;
      this.mixpanel.track('Make Referral - Clicked View Bio', { specialty: this.selectedSpecialty.name, specialistId: this.user.id });
    },
    closeBio() {
      this.show = false;
    },
  },
};
</script>
<style>
#specBio .p-dialog-content {
  padding: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.p-dialog {
  border: none !important;
}
</style>
