<template>
  <div class="flex w-full justify-center" v-if="onboardingForm && loggedInUser">
    <div v-if="onboardingForm.is_eula_agreed" class="w-full">
      <router-link class="sidebar-router-link p-ripple group" v-ripple to="/verification" v-if="isOnboarding(loggedInUser) || isOrgUnitHead(loggedInUser)" @click="clickVerification">
        <div class="sidebar-router-label">Verification</div>
        <i class="pi pi-shield" v-badge.danger></i>
      </router-link>

      <router-link class="sidebar-router-link p-ripple group" v-ripple to="/makereferral" @click="clickBookAConsult">
        <div class="sidebar-router-label">Book a Consult</div>
        <i class="pi pi-id-card"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Ripple from 'primevue/ripple';

export default {
  inject: ['mixpanel'],
  emits: ['menu-toggle'],
  directives: {
    ripple: Ripple,
  },
  computed: {
    ...mapGetters(['loggedInUser', 'onboardingForm', 'isOnboarding', 'isOrgUnitHead', 'tutorialStatus', 'isSpecialist']),
  },
  methods: {
    ...mapMutations(['setTutorialStatus']),
    clickBookAConsult(event) {
      if (this.isOrgUnitHead(this.loggedInUser) && this.tutorialStatus !== '') {
        this.setTutorialStatus('org-select-user');
      }
      this.$emit('menu-toggle', event);
      this.mixpanel.track('Sidebar - Clicked Make Referral');
    },
    clickVerification(event) {
      if (this.isOrgUnitHead(this.loggedInUser) && this.tutorialStatus !== '') {
        this.setTutorialStatus('');
      }
      this.$emit('menu-toggle', event);
      this.mixpanel.track('Sidebar - Clicked Verification');
    },
  },
};
</script>

<style scoped>
i {
  font-size: 1.2rem !important;
}

.icon-grey {
  color: #859dc4;
}
</style>
