<template>
  <Dialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" v-model:visible="showModal" :modal="true" :dismissableMask="false" :draggable="false" :closable="false" style="max-width: 450px; width: 100%">
    <template #header>
      <h1 class="text-xl font-bold text-gray-900">{{ feedBackTitle }}</h1>
    </template>
    <div class="w-full"></div>
    <div class="flex w-full flex-col p-2">
      <span class="text-center !text-base w-full flex" :class="errors.starRating ? 'error' : ''"> How would you rate {{ feedBackSubTitle }}? <span class="error">&nbsp;*</span> </span>
      <div class="w-full justify-center">
        <Rating
          v-model="starRating"
          :cancel="false"
          :unstyled="true"
          :pt="{
            root: { class: 'flex flex-row w-full max-w-72 justify-between mx-auto mt-2' },
            item: { class: ' text-blue-500 cursor-pointer' },
          }"
        />
      </div>
      <span class="error pt-4 flex justify-start" v-if="errors.starRating">{{ errors.starRating }}</span>

      <div class="mt-8 text-md">
        <span class="text-sm"> Tell Us More (Optional) </span>
        <Textarea v-model="reviewText" class="auth-input" :autoResize="true" rows="5" cols="30" required maxLength="65535" />
      </div>
    </div>

    <template #footer>
      <!-- <Button label="Close" class="p-button-secondary p-button-outlined" @click="closeDialogOnClick" /> -->
      <div class="flex flex-row justify-end">
        <Button label="Submit" class="" @click="submit" />
      </div>
    </template>
  </Dialog>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Loading from 'vue-loading-overlay';
import Rating from 'primevue/rating';

export default {
  props: ['feedBackTitle', 'feedBackSubTitle', 'entityType', 'entityTypeId'],
  components: {
    Button,
    Dialog,
    Loading,
    Rating,
  },
  data() {
    return {
      starRating: 0,
      errors: {},
      reviewText: '',
      isLoading: false,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['createUserFeedback']),
    openDialog() {
      this.showModal = true;
    },
    closeDialogOnClick() {
      if (this.entityType === 'Summary') {
        const requestData = {
          user_id: this.loggedInUser.id,
          entity_type: this.entityType,
          entity_type_id: this.entityTypeId ? this.entityTypeId : null,
          meta_data: JSON.stringify({ starRating: null, reviewText: null }),
        };
        this.createUserFeedback(requestData)
          .then(() => {})
          .catch(() => {});
      }
      this.closeDialog();
    },
    closeDialog() {
      this.starRating = 0;
      this.reviewText = '';
      this.showModal = false;
    },
    submit() {
      this.errors = {};
      if (this.starRating === 0) {
        this.errors.starRating = 'Please select a rating to submit the feedback';
        return;
      }

      const requestData = {
        user_id: this.loggedInUser.id,
        entity_type: this.entityType,
        entity_type_id: this.entityTypeId ? this.entityTypeId : null,
        meta_data: JSON.stringify({ starRating: this.starRating, reviewText: this.reviewText }),
      };
      this.createUserFeedback(requestData)
        .then(() => {
          this.closeDialog();
          this.$toast.add({
            severity: 'success',
            summary: 'Feedback Sent',
            detail: 'Thank you for your feedback!',
            life: 5000,
          });
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
  },
};
</script>
<style></style>
