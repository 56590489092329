import axios from 'axios';
import globals from '../../globals';

const state = {
  summaries: [],
};

const getters = {
  summaries: (state) => {
    return state.summaries;
  },
};

const actions = {
  getSummariesByReferral(context, referral_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSummariesByReferralUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: { referral_id: referral_id },
        })
        .then((response) => {
          context.commit('setSummaries', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  regenerateSummary(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.regenerateSummaryUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getScribeStatus(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getScribeStatusUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setSummaries(state, data) {
    state.summaries = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
