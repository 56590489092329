<template>
  <div v-if="isLoggedIn">
    <div v-if="onboardingForm">
      <div class="alert-container" v-if="canMakeReferrals(loggedInUser) && !isSpecialist(loggedInUser) && onboardingForm.is_eula_agreed && numberRemaingReferrals !== null">
        <transition name="slide-up-fade">
          <div class="alert-text">
            <!-- <span>You can book {{ numberRemaingReferrals }} more phone consults before completing your
              <a href="/verification" class="under-line" id="account_verification_link">account verification</a>
            </span> -->
            <span class="flex flex-row items-center justify-center">
              <i class="pi pi-exclamation-circle mr-2"></i>
              Don't forget to
              <a @click="$router.push('/verification')" class="under-line ml-1 cursor-pointer" id="account_verification_link">Verify Your Account</a>
            </span>
            <span class="alert-close-btn cursor-pointer" onclick="this.parentElement.parentElement.style.display='none';">&times;</span>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters(['isLoggedIn', 'onboardingForm', 'loggedInUser', 'isEmailActivated', 'numberRemaingReferrals', 'actingUser', 'canMakeReferrals', 'isSpecialist']),
  },
  methods: {
    ...mapActions(['checkIfUserIsAllowedToMakeReferral']),
  },
  created() {
    if (this.isEmailActivated) {
      this.checkIfUserIsAllowedToMakeReferral(this.actingUser.id)
        .then(() => {})
        .catch(() => {});
    }
  },
  watch: {
    'loggedInUser.status'() {
      if (this.isEmailActivated) {
        if (this.loggedInUser.status === 'Onboarding') {
          this.checkIfUserIsAllowedToMakeReferral(this.actingUser.id)
            .then(() => {})
            .catch(() => {});
        }
      }
    },
  },
};
</script>

<style></style>
