<template>
  <div class="pt-mobile-inset animated backdrop-blur-xl bg-white/75 text-center text-lg font-semibold fixed top-0 z-10 border-b w-full" :class="[{ hidden: hasScrolled === null }, { 'fade-in-down': hasScrolled === true }, { 'fade-out-up': hasScrolled === false }]" v-if="isNativePlatform()">
    <div class="h-10">Clinical Note</div>
  </div>
  <div class="mt-20 w-full main-inner-wrap" :class="{ 'h-full': isFullNote && isNativePlatform(), 'h-[82vh]': !isFullNote && isNativePlatform() }" v-if="!isLoading">
    <div class="flex flex-row justify-start mx-auto mt-8 font-semibold cursor-pointer items-start w-full hover:text-gray-500 text-lg mb-4" :class="{ 'max-w-2xl': !isNativePlatform() }">
      <h1 @click="backToNotesOnClick" :class="{ 'pl-4': isNativePlatform() }">
        &lt; <span :class="!isNativePlatform() ? 'border-b-4 border-gray-600 border-solid hover:border-gray-500' : ''">{{ isNativePlatform() ? 'Notes' : 'Back to Notes' }}</span>
      </h1>
    </div>
    <div class="flex flex-col items-center w-full p-6 my-2 animated fade-in-up bg-white rounded-2xl h-full mx-auto shadow" :class="{ 'max-w-2xl': !isNativePlatform() }">
      <div class="flex flex-row items-center text-left w-full justify-between">
        <div class="flex flex-row w-full">
          <template v-if="!isComplete && displayIsFullNote">
            <div class="flex flex-col pr-2 mr-2 items-start border-r">
              <label class="auth-input w-14" style="font-size: 10px" for="isFullNoteId">Expand</label>
              <InputSwitch class="auth-input" v-model="isFullNote" id="isFullNoteId" />
            </div>
          </template>
          <div class="text-blue-400 w-full text-center">
            <h2 class="text-left font-semibold text-lg text-gray-600">Clinical Note</h2>
            <div class="flex flex-row w-full text-left">
              <h5 class="text-xs text-gray-500">Last Updated: {{ encounterNote.updated_at ? formatDateTime(encounterNote.updated_at) : formatDateTime(new Date()) }} {{ getUserTimeZoneAbbr() }}</h5>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-end pl-4">
          <template v-if="encounterNote.id != null">
            <div class="bg-secondary rounded-lg w-10 h-10 flex justify-center items-center" v-if="isNativePlatform()" @click="toggleMenu($event)" id="encounterNoteMenuButton">
              <i class="pi pi-chevron-down text-white" style="font-size: 1.4rem"></i>
            </div>
            <SplitButton label="Options" class="p-button-sm p-button-secondary mt-4" icon="pi pi-download" @click="toggleMenu($event)" :model="menuItems" v-else></SplitButton>
          </template>
        </div>
      </div>
      <div class="seperator-light"></div>
      <form v-auto-animate method="post" autocomplete="off" @submit.prevent="signOffOnClick" class="grid grid-cols-2 gap-1 gap-x-4 w-full" ref="form">
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label :class="['auth-input', { error: errors.patient_first_name }]" for="patient_first_name">Patient First Name </label>
          <InputText type="text" v-model="encounterNote.patient_first_name" class="auth-input" maxLength="100" id="patient_first_name" :disabled="isComplete" />
          <div class="error">{{ errors.patient_first_name }}</div>
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label :class="['auth-input', { error: errors.patient_last_name }]" for="patient_last_name"> Patient Last Name </label>
          <InputText type="text" v-model="encounterNote.patient_last_name" class="auth-input" maxLength="100" id="patient_last_name" :disabled="isComplete" />
          <div class="error">{{ errors.patient_last_name }}</div>
        </div>
        <template v-if="isFullNote">
          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label :class="['auth-input', { error: errors.patient_dob }]" for="patient_dob">Patient Date of Birth </label>
            <InputText type="text" class="auth-input" maxLength="100" id="patient_dob" :disabled="isComplete" v-if="isComplete" :value="formatDate(encounterNote.patient_dob)" />
            <VHCDatePicker :inputModel="encounterNote.patient_dob" :inputId="'patient_dob'" :maxInputDate="new Date()" @dateSelected="(val) => (encounterNote.patient_dob = val)" :displayAge="true" v-else />
            <div class="error">{{ errors.patient_dob }}</div>
          </div>
          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input" for="health_card_province" :class="[{ error: errors.health_card_province }]"> Health Card/Policy Type </label>
            <InputText type="text" class="auth-input" maxLength="100" id="health_card_province" :disabled="isComplete" v-if="isComplete" :value="encounterNote.health_card_province" />
            <Dropdown :class="['w-full text-left']" v-model="encounterNote.health_card_province" :options="healthCardProvinceOptions" optionValue="name" optionLabel="name" id="health_card_province" class="auth-input w-full text-xs font-normal text-gray-400" v-else />
            <div class="error">{{ errors.health_card_province }}</div>
          </div>
          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label :class="['auth-input', { error: errors.health_card_number }]" for="health_card_number"> Health Card/ID Number </label>
            <InputText type="text" v-model="encounterNote.health_card_number" class="auth-input" maxLength="255" id="health_card_number" :disabled="isComplete" />
            <div class="error">{{ errors.health_card_number }}</div>
          </div>
          <template v-if="encounterNote.health_card_province === 'Blue Cross'">
            <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
              <label :class="[{ error: errors.group_number }]" class="auth-input" for="group_number"> Policy/Group Number </label>
              <InputText type="text" v-model="encounterNote.group_number" class="auth-input" maxLength="255" id="group_number" :disabled="isComplete" />
              <div class="error">{{ errors.group_number }}</div>
            </div>
          </template>
          <div class="select-button-container col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input" :class="[{ error: errors.patient_sex }]" for="patient_sex">Patient Sex </label>
            <SelectButton v-model="encounterNote.patient_sex" :options="['Male', 'Female']" id="patient_sex" class="auth-input w-full" style="height: 50px" :disabled="isComplete" />
            <div class="error">{{ errors.patient_sex }}</div>
          </div>

          <div class="grid grid-cols-2 gap-1 gap-x-2 col-start-1 col-end-3">
            <div class="col-start-1 col-end-3 mb-2 flex flex-col" v-if="encounterNote && !isComplete">
              <label class="auth-input" for="diagnostic_code_unknown">Diagnostic Code Undetermined/Unknown:</label>
              <InputSwitch class="auth-input mt-1" id="diagnostic_code_unknown" v-model="diagnosticCodeUnknown" @click="toggleDiagnosticCodeDiagnosis" v-tooltip="'Try to search for the diagnostic code for the patient below. If you are unable to find it or it is unknown click this toggle and enter a diagnosis below.'" :disabled="isComplete" />
            </div>

            <div class="col-start-1 col-end-3 mb-2" v-if="encounterNote && !hasProvinceOfPractice(actingUser, ['ON']) && !diagnosticCodeUnknown">
              <label class="auth-input" :class="[{ error: errors.diagnostic_code }]" for="diagnostic_code">Diagnostic Code</label>
              <InputText type="text" class="auth-input" maxLength="100" id="diagnostic_code" :disabled="isComplete" v-if="isComplete" v-model="encounterNote.diagnostic_code.code_text" />
              <AutoComplete class="auth-input w-full" v-model="encounterNote.diagnostic_code" :suggestions="diagnosticCodes" field="code_text" @complete="searchDiagnosticCode($event)" inputId="selenium_diagnostic_code" id="diagnostic_code" placeholder="Start Typing And Select Option" :completeOnFocus="true" forceSelection v-else />
              <div class="error !text-left">{{ errors.diagnostic_code }}</div>
            </div>
            <div class="col-start-1 col-end-3 mb-2" v-else-if="encounterNote && hasProvinceOfPractice(actingUser, ['ON'])">
              <label class="auth-input" for="diagnostic_code" :class="[{ error: errors.diagnostic_code }]">OHIP Diagnostic Code<i class="pi pi-question-circle ml-2" v-tooltip.bottom="'Diagnostic codes are used to group and identify diseases, disorders, symptoms and other reasons for patient encounters. Use our diagnostic code lookup by typing the code, or spell out the diagnosis and a list of related codes will popup for you.'"></i></label>
              <InputText type="text" class="auth-input" maxLength="100" id="diagnostic_code" :disabled="isComplete" v-if="isComplete" v-model="encounterNote.diagnostic_code.code_text" />
              <AutoComplete class="auth-input w-full" v-model="encounterNote.diagnostic_code" :suggestions="filteredDiagnosticCodesOntario" field="code_text" @complete="searchDiagnosticCodeOntario($event)" inputId="diagnostic_code_ontario" placeholder="Start Typing And Select an Option" :completeOnFocus="true" v-else />
              <div class="error !text-left">{{ errors.diagnostic_code }}</div>
            </div>

            <div class="col-start-1 col-end-3 mb-2" v-if="hasProvinceOfPractice(actingUser, 'ON') || diagnosticCodeUnknown">
              <label class="auth-input" for="diagnosis" :class="[{ error: errors.diagnosis }]"> Diagnosis </label>
              <InputText v-model="encounterNote.diagnosis" class="auth-input" id="diagnosis" name="diagnosis" required placeholder="Please specify your diagnosis of the patient" :disabled="isComplete" />
              <div class="error">{{ errors.diagnosis }}</div>
            </div>
          </div>

          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input" for="consult_with_first_name" :class="[{ error: errors.consult_with_first_name }]">Encounter With First Name</label>
            <InputText type="text" v-model="encounterNote.consult_with_first_name" class="auth-input" maxLength="100" id="consult_with_first_name" :disabled="isComplete" />
            <div class="error">{{ errors.consult_with_first_name }}</div>
          </div>
          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input" for="consult_with_last_name" :class="[{ error: errors.consult_with_last_name }]">Encounter With Last Name </label>
            <InputText type="text" v-model="encounterNote.consult_with_last_name" class="auth-input" maxLength="100" id="consult_with_last_name" :disabled="isComplete" />
            <div class="error">{{ errors.consult_with_last_name }}</div>
          </div>
        </template>

        <div class="mt-3 grid grid-cols-3 gap-1 gap-x-2 col-start-1 col-end-3">
          <div class="col-span-1 mb-2">
            <DatePicker v-model="encounterNote.call_date" required :popover="{ visibility: 'focus' }" class="relative pb-2">
              <template v-slot="{ inputValue, inputEvents }">
                <label class="auth-input">Encounter Date</label>
                <InputText type="text" :value="inputValue" v-on="inputEvents" v-model="encounterNote.call_date" class="auth-input relative" required name="dateTimeInput" maxLength="55" id="dateTimeInput" :disabled="isComplete" />
              </template>
            </DatePicker>
            <div class="error">{{ errors.call_date }}</div>
          </div>
          <div class="relative col-span-1 mb-2 sm:col-auto">
            <DatePicker v-model="encounterNote.start_datetime" :popover="{ visibility: 'focus' }" class="relative" mode="time" is-required :input-debounce="100">
              <template v-slot="{ inputValue, inputEvents }">
                <label class="auth-input">Start Time </label>
                <InputText type="text" :value="inputValue" v-on="inputEvents" v-model="encounterNote.start_datetime" class="auth-input relative" required maxLength="55" id="callStartTime" :disabled="isComplete" />
              </template>
            </DatePicker>
            <div class="error">{{ errors.start_datetime }}</div>
          </div>
          <div>
            <DatePicker v-model="encounterNote.end_datetime" :popover="{ visibility: 'focus' }" class="relative" mode="time" is-required :input-debounce="100">
              <template v-slot="{ inputValue, inputEvents }">
                <label class="auth-input">End Time </label>
                <InputText type="text" :value="inputValue" v-on="inputEvents" v-model="encounterNote.end_datetime" class="auth-input relative" required maxLength="55" id="callEndTime" :disabled="isComplete" />
              </template>
            </DatePicker>
            <div class="error">{{ errors.end_datetime }}</div>
          </div>
        </div>
        <div class="col-start-1 col-end-3 mb-2">
          <label class="auth-input" for="referral_question"> Consult Note </label>
          <Textarea v-model="encounterNote.referral_question" class="auth-input" :autoResize="true" rows="8" cols="30" required maxLength="65535" id="encounter_note_input" @keydown.tab.prevent="processTabs($event)" :disabled="encounterNote.status !== 'Draft'"></Textarea>
        </div>

        <template v-if="!isComplete">
          <div class="w-full flex flex-row col-span-2" v-tooltip.top="{ value: 'Saving, Please Wait', disabled: !isLoadingSave && !isLoadingSubmit }">
            <Button label="Save" :icon="isLoadingSave ? 'pi pi-spin pi-spinner' : 'pi pi-save'" class="p-button-secondary col-span-1 mb-2 w-full mr-2" @click="saveNoteOnClick" name="saveButton" :disabled="isLoadingSave || isLoadingSubmit"></Button>
            <template v-if="encounterNote.status === 'Draft'">
              <Button label="Sign Off" :icon="isLoadingSubmit ? 'pi pi-spin pi-spinner' : 'pi pi-arrow-right'" iconPos="right" type="submit" name="signOffButton" class="col-start-2 col-end-3 mb-2 flex justify-center w-full" :disabled="isLoadingSave || isLoadingSubmit"> </Button>
            </template>
            <template v-else-if="['Signed Off'].includes(encounterNote.status) && isSpecialist(loggedInUser) && hasProvinceOfPractice(loggedInUser, ['NS']) && !hasShadowBilled(loggedInUser, referral)">
              <Button label="Submit For Billing" iconPos="right" name="submitForBillingButton" class="col-start-2 col-end-3 mb-2 flex justify-center w-full" @click="submitForBillingOnClick" v-tooltip="{ value: 'Please fill out the full note in order to bill for this consult.', disabled: isReadyForBilling }"> </Button>
            </template>
          </div>
        </template>
      </form>
      <div class="flex flex-row w-full">
        <AddendumsComponent :type="'encounter_note'" :referral="encounterNote" :addendums="encounterNoteAddendums" :responseForm="encounterNote.referral_response_form" />
      </div>
    </div>
  </div>

  <Dialog v-model:visible="openBillingArrangement" modal :show-header="false" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" style="max-width: 680px; width: 100%" :dismissable-mask="true">
    <MSI @closeBillingArrangementModal="closeBillingArrangementModal" />
  </Dialog>
  <TieredMenu ref="menu" :model="menuItems" :popup="true" />
  <FaxConsultDialog :show="showFaxConsultDialog" @closeFaxConsultDialog="showFaxConsultDialog = false" :referral="referral" :fax_document="fax_document" :documentId="faxDocumentId" :displayEditFaxOption="false" />
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />

  <Dialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :visible="showSubmitForBillingModal" :modal="true" :dismissableMask="true" :draggable="false" :closable="false" id="confirmSubmitNoteForBilling" style="max-width: 680px; width: 100%">
    <template #header>
      <div class="flex flex-row items-center">
        <h1 class="text-xl font-bold text-gray-700">Submit For Billing</h1>
      </div>
    </template>
    <div class="flex w-full max-w-lg flex-col">
      <div class="text-sm w-full max-w-lg px-4 pt-2 text-gray-600 text-left">
        <span class="font-semibold"> How It Works: </span>
        <ul class="list-disc px-4">
          <li class="pt-1"><span class="font-semibold">Note Requirements: </span> Your notes must be based on a phone call with another healthcare professional.</li>
          <li class="pt-1"><span class="font-semibold">Same Rate:</span> You will receive the same phone call rate for these notes as you do for your other calls.</li>
          <li class="pt-1"><span class="font-semibold">Monthly Payment:</span> Payments for your notes will be processed and issued on the first week of each month, just like our other billing services.</li>
        </ul>
      </div>
    </div>
    <template #footer>
      <div class="flex flex-row justify-end w-full">
        <div class="mr-2 w-full">
          <Button label="Cancel" icon="pi pi-ban" class="p-button-outlined p-button-danger justify-start !w-full" @click="showSubmitForBillingModal = false"></Button>
        </div>
        <div class="-mr-1 w-full">
          <Button @click="submitForBillingOnClick" :disabled="isLoadingSubmit" label="Submit" icon="pi pi-check-circle" id="confirmSubmitNoteForBillingButton" class="!w-full"></Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Loading from 'vue-loading-overlay';
import Modal from '@/components/misc/Modal.vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import AutoComplete from 'primevue/autocomplete';
import InputSwitch from 'primevue/inputswitch';
import TieredMenu from 'primevue/tieredmenu';
import SplitButton from 'primevue/splitbutton';
import FaxConsultDialog from '@/components/referral/FaxConsultDialog.vue';
import { DatePicker } from 'v-calendar';
import moment from 'moment';
import MSI from '@/views/user/onboarding/onboardingForm/MSI.vue';

import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  components: { Button, Loading, Dialog, Modal, Dropdown, InputText, AutoComplete, InputSwitch, DatePicker, TieredMenu, SplitButton, FaxConsultDialog, MSI },
  data() {
    return {
      isLoading: false,
      hasScrolled: null,
      isFullNote: false,
      patientDoB: null,
      encounterNote: {
        call_date: new Date(),
        start_datetime: new Date(),
        end_datetime: new Date(),
        is_signed_off: false,
        submit_for_billing: false,
        status: 'Draft',
      },
      diagnosticCodeUnknown: false,
      filteredDiagnosticCodesOntario: null,
      disableConsultNoteInput: false,
      submittedToBilling: false,
      showFaxConsultDialog: false,
      errors: {},
      openAddendum: false,
      displayIsFullNote: true,
      autoSaveTimer: null,
      baseMenuItemOptions: [
        {
          label: 'Download PDF',
          icon: 'pi pi-download',
          command: () => {
            this.downloadPdfOfCombinedForm(this.encounterNote.id)
              .then(() => {})
              .catch(() => {
                this.showGenericErrorToast();
              });
          },
        },
        {
          label: `Fax Note`,
          icon: 'pi pi-file-pdf',
          command: () => {
            this.fax_document = 'encounter_note';
            this.faxDocumentId = this.encounterNote.id;
            this.showFaxConsultDialog = true;
          },
        },
        {
          label: `New Note`,
          icon: 'pi pi-plus',
          command: () => {
            this.$router.push('/add-note');
          },
        },
      ],
      finishedMenuItemOptions: [
        {
          label: 'Download PDF',
          icon: 'pi pi-download',
          command: () => {
            this.downloadPdfOfCombinedForm(this.encounterNote.id)
              .then(() => {})
              .catch(() => {
                this.showGenericErrorToast();
              });
          },
        },
        {
          label: `Fax Note`,
          icon: 'pi pi-file-pdf',
          command: () => {
            this.fax_document = 'encounter_note';
            this.faxDocumentId = this.encounterNote.id;
            this.showFaxConsultDialog = true;
          },
        },
        {
          label: `Add Addendum`,
          icon: 'pi pi-pencil',
          command: () => {
            this.openAddendum = true;
          },
        },
        {
          label: `New Note`,
          icon: 'pi pi-plus',
          command: () => {
            this.$router.push('/add-note');
          },
        },
      ],
      menuItems: this.baseMenuItemOptions,
      isLoadingSave: false,
      isLoadingSubmit: false,
      openBillingArrangement: false,
      hasSubmittedBillingArrangement: false,
      showSubmitForBillingModal: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'allProvinces', 'actingUser', 'hasProvinceOfPractice', 'diagnosticCodes', 'diagnosticCodesOntario', 'referral', 'referralAddendums', 'selectedEncounterNoteId', 'isSpecialist', 'billingArrangement', 'getUserActionByName']),
    isReadyForBilling() {
      return this.isSignedOff && this.encounterNote.referral_question && this.encounterNote.patient_first_name && this.encounterNote.patient_last_name && this.encounterNote.patient_dob && this.encounterNote.health_card_province && this.encounterNote.health_card_number && this.encounterNote.health_card_version_number && this.encounterNote.group_number && this.encounterNote.patient_sex && (this.encounterNote.diagnostic_code || this.encounterNote.diagnosis) && this.encounterNote.consult_with_first_name && this.encounterNote.consult_with_last_name;
    },
    isComplete() {
      return this.encounterNote?.status === 'Complete';
    },
    isSignedOff() {
      return this.encounterNote?.status === 'Signed Off';
    },
    encounterNoteAddendums() {
      return this.referralAddendums.filter((addendum) => addendum.type === 'encounter_note');
    },
    showMakeAddendumButton() {
      return ['Signed Off', 'Complete'].includes(this.encounterNote.status) && !this.openAddendum;
    },
  },
  methods: {
    ...mapActions(['getAllProvinces', 'getReferralById', 'getDiagnosticCodes', 'getDiagnosticCodesOntario', 'createOrUpdateEncounterNote', 'downloadPdfOfCombinedForm', 'getReferralAddendumsByReferralId', 'createReferralAddendum', 'getBillingArrangement']),
    ...mapMutations(['setSelectedEncounterNoteId', 'setReferralAddendums']),
    backToNotesOnClick() {
      this.setSelectedEncounterNoteId(null);
      this.setReferralAddendums([]);
      this.$router.push('/notes');
    },
    handleScroll() {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      const heightToChange = 45;
      if (this.hasScrolled === null && scrollPosition > heightToChange) {
        this.hasScrolled = scrollPosition > heightToChange;
      } else if (this.hasScrolled !== null) {
        this.hasScrolled = scrollPosition > heightToChange;
      }
    },
    searchDiagnosticCode(event) {
      this.getDiagnosticCodes(event.query)
        .then(() => {})
        .catch(() => {});
    },
    searchDiagnosticCodeOntario(event) {
      this.getDiagnosticCodesOntario(event.query)
        .then(() => {
          setTimeout(() => {
            if (!event.query.trim().length) {
              this.filteredDiagnosticCodesOntario = [...this.diagnosticCodesOntario];
            } else {
              this.filteredDiagnosticCodesOntario = this.diagnosticCodesOntario.filter((diagnostic) => {
                return diagnostic.code_text.toLowerCase().includes(event.query.toLowerCase());
              });
            }
          }, 250);
        })
        .catch(() => {});
    },
    toggleDiagnosticCodeDiagnosis() {
      this.encounterNote.diagnostic_code = null;
      this.encounterNote.diagnosis = null;
    },
    saveNoteOnClick() {
      this.encounterNote.submit_for_billing = false;
      this.createOrUpdateEncounterNoteSubmit(true);
    },
    signOffOnClick() {
      this.encounterNote.submit_for_billing = false;
      this.encounterNote.is_signed_off = true;
      this.createOrUpdateEncounterNoteSubmit(true);
    },
    submitForBillingOnClick() {
      this.isFullNote = true;
      this.validateIsReadyForBilling();

      if (Object.keys(this.errors).length !== 0) {
        return;
      }

      if (!this.hasSubmittedBillingArrangement) {
        this.openBillingArrangement = true;
        this.encounterNote.submit_for_billing = false;
        this.createOrUpdateEncounterNoteSubmit(false);
        return;
      }

      if (!this.showSubmitForBillingModal && this.getUserActionByName(this.loggedInUser, 'submit_note_for_billing')?.status === 'Active') {
        this.showSubmitForBillingModal = true;
        return;
      }

      this.encounterNote.submit_for_billing = true;
      this.createOrUpdateEncounterNoteSubmit(true);
    },
    createOrUpdateEncounterNoteSubmit(displayToast) {
      this.encounterNote.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (this.encounterNote.patient_dob) {
        this.encounterNote.patient_dob = moment(this.encounterNote.patient_dob).format('YYYY-MM-DD');
      }

      if (this.encounterNote.call_date == null || isNaN(this.encounterNote.call_date)) {
        this.encounterNote.call_date = new Date();
      }
      if (this.encounterNote.start_datetime == null || isNaN(this.encounterNote.start_datetime)) {
        const dateTime = new Date();
        dateTime.setMilliseconds(0);
        dateTime.setSeconds(0);
        dateTime.setMinutes(Math.floor(dateTime.getMinutes() / 10) * 10);
        this.encounterNote.start_datetime = dateTime;
      }

      if (this.encounterNote.end_datetime == null || isNaN(this.encounterNote.end_datetime)) {
        const dateTime = new Date();
        dateTime.setMilliseconds(0);
        dateTime.setSeconds(0);
        dateTime.setMinutes(Math.floor(dateTime.getMinutes() / 10) * 10);
        this.encounterNote.end_datetime = dateTime;
      }

      this.isLoadingSave = !this.encounterNote.is_signed_off || this.encounterNote.status !== 'Draft';
      this.isLoadingSubmit = this.encounterNote.is_signed_off;
      this.resetAutoSaveTimer();

      const encounterNote = JSON.parse(JSON.stringify(this.encounterNote));

      let startDate = moment(encounterNote.start_datetime);
      let endDate = moment(encounterNote.end_datetime);
      let callDate = moment(encounterNote.call_date);

      let diffInMin = startDate.diff(endDate, 'minutes');
      startDate.year(callDate.year()).month(callDate.month()).date(callDate.date());
      endDate = moment(startDate);

      endDate.add(Math.abs(diffInMin), 'minutes');

      encounterNote.start_datetime = startDate.format('YYYY-MM-DD H:mm:ss');
      encounterNote.end_datetime = endDate.format('YYYY-MM-DD H:mm:ss');

      this.createOrUpdateEncounterNote(encounterNote)
        .then(() => {
          this.showSubmitForBillingModal = false;
          this.resetPatientDOB();
          this.$toast.removeAllGroups();
          if (displayToast) {
            if (this.referral.status === 'Complete') {
              this.$toast.add({
                severity: 'success',
                summary: 'Note Submitted to billing',
                detail: 'You successfully submitted this note for billing. Payment is sent the beginning of the month',
                life: 3000,
              });
            } else if (this.referral.status === 'Signed Off' && this.encounterNote.status !== 'Signed Off') {
              this.$toast.add({
                severity: 'success',
                summary: 'Note Signed Off',
                detail: 'You successfully signed off this note.',
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: 'success',
                summary: 'Note Saved',
                detail: 'You successfully saved this note.',
                life: 3000,
              });
            }
          }
          this.isLoadingSave = false;
          this.isLoadingSubmit = false;

          this.encounterNote.id = this.referral.id;
          this.encounterNote.status = this.referral.status;
          this.errors = {};
          this.setDisplayIsFullNote();

          if (!this.$route.params.id) {
            this.setSelectedEncounterNoteId(this.referral.id);
            this.$router.push(`/note/${this.referral.id}`);
          }

          if (['Signed Off', 'Complete'].includes(this.encounterNote.status)) {
            this.menuItems = this.finishedMenuItemOptions;
          }

          this.resetAutoSaveTimer();
          //It seems that when the encounter note is updated above it cause a rerender that hits the watcher
          //Stops it from saving after just submitting
          setTimeout(() => {
            this.resetAutoSaveTimer();
          }, 2500);
        })
        .catch(() => {
          this.isLoadingSave = false;
          this.isLoadingSubmit = false;

          this.showGenericErrorToast();
          this.resetAutoSaveTimer();
        });
    },
    validateIsReadyForBilling() {
      this.errors = {};
      if (this.encounterNote.patient_first_name == null || this.encounterNote.patient_first_name == '') {
        this.errors.patient_first_name = 'Patient first name is required.';
      }
      if (this.encounterNote.patient_last_name == null || this.encounterNote.patient_last_name == '') {
        this.errors.patient_last_name = 'Patient last name is required.';
      }
      if (this.encounterNote.patient_dob == null) {
        this.errors.patient_dob = 'Patient date of birth is required.';
      }
      if (this.encounterNote.health_card_number == null || this.encounterNote.health_card_number == '') {
        this.errors.health_card_number = 'Health Card/ID Number is required.';
      }
      if (!this.encounterNote.health_card_province) {
        this.errors.health_card_province = 'Health Card/Policy Type is required.';
      }
      if (this.encounterNote.patient_dob == null || this.encounterNote.patient_dob == '' || isNaN(new Date(this.encounterNote.patient_dob).getTime())) {
        this.errors.patient_dob = 'Patient date of birth is required.';
      }
      if (this.encounterNote.patient_sex !== 'Male' && this.encounterNote.patient_sex !== 'Female') {
        this.errors.patient_sex = 'Patient sex is required.';
      }
      if (this.encounterNote.health_card_province === 'Blue Cross' && (!this.encounterNote.group_number || this.encounterNote.group_number == '')) {
        this.errors.group_number = 'Group Number is required.';
      }
      if (!this.diagnosticCodeUnknown && !this.encounterNote.diagnostic_code?.code && !this.encounterNote.diagnostic_code?.text) {
        this.errors.diagnostic_code = 'A valid diagnostic code is required. Please select a code by typing in the Diagnosis Code field. If you can not find the correct code, please press the Diagnostic Code Undetermined/Unknown button to type the code.';
      } else if (this.diagnosticCodeUnknown && !this.encounterNote.diagnosis) {
        this.errors.diagnosis = 'Diagnosis is required.';
      }
      if (this.encounterNote.consult_with_first_name == null || this.encounterNote.consult_with_first_name == '') {
        this.errors.consult_with_first_name = 'Encounter with first name is required.';
      }
      if (this.encounterNote.consult_with_last_name == null || this.encounterNote.consult_with_last_name == '') {
        this.errors.consult_with_last_name = 'Encounter with last name is required.';
      }
      if (this.encounterNote.call_date == null || isNaN(this.encounterNote.call_date)) {
        this.errors.call_date = 'Call Date is required.';
      }
      if (this.encounterNote.start_datetime == null || isNaN(this.encounterNote.start_datetime)) {
        this.errors.start_datetime = 'Start Time is required.';
      }
      if (this.encounterNote.end_datetime == null || isNaN(this.encounterNote.end_datetime)) {
        this.errors.end_datetime = 'End Time is required.';
      }
    },
    resetPatientDOB() {
      this.patientDoB = this.referral.referral_form.patient_dob != null ? new Date(Date.parse(this.referral.referral_form.patient_dob + 'T00:00:00')).toUTCString() : null;
      this.encounterNote.patient_dob = this.patientDoB;
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    processTabs(event) {
      let textarea = document.getElementById(event.target.id);
      let start = textarea.selectionStart;
      textarea.value = textarea.value.substring(0, textarea.selectionStart) + '\t' + textarea.value.substring(textarea.selectionStart, textarea.value.length);
      event.target.selectionStart = event.target.selectionEnd = start + 1;
    },
    autoSaveEncounterNote() {
      this.encounterNote.is_signed_off = this.isComplete || this.isSignedOff;
      this.encounterNote.submit_for_billing = this.encounterNote.status === 'Complete';
      this.createOrUpdateEncounterNoteSubmit(false);
    },
    setAutoSaveEncounterNote() {
      this.resetAutoSaveTimer();
      this.autoSaveTimer = setTimeout(() => {
        this.autoSaveEncounterNote();
      }, 6000);
    },
    resetAutoSaveTimer() {
      if (this.autoSaveTimer) {
        clearTimeout(this.autoSaveTimer);
        this.autoSaveTimer = null;
      }
    },
    setDisplayIsFullNote() {
      if (this.encounterNote.patient_dob || this.encounterNote.health_card_province || this.encounterNote.health_card_number || this.encounterNote.health_card_version_number || this.encounterNote.group_number || this.encounterNote.patient_sex === 'Male' || this.encounterNote.patient_sex === 'Female' || this.encounterNote.diagnostic_code || this.encounterNote.diagnosis || this.encounterNote.consult_with_first_name || this.encounterNote.consult_with_last_name) {
        this.isFullNote = true;
        this.displayIsFullNote = false;
      }
    },
    closeBillingArrangementModal(event) {
      this.openBillingArrangement = event.show;
      this.hasSubmittedBillingArrangement = event.data != null;
      if (this.hasSubmittedBillingArrangement) {
        this.$toast.add({
          severity: 'success',
          summary: 'Billing Arrangement Submitted',
          detail: 'You are ready to submit the note to billing',
          life: 3000,
        });
      }
    },
    hasShadowBilled(user, referral) {
      if (referral?.referee?.id === user.id) {
        return referral?.billing?.referee_shadow_bill_export;
      } else if (referral?.referral_person?.id === user.id) {
        return referral?.billing?.referral_person_shadow_bill_export;
      }
      return false;
    },
  },
  created() {
    this.encounterNote.call_date = new Date();
    let startDate = new Date();
    startDate.setMilliseconds(0);
    startDate.setSeconds(0);
    startDate.setMinutes(Math.floor(startDate.getMinutes() / 10) * 10);
    this.encounterNote.start_datetime = startDate;
    let endDate = new Date();
    endDate.setMilliseconds(0);
    endDate.setSeconds(0);
    endDate.setMinutes(startDate.getMinutes() + 10);
    this.encounterNote.end_datetime = endDate;

    let promises = [];
    promises.push(this.getAllProvinces());
    promises.push(this.getBillingArrangement({ userId: this.loggedInUser.id, practiceProvinceId: this.loggedInUser.practice_province.id }));
    this.isLoading = true;
    if (this.$route.params.id) {
      promises.push(this.getReferralById(this.$route.params.id));
      promises.push(this.getReferralAddendumsByReferralId({ referral_id: this.$route.params.id }));
      this.setSelectedEncounterNoteId(this.$route.params.id);
    } else {
      this.setSelectedEncounterNoteId(null);
      this.setReferralAddendums([]);
    }
    Promise.all(promises)
      .then(() => {
        if (this.$route.params.id) {
          this.encounterNote = { ...this.referral, ...this.referral.referral_form, ...this.referral.referral_response_form };
          this.encounterNote.id = this.$route.params.id;
          this.patientDoB = this.referral.referral_form.patient_dob != null ? new Date(Date.parse(this.referral.referral_form.patient_dob + 'T00:00:00')).toUTCString() : null;
          this.encounterNote.patient_dob = this.patientDoB;

          this.encounterNote.call_date = moment(this.referral.start_datetime).toDate();
          this.encounterNote.start_datetime = moment(this.referral.start_datetime).toDate();
          this.encounterNote.end_datetime = moment(this.referral.end_datetime).toDate();

          if (this.encounterNote.referee.role === 'gp') {
            this.encounterNote.consult_with_first_name = this.encounterNote.referral_person_first_name;
            this.encounterNote.consult_with_last_name = this.encounterNote.referral_person_last_name;
          } else {
            this.encounterNote.consult_with_first_name = this.encounterNote.referee_first_name;
            this.encounterNote.consult_with_last_name = this.encounterNote.referee_last_name;
          }

          if (this.referral.diagnostic_code === null && this.referral.referral_response_form.diagnosis) {
            this.diagnosticCodeUnknown = true;
          }

          if (['Signed Off', 'Complete'].includes(this.encounterNote.status)) {
            this.menuItems = this.finishedMenuItemOptions;
          }

          this.setDisplayIsFullNote();
        }

        this.hasSubmittedBillingArrangement = this.billingArrangement != null && this.billingArrangement.status === 'Submitted';
        this.encounterNote.user_id = this.actingUser.id;
        this.healthCardProvinceOptions = this.allProvinces;
        this.healthCardProvinceOptions.push({ name: 'Canadian Armed Forces (CAF)' });
        this.healthCardProvinceOptions.push({ name: 'Royal Canadian Mounted Police (RCMP)' });
        this.healthCardProvinceOptions.push({ name: 'Immigration, Refugees and Citizenship Canada (IRCC)' });
        this.healthCardProvinceOptions.push({ name: 'Veterans Affairs Canada (VAC)' });
        if (this.hasProvinceOfPractice(this.actingUser, 'ON')) {
          this.healthCardProvinceOptions.push({ name: 'University Health Insurance Health Plan' });
        }
        this.healthCardProvinceOptions.push({ name: 'Blue Cross' });
        this.healthCardProvinceOptions.push({ name: 'Interim Federal Health Program' });
        this.healthCardProvinceOptions.push({ name: 'Other' });

        this.isLoading = false;
        this.resetAutoSaveTimer();

        this.$watch('encounterNote', {
          handler(val) {
            this.encounterNote.is_signed_off = this.isComplete || this.isSignedOff;
            this.encounterNote.submit_for_billing = this.encounterNote.status === 'Complete';
            this.setAutoSaveEncounterNote();
          },
          deep: true,
        });
      })
      .catch((error) => {
        this.resetAutoSaveTimer();
        this.isLoading = false;

        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          this.$router.push('/add-note');
        }
      });
  },
  mounted() {
    if (this.isNativePlatform()) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  beforeUnmount() {
    this.resetAutoSaveTimer();
    if (this.isNativePlatform()) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name && to.name.includes('AddEncounterNote')) {
        window.location.reload();
      }
    },
    'encounterNote.start_datetime'() {
      let startTime = moment(this.encounterNote.start_datetime);
      let endTime = moment(this.encounterNote.end_datetime);
      if (endTime.isSameOrBefore(startTime) || endTime.diff(startTime, 'hours') >= 2) {
        endTime = startTime.add(10, 'minutes');
        this.encounterNote.end_datetime = new Date(moment(endTime));
      }
    },
    'encounterNote.end_datetime'() {
      let startTime = moment(this.encounterNote.start_datetime);
      let endTime = moment(this.encounterNote.end_datetime);
      if (endTime.isSameOrBefore(startTime) || endTime.diff(startTime, 'hours') >= 2) {
        startTime = endTime.subtract(10, 'minutes');
        this.encounterNote.start_datetime = new Date(moment(startTime));
      }
    },
  },
};
</script>

<style>
.p-disabled,
.p-component:disabled {
  opacity: 0.8 !important;
}

.vc-time-picker .vc-time-header {
  display: none !important;
}
</style>
