<template>
  <!-- ================== -->
  <!-- This is deprecated -->
  <!-- ================== -->

  <div v-if="user">
    <form method="post" @submit.prevent="submit" class="py-4">
      <div class="grid grid-cols-2 gap-1 gap-x-3">
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="street">Street </label>
          <InputText v-model="user.address.street" class="auth-input" required id="street" maxLength="255" />
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="city">City </label>
          <InputText v-model="user.address.city" class="auth-input" id="city" required maxLength="255" />
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="postalCode">Postal Code </label>
          <InputText v-model="user.address.postal_code" :class="['auth-input', { 'p-invalid': postalCodeError }]" required id="postalCode" @change="validatePostalCode" maxLength="6" />
          <span class="error" v-if="postalCodeError"> Please Enter Postal Code with Format A1A1A1 </span>
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input" for="province">Province </label>
          <Dropdown class="auth-input" v-model="user.address.province" :options="allProvinces" id="province" required />
        </div>
      </div>
      <div class="mt-4 flex justify-end"><Button label="Confirm Changes" icon="pi pi-check" type="submit" id="confirmChangesButton"></Button></div>
    </form>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions } from 'vuex';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';
import Dropdown from 'primevue/dropdown';

export default {
  props: ['loggedInUser'],
  emits: ['selectInfo'],
  data() {
    return {
      user: this.loggedInUser,
      isLoading: false,
      postalCodeRegex: /([A-Za-z][0-9]){3}/,
      postalCodeError: false,
      allProvinces: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'],
    };
  },
  methods: {
    ...mapActions(['updateUserAccountInfo']),
    submit() {
      this.validatePostalCode();
      if (!this.postalCodeError) {
        this.$toast.removeAllGroups();
        this.isLoading = true;
        this.updateUserAccountInfo(this.user)
          .then(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Account Updated',
              detail: 'Account information has been successfully updated.',
              life: 5000,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          });
      } else {
        this.isLoading = false;
      }
    },
    validatePostalCode() {
      if (this.user.postal_code === null) {
        this.postalCodeError = true;
      } else {
        this.user.address.postal_code = this.user.address.postal_code.replace(/\s+/g, '');
        if (this.user.address.postal_code.length === 6 && this.postalCodeRegex.test(this.user.address.postal_code)) {
          this.postalCodeError = false;
        } else {
          this.postalCodeError = true;
        }
      }
    },
  },
  components: { InputText, Button, Loading, Dropdown },
};
</script>

<style></style>
