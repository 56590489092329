<template>
  <div :class="animation" class="bg-white rounded-xl shadow p-8 w-full max-w-lg">
    <h1 class="text-left text-blue text-xl font-bold">Are you a...</h1>
    <div class="seperator-small mt-4 mb-4"></div>
    <h4 class="text-left text-gray-600 text-sm mb-6"></h4>
    <div class="">
      <div v-for="(physicianType, index) in physicianTypeOptions" :key="index" @click="setPhysicianType(physicianType)" class="select-button border-hover--blue">
        <span>{{ physicianType.name }}</span
        ><i class="pi pi-caret-right" />
      </div>
    </div>
    <div class="flex flex-row justify-left items-center align-center">
      <Button label="Back" @click="goToPrevious()" iconPos="left" class="p-button-text p-buttom-sm p-button-secondary" icon="pi pi-caret-left" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from 'primevue/button';

export default {
  components: {
    Button,
  },
  props: ['loggedInUser'],
  emits: ['goToNext', 'goToPrevious'],

  data() {
    return {
      animation: 'animate-in',
      user: this.loggedInUser,
      physicianTypeOptions: [
        { name: 'Family Physician', value: 'gp' },
        { name: 'Specialist Physician', value: 'specialist' },
        { name: 'Nurse Practitioner', value: 'gp' },
        { name: 'Pharmacist', value: 'cme' }, // TODO: Hugh pharmacist check
        { name: 'Resident Physician', value: 'org_unit_head' },
        { name: 'Administrator/Manager', value: 'org_unit_head' },
        { name: "Other (I'm here to watch CMEs)", value: 'cme' },
      ],
    };
  },
  computed: {
    ...mapGetters(['isGP', 'isSpecialist', 'allProvinces']),
  },
  created() {},
  methods: {
    setPhysicianType(physicianType) {
      this.user.role = physicianType.value;
      this.user.is_submitted = false;
      if (physicianType.name == 'Nurse Practitioner') {
        this.user.provider_type = 'NP';
      } else if (physicianType.name == 'Pharmacist') {
        this.user.provider_type = 'Pharmacist';
        // All individual hallway users can book within their hallway.
        if (this.isPartOfIndividualHallway(this.user)) {
          this.user.role = 'gp';
        }
      }
      this.$emit('goToNext', this.user);
    },
    goToPrevious() {
      this.$emit('goToPrevious', this.user);
    },
  },
};
</script>

<style></style>
