<template>
  <div class="main-inner-wrap">
    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block"><i class="pi pi-star mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div v-if="isOrgUnitHead(loggedInUser)" class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Set Consultant Availability</h1>
        <h1 class="text-sm text-gray-200">Please select the consultant whose availability you wish to view, then select the dates and times they are available to be booked for consults.</h1>
      </div>
      <div v-else class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Specialist Preferences</h1>
        <h1 class="text-sm text-gray-200">Adjust your account preferences below, then click Confirm Changes.</h1>
      </div>
    </div>

    <div :class="['bg-white', 'rounded-lg', 'w-full shadow', 'max-w-screen-lg', 'my-4', 'animated', 'fade-in-up', 'p-2', 'referral-tooltip', { 'in-front': isTutorial }]" v-if="!isLoading && isOrgUnitHead(loggedInUser)">
      <ChangeActingUserDropdown titleText="Setting Availability Of:" :selectedUser="actingUser" v-if="isOrgUnitHead(loggedInUser)" :orgUnitUsers="orgUnitUsers" @actingUserChanged="updateViewedAvailability" />
      <div class="px-6" v-if="isOrgUnitHead(loggedInUser) && orgUnitUsers && orgUnitUsers.length === 0">
        <Message severity="info" class="w-full text-center"> You do not have any consultant in your practice group. Add new users in the users tab. </Message>
      </div>
      <template v-if="tutorialStatus == 'org-select-user'">
        <div v-if="tutorialFit()" class="referral-tooltiptext org-schedule-select-user-tooltip py-4 px-6">
          <h1 class="text-left text-xl font-bold text-white">
            <div>Choose a User</div>
          </h1>
          <br />
          <div class="text-left font-bold text-white">
            <p>Select the user you would like to set availability for.</p>
          </div>
          <br />
          <div class="flex flex-row justify-between">
            <button class="text-xs font-bold text-white" @click="endSpecialistTutorial" id="skipSpecialistTutorialBtnUpper">Skip Tutorial</button>
            <div class="text-xs font-bold text-white">1/3</div>
          </div>
        </div>
      </template>
    </div>
    <div class="animated fade-in-up my-4 w-full max-w-screen-lg rounded-lg bg-white p-6 shadow">
      <div class="mb-6">
        <EditSpecialistPreference :loggedInUser="loggedInUser" />
      </div>
    </div>
  </div>
  <div>
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
  </div>
  <modal ref="inPersonReferralOptInCongratsModal" v-if="tutorialFit()">
    <template v-slot:header></template>
    <template v-slot:body>
      <div class="w-full max-w-lg">
        <div class="h-52 w-full overflow-hidden rounded-t-xl object-contain">
          <img src="@/assets/SpecialistWelcomeImage.jpeg" />
        </div>
      </div>
      <div class="flex w-full max-w-lg justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <div class="w-full">
            <h1 class="text-2xl font-bold">Here's to Making Wait Lists Shorter! &#127881;</h1>
          </div>
          <div class="text-md w-full max-w-lg px-4 pt-2 font-semibold text-gray-500">
            <div class="">
              Click the "Start Tour" button and we'll show you everything you need to know about accepting Referrals!
              <br />
            </div>
            <div class="mb-2 flex flex-col items-center justify-between py-4 pb-4 md:flex-row">
              <div class="w-full pr-1">
                <Button @click="setupFormOnClick" label="Setup Form" class="p-button-outlined p-button-secondary flex justify-center" style="width: 100% !important" />
              </div>
              <div class="flex w-full justify-center pl-1 text-center">
                <Button @click="startInPersonReferralTour" class="flex justify-center" style="width: 100% !important"> Start Tour </Button>
              </div>
            </div>
          </div>
          <button class="font-bold text-gray-400" @click="$refs.inPersonReferralOptInCongratsModal.closeModal()">Skip Tutorial</button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import Message from 'primevue/message';
import EditSpecialistPreference from '@/components/my_account/EditSpecialistPreference.vue';
import ChangeActingUserDropdown from '@/components/org_unit/ChangeActingUserDropdown.vue';
import Modal from '@/components/misc/Modal.vue';
import Button from 'primevue/button';

export default {
  data() {
    return {
      error: '',
      daysOfTheWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      selectedSchedule: null,
      selectedIsRecurring: false,
      dayOff: null,
      selectedUser: null,
      isLoading: false,
      isOnDemand: false,
    };
  },
  computed: {
    ...mapGetters(['actingUser', 'loggedInUser', 'isOrgUnitHead']),
  },
  components: {
    EditSpecialistPreference,
    Modal,
    Button,
    Message,
    ChangeActingUserDropdown,
  },
  methods: {
    ...mapActions(['getUpcomingScheduleOfUserForSpecialist', 'setSelectedUserToUpdateSchedule', 'groupSameDateSchedules', 'getOrgUnitSpecialists', 'bookDemoAsReferralPerson']),
    ...mapMutations(['setActingUser', 'setTutorialStatus']),
    compareYear(a, b) {
      return a - b;
    },
    endSpecialistTutorial() {
      this.setTutorialStatus('');
    },
    goToAddSchedule(event) {
      this.selectedIsRecurring = event.selectedIsRecurring;
      this.dayOff = event.dayOff;
      if (event.selectedIsRecurring) {
        this.selectedSchedule = null;
        for (let scheduleDay of this.filterRecurring) {
          if (this.formatDateTimeToWeekday(scheduleDay[0].start_datetime).toString() === event.dates) {
            this.selectedSchedule = scheduleDay;
          }
        }
        if (this.selectedSchedule === null) {
          this.selectedSchedule = this.getNextDayOfWeek(event.dates);
        }
      } else {
        this.selectedSchedule = event.dates;
      }
      this.$refs.addSchedule.openAddScheduleModal();
    },
    goToDeleteSchedule(event) {
      this.selectedIsRecurring = event.selectedIsRecurring;
      this.dayOff = event.dayOff;
      if (event.selectedIsRecurring) {
        this.selectedSchedule = null;
        for (let scheduleDay of this.filterRecurring) {
          if (this.formatDateTimeToWeekday(scheduleDay[0].start_datetime).toString() === event.dates) {
            this.selectedSchedule = scheduleDay;
          }
        }
      } else {
        this.selectedSchedule = event.dates;
      }
      this.$refs.confirmDeleteSchedule.openDeleteScheduleModal();
    },
    closeModal() {
      this.selectedSchedule = null;
      this.$toast.removeAllGroups();
      this.getUpcomingScheduleOfUserForSpecialist(this.actingUser.id)
        .then((res) => {
          if (res.status == 200) {
            this.groupSameDateSchedules(this.schedules);
          }
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        });
    },
    getNextDayOfWeek(dayOfWeek) {
      let today = moment().toDate();
      let nextDayOfWeek = moment().isoWeekday(dayOfWeek).add(1, 'hours').toDate();
      if (nextDayOfWeek.getTime() < today.getTime()) {
        nextDayOfWeek = moment(nextDayOfWeek).add(1, 'weeks').toDate();
      }
      return [
        {
          start_datetime: new Date(nextDayOfWeek.setHours(8, 0, 0, 0)),
          end_datetime: new Date(nextDayOfWeek.setHours(18, 0, 0, 0)),
        },
      ];
    },
    isDayInSchedule(dayOfWeek) {
      let recurringSchedules = this.filterRecurring.find((schedules) => this.formatDateTimeToWeekday(schedules[0].start_datetime).toString() === dayOfWeek);
      return recurringSchedules;
    },
    setupSchedules() {
      this.isLoading = true;
      this.setSelectedUserToUpdateSchedule(this.actingUser)
        .then(() => {
          this.getUpcomingScheduleOfUserForSpecialist(this.actingUser.id)
            .then((res) => {
              if (res.status == 200) {
                this.groupSameDateSchedules(this.schedules);
                this.isLoading = false;
              }
            })
            .catch(() => {
              this.showGenericErrorToast();
              this.isLoading = false;
            });
        })
        .catch(() => {
          this.showGenericErrorToast();
          this.isLoading = false;
        });
    },
    updateViewedAvailability() {
      if (this.actingUser.status == 'Active') {
        this.setupSchedules();
      } else {
        this.$router.push('/verification');
      }
      if (this.tutorialStatus !== '') {
        this.setTutorialStatus('set-availability');
      }
    },
    addAvailabilityTutorialOnClick() {
      if (this.isOrgUnitHead(this.loggedInUser)) {
        this.setTutorialStatus('org-select-user');
      } else {
        this.setTutorialStatus('set-availability');
      }
    },
  },
  created() {
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.isLoading = true;
      this.getOrgUnitSpecialists(this.loggedInUser.org_units[0].id)
        .then(() => {
          this.setActingUser(null);
          this.isLoading = false;
        })
        .catch(() => {
          this.showGenericErrorToast();
          this.isLoading = false;
        });
    }
  },
};
</script>

<style>
.time-divider {
  @apply border-l border-solid border-gray-200;
}
.border-bottom {
  @apply border-b border-solid border-gray-200;
}
.date-card:hover .date-card-icon {
  visibility: visible;
}
.schedule-grid {
  grid-template-columns: repeat(auto-fit, minmax(150px, 160px));
  justify-items: center;
}
.referral-tooltip {
  position: relative;
}

.referral-tooltip .referral-tooltiptext {
  width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  top: 10px;
  left: -130px;
  margin-left: -70px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.referral-tooltip .referral-tooltiptext.org-schedule-select-user-tooltip {
  top: 0px;
}

.referral-tooltip .referral-tooltiptext-lower {
  width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 580px;
  left: -130px;
  margin-left: -70px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.referral-tooltip .referral-tooltiptext::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 102%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.referral-tooltip .referral-tooltiptext-lower::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 102%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.specialist-tooltip-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.in-front {
  position: relative;
  z-index: 100;
}
</style>
