<template>
  <div class="main-inner-wrap vld-parent" v-if="isLoading">
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
  </div>
  <div class="main-inner-wrap" v-if="!isLoading">
    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block"><i class="pi pi-list mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">My Consults</h1>
        <h1 class="text-sm text-gray-200">
          Search the table below for the consult you're looking for, then click the <i class="pi pi-eye mx-1 mb-2 text-white" style="font-size: 0.8rem" /> icons to view the consult, or the <i class="pi pi-ellipsis-v mx-1 mb-2 text-white" style="font-size: 0.8rem" /> icons to download a PDF of the initial Consult Request, or the Consult Report.
          <span class="text-sm text-gray-200" v-if="(isGP(loggedInUser) || isSpecialist(loggedInUser)) && loggedInUser.org_units.length > 0"> To share consults with your Practice Group, click the <i class="pi pi-elipsis-v mb-2 text-white" style="font-size: 0.8rem" /> icons, then select the Practice Group you wish to share it with.</span>
        </h1>
      </div>
    </div>
    <div v-if="allConsults.length < 1">
      <p class="mt-12">
        <span v-if="isGP(loggedInUser)">
          You do not have any consultations. Click on the
          <span class="p-text-bold">Book a Consult</span>
          button in the top right corner to schedule your first consultation.
        </span>
        <span v-else-if="isSpecialist(loggedInUser)">
          You do not have any consultations. Click on the
          <span class="p-text-bold">Availability</span>
          button in the top right corner to add availability.
        </span>
        <span v-else> No consultations to show. </span>
      </p>
    </div>
    <div class="w-full pt-4" v-else>
      <ReferralList :referrals="allConsults" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferralList from '@/components/referral/ReferralList.vue';

import Loading from 'vue-loading-overlay';

export default {
  components: {
    ReferralList,
    Loading,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'allReferrals', 'isGP', 'isSpecialist', 'isOrgUnitHead', 'actingUser']),
    allConsults() {
      return this.allReferrals.filter((referral) => referral.interaction_type.name !== 'encounter_note');
    },
  },
  methods: {
    ...mapActions(['getAllReferralsOfUser', 'getReferralByOrgUnitId']),
  },
  created() {
    this.isLoading = true;
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.getReferralByOrgUnitId(this.loggedInUser.org_units[0].id)
        .then(() => (this.isLoading = false))
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    } else {
      this.getAllReferralsOfUser({
        user_id: this.actingUser.id,
        required_relations: ['referral_form:id,referral_id,patient_first_name,patient_last_name,is_signed_off', 'interaction_type', 'specialty', 'org_units'],
      })
        .then(() => (this.isLoading = false))
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    }
  },
};
</script>

<style></style>
