<template>
  <modal ref="addScheduleModal" @closeModal="closeModal">
    <template v-slot:header>
      <div class="header mb-2 p-4">
        <div>
          <!-- <Button @click="closeAddScheduleModal" class="p-button-sm p-button-secondary"> Close </Button> -->
          <Button label="Close" icon="pi pi-times" class="p-button-secondary p-button-outlined p-button-sm" @click="closeAddScheduleModal" />
        </div>
      </div>
    </template>

    <template v-slot:body>
      <div :class="isNativePlatform() ? 'pb-10' : ''">
        <div v-if="isEditing" class="px-10">
          <Message severity="info">
            Updating the availability for
            <template v-if="selectedSchedule[0].is_recurring"> {{ formatDateTimeToWeekday(selectedSchedule[0].start_datetime) }}s </template>
            <template v-else>
              {{ formatDate(selectedSchedule[0].start_datetime) }}
            </template>
          </Message>
        </div>
        <SetSchedule v-if="selectedScreen == 'setSchedule'" :user="selectedUserToUpdateSchedule" @goToConfirmAddSchedule="goToConfirmAddSchedule" :selectedSchedule="selectedSchedule" :selectedIsRecurring="selectedIsRecurring" :dayOff="dayOff" ref="setSchedule" />
        <ConfirmAddSchedule v-if="selectedScreen == 'confirmAddSchedule'" :user="selectedUserToUpdateSchedule" :newSchedules="newSchedules" :selectedDate="selectedDate" :selectedSchedule="selectedSchedule" :existingSchedules="existingSchedules" :isRecurring="isRecurring" :isSmartPath="isSmartPath" :dayOff="dayOff" @closeAddScheduleModal="closeAddScheduleModal" />
      </div>
    </template>
    <template v-slot:footer> </template>
  </modal>

  <modal ref="endSpecialistTutorialModal" v-if="tutorialFit()">
    <template v-slot:header></template>
    <template v-slot:body>
      <div class="w-full max-w-lg">
        <div class="h-52 w-full overflow-hidden rounded-t-xl object-contain">
          <img src="@/assets/SpecialistWelcomeImage.jpeg" />
        </div>
      </div>
      <div class="flex w-full max-w-lg justify-center rounded-b-xl px-8 py-4 text-center">
        <div class="flex w-full max-w-lg flex-col">
          <div class="w-full">
            <h1 class="text-2xl font-bold">You have successfully set your availability!</h1>
          </div>
          <div class="text-md w-full max-w-lg px-4 pt-2 font-semibold text-gray-500">
            <div class="text-center font-bold">
              <template v-if="loggedInUser.status == 'Active'">Primary care providers can now book phone consults with you during your set dates and times. </template>
              <template v-else>Congrats, you are one step closer to your first Virtual Hallway Consult!</template>
              <br />
              <br />
              Revisit this page anytime to modify your schedule.
              <br />
            </div>
            <div class="flex w-full flex-col py-4 pb-4 text-center md:flex-row">
              <div :class="['mb-2 flex flex-col items-center pt-4 md:flex-row w-full', { 'justify-center': loggedInUser.provider_type !== 'FFS' }, { 'justify-between': loggedInUser.provider_type === 'FFS' }]">
                <div v-if="loggedInUser.provider_type === 'FFS'" class="mr-0 md:mr-2">
                  <Button @click="$router.push({ path: `/performance` })" label="Complete Billing Form" id="completeBillingForm" class="p-button-outlined p-button-secondary m-2 w-full" v-tooltip.bottom="'Complete the provincial billing form to receive payment for consults.'" />
                </div>
                <div>
                  <Button @click="bookDemoAsReferralPersonOnClick" label="Book Demo Consult" id="bookDemoConsultBtn" class="m-2 w-full" v-tooltip.bottom="'Book a demo consult to see what a consult looks like. You will receive all notifications as if this was a real consult. We will remove it from your calendar in 7 days.'" />
                </div>
              </div>
            </div>
            <button class="text-sm font-bold text-gray-400" @click="completeSpecialistTutorial" id="skipTutorialBtn">Skip Tutorial</button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import SetSchedule from '@/views/user/specialist/schedule/AddSchedule/SetSchedule.vue';
import ConfirmAddSchedule from '@/views/user/specialist/schedule/AddSchedule/ConfirmAddSchedule.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Button from 'primevue/button';
import modal from '@/components/misc/Modal.vue';

export default {
  props: ['selectedSchedule', 'selectedIsRecurring', 'dayOff', 'isEditing'],
  emits: ['closeModal'],
  components: {
    SetSchedule,
    ConfirmAddSchedule,
    Button,
    modal,
  },
  data() {
    return {
      selectedScreen: 'setSchedule',
      selectedDate: null,
      isRecurring: false,
      isSmartPath: false,
      newSchedules: [],
    };
  },
  computed: {
    ...mapGetters(['selectedUserToUpdateSchedule', 'tutorialStatus', 'loggedInUser', 'groupedSchedules', 'schedules', 'getUserActionByName']),
  },
  methods: {
    ...mapActions(['bookDemoAsReferralPerson']),
    ...mapMutations(['setTutorialStatus']),
    goToConfirmAddSchedule(event) {
      this.newSchedules = event.newSchedules;
      this.selectedDate = event.selectedDate;
      this.isRecurring = event.isRecurring;
      this.isSmartPath = event.isSmartPath;
      this.existingSchedules = event.existingSchedules;
      this.selectedScreen = 'confirmAddSchedule';
    },
    openAddScheduleModal() {
      this.$refs.addScheduleModal.openModal();
    },
    closeModal() {
      this.selectedScreen = 'setSchedule';
      this.$emit('closeModal');
    },
    closeAddScheduleModal() {
      this.$refs.addScheduleModal.closeModal();
      let userTutorial = this.getUserActionByName(this.loggedInUser, 'book_a_consult');
      if (this.groupedSchedules.length < 1 && userTutorial && userTutorial.status == 'Active' && this.tutorialFit()) {
        this.$refs.endSpecialistTutorialModal.openModal();
      }
    },
    completeSpecialistTutorial() {
      this.setTutorialStatus('');
      this.$refs.endSpecialistTutorialModal.closeModal();
    },
    bookDemoAsReferralPersonOnClick() {
      this.isLoading = true;
      this.bookDemoAsReferralPerson({ user_id: this.loggedInUser.id, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, type: 'phone' })
        .then(() => {
          this.isLoading = false;
          // The Consult Response tutorial needs to be reworked with additional tutorial tooltips, but for now ending it here.
          this.updateUserTutorialByName(this.loggedInUser, 'consult_response', true, 'Completed');
          this.setTutorialStatus('specialistDemoConsult');
          this.$toast.add({
            severity: 'success',
            summary: 'Demo Booked',
            life: 5000,
          });
          this.$router.push({ path: `/home` });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
  unmounted() {
    if (this.tutorialStatus === 'set-availability') {
      this.setTutorialStatus('');
    }
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.dark-mint-show-referral-background {
  background-color: var(--vh-new-feature-blue);
}

.modal-footer {
  border-radius: 6px;
}

.dark-mint-show-referral-button-background.p-button.p-component {
  background-color: #10699e;
  border-color: #10699e;
}

.dark-mint-show-referral-button-background.p-button.p-component:hover {
  background-color: #0a7abd;
  border-color: #0a7abd;
}

.dark-mint-show-referral-modal-background.modal__header {
  background-color: var(--vh-new-feature-blue) !important;
}

.dark-mint-show-referral-header .modal__header {
  background-color: var(--vh-new-feature-blue);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
</style>
