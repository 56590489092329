<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card animated fade-in-up rounded-2xl shadow">
        <DataTable editMode="cell" @cell-edit-complete="onCellEditComplete" :value="referrals" :paginator="true" class="p-datatable-gridlines editable-cells-table referral-list-table" dataKey="id" :rowHover="true" v-model:filters="filters1" filterDisplay="menu" :filters="filters1" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rows="25" :rowsPerPageOptions="[25, 50, 100]">
          <template #header>
            <div class="flex flex-col sm:flex-row justify-between">
              <h1 class="fc-toolbar-title mt-2 mb-4 md:mb-0 text-left">Consultation History</h1>
              <div class="grid grid-cols-2 gap-4 sm:flex">
                <Button class="p-button-secondary" label="Share Consults" @click="goToShareConsults" v-if="!isOrgUnitHead(loggedInUser) && loggedInUser.org_units.length > 0" v-tooltip="'Choose which consults you would like to share with your group practice.'"></Button>
                <IconField iconPosition="left" :class="{ 'col-span-2': isOrgUnitHead(loggedInUser) || !loggedInUser.org_units.length > 0 }">
                  <InputIcon class="pi pi-search"> </InputIcon>
                  <InputText v-model="filters1['global'].value" placeholder="Search" class="w-full" maxLength="255" />
                </IconField>
              </div>
            </div>
          </template>
          <template #empty> No Consults found. </template>
          <template #loading> Loading Consult data. Please wait. </template>

          <Column v-if="displayEconsult() && hasProvinceOfPractice(loggedInUser, ['ON'])" field="interaction_type.name" header="Type" :showFilterMatchModes="false" bodyClass="text-center" :filterMenuStyle="{ width: '14rem' }" style="width: 8rem">
            <template #body="{ data }">
              <span class="p-column-title">Type</span>
              <div :class="'p-text-center top-6 right-6 rounded px-2 py-1 text-xs font-bold uppercase text-white ' + data.interaction_type.name">{{ data.interaction_type.name }}</div>
            </template>
            <template #filter="{ filterModel }">
              <Dropdown v-model="filterModel.value" :options="types" placeholder="All" class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span :class="'status-badge text-white ' + slotProps.value" v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
                <template #option="slotProps">
                  <span :class="'status-badge text-white ' + slotProps.option">Referral</span>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column field="referral_form.patient_full_name" header="Patient" :sortable="true"> </Column>

          <Column field="specialty.name" header="Specialty" v-if="isGP(loggedInUser) || isOrgUnitHead(loggedInUser)"> </Column>

          <Column v-if="isOrgUnitHead(loggedInUser)" field="referee.full_name" header="Referring Practitioner" style="min-width: 1rem" :sortable="true">
            <template #body="{ data }">
              <span class="p-column-title">Full Name</span>
              {{ data.referee_full_name }}
            </template>
          </Column>

          <Column v-if="isOrgUnitHead(loggedInUser)" field="referral_person.full_name" header="Specialist" style="min-width: 1rem" :sortable="true">
            <template #body="{ data }">
              <span class="p-column-title">Full Name</span>
              {{ data.referral_person_full_name }}
            </template>
          </Column>

          <Column v-if="!isOrgUnitHead(loggedInUser)" field="#" header="Consult With" style="min-width: 1rem" :sortable="true">
            <template #body="{ data }">
              <span class="p-column-title">Full Name</span>
              <template v-if="data.referee_id === loggedInUser.id">
                <div class="flex items-center">
                  {{ data.referral_person_full_name }}
                  <span v-if="(isSpecialist(loggedInUser) && loggedInUser.id === data.referee_id) || (actingUser && isSpecialist(actingUser) && actingUser.id === data.referee_id)" class="flex flex-row pl-1" v-tooltip.bottom="{ value: 'The consultant will give you a call on the consult date. ' }">
                    <i class="pi pi-phone text-blue-400" style="font-size: 1.4rem; padding-top: 2px"></i>
                    <i class="pi pi-arrow-down -ml-3 mb-3 rotate-45 text-blue-400" style="font-size: 0.85rem; font-weight: bolder"></i>
                  </span>
                  <span v-if="isSpecialist(loggedInUser) && data.call_asap" class="flex flex-row pl-1" v-tooltip.right="{ value: 'This consult is OnDemand, and can be called at any time', class: 'custom-tooltip' }">
                    <i class="pi pi-bolt text-coral" style="font-size: 1.4rem; padding-top: 2px"></i>
                  </span>
                </div>
              </template>
              <template v-else-if="data.referral_person_id === loggedInUser.id">
                {{ data.referee_full_name }}
              </template>
            </template>
          </Column>

          <Column dataType="date" sortField="end_datetime" :field="(data) => data[0]?.end_datetime" header="Consult Date" style="max-width: 8rem" :sortable="true">
            <template #filter="{ filterModel }">
              <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
            </template>
            <template #body="{ data }">
              <span class="p-column-title">Date/Time</span>
              <span v-if="data.interaction_type.name === 'phone'">
                <template v-if="!data.call_asap"> {{ formatDateTime(data.start_datetime) }} {{ getUserTimeZoneAbbr() }} </template>
                <template v-else>
                  <div class="flex flex-row items-center justify-between">
                    <span class="pr-1"> {{ formatDateTime(data.end_datetime) }} {{ getUserTimeZoneAbbr() }} </span>
                    <div v-if="data.call_asap == true && data.status == 'Upcoming' && isSpecialist(loggedInUser)" class="font-semibold text-primary" v-tooltip.right="'This is an OnDemand consult, please call before the consult date.'">
                      On Demand
                      <i class="pi pi-bolt mx-auto" style="font-size: 0.8rem"></i>
                    </div>
                  </div>
                </template>
              </span>
            </template>
            <!-- <template #filter="{filterModel}">
              <Calendar
                v-model="filterModel.value"
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
              />
            </template> -->
          </Column>

          <Column field="status" :showFilterMatchModes="false" bodyClass="text-center" :filterMenuStyle="{ width: '14rem' }" style="max-width: 7.5rem">
            <template #header>
              <div class="p-column-title flex flex-row items-center">
                <div class="mr-1">Status</div>
                <div>
                  <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="1" />
                </div>
              </div>
            </template>
            <template #body="{ data }">
              <span class="p-column-title">Status</span>
              <div :class="'p-text-center top-6 right-6 rounded px-2 py-1 text-xs font-bold uppercase text-white ' + data.className">{{ data.status }}</div>
            </template>
            <template #filter="{ filterModel }">
              <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span :class="'status-badge text-white ' + slotProps.value.replace(/\s/g, '')" v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
                <template #option="slotProps">
                  <span :class="'status-badge text-white ' + slotProps.option.replace(/\s/g, '')">{{ slotProps.option }}</span>
                </template>
              </Dropdown>
            </template>
          </Column>

          <template v-if="(isGP(loggedInUser) || isSpecialist(loggedInUser)) && loggedInUser.org_units.length > 0">
            <Column field="id" style="max-width: 4rem" header="Shared">
              <template #body="{ data }">
                <div class="text-blue-500" v-if="data.org_units.find((org_unit) => this.loggedInUser.org_units.find((user_org_unit) => user_org_unit.pivot.user_id === org_unit.pivot.user_id))">
                  {{ data.org_units.find((org_unit) => this.loggedInUser.org_units.find((user_org_unit) => user_org_unit.pivot.user_id === org_unit.pivot.user_id)).name }}
                </div>
                <div v-else>Not Shared</div>
              </template>
            </Column>
          </template>

          <template v-if="isCrossBorderSpecialist(loggedInUser) && allProvinces.length > 0">
            <Column field="specialty.practice_province_id" style="min-width: 1rem" header="Consult Province" :showFilterMatchModes="false">
              <template #filter="{ filterModel }">
                <Dropdown v-model="filterModel.value" :options="allProvinces" optionLabel="name" optionValue="id" placeholder="Any" class="p-column-filter" :showClear="true">
                  <!-- <template #value="slotProps">
                    <span :class="'status-badge text-white ' + slotProps.value.replace(/\s/g, '')" v-if="slotProps.value">{{ slotProps.value }}</span>
                    <span v-else>{{ slotProps.placeholder }}</span>
                  </template>
                  <template #option="slotProps">
                    <span :class="'status-badge text-white ' + slotProps.option.replace(/\s/g, '')">{{ slotProps.option }}</span>
                  </template> -->
                </Dropdown>
              </template>
              <template #body="{ data }">
                <div>
                  {{ allProvinces.find((province) => data.specialty.practice_province_id === province.id)?.name }}
                </div>
              </template>
            </Column>
          </template>

          <Column field="id" header="View" style="width: 1rem">
            <template #body="{ data }">
              <Button icon="pi pi-eye" class="p-button-rounded p-button-outlined" @click="selectReferral(data.id)" :id="data.className + '-referral-view-button'" :aria-label="data.referral_form.patient_first_name + '-' + data.referral_form.patient_last_name + '-referral-view-button'" />
            </template>
          </Column>
          <template v-if="isOrgUnitHead(loggedInUser)">
            <Column field="consult_request" header="Request" style="width: 1rem">
              <template #body="{ data }">
                <Button v-if="data.status != 'Pending'" icon="pi pi-download" class="p-button-rounded p-button-outlined" @click="downloadPdf(data.referral_form.id)" />
              </template>
            </Column>
            <Column field="id" header="Report" style="width: 1rem">
              <template #body="{ data }">
                <Button v-if="data.status === 'Complete'" icon="pi pi-download" class="p-button-rounded p-button-outlined" @click="downloadResponsePdf(data.referral_response_form.id)" />
              </template>
            </Column>
            <Column field="referral_notes" header="Notes" key="referral_notes" style="width: 25%">
              <template #editor="slotProps">
                <InputText v-model="slotProps.data[slotProps.column.props.field]" class="w-full" />
              </template>
            </Column>
          </template>
          <template v-else>
            <Column field="id" style="width: 1rem">
              <template #body="{ data }">
                <div @click="toggleReferralMenu($event, data)" class="cursor-pointer p-0 text-gray-500 hover:text-black">
                  <i class="pi pi-ellipsis-v" style="font-size: 1rem p-0"></i>
                </div>
              </template>
            </Column>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <Menu ref="menu" style="width: 210px" :model="referralMenuItems" :popup="true" />
  <ShareReferralWithOrgUnit :openShareReferralWithOrgUnit="openShareReferralWithOrgUnit" @closeShareReferralWithOrgUnit="closeShareReferralWithOrgUnit" :referral="referral" />
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import { FilterOperator } from 'primevue/api';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import { mapGetters, mapActions } from 'vuex';
import Menu from 'primevue/menu';
import ShareReferralWithOrgUnit from '@/components/org_unit/ShareReferralWithOrgUnit.vue';

export default {
  data() {
    return {
      filters1: null,
      statuses: ['Complete', 'Pending', 'Upcoming', 'Cancelled', 'In Review'],
      types: ['phone', 'econsult'],
      referralMenuItems: [],
      openShareReferralWithOrgUnit: false,
      selectedReferral: null,
    };
  },

  created() {
    this.initFilters1();
    this.getAllProvinces();
    if (this.$route.query.filterStatus == 'Pending') {
      this.filters1.status.value = 'Pending';
    }
  },

  props: ['referrals'],
  components: {
    DataTable,
    Dropdown,
    Column,
    Button,
    InputText,
    Calendar,
    Menu,
    ShareReferralWithOrgUnit,
  },
  methods: {
    ...mapActions(['downloadPdfOfReferralForm', 'downloadPdfOfResponseForm', 'createReferralNotes', 'getReferralById', 'getAllProvinces']),
    selectReferral(id) {
      this.$router.push('/referral/' + id);
    },
    downloadPdf(id) {
      this.error = '';
      this.downloadPdfOfReferralForm(id)
        .then(() => {})
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error Downloading File',
            detail: 'There was an error processing your request, please contact support@virtualhallway.ca or try again later.',
            life: 10000,
          });
        });
    },
    downloadResponsePdf(id) {
      this.error = '';
      this.downloadPdfOfResponseForm(id)
        .then()
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error Downloading File',
            detail: 'There was an error processing your request, please contact support@virtualhallway.ca or try again later.',
            life: 10000,
          });
        });
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        start_datetime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        'interaction_type.name': { value: null, matchMode: FilterMatchMode.EQUALS },
        'specialty.practice_province_id': { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;

      this.createReferralNotes({
        userId: this.loggedInUser.id,
        referral_notes: newValue,
        referral: data,
      });

      switch (field) {
        default:
          if (newValue.trim().length > 0) data[field] = newValue;
          else event.preventDefault();
          break;
      }
    },
    toggleReferralMenu(event, referral) {
      this.referralMenuItems = [];
      this.selectedReferral = null;
      this.referralMenuItems.push({
        label: 'View',
        icon: 'pi pi-eye',
        command: () => {
          this.selectReferral(referral.id);
        },
      });
      if (this.actingUser.org_units.length > 0) {
        this.referralMenuItems.push({
          label: 'Share',
          icon: 'pi pi-external-link',
          command: () => {
            this.isLoading = true;
            this.getReferralById(referral.id)
              .then(() => {
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
                this.showGenericErrorToast();
              });
            this.selectedReferral = referral;
            this.openShareReferralWithOrgUnit = !this.openShareReferralWithOrgUnit;
          },
        });
      }
      if (referral.status !== 'Pending') {
        this.referralMenuItems.push({
          label: 'Download Request',
          icon: 'pi pi-download',
          command: () => {
            this.downloadPdf(referral.referral_form.id);
          },
        });
      }
      if (referral.status === 'Complete') {
        this.referralMenuItems.push({
          label: 'Download Response',
          icon: 'pi pi-download',
          command: () => {
            this.downloadResponsePdf(referral.referral_response_form.id);
          },
        });
      }
      this.$refs.menu.toggle(event);
    },
    closeShareReferralWithOrgUnit(event) {
      this.openShareReferralWithOrgUnit = event.show;
    },
    goToShareConsults() {
      this.$router.push('/shareconsults');
    },
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'isSpecialist', 'isOrgUnitHead', 'actingUser', 'referral', 'isCrossBorderSpecialist', 'allProvinces', 'hasProvinceOfPractice']),
  },
};
</script>

<style lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

.referral-list-table.p-datatable .p-column-filter {
  display: block;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.status-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-InReview {
    background: #946da6;
    color: #6b039c;
  }

  &.status-Complete {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Accepted {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Faxed {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Upcoming {
    background: #b3e5fc;
    color: #23547b;
  }

  &.status-Pending {
    background: #f8da87;
    color: #81620c;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--surface-d);

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: 0.5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #c63737;
}

.p-button.p-button-info {
  background: var(--vh-blue) !important;
}

.cal-leg {
  width: 15px;
  height: 15px;
  background-color: var(--vh-blue);
  margin-right: 10px;
  border-radius: 50px;
}

.p-editable-column > .p-inputtext {
  font-size: 0.7rem;
}
</style>
