import axios from 'axios';
import globals from '../../globals';
import moment from 'moment';

const state = {
  schedules: [],
  schedulesOfSpecialists: null,
  selectedUserToUpdateSchedule: null,
  groupedSchedules: [],
  groupedSchedulesMap: {},
  nextAvailabilityOfSpecialties: [],
  specialistFilterValue: '',
};

const getters = {
  schedulesOfSpecialists: (state) => {
    return state.schedulesOfSpecialists;
  },
  schedules: (state) => {
    return state.schedules;
  },
  selectedUserToUpdateSchedule: (state) => {
    return state.selectedUserToUpdateSchedule;
  },
  groupedSchedules: (state) => {
    return state.groupedSchedules;
  },
  groupedSchedulesMap: (state) => {
    return state.groupedSchedulesMap;
  },
  nextAvailabilityOfSpecialties: (state) => {
    return state.nextAvailabilityOfSpecialties;
  },
  specialistFilterValue: (state) => {
    return state.specialistFilterValue;
  },
};

// actions
const actions = {
  getUpcomingScheduleOfUserForSpecialist(context, specialistId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getUpcomingScheduleOfUserUrl(specialistId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setSchedules', response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUpcomingScheduleOfUser(context, specialistIds) {
    let schedules = [];
    let promise = [];
    return new Promise((resolve, reject) => {
      specialistIds.forEach((specId) => {
        promise.push(
          new Promise((resolve, reject) => {
            axios
              .get(globals.APIs.getUpcomingScheduleOfUserUrl(JSON.stringify(specId)), {
                headers: context.rootGetters.setHeaderWithAuth,
              })
              .then((response) => {
                response.data.data.forEach((interval) => {
                  interval.start_datetime = moment.utc(interval.start_datetime).toDate();
                  interval.end_datetime = moment.utc(interval.end_datetime).toDate();

                  schedules.push(interval);
                });
                resolve('success');
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
      });
      Promise.all(promise)
        .then(() => {
          context.commit('setSchedulesOfSpecialists', schedules);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUpcomingScheduleWithScheduleBufferOfUser(context, data) {
    let schedules = [];
    let referralCount = [];
    let promise = [];
    return new Promise((resolve, reject) => {
      data.specialistIds.forEach((specId) => {
        promise.push(
          new Promise((resolve, reject) => {
            axios
              .get(globals.APIs.getUpcomingScheduleWithScheduleBufferOfUserUrl(JSON.stringify(specId)), {
                headers: context.rootGetters.setHeaderWithAuth,
                params: {
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  smart_path_referral: data.smartPathReferral,
                },
              })
              .then((response) => {
                response.data.data.forEach((interval) => {
                  interval.start_datetime = moment.utc(interval.start_datetime).toDate();
                  interval.end_datetime = moment.utc(interval.end_datetime).toDate();
                  schedules.push(interval);
                  referralCount[specId] = response.data.referral_count;
                });
                resolve('success');
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
      });
      Promise.all(promise)
        .then(() => {
          context.commit('setSchedulesOfSpecialists', schedules);
          context.commit('setReferralCounts', referralCount);
          resolve(schedules);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getNextAvailabilityOfSpecialties(context, actingUserId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getNextAvailabilityOfSpecialtiesUrl(actingUserId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setNextAvailabilityOfSpecialties', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addSchedule(context, data) {
    return new Promise((resolve, reject) => {
      //// Converting the JS Datetime object to required datetime format for the API
      let schedules = JSON.parse(JSON.stringify(data.schedules));
      for (let i = 0; i < schedules.length; i++) {
        schedules[i].start_datetime = moment(schedules[i].start_datetime).format('YYYY-MM-DD HH:mm:ss');
        schedules[i].end_datetime = moment(schedules[i].end_datetime).format('YYYY-MM-DD HH:mm:ss');
      }
      axios
        .post(
          globals.APIs.addScheduleUrl(),
          {
            schedules: schedules,
            is_recurring: data.isRecurring,
            is_smart_path: data.isSmartPath,
            user_id: data.userId,
            is_day_off: data.dayOff,
            schedule_id_to_overwrite: data.schedule_id_to_overwrite,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          if (response.data.message == 'success') {
            context.commit('setIsAvailable', true);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setSelectedUserToUpdateSchedule(context, user) {
    context.commit('setSelectedUserToUpdateSchedule', user);
  },
  groupSameDateSchedules(context, data) {
    let schedules = [...data];

    let groupedMap = {};
    let groupedDates = [];
    for (let schedule of schedules) {
      if (schedule.is_recurring) {
        let dayOfweek = moment(schedule.start_datetime).format('dddd');
        let date = moment(schedule.start_datetime).format('YYYY-MM-DD');
        if (groupedMap[dayOfweek]) {
          if (groupedDates.includes(date)) {
            groupedMap[dayOfweek].push(schedule);
          }
        } else {
          groupedMap[dayOfweek] = [schedule];
          groupedDates.push(date);
        }
      } else {
        let startDate = moment(schedule.start_datetime).format('YYYY-MM-DD');
        if (groupedMap[startDate]) {
          groupedMap[startDate].push(schedule);
        } else {
          groupedMap[startDate] = [schedule];
        }
      }
    }
    context.commit('setGroupedScheduleMap', groupedMap);
    context.commit('setGroupedSchedule', Object.values(groupedMap));
  },
  // This method deletes all associated schedules for an ID, i.e. all intervals
  // associated with a schedule.
  deleteSchedules(context, schedules) {
    return new Promise((resolve, reject) => {
      axios
        .delete(globals.APIs.deleteScheduleByIdUrl(schedules[0].schedule_id), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};

// mutations
const mutations = {
  setSchedulesOfSpecialists(state, data) {
    state.schedulesOfSpecialists = data;
  },
  setSchedules(state, data) {
    let schedules = [];
    data.forEach((interval) => {
      interval.start_datetime = moment.utc(interval.start_datetime).toDate();
      interval.end_datetime = moment.utc(interval.end_datetime).toDate();
      schedules.push(interval);
    });
    state.schedules = schedules;
  },
  setSelectedUserToUpdateSchedule(state, data) {
    state.selectedUserToUpdateSchedule = data;
  },
  setGroupedSchedule(state, data) {
    state.groupedSchedules = data;
  },
  setGroupedScheduleMap(state, data) {
    state.groupedSchedulesMap = data;
  },
  setNextAvailabilityOfSpecialties(state, data) {
    state.nextAvailabilityOfSpecialties = data;
  },
  setSpecialistFilterValue(state, data) {
    state.specialistFilterValue = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
