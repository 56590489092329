<template>
  <div class="main-inner-wrap">
    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block"><i class="pi pi-envelope mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Invite a Colleague</h1>
        <h1 class="text-sm text-gray-200">Complete the form below in order to invite a family doctor to Virtual Hallway! They will be sent an email with a verification link to set up their account.</h1>
      </div>
    </div>
    <div class="h-full w-full max-w-lg pt-4">
      <div class="animated fade-in-up max-w-screen-md rounded-xl bg-white py-4 px-5 pt-6 shadow">
        <h1 class="text-blue text-2xl font-bold">Invite Colleague</h1>
        <div class="seperator"></div>
        <form method="post" @submit.prevent="submit" class="grid grid-cols-2 gap-y-4 gap-x-2">
          <div class="flex flex-col col-start-1 col-end-3 sm:col-auto">
            <label class="auth-input" for="firstName">First Name</label>
            <InputText v-model="user.first_name" name="firstName" id="firstName" type="text" class="auth-input" />
          </div>
          <div class="flex flex-col col-start-1 col-end-3 sm:col-auto">
            <label class="auth-input" for="lastName">Last Name</label>
            <InputText v-model="user.last_name" name="lastName" id="lastName" type="text" class="auth-input" />
          </div>
          <div class="flex flex-col col-start-1 col-end-3 sm:col-auto">
            <label class="auth-input" for="email">Email <span class="error">&nbsp;*</span> </label>
            <InputText v-model="user.email" id="email" maxLength="255" type="email" required class="auth-input" />
          </div>
          <div class="flex flex-col col-start-1 col-end-3 sm:col-auto">
            <label class="auth-input" for="phoneNumber">Cell Number</label>
            <InputMask type="tel" mask="(999)-999-9999" id="phoneNumber" name="phoneNumber" v-model="phoneNumberWitMask" :autoClear="false" class="auth-input w-full" :class="[{ 'p-invalid': phoneNumberError }]" @change="validatePhoneNumber" maxlength="16" />
            <span class="error" v-if="phoneNumberError">
              {{ phoneNumberErrorMsg }}
            </span>
          </div>
          <div class="col-start-2 col-end-3 flex justify-end">
            <Button label="Send Invite" iconPos="right" icon="pi pi-send" type="submit" name="submitButton" id="submitButton" />
          </div>
        </form>
      </div>
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Loading from 'vue-loading-overlay';
import InputMask from 'primevue/inputmask';

export default {
  components: {
    Button,
    InputText,
    Loading,
    InputMask,
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  data() {
    return {
      isLoading: false,
      user: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
      },
      phoneNumberWitMask: '',
      phoneNumberError: false,
      firstPhoneNumberChange: false,
    };
  },
  methods: {
    ...mapActions(['sendColleagueInvite']),
    submit() {
      this.validatePhoneNumber();

      if (!this.phoneNumberError) {
        this.$toast.removeAllGroups();
        this.isLoading = true;
        this.sendColleagueInvite({
          ...this.user,
        })
          .then(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Invite Sent',
              detail: this.user.first_name + ' ' + this.user.last_name + ' has been invited to Virtual Hallway.',
              life: 3000,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
    validatePhoneNumber() {
      if (this.firstPhoneNumberChange) {
        this.phoneNumberError = false;
        this.phoneNumberErrorMsg = '';
        this.user.phone_number = this.phoneNumberWitMask.replace(/[^0-9]/g, '');
        if (!/[0-9]/.test(this.user.phone_number) || this.user.phone_number.length != 10) {
          this.phoneNumberError = true;
          this.phoneNumberErrorMsg = 'Must be 10 digits.';
        }
        if (this.user.phone_number === '') {
          this.phoneNumberError = false;
          this.phoneNumberErrorMsg = '';
        }
      } else {
        this.firstPhoneNumberChange = true;
      }
    },
  },
};
</script>

<style></style>
