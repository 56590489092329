<template>
  <div class="main-inner-wrap" v-if="!isLoading">
    <div v-if="isOrgUnitHead(loggedInUser)" class="h-full w-full pt-4">
      <div :class="['bg-white', 'rounded-2xl', 'shadow', 'w-full']">
        <div class="py-2 px-6" v-if="orgUnitUsers && orgUnitUsers.length === 0">
          <Message severity="info" class="text-center"> You do not have any eligible SmartPath authors in your practice group. </Message>
        </div>
        <div v-if="orgUnitUsersOptedIntoSmartPath && orgUnitUsersOptedIntoSmartPath.length > 0">
          <ChangeActingUserDropdown titleText="Viewing SmartPath for:" :selectedUser="actingUser" :orgUnitUsers="orgUnitUsersOptedIntoSmartPath" @actingUserChanged="updateAuthor" />
        </div>
      </div>
    </div>

    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-gray-700 p-6 text-left shadow">
      <div class="hidden lg:block">
        <i class="pi pi-list mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i>
      </div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">My Pathways</h1>
        <h1 class="text-sm text-gray-200">The table below shows your own Smartpaths.</h1>
      </div>
    </div>
    <div v-if="smartPaths.length < 1">
      <div class="mt-12"></div>
      <div class="mb-4">
        <span> You do not have any pathways. Click the button below to create one in the Pathway Builder. </span>
      </div>
      <div v-if="isSpecialist(loggedInUser)">
        <Button label="Create Pathway" @click="makeSmartPath" />
      </div>
    </div>
    <div class="w-full pt-4" v-else>
      <SmartPathList :smartPaths="smartPaths" />
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SmartPathList from '@/components/specialist/smartpath/SmartPathList.vue';
import ChangeActingUserDropdown from '@/components/org_unit/ChangeActingUserDropdown.vue';
import Loading from 'vue-loading-overlay';
import Button from 'primevue/button';
import Message from 'primevue/message';

export default {
  components: {
    SmartPathList,
    ChangeActingUserDropdown,
    Loading,
    Button,
    Message,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isSpecialist', 'isOrgUnitHead', 'actingUser', 'smartPaths', 'selectedSmartPath', 'orgUnitUsersOptedIntoSmartPath']),
  },
  methods: {
    ...mapActions(['getSmartPathsOfUser', 'saveSmartPath', 'getOrgUnitUsersOptedIntoSmartPath']),
    ...mapMutations(['setActingUser']),
    makeSmartPath() {
      let makeSmartPathData = {
        name: 'New SmartPath ' + this.smartPaths.length,
        description: '',
        smartPath: JSON.stringify({ 0: { children: [], node_type: 'root', parent_id: -1, question: '', additional_information: '', lab_access: '', links: '', recommended_specialty: '', stat_visits: 0, stat_learn_more_clicks: 0, stat_lab_reqs: 0, stat_total_time_spent_on_node: 0 } }), // init node for all smartpaths
        status: 'Private',
        author_id: this.actingUser.id,
        version: 0,
      };
      this.saveSmartPath(makeSmartPathData)
        .then(() => {
          this.isLoading = false;
          this.$router.push({ path: '/builder/' + this.selectedSmartPath.id });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    updateAuthor() {
      if (this.actingUser.id !== 0) {
        this.isLoading = true;
        this.getSmartPathsOfUser(this.actingUser.id)
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
  },
  created() {
    this.isLoading = true;
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.getOrgUnitUsersOptedIntoSmartPath(this.loggedInUser.id)
        .then(() => {
          this.setActingUser(null);
          this.isLoading = false;
        })
        .catch(() => {
          this.showGenericErrorToast();
          this.isLoading = false;
        });
    } else {
      this.getSmartPathsOfUser(this.actingUser.id)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    }
  },
};
</script>

<style></style>
