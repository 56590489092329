<template>
  <div class="main-inner-wrap" v-if="!isLoading">
    <div class="lg:max-w-8xl flex w-full max-w-full items-start justify-center px-5 pt-8 pb-20 text-center lg:w-2/3">
      <div name="slide-fade">
        <div class="registration-form-card card rounded-3xl p-5 shadow">
          <h2 class="mb-2 flex-col text-xl font-bold text-blue-500">Join Department</h2>
          <ul v-for="(error, i) in addingExistingUserErrors" :key="i" class="error w-full">
            <li v-if="i == 0"><h3 class="font-bold">Failed to Join Department</h3></li>
            <li class="w-full text-left">- {{ error }}</li>
          </ul>
          <p class="success">
            {{ addExistingUserSuccess }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import Loading from 'vue-loading-overlay';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      addingExistingUserErrors: [],
      addExistingUserSuccess: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'orgUnitUsers', 'tutorialStatus']),
  },
  methods: {
    ...mapActions(['addExistingUserToOrgUnit']),
  },
  created() {
    this.isLoading = true;
    this.addExistingUserToOrgUnit({
      user_id: this.$route.params.userId,
      org_unit_id: this.$route.params.orgUnitId,
      code: this.$route.params.code,
    })
      .then((data) => {
        this.isLoading = false;
        this.addExistingUserSuccess = data.message;
        this.$toast.removeAllGroups();
        this.$toast.add({
          severity: 'success',
          summary: 'Added to Department!',
          detail: data.message + ' You can now share your existing consults with this department.',
          life: 3000,
        });
        this.$router.push({
          name: 'ShareConsults',
          query: { org_unit_id: this.$route.params.orgUnitId },
        });
      })
      .catch((error) => {
        this.isLoading = false;
        if (error.status == 422) {
          for (const key in error.data.errors) {
            error.data.errors[key].forEach((element) => {
              this.addingExistingUserErrors.push(element);
            });
          }
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        }
      });
  },
};
</script>

<style>
.org-add-complete-tooltip {
  width: 300px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1000;
  top: -150px;
  left: 55px;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.org-add-complete-tooltip::after {
  transform: rotate(90deg);
  content: '';
  position: absolute;
  top: 50%;
  left: -2%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.org-add-user-tooltip {
  width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  top: 10px;
  left: -130px;
  margin-left: -70px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}
.org-add-user-tooltip::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 102%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}
</style>
