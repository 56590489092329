import { createApp } from 'vue';

// import './tailwind.css';
// import './app.css';

import App from './App.vue';
import store from './store';
import router from './router';
import { globalMixin } from './mixins';
import PrimeVue from 'primevue/config';
import axios from 'axios';
import IdleVue from 'idle-vue';
import 'primevue/resources/themes/lara-light-green/theme.css';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import * as Sentry from '@sentry/vue';
import Dialog from 'primevue/dialog';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { SFacebook } from 'vue-socials';
import { STwitter } from 'vue-socials';
import { SLinkedIn } from 'vue-socials';

import vueVimeoPlayer from 'vue-vimeo-player';

import Tooltip from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';
import Ripple from 'primevue/ripple';

import VueConfetti from 'vue-confetti';
import { createHead } from '@unhead/vue';

import { library } from '@fortawesome/fontawesome-svg-core';
// Uncomment below to access all fontawesome Icons
// import { fas } from '@fortawesome/free-solid-svg-icons'
// Uncomment below to import only the used Icons
import { faUser, faUserDoctor, faBolt, faCoffee, faCircleCheck, faSquareCheck, faNotesMedical, faPencil, faCertificate, faPaperPlane, faLink, faPlus, faClock, faHandHoldingHeart, faHandHolding, faHeart, faFingerprint, faCirclePlay, faAward, faStethoscope, faHourglass, faBook, faScroll, faFileInvoice, faPeopleGroup, faBookOpen, faMedal } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farFaHeart } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { dom } from '@fortawesome/fontawesome-svg-core';

import VueMixpanel from 'vue-mixpanel';

const app = createApp(App);
const head = createHead();
app.use(head);
//Fontawesome Icon Registration
app.component('fa', FontAwesomeIcon);
app.config.productionTip = false;
// Uncomment below to access all fontawesome Icons
// library.add(fas)
// Uncomment below to import only the used Icons
library.add(faUser, faUserDoctor, faBolt, faCoffee, faCircleCheck, faSquareCheck, faNotesMedical, faPencil, faCertificate, faPaperPlane, faLink, faPlus, faClock, faHandHoldingHeart, faHandHolding, faHeart, farFaHeart, faFingerprint, faCirclePlay, faAward, faStethoscope, faHourglass, faBook, faScroll, faFileInvoice, faPeopleGroup, faBookOpen, faMedal);

dom.watch(); // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

app.use(store);
app.use(router);
app.mixin(globalMixin);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(vueVimeoPlayer);
app.use(IdleVue, {
  store,
  idleTime: 60000 * 9, //Set the minutes here 6 minutes less than the desired timeout duration as there's a 5 minute warning modal before logout.
  events: ['keydown', 'mousedown', 'touchstart', 'load'],
  startAtIdle: false, // This is important, else the logout warning modal will pop-up on page refresh.
});
app.use(VueConfetti);
app.use(VueMixpanel, {
  token: import.meta.env.VITE_MIXPANEL_ACCESS_TOKEN ? import.meta.env.VITE_MIXPANEL_ACCESS_TOKEN : 'temp',
  config: {
    debug: import.meta.env.VITE_APP_ENV !== 'production',
  },
});

app.component('SFacebook', SFacebook);
app.component('STwitter', STwitter);
app.component('SLinkedIn', SLinkedIn);
app.component('Toast', Toast);
app.directive('tooltip', Tooltip);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('Dialog', Dialog);
app.component('Stepper', Stepper);
app.component('StepperPanel', StepperPanel);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.use(ConfirmationService);

app.config.errorHandler = (err) => {
  Sentry.captureException(err);
  throw err;
};

app.use(autoAnimatePlugin).mount('#app');
const sentryEnabled = true;
if (sentryEnabled === true) {
  Sentry.init({
    app,
    dsn: 'https://2d055a4306a59d932c586b475d3b63f1@o4507183983755264.ingest.us.sentry.io/4507183983886336',
    environment: 'production',
    release: 'vh_vue3@' + import.meta.env.VITE_APP_VERSION,
    dist: import.meta.env.VITE_APP_VERSION,
    ignoreTransactions: ['Login', 'Logout'],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'ui.click') {
        const target = hint.event.target;
        if (target.ariaLabel) breadcrumb.message = target.ariaLabel;
      }
      if (breadcrumb.message.match(['Vue', [/Vue/], 'MIXPANEL', [/MIXPANEL/], 'sentryFormWidget'])) {
        return null;
      }
      if ((breadcrumb.level === 'info' || breadcrumb.level === 'warning') && breadcrumb.category === 'console') {
        return null;
      }
      return breadcrumb;
    },
    beforeSend: (event) => {
      if (store.getters.loggedInUser?.id) {
        const email = store.getters.loggedInUser?.email;
        const obscuredEmail = email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2');
        const userPage = 'https://app.virtualhallway.ca/admin/viewuser/' + store.getters.loggedInUser?.id;
        event.user = {
          id: store.getters.loggedInUser?.id,
          username: obscuredEmail,
          role: store.getters.loggedInUser?.role,
          status: store.getters.loggedInUser?.status,
          province: store.getters.loggedInUser?.practice_province?.short_name,
          adminLink: userPage,
          route: window.location.href,
          screen: `${window.screen.width} x ${window.screen.height}`,
        };
      }
      let searchParams = new URLSearchParams(window.location.search); //don't send error if autoLogout query is present in the url
      if (!searchParams.has('autoLogout')) {
        return event;
      }
      // console.log(event);
    },
    trackComponents: ['App', 'MakeReferral', 'Home', 'SidebarConsultCards', 'EditReferralForm'],
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/], //set from pipelines mainapiurl
    tracesSampleRate: 0.1, //  Capture 10% of the transactions, set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    replaysSessionSampleRate: 0.1, // (Session Replay) sets the sample rate at 10%. You may want to change it to 100% while in development.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: ['CapacitorCallPlugin', 'PushNotifications', 'Pusher', 'sentryFormWidget.removeFromDom is not a function', 'axios'],
    ignoreTransactions: '',
    denyUrls: '',
  });
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      let currentPath = window.location.pathname;
      if (!['/registration', '/logout', '/login'].includes(currentPath)) {
        router.replace({ name: 'Logout', query: { autoLogout: 'true', redirect: currentPath } });
      }
    }
    return Promise.reject(error);
  }
);
