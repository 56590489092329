<template>
  <div :class="animation" class="bg-white rounded-xl shadow p-8 w-full max-w-lg">
    <form method="post" @submit.prevent="submit">
      <h1 class="text-left text-blue text-xl font-bold">Want Calls OnDemand?</h1>
      <div class="seperator-small mt-4 mb-4"></div>
      <p class="text-left text-grey-500 text-sm mb-6 font-display">OnDemand Consults are unscheduled phone consults that provide flexibility for your schedule. When a provider books an OnDemand consult with you, you can call them any time that you are free. If disabled, providers can only book with you for times you make yourself available.</p>

      <div class="info-paragraph--gray">
        <div class="flex flex-row items-center">
          <Checkbox id="preboarding-call-asap-opt-in" v-model="callAsapOptIn" :binary="true" />
          <label for="preboarding-call-asap-opt-in" class="ml-2 text-lg">Enable OnDemand Consults</label>
        </div>
      </div>

      <div class="flex flex-row justify-between items-center align-center mt-8">
        <Button label="Back" @click="goToPrevious()" iconPos="left" class="p-button-text p-buttom-sm p-button-secondary" icon="pi pi-caret-left" />
        <Button label="Complete" type="submit" id="submit" class="p-button p-buttom-sm" iconPos="right" icon="pi pi-check" />
      </div>
    </form>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';

export default {
  components: {
    Button,
    Checkbox,
  },
  props: ['loggedInUser', 'propsUser'],
  emits: ['goToNext', 'goToPrevious'],

  data() {
    return {
      animation: 'animate-in',
      user: this.loggedInUser,
      callAsapOptIn: false,
    };
  },
  methods: {
    submit() {
      this.setOnDemand();
    },
    goToPrevious() {
      this.$emit('goToPrevious', this.user);
    },
    setOnDemand() {
      this.user.call_asap_optin = this.callAsapOptIn;
      this.user.is_submitted = true;
      this.user.status = 'Onboarding'; //Not sure if this is necessary, I think the user status is returned from the backend
      this.$emit('goToNext', this.user);
    },
  },
};
</script>

<style>
.p-multiselect-label-container {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
