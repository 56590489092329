<template>
  <div class="pb-2">
    <div class="flex w-full justify-center">
      <div class="animated fade-in-up flex items-center justify-center">
        <div class="position-relative tile flex w-full flex-col items-start rounded-2xl bg-white shadow">
          <div class="my-3 flex w-full flex-col items-start justify-center px-4">
            <div class="text-blue flex w-full flex-row items-start justify-between text-xs font-semibold">
              <div>Node ID: {{ curr }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="smartPath[curr]['node_type'] != 'root'">
    <div class="pb-2">
      <div class="flex w-full justify-center">
        <div class="animated fade-in-up flex items-center justify-center">
          <div class="position-relative tile flex w-full flex-col items-start rounded-2xl bg-white shadow">
            <div class="my-3 flex w-full flex-col items-start justify-center px-4">
              <div class="flex w-full flex-row items-start justify-between">
                <label class="answer-label auth-input">Answer <span class="error">&nbsp;*</span></label>
              </div>
              <div class="flex w-full flex-row items-start justify-between">
                <InputText required maxLength="255" class="p-inputtext-sm" v-model="answer" id="nodeAnswer" @change="validate()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex w-full justify-center">
    <div class="animated fade-in-up flex items-center justify-center">
      <div class="position-relative tile flex w-full flex-col items-start rounded-2xl bg-white shadow">
        <div class="mt-3 flex w-full flex-col items-start justify-center px-4">
          <div class="flex w-full flex-row items-start justify-between">
            <div v-if="smartPath[curr]['children'] == null || (children.length == 0 && smartPath[curr]['node_type'] != 'root')">
              <DropDown id="nodeTypeDropDown" class="mb-1 text-left text-xl font-bold text-gray-700" v-model="selectedNodeType" @change="changeNodeType({ id: this.curr, node_type: this.selectedNodeType.value })" :options="nodeTypeOptions" optionLabel="label" />
            </div>
            <div v-else class="text-md font-semibold text-red-500">
              <div v-if="smartPath[curr]['node_type'] == 'root'">Root Node</div>
              <div v-else-if="smartPath[curr]['node_type'] == 'decision'">Decision Node</div>
            </div>

            <div class="flex flex-row">
              <div>
                <div v-if="validation_error === ''" id="validationPassIcon" class="cursor-pointer px-2 text-gray-500 hover:text-black" v-tooltip.bottom="'Valid node!'">
                  <i class="pi pi-check navbar-icon text-red-500"></i>
                </div>
                <div v-else id="validationFailsIcon" class="cursor-pointer px-2 text-gray-500 hover:text-black" v-tooltip.bottom="validation_error">
                  <i class="pi pi-times navbar-icon text-red-500"></i>
                </div>
              </div>
              <div v-if="smartPath[curr]['node_type'] != 'pointer' && smartPath[curr]['node_type'] != 'link'">
                <div @click="toggleOptions" id="optionsCog" class="cursor-pointer pr-2 text-gray-500 hover:text-black" v-tooltip.bottom="'Additional Options'">
                  <i class="pi pi-cog navbar-icon text-gray-500"></i>
                </div>
              </div>
              <div>
                <div id="nodeStatsButton" @click="showNodeStats" class="cursor-pointer pr-2 text-gray-500 hover:text-black" v-tooltip.bottom="'Node Stats'">
                  <i class="pi pi-chart-bar navbar-icon text-blue-500"></i>
                </div>
              </div>
              <div v-if="children.length == 0 && smartPath[curr]['node_type'] != 'root'">
                <div id="deleteNodeButton" @click="deleteNode({ id: curr, parent_id: smartPath[curr]['parent_id'] })" class="cursor-pointer p-0 text-gray-500 hover:text-black" v-tooltip.bottom="'Delete Node'">
                  <i class="pi pi-trash navbar-icon text-red-500"></i>
                </div>
              </div>
            </div>
          </div>

          <div v-if="smartPath[curr]['node_type'] == 'decision' || smartPath[curr]['node_type'] == 'root'">
            <div class="mb-2 flex text-xs font-bold text-gray-500">
              <form>
                <label class="auth-input">Question<span class="error">&nbsp;*</span></label>
                <!-- <div class="flex w-full mb-2"> -->
                <!-- <Textarea class="auth-input" v-model="question" id="nodeQuestion" @change="validate()" :autoResize="true" rows="4" cols="60" required maxLength="65535" /> -->
                <Editor id="nodeQuestion" v-model="question" @input="validate()" @focusout="validate()" editorStyle="height:320px" :formats="['bold', 'italic', 'underline', 'header', 'link', 'color', 'list', 'indent', 'align']" />
                <!-- </div> -->
              </form>
            </div>
            <div v-show="additionalOptionsToggle">
              <form>
                <label class="auth-input">Additional Information</label>
                <Editor id="nodeAdditionalInformation" v-model="additional_information" @input="validate()" @focus="validate()" editorStyle="height:320px" :formats="['bold', 'italic', 'underline', 'header', 'link', 'color', 'list', 'indent', 'align']" />
                <!-- <label class="auth-input">General Requisition</label> -->
                <!-- <div class="flex w-full mb-2">
                  <InputText maxLength="255" class="p-inputtext-sm w-full" v-model="this.lab_access" id="nodeLabAccess" @change="validate()" />
                </div> -->
                <div class="pb-8">
                  <label class="auth-input">Lab Requisition Form</label>
                  <FileUpload name="files[]" :maxFileSize="20000000" :fileLimit="1" accept="application/pdf" :customUpload="true" @select="handleFileChange" :showCancelButton="false" :showUploadButton="false" @remove="remove" :key="rerender" chooseLabel="Choose Lab Form">
                    <template #empty>
                      <p class="text-sm">Drag and drop lab form here to upload.</p>
                    </template>
                  </FileUpload>
                  <div class="flex w-full flex-row">
                    <div v-if="smartPath[curr]['lab_access'] != ''" class="mt-4 flex w-full">
                      <div class="pr-4">
                        <Button label="Preview" @click="previewLabFile({ node_id: curr })" />
                      </div>
                      <Button label="Remove" class="p-button-danger" @click="removeLabFile({ node_id: curr })" />
                    </div>

                    <div class="mt-4 flex w-full justify-end">
                      <Button label="Submit Lab Form" :disabled="file === null" @click="uploadLabFile({ node_id: curr, lab_file: file })" />
                    </div>
                  </div>
                </div>

                <div class="pb-8">
                  <div v-if="smartPath[curr]['for_your_patient_files']">
                    <label class="auth-input">"For Your Patient" Files</label>
                    <div class="border-b border-solid border-gray-100 py-2 flex flex-row justify-between items-center" v-for="file in smartPath[curr]['for_your_patient_files']" :key="file.id">
                      {{ file }}
                      <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text" @click="removeForYourPatientFile({ node_id: curr, filename: file })" v-tooltip.bottom="'Click to delete this file.'" />
                    </div>
                  </div>

                  <FileUpload name="files[]" :maxFileSize="20000000" accept="application/pdf" :multiple="true" :customUpload="true" @select="handleForYourPatientFilesChange" :showCancelButton="false" :showUploadButton="false" @remove="remove" :key="rerender" chooseLabel="Choose For Your Patient Form">
                    <template #empty>
                      <div class="flex flex-col justify-center items-center">
                        <i class="pi pi-upload mb-3" style="font-size: 2rem"></i>
                        <p class="text-sm">Drag and drop files here, or click "choose" to upload.</p>
                      </div>
                    </template>
                    <template #content="{ files, uploadedFiles, removeFileCallback }">
                      <template v-if="uploadedFiles"></template>
                      <template v-if="files.length > 0">
                        <div v-for="file of files" :key="file.name + file.type + file.size" class="border-1 rounded p-3 surface-border grid grid-cols-10 gap-2 my-3">
                          <div class="place-self-center">
                            <img v-if="file.objectURL" role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
                            <i class="pi pi-file place-self-center" style="font-size: 2rem" v-else></i>
                          </div>
                          <div class="flex flex-col col-span-8">
                            <div class="font-semibold text-sm">{{ file.name }}</div>
                            <div>{{ formatSize(file.size) }}</div>
                          </div>
                          <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" @click="removeFileCallback" />
                        </div>
                      </template>
                    </template>
                  </FileUpload>

                  <div class="flex w-full flex-row">
                    <div class="mt-4 flex w-full justify-end">
                      <Button label="Submit For Your Patient Files" :disabled="forYourPatientFiles.length == 0" @click="uploadForYourPatientFiles({ node_id: curr, for_your_patient_files: forYourPatientFiles })" />
                    </div>
                  </div>
                </div>

                <label class="auth-input mt-2">Embedded Vimeo Link</label>
                <div id="vimeoLinkDiv" class="mb-2 flex w-full">
                  <InputText required maxLength="255" class="p-inputtext-sm w-full" v-model="this.vimeo_link" id="nodeVimeoLink" @change="validate()" />
                </div>
              </form>
            </div>
          </div>
          <div v-else-if="smartPath[curr]['node_type'] == 'pointer'">
            <div class="text-sm font-semibold text-gray-500">Which node would you like to point to?</div>
            <DropDown class="mb-1 w-full text-left text-xl font-bold text-gray-700" v-model="pointer" @change="validate()" :options="Object.keys(smartPath).filter((item) => item !== curr.toString())" />
          </div>
          <div v-else-if="smartPath[curr]['node_type'] == 'link'">
            <div v-if="linkableSmartPaths.length > 0">
              <div class="text-sm font-semibold text-gray-500">Which SmartPath would you like to link to?</div>
              <DropDown class="mb-1 w-full text-left text-xl font-bold text-gray-700" v-model="link" @change="validate()" id="linkSmartPathDropDown" :options="linkableSmartPaths" optionLabel="name" />
            </div>
            <div v-else>
              <div class="text-sm font-semibold text-gray-500">It seems that you don't have any SmartPaths to link here.</div>
              <div class="text-sm font-semibold text-gray-500">In order for a SmartPath to be able to be linked, it must be validated and Public.</div>
            </div>
          </div>
          <div v-else>
            <div class="mb-2 flex text-xs font-bold text-gray-500">
              <form class="w-full">
                <label class="auth-input mt-1">Recommended Action <span class="error">&nbsp;*</span></label>

                <Editor id="nodeRecommendedAction" v-model="recommended_action" @input="validate()" @focusout="validate()" editorStyle="height:320px" :formats="['bold', 'italic', 'underline', 'header', 'link', 'color', 'list', 'indent', 'align']" />

                <!-- <div class="flex w-full mb-2">
                  <InputText required maxLength="255" class="p-inputtext-sm w-full" v-model="this.recommended_action" id="nodeRecommendedAction" @change="validate()" />
                </div> -->
              </form>
            </div>
            <div v-show="additionalOptionsToggle" class="mb-2 flex text-xs font-bold text-gray-500">
              <form>
                <label class="auth-input">Additional Information</label>
                <Editor id="nodeAdditionalInformation" v-model="additional_information" @input="validate()" @focusout="validate()" editorStyle="height:320px" :formats="['bold', 'italic', 'underline', 'header', 'link', 'color', 'list', 'indent', 'align']" />

                <label class="auth-input mt-2">Recommended Specialty</label>
                <MultiSelect class="w-full" v-model="recommended_specialty" :options="allSpecialties" optionLabel="name" :showToggleAll="false" :filter="true" @change="validate()" />
                <!-- <label class="auth-input">General Requisition</label>
                <div class="flex w-full mb-2">
                  <InputText maxLength="255" class="p-inputtext-sm w-full" v-model="this.lab_access" id="nodeLabAccess" @change="validate()" />
                </div> -->
                <label class="auth-input mt-2">Triage Label</label>
                <div class="mb-2 flex w-full">
                  <InputText required maxLength="255" class="p-inputtext-sm w-full" v-model="this.triage_label" id="nodeTriageLabel" @change="validate()" />
                </div>
                <label class="auth-input mt-2">Suggested Action</label>
                <DropDown class="w-full" v-model="suggested_action" :inputId="'suggestedActionDropDown'" optionLabel="label" @change="validate()" :options="suggestedActionOptions" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="smartPath[curr]['node_type'] == 'decision' || smartPath[curr]['node_type'] == 'root'">
    <DecisionNode :id="curr" @addNode="addNode" />

    <ul :class="['tree', { 'multiple-children': children.length > 1 }]">
      <div class="ml-4 flex flex-row justify-center">
        <div v-for="(child, index) in children" :key="child">
          <li :class="[{ 'first-sibling': index === 0 }, { 'last-sibling': index === children.length - 1 }]">
            <SmartPathNode @addNode="addNode" @deleteNode="deleteNode" @updateNode="updateNode" @changeNodeType="changeNodeType" @uploadLabFile="uploadLabFile" @uploadForYourPatientFiles="uploadForYourPatientFiles" @previewLabFile="previewLabFile" @removeLabFile="removeLabFile" @removeForYourPatientFile="removeForYourPatientFile" :smartPath="smartPath" :curr="child" />
          </li>
        </div>
      </div>
    </ul>
  </div>

  <modal ref="nodeStatsModal">
    <template v-slot:header> </template>

    <template v-slot:body>
      <div class="max-w-3xl px-8 pt-8">
        <div class="relative rounded-xl bg-secondary remove-later p-4 text-left shadow">
          <div class="flex h-full flex-row">
            <div class="mb-2 flex w-full flex-col">
              <div class="flex w-full flex-row">
                <div class="flex">
                  <i class="pi pi-chart-bar mb-3 text-white" style="font-size: 2rem" />
                </div>

                <div class="flex w-full justify-end">
                  <Button id="closeStatsModal" class="save-button" label="Close" @click="closeNodeStats" />
                </div>
              </div>

              <span class="mb-2 text-xl font-bold text-white md:text-3xl">Node ID: {{ curr }}</span>
              <div>
                <div class="flex flex-row">
                  <div class="text-sm font-bold text-white">All metrics are based only on the individual selected node.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-centre text-xl font-bold text-blue-400">
          <div class="mt-6 grid grid-cols-1 gap-4 md:grid-cols-2">
            <div class="flex flex-col items-start rounded-xl p-6 text-left shadow" style="background-color: #3676a4">
              <i class="pi pi-chart-line mb-4 text-white" style="font-size: 2rem" />
              <span class="mb-2 text-2xl font-bold text-white md:text-4xl">{{ smartPath[curr]['stat_visits'] }} </span>
              <div class="flex flex-row gap-2">
                <div><span class="text-sm font-bold text-white">Total Visits</span></div>
              </div>
            </div>
            <div class="flex flex-col items-start rounded-xl p-6 text-left shadow" style="background-color: #f099c2">
              <i class="pi pi-info-circle mb-4 text-white" style="font-size: 2rem" />
              <span class="mb-2 text-2xl font-bold text-white md:text-4xl">{{ smartPath[curr]['stat_learn_more_clicks'] }}</span>
              <div class="flex flex-row gap-2">
                <div><span class="text-sm font-bold text-white">Total Learn More Clicks</span></div>
              </div>
            </div>
          </div>
          <div class="mt-6 grid grid-cols-1 gap-4 md:grid-cols-2">
            <div class="flex flex-col items-start rounded-xl p-6 text-left shadow" style="background-color: #cc338c">
              <i class="pi pi-download mb-4 text-white" style="font-size: 2rem" />
              <span class="mb-2 text-2xl font-bold text-white md:text-4xl">{{ smartPath[curr]['stat_lab_reqs'] }}</span>
              <div class="flex flex-row gap-2">
                <div><span class="text-sm font-bold text-white">Total Lab Requisition Downloads</span></div>
              </div>
            </div>
            <div class="flex flex-col items-start rounded-xl p-6 text-left shadow" style="background-color: #c42079">
              <i class="pi pi-clock mb-4 text-white" style="font-size: 2rem" />
              <span v-if="smartPath[curr]['stat_total_time_spent_on_node'] == 0" class="mb-2 text-2xl font-bold text-white md:text-4xl">N/A</span>
              <span v-else class="mb-2 text-2xl font-bold text-white md:text-4xl">{{ parseFloat(smartPath[curr]['stat_total_time_spent_on_node'] / smartPath[curr]['stat_visits']).toFixed(2) }}</span>
              <div class="flex flex-row gap-2">
                <div><span class="text-sm font-bold text-white">Average Time Spent (seconds)</span></div>
              </div>
            </div>
          </div>
          <div v-if="smartPath[curr]['stat_for_your_patient_download']" class="mt-6 grid grid-cols-1 gap-4 md:grid-cols-2">
            <div class="flex flex-col items-start rounded-xl p-6 text-left shadow" style="background-color: #cc338c">
              <i class="pi pi-download mb-4 text-white" style="font-size: 2rem" />
              <span class="mb-2 text-2xl font-bold text-white md:text-4xl">{{ smartPath[curr]['stat_for_your_patient_download'] }}</span>
              <div class="flex flex-row gap-2">
                <div><span class="text-sm font-bold text-white">Total "For Your Patient" File Downloads</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-s rounded-b py-2 pb-4"></div>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SmartPathNode from '@/components/specialist/smartpath/SmartPathNode.vue';
import DecisionNode from '@/components/specialist/smartpath/DecisionNode.vue';
import DropDown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
// import Textarea from 'primevue/textarea';
import MultiSelect from 'primevue/multiselect';
import Editor from 'primevue/editor';
import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';
import Modal from '@/components/misc/Modal.vue';

export default {
  name: 'SmartPathNode',
  components: {
    DecisionNode,
    DropDown,
    InputText,
    // Textarea,
    MultiSelect,
    Editor,
    Button,
    Modal,
    FileUpload,
    // SmartPathNode: SmartPathNode,
  },
  props: ['smartPath', 'curr'],
  emits: ['changeNodeType', 'addNode', 'deleteNode', 'updateNode', 'uploadLabFile', 'uploadForYourPatientFiles', 'previewLabFile', 'removeLabFile', 'removeForYourPatientFile'],
  computed: {
    ...mapGetters(['allSpecialties', 'actingUser', 'linkableSmartPaths']),
  },
  data() {
    return {
      additionalOptionsToggle: false,
      children: [],
      nodeTypeOptions: [
        { label: 'Decision Node', value: 'decision' },
        { label: 'Terminal Node', value: 'terminal' },
        { label: 'Pointer Node', value: 'pointer' },
        { label: 'Link Node', value: 'link' },
      ],
      selectedNodeType: { label: 'Decision Node', value: 'decision' },
      suggestedActionOptions: [
        { label: 'Phone Consult with Recommended Speciality', value: 'specialty' },
        { label: 'Phone Consult with Me', value: 'author' },
        { label: 'Offline Referral (Download)', value: 'offline' },
        { label: 'In-Person Referral', value: 'inperson' },
      ],
      specialties: [],
      // start node fields
      answer: '',
      question: '',
      recommended_action: '',
      lab_access: '',
      additional_information: '',
      recommended_specialty: '',
      triage_label: '',
      vimeo_link: '',
      suggested_action: '',
      pointer: 0,
      link: null,
      // end node fields
      validation_error: '',
      file: null,
      forYourPatientFiles: [],
      rerender: false,
    };
  },
  created() {
    if (this.smartPath[this.curr]['node_type'] == 'decision' || this.smartPath[this.curr]['node_type'] == 'root') {
      if (this.smartPath[this.curr]['node_type'] == 'decision') {
        this.selectedNodeType = { label: 'Decision Node', value: 'decision' };
      }
      this.findChildren();
    } else if (this.smartPath[this.curr]['node_type'] == 'pointer') {
      this.selectedNodeType = { label: 'Pointer Node', value: 'pointer' };
    } else if (this.smartPath[this.curr]['node_type'] == 'terminal') {
      // need to set it up like this so the drop downs look nice, same as above
      this.selectedNodeType = { label: 'Terminal Node', value: 'terminal' };
    } else if (this.smartPath[this.curr]['node_type'] == 'link') {
      this.selectedNodeType = { label: 'Link Node', value: 'link' };
    }

    // im so sorry
    if (this.smartPath[this.curr]['node_type'] != 'root') {
      for (let i = 0; i < this.smartPath[this.smartPath[this.curr]['parent_id']]['children'].length; i++) {
        let child = this.smartPath[this.smartPath[this.curr]['parent_id']]['children'][i];
        if (child['next'] == this.curr) {
          this.answer = child['answer'];
        }
      }
    }
    this.question = this.smartPath[this.curr]['question'];
    this.recommended_action = this.smartPath[this.curr]['recommended_action'];
    this.lab_access = this.smartPath[this.curr]['lab_access'];
    if (this.smartPath[this.curr]['for_your_patient_files'] != null) {
      this.forYourPatientFiles = this.smartPath[this.curr]['for_your_patient_files'];
    }

    // this.links = this.smartPath[this.curr]['links'];
    this.additional_information = this.smartPath[this.curr]['additional_information'];
    this.recommended_specialty = this.smartPath[this.curr]['recommended_specialty'];
    this.triage_label = this.smartPath[this.curr]['triage_label'];
    if (this.smartPath[this.curr]['vimeo_link']) {
      this.vimeo_link = this.smartPath[this.curr]['vimeo_link'];
    }
    // this.action_explanation = this.smartPath[this.curr]['action_explanation'];
    this.suggested_action = this.smartPath[this.curr]['suggested_action'];
    // need to cast this as a string to display it in dropdown... kind of annoying
    if (this.smartPath[this.curr]['pointer'] != null) {
      this.pointer = this.smartPath[this.curr]['pointer'].toString();
    }
    if (this.smartPath[this.curr]['link'] != null) {
      this.link = this.smartPath[this.curr]['link'];
    }

    this.validate();
  },
  methods: {
    ...mapActions(['getSpecialties']),
    formatSize(bytes) {
      if (bytes === 0) return '0 B';
      const k = 1024;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
    findChildren() {
      this.smartPath[this.curr]['children'].forEach((child) => {
        this.children.push(child.next);
      });
    },
    addNode(event) {
      this.$emit('addNode', event);
    },
    deleteNode(event) {
      this.$emit('deleteNode', {
        id: event.id,
        parent_id: event.parent_id,
      });
    },
    updateNode(event) {
      this.$emit('updateNode', {
        id: event.id,
        parent_id: event.parent_id,
        answer: event.answer,
        question: event.question,
        recommended_action: event.recommended_action,
        lab_access: event.lab_access,
        for_your_patient_files: event.for_your_patient_files,
        links: event.links,
        additional_information: event.additional_information,
        recommended_specialty: event.recommended_specialty,
        triage_label: event.triage_label,
        vimeo_link: event.vimeo_link,
        action_explanation: event.action_explanation,
        suggested_action: event.suggested_action,
        pointer: event.pointer,
        link: event.link,
        validation_error: event.validation_error,
      });
    },
    changeNodeType(event) {
      this.validate();
      this.$emit('changeNodeType', {
        id: event.id,
        node_type: event.node_type,
      });
    },
    toggleOptions() {
      this.additionalOptionsToggle = !this.additionalOptionsToggle;
    },
    validate() {
      this.validation_error = '';
      if (this.selectedNodeType.value == 'decision') {
        if (this.question == '') {
          this.validation_error += 'Question cannot be empty.\n';
        }
        if (this.curr !== 0 && this.answer == '') {
          this.validation_error += 'Answer cannot be empty.\n';
        }
        if (this.children.length < 1) {
          this.validation_error += 'Node must lead to terminal node.\n';
        }
      } else if (this.selectedNodeType.value == 'pointer') {
        if (this.answer == '') {
          this.validation_error += 'Answer cannot be empty.\n';
        }
        if (this.pointer == null) {
          this.validation_error += 'Must point to another node.\n';
        }
      } else if (this.selectedNodeType.value == 'link') {
        if (this.answer == '') {
          this.validation_error += 'Answer cannot be empty.\n';
        }
        if (this.link == null) {
          this.validation_error += 'Must link to another SmartPath.\n';
        }
      } else if (this.selectedNodeType.value == 'terminal') {
        if (this.answer == '') {
          this.validation_error += 'Answer cannot be empty.\n';
        }
        if (this.recommended_action == '') {
          this.validation_error += 'Recommended Action cannot be empty.\n';
        }
      }

      let event = {
        id: this.curr,
        parent_id: this.smartPath[this.curr]['parent_id'],
        answer: this.answer,
        question: this.question,
        recommended_action: this.recommended_action,
        lab_access: this.lab_access,
        for_your_patient_files: this.forYourPatientFiles,
        additional_information: this.additional_information,
        // links: this.links,
        recommended_specialty: this.recommended_specialty,
        // action_explanation: this.action_explanation,
        triage_label: this.triage_label,
        vimeo_link: this.vimeo_link,
        suggested_action: this.suggested_action,
        pointer: this.pointer,
        link: this.link,
        validation_error: this.validation_error,
      };
      this.updateNode(event);
    },
    showNodeStats() {
      this.$refs.nodeStatsModal.openModal();
    },
    closeNodeStats() {
      this.$refs.nodeStatsModal.closeModal();
    },
    handleFileChange(event) {
      this.file = event.files[0];
    },
    handleForYourPatientFilesChange(event) {
      this.forYourPatientFiles = event.files;
    },
    remove() {
      this.rerender = !this.rerender;
      this.file = null;
    },
    uploadLabFile(event) {
      this.$emit('uploadLabFile', {
        node_id: event.node_id,
        lab_file: event.lab_file,
      });
    },
    uploadForYourPatientFiles(event) {
      this.$emit('uploadForYourPatientFiles', {
        node_id: event.node_id,
        for_your_patient_files: event.for_your_patient_files,
      });
    },
    previewLabFile(event) {
      this.$emit('previewLabFile', {
        node_id: event.node_id,
      });
    },
    removeLabFile(event) {
      this.$emit('removeLabFile', {
        node_id: event.node_id,
      });
    },
    removeForYourPatientFile(event) {
      this.$emit('removeForYourPatientFile', {
        node_id: event.node_id,
        filename: event.filename,
      });
    },
  },
  beforeCreate() {
    this.$options.components.SmartPathNode = SmartPathNode;
  },
};
</script>

<style scoped>
/* https://codemyui.com/vertical-family-tree-pure-css/ */

.tree ul {
  padding-top: 20px;
  position: relative;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/* connectors */
.tree li::before {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid var(--vh-black);
  width: 50%;
  height: 20px;
}

.tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid var(--vh-black);
  width: 50%;
  height: 20px;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid var(--vh-black);
}

.multiple-children::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid var(--vh-black);
  width: 0;
  height: 20px;
}

/*Remove left connector from first child and 
right connector from last child*/
.first-sibling::before {
  border-top: 0 none !important;
}

.first-sibling::after {
  border-radius: 5px 0 0 0;
}

.last-sibling::after {
  border: 0 !important;
}

.last-sibling::before {
  border-right: 1px solid var(--vh-black);
  border-radius: 0 5px 0 0;
}
</style>
