<template>
  <div>
    <modal ref="shareReferralWithOrgUnit" @closeModal="closeModal">
      <template v-slot:header>
        <div class="header">
          <div class="px-5 pt-5">
            <Button label="Close" icon="pi pi-times" class="p-button-secondary p-button-outlined p-button-sm" @click="closeShareReferralWithOrgUnit" />
          </div>
        </div>
      </template>

      <template v-slot:body>
        <div class="upload-photo-container max-w-4xl">
          <h1 class="text-3xl font-bold text-blue">Share Consult</h1>
          <div class="seperator"></div>
          <div class="info-paragraph--gray">
            <p>By sharing this with your practice group, the practice group account will have full access to this consult.</p>
          </div>

          <template v-if="referral && referral.org_units.length > 0 && referral.org_units.find((org_unit) => actingUser.id === org_unit.pivot.user_id)">
            <div class="text-bold mb-4 text-left text-xl font-medium text-blue-400">Shared With</div>
            <div v-for="(org_unit, i) in referral.org_units.filter((org_unit) => actingUser.id === org_unit.pivot.user_id)" :key="i" class="bg-gray-25 mb-4 flex w-full flex-col justify-between rounded-lg border-2 border-solid border-blue-200 p-4 lg:flex-row">
              <div class="my-1 flex flex-row">
                <div class="align-center mr-2 flex items-center justify-center rounded bg-blue-50" style="width: 2.5rem; height: 2.5rem">
                  <i class="pi pi-users text-left text-xl text-blue-500"></i>
                </div>
                <div class="flex flex-col items-start">
                  <div class="text-xs font-normal uppercase text-gray-400">Practice Group Name</div>
                  <div class="text-base font-semibold text-blue-500">{{ org_unit.name }}</div>
                </div>
              </div>
              <div class="my-1 flex flex-row">
                <Button label="REVOKE ACCESS" class="p-button-danger" id="revokeAccessBtn" @click="detachOrgUnitToReferralOnClick(org_unit.id)" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="mb-2 w-full text-left" id="selected-patient-container">
              <label class="text-bold mb-4 w-full text-left font-medium text-blue-400" for="selectOrgUnit">Select Practice Group</label>
              <Dropdown :class="['w-full text-left']" v-model="selectedOrgUnitId" :options="actingUser.org_units" optionValue="id" optionLabel="name" id="selectOrgUnit" class="auth-input w-full text-xs font-normal uppercase text-gray-400" placeholder="Not Shared" :showClear="true" />
            </div>
            <div class="text-bold error mb-2 w-full text-left text-lg font-medium" v-if="error.length > 0">
              {{ error }}
            </div>
            <div class="mt-3 flex justify-end">
              <Button @click="attachOrgUnitToReferralOnClick" class="flex w-28 justify-center" id="shareReferralWithOrgUnitSubmit">Submit</Button>
            </div>
          </template>
        </div>
      </template>
    </modal>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import Modal from '@/components/misc/Modal.vue';
import { mapActions, mapGetters } from 'vuex';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';
import Dropdown from 'primevue/dropdown';

export default {
  emits: ['closeShareReferralWithOrgUnit'],
  props: ['openShareReferralWithOrgUnit', 'referral'],
  data() {
    return {
      isLoading: false,
      file: null,
      rerender: false,
      selectedOrgUnitId: null,
      error: '',
    };
  },
  computed: {
    ...mapGetters(['actingUser']),
  },
  methods: {
    ...mapActions(['getReferralById', 'attachOrgUnitToReferral', 'detachOrgUnitToReferral']),
    closeShareReferralWithOrgUnit() {
      this.$refs.shareReferralWithOrgUnit.closeModal();
    },
    closeModal(event) {
      this.$emit('closeShareReferralWithOrgUnit', event);
    },
    attachOrgUnitToReferralOnClick() {
      this.error = '';
      if (this.selectedOrgUnitId !== null) {
        const data = { org_unit_id: this.selectedOrgUnitId, referral_id: this.referral.id, user_id: this.actingUser.id };
        this.isLoading = true;
        this.$toast.removeAllGroups();
        this.attachOrgUnitToReferral(data)
          .then(() => {
            this.isLoading = false;
            this.selectedOrgUnitId = null;
            this.$toast.add({
              severity: 'success',
              summary: 'Consult Shared With Practice Group',
              life: 3000,
            });
            this.$refs.shareReferralWithOrgUnit.closeModal();
            this.$emit('closeShareReferralWithOrgUnit', { show: false });
          })
          .catch(() => {
            this.isLoading = false;
            this.selectedOrgUnitId = null;
            this.showGenericErrorToast();
          });
      } else {
        this.error = 'Please Select Practice Group You Would Like to Share Consult With.';
      }
    },
    detachOrgUnitToReferralOnClick(org_unit_id) {
      const data = { org_unit_id, referral_id: this.referral.id, user_id: this.actingUser.id };
      this.isLoading = true;
      this.detachOrgUnitToReferral(data)
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Revoked Access From Practice Group',
            life: 3000,
          });
          this.$refs.shareReferralWithOrgUnit.closeModal();
          this.$emit('closeShareReferralWithOrgUnit', { show: false });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
  components: {
    Modal,
    Button,
    Loading,
    Dropdown,
  },
  watch: {
    openShareReferralWithOrgUnit(val) {
      if (val) {
        this.$refs.shareReferralWithOrgUnit.openModal();
      }
    },
  },
};
</script>

<style>
.header {
  display: flex;
}
.upload-photo-container {
  height: 100%;
  padding: 20px;
  max-width: 600px;
}
</style>
