<template>
  <div class="mt-1 flex h-full min-h-screen flex-col" v-if="actingUser">
    <div class="relative h-full w-full">
      <div class="absolute z-0 h-64 w-full bg-blue-400"></div>
      <div class="p-4">
        <div class="height-min-content animated fade-in-up relative z-10 mx-auto my-32 max-w-screen-md rounded-xl bg-white py-6 px-6 shadow">
          <div class="absolute left-10 -top-16 z-10 h-28 w-28 rounded-full shadow-sm" @click="showToast">
            <img v-bind:src="actingUser.profile.profile_picture_location" class="mx-auto h-28 w-28 rounded-full bg-white object-cover" v-if="actingUser.profile.profile_picture_location" />
            <div class="h-28 w-28 rounded-full bg-white" v-else>
              <i class="pi pi-user"></i>
            </div>
          </div>
          <div class="relative flex flex-row justify-between pt-8 text-left">
            <div>
              <h2 class="text-lg font-bold text-blue-400 md:text-4xl">
                {{ actingUser.full_name }}
              </h2>
              <h2 class="text-sm font-semibold text-gray-400 lg:text-lg">
                {{ actingUser.email }}
              </h2>
            </div>
            <div v-if="allReferralsMade" class="text-center">
              <h2 class="text-lg font-bold text-blue-400 md:text-4xl">
                {{ allReferralsMade }}
              </h2>
              <h2 class="text-sm font-semibold text-gray-400 lg:text-lg" v-if="isGP(actingUser)">Consults Made</h2>
              <h2 class="text-sm font-semibold text-gray-400 lg:text-lg" v-if="isSpecialist(actingUser)">Consults</h2>
            </div>
          </div>
          <div class="relative my-5 flex w-full flex-row flex-wrap justify-between border-t border-b border-gray-200 py-5 text-base font-semibold text-gray-400">
            <div
              :class="[
                'my-account-links',
                {
                  active: component === 'EditGeneralInfo' || component === 'EditGeneralInfo',
                },
              ]"
              @click="selectInfo('EditGeneralInfo')"
              id="generalLink"
            >
              <div class="pi pi-user-edit" style="fontsize: 1.25rem"></div>
              <span class="pl-2">GENERAL</span>
            </div>
            <div
              :class="[
                'my-account-links',
                {
                  active: component === 'EditAddressInfo' || component === 'EditAddressInfo',
                },
              ]"
              id="addressLink"
              @click="selectInfo('EditAddressInfo')"
            >
              <div class="pi pi-home" style="fontsize: 1.25rem"></div>
              <span class="pl-2">ADDRESS</span>
            </div>
            <div
              :class="[
                'my-account-links',
                {
                  active: component === 'EditNotificationsInfo',
                },
              ]"
              @click="selectInfo('EditNotificationsInfo')"
              id="notificationsLink"
            >
              <div class="pi pi-bell" style="fontsize: 1.25rem"></div>
              <span class="pl-2">NOTIFICATIONS</span>
            </div>
          </div>
          <component :is="component" :loggedInUser="actingUser" @selectInfo="selectInfo"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditGeneralInfo from '@/components/my_account/EditGeneralInfo.vue';
import EditAddressInfo from '@/components/my_account/EditAddressInfo.vue';
import EditNotificationsInfo from '@/components/my_account/EditNotificationsInfo.vue';
import Button from 'primevue/button';

export default {
  computed: {
    ...mapGetters(['actingUser', 'isGP', 'isSpecialist', 'referrals', 'isOrgUnitHead', 'loggedInUser']),
  },
  components: {
    EditGeneralInfo,
    EditAddressInfo,
    EditNotificationsInfo,
    Button,
  },
  data() {
    return {
      file: null,
      success: '',
      errors: [],
      allReferralsMade: null,
      welcomeMessage: this.$route.query.referrer,
      component: 'EditGeneralInfo',
    };
  },
  methods: {
    ...mapActions(['getAllPhoneConsultsofUser', 'getReferralByOrgUnitIdAndUserId']),
    showToast() {
      this.showGenericErrorToast();
    },
    selectInfo(event) {
      if (this.component !== event) {
        this.component = event;
      }
    },
    closeAccount() {
      this.$router.push('closeaccount');
    },
  },
  created() {
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.getReferralByOrgUnitIdAndUserId({ orgUnitId: this.loggedInUser.org_units[0].id, userId: this.actingUser.id })
        .then(() => {
          if (this.referrals) {
            this.allReferralsMade = this.referrals.length;
          }
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    } else {
      this.getAllPhoneConsultsofUser(this.actingUser.id)
        .then(() => {
          if (this.referrals) {
            this.allReferralsMade = this.referrals.length;
          }
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    }
  },
};
</script>
<style>
.height-1000 {
  height: 1000px;
}
.height-min-content {
  height: min-content;
}
.my-account-links {
  cursor: pointer;
  @apply mb-2 flex items-center border-b-2  border-transparent py-1 hover:border-red-500 hover:border-current hover:text-red-500 md:mb-0;
}
.my-account-links.active {
  @apply border-b-2 border-red-500  text-red-500;
}
</style>
