import axios from 'axios';
import globals from '../../globals';
import CapacitorCallPlugin from '@/plugins/CapacitorCallPlugin';
import { globalMixin } from '@/mixins';

const state = {
  displayCall: false,
  callPhoneNumber: '9026949583',
  callAgent: null,
  callClient: null,
  displayPhoneNumber: false,
  acsToken: localStorage.getItem('acsToken'),
  callDisplayNames: localStorage.getItem('callDisplayNames'),
};

const getters = {
  displayCall: (state) => {
    return state.displayCall;
  },
  callPhoneNumber: (state) => {
    return state.callPhoneNumber;
  },
  callAgent: (state) => {
    return state.callAgent;
  },
  callClient: (state) => {
    return state.callClient;
  },
  displayPhoneNumber: (state) => {
    return state.displayPhoneNumber;
  },
  displayPhoneNumber: (state) => {
    return state.displayPhoneNumber;
  },
  acsToken: (state) => {
    if (state.acsToken !== null) {
      return JSON.parse(state.acsToken);
    } else return null;
  },
  hasValidAcsToken: (state) => {
    return state.acsToken ? new Date() < Date.parse(JSON.parse(state.acsToken).expires_on) : false;
  },
  callDisplayNames: (state) => {
    return state.callDisplayNames != null ? JSON.parse(state.callDisplayNames) : [];
  },
};

// actions
const actions = {
  acsSaveCallId(context, data) {
    return new Promise((resolve, reject) => {
      if (data.callee_phone_number?.length < 11) {
        data.callee_phone_number = '1' + data.callee_phone_number;
      }
      axios
        .post(globals.APIs.acsSaveCallIdUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAcsAccessToken(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAcsAccessTokenUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then(async (response) => {
          context.commit('setUserAccessToken', response.data.data);
          if (globalMixin.methods.isNativePlatform()) {
            CapacitorCallPlugin.setAccessToken({ acsAccessToken: response.data.data?.access_token, acsAccessTokenExpiry: response.data.data?.expires_on, applicationPhoneNumber: response.data.data?.application_phone_number });
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  sendTextReminder(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.sendTextReminderUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  verifyCallHashUrl(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.verifyCallHashUrl(), data, {})
        .then((response) => {
          context.commit('setReferral', response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  verifyCallHashLoginUrl(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.verifyCallHashLoginUrl(), data, {})
        .then((response) => {
          // TODO add check they are not loggedIn
          context
            .dispatch('storeLoggedInUser', response.data)
            .then((res) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.response);
            });
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getCallDisplayNames(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getCallDisplayNamesUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: { user_id: context.rootGetters.loggedInUser.id },
        })
        .then((response) => {
          context.commit('setCallDisplayNames', response.data.data);
          CapacitorCallPlugin.setCallDisplayNames({ callDisplayNames: response.data.data });

          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  createOrUpdateDisplayName(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.createOrUpdateDisplayNameUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setCallDisplayNames', response.data.data);
          if (globalMixin.methods.isNativePlatform()) {
            CapacitorCallPlugin.setCallDisplayNames({ callDisplayNames: response.data.data });
          }

          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  deleteDisplayName(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(globals.APIs.deleteDisplayNameUrl(), {
          params: {
            call_display_name_id: data.id,
          },
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setCallDisplayNames', response.data.data);
          if (globalMixin.methods.isNativePlatform()) {
            CapacitorCallPlugin.setCallDisplayNames({ callDisplayNames: response.data.data });
          }

          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getCallRecordsByUserId(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getCallRecordsByUserIdUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: data,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  saveCallNetworkDiagnostic(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveCallNetworkDiagnosticUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveCallMediaDiagnostic(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveCallMediaDiagnosticUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveMediaStatisticsReport(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveMediaStatisticsReportUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveDeviceVoipToken(context, data) {
    data.device_voip_token = data.deviceVoipToken;
    data.user_id = context.rootGetters.loggedInUser.id;
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.saveDeviceVoipTokenUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};

// mutations
const mutations = {
  setDisplayCall: (state, data) => {
    state.displayCall = data;
  },
  setCallPhoneNumber: (state, data) => {
    state.callPhoneNumber = data;
  },
  setCallAgent: (state, data) => {
    state.callAgent = data;
  },
  setCallClient: (state, data) => {
    state.callClient = data;
  },
  setDisplayPhoneNumber: (state, data) => {
    state.displayPhoneNumber = data;
  },
  setUserAccessToken: (state, data) => {
    state.acsToken = JSON.stringify(data);
    localStorage.setItem('acsToken', JSON.stringify(data));
  },
  setCallDisplayNames: (state, data) => {
    state.callDisplayNames = JSON.stringify(data);
    localStorage.setItem('callDisplayNames', JSON.stringify(data));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
