<template>
  <div v-if="!isLoading" class="main-inner-wrap">
    <div class="flex flex-col items-center w-full">
      <div class="animated fade-in-up mt-6 w-full rounded-lg bg-white p-5 pt-0 border-1 max-w-2xl">
        <div class="border-grey-400 flex pb-2 border-b-2">
          <div class="bg-white-400 title-banner w-full justify-start p-2 pt-4 hidden md:block">
            <img src="@/assets/SmartPathLogo.svg" alt="" class="h-8 mt-3" />
          </div>

          <div class="flex flex-col bg-white-400 justify-end p-2 pt-4">
            <Button @click="showSmartPathSupportModal = true" class="p-button-text" label="Need Some Help?"><i class="pi pi-question-circle navbar-icon text-gray-500"></i> </Button>
          </div>
        </div>

        <div v-if="selectedTransaction != null && selectedTransaction.status == 'Complete (In Person)'">
          <transition name="slide-up-fade">
            <div class="border-grey-400 mt-3 flex flex-col items-center rounded-md border-2 bg-white p-8">
              <i class="pi pi-check-square text-red-500 mb-2" style="font-size: 3rem"></i>
              <h3 id="reg-thank-you-sub-heading" class="text-gray-400">In Person Referral Submitted</h3>
              <h1 id="reg-thank-you-heading" class="text-blue text-3xl font-bold">The triage officer will contact you.</h1>
              <div class="seperator"></div>
            </div>
          </transition>

          <div class="mt-4 flex flex-col w-full items-center justify-between rounded-2xl bg-white p-6 text-left shadow border-1">
            <div class="w-full">
              <div class="mb-3 flex flex-row items-start justify-between border-b pb-2">
                <div class="flex flex-row">
                  <div class="text-blue-400">
                    <h2 class="text-left font-semibold text-lg text-gray-600">Triage Notes</h2>
                    <div class="flex flex-col text-left">
                      <h5 class="text-xs text-gray-400">The SmartPath physician's team will review the case, and leave a message here if needed.</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col w-full">
              <div class="flex flex-row">
                <div class="border-b border-solid border-gray-100 pb-1">
                  <div class="consult-field-label">Triage Review Status</div>
                  <div v-if="selectedTransaction.contributor_has_seen == true" class="font-bold text-gray-500 font-display text-sm">Review Status: Reviewed</div>
                  <div v-else class="font-bold text-gray-500 font-display text-sm">Pending Review</div>
                </div>
              </div>

              <div class="flex flex-row">
                <div class="border-b border-solid border-gray-100 pb-1">
                  <div class="consult-field-label">Triage Message</div>
                  <div v-if="selectedTransaction.triage_message != null" class="font-bold text-gray-500 font-display text-sm">{{ selectedTransaction.triage_message }}</div>
                  <div v-else class="font-bold text-gray-500 font-display text-sm">The triage officer has not yet sent a message.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="justify-start flex flex-row items-star pt-4">
            <h1 class="font-semibold text-sm text-gray-600">Not what you're looking for?</h1>
            <div @click="showCancelInPersonModal = true" class="text-sm ml-1 font-semibold underline cursor-pointer rounded-md">Go Back</div>
          </div>
        </div>

        <div v-else-if="selectedTransaction != null && selectedTransaction.status == 'IBD Ready'">
          <transition name="slide-up-fade">
            <div class="border-grey-400 mt-3 flex flex-col items-center rounded-md border-2 bg-white p-8">
              <i class="pi pi-check-square text-red-500 mb-2" style="font-size: 3rem"></i>
              <h3 id="reg-thank-you-sub-heading" class="text-gray-400">Referral Submitted to Nova Scotia Collaborative IBD Clinic</h3>
              <h1 id="reg-thank-you-heading" class="text-blue text-3xl font-bold">The IBD clinic will contact you.</h1>
              <div class="seperator"></div>
              <p>Thank you for referring via the pathway.</p>
            </div>
          </transition>
        </div>

        <div v-else>
          <div class="w-full" v-if="smartPath.smart_path[curr]['node_type'] != 'terminal' && smartPath.smart_path[curr]['node_type'] != 'link'">
            <Message v-if="$route.params.transactionId == ''" class="text-left" :closable="false">Enter your patient's information for SmartPath. This information will later be used to autofill your referral request. </Message>
            <span v-else-if="smartPath.smart_path[curr]['node_type'] == 'decision' || smartPath.smart_path[curr]['node_type'] == 'root'">
              <Message class="text-left" :closable="false">Answer each question about the given patient. Each answer will determine the next according to the SmartPath. </Message>
              <Message v-if="smartPath.smart_path[curr]['additional_information']" class="text-left" :closable="false">Need more information for this question? Click Learn More to find out. </Message>
              <Message v-if="smartPath.smart_path[curr]['lab_access']" class="text-left" :closable="false">Need to visit the lab for this question? Click Download Lab Form to get the required Lab Requisition form.</Message>
            </span>
          </div>

          <div class="flex flex-col items-center" v-if="$route.params.transactionId == ''">
            <div class="flex w-full flex-col justify-center pt-4">
              <h1 class="pt-4 text-lg md:text-xl font-semibold text-gray-600">Enter SmartPath Patient Information</h1>
            </div>
            <div class="mt-4 w-full">
              <form method="post" autocomplete="off" @submit.prevent="submit" class="grid grid-cols-2 gap-1 gap-x-4" ref="form">
                <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
                  <label :class="['auth-input', { error: errors.patient_first_name }]" for="patient_first_name"
                    >Patient First Name
                    <span class="error">&nbsp;*</span>
                  </label>
                  <InputText type="text" v-model="patientFirstName" class="auth-input" required maxLength="100" id="patient_first_name" />
                  <div class="error" id="patient_first_name_error">{{ errors.patient_first_name }}</div>
                </div>
                <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
                  <label :class="['auth-input', { error: errors.patient_last_name }]" for="patient_last_name">
                    Patient Last Name
                    <span class="error">&nbsp;*</span>
                  </label>
                  <InputText type="text" v-model="patientLastName" class="auth-input" required maxLength="100" id="patient_last_name" />
                  <div class="error" id="patient_last_name_error">{{ errors.patient_last_name }}</div>
                </div>
                <div class="col-start-1 col-end-3 sm:col-auto">
                  <label :class="['auth-input', { error: errors.patient_dob }]" for="patient_dob"
                    >Patient Date of Birth
                    <span class="error">&nbsp;*</span>
                  </label>
                  <VHCDatePicker :inputModel="patientDateOfBirth" :inputId="'patient_dob'" :maxInputDate="new Date()" @dateSelected="(val) => (patientDateOfBirth = val)" :displayAge="true" />
                  <div class="error" id="patient_dob_error">{{ errors.patient_dob }}</div>
                </div>
                <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
                  <label :class="['auth-input', { error: errors.health_card_province }]" for="health_card_province">
                    Health Card/Policy Type
                    <span class="error">&nbsp;*</span>
                  </label>
                  <Dropdown :class="['w-full text-left']" v-model="patientHealthCardProvince" :options="healthCardProvinceOptions" optionValue="name" optionLabel="name" id="health_card_province" class="auth-input w-full text-xs font-normal text-gray-400" />
                  <div class="error" id="health_card_province_error">{{ errors.health_card_province }}</div>
                </div>
                <template v-if="!hasProvinceOfPractice(actingUser, 'ON')">
                  <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
                    <label :class="['auth-input', { error: errors.health_card_number }]" for="health_card_number">
                      {{ healthCardProvinceOptionsRequireIdNumber().includes(patientHealthCardProvince) ? 'ID Number' : 'Health Card/ID Number' }}
                      <span class="error">&nbsp;*</span>
                    </label>
                    <InputText type="text" v-model="patientHealthCardNumber" class="auth-input" required maxLength="255" id="health_card_number" />
                    <div class="error" id="health_card_number_error">{{ errors.health_card_number }}</div>
                  </div>
                </template>
                <template v-else>
                  <div class="col-auto mb-2 md:flex md:justify-between">
                    <div class="mr-2 w-full md:w-7/12">
                      <label :class="['auth-input', { error: errors.health_card_number }]" for="health_card_number">
                        {{ healthCardProvinceOptionsRequireIdNumber().includes(patientHealthCardProvince) ? 'ID Number' : 'Health Card/ID Number' }}
                        <span class="error">&nbsp;*</span>
                      </label>
                      <InputText type="text" v-model="patientHealthCardNumber" class="auth-input" required maxLength="255" id="health_card_number" />
                      <div class="error" id="health_card_number_error">{{ errors.health_card_number }}</div>
                    </div>
                    <div class="w-full md:w-5/12">
                      <label :class="['auth-input', { error: errors.health_card_version_number }]" for="health_card_version_number"
                        >Version Number
                        <span class="error">&nbsp;*</span>
                      </label>
                      <InputText type="text" v-model="patientHealthCardVersionNumber" class="auth-input" required maxLength="255" id="health_card_version_number" />
                      <div class="error" id="health_card_version_number_error">{{ errors.health_card_version_number }}</div>
                    </div>
                  </div>
                </template>
                <div class="select-button-container col-start-1 col-end-3 mb-2 sm:col-auto">
                  <label :class="['auth-input', { error: errors.patient_sex }]" for="patient_sex"
                    >Patient Sex
                    <span class="error">&nbsp;*</span>
                  </label>
                  <SelectButton v-model="patientSex" :options="['Male', 'Female']" id="patient_sex" class="auth-input w-full" style="height: 50px" />
                  <div class="error" id="patient_sex_error">{{ errors.patient_sex }}</div>
                </div>
              </form>
              <div class="relative mb-1 mt-5 flex w-full flex-row justify-end">
                <div @click="cancelPatientInfo" class="text-sm h-12 font-semibold w-28 border-solid mr-6 cursor-pointer rounded-md border-2 border-gray-800 bg-gray-800 p-3 text-white hover:bg-black">Cancel</div>
                <div @click="saveResult" id="smartPathStart" class="text-sm h-12 w-28 font-semibold cursor-pointer rounded-md border-2 border-solid border-gray-800 bg-white p-3 text-black hover:bg-gray-200">Start</div>
              </div>
            </div>
          </div>
          <div v-else class="flex w-full place-items-center justify-center gap-4">
            <div class="col-span-5 flex w-full justify-center">
              <div v-if="smartPath.smart_path[curr]['node_type'] == 'decision' || smartPath.smart_path[curr]['node_type'] == 'root'" class="w-full">
                <div class="align-start relative mb-1">
                  <div class="rich-text w-full text-left text-lg font-display font-semibold mb-4" v-html="smartPath.smart_path[curr]['question']" />
                </div>

                <div class="flex w-full flex-row text-left">
                  <div class="text-sm text-gray-700">Select an answer from the following options:</div>
                </div>

                <div v-for="(child, index) in smartPath.smart_path[curr]['children']" :key="index" class="border-grey-400 recommendation-tile group mt-4 flex w-full flex-row rounded-md border-2 bg-white" @click="nextQuestion(child['answer'], child['next'])">
                  <div class="flex w-full flex-col">
                    <div class="flex flex-row">
                      <div :id="'smartPathAnswer_' + index" class="text-grey-400 group w-full transform py-4 px-4 text-left text-sm md:text-lg font-semibold transition duration-500 ease-in-out group-hover:translate-x-2">{{ child['answer'] }}</div>
                    </div>
                  </div>
                </div>

                <div class="relative mb-1 mt-5 flex w-full flex-col sm:flex-row justify-start">
                  <div v-if="curr != '0'" @click="popToPreviousQuestion" class="text-sm h-12 font-semibold w-28 border-solid mr-6 cursor-pointer rounded-md border-2 border-gray-800 bg-gray-800 p-3 text-white hover:bg-black">Go Back</div>
                  <div v-if="smartPath.smart_path[curr]['additional_information']" @click="showAdditionalInfo" id="learnMoreButton" class="text-sm h-12 w-28 mt-2 sm:mt-0 mr-6 font-semibold cursor-pointer rounded-md border-2 border-solid border-gray-800 bg-white p-3 text-black hover:bg-gray-200">Learn More</div>
                  <div v-if="smartPath.smart_path[curr]['lab_access']" @click="downloadLabAccess" id="downloadLabFormButton" class="text-sm h-12 w-40 mr-6 mt-2 sm:mt-0 font-semibold cursor-pointer rounded-md border-2 border-solid border-gray-800 bg-white p-3 text-black hover:bg-gray-200">Download Lab Form</div>
                  <div v-if="smartPath.smart_path[curr]['for_your_patient_files'] && smartPath.smart_path[curr]['for_your_patient_files'].length > 0" @click="showForYourPatientModal = true" id="forYourPatientModal" class="text-sm h-12 mt-2 sm:mt-0 w-40 mr-6 font-semibold cursor-pointer rounded-md border-2 border-solid border-gray-800 bg-white p-3 text-black hover:bg-gray-200">For Your Patient</div>
                  <div v-if="smartPath.smart_path[curr]['vimeo_link']" @click="showVimeoLinkModal = true" id="vimeoLinkModal" class="text-sm mt-2 sm:mt-0 h-12 w-40 font-semibold cursor-pointer rounded-md border-2 border-solid border-gray-800 bg-white p-3 text-black hover:bg-gray-200">Video Resource</div>
                </div>
              </div>

              <div v-else-if="smartPath.smart_path[curr]['node_type'] == 'link'">
                <div class="flex w-full flex-row bg-white">
                  <div class="flex flex-col justify-start"></div>
                  <div class="mb-2 flex w-full flex-col pt-4 pb-6 pr-8">
                    <div class="text-grey-400 w-full py-2 text-left text-xl font-semibold">SmartPath Link</div>
                  </div>
                </div>

                <div class="align-start relative" style="width: 35rem">
                  <div class="rich-text w-full text-left mb-4">Based on your answers, it is recommended that you change to the {{ smartPath.smart_path[curr]['link']['name'] }} SmartPath!</div>
                  <div class="rich-text w-full text-left">Warning: although the patient information will be saved, by clicking Go To Linked SmartPath, you will lose your progress on this one, if you want to use this SmartPath again you will have to start at the beginning.</div>
                </div>

                <div class="border-grey-400 recommendation-tile group mt-4 flex w-full flex-row rounded-md border-2 bg-white" @click="linkToSmartPath(smartPath.smart_path[curr]['link']['id'])">
                  <div class="flex w-full flex-col">
                    <div class="flex flex-row">
                      <div id="linkedSmartPathButton" class="text-grey-400 group w-full transform py-4 px-4 text-left text-xl font-semibold transition duration-500 ease-in-out group-hover:translate-x-2">Go To {{ smartPath.smart_path[curr]['link']['name'] }} SmartPath</div>
                    </div>
                  </div>
                </div>

                <div class="relative mb-1 mt-5 flex w-full flex-row justify-start">
                  <div v-if="curr != '0'" @click="popToPreviousQuestion" class="text-sm h-12 font-semibold w-28 border-solid mr-6 cursor-pointer rounded-md border-2 border-gray-800 bg-gray-800 p-3 text-white hover:bg-black">Go Back</div>
                </div>
              </div>

              <div v-else-if="smartPath.smart_path[curr]['node_type'] == 'terminal'" class="flex w-full flex-col items-center justify-center bg-white">
                <div class="flex w-full flex-row bg-white">
                  <div class="flex flex-col justify-start"></div>
                  <div class="mb-2 flex w-full flex-col pt-4 pb-6 pr-8">
                    <div class="text-grey-400 w-full py-2 text-left text-xl font-semibold">SmartPath Recommendation</div>
                    <div class="rich-text w-full text-left" v-html="smartPath.smart_path[curr]['recommended_action']" />
                  </div>
                </div>

                <div v-if="smartPath.smart_path[curr]['additional_information']" class="relative mb-1 mt-5 flex w-full flex-row justify-start">
                  <div @click="showAdditionalInfo" class="text-sm h-12 w-28 font-semibold cursor-pointer rounded-md border-2 border-solid border-gray-800 bg-white p-3 text-black hover:bg-gray-200">Learn More</div>
                </div>

                <div class="flex flex-col w-full py-2">
                  <div class="w-full">
                    <div class="mb-3 flex flex-row items-start justify-between border-b pb-2">
                      <div class="flex flex-row">
                        <div class="text-blue-400">
                          <h2 class="text-left font-semibold text-lg text-gray-600">Leave a Message for Triage</h2>
                          <div class="flex flex-col text-left">
                            <h5 class="text-xs text-gray-400">Before choosing a next step, leave a message for the triage officer regarding this SmartPath Referral.</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-row w-full">
                    <div v-if="terminalNode != null && terminalNode.triage_label" class="w-full justify-start">
                      <div class="flex flex-row text-xs text-gray-500">
                        Triage Label (for current terminal node)
                        <br />
                      </div>
                      <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
                        <div>{{ terminalNode.triage_label }}</div>
                      </div>
                    </div>

                    <div v-if="selectedTransaction.linked_smart_path_transaction_id" class="w-full justify-start">
                      <div class="flex flex-row text-xs text-gray-500">
                        Linked from another SmartPath
                        <br />
                      </div>
                      <div class="mb-2 flex flex-row border-b border-solid border-gray-100 font-semibold text-gray-500 font-display text-sm">
                        <div>Linked from SmartPath Result ID: {{ selectedTransaction.linked_smart_path_transaction_id }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-row w-full">
                    <Textarea v-model="messageForTriage" id="messageForTriageTextArea" class="auth-input" :autoResize="true" rows="5" cols="30" maxLength="65535" />
                  </div>
                  <div class="flex flex-row w-full">
                    <div class="mt-2 flex w-full justify-end">
                      <Button @click="saveMessageForTriage(selectedTransaction.id)" id="saveMessageForTriageButton" v-on:click.stop icon="pi pi-check" label="Save Message" class="p-button-sm" />
                    </div>
                  </div>
                </div>

                <div class="flex w-full flex-row">
                  <div class="text-grey-400 w-full py-2 text-left text-xl font-semibold">Select a Next Step...</div>
                </div>

                <div v-if="smartPath.name != 'Inflammatory Bowel Disease Flare'" class="w-full">
                  <div class="border-grey-400 recommendation-tile flex w-full flex-row rounded-md border-2 bg-white p-4 pr-0" @click="consultWithSelectedContributor">
                    <div class="flex w-full flex-col">
                      <div class="flex flex-col">
                        <div class="flex flex-row">
                          <div class="text-grey-400 w-full py-2 text-left text-xl font-semibold">Book a Phone Consult</div>
                          <div class="flex w-full justify-end">
                            <div v-if="smartPath.smart_path[curr]['suggested_action'] != null && smartPath.smart_path[curr]['suggested_action'].value == 'author'" class="flex flex-row items-center bg-red-400 px-3 py-2 font-bold">
                              <i class="pi pi-star-fill mr-1 text-xl text-white" style="font-size: 0.8rem" />
                              <h1 class="font-semibold text-white">Recommended</h1>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-row">
                          <div class="text-left text-gray-700 mt-3">Book a VH phone consult with {{ displayNameByRole(selectedTransaction.smart_path_person) }} to discuss the results of this pathway.</div>
                        </div>
                      </div>
                      <div class="flex w-full flex-col md:flex-row justify-evenly">
                        <div class="mb-2 mt-4 flex w-full flex-row justify-end">
                          <div id="smartPathBookWithAuthor" class="w-40 mr-6 cursor-pointer rounded-md border-2 border-solid border-black bg-white p-3 font-medium text-black hover:bg-gray-200">Consult Now</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="smartPath.smart_path[curr]['suggested_action'] != null && smartPath.smart_path[curr]['suggested_action'].value == 'inperson'" class="border-grey-400 recommendation-tile flex w-full flex-row rounded-md mt-2 border-2 bg-white p-4 pr-0" @click="inPersonConsult">
                    <div class="flex w-full flex-col">
                      <div class="flex flex-col">
                        <div class="flex flex-row">
                          <div class="text-grey-400 w-full py-2 text-left text-xl font-semibold">In Person Referral</div>
                          <div class="flex w-full justify-end">
                            <div v-if="smartPath.smart_path[curr]['suggested_action'] != null && smartPath.smart_path[curr]['suggested_action'].value == 'inperson'" class="flex flex-row items-center bg-red-400 px-3 py-2 font-bold">
                              <i class="pi pi-star-fill mr-1 text-xl text-white" style="font-size: 0.8rem" />
                              <h1 class="font-semibold text-white">Recommended</h1>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-row mt-3">
                          <div class="text-left text-gray-700">Book an In Person Referral to discuss the results of this pathway.</div>
                        </div>
                      </div>
                      <div class="flex w-full flex-col md:flex-row justify-evenly">
                        <div class="mb-2 mt-4 flex w-full flex-row justify-end">
                          <div id="smartPathBookInPerson" class="w-40 mr-6 cursor-pointer rounded-md border-2 border-solid border-black bg-white p-3 font-medium text-black hover:bg-gray-200">Consult Now</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="border-grey-400 recommendation-tile mt-2 flex w-full flex-row rounded-md border-2 bg-white p-4 pr-0" @click="offlineDownload">
                    <div class="flex w-full flex-col">
                      <div class="flex flex-col">
                        <div class="flex flex-row">
                          <div class="text-grey-400 w-full py-2 text-left text-xl font-semibold">Offline Referral</div>
                          <div class="flex w-full justify-end">
                            <div v-if="smartPath.smart_path[curr]['suggested_action'] != null && smartPath.smart_path[curr]['suggested_action'].value == 'offline'" class="flex flex-row items-center bg-red-400 px-3 py-2 font-bold">
                              <i class="pi pi-star-fill mr-1 text-xl text-white" style="font-size: 0.8rem" />
                              <h1 class="font-semibold text-white">Recommended</h1>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-row mt-3">
                          <div class="w-full text-left text-gray-700">Download the SmartPath Referral for offline use with your usual referral channels.</div>
                        </div>
                      </div>
                      <div class="flex w-full flex-row justify-evenly">
                        <div class="mb-2 mt-4 flex w-full flex-row justify-end">
                          <div class="w-40 mr-6 cursor-pointer rounded-md border-2 border-solid border-black bg-white p-3 font-medium text-black hover:bg-gray-200">Download Result</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-for="specialty in smartPath.smart_path[curr]['recommended_specialty']" :key="specialty.id" class="border-grey-400 recommendation-tile mt-2 flex w-full flex-row rounded-md border-2 bg-white p-4 pr-0" @click="makeReferralToSuggestedSpecialty(specialty)">
                    <div class="flex w-full flex-col">
                      <div class="flex flex-col">
                        <div class="flex flex-row">
                          <div class="text-grey-400 w-full py-2 text-left text-xl font-semibold">Consult with {{ specialty.name }}</div>
                          <div class="flex w-full justify-end">
                            <div v-if="smartPath.smart_path[curr]['suggested_action'] != null && smartPath.smart_path[curr]['suggested_action'].value == 'specialty'" class="flex flex-row items-center bg-red-400 px-3 py-2 font-bold">
                              <i class="pi pi-star-fill mr-1 text-xl text-white" style="font-size: 0.8rem" />
                              <h1 class="font-semibold text-white">Recommended</h1>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-row mt-3">
                          <div class="w-full text-left text-gray-700">Book a VH phone consult with {{ specialty.name }} to discuss the results of this pathway.</div>
                        </div>
                      </div>
                      <div class="flex w-full flex-row justify-evenly">
                        <div class="mb-2 mt-4 flex w-full flex-row justify-end">
                          <div class="w-40 mr-6 cursor-pointer rounded-md border-2 border-solid border-black bg-white p-3 font-medium text-black hover:bg-gray-200">Consult Now</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="w-full">
                  <div class="border-grey-400 recommendation-tile mt-2 flex w-full flex-row rounded-md border-2 bg-white" @click="ibdClinic">
                    <div class="flex flex-col justify-start">
                      <!-- <div class="flex justify-start h-52 w-60 pr-4 m-0"> -->
                      <div class="h-38 m-0 flex w-44 justify-start pr-4">
                        <img class="rounded-l-md" src="@/assets/SmartPath-NSCIBD-logo.png" />
                      </div>
                    </div>
                    <div class="mt-2 flex w-full flex-col">
                      <div class="flex flex-row">
                        <div class="text-grey-400 w-full py-2 text-left text-xl font-semibold">Refer to Nova Scotia Collaborative IBD Clinic</div>
                        <div class="flex justify-end">
                          <div class="flex flex-row items-center bg-red-400 px-3 py-2 font-bold">
                            <i class="pi pi-star-fill mr-1 text-xl text-white" style="font-size: 0.8rem" />
                            <h1 class="font-semibold text-white">Recommended</h1>
                          </div>
                        </div>
                      </div>
                      <div class="flex w-full flex-row justify-evenly">
                        <div class="my-2 flex flex-row">
                          <div class="mt-4 w-full text-left text-gray-700"></div>
                        </div>
                        <div class="mb-2 mt-10 flex w-full flex-row justify-end">
                          <div class="mr-6 cursor-pointer rounded-md border-2 border-solid border-black bg-white p-3 font-medium text-black hover:bg-gray-200">Refer</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="relative mb-1 mt-5 flex w-full flex-row justify-start">
                  <div v-if="curr != '0'" @click="popToPreviousQuestion" class="text-sm h-12 font-semibold w-28 border-solid mr-6 cursor-pointer rounded-md border-2 border-gray-800 bg-gray-800 p-3 text-white hover:bg-black">Go Back</div>
                  <div v-if="smartPath.smart_path[curr]['additional_information'] && smartPath.smart_path[curr]['node_type'] != 'terminal'" @click="showAdditionalInfo" class="text-sm h-12 w-28 font-semibold cursor-pointer rounded-md border-2 border-solid border-gray-800 bg-white p-3 text-black hover:bg-gray-200">Learn More</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal ref="additionalInfoModal">
    <template v-slot:header>
      <div class="flex w-full justify-center">
        <h1 class="pt-4 text-2xl font-bold text-blue">
          <label class="text-sm text-gray-500">{{ this.smartPath['name'] }}</label>
          <div>Learn More</div>
        </h1>
      </div>
    </template>

    <template v-slot:body>
      <div class="flex flex-col p-8" style="width: 35rem">
        <div id="learnMoreModalBody" class="rich-text w-full text-left" v-html="smartPath.smart_path[curr]['additional_information']" />
      </div>

      <div class="relative mb-1 flex w-full flex-row place-content-end pt-4 pr-8">
        <div @click="this.$refs.additionalInfoModal.closeModal()" id="closeLearnMoreButton" class="text-sm h-12 font-semibold w-28 border-solid cursor-pointer rounded-md border-2 border-gray-800 bg-gray-800 p-3 text-white hover:bg-black">Close</div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-s rounded-b py-2 pb-4"></div>
    </template>
  </modal>

  <Dialog v-model:visible="showForYourPatientModal" modal header="For Your Patient" :style="{ width: '50vw' }" :dismissable-mask="true" :draggable="false" id="forYourPatientModal">
    <Message severity="info" :closable="false" class="text-left">
      <span>Download resources here to help with patient management.</span>
    </Message>
    <div class="mb-5">
      <div class="border-b border-solid border-gray-100 py-2 flex flex-row justify-between items-center" v-for="(file, index) in smartPath.smart_path[curr]['for_your_patient_files']" :key="index">
        <div class="flex flex-col justify-start text-start">
          <div @click="downloadForYourPatientFile(file, formatForYourPatientFile(file))" class="consult-field-text hover:cursor-pointer">{{ index + 1 }}. {{ formatForYourPatientFile(file) }}</div>
        </div>
        <div>
          <Button icon="pi pi-download" @click="downloadForYourPatientFile(file, formatForYourPatientFile(file))" class="p-button-rounded p-button-secondary p-button-text hover:cursor-pointer" v-tooltip.bottom="'Click to download this file.'" :id="'downloadForYourPatientFileButton-' + index" />
        </div>
      </div>
    </div>
  </Dialog>

  <Dialog v-model:visible="showVimeoLinkModal" modal header="Video Resource" :style="{ width: '50vw' }" :dismissable-mask="true" :draggable="false" id="vimeoLinkModal">
    <div id="lecture_video" v-if="smartPath.smart_path[curr]['vimeo_link']" ref="videoPlayer" class="relative flex flex-col justify-center w-full max-w-5xl mb-10">
      <vimeo-player ref="player" :video-url="smartPath.smart_path[curr]['vimeo_link']" :options="{ responsive: true }" @ready="onReady" />
      <div v-if="!overlayHidden" @click="playAndHideOverlay()" class="flex flex-col items-center justify-center w-full max-w-5xl mb-10 transition duration-300 ease-out cursor-pointer video-player-branded-overlay">
        <img src="@/assets/Virtual-Hallway-Logo-Nav.png" class="mb-2 video-logo" />
        <i class="mb-4 text-white transition duration-300 ease-in-out fas fa-play-circle hover:text-gray-400 video-play-icon"></i>
        <h3 class="font-medium text-white">Click to Play</h3>
      </div>
    </div>
  </Dialog>

  <Dialog v-model:visible="showCancelInPersonModal" modal header="Are you sure you want to go back?" :style="{ width: '50vw' }" :dismissable-mask="true" :draggable="false" id="cancelInPersonModal">
    <div>By canceling your decision, you may be giving up your spot on the triage officer's waitlist and workflow. This may effect the visibility of your referral. You are always able to return to this decision.</div>
    <div class="relative mb-1 mt-5 flex w-full flex-row justify-start">
      <div @click="showCancelInPersonModal = false" class="text-sm h-12 font-semibold w-28 border-solid mr-6 cursor-pointer rounded-md border-2 border-gray-800 bg-gray-800 p-3 text-white hover:bg-black">Stay Here</div>
      <div @click="cancelDecision" class="text-sm h-12 w-30 font-semibold cursor-pointer rounded-md border-2 border-solid border-gray-800 bg-white p-3 text-black hover:bg-gray-200">Cancel In Person</div>
    </div>
  </Dialog>

  <Dialog v-model:visible="showSmartPathSupportModal" modal header="Need some help?" :style="{ width: '50vw' }" :dismissable-mask="true" :draggable="false" id="smartPathSupportModal">
    <div class="w-full px-3">
      <div class="flex flex-row text-md font-semibold text-gray-600">SmartPath is new, and we're working hard to improve it!</div>
      <div class="flex flex-row text-md font-semibold text-gray-600">If you encounter any bugs or confusing workflows, please reach out for assistance at noah.sealy@virtualhallway.ca or call me at (902) 266-8639. I'm here to help and will get back to you ASAP.</div>
      <div class="mt-2 flex flex-row text-md text-gray-600">In your email, please include that you are using the "{{ smartPath.name }}" SmartPath and you are on Node ID {{ curr }}.</div>
    </div>
  </Dialog>

  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Loading from 'vue-loading-overlay';
import Modal from '@/components/misc/Modal.vue';
import InputText from 'primevue/inputtext';
import moment from 'moment';
import { createPopup } from '@typeform/embed';
import '@typeform/embed/build/css/popup.css';

export default {
  components: {
    // Button,
    Loading,
    Modal,
    InputText,
  },
  data() {
    return {
      selectedViewOption: null,
      viewOptions: [
        { label: 'Answer Stack', value: 'answerStack' },
        { label: 'Additional Information', value: 'additionalInformation' },
      ],
      isLoading: false,
      patientFirstName: '',
      patientLastName: '',
      patientSex: '',
      patientHealthCardNumber: '',
      patientHealthCardVersionNumber: '',
      patientDateOfBirth: '',
      patientHealthCardProvince: '',
      groupNumber: '',
      nodeStartTime: moment.now(),
      errors: {},
      healthCardProvinceOptions: [],
      showForYourPatientModal: false,
      showVimeoLinkModal: false,
      playerReady: false,
      overlayHidden: false,
      responsiveOptions: [
        {
          breakpoint: '1199px',
          numVisible: 3,
          numScroll: 1,
        },
        {
          breakpoint: '1150px',
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: '767px',
          numVisible: 1,
          numScroll: 1,
        },
      ],
      showCancelInPersonModal: false,
      showSmartPathSupportModal: false,
      messageForTriage: '',
    };
  },
  computed: {
    ...mapGetters(['actingUser', 'loggedInUser', 'smartPath', 'curr', 'answerStack', 'selectedTransaction', 'smartPathReferralForm', 'selectedContributor', 'allProvinces', 'hasProvinceOfPractice']),
  },
  emits: ['goToResults'],
  updated() {
    // need this as quill is stripping the target=_blank from nodes with children in builder
    document.querySelectorAll('.rich-text a').forEach(function (a) {
      a.setAttribute('target', '_blank');
    });
  },
  created() {
    this.isLoading = true;
    this.selectedViewOption = { label: 'Answer Stack', value: 'answerStack' };
    this.smartPathSetUp();
  },
  methods: {
    formatForYourPatientFile(file) {
      /*
        in our db the file is saved as SmartPathId_NodeID_filename, 
        so this filters out everything before (and including) the first two underscores 
        so we just show the user the filename
      */
      let firstUnderscoreIndex = file.indexOf('_');
      let secondUnderscoreIndex = file.indexOf('_', firstUnderscoreIndex + 1);
      return file.substring(secondUnderscoreIndex + 1);
    },
    downloadForYourPatientFile(file, filename) {
      this.isLoading = true;
      let stat_data = JSON.stringify({ node_id: this.curr, value: 1, type: 'for_your_patient_download' });
      this.downloadSmartPathForYourPatientFile({ file: file, filename: filename, stat_data: stat_data, smart_path_id: this.smartPath.id })
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    smartPathSetUp() {
      this.getSmartPathForSurvey(this.$route.params.smartPathId)
        .then(() => {
          if (this.$route.params.transactionId != '') {
            this.getSmartPathTransactionById(this.$route.params.transactionId)
              .then(() => {
                this.patientFirstName = this.selectedTransaction.patient_first_name;
                this.patientLastName = this.selectedTransaction.patient_last_name;
                this.patientSex = this.selectedTransaction.patient_sex;
                this.patientDateOfBirth = this.selectedTransaction.patient_dob;
                this.patientHealthCardNumber = this.selectedTransaction.patient_health_card_number;
                this.patientHealthCardVersionNumber = this.selectedTransaction.patient_health_card_version_number;
                this.patientHealthCardProvince = this.selectedTransaction.patient_health_card_province;
                this.messageForTriage = this.selectedTransaction.message_for_triage;
                this.setAnswerStack(JSON.parse(this.selectedTransaction.smart_path_result));
                if (JSON.parse(this.selectedTransaction.smart_path_result).length > 0) {
                  this.setCurr(JSON.parse(this.selectedTransaction.smart_path_result)[JSON.parse(this.selectedTransaction.smart_path_result).length - 1]['id']);
                } else {
                  this.setCurr('0');
                }
                this.nodeStartTime = moment.now();
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
                this.showGenericErrorToast();
              });
          } else {
            this.getAllProvinces()
              .then(() => {
                this.healthCardProvinceOptions = this.allProvinces;
                this.healthCardProvinceOptions.push({ name: 'Canadian Armed Forces (CAF)' });
                this.healthCardProvinceOptions.push({ name: 'Royal Canadian Mounted Police (RCMP)' });
                this.healthCardProvinceOptions.push({ name: 'Immigration, Refugees and Citizenship Canada (IRCC)' });
                this.healthCardProvinceOptions.push({ name: 'Veterans Affairs Canada (VAC)' });
                if (this.hasProvinceOfPractice(this.actingUser, 'ON')) {
                  this.healthCardProvinceOptions.push({ name: 'University Health Insurance Health Plan' });
                }
                this.healthCardProvinceOptions.push({ name: 'Interim Federal Health Program' });
                this.healthCardProvinceOptions.push({ name: 'Other' });

                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
            this.resetCurr();
            this.resetAnswerStack();
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    ...mapActions(['getSmartPathForSurvey', 'saveSmartPathTransaction', 'downloadLabAccessForm', 'getSmartPathTransactionById', 'recordLearnMore', 'downloadSmartPathResult', 'getAllProvinces', 'linkSmartPathTransaction', 'downloadSmartPathForYourPatientFile', 'updateTransactionStatus', 'saveTransactionMessageForTriage']),
    ...mapMutations(['setSmartPath', 'setCurr', 'pushAnswerStack', 'popAllAfterIndexAnswerStack', 'setSmartPathReferralForm', 'setSmartPathSpecialty', 'setSmartPathReferralTransactionId', 'setSelectedSmartPathReferralContributor', 'resetAnswerStack', 'setAnswerStack', 'resetCurr', 'popAnswerStack', 'setSelectedTransaction', 'setSmartPathReferralSurveyFlag']),
    nextQuestion(value, next) {
      if (this.smartPath.smart_path[next]['node_type'] == 'pointer') {
        // basically threading through pointers until we hit the next actual node...
        // recursion might be overkill but we're in too deep at this point
        // this.setCurr(this.smartPath.smart_path[next]['pointer']);
        this.nextQuestion(value, this.smartPath.smart_path[next]['pointer']);
      } else {
        this.popAllAfterIndexAnswerStack(this.curr, this.smartPath.smart_path[this.curr]['question'], value);
        this.pushAnswerStack({ id: this.curr, question: this.smartPath.smart_path[this.curr]['question'], value: value });
        this.setCurr(next);

        if (this.smartPath.smart_path[this.curr]['node_type'] == 'terminal') {
          // this.pushAnswerStack({ 'id': this.curr, 'question': this.smartPath.smart_path[this.curr]['question'], 'value': value });
          this.pushAnswerStack({ id: this.curr, question: '', recommended_action: this.smartPath.smart_path[this.curr]['recommended_action'] });
        }
      }
      this.saveResult();
    },
    returnToQuestion(question_pointer) {
      this.setCurr(question_pointer);
    },
    consultWithSelectedContributor() {
      let form = {};
      this.answerStack.forEach((answer) => {
        if (this.smartPath.smart_path[answer.id]['node_type'] != 'terminal') {
          form[answer.question.replace(/(<([^>]+)>)/gi, '')] = { field_id: 'smart_path', type: 'string', is_required: true, meta_data: null, value: answer.value };
        }
      });
      this.setSmartPathReferralForm(form);
      this.setSmartPathReferralTransactionId(this.selectedTransaction.id);
      this.setSmartPathSpecialty(this.smartPath.author.specialties[0]);
      this.setSelectedSmartPathReferralContributor(this.selectedTransaction.smart_path_person);
      this.saveResult();
      this.setSmartPathReferralSurveyFlag(true);
      this.updateTransactionStatus({ user_id: this.loggedInUser.id, transaction_id: this.selectedTransaction.id, status: 'Complete (Phone Consult)' })
        .then(() => {
          this.$router.push('/makereferral');
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    makeReferralToSuggestedSpecialty(specialty) {
      this.setSmartPathReferralForm(null);
      this.setSmartPathReferralTransactionId(this.$route.params.transactionId);
      // this.setSmartPathSpecialty(this.smartPath.smart_path[this.curr]['recommended_specialty'][0]);
      this.setSmartPathSpecialty(specialty);
      this.saveResult();
      this.setSmartPathReferralSurveyFlag(true);
      this.$router.push('/makereferral');
    },
    inPersonConsult() {
      this.isLoading = true;
      this.saveResult();
      this.updateTransactionStatus({ user_id: this.loggedInUser.id, transaction_id: this.selectedTransaction.id, status: 'Complete (In Person)' })
        .then(() => {
          this.isLoading = false;
          this.displaySmartPathPilotSurvey();
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    cancelDecision() {
      this.isLoading = true;
      this.saveResult();
      this.updateTransactionStatus({ user_id: this.loggedInUser.id, transaction_id: this.selectedTransaction.id, status: 'In Progress' })
        .then(() => {
          if (this.showCancelInPersonModal) {
            this.showCancelInPersonModal = false;
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    saveResult() {
      this.errors = {};
      let saveData;
      this.isLoading = true;
      let stat_data = JSON.stringify({ node_id: this.curr, value: 1, type: 'visit_node', time_taken_on_node: moment().diff(this.nodeStartTime, 'seconds') });
      this.patientDateOfBirth = moment(this.patientDateOfBirth).format('YYYY-MM-DD');
      if (this.$route.params.transactionId != '') {
        saveData = { transaction_id: this.$route.params.transactionId, referee_id: this.actingUser.id, smart_path_id: this.smartPath.id, smart_path_person_id: this.selectedTransaction.smart_path_person.id, smart_path_result: JSON.stringify(this.answerStack), smart_path_version: this.smartPath.version, patient_first_name: this.patientFirstName, patient_last_name: this.patientLastName, patient_sex: this.patientSex, patient_dob: this.patientDateOfBirth, patient_health_card_province: this.patientHealthCardProvince, patient_health_card_version_number: this.patientHealthCardVersionNumber, patient_health_card_number: this.patientHealthCardNumber, stat_data: stat_data };
      } else {
        saveData = { referee_id: this.actingUser.id, smart_path_id: this.smartPath.id, smart_path_result: JSON.stringify(this.answerStack), smart_path_person_id: this.$route.params.contributorId, smart_path_version: this.smartPath.version, patient_first_name: this.patientFirstName, patient_last_name: this.patientLastName, patient_health_card_number: this.patientHealthCardNumber, patient_sex: this.patientSex, patient_dob: this.patientDateOfBirth, patient_health_card_province: this.patientHealthCardProvince, patient_health_card_version_number: this.patientHealthCardVersionNumber, stat_data: stat_data };
        this.validateForm();
      }
      if (this.$route.params.tranactionId != '' || this.errors.length < 0) {
        this.saveSmartPathTransaction(saveData)
          .then(() => {
            if (this.$route.params.transactionId == '') {
              // this.$router.push({ path: '/pathway/' + this.$route.params.smartPathId + '/' + this.selectedTransaction.id + '/' + this.$route.params.contributorId });
              this.$router.push({ path: '/pathway/' + this.$route.params.smartPathId + '/' + this.$route.params.contributorId + '/' + this.selectedTransaction.id });
            }
            this.nodeStartTime = moment.now();
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
    downloadLabAccess() {
      this.isLoading = true;
      let stat_data = JSON.stringify({ node_id: this.curr, value: 1, type: 'lab_req_download' });

      this.downloadLabAccessForm({ node_id: this.curr, id: this.smartPath.id, stat_data: stat_data, transactionId: this.selectedTransaction.id })
        .then(() => {
          this.$refs.additionalInfoModal.closeModal();
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    cancelPatientInfo() {
      // this.$refs.patientInfoModal.closeModal();
      if (this.$route.params.transactionId == '') {
        this.$router.push({ path: '/makereferral' });
      }
    },
    popToPreviousQuestion() {
      // pop to previous answer
      this.popAnswerStack();
      this.saveResult();
    },
    showAdditionalInfo() {
      // this function calls a smartpath metric
      this.isLoading = true;
      let stat_data = JSON.stringify({ node_id: this.curr, value: 1, type: 'learn_more' });
      this.recordLearnMore({ smart_path_id: this.smartPath.id, stat_data: stat_data })
        .then(() => {
          this.isLoading = false;
          this.$refs.additionalInfoModal.openModal();
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    offlineDownload() {
      this.isLoading = true;
      this.downloadSmartPathResult(this.selectedTransaction.id)
        .then(() => {
          this.updateTransactionStatus({ user_id: this.loggedInUser.id, transaction_id: this.selectedTransaction.id, status: 'Complete (Offline Referral)' })
            .then(() => {
              this.isLoading = false;
              this.displaySmartPathPilotSurvey();
            })
            .catch(() => {
              this.isLoading = false;
              this.showGenericErrorToast();
            });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    displaySmartPathPilotSurvey() {
      const { toggle } = createPopup('dcTNFPlH', {
        hidden: {
          smart_path_id: this.smartPath.id,
          transaction_id: this.selectedTransaction.id,
          email: this.loggedInUser.email,
          user_name: this.loggedInUser.first_name + ' ' + this.loggedInUser.last_name,
        },
      });
      toggle();
    },
    ibdClinic() {
      this.isLoading = true;
      this.saveResult();

      this.updateTransactionStatus({ user_id: this.loggedInUser.id, transaction_id: this.selectedTransaction.id, status: 'IBD Ready' })
        .then(() => {
          this.isLoading = false;
          this.displaySmartPathPilotSurvey();
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    validateForm() {
      let valid = true;
      this.errors = {};
      if (this.patientFirstName == null || this.patientFirstName == '') {
        this.errors.patient_first_name = 'Patient first name is required.';
      }
      if (this.patientLastName == null || this.patientLastName == '') {
        this.errors.patient_last_name = 'Patient last name is required.';
      }
      if (this.patientLastName == this.loggedInUser.last_name && this.patientFirstName == this.loggedInUser.first_name) {
        this.errors.patient_last_name = 'Patient Name is flagged as incorrect.';
      }
      if (!this.patientSex) {
        this.errors.patient_sex_error = 'Patient sex is required.';
      }
      if (this.patientDateOfBirth > new Date()) {
        this.errors.patient_dob = 'Patient date of birth cannot be in the future.';
      }
      if (this.patientHealthCardNumber == null || this.patientHealthCardNumber == '') {
        this.errors.health_card_number = 'Health Card/ID Number is required.';
      }
      if (!this.patientHealthCardProvince) {
        this.errors.health_card_province = 'Health Card/Policy Type is required.';
      }
      if (this.actingUser.practice_province.name === 'Ontario') {
        if (this.patientHealthCardVersionNumber == null || this.patientHealthCardVersionNumber == '') {
          this.errors.health_card_version_number = 'Version Number is required.';
        }
      }
      if (this.patientDateOfBirth == null || this.patientDateOfBirth == '' || isNaN(new Date(this.patientDateOfBirth).getTime())) {
        this.errors.patient_dob = 'Patient date of birth is required.';
      }
      if (this.patientSex !== 'Male' && this.patientSex !== 'Female') {
        this.errors.patient_sex = 'Patient sex is required.';
      }
      // Bring the input with error into view.
      let firstError = Object.keys(this.errors)[0];
      let dom = document.getElementById(`${firstError}_error`);
      if (dom) {
        dom.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return valid;
    },
    linkToSmartPath(smartPathId) {
      this.saveResult();
      this.linkSmartPathTransaction({ user_id: this.loggedInUser.id, transaction_id: this.$route.params.transactionId, link_smart_path_id: smartPathId })
        .then(() => {
          this.$router.push({ path: '/pathway/' + smartPathId + '/' + this.$route.params.contributorId + '/' + this.selectedTransaction.id }).then(() => {
            this.smartPathSetUp();
          });
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.$refs.player.play();
    },
    playAndHideOverlay() {
      this.overlayHidden = true;
      this.play();
    },
    saveMessageForTriage() {
      this.isLoading = true;
      this.saveTransactionMessageForTriage({ user_id: this.loggedInUser.id, transaction_id: this.selectedTransaction.id, message_for_triage: this.messageForTriage })
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Saved!',
            detail: 'Message for triage saved.',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
  unmounted() {
    // purge selected transaction
    this.setSelectedTransaction(null);
  },
};
</script>

<style scoped>
.specialty-image {
  background-image: url('@/assets/SmartPath-Specialty.jpg');
}

.offline-image {
  background-image: url('@/assets/SmartPath-Offline-Referral.jpg');
}

.phone-image {
  background-image: url('@/assets/SmartPath-Phone.jpg');
}

.in-person-image {
  background-image: url('@/assets/SmartPath-In-Person-Option.jpg');
}

.dark-blue {
  color: #5b76a2;
}
.subheading-blue {
  color: #859dc4;
}

.video-background {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  background-size: cover;
}

.cme-label {
  color: white;
  top: 0;
  left: 0;
  position: absolute;
  border-top-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.answer-tile {
  /* border-left: 3px solid black; */
  border: 3px solid white;
  border-left: 3px solid #5d97f7;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.answer-tile:hover {
  /* border: 3px solid black; */
  border: 3px solid #5d97f7;
}

.suggested-action {
  color: #5d97f7;
}

.survey-background {
  background-color: #ffffff !important;
  padding-left: 0;
  padding-right: 0;
}

/* .title-banner {
  padding-left: revert;
  padding-right: revert;
} */

.recommendation-tile {
  border: 1px solid rgb(209 213 219);
}

.recommendation-tile:hover {
  border: 1px solid var(--vh-blue);
  cursor: pointer;
}

.tile {
  border: 3px solid white;
}
.tile:hover {
  border: 3px solid var(--vh-dark-blue);
}
.make-referral-select-container {
  height: 100%;
}
.make-referral-select-side-panel {
  flex: 0 0 200px;
  padding: 15px 30px 25px 30px;
  text-align: center;
  justify-content: space-between;
  position: relative;
  height: 100%;
  border-right: 1px solid var(--vh-medium-gray);
}

.make-referral-select-side-panel-item:nth-last-child(1) {
  margin-top: auto;
}
.select-specialty-list {
  flex: 1;
  max-height: 90%;
  padding-right: 10px;
}
.select-specialty-list-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #e5e7ee;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  padding: 15px;
  margin-bottom: 10px;
  color: #557bb9;
  text-decoration: none;
  transition: transform 0.2s ease-out;
  font-weight: 500;
  font-size: 1rem;
  text-align: left;
}

@media (max-width: 850px) {
  .make-referral-select-side-panel {
    display: none;
  }
  .specialty-content {
    padding: 0px;
  }
  .select-specialty-list {
    flex: 1;
    padding-right: 10px;
  }
}

.seperator-black {
  margin-bottom: 25px;
  border-radius: 100px;
  width: 100px;
  margin-top: 10px;
  background-color: black;
  margin-right: auto;
  margin-left: auto;
  height: 4px;
  min-height: 4px;
}
@media (max-width: 660px) {
  .alert-text {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.slide-up-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-up-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-up-fade-enter-from,
.slide-up-fade-leave-to {
  transform: translatey(200px);
  opacity: 0;
}

.p-overlaypanel-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}
.p-overlaypanel {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  box-shadow: 0px 3px 7px 0px rgb(132 132 132 / 22%) !important;
}

.tooltip-background-smartpath {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.tooltip-smartpath {
  position: relative;
  /* border-bottom: 1px dotted black; */
  z-index: 9;
}
.tooltip-smartpath .tooltiptext-smartpath {
  width: 256px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: -5%;
  /* left: 13%; */
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.p-rating-icon {
  font-size: 1.4rem !important;
}
.video-player-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
}
.video-player-branded-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
}
.video-logo {
  max-width: 24rem;
}
.bio-tooltip .p-tooltip-text {
  width: 300px;
  background-color: rgb(227, 227, 227);
  color: black;
  font-size: 0.9rem;
}
.video-play-icon {
  font-size: 5rem;
}

@media (max-width: 500px) {
  .video-play-icon {
    font-size: 3rem;
  }
  .video-logo {
    max-width: 16rem;
  }
}
</style>
