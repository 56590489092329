import axios from 'axios';
import globals from '../../globals';
import moment from 'moment';

const state = {
  referralResponseFormAddendums: [],
};

const getters = {
  referralResponseFormAddendums: (state) => {
    return state.referralResponseFormAddendums;
  },
};

// actions
const actions = {
  getAllAddendumsOfReferralResponseForm(context, referralResponseFormId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getReferralResponseFormAddendums(referralResponseFormId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setReferralResponseFormAddendums', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addAddendumToReferralResponseForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.addAddendumToReferralResponseFormUrl(),
          {
            referral_response_form_id: data.referralResponseFormId,
            addendum_text: data.addendumText,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          if (response.status == 200) context.state.referralResponseFormAddendums.push(response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadPdfOfResponseForm(context, referralResponseFormId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.downloadPdfOfResponseFormUrl(referralResponseFormId), {
          headers: context.rootGetters.setHeaderWithAuth,
          responseType: 'blob',
        })
        .then((response) => {
          let fileName = 'Consult Report-' + referralResponseFormId + '.pdf';
          context
            .dispatch('viewDownloadedFile', { downloadedFileContent: response.data, fileName: fileName })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log('downloading file failed', error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  faxPdfOfReferralResponseForm(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          globals.APIs.faxPdfOfReferralResponseFormUrl(),
          {
            referral_response_form_id: data.referralResponseFormId,
            fax_number: data.fax_number,
            fax_document: data.fax_document,
          },
          {
            headers: context.rootGetters.setHeaderWithAuth,
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setReferralResponseFormAddendums(state, data) {
    data.forEach((addendum) => {
      addendum.created_at = moment.utc(addendum.created_at).toDate();
      addendum.updated_at = moment.utc(addendum.updated_at).toDate();
    });
    state.referralResponseFormAddendums = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
