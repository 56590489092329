<template>
  <div class="main-inner-wrap">
    <div class="h-full w-full max-w-xl pt-16">
      <div class="rounded-xl bg-white p-8 shadow">
        <h1 class="text-xl font-bold text-gray-600 mb-6">Close Account</h1>
        <div>
          <p class="rounded border-2 border-red-500 bg-red-100 p-3 text-left text-sm text-red-700 mb-4">By filling out the form below your account will be scheduled for closure. <br /><br />You will have 60 days to log in and download any records before your data is permanently deleted. If you wish to reverse this process, please contact us at support@virtualhallway.ca</p>
        </div>
        <form method="post" @submit.prevent="submit" class="py-3">
          <label class="auth-input" for="closeInput">Please type CLOSE into the field below to close your account. <span class="error">*&nbsp;</span></label>
          <InputText v-model="closeMessage" class="auth-input" required id="closeInput" maxLength="255" />
          <div v-if="error != ''" class="error">
            {{ error }}
          </div>

          <label class="auth-input mt-2" for="closeInput">Reason for account closure (Optional)</label>
          <Textarea v-model="reasonForAccountClosure" class="auth-input" :autoResize="true" rows="5" cols="30" maxLength="65535" />

          <div class="flex justify-end pt-5">
            <Button type="submit" name="submitButton"> Close Account </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Loading from 'vue-loading-overlay';

export default {
  components: {
    InputText,
    Button,
    Loading,
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  data() {
    return {
      closeMessage: '',
      reasonForAccountClosure: '',
      error: '',
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(['closeAccount']),
    submit() {
      this.error = '';
      if (this.closeMessage.toLowerCase() === 'close') {
        this.$toast.removeAllGroups();
        this.isLoading = true;
        this.closeAccount({ reason_for_account_closure: this.reasonForAccountClosure })
          .then(() => {
            this.isLoading = false;
            let closeAccountDate = moment(this.loggedInUser.account_closed_on).toDate();
            this.reasonForAccountClosure = '';

            this.$toast.add({
              severity: 'success',
              summary: 'Confirmed',
              detail: 'Your account will be closed on ' + this.formatDate(closeAccountDate),
              life: 5000,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          });
      } else {
        this.error = 'Please enter CLOSE';
      }
    },
  },
};
</script>

<style></style>
