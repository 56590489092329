<template>
  <div :class="animation" class="bg-white rounded-xl shadow mt-16 w-full max-w-lg">
    <div class="w-full">
      <div class="object-contain overflow-hidden rounded-t-xl h-52">
        <img src="@/assets/account-setup-complete.jpg" />
      </div>
    </div>
    <div class="w-full flex justify-center">
      <div class="w-20 h-20 relative z-20 rounded-full bg-primary border-4 border-solid border-white -mt-10">
        <i class="pi pi-check text-white pt-5" style="font-size: 2rem" />
      </div>
    </div>

    <div v-if="isGP(loggedInUser)" class="px-8 pb-8 pt-4">
      <h1 class="text-blue text-2xl font-bold">Account Setup Complete</h1>
      <div class="seperator"></div>
      <div class="px-4">
        <p class="text-gray-500 text-sm mb-6">You're all set! You now have full access to Virtual Hallway. Ready to book your first consult? Or just want to check out the site?</p>
      </div>
      <div class="flex flex-row justify-center items-center align-center mb-2">
        <Button @click="$router.push('/makereferral')" label="Get Started" />
      </div>
    </div>

    <div v-if="isSpecialist(loggedInUser)" class="px-8 pb-8 pt-4">
      <h1 class="text-blue text-2xl font-bold">Account Successfully Setup</h1>
      <div class="seperator"></div>
      <div class="px-4">
        <p class="text-gray-500 text-sm mb-6">Before you can start accepting consults, we require you to verify your account by uploading a copy of your photo ID <span v-if="actingUser.provider_type === 'FFS'">, and submit the required billing documentation</span>.</p>
      </div>
      <div class="flex flex-row justify-center items-center align-center mb-2">
        <Button @click="$router.push('/verification')" label="Verify Account" />
      </div>
    </div>

    <div v-if="isOrgUnitHead(loggedInUser)" class="px-8 pb-8 pt-4">
      <h1 class="text-blue text-2xl font-bold">Welcome to Virtual Hallway! 🎉</h1>
      <div class="seperator"></div>
      <div class="px-4">
        <p class="text-gray-500 text-sm mb-6">Thank you for registering. We will be in touch shortly to get your practice group set up.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from 'primevue/button';

export default {
  components: {
    Button,
  },
  props: ['loggedInUser', 'propsUser'],

  data() {
    return {
      animation: 'animate-in',
      user: this.loggedInUser,
    };
  },
  computed: {
    ...mapGetters(['actingUser', 'isGP', 'isSpecialist', 'isOrgUnitHead', 'allProvinces']),
  },
};
</script>

<style></style>
