<template>
  <Button v-if="showCancelReferralBtn" icon="pi pi-ban" :label="buttonLabel" class="p-button-secondary p-button-sm w-full" @click="showCancelReferralModal = !showCancelReferralModal" id="cancelAppointmentBtn" />
  <Dialog v-model:visible="showCancelReferralModal" header="Cancel Consult" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" style="max-width: 500px" :modal="true" :dismissableMask="true" :draggable="false">
    <template v-if="showReasonForFailedDropdown">
      <p class="pb-3 text-sm">Are you sure you want to cancel this consult? Please choose a reason for cancelling to confirm the cancellation.</p>
      <div>
        <label for="" class="text-sm">Reason for Cancelling</label>
        <Dropdown v-model="cancelValue" :options="cancelOptions" optionLabel="name" id="cancelDropdown" placeholder="Select an option" @change="handleCancelChange" class="auth-input" />
      </div>
    </template>
    <div v-else>Are you sure you want to cancel this consult?</div>
    <div class="mt-2 w-full">
      <label for="" class="text-sm"
        >Cancellation Reason Details
        <span v-if="!cancelMessageRequired">(Optional)</span>
        <span v-else class="text-primary">(Required)</span>
      </label>
      <span>
        <Textarea v-model="cancellationDetails" id="referralCancellationDetails" rows="2" class="w-full text-sm" />
        <!-- <InputText type="text" name="other" id="other" v-model="reasonForFailed" required class="auth-input" maxLength="255" placeholder="Please specify a reason" /> -->
      </span>
    </div>
    <template #footer>
      <Button label="Back" icon="" @click="showCancelReferralModal = false" class="p-button-secondary p-button-outlined" id="referralCancellationConfirmationModalBackBtn" />
      <Button label="Confirm Cancellation" id="confirmConsultCancellationBtn" class="p-button-danger w-full text-center" @click="submitCancelReferral" />
    </template>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';

export default {
  props: ['referral'],
  components: {
    Button,
    Dialog,
    Dropdown,
    InputText,
  },
  data() {
    return {
      buttonLabel: 'Cancel Consult',
      showCancelReferralBtn: false,
      showCancelReferralModal: false,
      responseForCancel: '',
      cancelOptions: [
        { name: 'Conflict in Schedule', value: 'Conflict in Schedule' },
        { name: 'No Longer Required', value: 'No Longer Required' },
        { name: 'Better Suited for Other Type of Consult', value: 'Better Suited for Other Type of Consult' },
        { name: 'Other (Specify below)', value: 'Other' },
      ],
      cancelValue: null,
      cancelMessageRequired: false,
      reasonForFailed: '',
      isLoading: false,
      cancellationDetails: null,
      showReasonForFailedDropdown: true,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser', 'canEditReferralResponseForm']),
  },
  methods: {
    ...mapActions(['cancelReferral']),
    setShowCancelReferralModal() {
      if ((this.referral.status === 'Upcoming' && this.referral.interaction_type.name === 'phone') || (this.referral.status === 'In Review' && this.referral.interaction_type.name === 'econsult')) {
        this.showCancelReferralBtn = true;
        if (this.$route.query.cancel == 'yes') {
          this.showCancelReferralModal = true;
        }
      } else if (this.canEditReferralResponseForm(this.loggedInUser, this.referral.referral_person, this.referral) && this.referral.status === 'In Review' && this.referral.interaction_type.name === 'phone') {
        this.showCancelReferralBtn = true;
        this.buttonLabel = 'Call Not Completed';
      } else if (this.referral.status === 'Pending') {
        this.showCancelReferralBtn = true;
        this.buttonLabel = 'Cancel Consult';
        this.reasonForFailed = 'Canceled on Edit Referral Form';
        this.showReasonForFailedDropdown = false;
      } else {
        this.showCancelReferralBtn = false;
        this.showCancelReferralModal = false;
      }
    },
    submitCancelReferral() {
      this.error = '';
      this.$toast.removeAllGroups();
      if (!this.reasonForFailed) {
        // this.error = 'Please select or enter a cancelation reason';
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please select a reason for cancellation.',
          life: 3000,
        });
      } else if (this.reasonForFailed == 'Other' && !this.cancellationDetails) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please provide additional information regarding the reason for cancellation.',
          life: 3000,
        });
      } else {
        let data = {};
        data.userId = this.loggedInUser.id;
        data.reason_for_failed = this.reasonForFailed;
        data.referral = this.referral;
        data.cancellation_details = this.cancellationDetails;

        this.isLoading = true;
        this.cancelReferral(data)
          .then(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Consult successfully cancelled',
              life: 5000,
            });
            if (this.reasonForFailed == 'Canceled on Edit Referral Form') {
              this.$router.push({ path: `/makereferral` });
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          });
      }
    },
    handleCancelChange() {
      this.reasonForFailed = this.cancelValue.value;
      if (this.cancelValue.value === 'Other' || this.cancelValue.value == 'Better Suited for Other Type of Consult') {
        this.cancelMessageRequired = true;
      } else {
        this.cancelMessageRequired = false;
      }
    },
  },
  created() {
    this.setShowCancelReferralModal();
    if (this.referral.interaction_type.name === 'econsult' && this.canEditReferralResponseForm(this.loggedInUser, this.referral.referral_person, this.referral)) {
      this.cancelOptions[0] = { name: 'This consult is more suited to a Phone consult', value: 'This consult is more suited to a Phone consult' };
    }
  },
  watch: {
    referral: function () {
      this.setShowCancelReferralModal();
    },
  },
};
</script>

<style></style>
