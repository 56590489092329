<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-row">
      <h1 class="text-blue mt-1 text-xl font-bold">Need us to call ASAP?</h1>
    </div>
    <div class="seperator"></div>
    <p class="tip-title text-sm">
      By clicking <strong>Call Me <i class="fa fa-bolt" /></strong>, our support staff will give you a call as soon as we can. <br />
      Support staff will never ask for information related to account passwords or patient data.
    </p>
  </div>
  <div class="flex justify-end pt-5">
    <Button id="callMeButton" @click="callMe">Call Me <i class="ml-2 text-white fa fa-bolt" /></Button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';

export default {
  emits: ['toggleHelp'],
  components: {
    Button,
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(['supportCallMe']),
    callMe() {
      // grab all the names of the users org units and throw them into an array, then make that a string
      let orgUnits = this.loggedInUser.org_units.map(({ name }) => name).toString();

      this.isLoading = true;
      let data = {
        userId: this.loggedInUser.id,
        currentRoute: window.location.href,
        orgUnits: orgUnits,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        userAgent: navigator.userAgent,
      };
      this.supportCallMe(data)
        .then(() => {
          this.isLoading = false;
          this.$emit('toggleHelp');
          this.$toast.add({
            severity: 'success',
            summary: 'Thanks for reaching out',
            detail: 'Support staff will contact you ASAP!',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
};
</script>

<style></style>
