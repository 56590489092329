<template>
  <div class="pt-mobile-inset animated backdrop-blur-xl bg-white/75 text-center text-lg font-semibold fixed top-0 z-10 border-b w-full" :class="[{ hidden: hasScrolled === null }, { 'fade-in-down': hasScrolled === true }, { 'fade-out-up': hasScrolled === false }]" v-if="isNativePlatform()">
    <div class="h-10">Clinical Notes</div>
  </div>

  <div class="main-inner-wrap vld-parent" v-if="isLoading">
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
  </div>
  <div class="main-inner-wrap" v-else>
    <div class="align-start relative mt-4 mb-1 w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow flex" v-if="!isNativePlatform()">
      <div class="hidden lg:block"><i class="pi pi-list mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">My Notes</h1>
        <h1 class="text-sm text-gray-200">Search the table below for the note you're looking for, then click the <i class="pi pi-eye mx-1 mb-2 text-white" style="font-size: 0.8rem" /> icons to view the note, or the <i class="pi pi-ellipsis-v mx-1 mb-2 text-white" style="font-size: 0.8rem" /> icons to download a PDF of the note.</h1>
      </div>
    </div>
    <div class="w-full flex flex-col justify-start text-left mt-0" v-else>
      <div class="flex flex-row mb-2">
        <h1 class="font-bold text-gray-700 text-2xl pl-0.5">Notes</h1>
        <Button label="Add Note" class="p-button-sm p-button-secondary w-28 ml-auto" name="addNewNoteButton" @click="$router.push('/add-note')" />
        <span class="w-10 h-10 flex items-center pl-2" @click="toggleMenu">
          <i class="pi pi-ellipsis-h p-1 border-2 rounded-full border-gray-800 text-lg cursor-pointer"></i>
        </span>
        <Menu ref="menu" id="overlay_menu" :model="menuItems" popup>
          <template #submenuheader="{ item }">
            <div class="flex flex-row">
              <span class="text-sm font-bold">{{ item.label }}</span>
              <span class="ml-auto"><i :class="item.icon"></i></span>
            </div>
          </template>
          <template #item="{ item }">
            <div class="flex align-items-center w-full px-3 py-2 cursor-pointer" @click="item.action(item.label)">
              <template v-if="['Draft', 'Signed Off'].includes(item.label)">
                <div class="rounded px-2 py-1 text-xs font-bold uppercase text-white w-full text-center relative" :class="item.label.replace(/\s/g, '')">
                  <span class="">
                    {{ item.label }}
                  </span>
                  <i class="pi pi-filter ml-auto mt-0.5 right-2 absolute" style="font-size: 0.75rem; font-weight: 1000" v-if="selectedFilterStatus === item.label"></i>
                </div>
              </template>
              <template v-else>
                <div class="flex flex-row w-full">
                  <span class="pl-1 text-xs font-bold">{{ item.label }}</span>
                  <i class="pi ml-auto mt-0.5" style="font-size: 0.75rem; font-weight: 1000" :class="selectedSort.desc ? 'pi-arrow-down' : 'pi-arrow-up'" v-if="selectedSort.type === item.label"></i>
                </div>
              </template>
            </div>
          </template>
        </Menu>
      </div>
      <InputText type="text" name="notesSearch" id="notesSearch" v-model="notesSearch" required class="w-full !border-0 !rounded-lg shadow" placeholder="Search" />
    </div>

    <div class="w-full pt-4" v-if="!isNativePlatform()">
      <NoteList :referrals="encounterNotes" class="" />
    </div>
    <div class="w-full pt-4 block" v-else>
      <div class="bg-white w-full rounded-lg text-black px-2 shadow">
        <div class="w-full flex flex-row py-3 px-2 justify-center min-h-[50px] cursor-pointer" :class="idx !== encounterNotes.length - 1 ? 'border-b' : 'border-b-0'" v-for="(encounterNote, idx) in filteredEncounterNotes" :key="idx" @click="this.$router.push(`/note/${encounterNote.id}`)">
          <div class="flex flex-col text-left text-gray-800">
            <span v-if="encounterNote.referral_form.patient_first_name || encounterNote.referral_form.patient_last_name"> {{ encounterNote.referral_form.patient_first_name }} {{ encounterNote.referral_form.patient_last_name }} </span>
            <span v-else>Note</span>
            <span class="text-gray-500 text-xs"> {{ formatDateYYYYMMDD(encounterNote.updated_at) }} </span>
          </div>
          <span class="ml-auto my-auto rounded px-2 py-1 text-xs font-bold uppercase text-white w-24" :class="encounterNote.status.replace(/\s/g, '')">
            {{ encounterNote.status }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <Modal ref="welcomeEncounterNoteModal" class="dark-mint-header">
    <template v-slot:header></template>
    <template v-slot:body>
      <div class="flex w-full max-w-lg justify-center rounded-b-xl p-8 sm:p-12">
        <div class="flex w-full max-w-lg flex-col">
          <div class="w-full">
            <h1 class="text-xl font-bold pb-3">Encounter Notes! ✍️</h1>
          </div>
          <div class="text-center pb-3 text-sm">Capture Clinical Documentation On The Go</div>

          <div class="text-sm w-full max-w-lg px-4 pt-2 text-gray-600 text-left">
            <ul>
              <li class="pt-1"><span class="font-semibold">No Need for Sticky Notes: </span> Capture details of clinical encounters when your on the go or away from your desk in a compliant manner.</li>
              <li class="pt-1"><span class="font-semibold">Save & Edit:</span> Save your progress at any point and return to edit your notes later.</li>
              <li class="pt-1"><span class="font-semibold">Sign Off:</span> Once your notes are ready, sign off on your note. You can always update the patient details after.</li>
              <!-- <li class="pt-1"><span class="font-semibold">Submit For Billing:</span> When signed off, submit the note for billing and we'll submit it for you.</li> -->
            </ul>
          </div>
          <div class="flex w-full flex-row items-center justify-between mt-4">
            <span id="skipTutorialBtn" class="text-blue text-sm only-child-m-auto cursor-pointer font-semibold hover:text-red-300" @click="$refs.welcomeEncounterNoteModal.closeModal()">Close</span>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import NoteList from '@/components/encounter_note/NoteList.vue';
import Loading from 'vue-loading-overlay';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Modal from '@/components/misc/Modal.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  components: { NoteList, Loading, InputText, Button, Menu, Modal },
  data() {
    return {
      isLoading: false,
      displayNotes: true,
      hasScrolled: null,
      filteredEncounterNotes: [],
      notesSearch: '',
      menuItems: [
        {
          label: 'Sort By',
          icon: 'pi pi-sort-alt',
          items: [
            { label: 'Date Edited', icon: 'pi pi-plus', action: this.sortOnClick },
            { label: 'Date Created', icon: 'pi pi-download', action: this.sortOnClick },
          ],
        },
        {
          label: 'Filter By',
          icon: 'pi pi-filter',
          items: [
            { label: 'Draft', action: this.filterStatusOnClick },
            { label: 'Signed Off', action: this.filterStatusOnClick },
          ],
        },
      ],
      selectedFilterStatus: null,
      selectedSort: {
        type: 'Date Edited',
        desc: true,
      },
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'allReferrals', 'getUserActionByName']),
    encounterNotes() {
      return this.allReferrals.filter((referral) => referral.interaction_type.name === 'encounter_note');
    },
  },
  methods: {
    ...mapActions(['getAllReferralsOfUser']),
    handleScroll() {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      const heightToChange = 45;
      if (this.hasScrolled === null && scrollPosition > heightToChange) {
        this.hasScrolled = scrollPosition > heightToChange;
      } else if (this.hasScrolled !== null) {
        this.hasScrolled = scrollPosition > heightToChange;
      }
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    sortOnClick(sortType) {
      if (sortType === this.selectedSort.type) {
        this.selectedSort.desc = !this.selectedSort.desc;
      } else {
        this.selectedSort.type = sortType;
        this.selectedSort.desc = true;
      }
      this.filteredEncounterNotes.sort((a, b) => {
        if (this.selectedSort.type === 'Date Edited') {
          if (a.updated_at > b.updated_at) {
            return this.selectedSort.desc ? -1 : 1;
          } else if (a.updated_at < b.updated_at) {
            return this.selectedSort.desc ? 1 : -1;
          }
        } else {
          if (a.created_at > b.created_at) {
            return this.selectedSort.desc ? 1 : -1;
          } else if (a.created_at < b.created_at) {
            return this.selectedSort.desc ? 1 : -1;
          }
        }
        return 0;
      });
    },
    filterStatusOnClick(status) {
      if (this.selectedFilterStatus !== status) {
        this.selectedFilterStatus = status;
      } else {
        this.selectedFilterStatus = null;
      }

      this.filteredEncounterNotes = this.searchNote().filter((note) => this.selectedFilterStatus === null || note.status === this.selectedFilterStatus);
    },
    searchNote() {
      if (this.notesSearch === '') {
        return this.encounterNotes;
      }
      const search = this.notesSearch.toLowerCase();
      return this.encounterNotes.filter((note) => note.referral_form.patient_first_name?.toLowerCase().startsWith(search) || note.referral_form.patient_last_name?.toLowerCase().startsWith(search) || note.referral_person_first_name?.toLowerCase().startsWith(search) || note.referral_person_last_name?.toLowerCase().startsWith(search) || note.referee_last_name?.toLowerCase().startsWith(search) || note.referee_first_name?.toLowerCase().startsWith(search));
    },
  },
  created() {
    this.isLoading = true;
    this.getAllReferralsOfUser({
      user_id: this.loggedInUser.id,
      required_relations: ['referral_form:id,referral_id,patient_first_name,patient_last_name,is_signed_off', 'interaction_type', 'specialty', 'org_units'],
    })
      .then(() => {
        this.isLoading = false;
        this.filteredEncounterNotes = this.encounterNotes.sort((a, b) => {
          if (a.updated_at > b.updated_at) {
            return this.selectedSort.desc ? -1 : 1;
          } else if (a.updated_at < b.updated_at) {
            return this.selectedSort.desc ? 1 : -1;
          }
          return 0;
        });
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });
  },
  watch: {
    notesSearch() {
      this.filteredEncounterNotes = this.searchNote();
    },
  },
  mounted() {
    if (this.isNativePlatform()) {
      window.addEventListener('scroll', this.handleScroll);
    }
    if (this.getUserActionByName(this.loggedInUser, 'create_encounter_note')?.status === 'Active') {
      this.$refs.welcomeEncounterNoteModal.openModal();
      this.updateUserTutorialByName(this.loggedInUser, 'create_encounter_note', false, 'Completed');
    }
  },
  unmounted() {
    if (this.isNativePlatform()) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
};
</script>

<style scoped>
.p-disabled,
.p-component:disabled {
  opacity: 0.8 !important;
}
</style>
