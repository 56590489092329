<template>
  <span @click="openOnDemandDialog" class="flex cursor-pointer items-center hover:text-red-500">Learn More <i class="pi pi-question-circle ml-1" style="font-size: 0.8rem" /></span>
  <Dialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" v-model:visible="show" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" class="w-3/4 p-0" id="formTips">
    <template #header>
      <div class="flex flex-row items-center">
        <i class="fas fa-bolt mr-2" style="font-size: 1rem"></i>
        <h1>OnDemand Consults</h1>
      </div>
    </template>

    <div class="p-6 sm:flex sm:flex-col">
      <div class="mb-1 flex flex-row items-center">
        <i class="pi pi-star-fill text-blue pr-2 text-left text-sm"></i>
        <h1 class="text-blue font-bold">What are OnDemand Consults?</h1>
      </div>
      <p class="mb-6 text-sm text-gray-700">Some {{ getReferralPersonTerm(actingUser) }}s offer the ability to be booked "OnDemand", which if selected will let them know that they can call you before the specified date and time of the consult booking.</p>
      <div class="mb-1 flex flex-row items-center">
        <i class="pi pi-star-fill text-blue pr-2 text-left text-sm"></i>
        <h1 class="text-blue font-bold">Are OnDemand Consults Faster?</h1>
      </div>
      <p class="mb-6 text-sm text-gray-700">OnDemand Consults don't guarantee that your consult request will be responded to early, however if the {{ getReferralPersonTerm(actingUser) }} finds an opportunity to reach out before the consult date, they will do so.</p>
      <div class="mb-1 flex flex-row items-center">
        <i class="pi pi-star-fill text-blue pr-2 text-left text-sm"></i>
        <h1 class="text-blue font-bold">How Do I Book an OnDemand Consult?</h1>
      </div>
      <p class="text-sm text-gray-700">If the {{ getReferralPersonTerm(actingUser) }} provides the ability to be booked OnDemand, you can simply tick the checkbox at the bottom of the consult form, notifying the {{ getReferralPersonTerm(actingUser) }} that you are open to having the consult call ASAP.</p>
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" class="p-button-outlined p-button-secondary" @click="closeOnDemandDialog()" />
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
export default {
  // props: ['user', 'selectedSpecialty'],
  data() {
    return {
      // selectedSpecialist: [],
      isLoading: false,
      show: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser']),
  },
  components: {
    Button,
    Dialog,
  },
  methods: {
    openOnDemandDialog() {
      this.show = true;
    },
    closeOnDemandDialog() {
      this.show = false;
    },
  },
};
</script>
<style>
#formTips .p-dialog-content {
  padding: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-dialog {
  border: none !important;
}

#tips-button {
  border: 1px solid white;
  color: white;
  position: absolute;
  right: 16px;
  top: 16px;
}
</style>
