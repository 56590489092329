<template>
  <div class="col-span-2 mt-8 mb-4 flex flex-row items-center justify-center">
    <h1 class="mr-2 text-lg font-bold text-gray-500">Practice Groups for {{ smartPath.name }}</h1>
    <div class="h-1 flex-grow bg-gray-500"></div>
  </div>
  <Message severity="info" :closable="false" class="text-left">Select your Practice Group, then click Add Practice Group. Adding a Practice Group will allow your admin to view your SmartPath's inbox.</Message>

  <div v-if="this.smartPath.org_units.length > 0" class="w-full">
    <div class="text-xs uppercase font-display font-semibold text-gray-400 text-left border-b border-gray-400 p-1">Practice Groups</div>
    <div v-for="org_unit in smartPath.org_units" :key="org_unit.id" class="flex flex-row justify-between items-center font-display border-b border-gray-400 p-1">
      {{ org_unit.name }}
      <Button label="Remove" class="p-button-icon-only p-button-text" @click="remove(org_unit)" v-tooltip.left="'Remove Practice Group from this SmartPath'"> <i class="pi pi-times navbar-icon text-gray-400" style="font-size: 0.8rem !important"></i> </Button>
    </div>
  </div>
  <div class="mt-8">
    <form method="post" @submit.prevent="submit">
      <div class="px-0 text-left mb-4">
        <label for="contributorsMultiselect" class="auth-input">Select contributors</label>
        <MultiSelect :class="['w-full']" v-model="selectedOrgUnits" :options="availableOrgUnits" name="orgUnitsMultiselect" required optionLabel="name" optionValue="id" :showToggleAll="false" :filter="true" display="chip" emptyMessage="Select SmartPath Contributors" />
      </div>
      <div class="flex flex-col md:flex-row justify-end items-end mb-3">
        <div><Button label="Add Practice Group" @click="submit()"></Button></div>
      </div>
    </form>
  </div>

  <Loading z-index="99" v-model:active="isLoading" loader="spinner" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from 'primevue/button';
import MultiSelect from 'primevue/multiselect';
import Message from 'primevue/message';
import Loading from 'vue-loading-overlay';

export default {
  props: ['loggedInUser', 'smartPath'],
  components: {
    Button,
    MultiSelect,
    Message,
    Loading,
  },
  data() {
    return {
      user: this.loggedInUser,
      selectedOrgUnits: [],
      availableOrgUnits: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(['attachSmartPathOrgUnits', 'detachSmartPathOrgUnit']),
    remove(org_unit) {
      this.isLoading = true;
      this.detachSmartPathOrgUnit({
        smart_path_id: this.smartPath.id,
        org_unit_ids: [org_unit.id],
      })
        .then(() => {
          this.isLoading = false;
          this.availableOrgUnits = this.loggedInUser.org_units.filter((org) => !this.smartPath.org_units.find((smartPathOrg) => smartPathOrg.id == org.id));
          this.$toast.add({
            severity: 'success',
            summary: 'Practice Group removed!',
            detail: 'SmartPath has been successfully updated.',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    submit() {
      this.isLoading = true;
      this.attachSmartPathOrgUnits({
        smart_path_id: this.smartPath.id,
        org_unit_ids: this.selectedOrgUnits,
      })
        .then(() => {
          this.selectedOrgUnits = [];
          this.isLoading = false;
          this.availableOrgUnits = this.loggedInUser.org_units.filter((org) => !this.smartPath.org_units.find((smartPathOrg) => smartPathOrg.id == org.id));
          this.$toast.add({
            severity: 'success',
            summary: 'Practice Group added!',
            detail: 'SmartPath has been successfully updated.',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
  created() {
    this.availableOrgUnits = this.loggedInUser.org_units.filter((org) => !this.smartPath.org_units.find((smartPathOrg) => smartPathOrg.id == org.id));
  },
};
</script>

<style></style>
