<template>
  <div :class="cardClasses" @click="openSpecialistModal" :aria-label="'specialist_card_' + user?.first_name">
    <div v-if="user" class="flex bg-white h-full flex-grow flex-col overflow-hidden rounded-2xl p-4 shadow" :class="tutorialStatus != 'try-smartpath' || (tutorialStatus == 'try-smartpath' && specialistSmartPaths.length == 0) ? 'hover-border' : 'try-smartpath-tile'" style="--index: 0">
      <div v-if="tutorialStatus == 'try-smartpath' && specialistSmartPaths.length > 0" style="bottom: 6.5rem" class="flex w-full justify-start absolute z-50 mb-6 pl-2 pr-3">
        <div class="flex flex-col rounded-md text-white font-bold new-feature-background p-1.5">
          <div class="font-bold" style="font-size: 1rem"><i class="pi pi-sitemap" /> SmartPath</div>
        </div>
      </div>

      <div class="flex flex-col items-start justify-start w-full">
        <!-- Services -->
        <div class="absolute top-4 right-4">
          <div :class="[available ? '' : 'opacity-50', 'flex', 'flex-col']">
            <div class="flex flex-row items-center justify-center w-full gap-2">
              <i v-tooltip.bottom="'Phone Consult'" v-if="availableServices.includes('phoneConsult')" class="pi pi-phone font-body mb-2 text-xs text-gray-400" />
              <i v-tooltip.bottom="'eConsult+'" v-if="availableServices.includes('eConsult')" class="pi pi-file-o font-body mb-2 text-xs text-gray-400" />
              <i v-tooltip.bottom="'OnDemand'" v-if="availableServices.includes('onDemandConsult')" class="pi pi-bolt font-body mb-2 text-xs text-gray-400" />
              <i v-tooltip.bottom="'SmartPath'" v-if="specialistSmartPaths.length > 0" class="pi pi-sitemap font-body mb-2 text-xs text-gray-400" />
            </div>
          </div>
        </div>

        <!-- Flags of Province of Practice -->
        <div v-if="hasProvinceOfPractice(loggedInUser, ['PE'])" class="absolute top-4 right-4">
          <div v-if="hasProvinceOfPractice(user, ['PE'])">
            <img src="@/assets/Flag_of_Prince_Edward_Island.png" class="w-10 h-6 shadow rounded-sm border border-black" alt="PEI Flag" />
          </div>
          <div v-else>
            <img src="@/assets/Flag_of_Nova_Scotia.png" alt="NS Flag" class="w-10 h-6 shadow rounded-sm border border-black" />
          </div>
        </div>

        <!-- Profile Picture -->
        <div class="relative h-16 w-16">
          <img v-bind:src="user.profile.profile_picture_location" class="relative z-20 h-16 w-16 rounded-full bg-white object-cover" :class="available ? '' : 'opacity-50'" v-if="user.profile.profile_picture_location" />
        </div>

        <!-- Name  -->
        <div class="mt-3 flex items-center" :class="available ? '' : 'opacity-50'">
          <h3 class="font-body text-md font-semibold text-start" v-if="user.cpsns == 'demo'">{{ user.first_name }} {{ user.last_name }} (Demo)</h3>
          <h3 class="font-body text-md font-semibold capitalize" v-else>{{ displayNameByRole(user) }}</h3>
          <span v-if="user.top_consulter" v-tooltip.right="'Top Consultant'">
            <i class="fa-solid fa-medal ml-1.5 text-primary"></i>
          </span>
        </div>

        <div v-if="!available" class="flex flex-col items-center">
          <div class="my-2 flex flex-row text-xs font-bold text-gray-600 h-4 underline hover:cursor-pointer">Fully Booked - Get Notified when Available</div>
        </div>
      </div>

      <!-- Expertise Areas -->
      <!-- TODO: Don't show expertise area if it's the same as the user's specialty -->
      <div v-if="user.expertise_areas.length > 0" class="w-full flex flex-wrap text-xs pt-2" @mouseenter="showExpertiseAreaOverlay" @mouseleave="hideExpertiseAreaOverlay">
        <span class="mr-1 text-gray-500">Focus: </span>
        <!-- TODO: Fix visibleAreasOfExpertise -->
        <div v-for="(expertise, index) in visibleAreasOfExpertise" :key="index" class="text-gray-500">
          <span v-if="index !== 0">, </span>
          <span>{{ expertise.name }}</span>
        </div>
        <div v-if="user.expertise_areas.length > visibleAreasOfExpertise.length" class="text-gray-500">&nbsp;and {{ user.expertise_areas.length - visibleAreasOfExpertise.length }} more.</div>
        <OverlayPanel :ref="'expertiseAreaOverlay' + user.id">
          <div class="flex flex-col px-5 pt-4">
            <h1 class="text-gray-500 mb-2">Areas of Expertise</h1>
            <div class="flex flex-col items-start justify-start rounded-xl text-xs font-normal gap-y-2">
              <div v-for="area in user.expertise_areas" class="flex flex-row items-center text-sm" :key="area.id">
                <i class="pi pi-star text-xs text-left text-blue pr-2"></i>
                <p>{{ area.name }}</p>
              </div>
            </div>
          </div>
        </OverlayPanel>
      </div>
    </div>

    <!-- Any Specialist Card-->
    <div v-else class="tile animated bg-white hover-border fade-in-up flex h-full flex-grow flex-col overflow-hidden rounded-2xl p-4 shadow" style="--index: 0">
      <div class="flex flex-col items-start justify-start">
        <div class="relative z-20 h-16 w-16 rounded-full bg-secondary">
          <i class="pi fas fa-user-doctor specialist-card-item-img text-white" style="font-size: 1rem" />
        </div>
        <div class="mt-3 flex">
          <h3 class="font-body text-md font-semibold">Any {{ getReferralPersonTerm(actingUser, true) }}<br /></h3>
        </div>
        <div class="font-body mt-2 text-xs text-gray-500">View all available appointments.</div>
      </div>
    </div>
  </div>
  <SpecialistModal v-if="showSpecialistModal" :availableServices="availableServices" :selectedSpecialist="user" :selectedSpecialty="selectedSpecialty" @closeSpecialistModal="closeSpecialistModal" @specialistCardOnClick="specialistCardOnClick" @econsultOnClick="econsultOnClick" @bookOnDemand="bookOnDemand" />

  <RequestServiceModal v-if="showRequestServiceModal" :selectedSpecialist="user" :availableServices="availableServices" @closeRequestServiceModal="closeRequestServiceModal" />
</template>

<script>
import ViewBio from '@/components/misc/ViewBio.vue';
import ViewSmartPaths from '../../components/gp/smartpath/ViewSmartPaths.vue';
import SpecialistModal from '../specialist/SpecialistModal.vue';
import RequestServiceModal from '../specialist/RequestServiceModal.vue';
import Button from 'primevue/button';
import Chip from 'primevue/chip';
import { mapActions, mapGetters } from 'vuex';
import OverlayPanel from 'primevue/overlaypanel';

export default {
  inject: ['mixpanel'],
  props: ['user', 'schedulesOfSpecialists', 'selectedSpecialty', 'specialists', 'numberOfAvailableSpecialists'],
  components: {
    ViewBio,
    ViewSmartPaths,
    SpecialistModal,
    RequestServiceModal,
    Button,
    Chip,
    OverlayPanel,
  },
  data() {
    return {
      specialistSmartPaths: [],
      showSpecialistModal: false,
      availableServices: [],
      showRequestServiceModal: false,
      visibleAreasOfExpertise: [],
    };
  },
  emits: ['goToSelectDate', 'econsultOnClick', 'popUpConfirmReferral', 'bookOnDemand'],
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser', 'smartPaths', 'selectedInteractionType', 'offeredServicesOfUsers', 'hasProvinceOfPractice', 'tutorialStatus']),
    cardClasses() {
      if (this.availableServices.length > 0) {
        return ['specialist-card', 'border-hover--blue', 'cursor-pointer'];
      } else {
        return ['specialist-card'];
      }
    },
    available() {
      if (this.availableServices.length > 0 || this.specialistSmartPaths.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions([]),
    openSpecialistModal() {
      if (this.user == null) {
        this.specialistCardOnClick();
      } else if (this.availableServices.length > 0 || this.specialistSmartPaths.length > 0) {
        this.showSpecialistModal = true;
        this.mixpanel.track('Make Referral - Opened Specialist Modal', { specialty: this.selectedSpecialty.name, numberOfAvailableSpecialist: this.numberOfAvailableSpecialists });
      } else {
        this.showRequestServiceModal = true;
      }
    },
    closeSpecialistModal() {
      this.showSpecialistModal = false;
    },
    closeRequestServiceModal() {
      this.showRequestServiceModal = false;
    },
    specialistCardOnClick() {
      if (this.specialists) {
        this.$emit('goToSelectDate', { specialists: this.specialists });
        this.mixpanel.track('Make Referral - Clicked Any Specialist', { specialty: this.selectedSpecialty.name, numberOfAvailableSpecialist: this.numberOfAvailableSpecialists });
      } else {
        this.$emit('goToSelectDate', { specialists: [this.user] });
        this.mixpanel.track('Specialist Modal - Clicked Phone Consult', { specialty: this.selectedSpecialty.name, specialistId: this.user.id });
      }
    },
    econsultOnClick() {
      if (this.specialists) {
        this.$emit('econsultOnClick', { specialists: this.specialists, numberOfAvailableSpecialist: this.numberOfAvailableSpecialists });
      } else {
        this.$emit('econsultOnClick', { specialists: [this.user], numberOfAvailableSpecialist: this.numberOfAvailableSpecialists });
      }
    },
    bookOnDemand(event) {
      this.mixpanel.track('Specialist Modal - Clicked OnDemand Consult', { specialty: this.selectedSpecialty.name, specialistId: this.user.id });
      this.$emit('bookOnDemand', event);
    },
    showExpertiseAreaOverlay(event) {
      this.$refs['expertiseAreaOverlay' + this.user.id].toggle(event);
    },
    hideExpertiseAreaOverlay(event) {
      this.$refs['expertiseAreaOverlay' + this.user.id].toggle(event);
    },
  },
  created() {
    window.scrollTo(0, 0);
    if (this.user) {
      this.specialistSmartPaths = this.smartPaths.filter((smartPath) => (smartPath.author.id == this.user.id || smartPath.contributors.find((contributor) => contributor.id == this.user.id)) && smartPath.status == 'Public');
      if (this.user.phoneConsult) {
        this.availableServices.push('phoneConsult');
      }
      if (this.user.eConsult) {
        this.availableServices.push('eConsult');
      }
      if (this.user.onDemandConsult) {
        this.availableServices.push('onDemandConsult');
      }
      let nextAvailability = this.user.nextAvailabilityTimes;
      if (nextAvailability) {
        this.availableServices.push({ nextPhoneAvailability: nextAvailability });
      }
      let totalCharCount = 0;
      for (let i = 0; i < this.user.expertise_areas.length; i++) {
        if (totalCharCount + this.user.expertise_areas[i].name.length < 30) {
          this.visibleAreasOfExpertise.push(this.user.expertise_areas[i]);
          totalCharCount += this.user.expertise_areas[i].name.length;
        }
        if (totalCharCount >= 30) {
          break;
        } else if (this.visibleAreasOfExpertise.length > 1) {
          break;
        }
      }
      // Possible that all the areas of expertise are greater than 30 characters long, in that case we need to manually add them with ellipses to prevent overflow
      if (this.user.expertise_areas.length > 0 && this.visibleAreasOfExpertise.length == 0) {
        this.visibleAreasOfExpertise.push({ id: this.user.expertise_areas[0].id, name: this.user.expertise_areas[0].name.substr(0, 20) + '...' });
      }
    } else {
      this.availableServices.push('phoneConsult');
    }
  },
};
</script>

<style>
.hover-border {
  margin: 2px;
  border: 1px solid #c9c9c9;
}

.hover-border:hover {
  margin: 2px;
  border: 1px solid var(--vh-dark-blue);
}

.specialist-card-item {
  padding: 2px 15px;
}

.specialist-card-item-img {
  border-radius: 50%;
  /* background-size: cover;
  background-position: center center; */
  height: 80%;
  overflow: hidden;
  padding-top: 1rem;
}

.rounded-icon-inside {
  border-radius: 50%;
  height: 80%;
  overflow: hidden;
  padding-top: 1.25rem;
}

.specialist-card-item-text {
  margin-top: auto;
  margin-bottom: auto;
  text-overflow: ellipsis;
  overflow: hidden;
}

.border-hover--blue:hover {
  border-color: var(--vh-blue);
}

.econsult-btn-color {
  background-color: #c7e4e2;
  color: #21938a;
}

.phone-btn-color {
  background-color: #21938a;
  color: #e6f2f1;
}

.specialist-card {
  height: 100%;
  flex-grow: 1;
}

.specialist-card .p-button {
  font-size: 13px !important;
}

.custom-chip {
  background: rgb(219, 219, 219);
  color: black;
  font-size: 0.7rem;
  font-weight: 600;
}
</style>
