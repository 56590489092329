import axios from 'axios';
import globals from '../../globals';

const state = {
  allSpecialties: [],
};

const getters = {
  allSpecialties: (state) => {
    return state.allSpecialties;
  },
};

// actions
const actions = {
  getSpecialtyById(context, specialtyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSpecialtyByIdUrl(specialtyId), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllSpecialties(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAllSpecialtiesUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setAllSpecialties', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSpecialties(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getSpecialtiesUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
          params: params,
        })
        .then((response) => {
          context.commit('setAllSpecialties', response.data.data);
          resolve('success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setAllSpecialties(state, data) {
    state.allSpecialties = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
