<template>
  <div v-if="referrals.length < 1" class="no-consult-card border-1 mb-4 flex w-full flex-col items-center justify-between rounded-lg bg-white p-6 shadow">
    <div class="spacing"></div>
    <div>
      <i class="pi pi-check-circle text-gray-600 mb-3" style="font-size: 2rem"></i>
      <h3 class="text-gray-600 font-semibold">No Consults</h3>
    </div>
    <Button class="p-button-secondary p-button-sm w-full justify-center" @click="$router.push('/makereferral')"> Book a Consult </Button>
  </div>
  <div v-else v-for="(referral, index) in referrals.slice(0, 5)" :key="referral.id" class="tile animated fade-in-up relative mb-6 w-full rounded-lg bg-white p-4 text-left shadow border-1" :style="'--index: ' + index">
    <div class="mb-4 flex flex-row justify-between">
      <template v-if="loggedInUser.id === referral.referee_id || actingUser?.id === referral.referee_id">
        <div class="text-left">
          <h1 class="text-gray-600 font-bold sm:text-sm lg:text-xl">{{ referral.referral_person_full_name }}</h1>
          <span class="text-gray-400 sm:text-sm lg:text-lg">Specialty: {{ referral.specialty?.name }}</span>
        </div>
      </template>
      <template v-else>
        <!-- <img v-bind:src="referral.referee_avatar" class="rounded-full h-16 w-16 object-cover bg-white mr-3"/> -->
        <div class="text-left">
          <h1 class="text-gray-600 font-bold sm:text-sm lg:text-xl">{{ referral.referee_full_name }}</h1>
          <span class="text-gray-400 sm:text-sm lg:text-lg">Specialty: {{ referral.specialty?.name }}</span>
        </div>
      </template>
      <div class="absolute top-2 right-2 flex md:top-6 md:right-6">
        <div v-if="isSpecialist(loggedInUser) && loggedInUser.id === referral.referee_id" class="flex flex-row pr-1" v-tooltip.bottom="{ value: 'The consultant will give you a call on the consult date. ' }">
          <i class="pi pi-phone text-gray-400" style="font-size: 1.3rem; padding-top: 4px; padding-right: 3px"></i>
          <i class="pi pi-arrow-down -ml-3 mb-3 rotate-45 text-gray-400" style="font-size: 0.85rem; font-weight: 900"></i>
        </div>
        <div :class="'h-6 rounded px-2 py-1 text-xs font-bold  text-white ' + referral.className">
          {{ referral.status }}
        </div>
      </div>
    </div>
    <div class="mb-3 flex w-full flex-col justify-between 2xl:flex-row">
      <div class="my-1 flex flex-row" v-if="referral.referral_form.patient_first_name">
        <div class="align-center mr-2 flex items-center justify-center rounded border-1" style="width: 2.5rem; height: 2.5rem">
          <i class="pi pi-user text-left text-xl text-gray-400"></i>
        </div>
        <div class="mb-2 flex flex-col items-start py-1 md:border-none md:py-0">
          <div class="text-xs font-normal text-gray-400">Patient</div>
          <div class="text-sm font-semibold text-gray-600">{{ referral.referral_form.patient_first_name }} {{ referral.referral_form.patient_last_name }}</div>
        </div>
      </div>
      <template v-if="referral?.interaction_type?.name === 'phone'">
        <div class="my-1 flex flex-row">
          <div class="align-center mr-2 flex items-center justify-center rounded border-1" style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-calendar text-left text-xl text-gray-400"></i>
          </div>
          <div class="mb-2 flex flex-col items-start py-1 md:border-none md:py-0">
            <div class="text-xs font-normal text-gray-400">Date</div>
            <div class="text-sm font-semibold text-gray-600">{{ formatDate(referral.start_datetime) }}</div>
          </div>
        </div>
        <div class="my-1 flex flex-row">
          <div class="align-center mr-2 flex items-center justify-center rounded border-1" style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-clock text-left text-xl text-gray-400"></i>
          </div>
          <div class="mb-2 flex flex-col items-start border-solid border-gray-100 py-1 md:border-none md:py-0">
            <div class="text-xs font-normal text-gray-400">Time</div>
            <div class="text-sm font-semibold text-gray-600">{{ formatTime(referral.start_datetime) }} {{ getUserTimeZoneAbbr() }}</div>
          </div>
        </div>
      </template>
      <template v-if="referral?.interaction_type?.name === 'econsult'">
        <div class="my-1 flex flex-row">
          <div class="align-center mr-2 flex items-center justify-center rounded border-1" style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-envelope text-left text-xl text-gray-400"></i>
          </div>
          <div class="mb-2 flex flex-col items-start py-1 md:border-none md:py-0">
            <div class="text-xs font-normal text-gray-400">Consult Type</div>
            <div class="text-sm font-semibold text-gray-600 flex flex-row">
              {{ referral.interaction_type.display_name }} <span class="flex"><i class="fa fa-plus text-pink-500 flex" style="font-size: 0.7rem; font-weight: 900"></i>&nbsp;</span>
            </div>
          </div>
        </div>
      </template>
    </div>

    <Button :class="'w-full items-center p-button-sm ' + referral.className" @click="selectReferral(referral.id)">
      <div v-if="referral.status === 'Upcoming'" class="mx-auto">View Consult</div>
      <div v-else-if="referral.status === 'Pending'" class="mx-auto">
        <span v-if="isGP(loggedInUser)">Sign Off and Submit</span>
        <span v-else>View Consult Info</span>
      </div>
      <div v-else class="mx-auto">View {{ referral.status }} Consult</div>
    </Button>
  </div>
</template>

<script>
import Button from 'primevue/button';
import { mapGetters } from 'vuex';

export default {
  props: ['referrals'],
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'actingUser', 'isSpecialist']),
  },
  components: {
    Button,
  },
  methods: {
    selectReferral(id) {
      this.$router.push('/referral/' + id);
    },
  },
};
</script>

<style>
.no-consult-card border-1 {
  height: 245px;
}

.tile {
  animation-delay: calc(var(--index) * 75ms);
}

.rotate-45 {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}
</style>
