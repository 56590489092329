<template>
  <div class="p-4 privacy-content-left">
    <h1 class="text-2xl font-bold">General Privacy Policy</h1>
    <p><span>Last Updated: May 3, 2021.</span></p>
    <p><span>Effective Date: June 1, 2021.</span></p>
    <p class="privacy-content-item">
      <span>Virtual Hallway Consults Inc. (“Virtual Hallway”, “Us”, “We”, or “Our”) operates the </span><span class="c4"><a href="https://www.google.com/url?q=https://virtualhallway.ca&amp;sa=D&amp;source=editors&amp;ust=1621892704928000&amp;usg=AOvVaw3-qthx5dukvIO3qsHSDUvp">www.virtualhallway.ca</a></span
      ><span>&nbsp;Website (the “Website”). This General Privacy Policy (the “Policy”) informs you (“You” or “Your”) of Our policies regarding the collection, use, and disclosure of personally identifiable information when You use the Website and the choices You have associated with the data You submitted to Us. We use Your data to provide and improve the Website. </span>
    </p>
    <p class="privacy-content-item">
      <span>By using the Website, You agree to the collection and use of information under this Policy. Unless otherwise defined in this Policy, terms used in this Policy have the same meanings as in Our General Terms and Conditions (</span><span class="c4"><a href="https://www.google.com/url?q=https://virtualhallway.ca/terms-of-service/&amp;sa=D&amp;source=editors&amp;ust=1621892704929000&amp;usg=AOvVaw384iesIb2Ex4b0qXWg4ssw">www.virtualhallway.ca/terms-of-service/</a></span
      ><span>).</span>
    </p>
    <p class="privacy-content-item"><span>This Policy describes:</span></p>
    <ul class="c1">
      <li class="privacy-bullet-point">
        <span>How We collect, use, disclose, and protect any personal information submitted by You on the Website.</span>
      </li>
      <li class="privacy-bullet-point">
        <span>Describes the types of information We may collect from You or that You may provide when You visit the Website</span>
      </li>
      <li class="privacy-bullet-point">
        <span>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</span>
      </li>
    </ul>
    <p class="privacy-content-item"><span>This Policy DOES NOT include any policies regarding Our </span><span>collection, use, and disclosure of personal health information or any information through Your interaction with the Website’s Platform. Please note that Your usage of the Platform is subject to the Platform Privacy Policy in addition to this General Privacy Policy. Our Platform Privacy Policy is available at (insert Link).</span></p>
    <p class="privacy-content-item">
      <span>This policy DOES NOT apply to information that You provide to or is collected by any third party, through any application or content (including advertising) that may link to or be accessible from or on the Website.</span>
    </p>
    <p class="privacy-content-item">
      <span>We will only use Your personal information in accordance with this Policy unless otherwise required by applicable law. We take steps to ensure that the personal information that We collect about You is adequate, relevant, not excessive, and used for limited purposes. Privacy laws in Canada generally define “personally information” as any information about an identifiable individual, which includes information that can be used on its own or with other information to identify, contact, or locate a single person. </span>
    </p>
    <p class="privacy-content-item">
      <span>This Policy applies to information We collect, use, or disclose about You:</span>
    </p>
    <ul class="c1">
      <li class="privacy-bullet-point"><span>On this Website.</span></li>
      <li class="privacy-bullet-point">
        <span>In email, text, and other electronic messages between You and Virtual Hallway.</span>
      </li>
    </ul>
    <p class="privacy-content-item">
      <span class="privacy-sub-heading">Please read this policy carefully to understand Our policies and practices for collecting, processing, and storing Your information. If You do not agree with Our policies and practices, Your choice is not to use Our Website. </span><span class="">By accessing or using this Website, You indicate that You understand, accept, and consent to the practices described in this policy. This policy may change from time to time (see Changes to Our Privacy Policy</span>
      <span>). Your continued use of this Website after We make changes indicates that You accept and consent to those changes, so please check the policy periodically for updates.</span>
    </p>
    <ol class="privacy-number-title" start="1">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Information Collection and Use</span>
      </li>
    </ol>

    <p>
      <span>We collect several different types of information for various purposes to provide and improve Our Website to You. </span>
    </p>
    <p class="privacy-content-item">
      <span class="privacy-sub-heading under-line">Types of Data Collected</span>
    </p>
    <ol class="c1" type="a" start="1">
      <li class="privacy-letter-list"><span>Personal Data</span></li>
    </ol>
    <p class="privacy-roman-numerals">
      <span>While using Our Service, We may ask You to provide Us with certain personal information that can be used to contact or identify You. The information we collected may include but is not limited to: Email address, First name and last name, Your device’s Internet Protocol address, and phone number. </span>
    </p>
    <div></div>
    <ol class="c1" type="a" start="2">
      <li class="privacy-letter-list"><span>Usage Data</span></li>
    </ol>
    <p class="privacy-roman-numerals">
      <span>We may also collect information how the Website is accessed and used (“Usage Data”). This Usage Data may include information such as browser type, browser version, the pages of Our Website that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data. </span>
    </p>
    <ol class="c1" type="a" start="3">
      <li class="privacy-letter-list">
        <span>Tracking &amp; Cookies Data</span>
      </li>
    </ol>
    <p class="privacy-roman-numerals">
      <span>We use cookies and similar tracking technologies to track the activity on Our Website and hold certain information. Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to Your browser from a Website and stored on Your device. Tracking technologies also used are web beacons, tags, and scripts to collect and track information and to improve and analyze Our Website. You can instruct Your browser to refuse all cookies or to indicate when a cookie is being sent. However, if You do not accept cookies, You may not be able to use some portions of Our Website. Examples of Cookies We use: </span>
    </p>
    <ol class="c1" type="i" start="1">
      <li class="privacy-roman-numerals"><span class="privacy-roman-numerals-spacing--1 privacy-sub-heading">Session Cookies.</span><span>&nbsp;We use Session Cookies to operate Our Website.</span></li>
      <li class="privacy-roman-numerals"><span class="privacy-roman-numerals-spacing--2 privacy-sub-heading">Preference Cookies.</span><span>&nbsp;We use Preference Cookies to remember Your preferences and various settings.</span></li>
      <li class="privacy-roman-numerals"><span class="privacy-roman-numerals-spacing--3 privacy-sub-heading">Security Cookies.</span><span>&nbsp;We use Security Cookies for security purposes.</span></li>
    </ol>
    <ol class="privacy-number-title" start="2">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">How We Collect Information About You</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>We use different methods to collect Your information, including through:</span>
    </p>
    <ol class="c1" type="a" start="1">
      <li class="privacy-letter-list">
        <span>Direct interactions with You when You provide it to us, for example, by filling in forms or corresponding with Us by phone, email, or otherwise. </span>
      </li>
      <li class="privacy-letter-list">
        <span>Automated technologies or interactions, as You navigate through Our Website. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Third parties or publicly available sources, for example, Our business or advertising partners.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="3">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Information You Provide to Us </span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>The information We collect directly from You on or through Our Website may include:</span>
    </p>
    <ol class="c1" type="a" start="1">
      <li class="privacy-letter-list">
        <span>Information that You provide by filling in forms on Our Website. This includes information provided at the time of registering to use Our Platform, subscribing to Our Platform, posting any material, or requesting further services. We may also ask You for information when You enter a contest or promotion sponsored by us, and when You report a problem with Our Website.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Records and copies of Your correspondence (including email addresses), if You contact Us.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="4">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Use of Your Information</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>Virtual Hallway uses the collected data for various purposes: </span>
    </p>
    <ol class="c1" type="a" start="1">
      <li class="privacy-letter-list">
        <span>To provide and maintain the Website;</span>
      </li>
      <li class="privacy-letter-list">
        <span>To notify You about changes to Our Website;</span>
      </li>
      <li class="privacy-letter-list">
        <span>To allow You to participate in interactive features of Our Website when You choose to do so;</span>
      </li>
      <li class="privacy-letter-list">
        <span>To provide customer care and support;</span>
      </li>
      <li class="privacy-letter-list">
        <span>To provide analysis or valuable information so that We can improve the Website;</span>
      </li>
      <li class="privacy-letter-list">
        <span>To monitor the usage of the Website;</span>
      </li>
      <li class="privacy-letter-list">
        <span>To detect, prevent and address technical issues; and</span>
      </li>
      <li class="privacy-letter-list">
        <span>For any other purpose with Your consent.</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>We may also use Your information to contact You about Our own and third-parties’ goods and services that may be of interest to You, as permitted by law. If You do not want Us to use Your information in this way, please e-mail Us at privacy@virtualhallway.ca. For more information, see Choices About How We Use and Disclose Your Information.</span>
    </p>
    <p class="privacy-content-item">
      <span>We may use the information We have collected from You to enable Us to display advertisements to Our advertisers’ target audiences. Even though We do not disclose Your personal information for these purposes without Your consent, if You click on or otherwise interact with an advertisement, the advertiser may assume that You meet its target criteria.</span>
    </p>
    <ol class="privacy-number-title" start="5">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Data Processing and Transfer</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>Please note that We store and process Your information, including Personal Data, in Canada. If You are located outside Canada and choose to provide information to us, Your consent to this Policy followed by Your submission of such information represents Your agreement to that transfer. </span>
    </p>
    <p class="privacy-content-item">
      <span>We may transfer personal information that We collect or that You provide as described in this policy to contractors, Our Service Providers, and other third parties We use to support Our business (such as analytics and search engine providers that assist Us with Website improvement and optimization) and who are contractually obligated to keep personal information confidential, use it only for the purposes for which We disclose it to them, and to process the personal information with the same standards set out in this Policy.</span>
    </p>
    <ol class="privacy-number-title" start="6">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Disclosure of Your Information</span>
      </li>
    </ol>
    <ol class="c1" type="a" start="1">
      <li class="privacy-letter-list">
        <span>Virtual Hallway may disclose aggregated information about Our users, and information that does not identify any individual, without restriction.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Virtual Hallway may disclose personal information that We collect or You provide in the good faith belief that such action is necessary: </span>
      </li>
    </ol>
    <ol class="c1" type="i" start="1">
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--1">To comply with Our legal obligations, including to respond to any government or regulatory request, in accordance with applicable law;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">To fulfill the purpose for which You provide it;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--3">In accordance with applicable law, to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Virtual Hallway’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Virtual Hallway is among the assets transferred;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">To protect and defend the rights or property of Virtual Hallway;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--1">To prevent or investigate possible wrongdoing in connection with Our services;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--2">To protect the personal safety of users of the Website or the public; and</span>
      </li>
      <li class="privacy-roman-numerals">
        <span class="privacy-roman-numerals-spacing--3">To protect against Our legal liability.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="7">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Security Of Data</span>
      </li>
    </ol>
    <p><span>Personal information provided on the Website is transmitted through a secure server using Secure Socket Layering (SSL) encryption technology. The security of Your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your personal information, We cannot guarantee its absolute security.</span><span>&nbsp;</span><span>We are not responsible for circumvention of any privacy settings or security measures contained on the Website.</span></p>
    <ol class="privacy-number-title" start="8">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Retention of Data</span>
      </li>
    </ol>
    <p>
      <span>Except as otherwise permitted or required by applicable law or regulation, We will only retain Your personal information for as long as necessary to fulfill the purposes We collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. Under some circumstances We may anonymize Your personal information so that it can no longer be associated with You. We reserve the right to use such anonymous and de-identified data for any legitimate business purpose without further notice to You or Your consent.</span>
    </p>
    <ol class="privacy-number-title" start="9">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Accessing and Correcting Your Personal Information</span>
      </li>
    </ol>
    <p>
      <span>You may send Us an email at privacy@virtualhallway.ca to request access to, correct, or delete any Personal Information that You have provided to Us. We may not accommodate a request to change information if We believe the change would violate any law or legal requirement or cause the information to be incorrect. We may need to delete Your account to delete Your personal information.</span>
    </p>
    <p>
      <span>We may request specific information from You to help Us confirm Your identity and Your right to access, and to provide You with the personal information that We hold about You or make Your requested changes. If We cannot provide You with access to Your personal information, We will inform You of the reasons why, subject to any legal or regulatory restrictions. We will provide access to Your personal information, subject to exceptions set out in applicable privacy legislation.</span>
    </p>
    <p>
      <span>If You are concerned about Our response or would like to correct the information provided, You may contact Our Privacy Officer at </span><span class="c4"><a href="mailto:privacy@virtualhallway.ca">privacy@virtualhallway.ca</a></span
      ><span>.</span>
    </p>
    <ol class="privacy-number-title" start="10">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Withdraw Your Consent</span>
      </li>
    </ol>
    <p>
      <span>Where You have provided Your consent to the collection, use, and transfer of Your personal information, You may have the legal right to withdraw Your consent under certain circumstances. To withdraw Your consent, if applicable, contact Us at </span><span class="c4"><a href="mailto:privacy@virtualhallway.ca">privacy@virtualhallway.ca</a></span
      ><span>. Please note that if You withdraw Your consent We may not be able to provide You with a particular product or service. We will explain the impact to You at the time to help You with Your decision.</span>
    </p>
    <ol class="privacy-number-title" start="11">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Third-Party Providers</span>
      </li>
    </ol>
    <ol class="c1" type="a" start="1">
      <li class="privacy-letter-list">
        <span>From time to time, We may engage with or employ third-party entities and individuals (“Service Providers”) for maintaining functionalities of and making changes to the Website, or to host information and data of the Website. </span>
      </li>
      <li class="privacy-letter-list">
        <span>Service Providers have access to Your Personal Data only to perform these tasks on Our behalf and are obligated not to disclose or use it for any other purpose. </span>
      </li>
      <li class="privacy-letter-list">
        <span>From time to time, Service Providers may also access or process de-identified Personal Information to assist Us in analyzing how Our Website is used.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="12">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Links To Other Sites</span>
      </li>
    </ol>
    <p class="privacy-content-item">
      <span>The Website may include plug-ins, services, social networks, applications, or links to third-party websites. Clicking on those links or enabling those connections may allow the third party to collect or share data about You. If You follow a link to a third-party website or engage a third-party plug-in, please note that these third parties have their own privacy policies, and We do not accept any responsibility or liability for the practice of third-party websites and these policies. We do not control these third-party websites or applications, and We encourage You to read the privacy policy of every website or application You visit. </span>
    </p>
    <ol class="privacy-number-title" start="13">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Children’s Privacy</span>
      </li>
    </ol>
    <p>
      <span>You represent and warrant that you are over the majority age of your jurisdiction or age of 19 (whichever is higher) when you access or use the Website. Our Website does not address any user under the age of the majority of Your jurisdiction (“Minor”). We do not knowingly collect personal information from a Minor. </span>
    </p>
    <ol class="privacy-number-title" start="14">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Changes to This Policy</span>
      </li>
    </ol>
    <p>
      <span>We may update Our Policy from time to time. We will notify You of any changes by posting the new Policy on this page. We will let You know via email and/or a prominent notice on Our Website, prior to the change becoming effective and update the “Effective Date” at the top of this Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. </span>
    </p>
    <ol class="privacy-number-title" start="15">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Contact Us</span>
      </li>
    </ol>
    <p>
      <span>If You have any questions about this General Privacy Policy, please contact Us by email: </span><span class="c4"><a href="mailto:privacy@virtualhallway.ca">privacy@virtualhallway.ca</a></span>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
.privacy-content-left {
  align-items: flex-start;
  text-align: left;
}

.privacy-roman-numerals-spacing--1 {
  margin-left: 8.5px;
  padding-left: 0pt;
}

.privacy-roman-numerals-spacing--2 {
  margin-left: 3.5px;
  padding-left: 0pt;
}
.privacy-roman-numerals-spacing--3 {
  margin-left: 0px;
  padding-left: 0pt;
}

.privacy-content-item {
  padding-top: 10px;
  orphans: 2;
  widows: 2;
}
.under-line {
  text-decoration: underline;
}
.privacy-letter-list {
  margin-left: 20pt;
  padding-top: 5pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.4;
  orphans: 2;
  widows: 2;
}
.privacy-bullet-point {
  margin-left: 36pt;
  padding-top: 6pt;
  padding-left: 0pt;
  padding-bottom: 6pt;
  line-height: 1.4;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.privacy-sub-heading {
  font-size: 12pt;
  font-weight: 700;
}

li.privacy-roman-numerals {
  list-style-position: inside;
}
.privacy-number-title {
  padding: 0;
  margin: 0;
  margin-left: 18px;
  margin-top: 8px;
}
.privacy-roman-numerals {
  margin-left: 36px;
}
</style>
