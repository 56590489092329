<template>
  <div class="w-full">
    <h1 class="text-xl font-bold text-gray-600 mb-4 text-center">Ontario Ministry of Health and Long-Term Care <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="5" /></h1>
    <form method="post" @submit.prevent="submit" class="billing-arrangement-content">
      <p class="text-xs mb-2 info-paragraph--gray">
        This form will authorize the Ministry of Health and Long-Term Care (the ministry) to make payment directly to the Group identified below. Completing this form allows Virtual Hallway to bill OHIP on your behalf using billing codes K730 and K731. If you are joining more than one group, complete and sign an Authorization for Group Payment form for each group where you will be providing services on behalf of the Group. <br /><br />
        Click <a target="_blank" style="text-decoration: underline" href="https://app.virtualhallway.ca/images/ontario_billing_guide.pdf">here</a> for more information.
      </p>
      <div class="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-4">
        <p class="text-xs">Provider Group Name: <span class="font-bold text-gray-900">Virtual Hallway</span>&nbsp;</p>
        <p class="text-xs">Group Billing Number: <span class="font-bold text-gray-900">4246</span>&nbsp;</p>
        <p class="text-xs">
          Effective Date with Group <br /><span class="font-bold text-gray-900">{{ todaysDate }}</span>
        </p>
        <p class="text-xs">
          Service Provider Name: <br /><span class="font-bold text-gray-900">{{ actingUser.first_name }} {{ actingUser.last_name }}</span>
        </p>
      </div>
      <div class="grid grid-cols-2 gap-1 gap-x-3">
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label for="eTransferEmail" class="auth-input">E-Transfer Email<span class="error">&nbsp; *</span></label>
          <InputText name="eTransferEmail" id="eTransferEmail" v-model="eTransferEmail" required class="auth-input" type="email" />
        </div>
        <div class="mb- col-start-1 col-end-3 sm:col-auto">
          <label for="serviceProviderNumber" class="auth-input">OHIP Billing Number<span class="error">&nbsp; *</span></label>
          <InputText name="serviceProviderNumber" id="serviceProviderNumber" v-model="serviceProviderNumber" class="auth-input billing-arrangement-side-by-side" required maxLength="255" />
        </div>
      </div>
      <template v-if="isOrgUnitHead(loggedInUser)">
        <div class="mt-2 text-center">
          <Message severity="info" class="text-center"> Please download the OMHLTC form and have the physician fill out the form and sign,<br />then attach the form below and submit. </Message>
          <Button icon="pi pi-download" label="Download OMHLTC Form" class="p-button-secondary p-button-outlined mb-2 rounded py-2 px-4 font-bold text-white" type="button" @click="downloadForm" id="downloadBillingArrangementButton" />
          <br />
          <br />
          <FileUpload name="files[]" :maxFileSize="20000000" :fileLimit="1" accept=".pdf" :customUpload="true" @select="handleFileChange" :uploadLabel="'Submit'" :showCancelButton="false" :showUploadButton="false" chooseLabel="Select" @remove="remove" :key="rerender">
            <template #empty>
              <p class="text-sm">Drag and drop files to here to upload.</p>
            </template>
          </FileUpload>
        </div>
      </template>
      <template v-else>
        <!-- <p class="mb-2 text-xs">By signing below, I assign payment for services rendered by me and submitted by the above group to be made directly to the Business Arrangement(s) established for the group.&nbsp;</p> -->
        <label for="signature" class="auth-input" v-if="!signatureError">Signature <span class="error">&nbsp; *</span></label>
        <label for="signature" class="auth-input error" v-else>Please Provide a Signature <span class="error">&nbsp; *</span></label>
        <Signature ref="Signature" id="signature" class="mb-2" />

        <Checkbox v-model="signatureAgreement" :binary="true" name="signatureAgreement" inputId="signatureAgreement" class="mr-2" />
        <label for="signatureAgreement" class="signature-agreement-text"><span class="error"></span>By signing above, I agree to use of an electronic signature as a valid substitute for my handwritten signature, I assign payment for services rendered by me and submitted by the above group to be made directly to the Business Arrangement(s) established for the group.</label>
        <label for="signatureAgreement" class="signature-agreement-text error" v-if="signatureAgreementError"><br />Please agree the electronic signature is a valid substitute.</label>
      </template>

      <div class="error">
        {{ errorMessage }}
      </div>
      <div class="flex justify-center py-5">
        <Button icon="pi pi-check" type="submit" label="Submit" name="submit" id="submit" class="w-1/3"></Button>
      </div>
    </form>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import moment from 'moment';
import Modal from '@/components/misc/Modal.vue';
import { mapGetters, mapActions } from 'vuex';
import Signature from '@/components/misc/Signature.vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Loading from 'vue-loading-overlay';
import FileUpload from 'primevue/fileupload';
import Message from 'primevue/message';

export default {
  emits: ['closeBillingArrangementModal'],
  computed: {
    ...mapGetters(['loggedInUser', 'billingArrangement', 'actingUser', 'isOrgUnitHead']),
  },
  components: {
    Signature,
    Modal,
    InputText,
    Button,
    Checkbox,
    Loading,
    FileUpload,
    Message,
  },
  data() {
    return {
      errorMessage: '',
      todaysDate: moment().format('YYYY-MM-DD'),
      serviceProviderNumber: null,
      signatureAgreement: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      signatureAgreementError: false,
      signatureError: false,
      isLoading: false,
      eTransferEmail: '',
      file: null,
      rerender: false,
    };
  },
  methods: {
    ...mapActions(['updateBillingArrangement', 'uploadBillingArrangement', 'downloadUnSignedBillingForm']),
    submit() {
      if (this.isOrgUnitHead(this.loggedInUser)) {
        this.uploadOmhltcForm();
      } else {
        this.updatedOmhltcWithSignature();
      }
    },
    updatedOmhltcWithSignature() {
      this.signatureError = this.isSignatureEmpty();
      this.signatureAgreementError = !this.signatureAgreement;
      if (!this.signatureError && this.signatureAgreement && this.serviceProviderNumber) {
        this.urltoFile(this.saveSignature(), 'sign_file').then((file) => {
          let user_id = this.actingUser.id;
          const data = new FormData();
          data.append('sign_file', file);
          data.append('service_provider_number', this.serviceProviderNumber);
          data.append('is_agreed_to_use_sign', this.signatureAgreement);
          data.append('sign_date', this.todaysDate); //Passed to the user BillingArrangement Object for our records, but PDF is generated with loggedInUser.created_at in BillingArrangementService
          data.append('user_id', user_id);
          data.append('timezone', this.timezone);
          data.append('etransfer_email', this.eTransferEmail);
          data.append('practice_province_id', this.actingUser.practice_province.id);
          this.isLoading = true;
          this.updateBillingArrangement(data)
            .then((response) => {
              this.isLoading = false;
              this.$emit('closeBillingArrangementModal', {
                data: response.data.data,
                show: false,
              });
            })
            .catch(() => {
              this.isLoading = false;
              this.showGenericErrorToast();
            });
        });
      }
    },
    uploadOmhltcForm() {
      this.errorMessage = '';
      if (this.file === null) {
        this.errorMessage = 'Please upload the OMHLTC Form to submit';
      } else {
        const data = new FormData();
        data.append('file', this.file);
        data.append('user_id', this.actingUser.id);
        data.append('etransfer_email', this.eTransferEmail);
        data.append('service_provider_number', this.serviceProviderNumber);
        data.append('practice_province_id', this.actingUser.practice_province.id);
        this.isLoading = true;
        this.uploadBillingArrangement(data)
          .then((response) => {
            this.isLoading = false;
            this.$emit('closeBillingArrangementModal', {
              data: response.data.data,
              show: false,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
    downloadForm() {
      this.downloadUnSignedBillingForm({ userId: this.actingUser.id, practiceProvinceId: this.actingUser.practice_province.id })
        .then()
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    isSignatureEmpty() {
      var _this = this;
      return _this.$refs.Signature.isSignatureEmpty();
    },
    saveSignature() {
      var _this = this;
      return _this.$refs.Signature.saveSignature();
    },
    urltoFile(url, filename) {
      var _this = this;
      return _this.$refs.Signature.urltoFile(url, filename);
    },
    remove() {
      this.rerender = !this.rerender;
      this.file = null;
    },
    handleFileChange(event) {
      this.file = event.files[0];
    },
  },
  created() {
    if (this.actingUser) {
      this.eTransferEmail = this.actingUser.email;
    }
    if (this.billingArrangement) {
      this.serviceProviderNumber = this.billingArrangement.service_provider_number;
    }
  },
};
</script>

<style>
.billing-arrangement-content {
  text-align: start;
  font-size: 14px;
}
.billing-arrangement-content p,
.billing-arrangement-content label {
  padding-top: 8px;
}
.billing-arrangement-container {
  padding: 0 20px 0 20px;
}
.billing-arrangement-rows {
  justify-content: space-between;
}
.billing-arrangement-side-by-side {
  width: 48%;
}
.signature-agreement-text {
  font-size: 13px;
}
#signature {
  padding-bottom: 10px;
  margin-right: 60px;
}
.billing-arrangement-footer {
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  display: flex;
}
</style>
