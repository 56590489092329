<template>
  <a @click="openEula" class="text-xs text-secondary underline" href="#" id="smartpath-eula-button">SmartPath EULA Agreement</a>
  <Dialog header="VIRTUAL HALLWAY SMARTPATH WEB APP END USER LICENSE AGREEMENT (EULA)" :breakpoints="{ '960px': '50vw', '640px': '90vw' }" style="max-width: 800px; width: 100%" v-model:visible="show" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" class="euladialog p-0" id="eulaDialog">
    <div class="max-h-400 bg-gray-100 p-6 text-left text-sm" ref="smartpath-eula" id="smartpath-eula">
      <article class="page sans">
        <!-- <header><h1 class="page-title">EULA</h1></header> -->
        <div class="page-body">
          <p><strong>VIRTUAL HALLWAY SMARTPATH WEB APP END USER LICENSE AGREEMENT (EULA)</strong></p>
          <p>This End User License Agreement ("Agreement") is entered into by and between VIRTUAL HALLWAY, a corporation incorporated under the laws of Canada (<strong>"Company"</strong> or <strong>"VIRTUAL HALLWAY"</strong>), and the individual or entity agreeing to these terms (<strong>"User"</strong> or <strong>"You"</strong>)</p>

          <p class="my-6"><strong>1. Purpose of the Agreement.</strong></p>
          <p>The Company presents SmartPath, a clinical decision support tool embedded within the Virtual Hallway web app. SmartPath facilitates the creation of clinical decision pathways by specialist physicians, which generate recommendations based on user input. These pathways are then used by end users, primarily primary care practitioners, to assist in decision-making.</p>

          <p class="my-6"><strong>2. Clarifications.</strong></p>
          <p>a. The Company, VIRTUAL HALLWAY, is solely a software provider and does not provide any clinical advice.</p>
          <p>b. Clinical decision-making rests entirely with the end user. It is the responsibility of the user to exercise clinical judgment.</p>
          <p>c. Specialist physicians creating pathways must review and apply clinical judgment to ensure the accuracy and relevance of pathways.</p>

          <p class="my-6"><strong>3. Scope of Use.</strong></p>
          <p>a. The User agrees to use SmartPath for legitimate medical decision support purposes.</p>
          <p>b. The User acknowledges that any recommendation provided by SmartPath is to be used as a guide and not as definitive medical advice.</p>
          <p>c. The User confirms understanding that final clinical decisions remain the sole responsibility of the practitioner using the software.</p>

          <p class="my-6"><strong>4. Intellectual Property and Licensing.</strong></p>
          <p>a. Specialists who create pathways within SmartPath hereby license the intellectual property of said pathways to VIRTUAL HALLWAY for unrestricted use worldwide in perpetuity.</p>
          <p>b. The User agrees not to, and will not permit any third party to, reverse engineer, decompile, disassemble, modify, or create derivative works based on the SmartPath software or any related materials.</p>
          <p>c. The User agrees to a non-competition clause, refraining from developing, endorsing, or promoting any similar product that competes with SmartPath for the duration of their usage and for a period of two years thereafter.</p>

          <p class="my-6"><strong>5. Data and Privacy.</strong></p>
          <p>The Company ensures measures for data protection, including encryption and multi-factor authentication. Users must adhere to all relevant data privacy regulations, including but not limited to the Personal Information Protection and Electronic Documents Act (PIPEDA) and the provincial privacy or health information act of the province relevant to the consultation, as it may be amended from time to time and including any relevant regulations. For example in Nova Scotia - the Personal Health Information Act (PHIA), for Ontario - the Personal Health Information Protection Act (PHIPA), for British Columbia – the Personal Information Protection Act (PIPA), Personal Health Information Privacy Act (PHIPA), and so on.</p>

          <p class="my-6"><strong>6. No Warranty.</strong></p>
          <p>SmartPath is provided "as is" without any warranty of any kind. The Company makes no warranty that SmartPath will be error-free or function without disruptions</p>

          <p class="my-6"><strong>7. Liability.</strong></p>
          <p>The User agrees to indemnify and hold the Company harmless against any claims, losses, liabilities, damages, costs, or expenses, arising out of the User's misuse of SmartPath or any decisions made based on its recommendations</p>

          <p class="my-6"><strong>8. Governing Law.</strong></p>
          <p>This Agreement shall be governed by and construed in accordance with the laws of Canada and the Province of Nova Scotia, without giving effect to any principles of conflicts of law.</p>

          <p class="my-6"><strong>9. No Medical Advice.</strong></p>
          <p>a. The <strong>"SmartPath"</strong> tool provided by VIRTUAL HALLWAY is intended solely as a clinical decision support tool and not as a definitive diagnostic, treatment, or therapeutic tool.</p>
          <p>b. VIRTUAL HALLWAY does not provide medical advice or recommendations. The user acknowledges and agrees that any and all clinical decisions are made by the user based on their professional judgment.</p>
          <p>c. The user acknowledges that the tool is meant to support and not replace the user's judgment. Any decisions made in patient care remain the sole responsibility of the user.</p>

          <p class="my-6"><strong>10. Specialist Responsibility.</strong></p>
          <p>a. Specialists creating pathways using the <strong>"SmartPath"</strong> builder are responsible for reviewing and validating the pathways once they are complete.</p>
          <p>b. By creating pathways, the specialist licenses the intellectual property to VIRTUAL HALLWAY for unrestricted use worldwide perpetually.</p>
          <p>c. Specialists understand that they must exercise clinical judgment in reviewing and validating pathways, ensuring they reflect accurate and up-to-date clinical knowledge and standards.</p>

          <p class="my-6"><strong>11. Intellectual Property and Non-Competition.</strong></p>
          <p>a. The user agrees not to reverse engineer, decompile, or otherwise attempt to discover the source code or underlying algorithms of <strong>"SmartPath"</strong> or any related software.</p>
          <p>b. The user acknowledges that by using <strong>"SmartPath"</strong>, they agree to a non-compete clause, refraining from creating or promoting any software or tool that directly competes with <strong>"SmartPath"</strong> for a duration of 24 months from the date of last use.</p>
          <p>c. All intellectual property rights related to <strong>"SmartPath"</strong> belong exclusively to VIRTUAL HALLWAY.</p>

          <p class="my-6"><strong>12. Termination.</strong></p>
          <p>VIRTUAL HALLWAY reserves the right to terminate the user's access to <strong>"SmartPath"</strong> at any time, for any reason, without notice.</p>

          <p class="my-6"><strong>13. Entire Agreement.</strong></p>
          <p>This Agreement constitutes the entire agreement between the parties concerning the subject matter hereof and supersedes all prior and contemporaneous agreements and understandings, whether oral or written.</p>

          <p class="my-6"><strong>14. Relation to Virtual Hallway EULA.</strong></p>
          <p>This Agreement is in addition to and does not nullify any other agreement between the parties, including the End User License Agreement (EULA) for Virtual Hallway products or services. The terms of the existing EULA for Virtual Hallway remain intact and in full effect.</p>

          <p class="my-6"><strong>15. Amendments.</strong></p>
          <p>VIRTUAL HALLWAY reserves the right to modify, amend, or change the terms of this Agreement at any time, and it's the responsibility of the user to review this Agreement periodically.</p>

          <br />
          <p>By using <strong>"SmartPath"</strong>, <strong>You</strong> acknowledge that <strong>You</strong> have read, understood, and agree to be bound by this End User License Agreement.</p>
        </div>
      </article>
    </div>
    <template #footer>
      <Button label="Close" id="smartPathEULACloseButton" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="closeEula()" />
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
export default {
  components: {
    Button,
    Dialog,
  },
  data() {
    return {
      isLoading: false,
      show: false,
    };
  },
  methods: {
    openEula() {
      this.show = true;
    },
    closeEula() {
      this.show = false;
    },
  },
};
</script>
<style>
#eulaDialog .p-dialog-content {
  padding: 0;
  height: 75vh;
}

#eula > ul,
#eula > ol {
  list-style: revert;
  margin-left: 1.5rem;
}
</style>
