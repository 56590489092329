<template>
  <Dialog header="Send Thank You Message." v-model:visible="showModal" :draggable="false" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '40vw' }" :modal="true">
    <div class="sm:flex sm:flex-col md:grid">
      <div class="mx-4 mb-4">
        <div class="tip-title mb-3 text-sm">Did the {{ getReferralPersonTerm(actingUser) }} do above and beyond? Send {{ displayNameByRole(referral.referral_person) }} a personalized thank you note!</div>
        <form method="post" @submit.prevent="sendThankYouMessage">
          <div class="mb-2 mt-4 w-full">
            <span class="w-full">
              <label for="university-name-input" class="auth-input">Message</label>
              <Textarea v-model="message" class="auth-input" :autoResize="true" rows="5" cols="30" required maxLength="65535" placeholder="Example: Thanks so much for your help, the patient is..." />
            </span>
          </div>
          <div class="flex flex-row justify-end items-end">
            <div class="mr-2"><Button label="Cancel" icon="" @click="closeDialog" class="p-button-secondary p-button-outlined" /></div>
            <div><Button label="Submit" class="p-button w-full text-center" type="submit" id="inquirySubmitButton" /></div>
          </div>
        </form>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import Dialog from 'primevue/dialog';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Button,
    Textarea,
    Dialog,
  },
  props: ['referral'],
  data() {
    return {
      showModal: false,
      isLoading: false,
      message: '',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser']),
  },
  created() {
    if (this.referral.thank_you) {
      this.message = this.referral.thank_you.message;
    }
  },
  methods: {
    ...mapActions(['createOrUpdateThankYou']),
    closeDialog() {
      this.showModal = false;
    },
    openDialog() {
      this.showModal = true;
    },
    sendThankYouMessage() {
      this.$toast.add({
        severity: 'success',
        summary: 'Message Sent!',
        life: 3000,
      });
      this.closeDialog();
      const data = { thank_you_id: this.referral.thank_you.id, referral_id: this.referral.id, sender_id: this.referral.referee.id, receiver_id: this.referral.referral_person.id, message: this.message };
      this.createOrUpdateThankYou(data)
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>
<style>
#faxConsultDialog .p-dialog-content {
  padding: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
