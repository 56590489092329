<template>
  <div class="col-span-2 mt-2 mb-4 flex flex-row items-center justify-center">
    <h1 class="mr-2 text-lg font-bold text-gray-600">Subscribe To Calendar</h1>
    <div class="h-1 flex-grow bg-gray-600"></div>
  </div>
  <div class="mb-12">
    <Message severity="info" :closable="false" class="text-left">Select your device below to have Virtual Hallway consult bookings added to your calendar. Depending on which app you use, it can take up to 12 hours for your consults to update within it. </Message>
    <div class="mb-3 flex flex-col sm:flex-row text-left justify-between border-b border-solid border-gray-200 p-2 pb-4">
      <div>
        <p class="text-sm font-semibold font-display text-gray-600">Subscribe on this Device</p>
        <p class="text-sm text-gray-500">This will open your device's default calendar app. <TutorialVideoDialog video="ical_subscription" linkLabel="Learn How" /></p>
      </div>
      <div class="p-grid p-ai-center pl-2">
        <a :href="accessDeviceUrl" class="mt-4 sm:mt-0 w-full sm:w-52" @click="subscribeCalendarOnClick('Calendar Subscription - Device')">
          <Button label="Device Calendar" icon="pi pi-desktop" iconPos="left" class="w-52 p-button-secondary"> </Button>
        </a>
      </div>
    </div>
    <div class="mb-3 flex flex-col sm:flex-row text-left justify-between border-b border-solid border-gray-200 p-2 pb-4">
      <div>
        <p class="text-sm font-semibold font-display text-gray-600">Subscribe on Google Calendar</p>
        <p class="text-sm text-gray-500">Add Virtual Hallway Calendar to your Google calendar.</p>
      </div>
      <div class="p-grid p-ai-center pl-2">
        <a :href="googleCalendarUrl" target="_blank" class="mt-4 sm:mt-0 w-full sm:w-52" @click="subscribeCalendarOnClick('Calendar Subscription - Google')">
          <Button class="p-button-secondary w-52" icon="pi pi-google" iconPos="left" label="Google Calendar"></Button>
        </a>
      </div>
    </div>
    <div class="mb-3 flex flex-col sm:flex-row text-left justify-between border-b border-solid border-gray-200 p-2 pb-4">
      <div>
        <p class="text-sm font-semibold font-display text-gray-600">Subscribe on Outlook Calendar</p>
        <p class="text-sm text-gray-500">Add Virtual Hallway Calendar to your Outlook calendar. <TutorialVideoDialog video="outlook_subscription" linkLabel="Learn How" /></p>
      </div>
      <div class="p-grid p-ai-center pl-2">
        <a :href="outlookCalendarUrl" target="_blank" class="mt-4 sm:mt-0 w-full sm:w-52" @click="subscribeCalendarOnClick('Calendar Subscription - Outlook')">
          <Button class="p-button-secondary w-52" icon="pi pi-microsoft" iconPos="left" label="Outlook Calendar" v-tooltip="'Outlook calendar subscription may not work if you are not already signed into your Outlook account.'"> </Button>
        </a>
      </div>
    </div>
    <div class="mb-3 flex flex-col sm:flex-row text-left justify-between border-b border-solid border-gray-200 p-2 pb-4">
      <div>
        <p class="text-sm font-semibold font-display text-gray-600">Manually Subscribe</p>
        <p class="text-sm text-gray-500">Click the button to copy your calendar subscription link. <TutorialVideoDialog video="manual_subscription" linkLabel="Learn How" /></p>
      </div>
      <div class="p-grid p-ai-center pl-2">
        <div class="mt-4 sm:mt-0 w-full sm:w-52">
          <Button class="p-button-secondary w-52" icon="pi pi-copy" iconPos="left" label="Copy Link" @click="copyToClipboard" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Message from 'primevue/message';
import TutorialVideoDialog from '../misc/TutorialVideoDialog.vue';

export default {
  components: { Button, Message, TutorialVideoDialog },
  props: ['loggedInUser'],
  inject: ['mixpanel'],
  data() {
    return {
      user: this.loggedInUser,
      accessDeviceUrl: '',
      googleCalendarUrl: '',
      outlookCalendarUrl: '',
      manualUrl: '',
      isMobile: false,
    };
  },
  created() {
    let hostname = window.location.hostname;
    const prefixBaseUrl = hostname === 'localhost' ? 'http://' : 'https://';
    if (hostname === 'localhost') {
      hostname = 'localhost:8000';
    }

    const baseUrl = `${hostname}/api/ical/${this.loggedInUser.id}/${this.loggedInUser.profile.ical_url_hash}`;

    this.accessDeviceUrl = `webcal://${baseUrl}`;
    this.googleCalendarUrl = `https://calendar.google.com/calendar/r?pli=1&cid=${prefixBaseUrl}${baseUrl}`;
    this.outlookCalendarUrl = `https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addsubscription&url=${prefixBaseUrl}${baseUrl}`;
    this.manualUrl = `${prefixBaseUrl}${baseUrl}`;
  },
  methods: {
    copyToClipboard() {
      this.subscribeCalendarOnClick('Calendar Subscription - Manual Copy');
      navigator.clipboard.writeText(this.manualUrl);
      this.$toast.add({
        severity: 'success',
        summary: 'Link Copied',
      });
    },
    subscribeCalendarOnClick(message) {
      this.mixpanel.track(message);
    },
  },
};
</script>

<style></style>
