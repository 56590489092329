<template>
  <Dialog header="Request a Lecture" :breakpoints="{ '960px': '50vw', '640px': '90vw' }" style="width: 500px" v-model:visible="show" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" id="requestCmeTopicDiaglog">
    <div class="text-sm mb-2">Do you have an idea for a lecture? Let us know below and we will look into hosting it.</div>
    <form method="post" @submit.prevent="submitSpeakingInterest">
      <div class="w-full flex flex-row" v-if="!loggedInUser">
        <div class="mb-2 pt-2 w-full mr-2">
          <label class="auth-input">Name</label>
          <InputText v-model="requestCmeTopicData.name" class="auth-input" required />
        </div>
        <div class="mb-2 pt-2 w-full ml-2">
          <label class="auth-input">Email</label>
          <InputText type="email" v-model="requestCmeTopicData.email" class="auth-input" required />
        </div>
      </div>
      <div class="mb-2 pt-2">
        <label class="auth-input">What CME topic would you like to see?</label>
        <Textarea v-model="requestCmeTopicData.topic" class="auth-input" :autoResize="true" rows="5" cols="30" required maxLength="65535" />
      </div>
      <div class="flex justify-end pt-5">
        <Button label="Close" class="p-button-secondary p-button-outlined mr-4" @click="show = false" />
        <Button type="submit" name="submitButton"> Submit </Button>
      </div>
    </form>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: ['pastLectures'],
  data() {
    return {
      isLoading: false,
      show: false,
      requestCmeTopicData: {
        name: null,
        email: null,
        topic: null,
      },
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isLoggedIn']),
  },
  methods: {
    ...mapActions(['requestCmeTopic']),
    submitSpeakingInterest() {
      this.isLoading = true;
      if (this.loggedInUser && this.requestCmeTopicData.topic != null) {
        this.requestCmeTopicData.name = this.loggedInUser.first_name + ' ' + this.loggedInUser.last_name;
        this.requestCmeTopicData.email = this.loggedInUser.email;
      }
      this.requestCmeTopic(this.requestCmeTopicData)
        .then(() => {
          this.isLoading = false;
          this.show = false;
          this.requestCmeTopicData.topic = null;
          this.$toast.add({
            severity: 'success',
            summary: 'Topic Request Sent',
            detail: 'Thank you for your interest. We will reach out shortly.',
            life: 10000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast;
        });
    },
    openDialog() {
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    },
  },
};
</script>

<style></style>
