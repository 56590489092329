<template>
  <div class="h-screen"></div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';

export default {
  inject: ['mixpanel'],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {},
  components: {
    Loading,
  },
  created() {
    this.isLoading = true;
    let province = 'Ontario';
    if (this.$route.path == '/cep-ns') {
      province = 'Nova Scotia';
    }
    this.demoLogin({ province })
      .then(() => {
        this.mixpanel.disable();
        this.isLoading = false;
        this.$router.push({ path: '/makereferral' });
      })
      .catch(() => {
        this.isLoading = true;
        this.$router.push({ path: `/login` });
        this.showGenericErrorToast();
      });
  },
  methods: {
    ...mapActions(['demoLogin']),
  },
};
</script>

<style scoped></style>
