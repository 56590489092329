<template>
  <div v-if="!isLoading && !isOrgUnitHead(loggedInUser)" :class="{ 'pt-8': isNativePlatform() }">
    <RemainingConsults v-if="actingUser !== null" />
  </div>

  <div v-if="loggedInUser !== null && isLoading" class="main-inner-wrap vld-parent p-4">
    <Loading z-index="99" v-model:active="isLoading" loader="spinner" color="#ef5164" :can-cancel="false" :is-full-page="false" />
  </div>

  <div v-if="loggedInUser !== null && !isLoading" class="main-inner-wrap">
    <OnDemandConsultCards v-if="isSpecialist(loggedInUser)" :referrals="upcomingOnDemandConsults" />
    <div :class="['w-full', { calendarGrid: false }]">
      <div class="flex-grow-1 w-full">
        <ReferralCalendar />
      </div>
    </div>

    <div v-if="isMobile" class="w-full mt-4">
      <div class="grid grid-cols-2 gap-4 w-full mb-5">
        <Button @click="consultCardPanelIndex = 0" label="Upcoming Consults" rounded class="p-button-xs p-button-secondary" :outlined="consultCardPanelIndex !== 0" />
        <Button @click="consultCardPanelIndex = 1" label="Recent Consults" rounded class="p-button-xs p-button-secondary" :outlined="consultCardPanelIndex !== 1" />
      </div>
      <TabView id="consultCardPanel" ref="tabview1" v-model:activeIndex="consultCardPanelIndex" class="w-full">
        <TabPanel header="Upcoming" headerStyle="font-size:0.7rem;">
          <div class="mt-2">
            <DashboardConsultCards :referrals="recentConsults" />
          </div>
        </TabPanel>
        <TabPanel header="Recent" headerStyle="font-size:0.7rem;">
          <div class="mt-2">
            <DashboardConsultCards :referrals="upComingConsults" />
          </div>
        </TabPanel>
      </TabView>
    </div>

    <!-- This section needs to remain for OrgUnitHeads for now, since the feed sidebar acts inconsistently for them -->
    <div v-if="isOrgUnitHead(loggedInUser)" class="consult-card-section">
      <div class="animated-delay-2 w-full">
        <h4 class="consult-col-heading">Recent Consults</h4>
        <DashboardConsultCards :referrals="recentConsults" />
      </div>

      <div :class="['animated fade-in-up animated-delay-3 relative w-full']">
        <h4 :class="['consult-col-heading mx-auto font-semibold']">Upcoming Consults</h4>
        <DashboardConsultCards :referrals="upComingConsults" />
      </div>
    </div>
    <template v-if="tutorialStatus === 'specialist-verification'">
      <div v-if="tutorialFit()" class="book-a-consult-new-feature-tool-tip py-4 px-4 text-left text-white">
        <h1 class="text-lg font-bold uppercase">Verify Your Account!</h1>
        <p class="clear-left mt-1 text-xs font-bold">Lets get your account verified to start accepting consults!</p>
        <div class="mt-2 ml-auto mr-2 flex flex-row justify-end">
          <button class="book-a-consult-new-feature-tool-tip-btn text-xs font-bold text-white" @click="setTutorialStatus('skip')" id="bookConsultTutorialBtn">CLOSE</button>
        </div>
      </div>
    </template>
  </div>
  <AccountSetupProgress v-if="isSpecialist(loggedInUser)" />

  <modal ref="specialistTutorialModal">
    <template v-slot:header></template>
    <template v-slot:body>
      <div class="flex w-full max-w-lg justify-center rounded-b-xl px-8 py-8">
        <div class="flex w-full max-w-lg flex-col">
          <div class="w-full mb-2">
            <h1 class="text-xl font-bold">Welcome to Virtual Hallway! &#127881;</h1>
          </div>
          <div class="text-md w-full max-w-lg px-4 pt-2 text-gray-600">
            <div class="text-left text-sm px-2">
              We’re thrilled you’re here!
              <br />
              Welcome to your local community of clinicians.
              <br />
              <br />
              Let’s get started by setting your availability for phone consults.
            </div>
            <div class="flex w-full flex-col py-4 pb-4 text-center md:flex-row">
              <div class="w-full px-2">
                <Button class="get-started-button dark-mint-button-background w-full" @click="startSpecialistTutorial" id="specialistTutorialGetStartedBtn" style="display: block"> Get Started </Button>
              </div>
            </div>
          </div>
          <button class="font-bold text-gray-400" @click="skipTutorial" id="notReadyToConsultBtn">Skip Tutorial</button>
        </div>
      </div>
    </template>
  </modal>
  <TimezoneModal :openModal="openTimezoneModal" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ReferralCalendar from '../../components/misc/ReferralCalendar.vue';
import DashboardConsultCards from '../../components/referral/DashboardConsultCards.vue';
import RemainingConsults from '@/components/gp/RemainingConsults.vue';
import Loading from 'vue-loading-overlay';
import Modal from '@/components/misc/Modal.vue';
import Button from 'primevue/button';
import AccountSetupProgress from '@/components/misc/AccountSetupProgress.vue';
import TimezoneModal from '@/components//misc/TimezoneModal.vue';
import moment from 'moment';
import PromptToAddAvailability from '../../components/specialist/PromptToAddAvailability.vue';
import OnDemandConsultCards from '../../components/referral/OnDemandConsultCards.vue';

export default {
  components: { ReferralCalendar, DashboardConsultCards, Loading, RemainingConsults, Button, Modal, AccountSetupProgress, TimezoneModal, PromptToAddAvailability },
  data() {
    return {
      consultCardPanelIndex: 0,
      isLoading: false,
      isMobile: false,
      openTimezoneModal: false,
      showPromptToAddAvailabilityModal: false,
      selectOptions: ['Upcoming', 'Recent'],
    };
  },
  computed: {
    ...mapGetters(['isAvailable', 'loggedInUser', 'referrals', 'isGP', 'isSpecialist', 'isOrgUnitHead', 'actingUser', 'orgUnitUsers', 'tutorialStatus', 'allReferrals', 'schedules', 'getUserActionByName', 'upcomingLectures']),
    upComingConsults() {
      return this.allReferrals
        .filter((referral) => {
          return (referral.interaction_type.name === 'phone' && (referral.status == 'Upcoming' || referral.status == 'Pending')) || (referral.interaction_type.name === 'econsult' && (referral.status == 'In Review' || referral.status == 'Pending'));
        })
        .sort((a, b) => a.start_datetime - b.start_datetime);
    },
    recentConsults() {
      return this.allReferrals.filter((referral) => {
        return referral.status == 'Cancelled' || referral.status == 'Complete' || referral.status == 'Accepted' || referral.status == 'Declined' || referral.status == 'Faxed';
      });
    },
    upcomingOnDemandConsults() {
      return this.allReferrals
        .filter((referral) => {
          return referral.interaction_type.name === 'phone' && referral.call_asap == true && referral.status == 'Upcoming';
        })
        .sort((a, b) => a.start_datetime - b.start_datetime);
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  },
  methods: {
    ...mapActions(['checkCurrentUsersAvailability', 'getRecentReferralsOfUser', 'getOrgUnitUsers', 'getUpcomingScheduleOfUserForSpecialist', 'getReferralByOrgUnitIdAndUserId', 'getUpcomingLectures']),
    ...mapMutations(['setActingUser', 'setTutorialStatus', 'updateLoggedInUser']),
    onResize() {
      this.isMobile = window.innerWidth < 1024;
    },
    startSpecialistTutorial() {
      this.setTutorialStatus('click-availability');
      this.$refs.specialistTutorialModal.closeModal();
    },
    skipTutorial() {
      if (this.tutorialStatus === 'get-started') {
        this.updateUserTutorialByName(this.loggedInUser, 'set_availability', true, 'Skipped');
        this.$refs.specialistTutorialModal.closeModal();
      }
      this.setTutorialStatus('');
    },
    showNoAvailabilityModal() {
      this.showPromptToAddAvailabilityModal = true;
    },
    closeNoAvailabilityModal() {
      this.$refs.noAvailability.closeModal();
    },
    goToAvailability() {
      this.$router.push({ path: `/schedules` });
    },
  },
  created() {
    this.isLoading = true;
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.isLoading = true;
      this.getOrgUnitUsers(this.loggedInUser.org_units[0].id)
        .then(() => {
          if (this.orgUnitUsers.length > 0) {
            this.setActingUser(this.orgUnitUsers[0]);
            this.getReferralByOrgUnitIdAndUserId({ orgUnitId: this.loggedInUser.org_units[0].id, userId: this.actingUser.id })
              .then(() => {
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
                this.showGenericErrorToast();
              });
          } else {
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    } else if (this.isBookingHallwayAdmin(this.loggedInUser)) {
      this.$router.push('/manageusers');
    } else {
      this.setActingUser(this.loggedInUser);
      const promises = [];
      promises.push(
        this.getRecentReferralsOfUser({
          user_id: this.actingUser.id,
          // required_relations: ['referral_form:id,referral_id,patient_first_name,patient_last_name,is_signed_off', 'referee:id,phone_number', 'interaction_type', 'specialty'],
          required_relations: ['referral_form:id,referral_id,patient_first_name,patient_last_name,is_signed_off', 'interaction_type', 'specialty'],
        })
      );
      promises.push(this.getUpcomingLectures());
      // const promise1 = this.getRecentReferralsOfUser({
      //   user_id: this.actingUser.id,
      //   required_relations: ['referral_form', 'interaction_type', 'specialty'],
      // });
      if (this.isSpecialist(this.actingUser)) {
        promises.push(this.checkCurrentUsersAvailability(this.actingUser.id));
      }
      // const promise2 = this.checkCurrentUsersAvailability(this.actingUser.id);
      Promise.all(promises)
        .then(() => {
          if (this.isSpecialist(this.loggedInUser)) {
            const now = moment();
            const nowDefaultTimezone = now.toDate().toLocaleString('en-US', { timeZone: this.loggedInUser.default_timezone });
            let userTutorial = this.getUserActionByName(this.loggedInUser, 'set_availability');

            if (this.getCurrentTimezoneLocalString() !== nowDefaultTimezone && !this.loggedInUser.hasViewDefaultTimezoneModal) {
              this.updateLoggedInUser({ ...this.loggedInUser, hasViewDefaultTimezoneModal: true });
              this.openTimezoneModal = true;
            } else if (userTutorial && userTutorial.status == 'Active' && this.tutorialFit()) {
              this.setTutorialStatus('get-started');
              this.$refs.specialistTutorialModal.openModal();
              this.updateLoggedInUser({ ...this.loggedInUser, first_login: false });
              this.setActingUser(this.loggedInUser);
            } else if (this.loggedInUser.status === 'Onboarding' && this.loggedInUser.onboarding_form.status === 'Editing') {
              this.setTutorialStatus('specialist-verification');
            } else if (this.isAvailable == false && this.loggedInUser.specialist_preference.call_asap_optin == false) {
              this.showNoAvailabilityModal();
            }
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    }
  },
  unmounted() {
    if (this.tutorialStatus === 'get-started' || this.tutorialStatus === 'specialist-verification') {
      this.setTutorialStatus('');
    }
  },
};
</script>

<style>
.calendarGrid {
  justify-items: stretch;
  align-items: stretch;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: grid;
}

.calendarGrid > :nth-child(1) {
  grid-column: span 3;
}

@media only screen and (max-width: 1250px) {
  .calendarGrid {
    display: flex;
    flex-direction: column-reverse;
  }
}

.book-consult-sidebar {
  margin-top: 1rem;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex-grow: 1;
}

.no-referrals {
  border: 2px solid var(--vh-auth-input-border);
  color: #859dc4;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
}

.consult-card-section {
  display: grid;
  justify-items: center;
  align-items: stretch;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
}

.consult-col-heading {
  color: #394353;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}

@media (max-width: 670px) {
  .consult-card-section {
    display: flex;
    flex-direction: column-reverse;
  }
  .consult-col-heading {
    margin-left: 15px;
    text-align: left;
  }
}

.book-a-consult-new-feature-tool-tip {
  position: fixed;
  width: 300px;
  background-color: var(--vh-new-feature-blue);
  top: 35px;
  left: 210px;
  border-radius: 6px;
  z-index: 1000;
}

.book-a-consult-new-feature-tool-tip::after {
  transform: rotate(90deg);
  content: '';
  position: absolute;
  top: 50%;
  left: -2%;
  margin-left: -12px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.book-a-consult-new-feature-tool-tip-btn {
  background-color: #005d9b;
  @apply h-6 w-14 rounded;
}

.dark-mint-show-referral-background {
  background-color: var(--vh-new-feature-blue);
}

.modal-footer {
  border-radius: 6px;
}

.dark-mint-show-referral-button-background.p-button.p-component {
  background-color: #10699e;
  border-color: #10699e;
}

.dark-mint-show-referral-button-background.p-button.p-component:hover {
  background-color: #0a7abd;
  border-color: #0a7abd;
}

.dark-mint-show-referral-modal-background.modal__header {
  background-color: var(--vh-new-feature-blue) !important;
}

.dark-mint-show-referral-header .modal__header {
  background-color: var(--vh-new-feature-blue);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.consult-card-tool-tip {
  position: absolute;
  top: -88px;
  left: -210px;
  border-radius: 6px;
  width: 200px;
  background-color: #fff;
  color: black;
  text-align: center;
  z-index: 100;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.consult-card-tool-tip::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.consult-card-tool-tip-btn {
  background-color: #005d9b;
  @apply h-6 w-14 rounded;
}

#consultCardPanel > .p-tabview-nav-container {
  display: none !important;
}
#consultCardPanel > .p-tabview-panels {
  background: none !important;
}
</style>
