<template>
  <div class="flex w-full justify-center">
    <router-link class="sidebar-router-link p-ripple group" v-ripple to="/schedules" @click="schedulesOnClick(event)">
      <div v-if="isOrgUnitHead(loggedInUser)" class="sidebar-router-label">Availability</div>
      <div v-else class="sidebar-router-label">My Availability</div>
      <i class="pi pi-calendar-times icon-grey group-hover:text-primary"></i>
    </router-link>
  </div>
  <div v-if="smartPathAccess(loggedInUser) || orgUnitUsers.find((user) => user.specialist_preference?.smart_path_optin == true)">
    <router-link class="sidebar-router-link p-ripple group" v-ripple to="/smartpaths" @click="smartPathOnClick(event)">
      <div class="sidebar-router-label">SmartPath</div>
      <i class="pi pi-share-alt icon-grey group-hover:text-primary"></i>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Ripple from 'primevue/ripple';

export default {
  inject: ['mixpanel'],
  directives: {
    ripple: Ripple,
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isOnboarding', 'isOrgUnitHead', 'orgUnitUsers']),
  },
  methods: {
    schedulesOnClick(event) {
      this.$emit('menu-toggle', event);
      this.mixpanel.track('Sidebar - Clicked My Availability');
    },
    smartPathOnClick(event) {
      this.$emit('menu-toggle', event);
      this.mixpanel.track('Sidebar - Clicked SmartPath');
    },
    preferencesOnClick(event) {
      this.$emit('menu-toggle', event);
      this.mixpanel.track('Sidebar - Clicked Specialist Preference');
    },
  },
};
</script>

<style scoped>
.overflow-hidden {
  overflow: hidden;
}

i {
  font-size: 1.2rem !important;
}
</style>
