<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="animated fade-in-up rounded-2xl">
        <DataTable editMode="cell" :value="referrals" class="editable-cells-table" :rows="25" dataKey="id" :rowHover="true" v-model:filters="filters1" filterDisplay="menu" :filters="filters1" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10, 25, 50, 100]" v-model:selection="selectedReferrals">
          <template #empty> No Consults found. </template>
          <template #loading> Loading Consult data. Please wait. </template>
          <Column selectionMode="multiple" style="width: 2rem" v-if="selectionView"></Column>

          <Column field="specialty.name" header="Specialty"> </Column>

          <Column v-if="isOrgUnitHead(loggedInUser)" field="referee.full_name" header="Referring Practitioner" style="min-width: 1rem">
            <template #body="{ data }">
              <span class="p-column-title">Full Name</span>
              {{ data.referee_full_name }}
            </template>
          </Column>

          <Column v-if="isOrgUnitHead(loggedInUser)" field="referral_person.full_name" header="Specialist" style="min-width: 1rem">
            <template #body="{ data }">
              <span class="p-column-title">Full Name</span>
              {{ data.referral_person_full_name }}
            </template>
          </Column>

          <Column v-if="!isOrgUnitHead(loggedInUser)" field="#" header="Consult With" style="min-width: 1rem">
            <template #body="{ data }">
              <span class="p-column-title">Full Name</span>
              <template v-if="data.referral_person_id === loggedInUser.id">
                {{ data.referee_full_name }}
              </template>
              <template v-else>
                {{ data.referral_person_full_name }}
              </template>
            </template>
          </Column>

          <Column field="start_datetime" dataType="date" header="Consult Date" style="max-width: 8rem" :sortable="true">
            <template #filter="{ filterModel }">
              <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
            </template>
            <template #body="{ data }">
              <span class="p-column-title">Date/Time</span>
              {{ formatDateTime(data.start_datetime) }} {{ getUserTimeZoneAbbr() }}
            </template>
          </Column>
          <Column field="id" header="View" style="width: 1rem">
            <template #body="{ data }">
              <Button icon="pi pi-eye" class="p-button-rounded p-button-outlined" @click="peekReferral(data)" :id="data.className + '-referral-view-button'" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <PeekCompleteReferralDialog ref="peekCompleteReferralDialog" />
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import { FilterOperator } from 'primevue/api';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import { mapActions, mapGetters } from 'vuex';
import PeekCompleteReferralDialog from '@/components/referral/PeekCompleteReferralDialog.vue';

export default {
  props: ['referrals', 'selectionView'],
  emits: ['attachPreviousConsult'],
  data() {
    return {
      filters1: null,
      selectedReferrals: [],
    };
  },
  components: {
    DataTable,
    Dropdown,
    Column,
    Button,
    InputText,
    Calendar,
    PeekCompleteReferralDialog,
  },
  computed: {
    ...mapGetters(['isOrgUnitHead', 'loggedInUser']),
  },
  methods: {
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        start_datetime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
    peekReferral(referral) {
      this.$refs.peekCompleteReferralDialog.openDialog(referral);
    },
  },
  created() {
    this.initFilters1();
  },
  watch: {
    selectedReferrals() {
      this.$emit('attachPreviousConsult', this.selectedReferrals);
    },
  },
};
</script>

<style></style>
