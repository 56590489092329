<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card animated fade-in-up rounded-2xl shadow">
        <DataTable editMode="cell" @cell-edit-complete="onCellEditComplete" :value="referralResponseFormRelatedFiles" :paginator="true" class="p-datatable-gridlines editable-cells-table referral-list-table" :rows="10" dataKey="id" :rowHover="true" v-model:filters="filters1" filterDisplay="menu" :filters="filters1" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10, 25, 50, 100]">
          <template #header>
            <div class="p-d-flex p-jc-between p-flex-column p-flex-sm-row">
              <h1 class="fc-toolbar-title mt-2 mb-2 md:mb-0">Resources</h1>
              <div>
                <IconField iconPosition="left">
                  <InputIcon class="pi pi-search"> </InputIcon>
                  <InputText v-model="filters1['global'].value" placeholder="Search" class="w-full" maxLength="255" />
                </IconField>
              </div>
            </div>
          </template>
          <template #empty> No Resources Found. </template>
          <template #loading>
            <ProgressSpinner />
          </template>
          <Column field="original_file_name" header="File Name" :sortable="true"></Column>
          <Column field="uploader.first_name" header="Shared By" v-if="!isSpecialist(loggedInUser)" :sortable="true">
            <template #body="{ data }"> {{ data.uploader.first_name }} {{ data.uploader.last_name }} </template>
          </Column>
          <Column field="referral.referee.first_name" header="Shared With" v-if="!isGP(loggedInUser)" :sortable="true">
            <template #body="{ data }"> {{ data.referral.referee.first_name }} {{ data.referral.referee.last_name }} </template>
          </Column>
          <Column field="referral.referral_form.patient_first_name" header="Related Referral Patient" :sortable="true">
            <template #body="{ data }"> {{ data.referral.referral_form.patient_first_name }} {{ data.referral.referral_form.patient_last_name }} </template>
          </Column>
          <Column field="referral.specialty.name" header="Related Specialty" :sortable="true"> </Column>
          <Column field="id" header="" style="width: 1rem">
            <template #body="{ data, index }">
              <Button icon="pi pi-download" class="p-button-text p-button-rounded" @click="clickDownloadResponseFormRelatedFile(data.id)" v-if="canDownloadResponseFormRelatedFile(loggedInUser, data.referral)" :id="'downloadResponseFormRelatedFileButton-' + index" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import ProgressSpinner from 'primevue/progressspinner';
import { FilterMatchMode } from 'primevue/api';
import { FilterOperator } from 'primevue/api';
import { mapGetters, mapActions } from 'vuex';
import Menu from 'primevue/menu';
import ShareReferralWithOrgUnit from '@/components/org_unit/ShareReferralWithOrgUnit.vue';

export default {
  data() {
    return {
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        start_datetime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        'interaction_type.name': { value: null, matchMode: FilterMatchMode.EQUALS },
        'specialty.practice_province_id': { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    };
  },
  created() {},
  props: ['referralResponseFormRelatedFiles'],
  components: {
    DataTable,
    Dropdown,
    Column,
    Button,
    InputText,
    Calendar,
    Menu,
    ShareReferralWithOrgUnit,
  },
  methods: {
    ...mapActions(['downloadResponseFormRelatedFile']),
    clickDownloadResponseFormRelatedFile(id) {
      this.downloadResponseFormRelatedFile({
        id: id,
      })
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'File Downloaded',
            detail: 'The file has been successfully downloaded to your computer, please check your downloads folder.',
            life: 5000,
          });
        })
        .catch(() => this.showGenericErrorToast());
    },
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'isSpecialist', 'isSpecialist', 'isOrgUnitHead', 'actingUser', 'canDownloadResponseFormRelatedFile']),
  },
};
</script>

<style lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

.referral-list-table.p-datatable .p-column-filter {
  display: block;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.status-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-InReview {
    background: #946da6;
    color: #6b039c;
  }

  &.status-Complete {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Accepted {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Faxed {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Upcoming {
    background: #b3e5fc;
    color: #23547b;
  }

  &.status-Pending {
    background: #f8da87;
    color: #81620c;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--surface-d);

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: 0.5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #c63737;
}

.p-button.p-button-info {
  background: var(--vh-blue) !important;
}

.cal-leg {
  width: 15px;
  height: 15px;
  background-color: var(--vh-blue);
  margin-right: 10px;
  border-radius: 50px;
}

.p-editable-column > .p-inputtext {
  font-size: 0.7rem;
}
</style>
