<template>
  <template v-if="referrals.length < 1"></template>
  <div v-else class="w-full mt-4">
    <div class="text-left font-semibold text-gray-700 font-display flex items-center mb-3 ml-1"><i class="pi pi-bolt mr-1"></i> OnDemand Consults</div>

    <Accordion expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up" class="grid grid-cols-1 md:grid-cols-3 w-full gap-4">
      <AccordionTab v-for="(referral, index) in referrals.slice(0, 10)" :key="referral.id" @click="selectReferral(referral.id)">
        <template #header>
          <span class="flex align-items-center gap-2 w-full">
            <div class="text-left">
              <h1 class="text-gray-600 font-bold text-sm">{{ referral.referee_full_name }}</h1>
              <div class="text-xs font-semibold text-primary">Call with{{ formatDaysUntilTarget(referral.start_datetime) }}</div>
            </div>
          </span>
        </template>

        <div class="w-full bg-white text-left" :style="'--index: ' + index">
          <div class="flex w-full flex-col justify-between pt-1">
            <div class="my-1 flex flex-row" v-if="referral.referral_form.patient_first_name">
              <div class="align-center mr-2 flex items-center justify-center rounded border-1" style="width: 2rem; height: 2rem">
                <i class="pi pi-user text-left text-xl text-gray-400"></i>
              </div>
              <div class="mb-2 flex flex-col items-start py-0">
                <div class="text-xs font-normal text-gray-400">Patient Name</div>
                <div class="text-xs font-semibold text-gray-600">{{ referral.referral_form.patient_first_name }} {{ referral.referral_form.patient_last_name }}</div>
              </div>
            </div>
            <template v-if="referral.interaction_type.name === 'phone'">
              <div class="flex flex-row">
                <div class="align-center mr-2 flex items-center justify-center rounded border-1" style="width: 2rem; height: 2rem">
                  <i class="pi pi-calendar text-left text-xl text-gray-400"></i>
                </div>
                <div class="flex flex-col items-start py-0">
                  <div class="text-xs font-normal text-gray-400">Call Before</div>
                  <div class="text-xs font-semibold text-gray-600">{{ formatReadableDateTime(referral.start_datetime) }}</div>
                </div>
              </div>
            </template>

            <div class="mt-6 flex flex-row gap-4">
              <Button label="Cancel" @click="cancelReferral(referral.id)" v-if="isSpecialist(loggedInUser)" class="w-2/3 p-button-secondary p-button-outlined p-button-xs items-center" icon="pi pi-ban" />
              <Button label="View Consult" @click="selectReferral(referral.id)" v-if="isSpecialist(loggedInUser)" class="w-full p-button-secondary p-button-xs items-center" />
            </div>
            <!-- <a v-if="referral.status == 'In Review' || referral.status == 'Upcoming' || (referral.interaction_type.name === 'phone' && referral.call_asap == true && referral.status != 'Complete' && referral.referee?.phone_number != null)" v-on:click.stop :href="`tel:${referral.referee?.phone_number}`" class="flex">
              <Button v-if="isSpecialist(loggedInUser)" class="w-full p-button-xs items-center" icon="pi pi-phone" />
            </a> -->
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>
<script>
import Button from 'primevue/button';
import { mapGetters } from 'vuex';

export default {
  props: ['referrals', 'label'],
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'actingUser', 'isSpecialist']),
  },
  components: {
    Button,
  },
  methods: {
    selectReferral(id) {
      this.$router.push({ path: '/referral/' + id });
    },
    cancelReferral(id) {
      this.$router.push({ path: '/referral/' + id, query: { cancel: 'yes' } });
    },
    rebookReferral(id) {
      this.$router.push({ path: '/referral/' + id, query: { rebook: 'yes' } });
    },
  },
};
</script>

<style scoped>
:deep(.p-accordion .p-accordion-header .p-accordion-header-link:hover) {
  background: #ffffff !important;
  border: 1px solid black !important;
}
:deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
  padding: 1.25rem;
  border: 1px solid #c9c9c9;
  color: #6b7280;
  background: #f9fafb;
  font-weight: 700;
  border-radius: 6px;
  transition: box-shadow 0.2s;
  box-shadow: 0px 3px 3px 0px rgb(135 135 135 / 10%);
}
:deep(.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link) {
  border-top: 1px solid #c9c9c9;
}
:deep(.p-accordion .p-accordion-tab:not(:first-child):hover .p-accordion-header .p-accordion-header-link) {
  border-top: 1px solid #c9c9c9;
}
:deep(.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon) {
  margin-right: 0.5rem;
}
:deep(.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
:deep(.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 3px 3px 0px rgb(135 135 135 / 10%);
}
:deep(.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link) {
  background: #f3f4f6;
  border-color: #c9c9c9;
  color: #374151;
}
:deep(.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link) {
  background: #f9fafb;
  border-color: #c9c9c9;
  color: #374151;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
:deep(.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link) {
  border-color: #c9c9c9;
  background: #f3f4f6;
  color: #374151;
}
:deep(.p-accordion .p-accordion-content) {
  margin-top: -4px;
  padding: 1.25rem;
  padding-top: 0.85rem;
  border: 1px solid #c9c9c9;
  background: #ffffff;
  color: #4b5563;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
:deep(.p-accordion .p-accordion-tab) {
  /* box-shadow: 0px 3px 3px 0px rgb(135 135 135 / 10%); */
  /* margin-bottom: 20px; */
}
:deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link) {
  border-radius: 6px !important;
  display: flex;
  flex-direction: row-reverse;
}
</style>
