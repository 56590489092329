<template>
  <div class="main-inner-wrap">
    <div class="animated fade-in-up my-4 w-full rounded-lg bg-white p-6 shadow max-w-2xl" v-if="!isLoading">
      <div class="w-full">
        <div class="mt-2 mb-4 flex flex-row items-center justify-center text-left font-semibold text-lg text-gray-600">
          <h1 class="mx-2 text-lg font-bold" v-if="isDemoAccount(loggedInUser)">Demo Consult Form</h1>
          <h1 class="mx-2 text-lg font-bold" v-else>Example Consult Form</h1>
          <div class="h-1 flex-grow bg-gray-500"></div>
        </div>
        <Message severity="info" :closable="false" class="text-left" v-if="!isDemoAccount(loggedInUser)">Below is an example consult request form for {{ specialty.name }} {{ specialty.interaction_type.display_name }}s. If you would like any additional fields added, please enter your suggestions below.</Message>
        <form method="post" class="grid grid-cols-2 gap-1 gap-x-2">
          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input" for="patient_first_name"
              >Patient First Name
              <span class="error">&nbsp;*</span>
            </label>
            <InputText type="text" disabled class="auth-input" required maxLength="100" />
          </div>
          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input" for="patient_last_name">
              Patient Last Name
              <span class="error">&nbsp;*</span>
            </label>
            <InputText type="text" disabled class="auth-input" required maxLength="100" id="patient_last_name" />
          </div>

          <div v-if="!hasProvinceOfPractice(loggedInUser, 'ON')">
            <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
              <label class="auth-input" for="health_card_number"
                >Patient Health Card Number
                <span class="error">&nbsp;*</span>
              </label>
              <InputText type="text" disabled class="auth-input" required maxLength="255" id="health_card_number" />
            </div>
          </div>
          <div v-else>
            <div class="col-auto mb-2 md:flex md:justify-between">
              <div class="mr-2 w-full md:w-7/12">
                <label class="auth-input" for="health_card_number"
                  >Health Card Number
                  <span class="error">&nbsp;*</span>
                </label>
                <InputText type="text" disabled class="auth-input" required maxLength="255" id="health_card_number" />
              </div>
              <div class="w-full md:w-5/12">
                <label class="auth-input" for="health_card_version_number"
                  >Version Number
                  <span class="error">&nbsp;*</span>
                </label>
                <InputText type="text" disabled class="auth-input" required maxLength="255" id="health_card_version_number" />
              </div>
            </div>
          </div>
          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label :class="['auth-input']" for="health_card_province">
              Health Card/Policy Type
              <span class="error">&nbsp;*</span>
            </label>
            <Dropdown :class="['w-full text-left']" disabled id="health_card_province" class="auth-input w-full text-xs font-normal text-gray-400" />
          </div>
          <div class="col-start-1 col-end-3 sm:col-auto">
            <label class="auth-input" for="patient_dob"
              >Patient Date of Birth
              <span class="error">&nbsp;*</span>
            </label>
            <InputText class="auth-input" disabled id="patient_dob" placeholder="YYYY/MM/DD" maxLength="10" />
          </div>
          <div class="select-button-container col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input" for="patient_sex"
              >Patient Sex
              <span class="error">&nbsp;*</span>
            </label>
            <SelectButton disabled :options="['Male', 'Female']" id="patient_sex" class="auth-input w-full" />
          </div>
          <template v-if="specialty.interaction_type.name === 'in_person'">
            <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
              <label class="auth-input" for="street">Patient Street <span class="error">&nbsp;*</span></label>
              <InputText disabled class="auth-input" id="street" maxLength="255" />
            </div>
            <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
              <label class="auth-input" for="city">Patient City <span class="error">&nbsp;*</span></label>
              <InputText disabled class="auth-input" id="city" maxLength="255" />
            </div>
            <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
              <label class="auth-input" for="postalCode">Patient Postal Code<span class="error">&nbsp;*</span></label>
              <InputText disabled class="auth-input" id="postalCode" maxLength="6" />
            </div>
            <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
              <label class="auth-input" for="patient_province">Patient Province<span class="error">&nbsp;*</span></label>
              <Dropdown class="auth-input" disabled id="patient_province" />
            </div>
            <div class="col-start-1 col-end-3 mb-2">
              <label class="auth-input" for="patientPhoneNumber">Patient Phone Number<span class="error">&nbsp;*</span></label>
              <InputText id="patientPhoneNumber" name="patientPhoneNumber" disabled :autoClear="false" class="auth-input" maxlength="16" />
            </div>
            <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
              <label class="auth-input" for="refereePhoneNumber">Referee Contact Number<span class="error">&nbsp;*</span></label>
              <InputText id="refereePhoneNumber" name="refereePhoneNumber" disabled :autoClear="false" class="auth-input" maxlength="16" />
            </div>
            <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
              <label class="auth-input" for="fax_number">Referee Fax Number<span class="error">&nbsp;*</span></label>
              <InputText id="refereeFaxNumber" name="refereeFaxNumber" disabled :autoClear="false" class="auth-input" maxlength="16" />
            </div>
          </template>

          <div class="col-start-1 col-end-3 mb-2">
            <label class="auth-input" for="referral_question"
              >Consult Question
              <span class="error">&nbsp;*</span>
            </label>
            <Textarea disabled class="auth-input" :autoResize="true" placeholder="Please describe the question you are looking to consult about" rows="5" cols="30" required maxLength="65535" id="referral_question" @keydown.tab.prevent="processTabs($event)"></Textarea>
          </div>

          <div v-for="(fieldData, fieldName) in newReferralForm" :key="fieldName" class="col-start-1 col-end-3 mb-2">
            <label v-if="fieldData.type != 'date'" class="auth-input">
              {{ fieldName }}
              <template v-if="isRequired(fieldName)">
                <span class="error">&nbsp;*</span>
              </template>
              <span v-if="fieldData.type == 'text' && this.newReferralForm[fieldName]['value']" v-tooltip.top="'The average length of this field is under 500 characters.'" class="ml-auto">{{ this.newReferralForm[fieldName]['value'].length }}/500</span>
            </label>
            <InputText type="text" v-if="fieldData.type == 'string'" disabled class="auth-input" maxLength="255" :placeholder="isRequired(fieldName) ? 'If none please write None or Not Available' : ''" />

            <Textarea v-if="fieldData.type == 'text'" disabled class="auth-input" :autoResize="true" rows="5" cols="30" maxLength="65535" :id="fieldName" @keydown.tab.prevent="processTabs($event)" :placeholder="isRequired(fieldName) ? 'If none please write None or Not Available' : ''"></Textarea>

            <div v-if="fieldData.type == 'date'" class="relative mb-4" :masks="masks">
              <InputText class="auth-input" disabled id="patient_dob" placeholder="YYYY/MM/DD" maxLength="10" />
            </div>

            <div v-if="fieldData.type == 'radio_button'" align="left">
              <span class="p-text-justify mr-4" v-for="(val, i) in JSON.parse(fieldData.meta_data)" :key="i">
                <RadioButton class="mr-2" disabled :value="val" :id="fieldName + val" />
                <label :for="fieldName + val">{{ val }}</label>
              </span>
            </div>

            <template v-if="fieldData.type == 'search_select'">
              <Multiselect disabled mode="tags" :searchable="true" :createTag="true" :options="JSON.parse(fieldData.meta_data).concat(fieldData.value)" />
            </template>

            <template v-if="fieldData.type == 'select'">
              <div class="mt-1 grid grid-cols-1 gap-x-1 text-sm md:grid-cols-2">
                <div class="p-field-checkbox" v-for="(val, i) in JSON.parse(fieldData.meta_data)" :key="i" align="left">
                  <Checkbox :value="val" :id="fieldName + val" disabled />
                  <label :for="fieldName + val" class="ml-2">{{ val }}</label>
                </div>
              </div>
            </template>

            <div class="border border-gray-300 pb-4" v-if="fieldData.type == 'file_uploads'">
              <FileUpload :maxFileSize="20000000" disabled accept="image/png, image/jpeg, image/jpg, image/heic, application/pdf" :uploadLabel="'Upload File'" :showUploadButton="false">
                <template #empty>
                  <p class="text-sm">Drag and drop files here to upload.</p>
                </template>
              </FileUpload>
            </div>
          </div>
          <template v-if="isDemoAccount(loggedInUser)">
            <Button label="Get Started" icon="pi pi-user-plus" @click="$router.push('/logout?redirect=/registration')" name="saveButton" class="p-button-secondary col-span-full md:col-span-1 mb-2" v-tooltip.top="'Click here to get started!'" id="signUpButton"></Button>
            <Button label="Send Invite" icon="pi pi-envelope" @click="$router.push({ path: '/inviteuser' })" name="saveButton" class="col-span-full md:col-span-1 mb-2" v-tooltip.top="`Click here to send an invite to a colleague, or as a reminder for yourself!`" id="inviteUserButton"></Button>
          </template>
        </form>
        <form method="post" @submit.prevent="requestAdditionalReferralFormFieldsOnClick" class="mt-2 w-full" v-if="!isDemoAccount(loggedInUser)">
          <Message severity="info" :closable="false" class="text-left">If the above consult request form suffices and does not require any additional fields, please click submit. If you would like to suggest additional fields, please type them below. We welcome your suggestions for modifications for text inputs, multiple choice fields, date inputs and file uploads.</Message>
          <div class="mb-2">
            <label class="auth-input" for="additional_fields"> Enter Additional Fields </label>
            <Textarea v-model="additional_fields" class="auth-input" :autoResize="true" rows="5" cols="30" maxLength="65535" id="additional_fields" @keydown.tab.prevent="processTabs($event)"></Textarea>
          </div>
          <div class="mt-3 flex justify-end">
            <Button @click="requestAdditionalReferralFormFieldsOnClick" class="flex w-28 justify-center" id="uploadPhotoIdSubmit" label="Submit" />
          </div>
        </form>
      </div>
    </div>
    <div class="relative flex w-full flex-row items-center justify-center" v-if="isDemoAccount(loggedInUser)">
      <div class="absolute top-0">
        <Button icon="pi pi-home" label="Back Home" class="p-button-outlined p-button-secondary" name="cancelBtn" @click="$router.push({ path: '/makereferral' })" />
      </div>
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
  <modal ref="readyToStartAcceptingReferralsModal" style="padding-top: 100px">
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="w-full">
        <div class="h-52 overflow-hidden rounded-t-xl object-contain">
          <img src="@/assets/complete.jpg" />
        </div>
      </div>
      <div class="flex w-full justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <h1 class="mb-4 text-xl font-bold md:text-2xl">You're all set to start accepting Referrals!</h1>
          <p class="mb-2 text-sm font-semibold text-gray-500">We'll update your referral form. If you have any questions about Virtual Hallway Referrals reach us at <a href="mailto:support@virtualhallway.ca" target="_self"> support@virtualhallway.ca</a>.</p>
          <div class="flex flex-col items-center justify-between py-4 pb-4 md:flex-row">
            <div class="mx-auto mb-0">
              <Button class="p-button-outlined w-60" label="Close" @click="$refs.readyToStartAcceptingReferralsModal.closeModal()" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import Loading from 'vue-loading-overlay';
import Multiselect from '@vueform/multiselect';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import FileUpload from 'primevue/fileupload';
import SelectButton from 'primevue/selectbutton';
import RadioButton from 'primevue/radiobutton';
import Message from 'primevue/message';
import Modal from '@/components/misc/Modal.vue';

export default {
  components: { Button, Loading, Textarea, InputText, Multiselect, Checkbox, RadioButton, FileUpload, SelectButton, Message, Modal, Dropdown },
  data() {
    return {
      isLoading: false,
      newReferralForm: {},
      additional_fields: '',
      specialty: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'hasProvinceOfPractice', 'referralFormFields', 'allSpecialties', 'allProvinces', 'tutorialStatus', 'getUserActionByName']),
  },
  methods: {
    ...mapActions(['getReferralFormFields', 'requestAdditionalReferralFormFields', 'getSpecialties', 'getAllProvinces', 'bookDemoAsReferralPerson']),
    ...mapMutations(['setTutorialStatus']),
    isRequired(fieldName) {
      if (this.referralFormFields) {
        if (this.referralFormFields.find((field) => field.field_name === fieldName)) {
          return this.referralFormFields.find((field) => field.field_name === fieldName).required;
        }
      }
      return false;
    },
    requestAdditionalReferralFormFieldsOnClick() {
      this.isLoading = true;
      this.requestAdditionalReferralFormFields({ ...this.additional_fields, ...this.specialty })
        .then(() => {
          this.additional_fields = '';
          this.isLoading = false;
          if (this.tutorialStatus === 'setupInPersonForm') {
            this.$refs.readyToStartAcceptingReferralsModal.openModal();
            this.setTutorialStatus('');
            this.updateUserTutorialByName(this.loggedInUser, 'review_in_person_form', true, 'Completed');
          } else {
            this.$toast.removeAllGroups();
            this.$toast.add({
              severity: 'success',
              summary: 'Ticket Submitted',
              detail: 'Our admins will take a look shortly.',
              life: 5000,
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    getSpecialtyReferralFormFields() {
      this.getReferralFormFields(this.specialty.id)
        .then(() => {
          this.isLoading = false;
          this.referralFormFields.forEach((field) => {
            field.type = field.field_type;
            this.newReferralForm[field.field_name] = field;
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    getSpecialty(data) {
      this.getSpecialties(data)
        .then(() => {
          this.specialty = this.allSpecialties[0];
          //Check if specialty exists if not call for Nova Scotia Internal Med
          if (this.specialty) {
            //Get the internal med referral form
            this.getSpecialtyReferralFormFields();
          }
          //If Province does not have internal med show NS internal med
          else {
            this.getSpecialty({ province_name: 'Nova Scotia', interaction_type_names: ['phone'], names: ['Internal Medicine'], for_referral: 0 });
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
  created() {
    this.isLoading = true;
    //Check if query for interaction_type
    let interaction_type = 'phone';
    if (this.$route.query && this.$route.query.interaction_type) {
      interaction_type = this.$route.query.interaction_type;
    }

    //Get specialty fields based on query
    if (this.$route.query && this.$route.query.specialty) {
      this.getSpecialty({ ids: [this.$route.query.specialty], for_referral: 0, interaction_type_names: [interaction_type], province_name: this.loggedInUser.practice_province.name, requesting_user_id: this.loggedInUser.id });
    }
    //User has other as specialty
    else if (!this.loggedInUser.specialties || this.isDemoAccount(this.loggedInUser) || this.loggedInUser.specialties.find((specialty) => specialty.name === 'Other')) {
      this.getSpecialty({ province_name: this.loggedInUser.practice_province.name, interaction_type_names: [interaction_type], names: ['Internal Medicine'], for_referral: 0, requesting_user_id: this.loggedInUser.id });
    }
    //User does not have other as a specialty
    else {
      //Get the specialty from the user where it has the same interaction type, if that does not exist get the first specialty.
      this.specialty = this.loggedInUser.specialties.find((specialty) => specialty.interaction_type.name === interaction_type) ? this.loggedInUser.specialties.find((specialty) => specialty.interaction_type.name === interaction_type) : this.loggedInUser.specialties;
      this.getSpecialtyReferralFormFields();
    }
  },
  unmounted() {
    if (this.tutorialStatus === 'setupInPersonForm') {
      this.setTutorialStatus('');
    }
  },
};
</script>

<style></style>
