import moment from 'moment';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/vue';

//// Mixins were implemented with the help from: https://stackoverflow.com/a/43196768/10052594
export const globalMixin = {
  methods: {
    formatDateTimeToSpecifiedFormat(datetime, format, convertToLocal = false) {
      if (convertToLocal) return moment.utc(datetime).local().format(format);
      else return moment(datetime).format(format);
    },
    formatDateTime(value) {
      if (!value) return '';
      return moment(value).format('MMM D, YYYY • h:mma');
    },
    formatDate(value) {
      if (!value) return '';
      return moment(value).format('MMM D, YYYY');
    },
    formatNotificationDate(value) {
      if (!value) return '';
      return moment(value).fromNow();
    },
    formatReadableDateTime(value) {
      if (!value) return '';
      return moment.utc(value).local().format('ddd MMMM D • h:mma ');
    },
    formatReadableDateTimeWithZone(value) {
      if (!value) return '';
      return moment.utc(value).local().format('ddd MMMM D • h:mma ') + moment.tz(moment.tz.guess()).zoneAbbr();
    },
    formatDaysUntilTarget(value) {
      return new moment().to(moment(value));
    },
    formatTime(value) {
      if (!value) return '';
      return moment(value).format('h:mma');
    },
    formatDateTimeToWeekday(value) {
      if (!value) return '';
      return moment(value).format('dddd');
    },
    formatDateYYYYMMDD(value) {
      if (!value) return '';
      return moment(value).format('YYYY-MM-DD');
    },
    formatDateTimeYYYYMMDDHiS(value) {
      if (!value) return '';
      return moment(value).format('YYYY-MM-DD H:mm A');
    },
    formatDateTimeIS(value) {
      if (!value) return '';
      return moment(value).format('mm:ss');
    },
    formatToYear(value) {
      if (!value) return '';
      return moment(value).format('YYYY');
    },
    formatToDateOfBirth(value) {
      if (!value) return '';
      return moment(value).format('YYYY/MM/DD');
    },
    formatAge(value) {
      if (!value) return null;
      return moment().diff(value, 'years');
    },
    validatePassword(password, passwordConfirmation) {
      let passwordResponse = [];
      let regex = /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/g;
      if (password < 10) {
        passwordResponse.push('Ten characters minimum.');
      }
      if (!/[a-z]/.test(password)) {
        passwordResponse.push('At least one lowercase letter.');
      }
      if (!/[A-Z]/.test(password)) {
        passwordResponse.push('At least one uppercase letter.');
      }
      if (!/[0-9]/.test(password)) {
        passwordResponse.push('At least one number.');
      }
      if (!regex.test(password)) {
        passwordResponse.push('At least one special character.');
      }
      if (password !== passwordConfirmation) {
        passwordResponse.push('Password and password confirmation do not match.');
      }
      return passwordResponse;
    },
    validatePhoneNumber(phoneNumber) {
      let errors = [];
      if (!/[0-9]/.test(phoneNumber) || phoneNumber.length != 10) {
        errors.push('Phone number must be a 10 digit number');
      }
      return errors;
    },
    formatCalendarDateTime(value) {
      if (!value) return '';
      return moment(value).format('YYYY MM DD h:mm a');
    },
    showGenericErrorToast() {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
        life: 10000,
      });
      throw new Error('ErrorToast');
    },
    getURL() {
      let hostname = window.location.hostname;
      if (hostname === 'localhost') {
        return 'http://localhost:8000/';
      } else {
        return 'https://' + hostname + '/';
      }
    },
    hasNotificationEnabled(user, notificationSetting, notificationType) {
      if (user && user.profile && user.profile.notification_setting && user.profile.notification_setting[notificationSetting]) {
        if (!Array.isArray(user.profile.notification_setting[notificationSetting])) {
          user.profile.notification_setting[notificationSetting] = JSON.parse(user.profile.notification_setting[notificationSetting]);
        }
        return user.profile.notification_setting[notificationSetting].includes(notificationType);
      }
      return false;
    },
    clearUnauthenticatedUser() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('loggedInUser');
      localStorage.removeItem('actingUser');
      localStorage.removeItem('onboardingForm');
      localStorage.removeItem('actingUserOnboardingForm');
      localStorage.removeItem('isAllowedMakeReferral');
      localStorage.clear();
      this.$store.commit('removeLoggedInUser', null, null);
      this.$router.push('/login?unauthorized=true');
    },
    tutorialFit() {
      if (window.innerWidth > 1024) {
        return true;
      }
      return false;
    },
    updateUserTutorialByName(user, tutorialName, showTutorial = true, status) {
      let userAction = this.$store.getters.getUserActionByName(user, tutorialName);
      if (userAction && userAction.status === 'Active') {
        userAction.status = status;
        this.$store.dispatch('updateUserAction', userAction);
        this.$store.commit('setShowTutorialRelaunchTooltip', showTutorial);
      }
    },
    getUserTimeZoneAbbr() {
      return new Date()
        .toLocaleDateString('en-US', {
          day: '2-digit',
          timeZoneName: 'short',
        })
        .slice(4);
    },
    getUserTimeZoneLong() {
      return new Date()
        .toLocaleDateString('en-US', {
          day: '2-digit',
          timeZoneName: 'long',
        })
        .slice(4);
    },
    validatePostalCode(postal_code) {
      if (postal_code) {
        const postalCodeRegex = /([A-Za-z][0-9]){3}/;
        postal_code = postal_code.replace(/\s+/g, '');
        return postal_code.length === 6 && postalCodeRegex.test(postal_code);
      }
      return false;
    },
    formatPhoneNumber(value) {
      if (value) {
        value = value.replace(/[^0-9]/g, '');
        const match = value.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
      }
      return '';
    },
    displayEconsult() {
      return false;
    },
    displayRole(role) {
      if (role === 'gp') {
        return 'Primary Care';
      } else if (role === 'specialist') {
        return 'Specialist';
      } else if (role === 'org_unit_head') {
        return 'Practice Group Administer';
      }
      return '';
    },
    displayNameByRole(user) {
      if (user.provider_type !== 'NP' && user.provider_type !== 'Pharmacist') {
        return `Dr ${user.first_name} ${user.last_name}`;
      }
      return `${user.first_name} ${user.last_name}`;
    },
    inviteAccess(loggedInUser) {
      const access = loggedInUser.org_units.find((org_unit) => org_unit.name === "Dr. Peter Kagoma's Referral Network");
      if (loggedInUser.role == 'org_unit_head' && access != null) {
        return true;
      }
      return false;
    },
    getCurrentTimezoneLocalString() {
      const now = moment();
      return now.toDate().toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    },
    isSmartPathAuthor(user, smartPath) {
      if (user && smartPath) {
        return user.id === smartPath.author.id;
      } else {
        return null;
      }
    },
    isDemoAccount(user) {
      return user?.demo_account;
    },
    isBookingHallwayAdmin(user) {
      return user?.role == 'hallway_admin';
    },
    isPartOfGeneralVhcHallway(user) {
      return user?.booking_hallways?.filter((hallway) => hallway.name == 'General VHC Hallway').length > 0;
    },
    isPartOfIndividualHallway(user) {
      return user?.booking_hallways?.filter((hallway) => hallway.name != 'General VHC Hallway').length > 0;
    },
    getReferralPersonTerm(actingUser, isFirstLetterCapital = false) {
      let term = 'consultant';
      if (actingUser.provider_type != 'Pharmacist') {
        term = 'specialist';
      }
      if (isFirstLetterCapital) {
        // https://stackoverflow.com/a/1026087/10052594 :')
        return term.charAt(0).toUpperCase() + term.slice(1);
      }
      return term;
    },
    smartPathAccess(user) {
      // org unit heads are checked in different places throughout the app for access
      // this is because the getter this.orgUnitUsers needs to be checked to see if any of their specialists have opted into SmartPath
      // this.orgUnitUsers gets loaded in when the org unit head logs in, so to avoid loading it or trying to access a getter in the mixins, we just check access elsewhere
      // if you need to find that, global search this code:
      // orgUnitUsers.find((user) => user.specialist_preference?.smart_path_optin == true)
      if (user.role == 'specialist') {
        if (user.status == 'Active' && user.specialist_preference.smart_path_optin == true) {
          return true;
        }
      } else if (user.role == 'gp') {
        if (user.practice_province?.name === 'Nova Scotia') {
          return true;
        }
      }
      return false;
    },
    healthCardProvinceOptionsRequireIdNumber() {
      return ['Canadian Armed Forces (CAF)', 'Royal Canadian Mounted Police (RCMP)', 'Immigration, Refugees and Citizenship Canada (IRCC)', 'Veterans Affairs Canada (VAC)', 'University Health Insurance Health Plan', 'Blue Cross'];
    },
    getAllMessagesFromError422(error) {
      let errors = [];
      for (const key in error.data.errors) {
        error.data.errors[key].forEach((element) => {
          errors.push(element);
        });
      }
      return errors;
    },
    isNativePlatform() {
      return Capacitor.isNativePlatform();
    },
    getMobileNotesTabAllowedUrlNames() {
      return ['AddEncounterNote', 'Notes', 'EditEncounterNote'];
    },
    getMobileRecentsTabAllowedUrlNames() {
      return ['RecentCallsHistory'];
    },
    phoneNumberWithoutCountryCode(phoneNumber) {
      if (!phoneNumber) {
        return false;
      } else if (phoneNumber.length < 10 || phoneNumber.length > 12) {
        return false;
      } else if (phoneNumber.length == 11 && phoneNumber[0] != '1') {
        return false;
      } else if (phoneNumber.length == 12 && phoneNumber[0] != '+') {
        return false;
      }
      phoneNumber = phoneNumber.slice(-10);
      if (!isNaN(phoneNumber)) {
        return phoneNumber;
      } else return false;
    },
    isIphoneBrowserButNotIosApp() {
      const userAgentString = navigator.userAgent;
      const parsedInfo = this.parseUserAgent(userAgentString);

      // Display parsed information
      // console.log('Browser:', parsedInfo.browser);
      // console.log('Version:', parsedInfo.version);
      // console.log('Operating System:', parsedInfo.os);
      // console.log('Device:', parsedInfo.device);

      if (parsedInfo.device == 'iPhone' && !this.isNativePlatform() && (parsedInfo.browser == 'Safari' || parsedInfo.browser == 'Chrome' || parsedInfo.browser == 'Edge' || parsedInfo.browser == 'Firefox')) {
        return true;
      }
    },
    parseUserAgent(userAgentString) {
      const parser = {
        browser: null,
        version: null,
        os: null,
        device: null,
      };

      // Regular expressions to match different parts of the user agent string
      const browserRegex = /(edge|chrome|safari|firefox|opera)\/([\d.]+)/i;
      const osRegex = /(windows|mac os x|android|linux|ios)/i;
      const deviceRegex = /(iphone|ipad|ipod|android)/i;

      // Extract browser information
      const browserMatch = userAgentString.match(browserRegex);
      if (browserMatch) {
        parser.browser = browserMatch[1];
        parser.version = browserMatch[2];
      }

      // Extract operating system information
      const osMatch = userAgentString.match(osRegex);
      if (osMatch) {
        parser.os = osMatch[1];
      }

      // Extract device information
      const deviceMatch = userAgentString.match(deviceRegex);
      if (deviceMatch) {
        parser.device = deviceMatch[1];
      }

      return parser;
    },
  },
};
