<template>
  {{ formatDateTimeIS(timer) }}
  <!-- This span is important to update the timer when countDown changes, without this
    span Vue does not reload the template. -->
  <span class="hidden">{{ countDown }}</span>
</template>

<script>
export default {
  props: ['countDownInSeconds'],
  emits: ['countdownEnded'],
  data() {
    return {
      timer: new Date(0, 0, 0, 0, 0, 0),
      countDown: this.countDownInSeconds,
    };
  },
  created() {
    let totalMinutes, totalSeconds;
    if (this.countDown % 60 == 0) {
      totalMinutes = (this.countDown - 60) / 60;
      totalSeconds = 59;
    } else {
      totalSeconds = this.countDown % 60;
      totalMinutes = (this.countDown - totalSeconds) / 60;
    }
    this.timer = new Date(0, 0, 0, 0, totalMinutes, totalSeconds);
    this.countDownTimer();
  },
  methods: {
    //// Countdown timer implemented with help from: https://stackoverflow.com/a/55773753/10052594
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.timer.setSeconds(this.timer.getSeconds() - 1);
          this.countDownTimer();
        }, 1000);
      } else {
        this.countDown = new Date(0, 0, 0, 0, 0, 0);
        this.$emit('countdownEnded', true);
      }
    },
  },
};
</script>

<style></style>
