<template>
  <template v-if="this.orgUnitUsers.length > 0">
    <div class="rounded-xl text-left">
      <div class="w-full px-5 pt-3 pb-4">
        <h1 class="fc-toolbar-title mb-2">{{ titleText }}</h1>
        <Dropdown v-model="user" @change="changeActingUser" class="w-full" id="changeActingUserDropdown" :options="dropDownOptions" optionLabel="full_name" />
      </div>
    </div>
  </template>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import { mapMutations, mapGetters } from 'vuex';

export default {
  props: ['titleText', 'selectedUser', 'orgUnitUsers', 'autoSelected'],
  data() {
    return {
      user: this.selectedUser,
      dropDownOptions: this.orgUnitUsers.slice(0),
    };
  },
  components: {
    Dropdown,
  },
  computed: {
    ...mapGetters(['actingUser']),
  },
  methods: {
    ...mapMutations(['setActingUser']),
    changeActingUser() {
      this.setActingUser(this.user);
      this.$emit('actingUserChanged', true);
    },
  },
  created() {
    if (this.selectedUser === null) {
      this.dropDownOptions.unshift({
        id: 0,
        full_name: 'Select a User',
      });
      this.user = this.dropDownOptions[0];
      this.setActingUser(this.user);
    }
  },
};
</script>

<style></style>
