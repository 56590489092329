<template>
  <div v-if="tutorialStatus === 'specialistDemoConsult'" class="specialist-tooltip-background"></div>
  <div v-if="isOrgUnitHead(loggedInUser)" class="bg-white rounded-2xl shadow w-full mt-4">
    <ChangeActingUserDropdown titleText="Viewing Consults Of:" :selectedUser="this.actingUser" :orgUnitUsers="orgUnitUsers" @actingUserChanged="updateViewedReferrals" />
  </div>
  <div :class="['calendar-container mt-4 animated fade-in-up border-1', { ['in-front referral-tooltip']: tutorialStatus === 'specialistDemoConsult' }]">
    <FC :options="calendarOptions" />
    <div class="text-xs text-gray-500 font-bold mt-2">Time Displayed in {{ getUserTimeZoneLong() }}</div>
    <div class="hidden md:flex flex-row justify-between mt-4">
      <div class="flex flex-row text-center items-center content-center" v-tooltip.top="'Consult has been booked successfully.'">
        <div class="cal-leg .Upcoming"></div>
        <div class="text-xs text-gray-500 text-bold">Upcoming&nbsp;</div>
      </div>
      <div class="flex flex-row text-center items-center content-center" v-tooltip.top="'Consult is In Review, awaiting consultant response.'">
        <div class="cal-leg InReview"></div>
        <div class="text-xs text-gray-500 text-bold">In Review&nbsp;</div>
      </div>
      <div class="flex flex-row text-center items-center content-center" v-tooltip.top="'Consult form is editable by the referring practitioner and is booked to a consultant time slot.'">
        <div class="cal-leg Pending"></div>
        <div class="text-xs text-gray-500 text-bold">Pending&nbsp;</div>
      </div>
      <div class="flex flex-row text-center items-center content-center" v-tooltip.top="'Consult appointment has been cancelled or is incomplete.'">
        <div class="cal-leg Cancelled"></div>
        <div class="text-xs text-gray-500 text-bold">Cancelled&nbsp;</div>
      </div>
      <div class="flex flex-row text-center items-center content-center" v-tooltip.top="'Specialist has submitted the consult report.'">
        <div class="cal-leg Complete"></div>
        <div class="text-xs text-gray-500 text-bold">Complete&nbsp;</div>
      </div>

      <div class="flex justify-start" v-if="!isDemoAccount(loggedInUser)">
        <Button class="p-button-secondary p-button-xs w-42" @click="goToSubscribeToCalendar"> Subscribe To Calendar </Button>
      </div>

      <template v-if="tutorialStatus === 'specialistDemoConsult'">
        <span class="referral-tooltiptext py-4 px-6" style="left: -140px; top: 100px">
          <h1 class="text-xl text-white font-bold text-left">
            <div>Click the demo consult</div>
          </h1>
          <br />
          <div class="text-white font-semibold text-left">
            <p>Click the blue consult on your calendar. Blue indicates the consult is upcoming</p>
          </div>
          <br />
          <div class="flex flex-row justify-between">
            <button class="text-xs font-bold text-white" @click="this.setTutorialStatus('')" id="skipSpecialistTutorialBtnUpper">Skip Tutorial</button>
          </div>
        </span>
      </template>
    </div>

    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
  </div>
  <Dialog header="Upcoming CME Lecture" :breakpoints="{ '960px': '50vw', '640px': '90vw' }" style="width: 500px" v-model:visible="showLectureDialog" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" id="lectureDialog">
    <div class="text-left">
      <div class="text-lg font-display font-medium mb-2">{{ lecture_title }}</div>
      <div class="text-sm font-display mb-4">Dr. {{ presenter_name }} • {{ presenter_specialty }}</div>
      <pre class="text-sm text-gray-500">{{ lecture_description }}</pre>
    </div>
    <template #footer>
      <div class="flex justify-end">
        <Button label="Close" class="p-button-secondary mr-4" text @click="showLectureDialog = false" />
        <Button @click="goToLecture()" class="p-button-secondary" label="Learn More" iconPos="right" icon="pi pi-arrow-right" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { default as FC } from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import ChangeActingUserDropdown from '@/components/org_unit/ChangeActingUserDropdown.vue';
import Loading from 'vue-loading-overlay';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Calendar',
  components: {
    ChangeActingUserDropdown,
    Loading,
    FC,
  },
  created() {
    this.calendarOptions.events = this.referrals;
    this.calendarOptions.events.push(...this.upcomingLectures);
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin],
        displayEventTime: true,
        eventDisplay: 'block',
        headerToolbar: {
          right: 'prev,next',
          center: 'title',
          left: 'dayGridMonth,timeGridWeek',
        },
        slotMinTime: '6:00:00',
        eventMinHeight: '30',
        firstDay: '0',
        editable: false,
        allDaySlot: false,
        eventClick: this.eventClick,
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short',
        },
        contentHeight: 'auto',
        titleFormat: { year: 'numeric', month: 'short' },
        validRange: { start: this.twoMonthsBefore() },
      },
      showLectureDialog: false,
      lecture_title: null,
      lecture_description: null,
      presenter_name: null,
      presenter_specialty: null,
      lecture_id: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['referrals', 'upcomingLectures', 'actingUser', 'orgUnitUsers', 'isOrgUnitHead', 'loggedInUser', 'isSpecialist', 'tutorialStatus', 'selectedLecture']),
  },
  methods: {
    ...mapActions(['setSelectedReferral', 'getReferralByOrgUnitIdAndUserId', 'getLectureById)']),
    ...mapMutations(['setTutorialStatus']),
    twoMonthsBefore() {
      let myDate = new Date();
      let currentMonth = myDate.getMonth();
      let currentYear = myDate.getFullYear();
      // Set the month to two months earlier
      myDate.setMonth(currentMonth - 2);
      // If the current month was January, set the year to the previous year
      if (currentMonth === 0 || currentMonth === 1) {
        myDate.setFullYear(currentYear - 1);
      }
      return myDate;
    },
    eventClick(info) {
      if (info.event.title == 'CME') {
        this.lecture_title = info.event._def.extendedProps.lecture_title;
        this.lecture_description = info.event._def.extendedProps.lecture_description;
        this.presenter_name = info.event._def.extendedProps.presenter_name;
        this.presenter_specialty = info.event._def.extendedProps.presenter_specialty;
        this.lecture_id = info.event.id;
        this.showLectureDialog = true;
      } else {
        this.$router.push('/referral/' + info.event.id);
      }
    },
    goToLecture() {
      this.$router.push('/lecture/' + this.lecture_id);
    },
    updateViewedReferrals() {
      if (this.actingUser.id != 0) {
        this.isLoading = true;
        this.getReferralByOrgUnitIdAndUserId({ orgUnitId: this.loggedInUser.org_units[0].id, userId: this.actingUser.id })
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
    goToSubscribeToCalendar() {
      this.$router.push({ path: `/myaccount`, query: { subscribeToCalendar: true } });
    },
  },
};
</script>

<style scoped>
.calendar-container {
  background: white;
  border-radius: 0.5rem;
  padding: 20px;
  box-shadow: 0px 2px 3px 2px rgb(135 135 135 / 10%);
  margin-bottom: 20px;
}

@media (max-width: 670px) {
  .calendar-container {
    padding: 1rem;
  }
  .fc-toolbar-chunk {
    display: none !important;
  }
}

.cal-leg {
  width: 15px;
  height: 15px;
  background-color: #5d97f7;
  margin-right: 10px;
  border-radius: 50px;
}
</style>
