<template>
  <div class="z-100 absolute right-0 left-0 mx-auto mt-5 md:ml-auto md:mr-4 px-6 md:px-0 -top-32 md:-top-4 md:mt-8 nightmare-input">
    <!-- filtering specialties on desktop -->
    <IconField iconPosition="left" class="hidden lg:flex">
      <InputIcon class="pi pi-search"> </InputIcon>
      <InputText v-model="filterValue" placeholder="Search Specialties" class="w-full text-white placeholder-white" maxLength="255" @input="filterValueOnChange" style="background: transparent; border: 1px solid white; color: white" />
    </IconField>
    <!-- specialty dropdown on mobile (arguably better UX)-->
    <Dropdown :options="availableSpecialtiesOptions" placeholder="Available Specialties" v-model="dropdownSelectedSpecialtyId" optionLabel="specialty_name" optionValue="id" @change="goToSelectSpecialist(this.dropdownSelectedSpecialtyId)" class="p-inputtext-md w-full text-left text-white flex lg:hidden" id="mobile-specialty-select" style="background: transparent; border: 2px solid white; color: white" />
  </div>

  <div v-if="this.featuredSpecialties.length > 0" id="top_specialties" class="w-full">
    <div class="w-full text-left mt-4 font-semibold px-3 font-display">Top Specialties</div>
    <div class="relative grid w-full grid-cols-1 gap-4 pt-4 sm:grid-cols-2 xl:grid-cols-4" v-if="!isLoading">
      <div v-for="(availability, specialtyName) in featuredSpecialties" :key="specialtyName" @click="goToSelectSpecialist(availability.id)" :aria-label="featuredSpecialtyName" class="position-relative tile flex w-full flex-col items-center justify-center rounded-2xl bg-white shadow px-5 py-8 border-1 hover:border-black cursor-pointer">
        <img v-bind:src="availability.specialty_image" class="h-20 mb-3" v-if="availability.specialty_image" />
        <h3 h3 class="text-md text-center font-semibold text-gray-700">{{ availability.specialty_name }}</h3>
      </div>
    </div>
  </div>

  <div class="w-full text-left mt-6 font-semibold px-3 font-display">All Specialties</div>

  <div class="relative grid w-full grid-cols-1 gap-4 pt-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4" v-if="!isLoading">
    <div v-for="(availability, specialtyName) in availableSpecialties" :key="specialtyName" class="animated fade-in-up flex cursor-pointer flex-col items-center" @click="goToSelectSpecialist(availability.specialty_id)" :aria-label="specialtyName">
      <!-- <div v-if="actingUser.practice_province.name == 'Nova Scotia' && specialtyName == 'Gastroenterology'" style="bottom: 3.7rem" class="hidden md:flex w-full justify-end absolute z-50 mb-6 pl-2 pr-1">
          <div class="flex flex-col rounded-md text-white font-bold new-feature-background p-1.5">
            <div class="text-xs font-bold">Patient with Anemia or Lower GI symptoms?</div>
            <div class="text-xs font-bold">Get through triage fast with SmartPath!</div>
            <div class="text-xs underline cursor-pointer" @click="trySmartPath(availability.specialty_id)">Try SmartPath</div>
          </div>
        </div> -->

      <div v-if="availability?.next_availability" class="position-relative tile flex w-full flex-col items-start rounded-2xl bg-white shadow">
        <div class="mt-3 flex w-full flex-col items-start justify-between px-4">
          <div class="flex w-full flex-row items-center justify-between">
            <h3 h3 class="text-md mb-2 text-left font-semibold text-gray-700">{{ specialtyName }}</h3>
            <div class="mb-2 flex text-sm text-gray-400">
              <span v-if="availability.available_user_ids.length > 0" style="font-size: 0.8rem"><i class="pi fas fa-user mr-1" style="font-size: 0.7rem" />{{ availability.available_user_ids.length }}</span>
            </div>
          </div>
          <div class="mb-4 flex flex-row text-xs font-bold text-gray-600">
            Next Availability: <span class="ml-1 text-blue">{{ formatReadableDateTime(availability.next_availability.start_datetime) }}</span>
          </div>

          <div class="grid grid-cols-2 gap-4 mb-4 w-full">
            <Button @click="confirmConsult(availability)" v-on:click.stop class="p-button-xs p-button-secondary mx-auto w-full">
              <span class="mx-auto text-center">Book Next Availability</span>
            </Button>
            <Button id="selenium-select-specialty" @click="goToSelectSpecialist(availability.specialty_id)" v-on:click.stop class="p-button-xs p-button-secondary p-button-outlined mx-auto w-full" :aria-label="specialtyName + '-button'">
              <span class="mx-auto text-center">View All Availabilities</span>
            </Button>
          </div>
        </div>
        <!-- <div id="selenium-select-specialty" class="mb-3 mt-1 flex w-full cursor-pointer items-center justify-between px-5 text-sm font-bold text-gray-500 hover:text-blue-500" @click="goToSelectSpecialist(availability.specialty_id)">
            <div class="flex flex-row">View All Availabilities</div>
            <i class="pi pi-arrow-right" style="font-size: 0.8rem"></i>
          </div> -->
      </div>
      <div v-else-if="availability?.count_available_econsult_users > 0" class="position-relative tile flex w-full flex-col items-start rounded-2xl bg-white shadow">
        <div class="mt-3 flex w-full flex-col items-start justify-between px-4">
          <div class="flex w-full flex-row items-center justify-between">
            <h3 h3 class="text-md mb-2 text-left font-semibold text-gray-700">{{ specialtyName }}</h3>
            <div class="mb-2 flex text-sm text-gray-400">
              <span v-if="availability?.count_available_econsult_users > 0" style="font-size: 0.8rem"><i class="pi fas fa-user mr-1" style="font-size: 0.7rem" />{{ availability.count_available_econsult_users }}</span>
            </div>
          </div>
          <div class="mb-4 flex flex-row text-xs font-bold text-gray-600">Available For eConsults</div>

          <div class="mb-4 flex w-full">
            <Button id="selenium-select-specialty" @click="goToSelectSpecialist(availability?.specialty_id)" v-on:click.stop class="p-button-xs p-button-secondary mx-auto w-full" :aria-label="specialtyName + '-button'">
              <span class="mx-auto text-center">View All {{ getReferralPersonTerm(actingUser, true) }}s</span>
            </Button>
          </div>
        </div>
      </div>
      <div v-else class="position-relative tile flex w-full flex-col items-start rounded-2xl bg-white shadow">
        <div class="mt-3 flex w-full flex-col items-start justify-between px-4">
          <div class="flex w-full flex-row items-center justify-between">
            <h3 h3 class="text-md mb-2 text-left font-semibold text-gray-700">{{ specialtyName }}</h3>
            <div class="mb-2 flex text-sm text-gray-400">
              <!-- <span v-if="availability?.count_available_econsult_users > 0" style="font-size: 0.8rem"><i class="pi fas fa-user mr-1" style="font-size: 0.7rem" />{{ availability.count_available_econsult_users }}</span> -->
            </div>
          </div>
          <div v-if="availability?.count_on_demand_availability > 0" class="mb-4 flex flex-row text-xs font-bold text-gray-600 h-4">OnDemand Available Only <i class="ml-1 pi pi-bolt text-red-500" /></div>
          <div v-else class="mb-4 flex flex-row text-xs font-bold text-gray-600 h-4">Fully Booked</div>

          <div class="mb-4 flex w-full">
            <Button id="selenium-select-specialty" @click="goToSelectSpecialist(availability?.specialty_id)" v-on:click.stop class="p-button-xs p-button-secondary mx-auto w-full" :class="{ ' p-button-gray': !availability?.count_on_demand_availability > 0 }" :aria-label="specialtyName + '-button'">
              <span v-if="availability?.count_on_demand_availability > 0" class="mx-auto text-center">Book OnDemand</span>
              <span v-else class="mx-auto text-center">Get Notified when Available</span>
            </Button>
          </div>
        </div>
      </div>
    </div>

    <!-- START AVAILABILITY NOT HERE TILE -->
    <div class="animated fade-in-up flex cursor-pointer flex-col items-center">
      <div class="position-relative tile flex w-full flex-col items-start rounded-2xl bg-white shadow" @click="openAvailabilityNotHereModal">
        <div class="mt-3 flex w-full flex-col items-start justify-between px-4">
          <div class="flex w-full flex-row items-center justify-between">
            <h3 h3 class="text-md mb-2 text-left font-semibold text-gray-700">Don't see who you're looking for?</h3>
          </div>
          <div class="mb-4 flex flex-row text-xs font-bold text-gray-600">Is a specialty missing or lacking availability?</div>

          <div class="flex gap-4 mb-4 w-full justify-end">
            <Button id="let-us-know-availability" @click="openAvailabilityNotHereModal" v-on:click.stop class="p-button-xs p-button-secondary p-button-outlined mx-auto w-full">
              <span class="mx-auto text-center">Let us know </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
    <!-- END AVAILABILITY NOT HERE TILE -->
  </div>
  <div v-else class="flex h-48 w-full flex-grow flex-col items-center justify-end">
    <i class="pi pi-spin pi-spinner" style="font-size: 2.5rem; color: #ef5164"></i>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';

export default {
  props: ['consultType'],
  inject: ['mixpanel'],

  data() {
    return {
      availableSpecialties: [],
      featuredSpecialties: [],
      dropdownSelectedSpecialtyId: null,
      isLoading: false,
      filterValue: '',
    };
  },
  computed: {
    ...mapGetters(['allSpecialties', 'actingUser', 'selectedInteractionType', 'nextAvailabilityOfSpecialties', 'isSpecialist', 'loggedInUser']),
  },
  components: {
    Button,
    InputText,
    IconField,
    InputIcon,
  },
  emits: ['goToSelectSpecialist', 'popUpConfirmReferral', 'openAvailabilityNotHereModal'],
  methods: {
    ...mapActions(['getSpecialties', 'getNextAvailabilityOfSpecialties', 'getSpecialtyById', 'getUserById']),
    ...mapMutations(['setTutorialStatus']),
    goToSelectSpecialist(specialtyId) {
      this.getSpecialtyById(specialtyId)
        .then((specialty) => {
          this.$emit('goToSelectSpecialist', specialty);
          this.mixpanel.track('Make Referral - Clicked Specialty', { specialty: specialty.name, numberOfAvailableSpecialist: this.availableSpecialties[specialty.name].available_user_ids.length });
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    confirmConsult(availability) {
      this.getSpecialtyById(availability.specialty_id)
        .then((specialty) => {
          this.getUserById(availability.next_availability.user_id)
            .then((user) => {
              this.$emit('popUpConfirmReferral', {
                user: user,
                selectedSpecialty: specialty,
                selectedAvailabilitySlot: availability.next_availability,
                consultType: 'phone',
              });
            })
            .catch(() => {
              this.showGenericErrorToast();
            });
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
      // http://localhost:8080/makereferral?selectedScreen=selectSpecialist&specialty=Psychiatry&id=1
    },
    filterValueOnChange() {
      if (this.selectedInteractionType === 'phone') {
        if (this.filterValue === '') {
          this.availableSpecialties = this.nextAvailabilityOfSpecialties;
        } else {
          this.availableSpecialties = Object.fromEntries(Object.entries(this.nextAvailabilityOfSpecialties).filter(([key]) => key.toLowerCase().includes(this.filterValue.toLowerCase())));
        }
      }
    },
    openAvailabilityNotHereModal() {
      this.$emit('openAvailabilityNotHereModal');
    },
    trySmartPath(specialty_id) {
      this.setTutorialStatus('try-smartpath');
      this.goToSelectSpecialist(specialty_id);
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.isLoading = true;

    this.getNextAvailabilityOfSpecialties(this.actingUser.id)
      .then(() => {
        this.isLoading = false;
        this.availableSpecialties = this.nextAvailabilityOfSpecialties;
        this.availableSpecialtiesOptions = Object.entries(this.availableSpecialties).map(([specialty_name, details]) => ({
          specialty_name,
          id: details.specialty_id,
          specialty_status: details.specialty_status,
          specialty_image: details.specialty_image,
        }));
        this.featuredSpecialties = this.availableSpecialtiesOptions.filter((specialty) => {
          return specialty.specialty_status == 'FEATURED';
        });
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });
  },
};
</script>

<style scoped>
.tile {
  /* border: 3px solid white; */
  border: 1px solid #c9c9c9;
}
.tile:hover {
  border: 1px solid var(--vh-dark-blue);
}
.make-referral-select-container {
  height: 100%;
}
.make-referral-select-side-panel {
  flex: 0 0 200px;
  padding: 15px 30px 25px 30px;
  text-align: center;
  justify-content: space-between;
  position: relative;
  height: 100%;
  border-right: 1px solid var(--vh-medium-gray);
}

.make-referral-select-side-panel-item:nth-last-child(1) {
  margin-top: auto;
}
.select-specialty-list {
  flex: 1;
  max-height: 90%;
  padding-right: 10px;
}
.select-specialty-list-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #e5e7ee;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  padding: 15px;
  margin-bottom: 10px;
  color: #557bb9;
  text-decoration: none;
  transition: transform 0.2s ease-out;
  font-weight: 500;
  font-size: 1rem;
  text-align: left;
}

.p-button-gray {
  background-color: #e3e3e3;
  border-color: #e3e3e3;
  color: rgb(23, 23, 23);
}
.p-button-gray:hover {
  background-color: #c7c7c7 !important;
  border-color: #c7c7c7 !important;
  color: rgb(23, 23, 23) !important;
}

.specialty-grid {
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
}

@media (max-width: 850px) {
  .make-referral-select-side-panel {
    display: none;
  }
  .specialty-content {
    padding: 0px;
  }
  .select-specialty-list {
    flex: 1;
    padding-right: 10px;
  }
}
</style>
