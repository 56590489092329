<template>
  <div>
    <div class="main-inner-wrap">
      <div class="flex w-full items-start justify-center bg-gray-100 px-5 pt-8 pb-20 text-center lg:w-3/4">
        <div class="registration-form-card card animated fade-in-up rounded-3xl p-4 shadow sm:p-8">
          <div class="m-auto flex w-full flex-col items-center justify-center py-4 text-center">
            <img class="w-60" src="@/assets/Virtual-Hallway-Logo-Horizontal.svg" alt="" />
            <h2 class="py-3 text-xl font-normal">Account Update Form</h2>
            <div class="info-paragraph--gray col-start-1 col-end-3 mb-2">
              <p>
                We've detected that you might be missing some information in your profile please fill out the form below
                <span class="error">*&nbsp;</span>
              </p>
            </div>
          </div>
          <AddUserForm @goToNext="goToNext" :loggedInUser="user" :propsUser="null" />
          <p class="error">
            {{ error }}
          </p>
        </div>
      </div>
    </div>
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddUserForm from '@/views/public/registration/registrationForm/AddUserForm.vue';
import Loading from 'vue-loading-overlay';
export default {
  data() {
    return {
      user: this.loggedInUser,
      error: '',
      response: '',
      isLoading: false,
    };
  },
  components: {
    AddUserForm,
    Loading,
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['updateUserAccountInfo']),
    submit() {
      this.error = '';
      this.$toast.removeAllGroups();
      this.isLoading = true;
      this.updateUserAccountInfo(this.user)
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Account Updated',
            detail: 'Account information has been successfully updated.',
            life: 5000,
          });
          this.$router.push('home');
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    goToNext($event) {
      this.user = { ...this.user, ...$event };
      this.submit();
    },
  },
  created() {
    this.user = this.loggedInUser;
  },
};
</script>

<style>
.registration-form-card {
  opacity: 1;
  transition: opacity 0.1s linear;
  margin: 0;
  max-width: 625px;
}
</style>
