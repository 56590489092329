<template>
  <div></div>
  <div class="mt-4 mb-1 hidden w-full rounded-2xl bg-white shadow xl:flex">
    <div class="flex w-full flex-row rounded-2xl">
      <div class="relative flex w-full flex-row items-center border-r border-solid border-gray-300 px-4 py-3">
        <div :class="specialtyColour" class="mr-4 h-12 w-12 rounded-full border-2 py-3 transition duration-500 ease-in-out">
          <i :class="specialtyIcon"></i>
        </div>
        <div class="flex flex-col items-start text-left">
          <p class="text-blue text-xs font-semibold uppercase">Step 1</p>
          <p class="text-xs font-semibold text-gray-500">{{ displaySpecialty }}</p>
        </div>
      </div>
      <div class="relative flex w-full flex-row items-center border-r border-solid border-gray-300 px-4 py-3">
        <div :class="specialistColour" class="mr-4 h-12 w-12 rounded-full border-2 py-3 transition duration-500 ease-in-out">
          <i :class="specialistIcon"></i>
        </div>
        <div class="flex flex-col items-start text-left">
          <p class="text-blue text-xs font-semibold uppercase">Step 2</p>
          <p class="text-xs font-semibold text-gray-500">{{ displaySpecialist }}</p>
        </div>
      </div>
      <div class="relative flex w-full flex-row items-center border-r border-solid border-gray-300 px-4 py-3">
        <div :class="dateColour" class="mr-4 h-12 w-12 rounded-full border-2 py-3 transition duration-500 ease-in-out">
          <i :class="dateIcon"></i>
        </div>
        <div class="flex flex-col items-start text-left">
          <p class="text-blue text-xs font-semibold uppercase">Step 3</p>
          <p class="text-xs font-semibold text-gray-500">{{ displayDate }}</p>
        </div>
      </div>
      <div class="relative flex w-full flex-row items-center border-gray-300 px-4 py-3">
        <div class="mr-4 h-12 w-12 rounded-full border-2 border-primary py-3 transition duration-500 ease-in-out">
          <i class="pi pi-list text-left text-gray-500"></i>
        </div>
        <div class="flex flex-col items-start text-left">
          <p class="text-blue text-xs font-semibold uppercase">Step 4</p>
          <p class="text-xs font-semibold text-gray-500">Consult Form</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['event', 'selectedSpecialty', 'selectedSpecialists', 'selectedDate', 'consultType'],
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser']),
    specialtyColour() {
      if (this.event == 'selectSpecialty' || this.event == null) {
        return 'bg-white border-primary';
      } else {
        return 'bg-primary border-primary';
      }
    },
    specialtyIcon() {
      if (this.event == 'selectSpecialty' || this.event == null) {
        return 'pi pi-search text-grey-500 text-left';
      } else if (this.event == 'referralForm') {
        return 'pi pi-lock text-white text-left';
      } else {
        return 'pi pi-check text-white text-xl text-left';
      }
    },
    displaySpecialty() {
      if (this.event != 'selectSpecialty' || this.event == null) {
        if (this.selectedSpecialty.name != null) {
          return this.selectedSpecialty.name;
        } else {
          return 'Select Specialty';
        }
      } else {
        return 'Select Specialty';
      }
    },
    specialistColour() {
      if (this.selectedSpecialists.length === 0) {
        return 'bg-white border-primary';
      } else {
        return 'bg-primary border-primary';
      }
    },
    specialistIcon() {
      if (this.selectedSpecialists.length === 0) {
        return 'pi pi-users text-grey-500 text-left';
      } else if (this.event == 'referralForm') {
        return 'pi pi-lock text-white text-left';
      } else {
        return 'pi pi-check text-white text-xl text-left';
      }
    },
    displaySpecialist() {
      if (this.selectedSpecialists.length > 0) {
        if (this.selectedSpecialists.length > 1) {
          return 'Any ' + this.getReferralPersonTerm(this.actingUser, true) + ' in ' + this.selectedSpecialty.name;
        } else {
          return this.displayNameByRole(this.selectedSpecialists[0]);
        }
      } else {
        return 'Select a ' + this.getReferralPersonTerm(this.actingUser, true);
      }
    },
    dateColour() {
      if (this.event == 'selectSpecialty' || this.event == 'selectSpecialist' || this.event == 'selectDate' || this.event == null) {
        return 'bg-white border-primary';
      } else {
        return 'bg-primary border-primary';
      }
    },
    dateIcon() {
      if (this.event == 'selectSpecialty' || this.event == 'selectSpecialist' || this.event == 'selectDate' || this.event == null) {
        return 'pi pi-calendar text-grey-500 text-left';
      } else if (this.event == 'referralForm') {
        return 'pi pi-lock text-white text-left';
      } else {
        return 'pi pi-check text-white text-xl text-left';
      }
    },
    displayDate() {
      if (this.consultType === 'econsult') {
        if (this.event === 'selectSpecialty' || this.event === 'selectSpecialist') {
          return 'Confirm eConsult Booking';
        } else {
          return 'eConsult Confirmed';
        }
      } else {
        if (this.event != 'selectSpecialty' && this.event != 'selectSpecialist' && this.event != 'selectDate') {
          if (this.selectedDate != null) {
            return this.selectedDate;
          } else {
            return 'Select a Date/Time';
          }
        } else {
          return 'Select a Date/Time';
        }
      }
    },
  },
};
</script>

<style></style>
