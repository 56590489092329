<template>
  <div class="grid grid-cols-1 gap-4 md:grid-cols-4 mt-4 w-full">
    <div class="relative rounded-xl bg-secondary p-4 text-left shadow md:col-end-3 md:col-start-1">
      <div class="flex h-full flex-row">
        <div class="mr-6 flex flex-col items-center justify-center border-r border-solid border-white p-6 pl-2">
          <span class="text-lg uppercase tracking-widest text-white md:text-2xl">{{ currentMonth }}</span>
          <span class="text-4xl font-bold text-white md:text-6xl">01</span>
        </div>

        <div class="mb-2 flex flex-col items-start justify-center">
          <i class="pi pi-credit-card mb-3 text-white" style="font-size: 2rem" />
          <span class="mb-2 text-xl font-bold text-white md:text-3xl">Next Payment Cycle</span>
          <div v-if="billingCompleted && actingUser">
            <div class="flex flex-row">
              <div class="text-sm font-bold text-white">
                Payment sent via e-transfer to {{ findBillingArrangementByProvince(this.actingUser, this.actingUser.practice_province.id)?.etransfer_email }}
                <i id="editEtransferPerformancePage" class="pi pi-pencil ml-2 mt-1 cursor-pointer text-white hover:text-blue-500" style="font-size: 0.8rem" @click="openEtransferEmailModal()" />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-row">
              <div class="cursor-pointer rounded-lg pl-1 text-sm font-bold text-white hover:bg-gray-600" id="complete-billing-form-btn" @click="$emit('openBillingArrangementModal')">
                <i class="pi pi-exclamation-circle mr-1 mt-1 text-white" style="font-size: 1rem" />
                You have not yet submitted your billing form. Click to Complete Billing Form.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-start rounded-xl p-6 text-left shadow" style="background-color: #cc338c">
      <i class="pi pi-check-circle mb-4 text-white" style="font-size: 2rem" />
      <span class="mb-2 text-2xl font-bold text-white md:text-3xl">${{ parseFloat(earningsThisMonth).toFixed(2) }}</span>
      <div class="flex flex-row gap-2">
        <div><span class="text-sm font-bold text-white">Expected Earnings this Month</span></div>
        <div><i class="pi pi-question-circle mb-4 text-white" v-tooltip.bottom="'Earnings are an approximation based on billing codes.'" /></div>
      </div>
    </div>
    <div class="flex flex-col items-start rounded-xl p-6 text-left shadow" style="background-color: #c42079">
      <i class="pi pi-chart-line mb-4 text-white" style="font-size: 2rem" />
      <span class="mb-2 text-2xl font-bold text-white md:text-3xl">${{ parseFloat(earningsToDate).toFixed(2) }}</span>
      <div class="flex flex-row gap-2">
        <div><span class="text-sm font-bold text-white">Estimated Earnings to Date</span></div>
        <div><i class="pi pi-question-circle mb-4 text-white" v-tooltip.bottom="'Earnings are an approximation based on billing codes.'" /></div>
      </div>
    </div>

    <div v-if="!isLoading" class="md:col-start-1 md:col-end-3">
      <div v-if="dataTableReferrals.length > 0">
        <div class="rounded-xl bg-white pb-6 shadow">
          <div class="flex flex-row justify-between">
            <h1 class="p-6 text-lg font-bold text-gray-600">Recent Completed Consults</h1>
          </div>
          <DataTable editMode="cell" :value="dataTableReferrals.slice(0, 4)" class="p-datatable-gridlines editable-cells-table" dataKey="referral.id" :rowHover="true" filterDisplay="menu" responsiveLayout="scroll">
            <Column field="referral_form.patient_full_name" header="Patient">
              <template #body="{ data }">
                <span class="p-column-title" :aria-label="data.referral_form.patient_full_name"></span>
                <div class="text-md hidden text-gray-600 md:block">{{ data.referral_form.patient_full_name }}</div>
              </template>
            </Column>

            <Column field="id" header="Billing Code">
              <template #body="{ data }">
                <div v-if="data.referral_person_id == actingUser.id">
                  <span class="p-column-title" :aria-label="data.billing.referral_person_billing_code.billing_code"></span>
                  {{ data.billing.referral_person_billing_code.billing_code }}
                </div>
                <div v-else>
                  <span class="p-column-title" :aria-label="data.billing.referee_billing_code.billing_code"></span>
                  <div class="text-md hidden text-gray-600 md:block">{{ data.billing.referee_billing_code.billing_code }}</div>
                </div>
              </template>
            </Column>

            <Column field="id" header="Reimbursement">
              <template #body="{ data }">
                <div v-if="data.referral_person_id == actingUser.id">
                  <div v-if="actingUser.billing_arrangements != null && actingUser.billing_arrangements.receives_full_reimbursement" class="text-md hidden text-gray-600 md:block">$ {{ parseFloat(data.billing.referral_person_billing_code.full_reimbursement).toFixed(2) }}</div>
                  <div v-else class="text-md hidden text-gray-600 md:block">$ {{ parseFloat(data.billing.referral_person_billing_code.reimbursement).toFixed(2) }}</div>
                </div>
                <div v-else>
                  <div v-if="actingUser.billing_arrangements != null && actingUser.billing_arrangements.receives_full_reimbursement" class="text-md hidden text-gray-600 md:block">$ {{ data.billing.referee_billing_code.full_reimbursement }}</div>
                  <div v-else class="text-md hidden text-gray-600 md:block">$ {{ data.billing.referee_billing_code.reimbursement }}</div>
                </div>
              </template>
            </Column>

            <Column field="start_datetime" header="Consult Date" :sortable="true">
              <template #body="{ data }">
                <span class="p-column-title" :aria-label="data.start_datetime"></span>
                <div class="text-md hidden text-gray-600 md:block">{{ formatDate(data.start_datetime) }}</div>
              </template>
            </Column>

            <Column field="id" header="View">
              <template #body="{ data }">
                <span class="p-column-title" :aria-label="data.start_datetime"></span>
                <Button class="p-button-sm" icon="pi pi-eye" @click="selectReferral(data.id)" />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <div v-else class="rounded-xl bg-white p-6 shadow h-full">
        <h1 class="text-lg font-bold text-gray-600">No consults yet!</h1>
        <router-link class="layout-sidebar-menu-link p-ripple group" to="/makereferral">
          <div class="ml-3 transform transition duration-500 ease-in-out group-hover:translate-x-2">Book a Consult</div>
          <i class="pi pi-id-card icon-grey group-hover:text-primary"></i>
        </router-link>
      </div>
    </div>
    <div>
      <div class="flex flex-col rounded-xl bg-secondary p-4 text-left shadow w-full">
        <h1 class="text-sm text-gray-200 mb-3">
          Would you like to bill yourself?
          <i class="pi pi-question-circle icon-grey ml-1" v-tooltip.bottom="'Virtual Hallway provides billing at no charge but if you prefer to bill yourself click the opt out button below. You will be able to export all consults Virtual Hallway has not already billed for.'"></i>
        </h1>
        <Button label="Opt Out" class="p-button-outlined !text-white p-button-sm !mt-4 hover:!text-black max-w-[256px] w-full" @click="optOutOfBillingOnClick"></Button>
      </div>
    </div>
  </div>

  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />

  <modal ref="etransferEmailModal">
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="flex w-full justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <h1 class="mb-6 text-xl font-bold md:text-2xl">Set your etransfer email</h1>
          <p class="mb-4 text-sm font-semibold text-gray-500">
            At the start of every month, Virtual Hallway will reimburse you for the consults completed in the previous month via e-transfer to the email address of you choice.
            <br /><br />
            If you do not provide an etransfer email, Virtual Hallway will transfer to your account email address, {{ actingUser.email }}.
          </p>
          <br />
          <form method="post" @submit.prevent="submit">
            <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
              <label class="auth-input" for="eTransferEmail">Change E-Transfer Email </label>
              <InputText v-model="actingUser.etransfer_email" class="auth-input" id="eTransferEmail" maxLength="255" type="email" required />
              <div class="col-span-2 mt-4 flex justify-between">
                <Button label="Cancel" icon="pi pi-ban" class="p-button-outlined p-button-danger justify-start" @click="closeEtransferEmailModal()"></Button>
                <Button type="submit" label="Confirm" icon="pi pi-check" id="saveEtransferEmailBtn"></Button>
              </div>
            </div>
          </form>
          <p class="mb-4 mt-4 text-sm font-semibold text-gray-500">To change other account settings, visit the Account page.</p>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Button from 'primevue/button';
import Modal from '@/components/misc/Modal.vue';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Loading from 'vue-loading-overlay';

export default {
  inject: ['mixpanel'],
  emits: ['openBillingArrangementModal'],
  data() {
    return {
      currentMonth: moment().add(1, 'months').format('MMM'),
      allCompleteReferrals: [],
      earningsThisMonth: 0.0,
      earningsToDate: 0.0,
      dataTableReferrals: [],
      isLoading: false,
    };
  },
  props: ['billingCompleted'],
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser', 'isGP', 'allReferrals', 'findBillingArrangementByProvince']),
  },
  components: {
    Button,
    Modal,
    InputText,
    DataTable,
    Column,
    Loading,
  },
  methods: {
    ...mapActions(['getAllReferralsOfUser', 'updateUserAccountInfo']),
    selectReferral(id) {
      this.$router.push('/referral/' + id);
    },
    openEtransferEmailModal() {
      this.$refs.etransferEmailModal.openModal();
    },
    closeEtransferEmailModal() {
      this.$refs.etransferEmailModal.closeModal();
    },
    submit() {
      this.updateUserAccountInfo(this.actingUser)
        .then(() => {
          this.$refs.etransferEmailModal.closeModal();
          this.$toast.add({
            severity: 'success',
            summary: 'Account Updated',
            detail: 'Account information has been successfully updated.',
            life: 5000,
          });
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    optOutOfBillingOnClick() {
      this.isLoading = true;
      this.updateUserAccountInfo({ ...this.actingUser, billing_opt_in: false })
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Successfully Opted Out Of Billing',
            detail: 'Account information has been successfully updated.',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
  created() {
    this.isLoading = true;
    this.getAllReferralsOfUser({
      user_id: this.actingUser.id,
      required_relations: ['referral_form', 'referee', 'billing', 'billing.referee_billing_code', 'billing.referral_person_billing_code', 'interaction_type', 'specialty'],
    })
      .then(() => {
        if (this.allReferrals) {
          this.allCompleteReferrals = this.allReferrals
            .filter(function (referral) {
              return referral.status === 'Complete';
            })
            .reverse();

          // need to deep copy them
          // b/c allCompleteReferrals is sorted by start_datetime where the first index is the very first referral, we need to reverse the array
          // this is because in the Most Recent datatables, we use array slice(0, 5)
          this.dataTableReferrals = JSON.parse(JSON.stringify(this.allCompleteReferrals));
          this.dataTableReferrals.reverse();

          let referralsThisMonth = this.allReferrals.filter(function (referral) {
            // this will return referrals that have only occurred within the month (after the start of the month)
            return moment(referral.start_datetime).isAfter(moment().startOf('month'));
            // return if date is greater than start of month
          });

          const receives_full_reimbursement = this.actingUser.billing_arrangements != null && this.actingUser.billing_arrangements.receives_full_reimbursement;

          referralsThisMonth.forEach((referral) => {
            // need to check if consult was complete
            if (referral.status === 'Complete') {
              if (referral.referral_person_id == this.actingUser.id) {
                if (receives_full_reimbursement) {
                  this.earningsThisMonth += parseFloat(referral.billing.referral_person_billing_code.full_reimbursement);
                } else {
                  this.earningsThisMonth += parseFloat(referral.billing.referral_person_billing_code.reimbursement);
                }
              } else {
                if (receives_full_reimbursement) {
                  this.earningsThisMonth += parseFloat(referral.billing.referee_billing_code.full_reimbursement);
                } else {
                  this.earningsThisMonth += parseFloat(referral.billing.referee_billing_code.reimbursement);
                }
              }
            }
          });

          // assign all other referrals to earnings to date
          this.allReferrals.forEach((referral) => {
            if (referral.status === 'Complete') {
              if (referral.referral_person_id == this.actingUser.id) {
                if (receives_full_reimbursement) {
                  this.earningsToDate += parseFloat(referral.billing.referral_person_billing_code.full_reimbursement);
                } else {
                  this.earningsToDate += parseFloat(referral.billing.referral_person_billing_code.reimbursement);
                }
              } else {
                if (receives_full_reimbursement) {
                  this.earningsToDate += parseFloat(referral.billing.referee_billing_code.full_reimbursement);
                } else {
                  this.earningsToDate += parseFloat(referral.billing.referee_billing_code.reimbursement);
                }
              }
            }
          });
        }
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });
    this.mixpanel.track('Viewed My Impact');
  },
};
</script>

<style></style>
