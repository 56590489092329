<template>
  <div class="main-inner-wrap">
    <div class="p-6 my-3 w-full max-w-lg" v-if="this.component != 'AccountSetupFinish'">
      <ProgressBar :value="progressValue" :showValue="false" />
    </div>
    <component :is="component" :propsUser="user" :loggedInUser="loggedInUser" @goToNext="goToNext" @goToPrevious="goToPrevious"></component>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SetIntention from '@/components/account_setup_forms/SetIntention.vue';
import SetProvince from '@/components/account_setup_forms/SetProvince.vue';
import SetRole from '@/components/account_setup_forms/SetRole.vue';
import SetProviderType from '@/components/account_setup_forms/SetProviderType.vue';
import SetLicense from '@/components/account_setup_forms/SetLicense.vue';
import SetOnDemand from '@/components/account_setup_forms/SetOnDemand.vue';
import AccountSetupFinish from '@/components/account_setup_forms/AccountSetupFinish.vue';
import ProgressBar from 'primevue/progressbar';
import Loading from 'vue-loading-overlay';

export default {
  components: {
    SetIntention: SetIntention,
    SetProvince: SetProvince,
    SetRole: SetRole,
    SetProviderType: SetProviderType,
    SetLicense: SetLicense,
    SetOnDemand: SetOnDemand,
    AccountSetupFinish: AccountSetupFinish,
    ProgressBar,
    Loading,
  },
  data() {
    return {
      component: '',
      isLoading: false,
      user: {
        practice_province_id: '',
        role: '',
        provider_type: '',
        provider_number: '',
        phone_number: '',
        cpsns: '',
        hear_about_us: '',
        specialty_ids: '',
        other_specialty: '',
        org_unit: null,
        ohip_number: null,
      },
      progressValue: '',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser', 'isPreboarding', 'isGP', 'isSpecialist', 'isOrgUnitHead', 'allProvinces', 'isCme']),
  },
  methods: {
    ...mapActions(['setupUserAccount']),
    ...mapMutations(['setTutorialStatus']),

    goToNext() {
      this.isLoading = true;
      this.setupUserAccount(this.user)
        .then((res) => {
          this.isLoading = false;
          if (res.status == 200) {
            if (this.component == 'SetIntention') {
              this.component = 'SetProvince';
              this.progressValue = 20;
              if (this.isCme(this.user)) {
                let u = this.loggedInUser;
                u.role = 'cme';
                this.$store.commit('updateLoggedInUser', u);
                this.$router.push('/lectures');
                return;
              }
              return;
            }
            if (this.component == 'SetProvince') {
              this.component = 'SetRole';
              this.progressValue = 40;
              return;
            }
            if (this.component == 'SetRole') {
              if (this.user.role != 'cme' && (this.user.provider_type == 'NP' || this.user.provider_type == 'Pharmacist' || this.user.role === 'org_unit_head')) {
                this.component = 'SetLicense';
                this.progressValue = 90;
                return;
              }
              this.component = 'SetProviderType';
              if (this.user.role == 'specialist') {
                this.progressValue = 60;
              } else if (this.isCme(this.user)) {
                let u = this.loggedInUser;
                u.role = 'cme';
                this.$store.commit('updateLoggedInUser', u);
                this.$router.push('/lectures');
              } else {
                this.progressValue = 75;
              }
              return;
            }
            if (this.component == 'SetProviderType') {
              this.component = 'SetLicense';
              if (this.user.role == 'specialist') {
                this.progressValue = 80;
              } else {
                this.progressValue = 100;
              }
              return;
            }
            if (this.component == 'SetLicense') {
              if (this.user.role == 'gp') {
                this.$router.push('/makereferral');
                return;
              }
              if (this.user.role == 'specialist') {
                this.progressValue = 100;
                this.component = 'SetOnDemand';
                return;
              }
              this.progressValue = 100;
              this.component = 'AccountSetupFinish';
              return;
            }
            if (this.component == 'SetOnDemand') {
              if (this.user.role == 'specialist') {
                this.$router.push('/verification');
                return;
              }
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    goToPrevious() {
      if (this.component == 'SetRole') {
        this.component = 'SetProvince';
        this.progressValue = 20;
        return;
      }
      if (this.component == 'SetProviderType') {
        this.component = 'SetRole';
        this.progressValue = 40;
        return;
      }
      if (this.component == 'SetLicense') {
        if (this.user.provider_type == 'NP' || this.user.provider_type == 'Pharmacist' || this.user.role === 'org_unit_head') {
          this.component = 'SetRole';
          this.progressValue = 60;
          return;
        }
        this.component = 'SetProviderType';
        if (this.user.role == 'specialist') {
          this.progressValue = 60;
        } else {
          this.progressValue = 75;
        }
        return;
      }
      if (this.component == 'SetOnDemand') {
        this.progressValue = 80;
        this.component = 'SetLicense';
      }
    },
  },
  created() {
    this.component = 'SetIntention';
    this.progressValue = 5;
    this.user = this.loggedInUser;
    if (this.user.role == 'cme') {
      this.user.role = null;
    }
  },
};
</script>

<style>
.select-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #e5e7ee;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  padding: 1rem;
  margin-bottom: 20px;
  color: #5b6678;
  text-decoration: none;
  transition: transform 0.2s ease-out;
  font-weight: 500;
  font-size: 1rem;
  text-align: left;
}

.select-button-small {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #e5e7ee;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  padding: 0.6rem 1rem;
  color: #5b6678;
  text-decoration: none;
  transition: transform 0.1s ease-out;
  font-weight: 500;
  font-size: 0.9rem;
  text-align: left;
  min-width: 220px;
}

.select-button > i {
  opacity: 0;
  margin-right: 0.5rem;
  transition: all 0.1s ease-in 0s;
}
.select-button:hover > i {
  opacity: 1;
  margin-right: 0;
}

.seperator-small {
  background-color: #f84e62;
  height: 3px;
  width: 2rem;
  border-radius: 40px;
}

.mb-8 > .p-float-label {
  padding-left: 0;
  padding-right: 0;
}

.animate-in {
  animation: in 0.3s ease-in-out;
}
.animate-out {
  animation: out 0.3s ease-in-out;
}

@keyframes in {
  0% {
    opacity: 0;
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-1rem);
  }
}

.min-h-100 {
  min-height: 100vh;
}
.split-bg {
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}

.p-progressbar {
  background-color: #d2d7dc;
  height: 10px;
}

.p-progressbar .p-progressbar-value {
  background-color: #f84e62;
}
</style>
