<template>
  <div class="main-inner-wrap privacy-content-left">
    <h2>VIRTUAL HALLWAY GENERAL TERMS AND CONDITIONS</h2>
    <p class="privacy-content-item privacy-sub-heading"><span>Important notice:</span><span>&nbsp;</span><span>Please be aware that section 10 contains important disclaimers and limitations of liability.</span><span>&nbsp;</span><span>Please read these Terms and Conditions carefully before accessing or using the Website. </span></p>
    <p class="privacy-content-item">
      <span>These General Terms and Conditions (“Terms'' or “Terms and Conditions”) constitute a legally binding contract between you (“User”,&nbsp;“You”&nbsp;or&nbsp;“Your”&nbsp;) and Virtual Hallway Consults Inc, established under the federal laws of Canada with its registered office in Halifax, Nova Scotia, Canada. (“Virtual Hallway”, “Us”, “We”, or “Our”). </span><span><a href="https://www.google.com/url?q=https://virtualhallway.ca&amp;sa=D&amp;source=editors&amp;ust=1621895114867000&amp;usg=AOvVaw34UVJrl26Pa8A0TdCj6_P2">www.virtualhallway.ca</a></span
      ><span>&nbsp;is the Website operated by Virtual Hallway. The Website also contains Our proprietary software as a service platform designed to virtually connect health care providers and specialist physicians (“Platform”). </span>
    </p>
    <p class="privacy-content-item">
      <span>These Terms apply to all visitors and users of the Website, including Registered Users and Unregistered Users. Registered Users are users of the Website who also sign up for or subscribe to the Platform. Unregistered Users are Website visitors who do not register an account on the Website. If You are using or accessing the Website on behalf of another person or legal entity, You represent and warrant that You are an authorized representative of such person or entity. </span>
    </p>
    <p class="privacy-content-item"><span>You agree that you have read and accept these Terms by accessing or browsing through the Website. We may amend these Terms and Conditions from time to time. Amendments will be effective upon Our posting of such updated Terms and Conditions at this webpage. Your continued access or use of the Website after such changes constitutes Your consent to be bound by these Terms, as amended.</span><span>&nbsp;</span></p>
    <ol class="privacy-number-title" start="1">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Privacy Policy.</span><span>&nbsp;We take industry standard security measures to protect personally identifiable information and personal health information (collectively “Personal Information”) under Our control from unauthorized disclosure, loss, misuse, and alteration. Personal information provided on the website are transmitted through a secure server using Secure Socket Layering (SSL) encryption technology. Important information about Our practices on the collection, use and disclosure of Users’ personal information is governed by Our General Privacy Policy (</span><span><a href="https://www.google.com/url?q=https://virtualhallway.ca/privacy-policy/&amp;sa=D&amp;source=editors&amp;ust=1621895114869000&amp;usg=AOvVaw0tpI6TSDTHlO3S1lq3ZFNy">www.virtualhallway.ca/privacy-policy/</a></span
        ><span>), which forms a part of these Terms. </span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="2">
      <li class="privacy-content-item"><span class="privacy-sub-heading">Additional Terms </span><span class="privacy-sub-heading">and</span><span class="privacy-sub-heading">&nbsp;Policies.</span><span>&nbsp;</span></li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>In addition to these Terms and General Privacy Policy, Users may subject to additional terms and policies agreed in separate agreements with Virtual Hallway, including but not limited to End User License Agreement (“EULA”) and Platform Privacy Policy. In the event of any conflict, terms and policies of these separate agreements will prevail.</span>
      </li>
      <li class="privacy-letter-list">
        <span>For greater certainty, you acknowledge that You will be subject to the EULA and Platform Privacy Policy if You sign up for or subscribe to the Platform. In the event of any conflict, the terms and conditions of the EULA and Platform Privacy Policy will supercede and prevail any terms and conditions hereunder. </span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="3">
      <li class="privacy-content-item"><span class="privacy-sub-heading">Account Registration.</span><span>&nbsp;</span></li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>You must register an account (“Virtual Hallway Account”) to access and use the Platform. If You are registering a Virtual Hallway Account for a business, organization or other legal entity, You represent and warrant that You have the authority to legally bind that entity and grant Us all permissions and licenses provided in these Terms. </span>
      </li>
      <li class="privacy-letter-list">
        <span>You must provide accurate, current and complete information during the registration process and keep Your Virtual Hallway Account and its profile page information up to date at all times.</span>
      </li>
      <li class="privacy-letter-list">
        <span>You may not register more than one (1) Virtual Hallway Account unless Virtual Hallway authorizes You to do so. You may not assign or otherwise transfer Your Virtual Hallway Account to another party.&nbsp;</span>
      </li>
      <li class="privacy-letter-list">
        <span>You are responsible for maintaining the confidentiality and security of Your Virtual Hallway Account credentials and may not disclose Your credentials to any third party. You must immediately notify Virtual Hallway if You know or have any reason to suspect that Your credentials have been lost, stolen, misappropriated, or otherwise compromised or in case of any actual or suspected unauthorized use of Your Virtual Hallway Account. You are liable for any and all activities conducted through Your Virtual Hallway Account.&nbsp;We are not liable for any loss or damages arising from Your failure to safeguard Your Virtual Hallway Account credentials.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="4">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Using the Website Functionalities</span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>You represent and warrant that all information supplied by You, including any personal information, on the Website is true, accurate, current and complete. </span>
      </li>
      <li class="privacy-letter-list">
        <span>You represent and warrant that you have received proper consent from third-parties if any information supplied by you contains personal information of these third-parties.</span>
      </li>
      <li class="privacy-letter-list">
        <span>As an Unregistered User, you may only use the Website for the permitted purposes list below:</span>
      </li>
    </ol>
    <ol type="i" start="1">
      <li class="privacy-roman-numerals">
        <span>create a Virtual Hallway Account for signing up or subscribing to the Platform;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>browse general information on the Website; and</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>use Our contact form to contact Virtual Hallway.</span>
      </li>
    </ol>
    <ol type="a" start="4">
      <li class="privacy-letter-list">
        <span>In addition to the permitted purposes listed in Section 4(c), Registered User may access &nbsp;and use the Platform after we approve Your Virtual Hallway Account registration, in accordance with applicable additional terms and policies such as the EULA and Platform Privacy Policy.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Subject to applicable privacy law and data retention policies, You understand and agree that We reserve the sole and absolute right to modify, cancel, suspend, delete, and terminate Your Virtual Hallway Account, restrict or suspend Your access to Your Virtual Hallway Account, or reject Your Virtual Hallway Account registration for any or no reason, including but not limited to:</span>
      </li>
    </ol>
    <ol type="i" start="1">
      <li class="privacy-roman-numerals">
        <span>violations of these Terms and Conditions;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>violation of any law, regulation, or professional code of conduct; and</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>our provision of the services to You is no longer commercially viable.</span>
      </li>
    </ol>
    <ol type="a" start="6">
      <li class="privacy-letter-list">
        <span>We will provide You with 30 days’ written notice before cancelling Your Virtual Hallway Account and any rights and obligations hereunder, at Our sole discretion.</span>
      </li>
      <li class="privacy-letter-list">
        <span>You are prohibited to: </span>
      </li>
    </ol>
    <ol type="i" start="1">
      <li class="privacy-roman-numerals">
        <span>use the Website for unlawful purposes;</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>remove any proprietary notices, including copyright and trademark notices, from the Website; </span>
      </li>
      <li class="privacy-roman-numerals">
        <span>reproduce, modify, prepare derivative works, license, distribute, lease, sell or resell, transfer, publicly display or perform, transmit, stream, broadcast or otherwise exploit the Website and its contents, unless we grant You a written permission; </span>
      </li>
      <li class="privacy-roman-numerals">
        <span>reverse engineer, recompile or disassemble the Website; </span>
      </li>
      <li class="privacy-roman-numerals">
        <span>link to, mirror or frame any portion of the Website unless we grant You a written permission; and</span>
      </li>
      <li class="privacy-roman-numerals">
        <span>unduly impair any aspect or functionality of the Website or its related systems or networks, including data mining and attempt to gain unauthorized access.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="5">
      <li class="privacy-content-item"><span class="privacy-sub-heading">Ending Relationship.</span><span>&nbsp;</span></li>
    </ol>
    <ol type="a" start="8">
      <li class="privacy-letter-list">
        <span>We may terminate these Terms at any time at Our discretion by providing notice to Your email address on Your Virtual Hallway Account information profile. </span>
      </li>
      <li class="privacy-letter-list">
        <span>For Registered Users, You may delete Your Virtual Hallway Account by emailing Your intention to Our support email: support@virtualhallway.ca. Subject to these Terms, EULA, Our General and Platform Privacy Policies, and any legal requirement, we may keep Your information for tax, privacy, and securities reasons, fraud prevention, and other lawful purposes for the duration as we determine to be necessary to fulfill Our legal obligations and business purposes. </span>
      </li>
      <li class="privacy-letter-list"><span>Please note that if You do not agree to these Terms, You must stop browsing or using the </span><span>Website</span><span>, or otherwise You will be deemed to accept these Terms and Conditions.</span></li>
    </ol>
    <ol class="privacy-number-title" start="6">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Our Intellectual Property Rights. </span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>No licenses or rights are granted to You by implication or otherwise under any intellectual property rights owned or controlled by Virtual Hallway, except for the rights expressly granted in these Terms and Conditions.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Virtual Hallway hereby grants You a revocable, non-exclusive, and non-transferable license solely to browse and use the Website in accordance with these Terms and Conditions. </span>
      </li>
      <li class="privacy-letter-list">
        <span>You agree that the Website and its entire contents, features, and functionalities, including but not limited to designs, graphics, pictures, illustrations, software, artwork, video, sound, audio, texts, names, words, titles, phrases, logos and marks (collectively “Contents”) displayed on the Site are owned by Virtual Hallway, Our licensors, or other providers of such material and protected by copyright, trademark and any other applicable laws. All trademarks, service marks, logos, trade names, and any other source identifiers of Virtual Hallway used on or in connection with the Virtual Hallway Platform and Virtual Hallway Website are trademarks or registered trademarks of Virtual Hallway.</span>
      </li>
      <li class="privacy-letter-list">
        <span>You will not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the Website except to the extent as expressly permitted in these Terms. </span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="7">
      <li class="privacy-content-item"><span class="privacy-sub-heading">No Provision of Advice or Services. &nbsp;</span><span>You should seek legal counsel regarding any legal and compliance issues and should not rely on any materials or contents associated with Virtual Hallway’s Website in determining compliance obligations under the applicable law. You agree that Virtual Hallway is not providing to any party any medical or legal advice.</span></li>
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">User Content. </span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>Each User is solely responsible for all Contents User uploaded, emailed, or, in any method, transmitted to the Website or Virtual Hallway (“User Content”). Subject to other agreements between You and Us, We shall not be liable under any circumstances in any way for any User Content, including for any loss or damage of any kind incurred as a result of or otherwise relates to the viewing or use of the Website. </span>
      </li>
      <li class="privacy-letter-list">
        <span>User Content shall not include Personal Information unless the information is necessary for the purpose of communicating between You and Us. We adhere to Our Privacy Policy to the portion of Your User Content that contains Personal Information. </span>
      </li>
      <li class="privacy-letter-list">
        <span>For any User Content You upload or otherwise make available to us, You grant Virtual Hallway, a perpetual, non-exclusive, worldwide, royalty-free, transferable, and fully sublicensable license to use, refrain from using, remove, reproduce, modify, edit, copy, adapt, publish, translate, create derivative works from, distribute, transmit, perform display and otherwise use such User Content, in whole or in part. You represent and warrant that You have all the consents, rights, power and authority necessary to grant the license granted herein to any User Content that You submit. </span>
      </li>
      <li class="privacy-letter-list">
        <span>You understand and agree that we do not guarantee the accuracy, integrity or quality of such Content. </span>
      </li>
      <li class="privacy-letter-list">
        <span>We are not obligated to post or display any User Content on the Website. We reserve the sole and absolute right to remove or modify Your User Content, including such content that violates applicable law, intellectual property rights, or affects Our ordinary business operation. We will not be liable to You or any third-party for any suspension or termination of Your access or removing any of Your User Content. </span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="9">
      <li class="privacy-content-item"><span class="privacy-sub-heading">Indemnification. &nbsp;</span><span>User</span><span>&nbsp;</span><span>shall indemnify and hold Virtual Hallway and its partners, employees, and agents harmless against all liabilities, losses, claims, damages, costs and expenses of any nature whatsoever incurred as a consequence of its non-observance of any regulations or as required with regards to or in connection with the use of the Website.</span></li>
      <li class="privacy-content-item"><span class="privacy-sub-heading">Warranties and Limitation of Liabilities.</span><span>&nbsp;</span></li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>Each party represents and warrants that it has the full right, power, and authority to enter into and perform its obligations under These Terms without breaching any obligation to any third party.</span>
      </li>
      <li class="privacy-letter-list">
        <span class="privacy-sub-heading">Except as expressly set forth herein, to the extent permitted by applicable law, Virtual Hallway hereby expressly disclaims all warranties and conditions, express, implied or statutory, </span><span class="privacy-sub-heading"><a href="https://www.google.com/url?q=https://www.lawinsider.com/clause/liability-limitations&amp;sa=D&amp;source=editors&amp;ust=1621895114877000&amp;usg=AOvVaw2k4toyfQ_-tmzn0enVwT3g">arising from</a></span
        ><span class="privacy-sub-heading">&nbsp;or otherwise relating to the use of the Website,</span><span>&nbsp;</span><span class="privacy-sub-heading">including but not limited to any implied warranties or conditions of merchantability, title, satisfactory quality or results, or fitness for a particular purpose and non-infringement. </span>
      </li>
      <li class="privacy-letter-list">
        <span>Virtual Hallway does not guarantee that the Website will meet Your requirements, will be available on an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe. </span>
      </li>
      <li class="privacy-letter-list"><span>Virtual Hallway</span><span>&nbsp;does not endorse the content on any third-party websites. </span><span>Virtual Hallway</span><span>&nbsp;is not responsible for the content of linked third-party websites, or third-party advertisements, and does not make any representations regarding their content or accuracy. Your use of third-party websites is at Your own risk and subject to the terms and conditions and privacy policy of use for such websites.</span></li>
      <li class="privacy-letter-list">
        <span>Virtual Hallway is not responsible for the accuracy, integrity, and quality of the information provided on the Website, including but not limited to the FAQ. User must not treat such information as medical, professional, or legal advice.</span>
      </li>
      <li class="privacy-letter-list">
        <span class="privacy-sub-heading">VIRTUAL HALLWAY SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, LIABILITY OR LOSSES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY OR DEATH, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE ARISING OUT OF: (I) Your USE OF OR RELIANCE ON THE WEBSITE OR Your INABILITY TO ACCESS OR USE THE WEBSITE; AND (II) ANY USER CONTENT You MAKE AVAILABLE TO Us. </span>
      </li>
      <li class="privacy-letter-list">
        <span class="privacy-sub-heading">IN NO EVENT SHALL VIRTUAL HALLWAY’S TOTAL LIABILITY TO YOU IN CONNECTION WITH THE WEBSITE FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED FIFTY (50) CANADIAN DOLLARS. </span>
      </li>
      <li class="privacy-letter-list">
        <span>The limitations and disclaimer in this section do not purport to limit liability or alter Your rights as a consumer that cannot be excluded under applicable law.</span>
      </li>
    </ol>
    <ol class="privacy-number-title" start="11">
      <li class="privacy-content-item">
        <span class="privacy-sub-heading">Miscellaneous</span>
      </li>
    </ol>
    <ol type="a" start="1">
      <li class="privacy-letter-list">
        <span>These Terms shall be governed by the provincial laws of the province(s) in which the User has a license to practice and the federal laws of Canada applicable therein, whose provincial Courts shall have the sole jurisdiction to adjudicate claims made in respect of these Terms. </span>
      </li>
      <li class="privacy-letter-list">
        <span>These Terms comprises the entirety of the contract between the parties with respect to the subject matter hereof, and Virtual Hallway shall not be held liable in respect of any representation not made in writing herein. </span>
      </li>
      <li class="privacy-letter-list">
        <span>Virtual Hallway’s failure to insist upon or enforce strict performance of any provision of these Terms and Conditions will not be construed as a waiver of any provisions or right.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Virtual Hallway may, in its sole discretion, provide notices or communications to You by email at the then-current email address for You on file with Virtual Hallway. Virtual Hallway may be contacted in writing at the email address: support@virtualhallway.ca. Virtual Hallway may change its notice contact information from time to time by posting updated contact details on the Website.</span>
      </li>
      <li class="privacy-letter-list">
        <span>All references in these Terms to “dollars” or “$” are to Canadian dollars, unless otherwise stated.</span>
      </li>
      <li class="privacy-letter-list">
        <span>You may not assign any of Your rights hereunder without the prior written consent of Virtual Hallway. </span>
      </li>
      <li class="privacy-letter-list">
        <span>If any term or provision of these Terms is invalid, illegal or unenforceable in any jurisdiction, such invalidity, illegality or unenforceability will not affect any other term or provision of these Terms or invalidate or render unenforceable such term or provision in any other jurisdiction. </span>
      </li>
      <li class="privacy-letter-list">
        <span>The parties agree that irreparable damage would occur if any provision of these Terms &nbsp;was not performed in accordance with the terms hereof and that the parties are entitled to equitable relief, including injunctive relief or specific performance of the terms hereof, in addition to any other remedy to which they are entitled at law or in equity.</span>
      </li>
      <li class="privacy-letter-list">
        <span>Virtual Hallway shall not be liable for any loss, damage, destruction or unreasonable delays caused by events it cannot control: (a) acts of God, the Queen’s or public enemies, extreme weather conditions, pandemic outbreaks; (b) riots, civil commotions; (c) strikes, mechanical delays; (d) authority of law; and (e) nuclear reaction, radiation or radioactive contamination.</span>
      </li>
    </ol>
    <p class="privacy-content-item"><span>Page </span><span>&nbsp;of </span></p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
