<template>
  <div v-if="responseForm.submitted && (referral.interaction_type.name === 'phone' || referral.interaction_type.name === 'econsult')" id="showReferralResponseFormParentDiv">
    <div class="mb-2 grid grid-cols-2 gap-2 text-left">
      <div class="border-b border-solid border-gray-100 pb-1" v-if="referral.interaction_type.name === 'phone'">
        <div class="consult-field-label">Call Start Time</div>
        <div class="consult-field-text">
          <p v-if="responseForm.call_start_time" id="callStartTime">
            {{ formatTime(responseForm.call_start_time) }}
          </p>
          <p v-else>N/A</p>
        </div>
      </div>
      <div class="border-b border-solid border-gray-100 pb-1" v-if="referral.interaction_type.name === 'phone'">
        <div class="consult-field-label">Call End Time</div>
        <div class="consult-field-text">
          <p v-if="responseForm.call_end_time" id="callEndTime">
            {{ formatTime(responseForm.call_end_time) }}
          </p>
          <p v-else>N/A</p>
        </div>
      </div>
      <div class="col-start-1 col-end-3 border-b border-solid border-gray-100 pb-1" v-if="referral.diagnostic_code">
        <div class="consult-field-label">Diagnostic Code</div>
        <div class="consult-field-text">
          <p id="diagnosis">
            {{ referral.diagnostic_code.code_text }}
          </p>
        </div>
      </div>
      <div class="col-start-1 col-end-3 border-b border-solid border-gray-100 pb-1" v-if="responseForm.diagnosis">
        <div class="text-xs font-normal text-gray-400">Diagnosis</div>
        <div class="consult-field-text">
          <p id="diagnosis">
            {{ responseForm.diagnosis }}
          </p>
        </div>
      </div>
      <div class="col-start-1 col-end-3 border-b border-solid border-gray-100 pb-1">
        <div class="consult-field-label" v-if="hasProvinceOfPractice(referral.specialty, 'NS')">Discussion Details</div>
        <div class="consult-field-label" v-else>Opinion/Recommendation</div>
        <div class="consult-field-text">
          <pre v-if="responseForm.discussion_details" id="discussionDetails"
            >{{ responseForm.discussion_details }}
          </pre>
          <p v-else>N/A</p>
        </div>
      </div>

      <div v-if="isGastro">
        <div class="col-start-1 col-end-3 border-b border-solid border-gray-100 pb-1">
          <div class="consult-field-label">NSH Encounter Number</div>
          <div class="consult-field-text">
            <p v-if="responseForm.nsh_encounter_number" id="nsh_encounter_number">{{ responseForm.nsh_encounter_number }}</p>
            <p v-else>N/A</p>
          </div>
        </div>
        <div class="col-start-1 col-end-3 border-b border-solid border-gray-100 pb-1">
          <div class="consult-field-label">NSHA Visit Type</div>
          <div class="consult-field-text">
            <p v-if="responseForm.nsh_visit_type" id="nsh_visit_type">{{ responseForm.nsh_visit_type }}</p>
            <p v-else>N/A</p>
          </div>
        </div>
        <div class="col-start-1 col-end-3 border-b border-solid border-gray-100 pb-1">
          <div class="consult-field-label">MRN</div>
          <div class="consult-field-text">
            <p v-if="responseForm.mrn" id="mrn">{{ responseForm.mrn }}</p>
            <p v-else>N/A</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else id="no-followup" :class="[referral.status === 'Cancelled' || referral.status === 'Declined' ? 'border-red-400 text-red-400' : 'text-vh-gray  border-vh-gray mb-4']">
    <div v-if="referral.status !== 'Cancelled' && referral.status !== 'Declined'">
      <template v-if="referral.interaction_type.name === 'phone' && referral.call_asap == false">
        <i class="pi pi-clock mb-2" style="font-size: 3.5rem"></i>
        <div v-if="referral.status === 'In Review'">
          <h4>In Review</h4>
          <p class="m-4 text-sm">Your consult is currently being reviewed by the specialist. Once reviewed, the consult response will be made available here.</p>
        </div>
        <div v-else>
          <h4>Upcoming Consult <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="1" /></h4>
          <p>{{ referral.consult_start }} on {{ referral.consult_date }}</p>
        </div>
      </template>
      <template v-if="referral.interaction_type.name === 'phone' && referral.call_asap == true">
        <i class="fas fa-bolt mb-2" style="font-size: 3.5rem"></i>
        <h4>Upcoming OnDemand Consult <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="1" /></h4>
        <p>Latest call by {{ formatDateTime(referral.end_datetime) }}</p>
      </template>
      <template v-if="referral.interaction_type.name === 'econsult'">
        <i class="pi pi-envelope mb-2" style="font-size: 3.5rem"></i>
        <h4>Consult In Review <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="1" /></h4>
      </template>
    </div>
    <div v-else-if="referral.status === 'Cancelled'">
      <i class="pi pi-ban mb-2" style="font-size: 3.5rem"></i>
      <h4>This consult has been cancelled. <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="1" /></h4>
      <div class="text-sm text-gray-500">Reason for Cancellation: {{ referral.reason_for_failed }}</div>
      <div class="text-sm text-gray-500" v-if="referral.cancellation_details">Cancellation Details: {{ referral.cancellation_details }}</div>
    </div>
    <div v-else-if="referral.status === 'Declined'">
      <i class="pi pi-ban mb-2" style="font-size: 3.5rem"></i>
      <h4>This referral has been declined. <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="1" /></h4>
      <span class="font-medium text-gray-400">Declined Reason: {{ referral.reason_for_failed }}</span>
    </div>
  </div>

  <AddendumsComponent :type="'referral_response_form'" :referral="referral" :addendums="referralResponseFormAddendums" :responseForm="responseForm" />
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import Loading from 'vue-loading-overlay';
import moment from 'moment';
import Message from 'primevue/message';

export default {
  props: ['referral'],
  components: {
    Button,
    Textarea,
    Loading,
    Message,
  },
  data() {
    return {
      showResponseForCancel: false,
      responseForm: this.referral.referral_response_form,
      referralForm: this.referral.referral_form,
      openAddendum: false,
      addendum: { addendum_date_time: null, addendum_details: null },
      addendumText: '',
      addendumError: '',
      isGastro: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'referralAddendums', 'hasProvinceOfPractice', 'canEditReferralForm', 'canEditReferralResponseForm']),
    referralResponseFormAddendums() {
      return this.referralAddendums.filter((addendum) => addendum.type === 'referral_response_form');
    },
  },
  created() {
    const gastro = this.loggedInUser.org_units.find((org_unit) => org_unit.name === 'Division of Digestive Care and Endoscopy');
    if (gastro != null) {
      this.isGastro = true;
    }
  },
  methods: {
    ...mapActions(['createReferralAddendum', 'setReferralToRebook']),
    canMakeAddendum() {
      return true;
    },
    processTabs(event) {
      let textarea = document.getElementById(event.target.id);
      let start = textarea.selectionStart;
      textarea.value = textarea.value.substring(0, textarea.selectionStart) + '\t' + textarea.value.substring(textarea.selectionStart, textarea.value.length);
      event.target.selectionStart = event.target.selectionEnd = start + 1;
    },
    checkIfLessThanOneDayAfterReferralCall() {
      return moment().diff(moment(this.referral.start_datetime), 'days') === 0;
    },
  },
};
</script>

<style>
.seperator-light {
  height: 1px;
  margin-bottom: 5px;
  border-radius: 100px;
  background-color: #eff4fb;
  margin-top: 10px;
  width: 100%;
  margin-right: auto;
}

#no-followup {
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  width: 100%;
  border-width: 3px;
  border-radius: 10px;
  border-style: solid;
  background-color: #ffffff;
  min-height: 272px;
  padding-top: 87px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 25px;
}

.p-button.response-buttons {
  display: block;
  width: 100%;
}

/* .p-button.p-component.cancel-btn{
  background-color: #98aed5;
  border-color: #98aed5;
} */
.p-button.p-component.cancel-btn:hover {
  background-color: red;
  border-color: red;
}
</style>
