<template>
  <Dialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :visible="showModal" :modal="true" :dismissableMask="true" :draggable="false" :closable="false" id="faxConsultDialog" style="max-width: 480px">
    <template #header>
      <div class="flex flex-row items-center">
        <i class="pi pi-file-pdf mr-2 text-gray-600" style="font-size: 1.3rem" />
        <h1 v-if="this.fax_document == 'consult_request'">Fax Consult Request PDF</h1>
        <h1 v-else-if="this.fax_document == 'consult_report'">Fax Consult Report PDF</h1>
        <h1 v-else-if="this.fax_document == 'smart_path_result'">Fax Smart Path Result</h1>
        <h1 v-else-if="this.fax_document == 'encounter_note'">Fax Encounter Note</h1>
      </div>
    </template>

    <div class="flex w-full flex-col p-5">
      <div v-if="whitelist.length == 0" class="mb-3 text-sm">Add a verified fax contact to send a copy of your documents to your EMR or Fax Machine. <TutorialVideoDialog video="using_autofax" linkLabel="Click here to watch a video on how it works." /></div>
      <div v-else class="tip-title mb-3 text-sm">Select a verified fax contact to send a copy of your documents to your EMR or Fax Machine.</div>

      <template v-if="whitelist.length > 0 && !displayAddFax">
        <div class="pb-4">
          <label class="auth-input" for="phoneNumber">Verified Fax Contacts</label>
          <Dropdown class="auth-input" v-model="faxNumber" optionLabel="contact_name" :options="whitelist" id="fax" @change="selectFaxNumber()" />
        </div>
        <div>
          <label class="auth-input" for="phoneNumber">Fax Number</label>
          <span class="w-full">
            <InputMask disabled type="tel" mask="(999)-999-9999" id="phoneNumber" name="phoneNumber" v-model="selectedFaxNumber" placeholder="(999)-999-9999" maxlength="16" required :autoClear="false" class="auth-input p-inputtext-lg" />
          </span>
        </div>
        <br />
      </template>

      <template v-if="displayAddFax">
        <form method="post" @submit.prevent="addFaxNumberOnSubmit" class="mb-4">
          <div class="mb-4 grid grid-cols-2 gap-1 gap-x-3">
            <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
              <label class="auth-input" for="firstName">Contact Name <span class="error">&nbsp;*</span> </label>
              <InputText v-model="inputContactName" class="auth-input" id="contactName" required maxLength="55" />
            </div>
            <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
              <label for="faxNumber" class="auth-input" v-if="!phoneNumberError">Fax Number <span class="error">&nbsp;*</span></label>
              <label for="faxNumber" class="auth-input error text-left" v-else>Fax number must be a 10 digits</label>
              <InputMask :autoClear="false" type="tel" id="faxNumber" name="faxNumber" v-model="inputFaxNumber" class="auth-input" required maxLength="16" mask="(999)-999-9999" />
            </div>
            <div class="gap-x-3 mt-2 flex flex-row" :class="whitelist.length > 0 ? 'col-span-2' : 'col-start-2'">
              <Button @click="displayAddFax = false" label="Back" icon="pi pi-angle-left" class="p-button-outlined p-button-secondary w-full" v-if="whitelist.length > 0"></Button>
              <Button type="submit" label="Submit" icon="pi pi-plus" class="w-full" id="confirmContactButton"></Button>
            </div>
          </div>
        </form>
      </template>
      <div class="flex flex-row justify-end" v-if="!displayAddFax">
        <div class="py-2">
          <Button label="Add Fax Number" icon="pi pi-plus" class="p-button-secondary" @click="displayAddFax = true" />
        </div>
      </div>
    </div>

    <template #footer v-if="whitelist.length > 0 && !displayAddFax">
      <div class="flex flex-row justify-end">
        <div class="mr-2">
          <Button label="Close" icon="pi pi-times" class="p-button-outlined p-button-secondary" @click="closeDialog()" />
        </div>
        <div class="-mr-1">
          <Button label="Send Fax" v-if="whitelist.length > 0 && !displayAddFax" icon="pi pi-wifi" class="p-button" @click="faxPdfCloseDialog()" />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';
import Dialog from 'primevue/dialog';
import { mapActions, mapGetters } from 'vuex';
import Dropdown from 'primevue/dropdown';
import TutorialVideoDialog from '@/components/misc/TutorialVideoDialog.vue';

export default {
  components: {
    Button,
    InputMask,
    Dialog,
    Dropdown,
    TutorialVideoDialog,
  },
  props: ['show', 'documentId', 'fax_document', 'displayEditFaxOption'],
  emits: ['closeFaxConsultDialog'],
  data() {
    return {
      showModal: false,
      isLoading: false,
      faxNumber: null,
      displayAddFax: false,
      inputFaxNumber: '',
      inputContactName: '',
    };
  },
  computed: {
    ...mapGetters(['actingUser', 'whitelist']),
  },
  created() {
    this.getWhitelistedFaxNumbers(this.actingUser.id)
      .then((response) => {
        if (this.whitelist.length > 0) {
          this.faxNumber = this.whitelist[0];
          this.selectFaxNumber();
        }
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });
    this.isLoading = false;
  },
  methods: {
    ...mapActions(['faxPdfOfReferralForm', 'faxPdfOfReferralResponseForm', 'getWhitelistedFaxNumbers', 'faxSmartPathResult', 'faxCombinedReferralForms', 'createWhitelistedFaxNumber']),
    closeDialog() {
      this.displayAddFax = false;
      this.$emit('closeFaxConsultDialog');
    },
    selectFaxNumber() {
      this.selectedFaxNumber = this.faxNumber.fax_number.replace(/[^0-9]/g, '');
    },
    faxPdfCloseDialog() {
      this.error = '';
      if (this.fax_document == 'consult_request') {
        this.faxPdfOfReferralForm({
          referralFormId: this.documentId,
          fax_number: this.selectedFaxNumber,
          fax_document: this.fax_document,
        })
          .then(() => {
            this.showFaxSuccessToast();
            this.$emit('closeFaxConsultDialog');
          })
          .catch(() => {
            this.showGenericErrorToast();
          });
      } else if (this.fax_document == 'consult_report') {
        this.faxPdfOfReferralResponseForm({
          referralResponseFormId: this.documentId,
          fax_number: this.selectedFaxNumber,
          fax_document: this.fax_document,
        })
          .then(() => {
            this.showFaxSuccessToast();
            this.$emit('closeFaxConsultDialog');
          })
          .catch(() => {
            this.showGenericErrorToast();
          });
      } else if (this.fax_document == 'smart_path_result') {
        this.faxSmartPathResult({
          transaction_id: this.documentId,
          fax_number: this.selectedFaxNumber,
        })
          .then(() => {
            this.showFaxSuccessToast();
            this.$emit('closeFaxConsultDialog');
          })
          .catch(() => {
            this.showGenericErrorToast();
          });
      } else if (this.fax_document == 'encounter_note') {
        this.faxCombinedReferralForms({
          referral_id: this.documentId,
          fax_number: this.selectedFaxNumber,
        })
          .then(() => {
            this.showFaxSuccessToast();
            this.$emit('closeFaxConsultDialog');
          })
          .catch(() => {
            this.showGenericErrorToast();
          });
      }
    },
    showFaxSuccessToast() {
      this.$toast.removeAllGroups();
      this.$toast.add({
        severity: 'success',
        summary: 'File Faxed',
        detail: 'Please allow up to 5 minutes for fax to be processed.',
        life: 10000,
      });
    },
    addFaxNumberOnSubmit() {
      this.validatePhoneNumber();
      if (!this.phoneNumberError) {
        this.$toast.removeAllGroups();
        this.isLoading = true;
        let user_data = { user_id: this.actingUser.id, contact_name: this.inputContactName, fax_number: this.inputFaxNumber };
        this.inputFaxNumber = '';
        this.inputContactName = '';
        this.createWhitelistedFaxNumber(user_data)
          .then(() => {
            this.displayAddFax = false;
            this.isLoading = false;

            if (this.whitelist.length > 0) {
              this.faxNumber = this.whitelist[this.whitelist.length - 1];
              this.selectFaxNumber();
            }
            this.$toast.add({
              severity: 'success',
              summary: 'Contact Saved',
              detail: 'Contact information has been successfully updated.',
              life: 3000,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Invalid Input',
              detail: 'Please make sure you have entered a contact name.',
              life: 5000,
            });
          });
      }
    },
    validatePhoneNumber() {
      this.inputFaxNumber = this.inputFaxNumber.replace(/[^0-9]/g, '');
      if (!/[0-9]/.test(this.inputFaxNumber) || this.inputFaxNumber.length != 10) {
        this.phoneNumberError = true;
      } else {
        this.phoneNumberError = false;
      }
    },
  },
  watch: {
    show: {
      handler() {
        this.showModal = !this.showModal;
      },
    },
  },
};
</script>
<style>
#faxConsultDialog .p-dialog-content {
  padding: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
