<template>
  <div v-if="user && isSpecialist(loggedInUser)" class="text-left">
    <div class="mt-2 mb-4 flex flex-row items-center justify-center text-gray-600">
      <i class="pi pi-phone" style="font-size: 1.3rem" />
      <h1 class="mx-2 text-lg font-bold">Phone Consult Preferences <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="9" /></h1>
      <div class="h-1 flex-grow bg-gray-600"></div>
    </div>
    <Message severity="info" :closable="false" class="text-left">Consult Duration is the length of your Phone Consult time slots. Consult Buffer is how far in advance primary care practitioners are permitted to book with you. </Message>
    <form method="post" @submit.prevent="submit" class="pb-4 mb-10">
      <div class="mb-4 grid grid-cols-2 gap-1">
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input">Consult Duration (Minutes)</label>
          <InputNumber v-model="user.specialist_preference.referral_duration_in_minutes" showButtons class="auth-input" v-tooltip.bottom="'Consult Duration is amount of time the phone consult will be booked for.'" :min="5" :max="30" :step="5" :inputId="'consultDuration'" />
        </div>
        <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
          <label class="auth-input">Consult Buffer (Hours)</label>
          <InputNumber v-model="user.specialist_preference.referral_time_range_in_hours" showButtons class="auth-input" v-tooltip.bottom="'Consult Buffer is the minimum amount of time before a phone consult can be booked.'" :min="1" :inputId="'consultBuffer'" />
        </div>
      </div>
      <div class="flex justify-end">
        <Button type="submit" label="Confirm Changes" class="p-button-sm" icon="pi pi-check" id="confirmChangesButton"></Button>
      </div>
    </form>

    <div class="w-full border-bottom border-red-100 border-1 mb-12"></div>

    <div class="mt-2 flex flex-row items-center justify-center text-gray-600">
      <i class="fas fa-bolt" style="font-size: 1.3rem" />
      <div>
        <h1 class="mx-2 text-lg font-bold">OnDemand Consult Settings <ToggleSupportSectionButton :openedSupportSectionKnowledgebaseAccordionIndex="10" /></h1>
      </div>
      <div class="h-1 flex-grow bg-gray-600 mr-4"></div>
      <TutorialVideoDialog video="on_demand_specialist" buttonLabel="Watch How This Works" />
    </div>
    <Message severity="info" :closable="false" class="text-left">OnDemand Consults are unscheduled phone consults that provide flexibility for your schedule. You can offer consultations on your own time, with a timeframe committed by you. Opt-in and set your preferences below, then click Confirm Changes.</Message>
    <form method="post" @submit.prevent="submit" class="pb-4">
      <div class="flex flex-col">
        <div class="info-paragraph--gray">
          <div class="flex flex-row">
            <Checkbox id="binary" v-model="user.specialist_preference.call_asap_optin" :binary="true" />
            <label for="binary" class="ml-2">I would like to enable OnDemand Consults (Allow unscheduled consult requests)</label>
          </div>
        </div>

        <div class="mb-4 grid grid-cols-2 gap-8">
          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input">My Maximum Upcoming OnDemand Consults</label>
            <InputNumber v-model="user.specialist_preference.call_asap_limit" showButtons class="auth-input" :min="5" :max="30" :step="5" :inputId="'callAsapLimitInput'" />
            <p class="text-xs mt-2">This is the the maximum number of Upcoming OnDemand consults you want to allow in your inbox at a given time.</p>
          </div>

          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input">My Turnaround Response Time (in days)</label>
            <InputNumber v-model="user.specialist_preference.call_asap_turnaround_time_in_days" showButtons class="auth-input" :min="1" :max="30" :step="1" :inputId="'callAsapTurnaroundTime'" />
            <p class="text-xs mt-2">This is the timeframe in which you commit to completing an OnDemand consult. You are welcome to call any time within this timeframe. We ask that you always attempt to call at least three times per consult before cancelling.</p>
          </div>
        </div>

        <div class="flex justify-end">
          <Button type="submit" label="Confirm Changes" class="p-button-sm" icon="pi pi-check" id="confirmChangesButton2"></Button>
        </div>
      </div>
    </form>

    <template v-if="displayEconsult() && hasProvinceOfPractice(loggedInUser, ['ON'])">
      <div class="mt-2 flex flex-row items-center justify-center text-gray-600">
        <h1 class="mx-2 text-lg font-bold flex flex-row">
          eConsult<span class="flex"><i class="fa fa-plus text-pink-600 flex pt-0.5" style="font-size: 0.7rem; font-weight: 900"></i>&nbsp;</span> Preferences
        </h1>
        <div class="h-1 flex-grow bg-gray-600"></div>
      </div>
      <Message severity="info" :closable="false" class="text-left">Consult Duration is the length of your Phone Consult time slots. Consult Buffer is how far in advance primary care practitioners are permitted to book with you. </Message>
      <form method="post" @submit.prevent="submit" class="pb-4">
        <div class="mb-4 grid grid-cols-2 gap-1">
          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input mb-[1px]">eConsult Opt-In</label>
            <div class="flex flex-row info-paragraph--gray !py-[14px]">
              <Checkbox inputId="econsultOptin" name="econsultOptin" v-model="user.specialist_preference.econsult_optin" :binary="true" />
              <label for="econsultOptin" class="ml-2"> Check this box to start accepting eConsults.</label>
            </div>
          </div>
          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input">eConsult Limit</label>
            <InputNumber v-model="user.specialist_preference.econsult_limit" showButtons class="auth-input" v-tooltip.bottom="'eConsult Limit is the maximum of unfinished eConsults requests that can be booked with you at any given time.'" :min="0" :max="100" id="econsultLimit" />
          </div>
        </div>
        <div class="flex justify-end">
          <Button type="submit" label="Confirm Changes" class="p-button-sm" icon="pi pi-check" id="confirmChangesButton"></Button>
        </div>
      </form>
    </template>
  </div>
  <div v-if="errors.length != 0">
    <ul v-for="(error, i) in errors" :key="i" class="error">
      <li>{{ error }}</li>
    </ul>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import Loading from 'vue-loading-overlay';
import TutorialVideoDialog from '@/components/misc/TutorialVideoDialog.vue';

export default {
  components: {
    Button,
    Checkbox,
    Message,
    Loading,
    InputNumber,
    InputSwitch,
    Dropdown,
    TutorialVideoDialog,
  },
  emits: ['selectInfo'],
  props: ['loggedInUser'],
  data() {
    return {
      user: this.loggedInUser,
      errors: [],
      phoneNumberError: false,
      phoneNumberWitMask: '',
      isLoading: false,
      file: null,
      rerender: false,
    };
  },
  computed: {
    ...mapGetters(['isSpecialist', 'hasProvinceOfPractice', 'tutorialStatus', 'availableZones']),
  },
  methods: {
    ...mapActions(['updateSpecialistPreference', 'getZonesByProvince']),
    ...mapMutations(['setTutorialStatus']),
    submit() {
      if (this.isSpecialist(this.loggedInUser)) {
        this.updateSpecialistPreference(this.user)
          .then(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'success',
              summary: 'Account Updated',
              detail: 'Your account preferences have been successfully updated.',
              life: 5000,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error updating your account, please try again later or contact support@virtualhallway.ca.',
              life: 10000,
            });
          });
      }
    },
  },
  created() {
    this.isLoading = true;
    this.getZonesByProvince(this.loggedInUser.practice_province.id)
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error loading available zones, please try again later or contact support@virtualhallway.ca.',
          life: 10000,
        });
      });
  },
};
</script>

<style scoped>
.auth-input.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-left: -1px;
}
.auth-input.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -1px;
}

.in-person-referral-opt-in-tool-tip {
  position: absolute;
  width: 300px;
  background-color: var(--vh-new-feature-blue);
  top: -180px;
  left: 0;
  border-radius: 6px;
  z-index: 10;
}

.in-person-referral-opt-in-tool-tip::after {
  transform: rotate(0deg);
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.in-person-referral-opt-in-tool-tip-btn {
  background-color: #005d9b;
  @apply h-6 w-14 rounded;
}
</style>
