<template>
  <div class="main-inner-wrap" v-if="!isLoading">
    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block"><i class="pi pi-list mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Share Consults</h1>
        <h1 class="text-sm text-gray-200">Search the table below for the consult you want to share, click the checkboxes to select those consults, then click the share button after selecting the practice group you wish to share them with.</h1>
      </div>
    </div>
    <div v-if="allReferrals.length < 1">
      <div class="mt-12"></div>
      <p>
        <span v-if="isGP(loggedInUser)">
          You do not have any consultations. Click on the
          <span class="p-text-bold">Book a Consult</span>
          button in the top right corner to schedule your first consultation.
        </span>
        <span v-else-if="isSpecialist(loggedInUser)">
          You do not have any consultations. Click on the
          <span class="p-text-bold">Availability</span>
          button in the top right corner to add availability.
        </span>
        <span v-else> No consultations to show. </span>
      </p>
    </div>
    <div class="w-full pt-4" v-else>
      <ShareReferralOrgUnitTable :referrals="allReferrals" :isSharing="true" />
    </div>
    <Dialog header="Auto Share Consults" v-model:visible="defaultShareModal" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '40vw', maxWidth: '450px' }" :modal="true" :draggable="false">
      <div class="m-0 flex flex-col justify-between">
        <p class="mb-6 text-sm">Select which Practice Group will have access to your new consults.</p>
        <div class="w-full">
          <Dropdown v-model="selectedOrgUnitId" :options="shareOrgUnitOptions" optionValue="id" optionLabel="name" id="selectOrgUnit" class="auth-input w-full text-xs font-normal uppercase text-gray-400" />
        </div>
      </div>
      <template #footer>
        <Button @click="submitUpdateProfile" id="confirmChangesButton">Confirm Changes</Button>
      </template>
    </Dialog>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ShareReferralOrgUnitTable from '@/components/referral/ShareReferralOrgUnitTable.vue';
import Dialog from 'primevue/dialog';
import Loading from 'vue-loading-overlay';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';

export default {
  components: {
    Loading,
    ShareReferralOrgUnitTable,
    Dialog,
    Dropdown,
    Button,
  },
  data() {
    return {
      isLoading: false,
      defaultShareModal: false,
      selectedOrgUnitId: 0,
      shareOrgUnitOptions: [],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'allReferrals', 'isGP', 'isSpecialist', 'isOrgUnitHead', 'actingUser']),
  },
  methods: {
    ...mapActions(['getAllReferralsOfUser', 'getReferralByOrgUnitId', 'updateUserProfileInfo']),
    submitUpdateProfile() {
      this.isLoading = true;
      this.$toast.removeAllGroups();
      let profile = this.loggedInUser.profile;
      profile.org_unit_referral_access_preference = this.selectedOrgUnitId;
      profile.user_id = this.loggedInUser.id;
      this.updateUserProfileInfo(profile)
        .then(() => {
          this.defaultShareModal = false;
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Account Updated',
            detail: 'Your account information has been successfully updated.',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
  created() {
    this.isLoading = true;
    let userId = this.actingUser.id;
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.getReferralByOrgUnitId(this.loggedInUser.org_units[0].id)
        .then(() => (this.isLoading = false))
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    } else {
      this.getAllReferralsOfUser({
        user_id: userId,
        required_relations: ['referral_form', 'org_units', 'specialty', 'interaction_type'],
      })
        .then(() => {
          this.isLoading = false;
          if (this.$route.query.org_unit_id) {
            this.shareOrgUnitOptions = JSON.parse(JSON.stringify(this.loggedInUser.org_units));
            this.shareOrgUnitOptions.push({ id: 0, name: 'Private, Do Not Share' });
            if (this.loggedInUser.profile.org_unit_referral_access_preference) {
              this.selectedOrgUnitId = this.loggedInUser.profile.org_unit_referral_access_preference;
            }
            this.defaultShareModal = true;
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    }
  },
};
</script>

<style></style>
