<template>
  <div class="main-inner-wrap">
    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-gray-700 p-6 text-left shadow">
      <div class="hidden lg:block"><i class="pi pi-users mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i></div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Invite a Family Doctor!</h1>
        <h1 class="text-sm text-gray-200">Complete the form below in order to invite a family doctor to Virtual Hallway! They will be sent an email with a verification link to set up their account.</h1>
      </div>
    </div>
    <div class="lg:max-w-8xl flex w-full max-w-full items-start justify-center px-5 pt-8 pb-20 text-center">
      <div name="slide-fade">
        <div :class="['registration-form-card', 'shadow', 'card', 'rounded-lg', 'p-8']">
          <div class="m-auto mb-8 flex w-full p-2 text-center">
            <h2 class="mb-2 mt-1 flex-col text-xl font-bold text-blue-500">Invite a Family Doctor</h2>
            <i class="fas fa-user-doctor text-blue-500 ml-4" />
          </div>
          <form method="post" @submit.prevent="submit" class="grid grid-cols-2 gap-y-7 gap-x-1">
            <div class="col-start-1 col-end-3 sm:col-auto mb-2">
              <span class="p-float-label">
                <InputText type="text" id="firstName" name="firstName" v-model="user.first_name" required class="w-full" maxLength="55" />
                <label for="firstName" class="">First Name </label>
              </span>
            </div>

            <div class="col-start-1 col-end-3 sm:col-auto mb-2">
              <span class="p-float-label">
                <InputText type="text" id="lastName" name="lastName" v-model="user.last_name" required class="w-full" maxLength="55" />
                <label for="lastName" class="">Last Name </label>
              </span>
            </div>

            <div class="col-start-1 col-end-3 sm:col-auto mb-2">
              <span class="p-float-label">
                <InputText type="email" name="email" id="email" v-model="user.email" required :class="['w-full', { 'p-invalid': confirmEmailError }]" @change="validateEmail" maxLength="255" />
                <label for="email" class="">Email </label>
              </span>
            </div>

            <div class="col-start-1 col-end-3 sm:col-auto mb-2">
              <span class="p-float-label">
                <InputText type="email" name="confirmEmail" id="confirmEmail" v-model="user.email_confirmation" required :class="['w-full', { 'p-invalid': confirmEmailError }]" @change="validateEmail" maxLength="255" />
                <label for="confirmEmail" class="text-left">Confirm Email </label>
              </span>
              <span class="registration-error" v-if="confirmEmailError"> Emails Do Not Match </span>
            </div>

            <div class="col-start-1 col-end-3 sm:col-auto mb-2">
              <span class="p-float-label">
                <InputMask type="tel" mask="(999)-999-9999" id="phoneNumber" name="phoneNumber" v-model="phoneNumberWithMask" :required="true" :autoClear="false" :class="['w-full', { 'p-invalid': phoneNumberError }]" @change="validatePhoneNumber" maxlength="16" />
                <label for="phoneNumber" class="">Cell Number </label>
              </span>
            </div>

            <div class="col-start-1 col-end-3 sm:col-auto mb-2">
              <span class="p-float-label">
                <InputMask type="tel" mask="(999)-999-9999" id="confirmPhoneNumber" name="confirmPhoneNumber" v-model="confirmPhoneNumberWithMask" :required="true" :autoClear="false" :class="['w-full', { 'p-invalid': phoneNumberError }]" @change="validatePhoneNumber" maxlength="16" />
                <label for="confirmPhoneNumber" class="">Confirm Cell Number </label>
              </span>
              <span class="registration-error" v-if="phoneNumberError">
                {{ phoneNumberErrorMsg }}
              </span>
            </div>

            <div class="col-span-2 mx-2">
              <Button type="submit" id="submit" class="p-button-lg w-full text-center text-xl" label="Invite"></Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <Loading z-index="99" v-model:active="isLoading" color="#5887c7" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Loading from 'vue-loading-overlay';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';

export default {
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        phone_number: '',
        confirm_phone_number: '',
        email: '',
        email_confirmation: '',
      },
      phoneNumberWithMask: '',
      confirmPhoneNumberWithMask: '',
      phoneNumberError: false,
      phoneNumberErrorMsg: '',
      phoneCountryCodes: [{ name: '+1 (CAN/USA)', value: '1' }],
      confirmEmailError: false,
      firstEmailChange: false,
      firstPhoneNumberChange: false,
      isLoading: false,
    };
  },
  components: {
    Loading,
    Button,
    Dialog,
    InputText,
    InputMask,
  },
  computed: {
    ...mapGetters(['loggedInUser', 'tutorialStatus']),
  },
  methods: {
    ...mapActions(['addUserAsOrgUnitHead', 'requestToAddExistingUserToOrgUnit', 'inviteUserAsOrgUnitHead']),
    ...mapMutations(['setTutorialStatus']),
    submit() {
      this.error = '';
      this.isLoading = true;
      let data = {};
      data = this.user;
      data.province_id = 6;
      data.role = 'gp';
      this.inviteUserAsOrgUnitHead(data)
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'User Invited!',
            detail: 'The user has been sucessfully invited! They will receive an email to set up their Virtual Hallway account shortly.',
            life: 3000,
          });

          this.$router.push({ path: `/manageusers` });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },

    validateEmail() {
      if (this.firstEmailChange) {
        if (this.user.email !== this.user.email_confirmation) {
          this.confirmEmailError = true;
        } else {
          this.confirmEmailError = false;
        }
      } else {
        this.firstEmailChange = true;
      }
    },

    validatePhoneNumber() {
      if (this.firstPhoneNumberChange) {
        this.phoneNumberError = false;
        this.phoneNumberErrorMsg = '';
        this.user.phone_number = this.phoneNumberWithMask.replace(/[^0-9]/g, '');
        this.user.confirm_phone_number = this.confirmPhoneNumberWithMask.replace(/[^0-9]/g, '');
        if (!/[0-9]/.test(this.user.phone_number) || this.user.phone_number.length != 10 || !/[0-9]/.test(this.user.confirm_phone_number) || this.user.confirm_phone_number.length != 10) {
          this.phoneNumberError = true;
          this.phoneNumberErrorMsg = 'Must be 10 digits. ';
        }
        if (this.user.phone_number !== this.user.confirm_phone_number) {
          this.phoneNumberError = true;
          this.phoneNumberErrorMsg += 'Cell numbers do not match.';
        }
      } else {
        this.firstPhoneNumberChange = true;
      }
    },
  },
};
</script>

<style scoped>
.process-circle-arrow {
  background-color: var(--vh-medium-gray);
  color: var(--vh-white);
  font-size: 22px;
  border-radius: 100px;
  box-shadow: 0px 8px 7px 0px rgb(0 0 0 / 6%);
}
.process-circle-arrow.active {
  background-color: var(--vh-blue);
  color: var(--vh-white);
}
.process-circle-arrow-icon {
  padding: 20px;
}
.process-circle-arrow-icon.active {
  transform: rotateX(180deg);
  transition: transform 0.3s;
}

.process-dropdown-text {
  margin-top: 10px;
  text-align: left;
  padding: 0px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  transition: opacity 0.5s ease;
  height: 0px;
}
.process-dropdown-text.active {
  visibility: visible;
  opacity: 1;
  height: 100%;
  padding: 30px;
}

.registration-form-card {
  margin: 0;
  max-width: 645px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(400px);
  opacity: 0;
}

.tooltip {
  position: relative;
}

.tooltip .org-add-user-tooltiptext {
  width: 300px;
  background-color: #fff;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 100px;
  left: -250px;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: var(--vh-new-feature-blue);
}

.tooltip .org-add-user-tooltiptext::after {
  transform: rotate(270deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 102%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--vh-new-feature-blue) transparent transparent transparent;
}

.p-float-label label {
  margin-left: 0.5rem;
}
.p-inputtext,
.p-dropdown,
.p-multiselect {
  background-color: #fafafa !important;
}

.fa-user-doctor {
  font-size: 2rem !important;
}
</style>
