<template>
  <Button @click="sendBugReport" id="sendBugReportBtn" class="w-full p-button-secondary" label="Report a Bug" />
</template>

<script>
import { mapGetters } from 'vuex';
import Button from 'primevue/button';
import * as Sentry from '@sentry/vue';

export default {
  emits: ['toggleHelp'],
  components: {
    Button,
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  data() {
    return {
      feedback: null,
    };
  },
  created() {
    this.feedback = Sentry.feedbackIntegration({
      autoInject: false,
    });
  },
  methods: {
    sendBugReport() {
      //Initialize User
      Sentry.setUser({
        fullName: this.loggedInUser.full_name,
        email: this.loggedInUser.email,
      });
      //Initialize Form
      const sentryFormWidget = this.feedback.createWidget({
        autoInject: false,
        showBranding: false,
        triggerLabel: 'Feedback',
        buttonLabel: '',
        submitButtonLabel: 'Submit',
        formTitle: 'Bug Report',
        showName: false,
        showEmail: false,
        messagePlaceholder: 'Briefly describe your issue',
        useSentryUser: {
          name: 'fullName',
          email: 'email',
        },
        themeLight: {
          submitBackground: '#2c283e',
          submitBorder: '#2c283e',
          submitBackgroundHover: '#2a263bd6',
        },

        //Form Callbacks
        onFormOpen: () => {
          this.$emit('toggleHelp');
        },
        onFormClose: () => {
          sentryFormWidget.removeFromDom();
        },
        onSubmitSuccess: () => {
          sentryFormWidget.removeFromDom();
          // needs type checking to work
          this.$toast.add({
            severity: 'success',
            summary: 'Bug Report Submitted',
            detail: 'Thank you for your feedback',
            life: 5000,
          });
        },
      });
      //Open Form
      sentryFormWidget.openDialog();
    },
  },
};
</script>

<style>
/* Doesnt work */
#sentry-feedback {
  --accent-background: #2c283e;
}
</style>
