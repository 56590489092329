<template>
  <div class="text-center align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 lg:text-left shadow">
    <div class="hidden lg:flex"><i class="pi pi-download mr-5 text-white" style="font-size: 2.5rem"></i></div>
    <div class="flex flex-col lg:flex-row w-full text-left">
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">Billing</h1>
        <h1 class="text-sm text-gray-200">View consults ready to export for billing and consults you have already billed for.</h1>
      </div>
      <div class="flex flex-col lg:w-64 lg:ml-auto lg:border-l lg:pl-6 mt-2 lg:mt-0 items-center lg:justify-end" v-if="isPhysician(loggedInUser) && loggedInUser?.practice_province?.billing_opt_in">
        <h1 class="text-sm text-gray-200">
          Would you like Virtual Hallway to bill on your behalf?
          <i class="pi pi-question-circle icon-grey ml-1" v-tooltip.bottom="'Every FFS physician has authorization to bill. Some physicians under AFP/APP arrangements may also bill over their contractual obligations. If uncertain, please refer to your department for clarification.'"></i>
        </h1>
        <Button label="Opt In" class="p-button-outlined !text-white p-button-sm !mt-2 hover:!text-black max-w-[256px] w-full" @click="optIntoBillingOnClick"></Button>
      </div>
    </div>
  </div>

  <div v-if="referrals.length < 1">
    <p class="mt-12">
      <span> No consults ready to export.</span>
    </p>
  </div>
  <div class="w-full pt-4" v-else>
    <DataTable :value="referrals" :paginator="true" class="billing-export-table p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" v-model:filters="filters1" filterDisplay="menu" :filters="filters1" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10, 25, 50, 100]" v-model:selection="selectedReferrals" :globalFilterFields="['start_datetime', 'referral_form.patient_full_name', 'referee.full_name', 'referral_person.full_name']">
      <template #header>
        <div class="p-d-flex p-jc-between p-flex-column p-flex-sm-row">
          <h1 class="fc-toolbar-title mt-2 mb-2 md:mb-0">Select which consults you would like to export to bill yourself.</h1>
          <div class="flex flex-row items-center">
            <Button icon="pi pi-external-link" label="Export" class="p-button mr-2" @click="exportBilling" :disabled="selectedReferrals.length === 0" id="billingExportButton" />
            <IconField iconPosition="left">
              <InputIcon class="pi pi-search"> </InputIcon>
              <InputText v-model="filters1['global'].value" placeholder="Search" class="w-full" maxLength="255" />
            </IconField>
          </div>
        </div>
      </template>
      <template #empty> No Consults found. </template>
      <template #loading> Loading Consult data. Please wait. </template>
      <Column selectionMode="multiple" style="width: 2rem" headerStyle="width: 3em"></Column>

      <Column field="referral_form.patient_full_name" header="Patient"> </Column>

      <Column field="specialty.name" header="Specialty" v-if="isGP(loggedInUser) || isOrgUnitHead(loggedInUser)"> </Column>

      <Column field="referee.full_name" header="Referring Practitioner" style="min-width: 1rem">
        <template #body="{ data }">
          <span class="p-column-title">Full Name</span>
          {{ data.referee_full_name }}
        </template>
      </Column>

      <Column field="referral_person.full_name" header="Specialist" style="min-width: 1rem">
        <template #body="{ data }">
          <span class="p-column-title">Full Name</span>
          {{ data.referral_person_full_name }}
        </template>
      </Column>

      <Column field="start_datetime" dataType="date" header="Consult Date" style="max-width: 8rem">
        <template #filter="{ filterModel }">
          <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" :show="(selectedReferrals = [])" />
        </template>
        <template #body="{ data }">
          <span class="p-column-title">Date/Time</span>
          <span v-if="data.interaction_type.name === 'phone'"> {{ formatDate(data.start_datetime) }}</span>
        </template>
      </Column>

      <Column field="user_shadow_bill_export" style="min-width: 1rem" header="Export Status" :showFilterMatchModes="false">
        <template #filter="{ filterModel }">
          <Dropdown v-model="filterModel.value" :options="exportOptions" placeholder="Any" class="p-column-filter" :showClear="true" optionLabel="name" optionValue="value" @change="selectedReferrals = []">
            <template #value="slotProps">
              <span :class="'status-badge text-black ' + exportOptions.find((option) => option.value === slotProps.value).name" v-if="slotProps.value != null">{{ exportOptions.find((option) => option.value === slotProps.value).displayName }}</span>
              <span v-else>{{ slotProps.placeholder }}</span>
            </template>
            <template #option="slotProps">
              <span :class="'status-badge text-white ' + slotProps.option.name">{{ slotProps.option.displayName }}</span>
            </template>
          </Dropdown>
        </template>
        <template #body="{ data }">
          <div class="status-badge" :class="data.user_shadow_bill_export ? 'Exported' : 'Ready'">
            {{ data.user_shadow_bill_export ? 'Exported' : 'Ready to Export' }}
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import { FilterOperator } from 'primevue/api';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';

import Loading from 'vue-loading-overlay';

export default {
  components: {
    Loading,
    Column,
    DataTable,
    Calendar,
    InputText,
    Button,
    Dropdown,
  },
  data() {
    return {
      isLoading: true,
      filters1: null,
      selectedReferrals: [],
      exportOptions: [
        { value: true, name: 'Exported', displayName: 'Exported' },
        { value: false, name: 'Ready', displayName: 'Ready to Export' },
      ],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'allReferrals', 'isGP', 'isSpecialist', 'isOrgUnitHead', 'actingUser', 'orgUnitUsers', 'hasProvinceOfPractice', 'isPhysician']),
    referrals() {
      if (this.isOrgUnitHead(this.loggedInUser)) {
        return this.allReferrals
          .filter((referral) => referral.status === 'Complete')
          .filter((referral) => referral.interaction_type.name !== 'encounter_note')
          .filter((referral) => this.orgUnitUsers.find((user) => user.id === referral.referee_id)?.billing_opt_in === false || this.orgUnitUsers.find((user) => user.id === referral.referral_person_id)?.billing_opt_in === false)
          .map((referral) => {
            const user_shadow_bill_export = this.orgUnitUsers.find((user) => user.id === referral.referee_id) ? referral.billing?.referee_shadow_bill_export : referral.billing?.referral_person_shadow_bill_export;
            return { ...referral, user_shadow_bill_export };
          });
      }

      return this.allReferrals
        .filter((referral) => referral.status === 'Complete')
        .filter((referral) => referral.interaction_type.name !== 'encounter_note')
        .map((referral) => {
          const user_shadow_bill_export = this.loggedInUser.id === referral.referee_id ? referral.billing?.referee_shadow_bill_export : referral.billing?.referral_person_shadow_bill_export;
          return { ...referral, user_shadow_bill_export };
        });
    },
  },
  methods: {
    ...mapActions(['getAllReferralsOfUser', 'getReferralByOrgUnitId', 'getAllProvinces', 'getOrgUnitUsers', 'exportBillingCsv', 'updateUserAccountInfo']),
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        start_datetime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        user_shadow_bill_export: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    exportBilling() {
      let data = [];
      if (this.isOrgUnitHead(this.loggedInUser)) {
        data = this.selectedReferrals.map((referral) => {
          const user_id = this.orgUnitUsers.find((user) => user.id === referral.referee_id) ? referral.referee_id : referral.referral_person_id;
          return { referral_id: referral.id, user_id };
        });
      } else {
        data = this.selectedReferrals.map((referral) => {
          return { referral_id: referral.id, user_id: this.loggedInUser.id };
        });
      }
      this.isLoading = true;
      this.exportBillingCsv({ referrals: data })
        .then(() => {
          this.selectedReferrals = [];
          this.resetReferrals();
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    resetReferrals() {
      this.isLoading = true;
      if (this.isOrgUnitHead(this.loggedInUser)) {
        this.getReferralByOrgUnitId(this.loggedInUser.org_units[0].id)
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
        this.getOrgUnitUsers(this.loggedInUser.org_units[0].id)
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      } else {
        this.getAllReferralsOfUser({
          user_id: this.actingUser.id,
          required_relations: ['referral_form', 'interaction_type', 'specialty', 'org_units', 'billing', 'referee', 'referral_person'],
        })
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showGenericErrorToast();
          });
      }
    },
    optIntoBillingOnClick() {
      this.isLoading = true;
      this.updateUserAccountInfo({ ...this.actingUser, billing_opt_in: true })
        .then(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: 'success',
            summary: 'Successfully Opted Into Billing',
            detail: 'Account information has been successfully updated.',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
  },
  created() {
    this.initFilters1();
    this.getAllProvinces();
    this.resetReferrals();
    if (this.isOrgUnitHead(this.loggedInUser)) {
      this.getOrgUnitUsers(this.loggedInUser.org_units[0].id)
        .then(() => {})
        .catch(() => {
          this.showGenericErrorToast();
        });
    }
  },
};
</script>

<style>
.billing-export-table.p-datatable .p-column-filter {
  display: block;
}

.status-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  text-align: center;
}

.status-badge.Exported {
  background: #5d97f7;
  color: #fff;
}
.status-badge.Ready {
  background: #ff8366;
  color: #fff;
}
</style>
