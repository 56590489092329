<template>
  <modal ref="timezoneModal" style="padding-top: 100px">
    <template v-slot:header></template>
    <template v-slot:body>
      <div class="flex w-full justify-center">
        <div class="relative z-20 -mt-10 flex h-20 w-20 justify-center rounded-full border-4 border-solid border-white bg-yellow-300">
          <i class="pi pi-exclamation-circle my-auto text-white" style="font-size: 3rem" />
        </div>
      </div>
      <div class="flex w-full justify-center rounded-b-xl px-8 py-4">
        <div class="flex w-full max-w-lg flex-col">
          <h1 class="mb-6 text-xl font-bold">It looks like you are outside your default timezone</h1>
          <p class="mb-4 px-4 text-sm">All consult times displayed in app will be in your current location timezone. Any notification we send you will be in your default timezone. You can update your default timezone below.</p>
          <div class="col-start-1 col-end-3 mb-2 sm:col-auto">
            <label class="auth-input">Account Timezone</label>
            <Dropdown class="auth-input" v-model="default_timezone" :options="timezoneOptions" id="timezone" optionLabel="name" optionValue="value" />
          </div>
          <div class="flex flex-col items-center justify-between py-4 pb-4 md:flex-row">
            <div class="grid sm:grid-auto md:grid-cols-2 gap-4 mb-0">
              <Button class="p-button-outlined p-button-secondary w-60" label="Close" @click="closeModal" style="margin-right: 5px" id="closeModal" />
              <Button class="ml-1 w-60" label="Update" @click="updateTimezone" id="updateTimezone" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import Modal from '@/components/misc/Modal.vue';
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';
import Dropdown from 'primevue/dropdown';

export default {
  props: ['openModal'],
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  components: {
    Modal,
    Button,
    Loading,
    Dropdown,
  },
  data() {
    return {
      isLoading: false,
      timezoneOptions: [
        { name: 'Newfoundland Time', value: 'America/St_Johns' },
        { name: 'Atlantic Time', value: 'America/Halifax' },
        { name: 'Eastern Time', value: 'America/Toronto' },
        { name: 'Central Time, Winnipeg', value: 'America/Winnipeg' },
        { name: 'Central Time, Regina', value: 'America/Regina' },
        { name: 'Mountain Time', value: 'America/Edmonton' },
        { name: 'Pacific Time', value: 'America/Vancouver' },
      ],
      default_timezone: '',
    };
  },
  methods: {
    ...mapActions(['updateUserAccountInfo']),
    closeModal() {
      this.$refs.timezoneModal.closeModal();
    },
    updateTimezone() {
      this.isLoading = true;
      this.updateUserAccountInfo({ ...this.loggedInUser, default_timezone: this.default_timezone })
        .then(() => {
          this.isLoading = false;
          this.closeModal();
          this.$toast.add({
            severity: 'success',
            summary: 'Timezone Updated',
            detail: 'Account information has been successfully updated.',
            life: 5000,
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    setInitialDefaultTimezone() {
      const now = new Date();
      const nowLocalTime = now.toLocaleString('en-US', { timeZone: this.loggedInUser.default_timezone });
      if (nowLocalTime === now.toLocaleString('en-US', { timeZone: 'America/St_Johns' })) {
        this.default_timezone = 'America/St_Johns';
      } else if (nowLocalTime === now.toLocaleString('en-US', { timeZone: 'America/Halifax' })) {
        this.default_timezone = 'America/Halifax';
      } else if (nowLocalTime === now.toLocaleString('en-US', { timeZone: 'America/Toronto' })) {
        this.default_timezone = 'America/Toronto';
      } else if (nowLocalTime === now.toLocaleString('en-US', { timeZone: 'America/Winnipeg' })) {
        this.default_timezone = 'America/Winnipeg';
      } else if (nowLocalTime === now.toLocaleString('en-US', { timeZone: 'America/Edmonton' })) {
        this.default_timezone = 'America/Edmonton';
      } else if (nowLocalTime === now.toLocaleString('en-US', { timeZone: 'America/Vancouver' })) {
        this.default_timezone = 'America/Vancouver';
      }
      if (this.loggedInUser.default_timezone === 'America/Regina' || this.loggedInUser.default_timezone === 'America/Swift_Current') {
        this.default_timezone = 'America/Regina';
      }
    },
  },
  mounted() {
    if (this.openModal && !this.isDemoAccount(this.loggedInUser)) {
      this.setInitialDefaultTimezone();
      this.$refs.timezoneModal.openModal();
    }
  },
  watch: {
    openModal(val) {
      if (val && !this.isDemoAccount(this.loggedInUser)) {
        this.setInitialDefaultTimezone();
        this.$refs.timezoneModal.openModal();
      }
    },
  },
};
</script>

<style>
.billing-arrangement-content {
  text-align: start;
  font-size: 14px;
}
.billing-arrangement-content p,
.billing-arrangement-content label {
  padding-top: 8px;
}
.billing-arrangement-container {
  padding: 0 20px 0 20px;
}
.billing-arrangement-rows {
  justify-content: space-between;
}
.billing-arrangement-side-by-side {
  width: 48%;
}
.signature-agreement-text {
  font-size: 13px;
}
#signature {
  padding-bottom: 10px;
  margin-right: 60px;
}
.billing-arrangement-footer {
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  display: flex;
}
</style>
