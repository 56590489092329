import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { globalMixin } from '../../mixins';

const state = {
  allInteractionTypes: [],
};

const getters = {
  allInteractionTypes: (state) => {
    return state.allInteractionTypes;
  },
};

// actions
const actions = {
  getAllInteractionTypes(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(globals.APIs.getAllInteractionTypesUrl(), {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('setAllInteractionTypes', response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setAllInteractionTypes(state, data) {
    state.allInteractionTypes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
