<template>
  <div class="animated fade-in-up animation-delay rounded-xl tile relative flex flex-col justify-between w-full bg-white shadow cursor-pointer">
    <Skeleton height="16rem" class="w-full v-r-top relative h-64 overflow-hidden" style="overflow: hidden !important"></Skeleton>
    <div class="rounded-b-xl grow flex flex-col justify-between p-4 text-left text-gray-600">
      <div class="flex flex-col font-bold">
        <div class="text-md font-display pb-2 mb-2 border-b">
          <Skeleton height="1.3rem" width="20rem" class="mb-2"></Skeleton>
          <Skeleton height="1.3rem" width="18rem"></Skeleton>
        </div>
        <div class="border-round flex flex-row items-center justify-between w-full">
          <div class="flex flex-col text-sm">
            <Skeleton height="1.2rem" width="16rem" class="w-full mt-1"></Skeleton>
          </div>
        </div>
      </div>
      <Skeleton height="2.4rem" class="w-full"></Skeleton>
    </div>
  </div>
</template>

<script>
export default {};
</script>
