<template>
  <div class="main-inner-wrap" v-if="!isLoading">
    <div class="align-start relative mt-4 mb-1 flex w-full flex-row items-start justify-start rounded-2xl bg-secondary remove-later p-6 text-left shadow">
      <div class="hidden lg:block">
        <i class="pi pi-list mb-2 mt-2 mr-5 text-white" style="font-size: 2.5rem"></i>
      </div>
      <div class="flex flex-col">
        <h1 class="mb-2 text-xl font-bold text-white md:text-2xl">My Pathway Results</h1>
        <h1 class="text-sm text-gray-200">The table below shows your own Smartpaths.</h1>
      </div>
    </div>
    <div v-if="transactionHistory.length < 1">
      <p class="mt-8">You do not have any completed pathways. You can find a pathway under a Specialist's booking card in Book A Consult.</p>
    </div>
    <div class="w-full pt-4" v-else>
      <!-- <SmartPathList :smartPaths="smartPaths" /> -->
      <SmartPathTransactionHistoryList :transactions="transactionHistory" />
    </div>
  </div>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SmartPathTransactionHistoryList from '@/components/gp/smartpath/SmartPathTransactionHistoryList.vue';
import Loading from 'vue-loading-overlay';

export default {
  components: {
    SmartPathTransactionHistoryList,
    Loading,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isSpecialist', 'actingUser', 'transactionHistory', 'selectedSmartPath']),
  },
  methods: {
    ...mapActions(['saveSmartPath', 'getSmartPathTransactionHistoryOfUser']),
  },
  created() {
    this.isLoading = true;
    this.getSmartPathTransactionHistoryOfUser(this.actingUser.id)
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.showGenericErrorToast();
      });
  },
};
</script>

<style></style>
