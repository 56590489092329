<template>
  <div class="flex flex-row items-start justify-between" v-if="!isLoading">
    <div class="flex flex-row mb-2">
      <div class="flex items-center">
        <i class="pi pi-file"></i>
      </div>
      <div class="ml-2 text-blue-400">
        <h2 class="text-left font-semibold text-base text-gray-600">Related Resources</h2>
      </div>
    </div>
  </div>
  <div class="mb-5">
    <div class="border-b border-solid border-gray-100 py-2 flex flex-row justify-between items-center" v-for="(file, index) in referralResponseFormRelatedFiles" :key="file.id">
      <div class="flex flex-col justify-start text-start">
        <div class="flex w-full items-center mb-1 text-sm text-gray-500 font-display cursor-pointer" @click="clickDownloadResponseFormRelatedFile(file.id)">
          <div class="font-semibold">
            {{ index + 1 }}. <template v-if="file.title">{{ file.title }}</template
            ><template v-else>{{ file.original_file_name }}</template>
          </div>
          <div class="text-xs ml-1" v-if="file.type">({{ file.type }} - {{ file.year }})</div>
        </div>
        <div class="text-xs text-gray-500">{{ formatReadableDateTime(file.created_at) }}</div>
      </div>
      <div>
        <Button v-if="canDownloadResponseFormRelatedFile(loggedInUser, referral)" icon="pi pi-download" class="p-button-rounded p-button-secondary p-button-text" @click="clickDownloadResponseFormRelatedFile(file.id)" v-tooltip.bottom="'Click to download this file.'" :id="'downloadResponseFormRelatedFileButton-' + index" />
        <Button
          v-if="canEditReferralResponseForm(loggedInUser, referral.referral_person, referral)"
          icon="pi pi-trash"
          class="p-button-rounded p-button-danger p-button-text"
          @click="
            () => {
              showConfirmDeleteResponseFormRelatedFileDialog = true;
              selectedFileToDelete = file.id;
            }
          "
          v-tooltip.bottom="'Click to delete this file.'"
          :id="'deleteResponseFormRelatedFileButton-' + index"
        />
      </div>
    </div>
  </div>
  <template v-if="referralResponseFormRelatedFiles.length < 1">
    <Message severity="info" :closable="false" class="text-left">
      <span v-if="isSpecialist(actingUser)"> Here, you can </span>
      <span v-else>Here, the {{ getReferralPersonTerm(actingUser) }} can </span>
      <span>further enhance your consults by sharing valuable articles, guidelines, or materials that relate to the consult.</span>
    </Message>
  </template>
  <div class="flex flex-row justify-end">
    <Button v-if="canEditReferralResponseForm(loggedInUser, referral.referral_person, referral)" icon="pi pi-upload" label="Upload Related Resource(s)" class="p-button-outlined text-sm" @click="showUploadRelevantFilesModal = true" v-tooltip.bottom="'Click here to upload document(s) and image(s) relevant to this consult.'" id="uploadReferralResponseRelatedFileButton" />
  </div>
  <Dialog v-model:visible="showUploadRelevantFilesModal" modal header="Upload Relevant Resource(s)" :key="'responseFormRelatedFileDialog' + fileUploadDialogIndex" class="md:w-1/2 sm:w-full" :dismissable-mask="true" :draggable="false" id="uploadResponseFormRelatedFileModal" @after-hide="hideUploadFileDialog">
    <FileUpload name="referral_response_related_files[]" :customUpload="true" @uploader="submitUploadReferralResponseFormRelatedFiles($event)" @clear="() => (fileProperties = [])" :showUploadButton="false" @select="appendFileToRequest($event)" :multiple="true" accept="image/*,application/pdf, .doc, .docx" :maxFileSize="20000000" :file-limit="maxFileLimit" :previewWidth="100" id="uploadResponseFormRelatedFileInput" :chooseLabel="'Add File'" @before-upload="() => false">
      <template #empty>
        <div v-if="referralResponseFormRelatedFileUploadSuccess" id="uploadResponseFormRelatedFileSuccessMessageDiv">
          <div class="flex flex-col p-0 sm:p-5 content-center place-items-center">
            <div class="flex flex-row border border-green-500 rounded-full h-16 w-16 justify-center">
              <i class="pi pi-check text-green-500 place-self-center" style="font-size: 2rem"></i>
            </div>
            <div class="mt-5 font-semibold text-gray-600">{{ referralResponseFormRelatedFileUploadSuccess }}</div>
          </div>
        </div>
        <div v-else-if="referralResponseFormRelatedFileUploadError" id="uploadResponseFormRelatedFileErrorMessageDiv">
          <div class="flex flex-col p-0 sm:p-5 content-center place-items-center">
            <div class="flex flex-row border border-red-500 rounded-full h-16 w-16 justify-center">
              <i class="pi pi-times text-red-500 place-self-center" style="font-size: 2rem"></i>
            </div>
            <div class="mt-5 text-gray-600">{{ referralResponseFormRelatedFileUploadError }}</div>
          </div>
        </div>
        <div v-else class="flex flex-col justify-center items-center">
          <i class="pi pi-upload mb-3" style="font-size: 2rem"></i>
          <p>Drag and drop files here, or click "choose" to upload.</p>
        </div>
      </template>
      <template #content="{ files, uploadedFiles, removeFileCallback }">
        <Message severity="info" v-if="maxFileLimit == files.length">You can only upload {{ maxFileLimit }} files at the same time. If you need to upload more files, upload the current files and start another upload request.</Message>
        <!-- <template v-if="uploadedFiles"></template> -->
        <template v-if="files.length > 0">
          <div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="border rounded p-3 surface-border flex flex-col mb-5">
            <div class="flex justify-between border-b pb-1 mb-2">
              <div class="flex">
                <div class="place-self-center mr-2">
                  <img v-if="file.objectURL" role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
                  <i class="pi pi-file place-self-center" style="font-size: 2rem" v-else></i>
                </div>
                <div class="flex flex-col">
                  <div class="text-sm">{{ file.name }}</div>
                  <div>{{ formatSize(file.size) }}</div>
                </div>
              </div>
              <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" @click="removeFileFromRequest(removeFileCallback, file, index)" />
            </div>
            <Message severity="error" v-if="this.fileProperties[index].errors" :closable="false" :id="'responseFormRelatedFileErrorMessage' + index">
              <div>
                {{ this.fileProperties[index].errors }}
              </div>
            </Message>
            <div class="flex flex-col col-span-8 text-xs">
              <div class="grid lg:flex">
                <div class="flex flex-col w-full justify-items-center mb-2 mr-2 w-full">
                  <div class="text-start mr-2 mb-1">Resource Type<span class="text-red-500">*</span></div>
                  <Dropdown v-model="fileProperties[index].type" :options="resourceTypes" placeholder="Type" class="p-inputtext-sm" :id="'responseFormRelatedFileTypeInput' + index" />
                </div>
                <div class="flex flex-col justify-items-center mb-2 mr-2 w-full">
                  <div class="text-start mr-2 mb-1">Year<span class="text-red-500">*</span></div>
                  <Dropdown v-model="fileProperties[index].year" :options="years" placeholder="Year" class="p-inputtext-sm" :id="'responseFormRelatedFileYearInput' + index" />
                </div>
                <div class="flex flex-col justify-items-center mb-2 w-full">
                  <div class="mr-2 mb-1 flex justify-between">
                    <span>Share with VH Network<span class="text-red-500">*</span></span>
                    <i class="pi pi-question-circle cursor-pointer" tabindex="1" v-tooltip.bottom.focus="'Selecting Yes will make this Resource available for all Virtual Hallway users to find in the Resource Centre. Note that this shares only the resource material itself, and not any other consult data.'"></i>
                  </div>
                  <SelectButton
                    v-model="fileProperties[index].privacy_level"
                    :options="[
                      { label: 'Yes', value: 'Public' },
                      { label: 'No', value: 'Referral' },
                    ]"
                    option-label="label"
                    option-value="value"
                    aria-labelledby="basic"
                    class="response-form-related-file-upload-form w-full"
                  />
                </div>
              </div>
              <div class="flex flex-col">
                <div class="flex flex-row w-full justify-items-center my-1">
                  <div class="place-self-center mr-2 w-24">Resource Title<span class="text-red-500">*</span></div>
                  <div class="flex-auto"><InputText :maxlength="titleInputMaxLimit" v-model="fileProperties[index].title" class="p-inputtext-sm" @focusin="fileProperties[index].titleInputFocused = true" @focusout="fileProperties[index].titleInputFocused = false" :id="'responseFormRelatedFileTitleInput' + index" /></div>
                </div>
                <div class="flex justify-between mb-2 text-gray-400" v-if="fileProperties[index].titleInputFocused">
                  <div class="w-32"></div>
                  <div class="flex justify-between w-full">
                    <div class="italic">e.g. Psychiatry</div>
                    <div>{{ titleInputMaxLimit - fileProperties[index].title.length }}/{{ titleInputMaxLimit }}</div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col">
                <div class="flex flex-row w-full justify-items-center my-1">
                  <div class="place-self-center mr-2 w-24">Resource Tags<span class="text-red-500">*</span></div>
                  <div class="flex-auto"><Chips v-model="fileProperties[index].tags" :max="5" class="p-inputtext-sm w-full" separator="," :add-on-blur="true" @focusin="fileProperties[index].tagsInputFocused = true" @focusout="fileProperties[index].tagsInputFocused = false" :input-id="'responseFormRelatedFileTagsInput' + index" /></div>
                </div>
                <div class="flex justify-between mb-2 text-gray-400" v-if="fileProperties[index].tagsInputFocused">
                  <div class="w-32"></div>
                  <div class="flex justify-between w-full">
                    <div class="italic">e.g. Psychiatry, Child Psychiatry (max:5)</div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col">
                <div class="flex flex-row w-full justify-items-center my-1">
                  <div class="place-self-center mr-2 w-24">Brief Description:</div>
                  <div class="flex-auto">
                    <Textarea v-model="fileProperties[index].description" :maxlength="descriptionInputMaxLimit" class="p-inputtext-sm" rows="2" :id="'responseFormRelatedFileDescriptionInput' + index" @focusin="textAreaFocusIn('responseFormRelatedFileDescriptionInput' + index, index)" @focusout="textAreaFocusOut('responseFormRelatedFileDescriptionInput' + index, index)" />
                  </div>
                </div>
                <div class="text-end mb-2 text-gray-400" v-if="fileProperties[index].briefDescriptionInputFocused">{{ descriptionInputMaxLimit - fileProperties[index].description.length }}/{{ descriptionInputMaxLimit }}</div>
              </div>
            </div>
          </div>
          <div class="w-full flex justify-center">
            <Button label="Upload" class="w-1/2" @click="submitUploadReferralResponseFormRelatedFiles" id="responseFormRelatedFileUploadButton" />
          </div>
        </template>
      </template>
    </FileUpload>
  </Dialog>
  <Dialog v-model:visible="showConfirmDeleteResponseFormRelatedFileDialog" modal header="Confirm Delete Resource" :style="{ width: '640px' }" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :dismissable-mask="true" :draggable="false" id="confirmDeleteResponseFormRelatedFileModal">
    <p class="text-sm text-gray-600 px-3">Are you sure you want to delete this resource? The referring physician will no longer be able to view this resource.</p>
    <template #footer>
      <Button
        label="Cancel"
        @click="
          () => {
            showConfirmDeleteResponseFormRelatedFileDialog = false;
            selectedFileToDelete = null;
          }
        "
        class="p-button-outlined"
      />
      <Button label="Delete" icon="pi pi-trash" @click="clickDeleteResponseFormRelatedFile()" autofocus id="confirmDeleteResponseFormRelatedFileButton" />
    </template>
  </Dialog>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Button from 'primevue/button';
import Loading from 'vue-loading-overlay';
import Message from 'primevue/message';
import FileUpload from 'primevue/fileupload';
import Dialog from 'primevue/dialog';
import Badge from 'primevue/badge';
import Textarea from 'primevue/textarea';
import Chips from 'primevue/chips';

export default {
  props: ['referral'],
  components: {
    Button,
    Loading,
    Message,
    FileUpload,
    Dialog,
    Badge,
    Textarea,
    Chips,
  },
  data() {
    return {
      isLoading: false,
      showUploadRelevantFilesModal: false,
      filesToUpload: null,
      selectedFileNames: [],
      referralResponseFormRelatedFileUploadSuccess: null,
      referralResponseFormRelatedFileUploadError: null,
      showConfirmDeleteResponseFormRelatedFileDialog: false,
      selectedFileToDelete: null,
      fileProperties: [],
      titleInputMaxLimit: 50,
      descriptionInputMaxLimit: 300,
      years: [],
      resourceTypes: ['Review', 'Meta-analysis', 'Guideline', 'Opinion', 'Editorial', 'RCT', 'Clinical Study', 'Observational Study', 'Other'],
      fileUploadDialogIndex: 0,
      maxFileLimit: 5,
    };
  },
  computed: {
    ...mapGetters(['actingUser', 'loggedInUser', 'isSpecialist', 'isOrgUnitHead', 'referralResponseFormRelatedFiles', 'canUploadResponseFormRelatedFile', 'canDeleteResponseFormRelatedFile', 'canDownloadResponseFormRelatedFile', 'canEditReferralResponseForm']),
  },
  created() {
    this.isLoading = true;
    let promises = [];
    for (let i = new Date().getFullYear(); i >= 1920; i--) {
      this.years.push(JSON.stringify(i));
    }
    promises.push(
      this.getReferralResponseFormRelatedFilesByReferralId({
        referral_id: this.referral.id,
      })
    );
    Promise.all(promises)
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => this.showGenericErrorToast());
  },
  methods: {
    ...mapMutations(['setTutorialStatus']),
    ...mapActions(['uploadReferralResponseFormRelatedFiles', 'getReferralResponseFormRelatedFilesByReferralId', 'downloadResponseFormRelatedFile', 'deleteResponseFormRelatedFile']),
    appendFileToRequest(event) {
      // This method reinstantiates the the upload data when user selects files. The method is fired when
      // users select files to upload. If user chooses a file, then later on chooses another file, this
      // event gets fired with both files in the event object not only new one.
      let alreadySelectedFileNames = this.selectedFileNames;
      let alreadySelectedFileProperties = this.fileProperties;
      this.filesToUpload = new FormData();
      this.fileProperties = [];
      this.selectedFileNames = [];
      // Setting the following to null so that when users select new files after uploading some files, the last messages go away.
      this.referralResponseFormRelatedFileUploadSuccess = null;
      this.referralResponseFormRelatedFileUploadError = null;
      for (let i = 0; i < event.files.length; i++) {
        this.filesToUpload.append('files[]', event.files[i]);
        // When users add/choose new files to upload this event gets fired. Even for already chosen files,
        // the event is fired with existing files. Thus, when reinitializing the Form Data to upload files,
        // we need to consider if user has selected new files and also selected files they chose before.
        // For already chosen files that are reselected, we want to retain what file properties they had
        // put in. Here we find if the files chosen were chosen before, if so get their input properties,
        // else add a new file entry.
        let j = alreadySelectedFileNames.indexOf(event.files[i].objectURL);
        if (j >= 0) {
          this.fileProperties.push(alreadySelectedFileProperties[j]);
        } else {
          this.fileProperties.push({
            type: '',
            year: '',
            title: '',
            tags: [],
            description: '',
            privacy_level: 'Public',
          });
        }
        this.selectedFileNames.push(event.files[i].objectURL);
      }
      this.filesToUpload.append('referral_id', this.referral.id);
      this.filesToUpload.append('uploader_id', this.actingUser.id);
    },
    removeFileFromRequest(event, file, index) {
      this.fileProperties.splice(index, 1);
      // Removing item from form data implemented from: https://stackoverflow.com/a/41691785/10052594
      var values = this.filesToUpload.getAll('files[]');
      var index = values.indexOf(file);
      values.splice(index, 1);
      // Delete existing file entries since they will be reset
      this.filesToUpload.delete('files[]');
      values.forEach((file) => {
        this.filesToUpload.append('files[]', file);
      });
      event(index);
    },
    submitUploadReferralResponseFormRelatedFiles() {
      this.errors = false;
      for (let i = 0; i < this.fileProperties.length; i++) {
        if (!this.fileProperties[i].type || !this.fileProperties[i].year || !this.fileProperties[i].title || this.fileProperties[i].tags.length < 1) {
          this.fileProperties[i].errors = 'Please fill-out the required fields.';
          this.errors = true;
        }
      }
      if (!this.errors) {
        this.isLoading = true;
        for (let i = 0; i < this.fileProperties.length; i++) {
          this.filesToUpload.append('file_properties[]', JSON.stringify(this.fileProperties[i]));
        }
        this.uploadReferralResponseFormRelatedFiles(this.filesToUpload)
          .then(() => {
            this.isLoading = false;
            this.hideUploadFileDialog();
            this.$toast.add({
              severity: 'success',
              summary: 'Related Resource(s) Uploaded',
              life: 5000,
            });
          })
          .catch(() => {
            this.isLoading = false;
            this.referralResponseFormRelatedFileUploadError = 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.';
            this.showGenericErrorToast();
          });
      }
    },
    clickDownloadResponseFormRelatedFile(id) {
      this.downloadResponseFormRelatedFile({
        id: id,
      })
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'File Downloaded',
            detail: 'The file has been successfully downloaded to your computer, please check your downloads folder.',
            life: 5000,
          });
        })
        .catch(() => this.showGenericErrorToast());
    },
    clickDeleteResponseFormRelatedFile() {
      this.deleteResponseFormRelatedFile({
        id: this.selectedFileToDelete,
      })
        .then(() => {
          this.showConfirmDeleteResponseFormRelatedFileDialog = false;
          this.selectedFileToDelete = null;
          this.$toast.add({
            severity: 'success',
            summary: 'Related Resource Deleted Successfully',
            life: 5000,
          });
        })
        .catch(() => this.showGenericErrorToast());
    },
    formatSize(bytes) {
      if (bytes === 0) return '0 B';
      const k = 1024;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
    textAreaFocusIn(areaId, index) {
      this.fileProperties[index].briefDescriptionInputFocused = true;
    },
    textAreaFocusOut(areaId, index) {
      this.fileProperties[index].briefDescriptionInputFocused = false;
    },
    hideUploadFileDialog() {
      this.fileProperties = [];
      this.fileUploadDialogIndex++;
      this.showUploadRelevantFilesModal = false;
    },
  },
};
</script>

<style>
.p-inputtext-sm ul {
  padding: 0.1rem 0.4375rem 0rem 0.65625rem !important;
}
.p-inputtext-sm ul li {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}

::v-deep(.p-selectbutton .p-button.p-highlight) {
  background-color: var(--vh-success-green);
  border-color: rgb(232, 24, 24);
}
</style>
