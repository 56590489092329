import axios from 'axios';
import globals from '../../globals';

const state = {};

const getters = {};

// actions
const actions = {
  requestToAddExistingUserToOrgUnit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.requestToAddExistingUserToOrgUnitUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  addExistingUserToOrgUnit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(globals.APIs.addExistingUserToOrgUnitUrl(), data, {
          headers: context.rootGetters.setHeaderWithAuth,
        })
        .then((response) => {
          context.commit('updateLoggedInUser', response.data.user);
          context.commit('updateActingUser', response.data.user);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};

// mutations
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
