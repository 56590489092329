<template>
  <template v-if="actingUser && referralComments && referral">
    <div v-if="displayConsultComments()" class="relative my-4 border-t border-solid border-gray-100">
      <div class="mt-2 text-xl font-bold text-gray-600">Consult Comments</div>
      <div class="-mt-2">
        <div v-for="comment in referralComments" :key="comment.id" class="py-2 border-b flex flex-col">
          <template v-if="comment.user_id != actingUser.id">
            <div class="flex flex-row justify-start">
              <div class="flex flex-row items-center mb-2">
                <img v-bind:src="comment.user.profile.profile_picture_location" class="relative z-20 h-5 w-5 rounded-full bg-white object-cover mr-2" />
                <div class="flex-col">
                  <div class="text-sm font-semibold font-display text-primary">{{ comment.user.full_name }}</div>
                  <div class="text-xs text-gray-500">{{ formatDateTime(comment.created_at) }}</div>
                </div>
              </div>
            </div>
            <div class="flex flex-row justify-start">
              <pre class="text-sm font-display">{{ comment.text }}</pre>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-row justify-end">
              <div class="flex flex-row items-center text-end mb-2">
                <div class="flex-col">
                  <div class="text-sm font-semibold font-display text-primary">{{ comment.user.full_name }}</div>
                  <div class="text-xs text-gray-500">{{ formatDateTime(comment.created_at) }}</div>
                </div>
                <img v-bind:src="comment.user.profile.profile_picture_location" class="relative z-20 h-5 w-5 rounded-full bg-white object-cover ml-2" />
              </div>
            </div>
            <div class="flex flex-row justify-end">
              <pre class="text-sm font-display">{{ comment.text }}</pre>
            </div>
          </template>
        </div>
      </div>
      <template v-if="referral.status === 'In Review' && (actingUser.id == referral.referral_person.id || (actingUser.id == referral.referee.id && referral.allow_referee_comment))">
        <form method="post" @submit.prevent="addComment" class="col-start-1 col-end-3 mb-2 w-full text-left">
          <div class="col-start-1 col-end-3 mt-2">
            <label class="auth-input">New Comment </label>
            <Textarea v-model="commentText" class="auth-input" :autoResize="true" rows="5" cols="30" required maxLength="65535" id="commentText" placeholder="Write your comment to the consult here, the Physician will be notified." @keydown.tab.prevent="processTabs($event)" />
          </div>
          <div class="mt-1 flex flex-row">
            <Button label="Submit Comment" class="response-buttons" type="submit" name="submitButton" id="consultSubmitBtn" />
          </div>
        </form>
      </template>
    </div>
  </template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';

export default {
  props: ['referral', 'actingUser'],
  components: {
    Button,
    Textarea,
  },
  data() {
    return {
      commentText: '',
      numOfUnreadComments: 0,
    };
  },
  computed: {
    ...mapGetters(['referralAddendums']),
    referralComments() {
      return this.referralAddendums.filter((addendum) => addendum.type === 'econsult_comment');
    },
  },
  methods: {
    ...mapActions(['getReferralAddendumsByReferralId', 'createReferralAddendum']),
    addComment() {
      const data = {
        user_id: this.actingUser.id,
        referral_id: this.referral.id,
        text: this.commentText,
        type: 'econsult_comment',
      };
      this.createReferralAddendum(data)
        .then(() => {
          this.commentText = '';
          this.$toast.add({
            severity: 'success',
            summary: 'Referral Comment Added',
            life: 3000,
          });
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    processTabs(event) {
      let textarea = document.getElementById(event.target.id);
      let start = textarea.selectionStart;
      textarea.value = textarea.value.substring(0, textarea.selectionStart) + '\t' + textarea.value.substring(textarea.selectionStart, textarea.value.length);
      event.target.selectionStart = event.target.selectionEnd = start + 1;
    },
    displayConsultComments() {
      return (this.referralComments.length > 0 && this.actingUser.id == this.referral.referee.id) || (this.actingUser.id == this.referral.referral_person.id && this.referral.status === 'In Review') || ((this.referral.status === 'Complete' || this.referral.status === 'Cancelled') && this.referralComments.length > 0);
    },
  },
  created() {
    this.getReferralAddendumsByReferralId({ referral_id: this.referral.id })
      .then(() => {})
      .catch(() => {
        this.showGenericErrorToast();
      });
  },
};
</script>

<style>
.p-button.response-buttons {
  display: block;
  width: 100%;
}
.consult-comments.p-accordion .p-accordion-header .p-accordion-header-link {
  background: white !important;
  border: 2px solid #ced4da;
  @apply text-blue-500 !important;
}
</style>
