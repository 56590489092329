<template>
  <div class="flex flex-col justify-around px-8 pb-10 sm:h-96 min-w-fit sm:flex-row md:px-10" v-show="selectedDate">
    <div class="relative my-2 flex flex-col sm:mt-0">
      <template v-if="!dayOff">
        <div class="mb-1 flex items-center py-1 text-center">
          <InputSwitch v-model="isRecurring" id="isRecurringToggle" />
          <label for="isRecurringToggle" class="ml-2 text-lg font-medium">&nbsp;Recurring Schedule</label>
        </div>
        <DatePicker v-model="selectedDate" :masks="masks" class="relative p-5" :min-date="minDate" :max-date="maxDate" :attributes="attributes" is-required />
      </template>
      <template v-else>
        <div class="w-full py-1 text-center font-medium">Select Range of Day(s) to Book Off</div>
        <div class="w-full my-3">
          <DatePicker :masks="masks" class="relative p-5" :min-date="minDate" :max-date="maxDate" :attributes="attributes" is-required v-model.range="deleteScheduleRangeDateSelected" @click="deleteScheduleDateChanged" />
        </div>
      </template>
      <div v-if="(smartPathAccess(user) || orgUnitUsers.find((user) => user.specialist_preference?.smart_path_optin == true)) && !dayOff" class="flex flex-row mt-1">
        <InputSwitch v-model="isSmartPath" inputId="isSmartPathToggle" />
        <label for="isSmartPathToggle" class="ml-2 text-lg font-medium">&nbsp;SmartPath Schedule</label>
      </div>
    </div>
    <div class="flex flex-col" v-if="!dayOff">
      <div class="text-lg font-medium mb-1">Set Schedule</div>
      <div class="overflow-y-scroll overscroll-contain px-4">
        <div v-for="(interval, i) in newSchedules" :key="i">
          <div class="relative mt-2 rounded border border-solid border-gray-300">
            <div class="relative flex flex-row items-center justify-between border-b border-solid border-gray-300 w-full">
              <span class="ml-5">Start</span>
              <DatePicker mode="time" v-model="interval.start_datetime" :minute-increment="5" :date="selectedDate" class="time-picker-date-none relative mr-3" hide-time-header />
            </div>
            <div class="relative flex flex-row items-center justify-between">
              <span class="ml-5">End</span>
              <DatePicker mode="time" v-model="interval.end_datetime" :minute-increment="5" :date="selectedDate" class="time-picker-date-none relative mr-3" hide-time-header />
            </div>

            <i @click="removeInterval(i)" class="pi pi-times-circle absolute inset-y-2/4 -left-2 -my-2.5 -mx-0.5 cursor-pointer bg-white text-red-500" style="font-size: 1.25rem" v-if="i > 0"></i>
          </div>
          <div v-if="intervalErrors[i] != ''" class="error">
            {{ this.intervalErrors[i] }}
          </div>
        </div>
        <span @click="addInterval" class="group mt-2 flex cursor-pointer flex-row items-center rounded border-2 border-dashed border-gray-400 p-2 text-gray-400 hover:border-red-500 hover:text-red-500" id="addInterval">
          <i class="pi pi-plus-circle pl-2 pr-3 text-gray-400 group-hover:text-primary"></i>
          Add Another Work Period
        </span>
      </div>
    </div>
  </div>
  <div class="mb-10 px-10 mt-2">
    <Button @click="addSchedule" class="flex w-full justify-center" name="saveButton"> Save Schedule </Button>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar';
import Button from 'primevue/button';
import moment from 'moment';
import InputSwitch from 'primevue/inputswitch';
import { mapGetters } from 'vuex';

export default {
  props: ['user', 'selectedSchedule', 'selectedIsRecurring', 'dayOff'],
  emits: ['goToConfirmAddSchedule'],
  data() {
    return {
      defaultStartDateTIme: new Date(new Date().setHours(8, 0, 0, 0)),
      defaultEndDateTIme: new Date(new Date().setHours(18, 0, 0, 0)),
      selectedDate: new Date(),
      intervalErrors: [''],
      newSchedules: [],
      isRecurring: false,
      isSmartPath: false,
      masks: {
        input: 'WWW DD MMM YYYY',
      },
      maxDate: moment().add(3, 'months').toDate(),
      minDate: moment().toDate(),
      deleteScheduleDates: {},
      deleteScheduleSingleDateSelected: null,
      deleteScheduleRangeDateSelected: {},
    };
  },
  components: { DatePicker, Button, InputSwitch },
  computed: {
    ...mapGetters(['groupedSchedulesMap', 'orgUnitUsers']),
    attributes() {
      if (this.isRecurring) {
        let i = 0;
        let recurringDates = [moment(this.selectedDate).add(i, 'weeks').toDate()];
        while (recurringDates[i].getTime() < this.maxDate.getTime()) {
          i++;
          recurringDates.push(moment(this.selectedDate).add(i, 'weeks').toDate());
        }
        return [
          {
            dates: recurringDates,
            highlight: true,
          },
        ];
      } else {
        return null;
      }
    },
    existingSchedules() {
      let dayOfWeek;
      if (this.isRecurring) {
        dayOfWeek = moment(this.selectedDate).format('dddd');
      } else {
        dayOfWeek = moment(this.selectedDate).format('YYYY-MM-DD');
      }
      if (this.groupedSchedulesMap[dayOfWeek] && Object.keys(this.deleteScheduleDates).length === 0) {
        return JSON.parse(JSON.stringify(this.groupedSchedulesMap[dayOfWeek]));
      } else {
        return [];
      }
    },
  },
  methods: {
    addInterval() {
      let prop1 = 'start_datetime';
      let prop2 = 'end_datetime';
      this.newSchedules.push({
        [prop1]: this.defaultStartDateTIme,
        [prop2]: this.defaultEndDateTIme,
        user_id: this.user.id,
      });
      this.intervalErrors.push('');
    },
    addSchedule() {
      let prop1 = 'start_datetime';
      let prop2 = 'end_datetime';
      let hasError = false;
      if (this.dayOff) {
        // Check if only a single date was selecetd or a range of dates.
        if (this.deleteScheduleSingleDateSelected) {
          this.deleteScheduleDates.start = new Date(this.deleteScheduleSingleDateSelected.setHours(8, 0, 0, 0));
          this.deleteScheduleDates.end = new Date(this.deleteScheduleSingleDateSelected.setHours(8, 0, 0, 0));
        } else {
          this.deleteScheduleDates.start = new Date(this.deleteScheduleRangeDateSelected.start.setHours(8, 0, 0, 0));
          this.deleteScheduleDates.end = new Date(this.deleteScheduleRangeDateSelected.end.setHours(8, 0, 0, 0));
        }
        let deleteScheduleStartDate = this.deleteScheduleDates.start;
        this.newSchedules = [];
        while (deleteScheduleStartDate <= this.deleteScheduleDates.end) {
          this.newSchedules.push({
            [prop1]: new Date(deleteScheduleStartDate.setHours(8, 0, 0, 0)),
            [prop2]: new Date(deleteScheduleStartDate.setHours(8, 0, 0, 0)),
            user_id: this.user.id,
          });
          deleteScheduleStartDate.setDate(deleteScheduleStartDate.getDate() + 1);
        }
      } else {
        for (let i = 0; i < this.newSchedules.length; i++) {
          this.intervalErrors[i] = '';
          //// Check if the interval start time is before or equal to end time.
          if (this.newSchedules[i][prop1].getTime() >= this.newSchedules[i][prop2].getTime()) {
            this.intervalErrors[i] = 'Interval start time should be before end time';
          } else {
            //// The date selection of datetime picker is set to current date for newly added intervals,
            //// but for existing schedule (i.e. while editing) the date is set to whatever its date
            //// is. Thus, need to compare only the time of the selection here to ensure no intervals
            //// overlap.

            //// Learned getting only time from JS Date object learned from: https://stackoverflow.com/a/49655432/10052594
            let iIntStartTime = this.newSchedules[i][prop1].getHours() * 60 + this.newSchedules[i][prop1].getMinutes();
            let iIntEndTime = this.newSchedules[i][prop2].getHours() * 60 + this.newSchedules[i][prop2].getMinutes();

            //// Check if the interval overlaps with other newSchedules
            for (let j = 0; j < this.newSchedules.length; j++) {
              if (i != j) {
                let jIntStartTime = this.newSchedules[j][prop1].getHours() * 60 + this.newSchedules[j][prop1].getMinutes();
                let jIntEndTime = this.newSchedules[j][prop2].getHours() * 60 + this.newSchedules[j][prop2].getMinutes();
                if (iIntStartTime >= jIntStartTime && iIntStartTime <= jIntEndTime) {
                  this.intervalErrors[i] = `This interval overlaps with interval ${j + 1}.`;
                } else if (iIntEndTime >= jIntStartTime && iIntEndTime <= jIntEndTime) {
                  this.intervalErrors[i] = `This interval overlaps with interval ${j + 1}.`;
                }
              }
            }
          }
          if (this.intervalErrors[i] != '') {
            hasError = true;
          } else {
            //// If everything's ok, set the date of the newSchedules according to selected date,
            //// as by default the date is the current date for the newSchedules.
            this.newSchedules[i][prop1] = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), this.newSchedules[i][prop1].getHours(), this.newSchedules[i][prop1].getMinutes(), 0);
            this.newSchedules[i][prop2] = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), this.newSchedules[i][prop2].getHours(), this.newSchedules[i][prop2].getMinutes(), 0);
          }
        }
      }
      if (!hasError) {
        this.$emit('goToConfirmAddSchedule', {
          selectedDate: this.selectedDate,
          isRecurring: this.isRecurring,
          isSmartPath: this.isSmartPath,
          newSchedules: this.newSchedules,
          existingSchedules: this.existingSchedules,
        });
      }
    },
    removeInterval(index) {
      if (this.newSchedules.length > 1) {
        this.newSchedules.splice(index, 1);
        this.intervalErrors.splice(index, 1);
      }
    },
    deleteScheduleDateChanged(value) {
      // We've range option for setting day offs, but when a user wants to set a single day off, they need to select the same day twice.
      // As a workaround, we check here if a range was not selected, set the only selected day in a variable. But since the DatePicker
      // range selecter does not assign a value to the model unless a whole range is selected, we need to get the selected single
      // date from the HTML element.
      if (!this.deleteScheduleRangeDateSelected.start) {
        let selectedDate = new Date(value.target.ariaLabel);
        // We need to check the date is fetched from the selected HTML element is actually a date, since the click event also fires
        // any time the Vcalender element is clicked (e.g., the navigation arrow buttons for changing months). Here, we check if the
        // element clicked was indeed a proper date by verifying it is greater or equal to today.
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        if (selectedDate >= today) {
          this.deleteScheduleSingleDateSelected = new Date(value.target.ariaLabel);
        }
      } else {
        this.deleteScheduleSingleDateSelected = null;
      }
    },
  },
  created() {
    this.isRecurring = this.selectedIsRecurring;
    if (this.selectedSchedule !== null) {
      this.selectedDate = this.selectedSchedule[0].start_datetime;
      // This is to clone the whole object and retain the moment format for the datetime objects
      this.selectedSchedule.forEach((element) => {
        this.newSchedules.push({
          user: element.user,
          is_recurring: element.is_recurring,
          start_datetime: moment.utc(element.start_datetime).toDate(),
          end_datetime: moment.utc(element.end_datetime).toDate(),
          is_smart_path: element.is_smart_path,
          user_id: element.user_id,
          schedule_id: element.schedule_id,
          is_day_off: element.is_day_off,
        });
      });
    } else {
      this.selectedDate = new Date();
      this.newSchedules = [
        {
          start_datetime: this.defaultStartDateTIme,
          end_datetime: this.defaultEndDateTIme,
          user_id: this.user.id,
        },
      ];
    }
  },
};
</script>

<style>
.time-picker-date-none .vc-time-date,
.time-picker-date-none svg {
  display: none !important;
}
.time-picker-date-none.vc-container {
  border: none !important;
}
.vc-time-select {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.vc-time-picker {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
}
.vc-time-picker.vc-invalid {
  pointer-events: none;
  opacity: 0.5;
}
.vc-time-picker.vc-bordered {
  border-top: 1px solid var(--gray-400);
}
.vc-highlight-content-light {
  color: white !important;
}
</style>
