<template>
  <Dialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :visible="showModal" :modal="true" :dismissableMask="true" :draggable="false" :closable="false" id="confirmDeleteFaxDialog" style="max-width: 480px">
    <template #header>
      <div class="flex flex-row items-center">
        <i class="pi pi-spin pi-ban mr-2 text-gray-400" style="font-size: 1.5rem" />
        <h1 class="text-xl font-bold text-gray-400">Confirm Delete Fax Contact</h1>
      </div>
    </template>

    <div class="flex w-full flex-col p-5">
      <div class="tip-title mb-3 text-sm">Are you sure you want to delete this contact?</div>
      <label class="auth-input" for="phoneNumber">Fax Number</label>
      <span class="p-input-icon-left w-full">
        {{ faxInfo.fax_number }}
      </span>
      <br />
      <label class="auth-input" for="contactName">Contact Name</label>
      <span class="p-input-icon-left w-full">
        {{ faxInfo.contact_name }}
      </span>
    </div>

    <template #footer>
      <div class="flex flex-row justify-end">
        <div class="mr-2">
          <Button label="Close" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="closeDialog()" />
        </div>
        <div class="-mr-1">
          <Button label="DELETE" icon="pi pi-check" class="p-button-danger" @click="deleteAndCloseDialog(faxInfo)" />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Button,
    Dialog,
  },
  props: ['show', 'referral', 'fax_document', 'faxInfo'],
  emits: ['closeConfirmDeleteFaxDialog'],
  data() {
    return {
      showModal: false,
      isLoading: false,
      faxNumber: null,
    };
  },
  computed: {
    ...mapGetters(['updateWhitelist', 'actingUser']),
  },
  created() {},
  methods: {
    ...mapActions(['deleteWhitelistedFaxNumber']),
    closeDialog() {
      this.$emit('closeConfirmDeleteFaxDialog');
    },
    deleteAndCloseDialog(faxInfo) {
      this.deleteWhitelistedFaxNumber(faxInfo)
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Contact Deleted',
            detail: 'You successfully deleted a contact.',
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Could Not Deleted Contact',
            detail: 'There was an error processing your request, please try again later or contact support@virtualhallway.ca.',
            life: 10000,
          });
        });
      this.closeDialog();
    },
  },
  watch: {
    show: {
      handler() {
        this.showModal = !this.showModal;
      },
    },
  },
};
</script>
<style>
#faxConsultDialog .p-dialog-content {
  padding: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
