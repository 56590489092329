<template>
  <Button @click="openTips" v-on:click.stop label="Consult Form Tips" icon="pi pi-external-link" class="p-button-outlined p-button-secondary p-button-sm hide-mobile text-xs" id="tips-button" iconPos="right" />
  <Dialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" v-model:visible="show" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" class="w-3/4 p-0" id="formTips">
    <template #header>
      <h1>Consult Form Tips</h1>
    </template>

    <div class="p-6 sm:flex sm:flex-col">
      <div class="mb-1 flex flex-row items-center">
        <i class="pi pi-star text-blue pr-2 text-left text-sm"></i>
        <h1 class="text-blue font-bold">Too much information can detract</h1>
      </div>
      <p class="mb-6 text-sm text-gray-700">Key points and summaries are what {{ getReferralPersonTerm(actingUser) }}s are interested in. Too much information can dilute the most important clinical information so {{ getReferralPersonTerm(actingUser) }}s advise on avoiding an ‘info dump’.</p>
      <div class="mb-1 flex flex-row items-center">
        <i class="pi pi-star text-blue pr-2 text-left text-sm"></i>
        <h1 class="text-blue font-bold">Let the consult form be your guide</h1>
      </div>
      <p class="mb-6 text-sm text-gray-700">The consult request forms are customized by the {{ getReferralPersonTerm(actingUser) }}s. So any specific pieces of information they would like to know is included there.</p>
      <div class="mb-1 flex flex-row items-center">
        <i class="pi pi-star text-blue pr-2 text-left text-sm"></i>
        <h1 class="text-blue font-bold">Medications and past medical history are important</h1>
      </div>
      <p class="mb-6 text-sm text-gray-700">Most {{ getReferralPersonTerm(actingUser) }}s need to know what medications the patient is taking, so including this information will help streamline the call so that you and the {{ getReferralPersonTerm(actingUser) }} can focus on options for the patient.</p>
      <div class="mb-1 flex flex-row items-center">
        <i class="pi pi-star text-blue pr-2 text-left text-sm"></i>
        <h1 class="text-blue font-bold">A stated question helps</h1>
      </div>
      <p class="mb-6 text-sm text-gray-700">{{ getReferralPersonTerm(actingUser, true) }} understand that sometimes the patient’s condition is complex so narrowing it down to a single question is difficult. However, coming up with one helps to focus the call.</p>
      <div class="mb-1 flex flex-row items-center">
        <i class="pi pi-star text-blue pr-2 text-left text-sm"></i>
        <h1 class="text-blue font-bold">It’s the call that matters</h1>
      </div>
      <p class="mb-6 text-sm text-gray-700">The consult request form sets the stage, but it is the conversation that is most critical. Providing enough information that the {{ getReferralPersonTerm(actingUser) }} is oriented to the general problem your patient is having, as well as some background information, will set up a call where the focus can be on collaborative problem solving.</p>
      <div class="mb-1 flex flex-row items-center">
        <i class="pi pi-star text-blue pr-2 text-left text-sm"></i>
        <h1 class="text-blue font-bold">Saving is not the same as Submitting</h1>
      </div>
      <p class="text-sm text-gray-700">You can <strong>Save</strong> your consult request form if you need to come back to it, but you must <strong>Submit</strong> the request before it is sent to the Specialist.</p>
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" class="p-button-outlined p-button-secondary" @click="closeTips()" />
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
export default {
  components: {
    Button,
    Dialog,
  },
  computed: {
    ...mapGetters(['loggedInUser', 'actingUser']),
  },
  props: ['user', 'selectedSpecialty'],
  data() {
    return {
      selectedSpecialist: [],
      isLoading: false,
      show: false,
    };
  },
  methods: {
    openTips() {
      this.show = true;
    },
    closeTips() {
      this.show = false;
    },
  },
};
</script>
<style>
#formTips .p-dialog-content {
  padding: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-dialog {
  border: none !important;
}

#tips-button {
  border: 1px solid white;
  color: white;
  position: absolute;
  right: 16px;
  top: 16px;
}
</style>
