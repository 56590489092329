<template>
  <div class="main-inner-wrap vld-parent" v-if="isLoading">
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
  </div>
  <div class="main-inner-wrap vld-parent" v-if="!isLoading">
    <template v-if="!isOrgUnitHead(loggedInUser) && actingUser.billing_opt_in">
      <template v-if="billingArrangement == null || billingArrangement.status !== 'Submitted'">
        <EditBilling :billingArrangement="billingArrangement" />
      </template>
      <template v-else>
        <PerformancePage :billingCompleted="billingArrangement" @openBillingArrangementModal="openBillingArrangementModal" />
      </template>
    </template>
    <template v-else>
      <ShadowBilling />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ShadowBilling from '@/components/billing/ShadowBilling.vue';
import PerformancePage from '@/components/billing/PerformancePage.vue';
import EditBilling from '@/components/billing/EditBilling.vue';
import Loading from 'vue-loading-overlay';

export default {
  components: {
    Loading,
    ShadowBilling,
    PerformancePage,
    EditBilling,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['actingUser', 'billingArrangement', 'isOrgUnitHead', 'loggedInUser']),
  },
  methods: {
    ...mapActions(['getBillingArrangement']),
  },
  created() {
    if (this.actingUser != null && this.actingUser.id !== 0) {
      this.isLoading = true;
      this.getBillingArrangement({ userId: this.actingUser.id, practiceProvinceId: this.actingUser.practice_province.id })
        .then(() => {
          if (this.billingArrangement && this.billingArrangement.status === 'Submitted') {
            this.eTransferEmail = this.billingArrangement.etransfer_email;
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    }
  },
};
</script>

<style>
.billing-export-table.p-datatable .p-column-filter {
  display: block;
}

.status-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  text-align: center;
}

.status-badge.Exported {
  background: #5d97f7;
  color: #fff;
}
.status-badge.Ready {
  background: #ff8366;
  color: #fff;
}
</style>
