<template>
  <Dialog @show="setClosedToFalse" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" style="max-width: 420px" :draggable="false" :visible="show" :modal="true" :dismissableMask="true" :closable="false">
    <template #header>
      <h2>Auto Logout Warning</h2>
    </template>
    <div class="text-center" style="min-width: 300px">
      You have been inactive for 10 minutes.
      <p class="p-text-bold mt-3">To prevent logout due to inactivity please close this message within the following time:</p>
      <p class="p-text-bold p-text-center mt-3 mb-3 text-2xl text-yellow-500">
        <CountDownTimer :countDownInSeconds="countDownLimit" @countdownEnded="logoutUser" />
      </p>
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" class="p-button-outlined p-button-secondary" @click="popUpClosed" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { mapActions } from 'vuex';

export default {
  props: ['show', 'countDownLimit'],
  emits: ['confirmActivityPopUpClosed'],
  components: {
    Dialog,
    Button,
  },
  data() {
    return {
      closed: false,
    };
  },
  methods: {
    ...mapActions(['logout']),
    logoutUser() {
      // this.closed check was needed since even closing a Dialog was still calling the countdownEnded
      // if countdown ended in the background.
      if (!this.closed) {
        this.closed = true;
        this.$emit('confirmActivityPopUpClosed', true);
        this.$router.replace({ name: 'Logout', query: { autoLogout: 'true', redirect: this.$route.fullPath } });
      }
    },
    setClosedToFalse() {
      this.closed = false;
    },
    popUpClosed() {
      this.closed = true;
      this.$emit('confirmActivityPopUpClosed', true);
    },
  },
};
</script>

<style></style>
