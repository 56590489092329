<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card animated fade-in-up rounded-2xl shadow">
        <DataTable editMode="cell" @cell-edit-complete="onCellEditComplete" :value="referrals" :paginator="true" class="p-datatable-gridlines editable-cells-table referral-list-table" :rows="10" dataKey="id" :rowHover="true" v-model:filters="filters1" filterDisplay="menu" :filters="filters1" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[10, 25, 50, 100]">
          <template #header>
            <div class="flex flex-col sm:flex-row justify-between">
              <h1 class="fc-toolbar-title mt-2 mb-4 md:mb-0 text-left">Notes</h1>
              <div class="grid grid-cols-2 gap-4 sm:flex">
                <Button class="p-button-secondary" label="Add New Note" icon="pi pi-plus" name="addNewNoteButton" @click="addNoteOnClick"></Button>
                <IconField iconPosition="left" :class="{ 'col-span-2': isOrgUnitHead(loggedInUser) || !loggedInUser.org_units.length > 0 }">
                  <InputIcon class="pi pi-search"> </InputIcon>
                  <InputText v-model="filters1['global'].value" placeholder="Search" class="w-full" maxLength="255" />
                </IconField>
              </div>
            </div>
          </template>
          <template #empty> No Notes found. </template>
          <template #loading> Loading Note data. Please wait. </template>

          <Column field="referral_form.patient_full_name" header="Patient" :sortable="true"> </Column>

          <Column v-if="!isOrgUnitHead(loggedInUser)" field="#" header="Note With" style="min-width: 1rem" :sortable="true">
            <template #body="{ data }">
              <span class="p-column-title">Full Name</span>
              <template v-if="data.referee_id === loggedInUser.id">
                {{ data.referral_person_full_name }}
              </template>
              <template v-else-if="data.referral_person_id === loggedInUser.id">
                {{ data.referee_full_name }}
              </template>
            </template>
          </Column>

          <Column dataType="date" sortField="updated_at" field="updated_at" header="Last Updated" style="max-width: 8rem" :sortable="true">
            <template #filter="{ filterModel }">
              <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
            </template>
            <template #body="{ data }">
              <span class="p-column-title">Date/Time</span>
              <div>{{ formatDateTime(data.updated_at) }}</div>
            </template>
          </Column>

          <Column field="status" header="Status" :showFilterMatchModes="false" bodyClass="text-center" :filterMenuStyle="{ width: '14rem' }" style="max-width: 8rem">
            <template #body="{ data }">
              <span class="p-column-title">Status</span>
              <div :class="'p-text-center top-6 right-6 rounded px-2 py-1 text-xs font-bold uppercase text-white  ' + data.status.replace(/\s/g, '')">{{ noteStatus[data.status] }}</div>
            </template>
            <template #filter="{ filterModel }">
              <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span :class="'status-badge text-white ' + slotProps.value.replace(/\s/g, '')" v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
                <template #option="slotProps">
                  <span :class="'status-badge text-white ' + slotProps.option.replace(/\s/g, '')">{{ noteStatus[slotProps.option] }}</span>
                </template>
              </Dropdown>
            </template>
          </Column>

          <Column field="id" header="View" style="width: 1rem">
            <template #body="{ data }">
              <Button icon="pi pi-eye" class="p-button-rounded p-button-outlined" @click="selectReferral(data.id)" :id="data.className + '-referral-view-button'" :aria-label="data.referral_form.patient_first_name + '-' + data.referral_form.patient_last_name + '-referral-view-button'" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import { FilterOperator } from 'primevue/api';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      filters1: null,
      statuses: ['Draft', 'Signed Off', 'Complete'],
      types: ['phone', 'econsult'],
      selectedReferral: null,
      noteStatus: {
        Draft: 'Draft',
        'Signed Off': 'Signed Off',
        Complete: 'Submitted For Billing',
      },
    };
  },

  created() {
    this.initFilters1();
    if (this.$route.params.filterStatus) {
      this.filters1.status.value = this.$route.params.filterStatus;
    }
  },
  props: ['referrals'],
  components: {
    DataTable,
    Dropdown,
    Column,
    Button,
    InputText,
    Calendar,
  },
  methods: {
    ...mapActions(['getReferralById']),
    selectReferral(id) {
      this.$router.push('/note/' + id);
    },
    addNoteOnClick() {
      this.$router.push('/add-note');
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        start_datetime: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        'interaction_type.name': { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;

      this.createReferralNotes({
        userId: this.loggedInUser.id,
        referral_notes: newValue,
        referral: data,
      });

      switch (field) {
        default:
          if (newValue.trim().length > 0) data[field] = newValue;
          else event.preventDefault();
          break;
      }
    },
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isGP', 'isSpecialist', 'isOrgUnitHead', 'actingUser', 'referral']),
  },
};
</script>

<style lang="scss">
::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

.referral-list-table.p-datatable .p-column-filter {
  display: block;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.status-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-InReview {
    background: #946da6;
    color: #6b039c;
  }

  &.status-SignedOff {
    background: #946da6;
    color: #6b039c;
  }

  &.status-Complete {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Accepted {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Faxed {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Upcoming {
    background: #b3e5fc;
    color: #23547b;
  }

  &.status-Pending {
    background: #f8da87;
    color: #81620c;
  }

  &.status-Draft {
    background: #f8da87;
    color: #81620c;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--surface-d);

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: 0.5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #c63737;
}

.p-button.p-button-info {
  background: var(--vh-blue) !important;
}

.cal-leg {
  width: 15px;
  height: 15px;
  background-color: var(--vh-blue);
  margin-right: 10px;
  border-radius: 50px;
}

.p-editable-column > .p-inputtext {
  font-size: 0.7rem;
}
</style>
