<template>
  <Dialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" header="Patient Booked Within Exclusion Period" style="max-width: 450px" :visible="showModal" :modal="true" :dismissableMask="true" :closable="false" :draggable="false">
    <div class="max-w-3xl" style="min-width: 300px">
      <div class="info-paragraph--gray align-center text-sm-override items-center justify-center py-2">Unfortunately you can only submit a consult once every {{ exclusionPeriod }} for a patient. We recommend you cancel this consult and book a time after {{ formatDate(patientBookWithinExclusionPeriodRebookDate) }}. If you have any questions please contact us at <a href="mailto:support@virtualhallway.ca">support@virtualhallway.ca&nbsp;</a></div>
    </div>
    <div class="flex justify-center">
      <Button id="cancelWithin14Days" label="Cancel Appointment" class="p-button-outlined p-button-danger w-full max-w-sm text-center" @click="submitCancelReferral" />
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" class="p-button-outlined p-button-secondary" @click="closeModal" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['show', 'patientBookWithinExclusionPeriodRebookDate', 'referral'],
  data() {
    return {
      showModal: true,
      password: '',
      error: '',
      exclusionPeriod: null,
    };
  },
  components: {
    Dialog,
    Button,
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  methods: {
    ...mapActions(['cancelReferral']),
    closeModal() {
      this.showModal = false;
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    submitCancelReferral() {
      this.$toast.removeAllGroups();
      let data = {};
      data.userId = this.loggedInUser.id;
      data.reason_for_failed = 'Patient Booked Within Exclusion Period';
      data.referral = this.referral;
      this.cancelReferral(data)
        .then(() => {
          this.closeModal();
          this.$toast.add({
            severity: 'success',
            summary: 'Consult successfully cancelled',
            life: 5000,
          });
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
  },
  created() {
    if (this.referral.specialty.practice_province.short_name === 'NS') {
      this.exclusionPeriod = '14 days';
    } else if (this.referral.specialty.practice_province.short_name === 'ON') {
      this.exclusionPeriod = 'day';
    }
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        this.showModal = this.show;
      },
    },
  },
};
</script>

<style></style>
