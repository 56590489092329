<template>
  <Dialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :visible="showModal" :modal="true" :dismissableMask="true" :draggable="false" :closable="false" id="confirmDeleteFaxDialog" style="max-width: 680px; width: 100%">
    <template #header>
      <h1 class="text-xl font-bold text-gray-900">Patient Previous Consult Form</h1>
    </template>
    <div class="w-full"></div>
    <template v-if="referral">
      <ReferralDetails :referral="referral" :completeView="false" />
      <ShowReferralForm :referral="referral" :completeView="false" />
      <div class="flex flex-row items-start justify-between mb-3 border-b border-solid border-gray-100 pb-4 w-full">
        <div class="flex flex-row">
          <div class="text-blue-400" v-if="referral.status !== 'Cancelled'">
            <h2 class="text-left font-semibold text-lg text-gray-600" v-if="referral.interaction_type.name === 'phone'">Consult Response</h2>
            <h2 class="text-left font-semibold text-lg text-gray-600 flex flex-row" v-else-if="referral.interaction_type.name === 'econsult'">
              {{ referral.interaction_type.display_name }}<span class="flex"><i class="fa fa-plus text-pink-500 flex pt-0.5" style="font-size: 0.7rem; font-weight: 900"></i>&nbsp;</span> Response
            </h2>
            <div class="flex flex-row" style="align-items: center">
              <h5 class="text-xs text-gray-500">Submitted: {{ formatDateTime(referral.referral_response_form.updated_at) }} {{ getUserTimeZoneAbbr() }}</h5>
            </div>
          </div>
        </div>
      </div>
      <ShowResponseForm :referral="referral" />
    </template>

    <template #footer>
      <div class="flex flex-row justify-end">
        <div class="mr-2">
          <Button label="Close" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="closeDialog()" />
        </div>
      </div>
    </template>
  </Dialog>
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Loading from 'vue-loading-overlay';
import ReferralDetails from '@/components/referral/ReferralDetails.vue';
import ShowResponseForm from '@/components/referral/ShowResponseForm.vue';
import { defineAsyncComponent } from 'vue';

export default {
  name: 'PeekCompleteReferralDialog',
  components: {
    Button,
    Dialog,
    Loading,
    ReferralDetails,
    ShowResponseForm,
    ShowReferralForm: defineAsyncComponent(() => import('@/components/referral/ShowReferralForm.vue')),
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      referral: null,
    };
  },
  created() {},
  methods: {
    openDialog(peekReferral) {
      this.referral = peekReferral;
      this.showModal = true;
    },
    closeDialog() {
      this.showModal = false;
    },
  },
};
</script>
<style>
#faxConsultDialog .p-dialog-content {
  padding: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
