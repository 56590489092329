<template lang="">
  <a @click="openDialog" class="text-xs text-secondary underline" href="#">General Privacy Policy</a>
  <Dialog header="Virtual Hallway General Privacy Policy" :breakpoints="{ '960px': '50vw', '640px': '90vw' }" style="max-width: 800px; width: 100%; padding-bottom: 48px" v-model:visible="show" :modal="true" :dismissableMask="true" :draggable="false" :closable="true" id="eulaDialog">
    <GeneralPrivacyPolicy />
    <template #footer>
      <Button label="Close" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="closeDialog()" />
    </template>
  </Dialog>
</template>
<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import GeneralPrivacyPolicy from '@/views/public/privacy/GeneralPrivacyPolicy.vue';

export default {
  components: {
    Button,
    Dialog,
    GeneralPrivacyPolicy,
  },
  data() {
    return {
      isLoading: false,
      show: false,
    };
  },
  methods: {
    openDialog() {
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    },
  },
};
</script>
<style lang=""></style>
