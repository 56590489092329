<template>
  <div class="main-inner-wrap vld-parent" v-if="isLoading">
    <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
  </div>
  <div class="main-inner-wrap" v-if="!isLoading">
    <div class="bg-white p-4 mt-6 rounded-lg shadow w-full max-w-2xl border-1">
      <div class="text-left font-display text-md font-bold text-gray-400 pb-3 border-b">All Notifications</div>
      <template v-if="isLoading" class="text-center items-center w-full justify-center p-5">
        <div class="flex flex-row justify-center items-center h-24">
          <i class="pi pi-spin pi-spinner" style="font-size: 1.5rem; color: #ef5164"></i>
        </div>
      </template>
      <template v-else>
        <div v-if="notifications.length < 1" class="bg-white rounded-xl p-6 mb-4 w-full flex justify-center items-center flex-col">
          <i class="pi pi-calendar-times text-grey-400 my-4" style="font-size: 2rem"></i>
          <div v-if="actingUser.specialist_preference?.call_asap_optin == false" class="w-full flex flex-col items-center justify-center">
            <h3 class="fc-toolbar-title mb-2">No New Notifications</h3>
            <p class="text-sm mx-5 mt-2 mb-4 tip-title">Did you know that enabling <strong>OnDemand Consults</strong> allow you to call the referring practitioner any time before the scheduled date? Click below to learn more and enable.</p>
            <Button icon="fas fa-bolt" label="OnDemand Consults" @click="goToPreference()" />
          </div>
          <div v-else class="w-full flex flex-col items-center justify-center">
            <h3 class="fc-toolbar-title mb-6">No New Notifications</h3>
            <Button v-if="isSpecialist(actingUser)" icon="pi pi-calendar" label="Add Availability" @click="goToAvailability()" />
          </div>
        </div>
        <!-- Come back to this and restyle/paginate it -->
        <div v-for="notification in notifications.slice(0, 100)" :key="notification.id" class="bg-white p-3 hover:bg-gray-50 w-full relative cursor-pointer border-solid border-gray-200 border-b last:border-none">
          <div class="flex flex-row w-full justify-between items-center">
            <div class="flex flex-row justify-center items-center my-1">
              <template v-if="notification.status != 'Unread'">
                <div v-tooltip="'Mark as Unread'" @click="flipNotificationStatus(notification)" class="flex align-center items-center justify-center rounded hover:bg-gray-200" style="width: 2rem; height: 2rem">
                  <i class="pi pi-envelope text-gray-400 text-xs text-left"></i>
                </div>
              </template>
              <template v-else>
                <div v-tooltip="'Mark as Read'" @click="flipNotificationStatus(notification)" class="flex align-center items-center justify-center rounded hover:bg-gray-300" style="width: 2rem; height: 2rem">
                  <i class="pi pi-envelope text-gray-400 text-xs text-left"></i>
                </div>
              </template>
            </div>

            <div class="flex flex-col ml-2 w-full text-left" @click="selectReferral(notification.meta_data.referral_id)">
              <div class="flex flex-row justify-between w-full items-start">
                <div class="flex flex-row text-gray-500 items-center uppercase font-semibold font-display text-xs">
                  {{ notification.title }}
                  <div v-if="notification.status == 'Active' || notification.status == 'Unread'" style="height: 8px; width: 8px; background-color: #ff6a78" class="ml-2 rounded-full"></div>
                </div>
                <div class="text-gray-500 post-date">
                  <!-- {{ formatNotificationDate(notification.meta_data.referral_start_datetime) }} -->
                  {{ formatNotificationDate(notification.created_at) }}
                </div>
              </div>
              <div class="text-gray-500" style="font-size: 0.65rem">{{ notification.details }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      id: '',
      notifications: [],
      existingNotifications: [],
      isLoading: true,
    };
  },
  emits: ['toggle'],
  computed: {
    ...mapGetters(['actingUser', 'getUserActionsByName', 'loggedInUser', 'getUserActionById', 'isSpecialist', 'isGP']),
  },
  components: {
    Button,
  },
  created() {
    this.getUserActions({ set_active_notifications_as_read: true })
      .then((response) => {
        this.notifications = response.data.data;
        this.notifications = this.notifications.filter((n) => {
          if (n.type == 'app_notification') {
            n.meta_data.referral_start_datetime = moment.utc(n.meta_data.referral_start_datetime).toDate();
            return true;
          } else return false;
        });
        this.isLoading = false;
      })
      .catch(() => {
        this.showGenericErrorToast();
        this.isLoading = false;
      });
  },
  unmounted() {
    let loggedInUser = this.loggedInUser;
    let userActions = this.loggedInUser.actions;
    userActions.forEach((n, index) => {
      if (n.type == 'app_notification' && n.status == 'Active') {
        n.status = 'Read';
      }
      userActions[index] = n;
    });
    loggedInUser.actions = userActions;
    this.$store.commit('updateLoggedInUser', loggedInUser);
  },
  methods: {
    ...mapActions(['getUserActions', 'updateUserAction']),
    selectReferral(id) {
      this.$router.push('/referral/' + id);
      this.$emit('toggle');
    },
    goToConsultHistory() {
      this.$router.push('/referralhistory/');
      this.$emit('toggle');
    },
    goToAvailability() {
      this.$router.push('/schedules/');
      this.$emit('toggle');
    },
    goToPreference() {
      this.$router.push('/preferences/');
      this.$emit('toggle');
    },
    flipNotificationStatus(notification) {
      if (notification.status == 'Unread') {
        notification.status = 'Read';
      } else notification.status = 'Unread';
      this.updateUserAction(notification);
    },
  },
};
</script>

<style></style>
